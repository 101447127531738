import { useLazyQuery, useMutation } from '@apollo/client';
import {
  BULK_UPLOAD_TRANSPONDERS,
  BULK_UPLOAD_TRANSPONDERS_PROCESS,
  GET_TRANSPONDER_LIST,
  ITranspondersUploadOutput,
} from '../../../graphql/transponders.graphql';
import useErrorHandling from '../../errors/useErrorHandling';
import { BulkUploadType } from '../../../components/Links/BulkUploadTemplateLink';
import {
  BULK_UPLOAD_VEHICLES,
  BULK_UPLOAD_VEHICLES_PROCESS,
  GET_VEHICLE_LIST,
  IVehiclesBulkUploadOutput,
} from '../../../graphql/vehicles.graphql';

export interface IUseBulkUpload {
  upload: (file: File) => Promise<any>;
  process: (batchId: string) => Promise<any>;
  uploading: boolean;
  reset: () => void;
}

export interface IUserBulkUploadProps {
  type: BulkUploadType;
  finishProcessing: () => void;
  accountId?: string;
}

export const useBulkUpload = ({ type, finishProcessing, accountId }: IUserBulkUploadProps): IUseBulkUpload => {
  const { resetError } = useErrorHandling();
  const [uploadTransponders, { loading: transponderBulkUploadLoading, reset: resetTransponderUpload }] = useMutation<
    { transpondersBulkUpload: ITranspondersUploadOutput },
    { file: File }
  >(BULK_UPLOAD_TRANSPONDERS, {
    onError() {
      finishProcessing();
    },
  });
  const [processTransponderBulkUpload, { client }] = useLazyQuery<
    {
      transpondersBulkUploadProcess: boolean;
    },
    { batchId: string }
  >(BULK_UPLOAD_TRANSPONDERS_PROCESS, {
    onError() {
      finishProcessing();
    },
    onCompleted: async () => {
      client.refetchQueries({
        include: [GET_TRANSPONDER_LIST],
      });
    },
  });

  const [uploadVehicles, { loading: vehicleBulkUploadLoading, reset: resetVehicleUpload }] = useMutation<
    { vehiclesBulkUpload: IVehiclesBulkUploadOutput },
    { file: File; accountId?: string }
  >(BULK_UPLOAD_VEHICLES, {
    onError() {
      finishProcessing();
    },
  });

  const [processVehicleBulkUpload] = useLazyQuery<
    {
      vehiclesBulkUploadProcess: boolean;
    },
    { batchId: string }
  >(BULK_UPLOAD_VEHICLES_PROCESS, {
    async onError() {
      finishProcessing();
    },
    onCompleted: async () => {
      client.refetchQueries({
        include: [GET_VEHICLE_LIST],
      });
    },
  });

  const upload = async (file: File): Promise<any> => {
    resetError();
    switch (type) {
      case 'transponders':
        return await uploadTransponders({
          variables: {
            file,
          },
        });

      case 'vehicles':
        return await uploadVehicles({
          variables: {
            file,
            ...(!!accountId && { accountId }),
          },
        });
      default:
        throw new Error('No matching bulk upload type for upload');
    }
  };

  const process = async (batchId: string): Promise<any> => {
    switch (type) {
      case 'transponders':
        return await processTransponderBulkUpload({
          variables: {
            batchId,
          },
        });

      case 'vehicles':
        return await processVehicleBulkUpload({
          variables: {
            batchId,
          },
        });
      default:
        throw new Error('No matching bulk upload type for process');
    }
  };

  return {
    upload,
    process,
    uploading: transponderBulkUploadLoading || vehicleBulkUploadLoading,
    reset: () => {
      type === 'transponders' ? resetTransponderUpload() : resetVehicleUpload();
    },
  };
};

import { i18n } from '@lingui/core';
import { isNull, isUndefined } from 'lodash';
import * as yup from 'yup';
import yupPassword from 'yup-password';
import 'yup-phone';
import { RequiredDateSchema } from 'yup/lib/date';
import BaseSchema from 'yup/lib/schema';
import { StringSchema } from 'yup';
import {
  ALPHANUMERIC_ERROR_MSG,
  SINGLE_CHAR_MAX_ERROR_MSG,
  ALPHABETICAL_ERROR_MSG,
  REPLENISHMENT_THRESHOLD_MIN_ERROR_MSG,
  ALPHANUMERIC_OR_SPECIAL_CHAR_ERROR_MSG,
  ENTER_VALUE_ERROR_MSG,
  ACCOUNT_NUMBER_REQUIRED,
  ACCOUNT_NUMBER_INVALID_ERROR_MSG,
} from './validatorsErrorMessages';
import {
  ADDRESS_MIN_ERROR_MSG,
  CITY_MIN_ERROR_MSG,
  EMAIL_ALPHANUMERIC_OR_SPECIAL_CHAR_ERROR_MSG,
  EMAIL_ERROR_MSG,
  FEIN_LENGTH_ERROR_MSG,
  FEIN_NUMBER_ERROR_MSG,
  MATCH_PASSWORD_ERROR_MSG,
  MATCHING_EMAIL_ERROR_MSG,
  NAME_ALPHABETICAL_OR_SPECIAL_CHAR_ERROR_MSG,
  NAME_AND_RESPONSE_MAX_ERROR_MSG,
  PASSWORD_ERROR_MSG,
  PASSWORD_MIN_LOWERCASE_ERROR_MSG,
  PASSWORD_MIN_NUMBERS_ERROR_MSG,
  PASSWORD_MIN_SYMBOLS_ERROR_MSG,
  PASSWORD_MIN_UPPERCASE_ERROR_MSG,
  PHONE_ERROR_MSG,
  PHONE_MAX_ERROR_MSG,
  PHONE_MIN_ERROR_MSG,
  POSTAL_CODE_ALPHANUMERIC_OR_SPECIAL_CHAR_ERROR_MSG,
  POSTAL_CODE_MIN_ERROR_MSG,
  POSTAL_CODE_REQUIRED_ERROR_MSG,
  USERNAME_ERROR_MSG,
  SECURITY_CODE_REQUIRED,
  SECURITY_CODE_MINIMUM,
  SECURITY_CODE_NUMBERS_ONLY,
  ADDRESS2_MIN_ERROR_MSG,
} from './onboarding/accountInfo/accountInfoErrorMessages';
import { DEPOSIT_NUMBER_MATCH_ERROR_MSG } from './onboarding/ezPay/ezPayErrorMessages';
import {
  MIN_DATE_ERROR_MSG,
  TRANSPONDER_NUMBER_DIGITS_ONLY_ERROR_MSG,
  TRANSPONDER_NUMBER_LENGTH_ERROR_MSG,
  TRANSPONDER_NUMBER_MATCH_ERROR_MSG,
  TRANSPONDER_NUMBER_REQUIRED_ERROR_MSG,
} from './onboarding/transponders/transpondersErrorMessages';

yupPassword(yup);

/*
  Field validation reference:
  https://confluence.portlandwebworks.com/display/SUNPASS/Field+Validation+Rules+for+Sunpass
 */

export const validators = {
  string: yup.string().trim(),
  name: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z-'‘’.\s]*$/, NAME_ALPHABETICAL_OR_SPECIAL_CHAR_ERROR_MSG)
    .max(40, NAME_AND_RESPONSE_MAX_ERROR_MSG),
  alphanumeric: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z0-9]*$/, ALPHANUMERIC_ERROR_MSG),
  singleChar: yup
    .string()
    .trim()
    .max(1, SINGLE_CHAR_MAX_ERROR_MSG)
    .matches(/^[a-zA-Z]*$/, ALPHABETICAL_ERROR_MSG),
  email: yup
    .string()
    .nullable()
    .email(EMAIL_ERROR_MSG)
    .trim()
    .min(7, EMAIL_ERROR_MSG)
    .matches(/^[A-Za-z0-9.]+@[A-Za-z0-9.]+\.[A-Za-z]{2,}$/, EMAIL_ALPHANUMERIC_OR_SPECIAL_CHAR_ERROR_MSG),
  emailConfirm: (emailFieldName: string): BaseSchema => {
    return yup
      .string()
      .nullable()
      .trim()
      .min(7, EMAIL_ERROR_MSG)
      .max(40, EMAIL_ERROR_MSG)
      .oneOf([yup.ref(emailFieldName), null], MATCHING_EMAIL_ERROR_MSG);
  },
  phone: yup
    .string()
    .transform((value) => {
      return value?.replace(/[&\/\\#,+()$~%.'":*?<>{}\-_]/g, '').replace(/ /g, '');
    })
    .trim()
    .test('min10Char', PHONE_MIN_ERROR_MSG, (value) => {
      if (!value || value.length === 0) return true;
      return !!value.match(/^[0-9]{10,}$/);
    })
    .max(17, PHONE_MAX_ERROR_MSG)
    .test('validUSNumber', PHONE_ERROR_MSG, (value) => {
      if (!value || value.length === 0) return true;
      else return yup.string().phone('US', false, PHONE_ERROR_MSG).isValidSync(value);
    }),
  address: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z0-9'. ,-]*$/, ALPHANUMERIC_OR_SPECIAL_CHAR_ERROR_MSG)
    .test('addressMinimum', ADDRESS_MIN_ERROR_MSG, (val) => !val || val.length === 0 || val.length >= 3),
  address2: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z0-9'. ,-]*$/, ALPHANUMERIC_OR_SPECIAL_CHAR_ERROR_MSG)
    .test('addressMinimum', ADDRESS2_MIN_ERROR_MSG, (val) => !val || val.length === 0 || val.length >= 1),
  city: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z0-9'. ,-]*$/, ALPHANUMERIC_OR_SPECIAL_CHAR_ERROR_MSG)
    .test('cityMinimum', CITY_MIN_ERROR_MSG, (value) => !value || value.length === 0 || value.length >= 3),
  postalCode: yup
    .string()
    .trim()
    .required(POSTAL_CODE_REQUIRED_ERROR_MSG)
    .matches(/^[a-zA-Z0-9- ]*$/, POSTAL_CODE_ALPHANUMERIC_OR_SPECIAL_CHAR_ERROR_MSG)
    .min(5, POSTAL_CODE_MIN_ERROR_MSG),
  username: (requiredErrorMsg = USERNAME_ERROR_MSG) => {
    return yup
      .string()
      .required(requiredErrorMsg)
      .trim()
      .min(6, USERNAME_ERROR_MSG)
      .matches(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$/, USERNAME_ERROR_MSG);
  },
  password: yup
    .string()
    .trim()
    .password()
    .min(8, PASSWORD_ERROR_MSG)
    .minLowercase(1, PASSWORD_MIN_LOWERCASE_ERROR_MSG)
    .minUppercase(1, PASSWORD_MIN_UPPERCASE_ERROR_MSG)
    .minNumbers(1, PASSWORD_MIN_NUMBERS_ERROR_MSG)
    .minSymbols(1, PASSWORD_MIN_SYMBOLS_ERROR_MSG)
    .matches(/^[a-zA-Z0-9!@#$%*()_+=~;,.-]*$/, PASSWORD_ERROR_MSG),
  passwordConfirm: (isSubmitted?: boolean): BaseSchema => {
    return yup
      .string()
      .trim()
      .test('password-match', MATCH_PASSWORD_ERROR_MSG, function (value) {
        if ((this.parent.password === undefined || this.parent.password === '') && isSubmitted === false) return true;
        else if ((this.parent.password === undefined || this.parent.password === '') && isSubmitted === true)
          return false;
        if (this.parent.password) return this.parent.password === value;
        else return false;
      });
  },
  payment: yup
    .string()
    .test('isANumber', MATCH_PASSWORD_ERROR_MSG, function (payment) {
      return !(payment === undefined || payment === null || isNaN(+payment));
    })
    .test('isEmpty', ENTER_VALUE_ERROR_MSG, function (payment) {
      return !(payment === '');
    }),
  replenishmentThreshold: yup
    .string()
    .test('meetsMinimumThreshold', REPLENISHMENT_THRESHOLD_MIN_ERROR_MSG, function (threshold) {
      return !(isUndefined(threshold) || isNull(threshold) || isNaN(+threshold) || +threshold < 10);
    }),
  depositAmountConfirm: (depositAmountFieldName: string): BaseSchema => {
    return yup.string().oneOf([yup.ref(depositAmountFieldName), null], DEPOSIT_NUMBER_MATCH_ERROR_MSG);
  },
  transponderNumber: (requiredErrorMsg = TRANSPONDER_NUMBER_REQUIRED_ERROR_MSG) => {
    return yup
      .string()
      .trim()
      .required(requiredErrorMsg)
      .matches(/^[0-9]*$/, TRANSPONDER_NUMBER_DIGITS_ONLY_ERROR_MSG)
      .test('test transponderNumber length', TRANSPONDER_NUMBER_LENGTH_ERROR_MSG, (number) => {
        return !!number?.length && number?.length >= 9 && number?.length <= 13;
      });
  },
  confirmTransponderNumber: (compareFieldName: string): StringSchema => {
    return yup.string().oneOf([yup.ref(compareFieldName), null], TRANSPONDER_NUMBER_MATCH_ERROR_MSG);
  },
  date: (invalidMessage: string): RequiredDateSchema<any, any> => {
    return yup.date().typeError(invalidMessage).required(invalidMessage);
  },
  minDate: (minDateFieldName: string, invalidMessage: string): BaseSchema => {
    return validators.date(invalidMessage).min(yup.ref(minDateFieldName), MIN_DATE_ERROR_MSG);
  },
  fein: yup
    .string()
    .transform((currentValue: string) => {
      return currentValue.replaceAll(/-/g, '');
    })
    .trim()
    .minNumbers(9, FEIN_LENGTH_ERROR_MSG)
    .length(9, FEIN_NUMBER_ERROR_MSG),
  confirm: (compareFieldName: string, message: string): StringSchema => {
    return yup.string().oneOf([yup.ref(compareFieldName), null], i18n._(message));
  },
  accountNumber: (requiredErrMsg = ACCOUNT_NUMBER_REQUIRED) => {
    return yup
      .string()
      .trim()
      .required(requiredErrMsg)
      .matches(/^[0-9]*$/, ACCOUNT_NUMBER_INVALID_ERROR_MSG)
      .min(1, ACCOUNT_NUMBER_REQUIRED)
      .max(10, ACCOUNT_NUMBER_REQUIRED);
  },
  accountNumberOptional: yup
    .string()
    .trim()
    .min(1, ACCOUNT_NUMBER_REQUIRED)
    .max(10, ACCOUNT_NUMBER_REQUIRED)
    .matches(/^[0-9]*$/, ACCOUNT_NUMBER_INVALID_ERROR_MSG),

  securityCode: yup
    .string()
    .required(SECURITY_CODE_REQUIRED)
    .min(3, SECURITY_CODE_MINIMUM)
    .matches(/^[0-9]*$/, SECURITY_CODE_NUMBERS_ONLY),
};

export const validateNonNegativeNumber = (value: number | string, defaultValue: number | string): number => {
  if (!value || isNaN(+value) || +value < 0) {
    return +defaultValue;
  }
  return +value;
};

import ConfirmationModal from './ConfirmationModal';
import { Trans } from '@lingui/macro';

interface IPinellasBaywayModalProps {
  showModal: boolean;
  onOk: () => void;
}
export const PinellasBaywayModal = ({ showModal, onOk }: IPinellasBaywayModalProps) => {
  return (
    <ConfirmationModal
      title={<Trans>Travel the Pinellas Bayway Often?</Trans>}
      content={
        <>
          <div>
            <Trans>
              The $50 yearly Commuter Pass transponder, which allows two-axle vehicles unlimited passage through all
              three Pinellas Bayway toll plazas without stopping, expires every year on Sept. 30, regardless of date of
              purchase.
            </Trans>
          </div>
          <div className="pinellas-modal mt-3 px-5 text-left">
            <ul>
              <li>
                <Trans>Cost of the plan is $50 per year, per transponder.</Trans>
              </li>
              <li>
                <Trans>Annual Commuter Pass is valid from October 1 - September 30.</Trans>
              </li>
              <li>
                <Trans>Please note: Plan will expire on September 30 regardless of when it is purchased.</Trans>
              </li>
              <li>
                <Trans>
                  If you are enrolled in Easy Pay, the plan will automatically renew each year in accordance with the
                  Terms and Conditions.
                </Trans>
              </li>
              <li>
                <Trans>
                  Pass is valid at all three Pinellas Bayway Toll Plazas - Eastern Plaza (SR 682), Western Plaza (SR
                  682), and (SR 679).
                </Trans>
              </li>
              <li>
                <Trans>
                  The SunPass transponder will function at all toll facilities in accordance with the prepaid balance
                  Terms and Conditions.
                </Trans>
              </li>
            </ul>
          </div>
        </>
      }
      onOk={onOk}
      okButtonText="Close"
      showModal={showModal}
      alignText="center"
    />
  );
};

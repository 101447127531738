import { gql } from '@apollo/client';

export const LOOKUP_WITH_INVOICE = gql`
  query lookupWithInvoice($lookupWithInvoiceInput: LookupWithInvoiceInput!) {
    lookupWithInvoice(lookupWithInvoiceInput: $lookupWithInvoiceInput) {
      jwt {
        token
        refreshToken
      }
    }
  }
`;

export const LOOKUP_WITHOUT_INVOICE = gql`
  query lookupWithoutInvoice($lookupWithoutInvoiceInput: LookupWithoutInvoiceInput!) {
    lookupWithoutInvoice(lookupWithoutInvoiceInput: $lookupWithoutInvoiceInput) {
      amountDue
      jwt {
        token
        refreshToken
      }
    }
  }
`;

export const INVOICE_PAYMENT = gql`
  mutation invoicePayment($invoicePaymentInput: InvoicePaymentInput!) {
    invoicePayment(invoicePaymentInput: $invoicePaymentInput) {
      emailMessage
      emailStatusCode
      transactionId
    }
  }
`;

export const GET_INVOICE_DETAILS = gql`
  query invoiceDetail {
    invoiceDetail {
      CSZ
      accountNumber
      addressLine1
      addressLine2
      amountDueOnTheAccount
      amountPaid
      checkState
      city
      closingBalance
      collectionAmount
      country
      discountedTotal
      eZPassDiscount
      etcAccountId
      invoiceDate
      invoiceDueDate
      invoiceLoginType
      invoiceNumber
      invoicePeriod
      invoiceTotal
      isCollectionAmountClick
      isEZPassEligible
      isNixieFlag
      isNoDue
      isPlateEligible
      isReturnAckDate
      isShowViolLink
      jwt {
        token
        refreshToken
      }
      lastPayDate
      licensePlate
      mailDate
      newTolls
      numOfItems
      numOfItemsUTC
      openAccountForChildAccount
      otherCharges
      paymentDisabled
      paymentOtherAmount
      payments
      previousBalance
      privateDiscountAmt
      privateVideoDiscountAmt
      resultInvoiceDetails {
        checkState
        credits
        discountAmount
        ezPassRate
        invoiceDate
        invoiceDueDate
        invoiceId
        invoiceNumber
        invoiceNumberDsp
        mailDate
        newTolls
        newTollsUnformatted
        otherCharges
        payments
        pdfFileName
        previousBalance
        totalAmountDue
        totalAmountDueUnformatted
        totalCharges
      }
      state
      status
      totalAmountDue
      totalAmountDueUnFormatted
      totalCharges
      totalLateFee
      totalOutstandingAmount
      totalOutstandingAmountUnFormatted
      unBilledActivity
      vehicleOwner
      zipcode
    }
  }
`;

export interface ILookupWithInvoiceInput {
  invoiceNumber: string;
  licensePlate: string;
}
export interface ILookupWithInvoiceOutput {
  jwt: IAuthToken;
}

export interface IAuthToken {
  token: string;
  refreshToken: string;
}

export interface ILookupWithoutInvoiceInput {
  licensePlate: string;
  zipcode: string;
}

export interface ILookupWithoutInvoiceOutput {
  amountDue: number;
  jwt: IAuthToken;
}

export interface IInvoicePaymentOutput {
  emailMessage: string;
  emailStatusCode: string;
  transactionId: string;
}

export interface IInvoicePaymentInput {
  invoiceNumber: string;
  licensePlate: string;
  licenseState: string;
  paymentAmount: string;
  email: string;
  cellPhone: string;
  paymentMethodId: string;
  payType: string;
  bankRoutingNumber: string;
  bankAccountNumber: string;
  cardNumber: string;
  expirationMonth: number;
  expirationYear: number;
  securityCode: string;
  firstName: string;
  middleName: string;
  lastName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  zipcode: string;
  zipcodePlus: string;
  easyPayOption: string;
}

export interface IInvoiceDetailOutput {
  CSZ: string;
  accountNumber: string;
  addressLine1: string;
  addressLine2: string;
  amountDueOnTheAccount: number;
  amountPaid: string;
  checkState: number;
  city: string;
  closingBalance: number;
  collectionAmount: number;
  country: string;
  discountedTotal: number;
  eZPassDiscount: number;
  etcAccountId: string;
  invoiceDate: string;
  invoiceDueDate: string;
  invoiceLoginType: string;
  invoiceNumber: string;
  invoicePeriod: string;
  invoiceTotal: number;
  isCollectionAmountClick: boolean;
  isEZPassEligible: boolean;
  isNixieFlag: boolean;
  isNoDue: boolean;
  isPlateEligible: boolean;
  isReturnAckDate: boolean;
  isShowViolLink: boolean;
  jwt: IAuthToken;
  lastPayDate: string;
  licensePlate: string;
  mailDate: string;
  newTolls: number;
  numOfItems: number;
  numOfItemsUTC: number;
  openAccountForChildAccount: boolean;
  otherCharges: number;
  paymentDisabled: boolean;
  paymentOtherAmount: number;
  payments: number;
  previousBalance: number;
  privateDiscountAmt: number;
  privateVideoDiscountAmt: number;
  resultInvoiceDetails: IResultInvoiceDetail[];
  state: string;
  status: string;
  totalAmountDue: number;
  totalAmountDueUnFormatted: number;
  totalCharges: number;
  totalLateFee: number;
  totalOutstandingAmount: number;
  totalOutstandingAmountUnFormatted: number;
  unBilledActivity: number;
  vehicleOwner: string;
  zipcode: string;
}

export interface IResultInvoiceDetail {
  checkState: number;
  credits: number;
  discountAmount: number;
  ezPassRate: number;
  invoiceDate: string;
  invoiceDueDate: string;
  invoiceId: string;
  invoiceNumber: string;
  invoiceNumberDsp: string;
  mailDate: string;
  newTolls: number;
  newTollsUnformatted: number;
  otherCharges: number;
  payments: number;
  pdfFileName: string;
  previousBalance: number;
  totalAmountDue: number;
  totalAmountDueUnformatted: number;
  totalCharges: number;
}

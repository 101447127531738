import { yupResolver } from '@hookform/resolvers/yup';
import { t } from '@lingui/macro';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useRouteMonitor } from 'src/hooks/location/useRouteMonitor';
import {
  AccountSubtype,
  AccountType,
  getStepsByUserTypeAndFlowType,
  IOnboardingTransponders,
  OnboardingUserType,
} from 'src/types/onboarding.types';
import '../../../pages/Onboarding/Onboarding.scss';
import ScrollToTop from 'src/components/ScrollToTop/ScrollToTop';
import OnboardingHeader, { OnboardingHeaderWholeViewProps } from '../OnboardingHeader';
import BackButton from '../../BackButton/BackButton';
import { useOnboardingStore } from '../../../store/onboarding/onboarding.store';
import { AccountCreatedSuccessfullyModal } from '../AccountCreatedSuccessfullyModal';
import { MainLoader } from '../../Loader/MainLoader';
import { useScroll } from '../../../hooks/screen/useScroll';
import {
  onboardingTranspondersActivationValidator,
  onboardingTranspondersPurchaseValidator,
} from '../../../validators/onboarding/transponders/transponders.validator';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import { concatRoutes, OnboardingStepPath } from '../../../types/routes';
import { ReferenceNumber } from '../ReferenceNumber';
import TransponderActivationRequiredModal from './TransponderActivationRequiredModal';
import OnboardingPurchaseTransponders from './OnboardingPurchaseTransponders';
import OnboardingActivateTransponders from './OnboardingActivateTransponders';
import { useOnboardingQuestion } from '../Questions/useOnboardingQuestion';
import NoPinellasWarningModal from './NoPinellasWarningModal';
import { Transponder } from 'src/types/transponder';
import {
  INVALID_TRANSPONDERS_ERROR,
  NO_TRANSPONDERS_ERROR,
  REGISTERED_TRANSPONDERS_ERROR,
} from 'src/components/Dashboard/Transponders/transpondersVariables';
import { SELECT_RECOMMENDED_SUNPASS_PLAN_NAME } from '../../../store/onboarding/onboarding.selectors';
import { useCallbackPrompt } from 'src/hooks/location/useCallbackPrompt';
import LeaveSiteConfirmationModal from 'src/components/Modals/ConfirmationModal/LeaveSiteConfirmationModal';
import { generateLimitMessage } from 'src/components/Dashboard/Transponders/transponders.utils';
import { useOnboardingErrors } from 'src/store/errors/onboardingErrors.store';
import { ActivateCalls } from '../ActivateFlow/ActivateCalls';

export default function OnboardingTransponders(): React.ReactElement {
  const {
    onboardingInfo: {
      userType,
      hasTranspondersToActivate,
      miniTransponderQty,
      miniTransponderQtyAssignedToPlan,
      proTransponderQty,
      proTransponderQtyAssignedToPlan,
      transpondersToActivate,
      accountType,
      accountSubtype,
      onboardingFlowType,
    },
    updateOnboardingStore,
  } = useOnboardingStore();

  const history = useRouteMonitor();
  const [showRedirectPrompt, setShowRedirectPrompt] = useState(false);
  const { cancelRedirect, confirmRedirect, showPrompt } = useCallbackPrompt(showRedirectPrompt);
  const [isTryingToProceed, setisTryingToProceed] = useState(false);
  const planName = SELECT_RECOMMENDED_SUNPASS_PLAN_NAME(accountType, accountSubtype);
  const [plansAddedToTranspondersInActivateFlow, setPlansAddedToTranspondersInActivateFlow] = useState<number>(1);
  const { validateTranponders, activationTranspondersLoading } = ActivateCalls(plansAddedToTranspondersInActivateFlow);
  const [activationPanelCollapsed, setActivationPanelCollapsed] = useState(false);
  const [purchasePanelCollapsed, setPurchasePanelCollapsed] = useState(false);
  const [showNoPinellasWarningModal, setShowNoPinellasWarningModal] = useState(false);
  const steps = onboardingFlowType && userType ? getStepsByUserTypeAndFlowType(userType, onboardingFlowType) : [];
  const isPrivateAccountWithPBC = accountType === AccountType.PRIVATE && accountSubtype === AccountSubtype.PBC;
  const { setOnboardingState, canProceedToNextStep, onboardingError } = useOnboardingErrors();
  const methods = useForm<IOnboardingTransponders>({
    resolver: yupResolver(
      hasTranspondersToActivate ? onboardingTranspondersActivationValidator : onboardingTranspondersPurchaseValidator,
    ),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      hasTranspondersToActivate,
      transpondersToActivate,
      miniTransponderQty: isPrivateAccountWithPBC ? miniTransponderQty || 1 : miniTransponderQty || 0,
      miniTransponderQtyAssignedToPlan: miniTransponderQtyAssignedToPlan || 0,
      proTransponderQty: proTransponderQty || 0,
      proTransponderQtyAssignedToPlan: proTransponderQtyAssignedToPlan || 0,
    },
  });

  const {
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isValid, isSubmitted, isSubmitSuccessful, isDirty },
  } = methods;

  const { onSubmit: submitActivatingTransponders } = useOnboardingQuestion<OnboardingUserType>({});

  const confirmNoPinellasPlan = (data: IOnboardingTransponders, accountSubtype?: AccountSubtype) => {
    submitActivatingTransponders();

    updateOnboardingStore({
      transpondersToActivate: data.transpondersToActivate,
      accountSubtype: accountSubtype || AccountSubtype.NONE,
    });
  };

  useEffect(() => {
    if (miniTransponderQty > 0 || proTransponderQty > 0) {
      setShowWarningMessage(false);
    }
  }, [miniTransponderQty, proTransponderQty]);

  const hasPinellaPlan = accountSubtype === AccountSubtype.PBC && miniTransponderQtyAssignedToPlan > 0;

  useEffect(() => {
    setShowRedirectPrompt(isDirty);
  }, [isDirty]);
  const proceedToTheNextStep = async (data: IOnboardingTransponders) => {
    if (limitErrorMessage) return;

    let transpondersAddedToPinellas: boolean;
    if (hasTranspondersToActivate) {
      transpondersAddedToPinellas = data.transpondersToActivate.some((item: Transponder) => item.pinellas);
      if ((hasPinellaPlan && transpondersAddedToPinellas) || !hasPinellaPlan) {
        confirmNoPinellasPlan(data, accountSubtype);
      }
      return;
    }
    if (
      !data.miniTransponderQty &&
      !data.miniTransponderQtyAssignedToPlan &&
      !data.proTransponderQty &&
      !data.proTransponderQtyAssignedToPlan
    ) {
      setShowWarningMessage(true);
    } else {
      setFormSubmitted(true);
      const activations = data.hasTranspondersToActivate ? [...data.transpondersToActivate] : [];
      updateOnboardingStore({
        accountSubtype: showNoPinellasWarningModal ? AccountSubtype.NONE : accountSubtype,
        miniTransponderQty: data.miniTransponderQty,
        miniTransponderQtyAssignedToPlan: data.miniTransponderQtyAssignedToPlan,
        proTransponderQty: data.proTransponderQty,
        proTransponderQtyAssignedToPlan: data.proTransponderQtyAssignedToPlan,
        ...(data?.hasTranspondersToActivate && {
          hasTranspondersToActivate: data.hasTranspondersToActivate,
          transpondersToActivate: activations,
        }),
        planName: planName,
      });
    }
  };

  const onSubmit = (data: IOnboardingTransponders) => {
    const tranponderNumbers = data.transpondersToActivate.map((transponder: Transponder) => transponder.number);
    if (hasTranspondersToActivate) {
      setOnboardingState(undefined, tranponderNumbers, undefined);
      validateTranponders(tranponderNumbers);
      return;
    }

    const hasUserPlanAddedToTransponder =
      proQtyAssignedToPlan === 0 && miniQtyAssignedToPlan === 0 && (miniQty !== 0 || proQty !== 0);

    if (hasUserPlanAddedToTransponder && accountSubtype === AccountSubtype.PBC) {
      setShowNoPinellasWarningModal(true);
    } else {
      proceedToTheNextStep(data);
      setShowNoPinellasWarningModal(false);
    }
  };

  const proQty = watch('proTransponderQty') || 0;
  const proQtyAssignedToPlan = watch('proTransponderQtyAssignedToPlan') || 0;
  const miniQty = watch('miniTransponderQty') || 0;
  const miniQtyAssignedToPlan = watch('miniTransponderQtyAssignedToPlan') || 0;

  useEffect(() => {
    if (isPrivateAccountWithPBC) {
      setValue('miniTransponderQtyAssignedToPlan', miniTransponderQty || 1);
    }
  }, []);

  useEffect(() => {
    updateOnboardingStore({
      miniTransponderQty: miniQty,
      miniTransponderQtyAssignedToPlan: miniQtyAssignedToPlan,
      proTransponderQty: proQty,
      proTransponderQtyAssignedToPlan: proQtyAssignedToPlan,
    });
  }, [miniQty, miniQtyAssignedToPlan, proQty, proQtyAssignedToPlan]);

  const [showWarningMessage, setShowWarningMessage] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');

  useEffect(() => {
    if (!isValid && isSubmitted) {
      setPurchasePanelCollapsed(false);
    }
  }, [isValid, isSubmitted, errors]);

  useEffect(() => {
    if (
      (isSubmitted && isSubmitSuccessful && formSubmitted && hasTranspondersToActivate === false) ||
      (canProceedToNextStep && isSubmitted)
    ) {
      setLoading(false);
      setShowWarningMessage(false);
      history.goToPath(concatRoutes(OnboardingStepPath.VEHICLES));
    } else if (!canProceedToNextStep && isSubmitted) {
      if (onboardingError?.errorByType) {
        onboardingError?.errorByType?.transponderNumber
          ? setWarningMessage(INVALID_TRANSPONDERS_ERROR)
          : setWarningMessage(REGISTERED_TRANSPONDERS_ERROR);
      }
      proQty === 0 && miniQty === 0 && setShowWarningMessage(true);
    }
  }, [isSubmitted, isSubmitSuccessful, formSubmitted, canProceedToNextStep, onboardingError]);

  const { scrollToError } = useScroll();

  useEffect(() => {
    scrollToError(errors);
  }, [errors]);

  const [showErrorMini, setShowErrorMini] = useState(false);
  const [showErrorPro, setShowErrorPro] = useState(false);
  useEffect(() => {
    if (proQty < proQtyAssignedToPlan) {
      setShowErrorPro(true);
    } else {
      setShowErrorPro(false);
    }
    if (miniQty < miniQtyAssignedToPlan) {
      setShowErrorMini(true);
    } else {
      setShowErrorMini(false);
    }
  }, [proQtyAssignedToPlan, miniQtyAssignedToPlan, proQty, miniQty]);

  const limitErrorMessage = generateLimitMessage(miniQty, proQty, accountType || AccountType.PRIVATE);

  return (
    <div className="onboarding-transponders h-full">
      <ScrollToTop />
      <MainLoader loading={loading || activationTranspondersLoading} />
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <OnboardingHeader<OnboardingHeaderWholeViewProps>
            title={t`Need to ${onboardingFlowType === 'PREPAID' ? 'activate' : 'purchase'} any transponders?`}
            steps={steps}
            currentStepIndex={2}
            planName={planName}
          />
          <ReferenceNumber />
          {hasTranspondersToActivate ? (
            <OnboardingActivateTransponders
              activationPanelCollapsed={activationPanelCollapsed}
              setActivationPanelCollapsed={setActivationPanelCollapsed}
              setPlansAddedToTranspondersInActivateFlow={setPlansAddedToTranspondersInActivateFlow}
              plansAddedToTranspondersInActivateFlow={plansAddedToTranspondersInActivateFlow}
            />
          ) : (
            <OnboardingPurchaseTransponders
              purchasePanelCollapsed={purchasePanelCollapsed}
              setPurchasePanelCollapsed={setPurchasePanelCollapsed}
              showErrorMini={showErrorMini}
              showErrorPro={showErrorPro}
            />
          )}
          {limitErrorMessage && isTryingToProceed ? (
            <div className="text-red mt-3 text-center">{limitErrorMessage}</div>
          ) : null}
          {!hasTranspondersToActivate && showWarningMessage && accountType !== AccountType.COMMERCIAL && (
            <div className="text-red mt-3 text-center">{NO_TRANSPONDERS_ERROR}</div>
          )}
          {hasTranspondersToActivate && showWarningMessage && (
            <div className="text-red mt-3 text-center">{warningMessage}</div>
          )}
          <div className="onboarding-button-container">
            <BackButton content="Back" onBack={() => history.goBack()} blockRedirectTill={true} />
            <CustomButton
              content="Continue"
              type="submit"
              onClick={() => {
                setisTryingToProceed(true);
                setShowRedirectPrompt(false);
                handleSubmit(onSubmit);
              }}
            />
          </div>{' '}
          <AccountCreatedSuccessfullyModal />
          <TransponderActivationRequiredModal />
          {!hasPinellaPlan && (
            <NoPinellasWarningModal
              showModal={showNoPinellasWarningModal}
              onCancel={() => setShowNoPinellasWarningModal(false)}
              onOk={handleSubmit((data) => {
                proceedToTheNextStep(data).then(() => setShowNoPinellasWarningModal(false));
              })}
              okButtonText="Proceed without Pinellas Bayway Plan"
              cancelButtonText="Go Back"
            />
          )}
        </form>
      </FormProvider>
      <LeaveSiteConfirmationModal showModal={showPrompt} onOk={confirmRedirect} onCancel={cancelRedirect} />
    </div>
  );
}

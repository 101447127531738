import { t, Trans } from '@lingui/macro';
import { useFormContext } from 'react-hook-form';
import { AccountType, IAccountInfo } from '../../../types/onboarding.types';
import CollapsableContainer from '../../CollapsableContainer/CollapsableContainer';
import React, { useEffect, useState } from 'react';
import { EmailAddresses } from './EmailAddresses';
import { FleetGovernmentAdditionalInfo } from './FleetGovernmentAdditionalInfo';
import { PhonesInfo } from './PhonesInfo';
import MonthlyStatement from '../../Inputs/MonthlyStatement';
import Language from '../../Inputs/Language';
import MessageNotifications from '../../Inputs/MessageNotifications';
import { useOnboardingStore } from '../../../store/onboarding/onboarding.store';
import { LastName } from '../../atoms/form/account/LastName';
import { MiddleName } from '../../atoms/form/account/MiddleName';
import { FirstName } from '../../atoms/form/account/FirstName';

export const OnboardingAccountHolderInfo = () => {
  const [accountInfoCollapsed, setAccountInfoCollapsed] = useState(false);

  const {
    formState: { errors, isValid, isSubmitted },
  } = useFormContext<IAccountInfo>();

  useEffect(() => {
    if (!isValid && isSubmitted) {
      setAccountInfoCollapsed(false);
    }
  }, [errors, isSubmitted, isValid]);

  const {
    onboardingInfo: { accountType },
  } = useOnboardingStore();

  return (
    <>
      <CollapsableContainer
        title={t`Account Holder Information`}
        onChange={(panels: any) => setAccountInfoCollapsed(!panels[0])}
        activeKey={accountInfoCollapsed ? [] : ['1']}
        noTopMargin
      >
        <div className="mt-5 flex flex-wrap">
          <FirstName />
          <MiddleName />
          <LastName />
        </div>

        <EmailAddresses />
        <FleetGovernmentAdditionalInfo />
        <PhonesInfo />

        {accountType !== AccountType.GOVERNMENT ? (
          <div className="message-notifications mt-5 flex flex-wrap">
            <MessageNotifications />
          </div>
        ) : null}
        {accountType !== AccountType.GOVERNMENT ? (
          <div className="flex flex-wrap">
            <div className="w-full sm:w-6/12 sm:pr-1">
              <MonthlyStatement label={<Trans>Statement Delivery Method</Trans>} required />
            </div>
            <div className="w-full sm:w-6/12 sm:pl-1">
              <Language label={<Trans>Preferred Statement Language</Trans>} required />
            </div>
          </div>
        ) : null}
      </CollapsableContainer>
    </>
  );
};

import React, { FC } from 'react';
import { t } from '@lingui/macro';
import { IPaymentMethodGraphQl } from 'src/graphql/paymentMethods.graphql';
import { ITransponder } from 'src/graphql/transponders.graphql';
import { IVehicle } from 'src/graphql/vehicles.graphql';
import { concatRoutes, DashboardParams, MainRoutes } from 'src/types/routes';
import ActionItem from './ActionItem';
import { ActionItemAndIconLinkCardId } from './ActionItemAndIconLinkCardId';

interface IItemsFleet {
  vehicles: IVehicle[] | undefined;
  paymentMethods: IPaymentMethodGraphQl[] | undefined;
  transponderListCount: number | undefined;
  activatedTransponders: false | ITransponder[];
}

const ItemsFleet: FC<IItemsFleet> = ({ vehicles, paymentMethods, transponderListCount, activatedTransponders }) => {
  return (
    <div className="action-items grid grid-cols-1 grid-rows-4 gap-0 md:gap-1 lg:grid-cols-2 lg:grid-rows-2">
      <ActionItem
        id={ActionItemAndIconLinkCardId.ADD_VEHICLE}
        title={t`Add Vehicle Information`}
        autoHeight={true}
        selected={!!vehicles}
        link={concatRoutes(MainRoutes.DASHBOARD, '/', DashboardParams.VEHICLES)}
      />
      <ActionItem
        id={ActionItemAndIconLinkCardId.ADD_PAYMENT_METHOD}
        title={t`Add Payment Method`}
        autoHeight={true}
        selected={!!paymentMethods && paymentMethods?.length != 0}
        link={concatRoutes(MainRoutes.DASHBOARD, '/', DashboardParams.WALLET)}
      />
      <ActionItem
        id={ActionItemAndIconLinkCardId.ORDER_TRANSPONDER}
        title={t`Order Transponder`}
        autoHeight={true}
        selected={transponderListCount !== 0 && transponderListCount !== null}
        link={concatRoutes(MainRoutes.DASHBOARD, '/', DashboardParams.TRANSPONDERS)}
      />
      <ActionItem
        id={ActionItemAndIconLinkCardId.ACTIVATE_TRANSPONDER}
        title={t`Activate Transponder`}
        autoHeight={true}
        selected={!!activatedTransponders && activatedTransponders.length !== 0}
        link={concatRoutes(MainRoutes.DASHBOARD, '/', DashboardParams.TRANSPONDERS)}
      />
    </div>
  );
};

export default ItemsFleet;

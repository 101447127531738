import { Form } from 'antd';
import { Trans } from '@lingui/macro';
import { Controller, useFormContext } from 'react-hook-form';
import StyledInput from '../../../../StyledInput/StyledInput';
import React from 'react';
import { ITransactionActivitySearch } from '../ActivitySearch';
import { useQuery } from '@apollo/client';
import { GET_ACCOUNT_DETAILS, IAccountDetails } from '../../../../../graphql/accountDetails.graphql';
import { AccountType } from '../../../../../types/onboarding.types';
import { usePlans } from '../../../MyDashboard/usePlans';

export const TransponderPlateSearchInput = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<ITransactionActivitySearch>();

  const { data: accountData } = useQuery<{ accountDetails: IAccountDetails }>(GET_ACCOUNT_DETAILS);
  const accountType = accountData?.accountDetails.accountType;

  const { PAYGAccount } = usePlans();
  const isPAYGAccount = accountType === AccountType.PRIVATE && PAYGAccount;

  return (
    <div className="w-full sm:pr-4 md:w-3/12 lg:w-6/12">
      <Form.Item
        id="transponderOrPlateNumber"
        className="theme"
        label={
          !isPAYGAccount ? <Trans>Search by transponder or plate number</Trans> : <Trans>Search by plate number</Trans>
        }
        htmlFor="transponderOrPlateNumber"
        validateStatus={errors.transponderOrPlateNumber ? 'error' : ''}
        help={errors.transponderOrPlateNumber ? errors.transponderOrPlateNumber.message : ''}
        validateTrigger={['onChange']}
      >
        <Controller
          control={control}
          name="transponderOrPlateNumber"
          render={({ field, fieldState }) => (
            <StyledInput
              field={field}
              fieldstate={fieldState}
              id="transponderOrPlateNumber"
              name="transponderOrPlateNumber"
              maxLength={13}
            />
          )}
        />
      </Form.Item>
    </div>
  );
};

import React, { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FL_STATE_CODE } from '../../utils/countryStates.utils';
import { useAddressFields } from '../../hooks/domain/address/useAddressFields';
import { useStateTriggerToDefault } from 'src/hooks/domain/address/useStateTriggerToDefault';
import { useNonInitialRender } from '../../hooks/effect/useNonInitialRender';
import { FirstAddress } from '../atoms/form/address/FirstAddress';
import { SecondAddress } from '../atoms/form/address/SecondAddress';
import { City } from '../atoms/form/address/City';
import { PostalCode } from '../atoms/form/address/PostalCode';
import { Country } from '../atoms/form/address/Country';
import { State } from '../atoms/form/address/State';
import { County } from '../atoms/form/address/County';
import { useScroll } from '../../hooks/screen/useScroll';
import { ADDRESS_VALIDATION_GENERAL_ERROR } from '../Dashboard/references';
import useErrorHandling from '../../hooks/errors/useErrorHandling';
import { ERROR_CODE } from '../../types/errors.enum';

interface IAddressProps {
  countyRequired: boolean;
  disabled?: boolean;
  isAddressUsedForPayment?: boolean;
}

const CustomAddress: FC<IAddressProps> = ({ countyRequired, disabled, isAddressUsedForPayment }) => {
  const {
    addressFieldsState: { countryField, stateField, countyField },
  } = useAddressFields(isAddressUsedForPayment);

  const { watch, resetField, trigger, setValue } = useFormContext();

  const countryWatch = watch(countryField.name);
  const stateWatch = watch(stateField.name);

  useNonInitialRender(() => {
    if (stateWatch === FL_STATE_CODE) {
      resetField(countyField.name, { defaultValue: '' });
    }
  }, [stateWatch]);

  useStateTriggerToDefault(countryField.name, stateField.name);

  useEffect(() => {
    trigger(stateField.name);
  }, [countryWatch]);

  const addressComponentsProps: {
    isAddressUsedForPayment?: boolean;
    disabled?: boolean;
  } = { disabled, isAddressUsedForPayment };

  useEffect(() => {
    if (stateWatch !== FL_STATE_CODE) {
      setValue('county', undefined);
    }
  }, [stateWatch]);

  const { scrollToElem } = useScroll();
  const { error } = useErrorHandling();

  useEffect(() => {
    error?.type === 'addressValidation' &&
      error?.code !== ERROR_CODE.INVALID_POSTAL_CODE &&
      setTimeout(() => scrollToElem(ADDRESS_VALIDATION_GENERAL_ERROR), 100);
  }, [error, error?.code, error?.type]);

  return (
    <React.Fragment>
      <div className="flex flex-wrap">
        <FirstAddress {...addressComponentsProps} className="w-full sm:w-6/12 sm:pr-1" />
        <SecondAddress {...addressComponentsProps} className="w-full sm:w-6/12 sm:pl-1" />
      </div>
      <div className="flex flex-wrap">
        <City {...addressComponentsProps} className="w-full sm:w-6/12 sm:pr-1" />
        <PostalCode {...addressComponentsProps} className="w-full sm:w-6/12 sm:pl-1" />
      </div>
      <div className="flex flex-wrap">
        <Country {...addressComponentsProps} className="w-full sm:w-6/12 sm:pr-1" />
        <State {...addressComponentsProps} className="w-full sm:w-6/12 sm:pl-1" />
      </div>

      {countyRequired && (
        <div className="flex flex-wrap">
          <County
            isAddressUsedForPayment={!!isAddressUsedForPayment}
            disabled={!!disabled}
            className="w-full sm:w-6/12 sm:pr-1"
          />
        </div>
      )}
      {error?.type === 'addressValidation' && error?.code !== ERROR_CODE.INVALID_POSTAL_CODE ? (
        <p id={ADDRESS_VALIDATION_GENERAL_ERROR} className="mt-5 text-alerts-red">
          {error?.message}
        </p>
      ) : null}
    </React.Fragment>
  );
};
export default CustomAddress;

import { useApolloClient, useMutation } from '@apollo/client';
import { ILogoutInput, LOGOUT_USER } from '../../graphql/logoutUser.graphql';
import { MainRoutes } from '../../types/routes';
import { useAuth } from '../../store/authentication/authentication.store';
import { useNavigate } from 'react-router-dom';
import { getAccessToken, getRefreshToken } from '../../security/token.service';
import { useIdleTimer } from 'react-idle-timer';
import { useContext, useEffect } from 'react';
import { NotificationContext } from '../../context/NotificationContext';
import { resetAllStores } from 'src/store/createStore';

interface IUseLogout {
  logout: (onCloseAccount?: boolean) => Promise<void>;
  loading: boolean;
  clearSession: (route?: MainRoutes) => Promise<void>;
}

export const useLogout = (): IUseLogout => {
  const {
    invalidateAuthentication,
    details: { isAuthenticated },
  } = useAuth();

  const client = useApolloClient();
  const notificationCtx = useContext(NotificationContext);
  const { clear } = notificationCtx || {};
  const history = useNavigate();

  const clearSession = async (route = MainRoutes.ROOT) => {
    await client.cache.reset();
    await client.clearStore();
    resetAllStores();
    await invalidateAuthentication(route === MainRoutes.ACCOUNT_CLOSURE);
    history(route);
  };

  const [logoutUser, { loading }] = useMutation<{ logoutUser: boolean }, { logoutInput: ILogoutInput }>(LOGOUT_USER, {
    async onError() {
      await clearSession();
    },
  });

  const logout = async (onCloseAccount = false): Promise<void> => {
    try {
      await logoutUser({
        variables: { logoutInput: { token: getAccessToken(), refreshToken: getRefreshToken() } },
      });
      clear && clear();
      onCloseAccount ? await clearSession(MainRoutes.ACCOUNT_CLOSURE) : await clearSession();
    } catch (err) {
      console.log(err);
    }
  };

  const { start } = useIdleTimer({
    onIdle: logout,
    timeout: 1800000,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
    startManually: true,
  });

  useEffect(() => {
    isAuthenticated && start();
  }, [isAuthenticated]);

  return {
    logout,
    loading,
    clearSession,
  };
};

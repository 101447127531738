import { gql } from '@apollo/client';
import { EasyPayOption } from './payment.graphql';
import { IUserNames } from './signUp/signUpDetails.graphql';
export const ORDER_TRANSPONDER = gql`
  mutation orderTransponder($orderTransponderInput: OrderTransponderInput!) {
    orderTransponder(orderTransponderInput: $orderTransponderInput) {
      tax
      amountDue
    }
  }
`;
export const PURCHASE_TRANSPONDER = gql`
  mutation purchaseTransponder($purchaseTransponderInput: PurchaseTransponderInput!) {
    purchaseTransponder(purchaseTransponderInput: $purchaseTransponderInput) {
      referenceNumber
      transactionId
      authCode
      emailStatusCode
      emailMessage
    }
  }
`;

export interface IOrderTransponderInput {
  numOfProTransponders: number;
  numOfStickerTransponders: number;
}

export interface IOrderTransponderOutput {
  amountDue: number;
  tax: number;
}

export interface IPurchaseTransponderInput extends IUserNames {
  paymentAmount: number;
  paymentMethodId?: string;
  bankRoutingNumber?: string;
  bankAccountNumber?: string;
  promoCode?: string;
  cardType?: string;
  cardNumber?: string;
  expirationMonth?: number;
  expirationYear?: number;
  securityCode?: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  easyPayOption?: EasyPayOption;
  additionalFundAmount?: number;
  numOfProTransponders: number;
  numOfStickerTransponders: number;
}

export interface IPurchaseTransponderOutput {
  referenceNumber: string;
  transactionId: string;
  authCode: string;
  emailStatusCode: string;
  emailMessage: string;
}

import React from 'react';
import { formatDate } from '../../../../utils/time.utils';
import { IAccountDetails } from '../../../../graphql/accountDetails.graphql';
import { t, Trans } from '@lingui/macro';
import moment from 'moment';
import { convertPriceToString } from '../../../../utils/formatter';

export interface IRightCardProps {
  account: IAccountDetails | undefined;
}

export const BalanceSumarryCard = ({ account }: IRightCardProps) => {
  const getCurrentTime = () => {
    return moment().format('MM/DD/YYYY LT');
  };

  const { currentBalance, tollBalance, lastReplenished, replenishThreshold } = account || {};
  const formattedLastReplenished = lastReplenished ? formatDate(lastReplenished, true) : '';

  const formattedCurrentBalance = convertPriceToString(currentBalance || 0);

  const formattedTollBalance = convertPriceToString(tollBalance || 0);

  const formattedReplenishThreshold = convertPriceToString(replenishThreshold || 0);

  return (
    <div className="balance-card-container balance-summary-card">
      <span className="mb-2 text-primary-orange1 ">{t`UPDATED ${getCurrentTime()}`}</span>
      <span className="balance-summary font-bold">
        <Trans>Balance Summary</Trans>
      </span>
      <div className="row">
        <span>
          <Trans>Prepaid Balance</Trans>
        </span>
        <span>{formattedTollBalance}</span>
      </div>
      <div className="row">
        <span>
          <Trans>Replenishment Threshold</Trans>
        </span>
        <span>{formattedReplenishThreshold}</span>
      </div>
      <div className="row">
        <span>
          <Trans>Last Replenished</Trans>
        </span>
        <span>{formattedLastReplenished}</span>
      </div>
      <div className="summary mt-auto border-t border-gray-300 pt-4 font-medium">
        <span>
          <Trans>Current Balance</Trans>
        </span>
        <span>{formattedCurrentBalance}</span>
      </div>
    </div>
  );
};

import { FC } from 'react';
import NewPaymentMethodContent from '../PaymentMethods/NewPaymentMethodContent';
import { defaultPaymentValues } from '../NewPaymentMethodsDefaultValues';
import { IPaymentMethod } from '../Payments.interfaces';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import ConfirmationModal from 'src/components/Modals/ConfirmationModal/ConfirmationModal';
import { t } from '@lingui/macro';
import useAddFunds from 'src/components/Dashboard/MyDashboard/AddFunds/useAddFunds';
import { mapNewPaymenthMethodToSettleBallancePayment } from 'src/components/Dashboard/SettleBalance/mappers';
import { showMessage } from 'src/utils/message.utils';
import { usePaymentStore } from 'src/store/payment/payment.store';

interface IOneTimePaymentWrapper {
  showOneTimePaymentModal: boolean;
  finalAmount: number;
  setShowOneTimePaymentModal: (showOneTimePaymentModal: boolean) => void;
}
const OneTimePaymentWrapper: FC<IOneTimePaymentWrapper> = ({
  showOneTimePaymentModal,
  finalAmount,
  setShowOneTimePaymentModal,
}) => {
  const { cardType } = usePaymentStore();

  const { increaseBalance, accountData, addFundsLoading } = useAddFunds();
  const navigationElement: JSX.Element = (
    <div className="flex justify-between px-12">
      <CustomButton
        type="button"
        content="Cancel"
        isPrimary={false}
        onClick={() => {
          setShowOneTimePaymentModal(false);
        }}
      />
      <CustomButton type="submit" content="Continue" className="" />
    </div>
  );

  const onSumbit = async (paymentMethod: IPaymentMethod) => {
    const mappedInput = mapNewPaymenthMethodToSettleBallancePayment(
      paymentMethod,
      finalAmount,
      cardType,
      accountData?.accountDetails?.replenishAmount || 0,
    );
    try {
      await increaseBalance({
        variables: {
          increaseBalanceInput: mappedInput,
        },
      });
      showMessage('success', t`Your payment was successful. Your account balance may take a few minutes to update.`);
      setShowOneTimePaymentModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const content = (
    <div>
      <NewPaymentMethodContent
        isAddingPaymentMethod={false}
        isOnboardingPayment={false}
        isLoading={addFundsLoading}
        onSubmit={onSumbit}
        navigationElements={navigationElement}
        shouldBeWrappedInCard={false}
        showCVV={true}
        defaultFormValues={defaultPaymentValues}
        defaultPaymentMethod={undefined}
        diasbleEasyPayEnrollment={true}
        shouldShowSaveForFutureCheckBox={false}
      />
    </div>
  );

  return (
    <>
      {' '}
      {showOneTimePaymentModal && (
        <>
          <ConfirmationModal
            showModal={showOneTimePaymentModal}
            content={content}
            closable={true}
            alignText="center"
            onCancel={() => {
              setShowOneTimePaymentModal(false);
            }}
            showButtons={false}
          />
        </>
      )}
    </>
  );
};
export default OneTimePaymentWrapper;

import React, { FC, useEffect, useState } from 'react';
import MoneyIcon from '../../assets/Yellow-Money-Icon.svg';
import ChooseAmount from './ChooseAmount';
import { FormProvider, useForm } from 'react-hook-form';
import { Form } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import AccountMinimumBalanceConfirmationModal from '../../../Modals/ConfirmationModal/AccountMinimumBalanceConfirmationModal';
import ConfirmPaymentModal from '../../../Modals/ConfirmationModal/ConfirmPaymentModal';
import { getPaymentMethodName } from '../../../../utils/formatter';
import { MainLoader } from '../../../Loader/MainLoader';
import { addFundsSchema } from '../../../../validators/dashboard/myDashboard/addFunds.validator';
import { useNavigate, useParams } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import { useDashboardStore } from '../../../../store/dashboard/dashboard.store';
import { useScroll } from '../../../../hooks/screen/useScroll';
import { ADD_FUNDS_MY_WALLET_SECTION_ID } from '../../references';
import { checkIfElementExists } from '../../checkIfElementExists';
import SecurityCodeModal from 'src/components/Payments/PaymentMethods/SecurityCodeModal';
import ChosePaymentMethodFormItem from 'src/components/Payments/ChosePaymentMethod/ChosePaymentMethodFormItem';
import { concatRoutes, DashboardParams, MainRoutes } from 'src/types/routes';
import { mapPaymentMethodToAddFunds } from '../../SettleBalance/mappers';
import { useSecurityCodeModalStore } from 'src/store/payment/securityCodeModal.store';
import useAddFunds from './useAddFunds';
import { useLocation } from 'react-router';
import { ADD_NEW_PAYMENT_OPTION, ONE_TIME_PURCHASE } from 'src/constants';
import { showMessage } from 'src/utils/message.utils';
import OneTimePaymentWrapper from 'src/components/Payments/NewPaymentMethodWrappers/OneTimePaymentWrapper';

export interface IAddFunds {
  addFundsButton: string;
  paymentMethodId: string;
  otherAmount: number | undefined;
  actualAmount: number;
}

export interface IAddFundsProps {
  inExpandedContainer?: boolean;
}

export const AddFunds: FC<IAddFundsProps> = ({ inExpandedContainer }) => {
  const [isOneTimePayment, setIsOneTimePayment] = useState(false);
  const [showConfirmPaymentModal, setShowConfirmPaymentModal] = useState(false);
  const [showMinimumBalanceModal, setShowMinimumBalanceModal] = useState(false);
  const [paymentMethodName, setPaymentMethodName] = useState('');
  const [showOneTimePaymentModal, setShowOneTimePaymentModal] = useState(false);
  const {
    amount,
    finalAmount,
    checkUserBalance,
    currentBalance,
    minimumDepositAmount,
    paymentMethodsData,
    getPaymentMethodById,
    sortedPaymentMethods,
    accountData,
    accountDetailsLoading,
    increaseBalance,
    isPaymentMethodListLoading,
    addFundsLoading,
  } = useAddFunds();

  const {
    securityCodeModalData: { securityCode },
    setShowSecurityCodeModal,
  } = useSecurityCodeModalStore();

  const history = useNavigate();

  const {
    setAddFundsMode,
    setAddPaymentMethodMode,
    setShouldAddPaymentMethodFromMyDashboard,
    setShouldAddPaymentMethodFromMyWallet,
    store: { addFundsMode, shouldAddPaymentMethodFromMyWallet },
  } = useDashboardStore();

  const defaultValues = {
    addFundsButton: undefined,
    paymentMethodId: undefined,
    otherAmount: undefined,
    actualAmount: 0,
  };

  const methods = useForm<IAddFunds>({
    resolver: yupResolver(addFundsSchema()),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: defaultValues,
  });

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    resetField,
    trigger,
    formState: { errors },
  } = methods;

  const otherAmountWatch = watch('otherAmount');
  const addFundsButtonWatch = watch('addFundsButton');

  const paymentMethod = paymentMethodsData ? getPaymentMethodById(watch('paymentMethodId')) : null;

  useEffect(() => {
    if (paymentMethod) {
      setPaymentMethodName(getPaymentMethodName(paymentMethod));
    }
  }, [paymentMethod]);

  useEffect(() => {
    const difference = minimumDepositAmount - currentBalance;
    if (otherAmountWatch !== undefined && otherAmountWatch !== 0) {
      checkUserBalance(otherAmountWatch, difference);
    } else if (addFundsButtonWatch) {
      checkUserBalance(Number.parseInt(addFundsButtonWatch), difference);
    }
  }, [addFundsButtonWatch, otherAmountWatch]);

  const handleChange = (value: string) => {
    if (value === ONE_TIME_PURCHASE) {
      setIsOneTimePayment(true);
    } else if (value === ADD_NEW_PAYMENT_OPTION) {
      redirectToPaymentMethods();
    }

    setValue('paymentMethodId', value);
    trigger('paymentMethodId');
  };

  const location = useLocation();

  const redirectToPaymentMethods = () => {
    const path = concatRoutes(MainRoutes.DASHBOARD, '/', DashboardParams.WALLET);
    location.pathname !== path ? history(path) : null;
    setAddPaymentMethodMode(true);
    location.pathname === path
      ? setShouldAddPaymentMethodFromMyWallet(true)
      : setShouldAddPaymentMethodFromMyDashboard(true);
  };

  const onConfirmPayment = async () => {
    setShowConfirmPaymentModal(false);
    setShowMinimumBalanceModal(false);
    setShowSecurityCodeModal(false);
    scrollTo({ top: 0, behavior: 'smooth' });

    let paymentMethod = sortedPaymentMethods?.find((e) => e.paymentMethodId === watch('paymentMethodId'));

    if (paymentMethod) {
      paymentMethod = {
        ...paymentMethod,
        securityCode: securityCode,
      };
    }

    if (paymentMethod) {
      const increaseBalanceInput = mapPaymentMethodToAddFunds(
        paymentMethod,
        finalAmount,
        accountData?.accountDetails?.replenishAmount || 0,
      );
      try {
        await increaseBalance({
          variables: {
            increaseBalanceInput,
          },
        });
        showMessage('success', t`Your payment was successful. Your account balance may take a few minutes to update.`);
      } catch (error) {
        console.log(error);
      }
    }
    reset();
  };

  const showConfirmationModalOrRedirect = () => {
    if (paymentMethod?.isBankAccount) {
      setShowConfirmPaymentModal(true);
    } else if (isOneTimePayment) {
      setShowOneTimePaymentModal(true);
    } else {
      setShowSecurityCodeModal(true);
    }
  };

  const onSubmit = async () => {
    const sum: number = currentBalance + amount;
    if (sum < minimumDepositAmount) {
      setShowMinimumBalanceModal(true);
    } else {
      if (isOneTimePayment) {
        setShowOneTimePaymentModal(true);
      } else {
        showConfirmationModalOrRedirect();
      }
    }
  };

  const confirmMinimumBalanceModal = () => {
    setShowMinimumBalanceModal(false);
    showConfirmationModalOrRedirect();
  };

  const { activeTab } = useParams();

  useEffect(() => {
    reset();
  }, [activeTab]);

  const { scrollToElem } = useScroll();

  const addFundsSectionExists = checkIfElementExists(ADD_FUNDS_MY_WALLET_SECTION_ID);

  useEffect(() => {
    if (addFundsMode) resetField('paymentMethodId');
  }, [addFundsMode]);

  useEffect(() => {
    if (addFundsSectionExists && addFundsMode) {
      if (!shouldAddPaymentMethodFromMyWallet) scrollToElem(ADD_FUNDS_MY_WALLET_SECTION_ID, true);
      setAddFundsMode(false);
      setShouldAddPaymentMethodFromMyDashboard(false);
      setShouldAddPaymentMethodFromMyWallet(false);
    }
  }, [addFundsMode, addFundsSectionExists]);

  return (
    <>
      <div
        className={`add-funds_container sm:mr-6 ${errors?.actualAmount ? 'amount-error' : ''} ${
          errors?.paymentMethodId ? 'payment-method-error' : ''
        }`}
        id={ADD_FUNDS_MY_WALLET_SECTION_ID}
      >
        <MainLoader loading={isPaymentMethodListLoading || addFundsLoading || accountDetailsLoading} />
        {inExpandedContainer ? null : (
          <div className="mb-6 flex items-center">
            <img src={MoneyIcon} alt="money icon" />
            <h1 className="ml-3 text-xl font-bold sm:ml-6 sm:text-3xl">
              <Trans>Add Funds</Trans>
            </h1>
          </div>
        )}
        <div className={`w-full space-y-6 ${inExpandedContainer ? '' : 'mb-10 mt-4'}`}>
          <FormProvider {...methods}>
            <Form layout={'vertical'} onFinish={handleSubmit(onSubmit)}>
              <ChooseAmount />
              <div className="w-full py-6">
                <ChosePaymentMethodFormItem
                  handleChange={(value) => handleChange(value)}
                  sortedPaymentMethods={sortedPaymentMethods}
                />
              </div>{' '}
              <div className="flex w-full flex-col sm:w-48">
                <CustomButton
                  type="submit"
                  className={`theme ${
                    errors?.actualAmount || errors?.paymentMethodId ? 'submit-payment-button-error' : ''
                  } submit-payment-add-funds`}
                  content="Submit Payment"
                />

                <button
                  type="button"
                  className="dashboard-links ml-1 mt-4 inline text-base"
                  onClick={() => {
                    reset();
                  }}
                >
                  <Trans>Cancel</Trans>
                </button>
              </div>
            </Form>
          </FormProvider>
        </div>

        <AccountMinimumBalanceConfirmationModal
          showModal={showMinimumBalanceModal}
          onOk={confirmMinimumBalanceModal}
          onCancel={() => {
            setShowMinimumBalanceModal(false);
          }}
          balance={accountData?.accountDetails?.currentBalance || 0}
          paymentMethodName={paymentMethodName}
          minBalance={minimumDepositAmount}
          amount={finalAmount}
        />

        <ConfirmPaymentModal
          showModal={showConfirmPaymentModal}
          onOk={onConfirmPayment}
          onCancel={() => {
            setShowConfirmPaymentModal(false);
          }}
          value={finalAmount}
          paymentMethodName={paymentMethodName}
        />

        <SecurityCodeModal paymentMethod={paymentMethod} amount={finalAmount} onSubmit={onConfirmPayment} />
        <OneTimePaymentWrapper
          showOneTimePaymentModal={showOneTimePaymentModal}
          setShowOneTimePaymentModal={setShowOneTimePaymentModal}
          finalAmount={finalAmount}
        />
      </div>
    </>
  );
};

export default AddFunds;

import { gql } from '@apollo/client';

export const ALERTS = gql`
  query alerts {
    alerts {
      id
      message
      expiresOn
    }
  }
`;

export interface IAlertsData {
  alerts: IAlert[];
}

export interface IAlert {
  id: string;
  message: string;
  expiresOn?: string;
}

import { i18n } from '@lingui/core';

export const VEHICLE_REQUIRED_ERROR_MSG = i18n._(`Vehicle required`);
export const PLATE_NUMBER_REQUIRED_ERROR_MSG = i18n._(`License plate number is required`);
export const VEHICLE_COUNTRY_REQUIRED_ERROR_MSG = i18n._(`License plate country is required`);
export const PLATE_STATE_REQUIRED_ERROR_MSG = i18n._(`License plate state is required`);
export const PLATE_TYPE_REQUIRED_ERROR_MSG = i18n._(`License plate type is required`);
export const FIELD_IS_REQUIRED = i18n._(`Required field`);
export const VEHICLE_MAKE_REQUIRED_ERROR_MSG = i18n._(`Make is required`);
export const VEHICLE_MODEL_REQUIRED_ERROR_MSG = i18n._(`Model is required`);
export const VEHICLE_YEAR_REQUIRED_ERROR_MSG = i18n._(`Year is required`);
export const EFFECTIVE_START_DATE_REQUIRED_ERROR_MSG = i18n._(`Effective start date is required`);
export const EFFECTIVE_START_DATE_IN_FUTURE_ERROR_MSG = i18n._(`Start date cannot be in the future`);
export const EFFECTIVE_START_TIME_REQUIRED_ERROR_MSG = i18n._(`Effective start time is required`);
export const EFFECTIVE_END_DATE_REQUIRED_ERROR_MSG = i18n._(`Effective end date is required`);
export const EFFECTIVE_END_TIME_REQUIRED_ERROR_MSG = i18n._(`Effective end time is required`);
export const END_TIME_AFTER_START_TIME_ERROR_MSG = i18n._(
  `Please make sure your end date/time is after your start date/time`,
);

export const MAX_TRANSPONDER_PLANS_ERROR_MSG = i18n._(`The number of plans cannot exceed the number of transponders`);
export const NO_TRANSPONDERS_TO_ADD_PLAN = i18n._(
  `You must add one or more transponder(s) before you can opt into the Pinellas Bayway Plan.`,
);

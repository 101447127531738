import { Trans } from '@lingui/macro';
import React from 'react';
import SelectableCard from 'src/components/SelectableCard/SelectableCard';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import BackButton from '../../BackButton/BackButton';
import ScrollToTop from '../../ScrollToTop/ScrollToTop';
import { usePinellasRecommendation } from './usePinellasRecommendation';

export default function OnboardingPinellasRecommendation(): React.ReactElement {
  const { cards, onSubmit, recommendedPlan, planDescription } = usePinellasRecommendation();

  return (
    <div className="onboarding-recommendation h-full">
      <ScrollToTop />
      <div className="onboarding-recommendation__header">
        <div role="heading" aria-level={1} className="main-header onboarding-header">
          <Trans>Recommended for you…</Trans>
        </div>

        <h4 className="py-5 text-center">{recommendedPlan}</h4>
      </div>

      <section className="onboarding-recommendation__content card">
        <div className="content__plan-description">{planDescription}</div>
        <div className={`content__plan-types`} tabIndex={-1}>
          {cards.map((card) => (
            <SelectableCard key={card.title} {...card}></SelectableCard>
          ))}
        </div>
      </section>

      <div className="onboarding-recommendation__button-container onboarding-button-container">
        <BackButton />
        <CustomButton content="Continue" onClick={onSubmit} />
      </div>
    </div>
  );
}

import { CreditCardType } from 'credit-card-type/dist/types';

export const TransponderMask = '0000000000000';

export const PhoneMask = '(000) 000-0000';

export const FeinMask = '00-0000000';

export const CCVMask = '0000';

export const BANK_ROUTING_NUMBER_MASK = '000000000';

export const BANK_ACCOUNT_NUMBER_MASK = '00000000000000000';

export const CREDIT_CARD_MASK = '0000 0000 0000 0000000';

export const creditCardMaskBuilder = (cardType: CreditCardType | undefined): string => {
  if (cardType === undefined) {
    return CREDIT_CARD_MASK;
  }

  const maxLength = cardType.lengths[cardType.lengths.length - 1];
  const mask = [...Array(maxLength)].map(() => '0');

  // add gaps
  for (let i = cardType.gaps.length - 1; i >= 0; i--) {
    mask.splice(cardType.gaps[i], 0, ' ' as any);
  }
  return mask.join('');
};

export const convertMaskToPlaceholder = (mask?: string): string | null => {
  return mask ? mask.replace(/\d+/g, '_') : null;
};

export const reformatPhoneNumber = (number?: string): string | undefined => {
  return number ? number.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : undefined;
};

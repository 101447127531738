import { Form } from 'antd';
import { t, Trans } from '@lingui/macro';
import { Controller, useFormContext } from 'react-hook-form';
import StyledInput from '../../../StyledInput/StyledInput';
import React from 'react';
import { IUserNames } from '../../../../graphql/signUp/signUpDetails.graphql';
import '../../../AppForm/AppForm.scss';

export const MiddleName = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IUserNames>();
  return (
    <div className="input-margins w-full sm:w-2/12 sm:px-2">
      <Form.Item label={<Trans>M.I.</Trans>} className="theme" help={errors.middleName?.message}>
        <Controller
          name="middleName"
          control={control}
          render={({ field, fieldState }) => (
            <StyledInput
              field={field}
              fieldstate={fieldState}
              id="middleName"
              key="middleName"
              maxLength={1}
              aria-label={t`Middle Name`}
            />
          )}
        />
      </Form.Item>
    </div>
  );
};

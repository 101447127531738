import { useFormContext } from 'react-hook-form';
import { CountryType, mapCountryToDefaultState } from 'src/utils/countryStates.utils';
import { useNonInitialRender } from '../../effect/useNonInitialRender';

export const useStateTriggerToDefault = (watchFieldName: string, triggerFieldName: string) => {
  const { resetField, watch } = useFormContext();
  const fieldWatch = watch(watchFieldName);

  useNonInitialRender(() => {
    if (fieldWatch) {
      resetField(triggerFieldName, {
        defaultValue: mapCountryToDefaultState[fieldWatch as CountryType],
      });
    }
  }, [fieldWatch]);
};

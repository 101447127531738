import { Form } from 'antd';
import { t, Trans } from '@lingui/macro';
import { Controller, useFormContext } from 'react-hook-form';
import StyledInput from '../../../StyledInput/StyledInput';
import React from 'react';
import { IFleetGovAdditionalInfo } from '../../../../types/onboarding.types';
import { IAccountFormElemBaseProps } from './account.types';

export const CompanyName = ({ disabled }: IAccountFormElemBaseProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IFleetGovAdditionalInfo>();
  return (
    <div className="w-full sm:w-6/12 sm:pr-1">
      <Form.Item label={<Trans>Company Name</Trans>} className="theme" help={errors.companyName?.message} required>
        <Controller
          name="companyName"
          control={control}
          render={({ field, fieldState }) => (
            <StyledInput
              disabled={disabled}
              field={field}
              fieldstate={fieldState}
              key="companyName"
              id="companyName"
              maxLength={30}
              aria-label={t`Company Name`}
            />
          )}
        />
      </Form.Item>
    </div>
  );
};

import React from 'react';
import { TransactionType } from './TransactionType';
import { AgencyName } from './AgencyName';
import { SearchDate } from './SearchDate';
import { CustomButton } from 'src/components/CustomButton/CustomButton';

export interface IActivityAdvanceFilters {
  hideAdvanceFilters: () => void;
}

export const ActivityAdvancedFilters = ({ hideAdvanceFilters }: IActivityAdvanceFilters) => {
  return (
    <div className="mb-10 flex w-full flex-col flex-wrap rounded-lg bg-white px-8 pb-10 pt-3 shadow-lg md:flex-row">
      <TransactionType />
      <AgencyName />
      <SearchDate />
      <div className="mx-0 mt-1 flex w-full flex-col sm:mx-auto sm:mt-7 sm:w-auto sm:flex-row">
        <CustomButton
          type="reset"
          onClick={hideAdvanceFilters}
          className="mx-0 mb-5 mt-2 sm:mx-3 sm:mb-0"
          content="Cancel"
          isPrimary={false}
        />
        <CustomButton type="submit" className="px-15 mx-0 mt-2 sm:mx-3 " content="Apply Filters" />
      </div>
    </div>
  );
};

import { t } from '@lingui/macro';
import React, { useEffect, useMemo, useState } from 'react';
import {
  AccountSubtype,
  AccountType,
  getStepsByUserTypeAndFlowType,
  IAccountInfo,
  OnboardingFlowType,
  OnboardingStepsPayg,
} from 'src/types/onboarding.types';
import OnboardingHeader, { OnboardingHeaderWholeViewProps } from '../OnboardingHeader';
import ScrollToTop from '../../ScrollToTop/ScrollToTop';
import { useOnboardingStore } from '../../../store/onboarding/onboarding.store';
import { SELECT_DETAILS, SELECT_RECOMMENDED_SUNPASS_PLAN_NAME } from '../../../store/onboarding/onboarding.selectors';

import { useRouteMonitor } from 'src/hooks/location/useRouteMonitor';
import { OnboardingStepPath } from 'src/types/routes';
import NewPaymentMethodOnboardingWrapper from 'src/components/Payments/NewPaymentMethodWrappers/NewPaymentMethodOnboardingWrapper';
import useTransponderPrices from 'src/hooks/domain/dashboard/transponders/useTransponderPrices';
import OneTimePurchase from '../../Payments/PaymentSummary/OneTimePurchase/OneTimePurchase';
import './OnboardingPayment.scss';
import PurchaseInformation from 'src/components/Payments/PaymentSummary/PurchaseInformation';
import { ReferenceNumber } from '../ReferenceNumber';
import { PromoCodeType } from '../../../graphql/verifyPromotionCode.graphql';
import { useMutation } from '@apollo/client';
import {
  ISignUpVehiclesTranspondersInput,
  ISignUpVehiclesTranspondersOutput,
  SIGN_UP_VEHICLES_AND_TRANSPONDERS,
} from '../../../graphql/signUp/signUpVehiclesTransponders.graphql';
import moment from 'moment';
import { GRAPHQL_DATE_FORMAT } from '../../../utils/time.utils';

export default function OnboardingPayment(): React.ReactElement {
  const history = useRouteMonitor();
  const [promoCodeAmount, setPromoCodeAmount] = useState<number>(0);
  const [promoCode, setPromoCode] = useState<string | undefined>('');
  const [isPromoCodeInvalid, setIsPromoCodeInvalid] = useState(false);
  const [promoType, setPromoType] = useState<string | null>(null);

  const {
    userType,
    accountType,
    accountSubtype,
    accountInfo,
    preloadedFunds,
    miniTransponderQty,
    proTransponderQty,
    onboardingFlowType,
    proTransponderQtyAssignedToPlan,
    miniTransponderQtyAssignedToPlan,
    easyPay,
    accountId,
    vehicles,
  } = useOnboardingStore(SELECT_DETAILS);

  const [signUpVehiclesAndTransponders] = useMutation<
    { signUpVehiclesTransponders: ISignUpVehiclesTranspondersOutput },
    { signUpVehiclesTranspondersInput: ISignUpVehiclesTranspondersInput }
  >(SIGN_UP_VEHICLES_AND_TRANSPONDERS);

  const { miniPrice, proPrice, PBCPrice, loading: transponderPricesLoading } = useTransponderPrices();
  const isPBCAccount = accountSubtype === AccountSubtype.PBC && accountType === AccountType.PRIVATE;
  const [totalCountyTax, setTotalCountyTax] = useState(0);

  const subtotalValue = useMemo(() => {
    if (onboardingFlowType === OnboardingFlowType.PREPAID) {
      return +preloadedFunds + (miniTransponderQtyAssignedToPlan + proTransponderQtyAssignedToPlan) * PBCPrice;
    } else {
      return isPBCAccount
        ? miniTransponderQty * PBCPrice + +preloadedFunds
        : miniTransponderQty * miniPrice +
            proTransponderQty * proPrice +
            +preloadedFunds +
            (miniTransponderQtyAssignedToPlan + proTransponderQtyAssignedToPlan) * PBCPrice;
    }
  }, [
    PBCPrice,
    miniTransponderQty,
    miniTransponderQtyAssignedToPlan,
    proTransponderQty,
    proTransponderQtyAssignedToPlan,
  ]);

  const [oneTimePurchaseSubTotal, setOneTimePurchaseSubTotal] = useState(subtotalValue);

  useEffect(() => {
    if (isNaN(oneTimePurchaseSubTotal)) {
      setOneTimePurchaseSubTotal(subtotalValue);
    }
  }, [subtotalValue, oneTimePurchaseSubTotal]);

  useEffect(() => {
    if (
      promoCodeAmount > 0 &&
      isPBCAccount &&
      promoType !== PromoCodeType.TOLL &&
      onboardingFlowType !== OnboardingFlowType.PREPAID
    ) {
      setOneTimePurchaseSubTotal(miniTransponderQty * PBCPrice + +preloadedFunds - promoCodeAmount);
    } else if (
      promoCodeAmount > 0 &&
      isPBCAccount &&
      promoType === PromoCodeType.TOLL &&
      onboardingFlowType !== OnboardingFlowType.PREPAID
    ) {
      setOneTimePurchaseSubTotal(miniTransponderQty * PBCPrice + +preloadedFunds);
    }
    if (onboardingFlowType === OnboardingFlowType.PREPAID) {
      setOneTimePurchaseSubTotal(
        +preloadedFunds + (miniTransponderQtyAssignedToPlan + proTransponderQtyAssignedToPlan) * PBCPrice,
      );
    }
  }, [promoCodeAmount, promoType]);

  const getTaxesAndAmount = async (finalMiniTransponderQty: number, finalProTransponderQty: number) => {
    if (isPBCAccount) {
      setTotalCountyTax(0);
    } else {
      const vehicleAndTranspondersInput: ISignUpVehiclesTranspondersInput = {
        accountId: accountId,
        miniTranspondersCount: finalMiniTransponderQty,
        proTranspondersCount: finalProTransponderQty,
        portableTranspondersCount: 0,
        vehicleUploadBatchId: '',
        vehicleUploadCount: '0',
        vehicles: vehicles.map((vehicle) => {
          const transformed = { ...vehicle } as any;
          transformed.effectiveStartDate = moment(vehicle?.effectiveStartDate).format(GRAPHQL_DATE_FORMAT);
          transformed.effectiveStartTime = moment(vehicle?.effectiveStartTime).format('hh:mm');
          if (vehicle.effectiveEndDate) {
            transformed.effectiveEndDate = moment(vehicle?.effectiveEndDate).format(GRAPHQL_DATE_FORMAT);
            transformed.effectiveEndTime = moment(vehicle?.effectiveEndTime).format('hh:mm');
          }
          if (vehicle.isTrailer) {
            transformed.vehicleModel = '';
            transformed.vehicleMake = '';
            transformed.vehicleYear = '';
          }
          delete transformed.notKnown;
          return transformed;
        }),
      };

      const { data } = await signUpVehiclesAndTransponders({
        variables: {
          signUpVehiclesTranspondersInput: vehicleAndTranspondersInput,
        },
      });
      setTotalCountyTax(data?.signUpVehiclesTransponders.tax || 0);
      if (data) {
        if (miniTransponderQtyAssignedToPlan > 0 || proTransponderQtyAssignedToPlan > 0) {
          setOneTimePurchaseSubTotal(
            data?.signUpVehiclesTransponders.totalTranspondersCost +
              +preloadedFunds +
              (miniTransponderQtyAssignedToPlan + proTransponderQtyAssignedToPlan) * PBCPrice,
          );
        } else {
          setOneTimePurchaseSubTotal(data?.signUpVehiclesTransponders.totalTranspondersCost + preloadedFunds);
        }
      }
    }
  };

  useEffect(() => {
    onboardingFlowType !== OnboardingFlowType.PREPAID && calculateAmountAndTaxes();
  }, [promoCode]);

  const calculateAmountAndTaxes = async () => {
    if (promoType === PromoCodeType.PRO && promoCode !== '' && promoCode !== undefined) {
      await getTaxesAndAmount(miniTransponderQty, proTransponderQty - 1);
    } else if (promoType === PromoCodeType.STK && promoCode !== '' && promoCode !== undefined) {
      await getTaxesAndAmount(miniTransponderQty - 1, proTransponderQty);
    } else if (promoCode === '' || promoCode === undefined) {
      await getTaxesAndAmount(miniTransponderQty, proTransponderQty);
    }
  };

  useEffect(() => {
    isPBCAccount && setOneTimePurchaseSubTotal(miniTransponderQty * PBCPrice + +preloadedFunds);
  }, []);

  const planName = SELECT_RECOMMENDED_SUNPASS_PLAN_NAME(accountType, accountSubtype);

  let steps = userType && onboardingFlowType ? getStepsByUserTypeAndFlowType(userType, onboardingFlowType) : [];
  if (accountType === 'PAYG') {
    steps = OnboardingStepsPayg;
  }

  const recurringChargesSubTotal = 0; // TODO: calculate once implemented

  const { updateOnboardingStore } = useOnboardingStore();

  const totalDueToday = useMemo(() => {
    updateOnboardingStore({
      totalTranspondersCost: oneTimePurchaseSubTotal + totalCountyTax + recurringChargesSubTotal,
      recurringPaymentAmount: +preloadedFunds,
    });
    return oneTimePurchaseSubTotal + totalCountyTax + recurringChargesSubTotal;
  }, [oneTimePurchaseSubTotal, totalCountyTax, recurringChargesSubTotal]);

  const paymentContent = (
    <>
      <OneTimePurchase
        miniTransponderCharge={isPBCAccount ? PBCPrice : miniPrice}
        proTransponderCharge={proPrice}
        miniTransponderQty={miniTransponderQty || 0}
        proTransponderQty={proTransponderQty || 0}
        pbcPlanPrice={PBCPrice}
        miniTransponderQtyAssignedToPlan={miniTransponderQtyAssignedToPlan}
        proTransponderQtyAssignedToPlan={proTransponderQtyAssignedToPlan}
        initialAccountDeposit={preloadedFunds || 0}
        salesTax={totalCountyTax}
        subTotal={oneTimePurchaseSubTotal}
        onEdit={() => {
          accountType === 'PAYG'
            ? history.goToPath(OnboardingStepPath.EASY_PAY)
            : history.goToPath(OnboardingStepPath.TRANSPONDERS);
        }}
        isDashboard={false}
        setPromoCodeAmount={setPromoCodeAmount}
        setPromoCode={setPromoCode}
        promoCodeAmount={promoCodeAmount}
        isPBCAccount={isPBCAccount}
        setIsPromoCodeInvalid={setIsPromoCodeInvalid}
        setPromoCodeType={setPromoType}
        promoType={promoType}
        onboardingFlowType={onboardingFlowType}
      />
    </>
  );

  return (
    <React.Fragment>
      <ScrollToTop />
      <OnboardingHeader<OnboardingHeaderWholeViewProps>
        title={t`Complete Your Order`}
        steps={steps}
        planName={planName}
        currentStepIndex={accountType === AccountType.PAYG ? 4 : 5}
      />
      <ReferenceNumber />
      <div className={`card onboarding-payment-purhcase-info`}>
        <PurchaseInformation
          accountInfo={accountInfo as unknown as IAccountInfo}
          planName={planName}
          isLoading={transponderPricesLoading}
          onEditContactInformation={() => history.goToPath(OnboardingStepPath.ACCOUNT)}
          onEditMailingAddress={() => history.goToPath(OnboardingStepPath.ACCOUNT)}
          totalPrice={totalDueToday}
          paymentContent={paymentContent}
        />
      </div>
      <NewPaymentMethodOnboardingWrapper
        promoCode={promoCode}
        isOptInEasyPay={easyPay.optInEZPay}
        isPromoCodeInvalid={isPromoCodeInvalid}
        totalDueToday={totalDueToday}
      />
    </React.Fragment>
  );
}

import React, { FC, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import ConfirmationModal from './ConfirmationModal';
import { Radio } from 'antd';
import { IAddressValidationOutput } from '../../../graphql/addressValidation.graphql';
import { IMailingAddress } from '../../../types/onboarding.types';
import { useFormContext } from 'react-hook-form';

interface IMailingAddressStandardarizationModalProps {
  showModal: boolean;
  onOk: () => void;
  onCancel?: (nextLocation: any) => void | (() => void);
  modalTitle?: string;
  modalCancelButtonText?: string;
  modalOkButtonText?: string;
  standardizedAddressData: { addressValidation: IAddressValidationOutput } | undefined;
  setIsAddressStandardized: React.Dispatch<React.SetStateAction<boolean>>;
}

const MailingAddressStandardizationModal: FC<IMailingAddressStandardarizationModalProps> = ({
  showModal,
  onOk,
  onCancel,
  standardizedAddressData,
  setIsAddressStandardized,
}) => {
  const cancelButtonText = t`Cancel`;
  const okButtonText = t`Continue`;
  const title = t`Mailing Address Standardization`;

  const [isStandardizedRadioChecked, setIsStandardizedRadioChecked] = useState(true);

  const text = t`The input address has been converted to a standardized format. Please choose the address you'd like to proceed
          with below:`;

  const { getValues } = useFormContext<IMailingAddress>();

  const addressLine1 = getValues('addressLine1');
  const addressLine2 = getValues('addressLine2');
  const postalCode = getValues('postalCode');
  const city = getValues('city');
  const country = getValues('country');
  const state = getValues('state');

  const content = (
    <>
      <div className="mb-5 text-left">{text}</div>
      <div className="address-modal ml-0 flex flex-col text-left">
        <div className="radio-buttons flex w-full">
          <div className="input-data">
            <Radio
              value="INPUT"
              aria-label={t`Input`}
              className="font-bold"
              checked={!isStandardizedRadioChecked}
              onClick={() => {
                setIsAddressStandardized(false);
                setIsStandardizedRadioChecked(false);
              }}
            >
              <Trans>Input</Trans>
            </Radio>
            <div className="flex">
              <div className="text-left">
                <div>{addressLine1}</div>
                {addressLine2 !== '' ? <div>{addressLine2}</div> : null}
                <div className="flex">
                  <div className="mr-1">{city}</div>
                  <div className="mr-1">{state}</div>
                  <div>{postalCode}</div>
                </div>
                <div>{country}</div>
              </div>
            </div>
          </div>
          <div className="standard-data">
            <Radio
              defaultChecked={true}
              value="STANDARDIZED"
              aria-label={t`Standardized`}
              className="font-bold"
              checked={isStandardizedRadioChecked}
              onClick={() => {
                setIsAddressStandardized(true);
                setIsStandardizedRadioChecked(true);
              }}
            >
              <Trans>Standardized</Trans>
            </Radio>
            <div className="standard-data flex">
              <div className="text-left">
                <div>{standardizedAddressData?.addressValidation.addressLine1}</div>
                {standardizedAddressData?.addressValidation.addressLine2 ? (
                  <div>{standardizedAddressData?.addressValidation.addressLine2}</div>
                ) : null}
                <div className="flex">
                  <div className="mr-1">{standardizedAddressData?.addressValidation.city}</div>
                  <div className="mr-1">{standardizedAddressData?.addressValidation.state}</div>
                  <div>{`${standardizedAddressData?.addressValidation.zipcode}-${standardizedAddressData?.addressValidation.zipcodePlus}`}</div>
                </div>
                <div>{standardizedAddressData?.addressValidation.country}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      {showModal && (
        <ConfirmationModal
          onOk={onOk}
          onCancel={onCancel}
          showModal={showModal}
          cancelButtonText={cancelButtonText}
          okButtonText={okButtonText}
          title={title}
          content={content}
          alignButtons="center"
          alignText="center"
        />
      )}
    </>
  );
};
export default MailingAddressStandardizationModal;

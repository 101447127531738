import { AccountType } from 'src/types/onboarding.types';
import {
  REACHED_PRIVATE_ACCOUNT_TRANSPONDER_LIMIT,
  MIN_TRANPONDERS_AMOUNT_FLEET_LIMIT,
  MAX_TRANPONDERS_AMOUNT_FLEET_LIMIT,
  PRIVATE_ACCOUNT_TRANPONDER_LIMIT,
  FLLET_ACCOUNT_MIN_TRANPONDER_LIMIT,
  FLLET_ACCOUNT_MAX_TRANPONDER_LIMIT,
} from './transpondersVariables';

export const generateLimitMessage = (
  numOfStickerTransponders: number,
  numOfProTransponders: number,
  accountType: AccountType,
): string | null => {
  switch (accountType) {
    case AccountType.PRIVATE:
      if (numOfStickerTransponders + numOfProTransponders > PRIVATE_ACCOUNT_TRANPONDER_LIMIT) {
        return REACHED_PRIVATE_ACCOUNT_TRANSPONDER_LIMIT;
      } else {
        return null;
      }
    case AccountType.COMMERCIAL:
      if (numOfStickerTransponders + numOfProTransponders < FLLET_ACCOUNT_MIN_TRANPONDER_LIMIT) {
        return MIN_TRANPONDERS_AMOUNT_FLEET_LIMIT;
      } else if (numOfStickerTransponders + numOfProTransponders > FLLET_ACCOUNT_MAX_TRANPONDER_LIMIT) {
        return MAX_TRANPONDERS_AMOUNT_FLEET_LIMIT;
      } else {
        return null;
      }
    default:
      return null;
  }
};

export interface IApiErrorTemplatesTransponders {
  TRANS_NUMBER_DUPLICATED: string;
  TRANS_STATUS_BLANK: string;
  TRANS_NUMBER_BLANK: string;
  TRANS_NUMBER_DOES_NOT_EXISTS: string;
}

const API_ERROR_TEMPLATES: IApiErrorTemplatesTransponders = {
  TRANS_NUMBER_DUPLICATED: '1614: Transponder number {@0} found duplicate in the file {@1}.',
  TRANS_STATUS_BLANK: '1615: The Transponder Status cannot be blank in file {@0}.',
  TRANS_NUMBER_BLANK: '1616: The Transponder Number cannot be blank in file {@0}.',
  TRANS_NUMBER_DOES_NOT_EXISTS:
    '1617: Entered Transponder number {@0} does not exist on the account provided in the file {@1}.',
};

export const transponderApiErrorCodeToTemplateMap: Record<number, string> = {
  1614: API_ERROR_TEMPLATES.TRANS_NUMBER_DUPLICATED,
  1615: API_ERROR_TEMPLATES.TRANS_STATUS_BLANK,
  1616: API_ERROR_TEMPLATES.TRANS_NUMBER_BLANK,
  1617: API_ERROR_TEMPLATES.TRANS_NUMBER_DOES_NOT_EXISTS,
};

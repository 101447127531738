import { Trans } from '@lingui/macro';
import { ActionType } from './ForgotUsernameOrPassword';

export const getMessage = (actionType: ActionType) => {
  switch (actionType) {
    case 'resetPassword':
      return (
        <Trans>
          <p>
            Please enter your postal code plus your account number{' '}
            <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>or</span> your username so that we can
            identify your account and retrieve one of your security questions.
          </p>
        </Trans>
      );
    case 'retrieveUsername':
      return (
        <Trans>
          <p>
            To retrieve your username, please enter your postal code plus your account number{' '}
            <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>or</span> your transponder number.
          </p>
        </Trans>
      );
    default:
      return;
  }
};

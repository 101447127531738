import { FC } from 'react';
import { Trans } from '@lingui/macro';
import useScreenSize from 'src/hooks/screen/useScreenSize';
import { ACTIVITY_HEADER } from '../../references';

const ActivityListHeader: FC = () => {
  const { screenSize } = useScreenSize();

  return (
    <header className={'flex flex-row justify-between px-8 py-4'} id={ACTIVITY_HEADER}>
      <div className={`${screenSize > 2 ? '' : 'hidden'} w-2/12`}>
        <Trans>Transaction Date</Trans>
      </div>
      <div className={`${screenSize > 2 ? '' : 'hidden'} w-1/12`}>
        <Trans>Time</Trans>
      </div>
      <div className={`${screenSize > 2 ? '' : 'hidden'} w-2/12`}>
        <Trans>Transaction Type</Trans>
      </div>
      <div className={`${screenSize > 2 ? '' : 'hidden'} w-2/12`}>
        <Trans>Description</Trans>
      </div>
      <div className={`${screenSize > 2 ? '' : 'hidden'} w-2/12`}>
        <Trans>Transponder</Trans>
      </div>
      <div className={`${screenSize > 2 ? '' : 'hidden'} w-1/12 text-right`}>
        <Trans>Amount</Trans>
      </div>
    </header>
  );
};

export default ActivityListHeader;

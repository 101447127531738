import { t } from '@lingui/macro';
import React, { FC } from 'react';
import { IVehicle } from 'src/graphql/vehicles.graphql';
import { concatRoutes, DashboardParams, MainRoutes } from 'src/types/routes';
import ActionItem from './ActionItem';
import { ActionItemAndIconLinkCardId } from './ActionItemAndIconLinkCardId';
import { IPaymentMethodGraphQl } from '../../../graphql/paymentMethods.graphql';

interface IItemsPAYG {
  vehicles: IVehicle[] | undefined;
  hasUserAddedFunds: boolean;
  paymentMethods: IPaymentMethodGraphQl[] | undefined;
  isOptInEasyPay: boolean;
}

const ItemsPAYG: FC<IItemsPAYG> = ({ vehicles, hasUserAddedFunds, paymentMethods, isOptInEasyPay }) => {
  return (
    <div className="action-items grid grid-cols-2 grid-rows-1 gap-0 md:gap-1">
      <ActionItem
        id={ActionItemAndIconLinkCardId.ADD_VEHICLE}
        title={t`Add Vehicle`}
        autoHeight={true}
        selected={!!vehicles}
        link={concatRoutes(MainRoutes.DASHBOARD, '/', DashboardParams.VEHICLES)}
      />
      <ActionItem
        id={ActionItemAndIconLinkCardId.ADD_FUNDS}
        title={t`Add Funds`}
        autoHeight={true}
        selected={hasUserAddedFunds}
        link={concatRoutes(MainRoutes.DASHBOARD, '/', DashboardParams.WALLET)}
      />
      <ActionItem
        id={ActionItemAndIconLinkCardId.SET_UP_EASY_PAY}
        title={t`Set up Easy Pay`}
        autoHeight={true}
        selected={isOptInEasyPay}
        link={concatRoutes(MainRoutes.DASHBOARD, '/', DashboardParams.WALLET)}
      />
      <ActionItem
        id={ActionItemAndIconLinkCardId.ADD_PAYMENT_METHOD}
        title={t`Add Payment Method`}
        autoHeight={true}
        selected={!!paymentMethods && paymentMethods?.length != 0}
        link={concatRoutes(MainRoutes.DASHBOARD, '/', DashboardParams.WALLET)}
      />
    </div>
  );
};

export default ItemsPAYG;

import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC } from 'react';
import { IContactInfo } from '../../../types/onboarding.types';
import styles from './ReviewYourInformation.module.scss';

interface IContactInformationProps {
  billingInfo: IContactInfo;
}

const Billing: FC<IContactInformationProps> = ({ billingInfo }) => {
  const { purchaseOrderNumber } = billingInfo;
  return (
    <Space direction="vertical" size="small">
      <div className="title">
        <Trans>Billing</Trans>
      </div>
      <div className={styles.billingContainer}>
        {!purchaseOrderNumber ? <Trans>None Provided</Trans> : `PO # ${purchaseOrderNumber}`}
      </div>
    </Space>
  );
};

export default Billing;

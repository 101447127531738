export const MONTH_DAY_YEAR_DATE_FORMAT = 'MM/DD/YYYY';
export const RECAPTCHA_SITE_KEYS = '6Lf22CsjAAAAALFHCnU2XTSRIyASXuF0yZ5l6-yK';
export const RECAPTCHA_URL = `https://www.google.com/recaptcha/api.js`;
export const RECAPTCHA_ID = 'recaptcha-container';
export const RECAPTCHA_SCRIPT_ID = 'recaptcha-script';
export const MIN_RECORDS_FOR_PAGINATION = 7;
export const MAX_LIST_COUNT = 9999;
export const ADD_NEW_PAYMENT_OPTION = 'add-new-option';
export const ONE_TIME_PURCHASE = 'one-time-purchase';
export const PAYMENT_METHODS_AMOUNT_LIMIT = 3;
export const ADDITIONAL_CONTACTS_LIMIT = 5;

import React, { FC } from 'react';
import { t, Trans } from '@lingui/macro';
import ConfirmationModal from './ConfirmationModal';

interface IRemovePhoneNumberConfirmationModalProps {
  showModal: boolean;
  onOk: () => void;
  onCancel?: (nextLocation: any) => void | (() => void);
  mobilePhoneNumber?: string;
}

const RemovePhoneNumberConfirmationModal: FC<IRemovePhoneNumberConfirmationModalProps> = ({
  showModal,
  onOk,
  onCancel,
  mobilePhoneNumber,
}) => {
  const cancelButtonText = t`Go Back`;
  const okButtonText = t`Confirm`;
  const title = (
    <>
      <h1 className="text-xl font-extrabold leading-6 xsm:leading-5">
        <Trans>Confirm Removal of Mobile Phone Number</Trans>
      </h1>
      <p className="remove-phone-title pt-5">{mobilePhoneNumber}</p>
    </>
  );
  const content = (
    <p className="italic">
      <Trans>
        NOTE: You are opted into text alerts, which requires a mobile phone number. If you remove this mobile phone
        number, you will no longer be able to receive text alerts. Please confirm that you would like to remove this
        Mobile Phone Number.
      </Trans>
    </p>
  );

  return (
    <>
      {showModal && (
        <ConfirmationModal
          onOk={onOk}
          onCancel={onCancel}
          showModal={showModal}
          cancelButtonText={cancelButtonText}
          okButtonText={okButtonText}
          title={title}
          content={content}
          closable={true}
          alignButtons="center"
          alignText="center"
        />
      )}
    </>
  );
};
export default RemovePhoneNumberConfirmationModal;

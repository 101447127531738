import React, { FC } from 'react';
import { DatePicker, Form } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import { t, Trans } from '@lingui/macro';
import { MONTH_DAY_YEAR_DATE_FORMAT } from '../../../../constants';
import { IVehicleFormItemProps } from '../../../Onboarding/Vehicles/OnboardingVehiclesForm/OnboardingVehicleForm';
import { buildErrorString, getInputName } from '../../../Onboarding/Vehicles/OnboardingVehiclesForm/vehicleForm.utils';
import classNames from 'classnames';

const EffectiveEndDate: FC<IVehicleFormItemProps> = ({ field, index, name, isInMyDashboard }) => {
  const {
    getValues,
    setValue,
    control,
    watch,
    trigger,
    formState: { errors },
  } = useFormContext();

  const isValidDate = watch(getInputName('effectiveEndDate', index))?.isValid();

  const isRental = isInMyDashboard ? 'isRental' : `vehicles.[${index}].isRental`;

  return (
    <div className="mb-4 w-full sm:mb-0 sm:w-3/12 sm:pr-2">
      <Form.Item
        {...field}
        id={name}
        className="theme"
        help={buildErrorString(name, errors, index)}
        label={<Trans id="Effective End Date" />}
        htmlFor={name}
        hasFeedback
        validateStatus={errors?.vehicles?.[0]?.effectiveEndDate || errors.effectiveEndDate ? 'error' : ''}
        required={watch(isRental)}
      >
        <Controller
          control={control}
          name={name}
          render={({ field, fieldState }) => (
            <DatePicker
              {...field}
              id={name}
              name={name}
              placeholder={t`Select`}
              format={MONTH_DAY_YEAR_DATE_FORMAT}
              onChange={(value) => {
                field.onChange(value);
                if (getValues('effectiveEndDate') === null) {
                  setValue(getInputName('effectiveEndTime', index), undefined);
                }
                trigger([getInputName('effectiveEndTime', index), getInputName('effectiveEndDate', index)]);
              }}
              onBlur={field.onBlur}
              allowClear={!watch(getInputName('isRental', index))}
              value={isValidDate ? watch(getInputName('effectiveEndDate', index)) : undefined}
              className={`${classNames({ 'input-error': fieldState?.invalid })} ${'end-date w-full'}`}
            />
          )}
        />
      </Form.Item>
    </div>
  );
};

export default EffectiveEndDate;

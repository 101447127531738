interface IVerifyRecaptchaOutput {
  action: string;
  challengeTimestamp: string;
  hostname: string;
  score: number;
  isSuccess: boolean;
}

interface IVerifyRecaptchaServerResponse {
  data: {
    verifyRecaptcha: IVerifyRecaptchaOutput;
  };
}

export const getRecaptchaResponse = async (): Promise<IVerifyRecaptchaServerResponse> => {
  const token = grecaptcha.getResponse();
  const res = await fetch('/graphql/verifyRecaptcha', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: `
  mutation verifyRecaptcha {
    verifyRecaptcha(token: "${token}") {
      isSuccess
    }
  }
`,
    }),
  });
  return await res.json();
};

import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { Trans } from '@lingui/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { colors } from 'src/theme/theme.js';
import sunpassLogo from '../../assets/SunPassLogo-Blue.svg';
import LanguagePicker from '../LanguagePicker/LanguagePicker';
import './AppHeader.scss';
import { useAuth } from '../../store/authentication/authentication.store';
import DropDownMenu from './DropDownMenu';
import { concatRoutes, DashboardParams, MainRoutes, OnboardingStepPath } from '../../types/routes';
import Alerts from '../Alerts/Alerts';
import HomePageAlert from './HomePageAlert';
import { useDashboardStore } from 'src/store/dashboard/dashboard.store';

const AppHeader: FC = () => {
  const {
    store: { showAlert },
  } = useDashboardStore();

  const {
    details: { isAuthenticated },
  } = useAuth();
  return (
    <div className="border-b-8 border-primary-yellow1">
      <header className="grid w-full grid-cols-2 bg-primary-white px-9 py-4 text-primary-gray1 sm:grid-cols-3 sm:px-10 sm:px-5">
        <div className="col-start-1 grid grid-rows-1 text-sm sm:text-base">
          <span className="florida-text sm:hidden">
            <Trans>
              Affiliated with <button className="text-primary-green1 underline">Florida Turnpike Enterprise</button>
            </Trans>
          </span>
          <span className="hidden sm:inline">
            <Trans>
              This site is affiliated with{' '}
              <button className="text-left text-primary-green1 underline">Florida Turnpike Enterprise </button> and{' '}
              <button className="text-left text-primary-green1 underline">SunPass</button>
            </Trans>
          </span>
        </div>
        <div className="col-start-1 grid grid-rows-1 justify-start sm:col-start-2 sm:justify-center">
          <Link
            to={
              isAuthenticated ? concatRoutes(MainRoutes.DASHBOARD, '/', DashboardParams.MY_DASHBOARD) : MainRoutes.ROOT
            }
            aria-label="Sunpass Prepaid Toll Program Link"
          >
            <img className="main-header-logo mt-2 sm:mt-0" src={sunpassLogo} alt="sunpass logo"></img>
          </Link>
        </div>
        <div className="col-start-2 grid grid-flow-row sm:col-start-3">
          <div className="mr-2 flex items-baseline justify-end text-xs uppercase tracking-wider">
            <Link
              to={MainRoutes.ROOT}
              className="mr-4 hidden text-sm font-semibold text-primary-gray1 no-underline md:block"
            >
              <Trans>Contact Us</Trans>
            </Link>
            <div className="language pr-4 sm:pr-0">
              <LanguagePicker></LanguagePicker>
            </div>
          </div>

          <div className="text-right">
            {isAuthenticated ? (
              <>
                <div className="flex flex-row justify-end">
                  <Alerts />
                  <div
                    style={{ position: 'relative' }}
                    className="ml-5 flex cursor-pointer items-center pr-0 text-base"
                  >
                    <DropDownMenu />
                  </div>
                </div>
              </>
            ) : (
              <>
                <Link to={OnboardingStepPath.INITIAL} aria-label="onboarding">
                  <FontAwesomeIcon
                    className="absolute right-6 top-12 mt-5 inline align-middle sm:right-12 lg:relative lg:right-0 lg:top-0 lg:mr-1 lg:mt-0"
                    icon={faUserCircle}
                    color={colors.primary.gray1}
                    size={'2x'}
                  />
                </Link>
                <Link
                  to={OnboardingStepPath.INITIAL}
                  className="text-bold col-span-2 mr-0 hidden align-middle font-bold text-primary-gray1 no-underline sm:mr-1 lg:inline"
                >
                  <Trans>Create an account</Trans>
                </Link>
              </>
            )}
          </div>
        </div>
      </header>

      {showAlert ? <HomePageAlert /> : null}
    </div>
  );
};

export default AppHeader;

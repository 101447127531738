import React from 'react';
import { Trans } from '@lingui/macro';
import { useFormContext } from 'react-hook-form';
import { IAccountInfo } from '../../../types/onboarding.types';

export const FormRequiredFieldsMsg = () => {
  const {
    formState: { isValid, isSubmitted },
  } = useFormContext<IAccountInfo>();

  return (
    <>
      {isSubmitted && !isValid ? (
        <div className="form-error new-payment-content mt-4 text-center">
          <Trans>Please complete the required fields to move forward</Trans>
        </div>
      ) : null}
    </>
  );
};

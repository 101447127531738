import { useLazyQuery } from '@apollo/client';
import {
  IAddressValidationInput,
  IAddressValidationOutput,
  STANDARDIZE_ADDRESS,
} from '../graphql/addressValidation.graphql';

export const useAddressValidation = () => {
  const [getStandardizedAddress, { data: standardizedAddressData, loading: standardizedAddressLoading }] = useLazyQuery<
    { addressValidation: IAddressValidationOutput },
    { addressValidationInput: IAddressValidationInput }
  >(STANDARDIZE_ADDRESS);

  return {
    getStandardizedAddress,
    standardizedAddressData,
    standardizedAddressLoading,
  };
};

import {
  AccountSubtype,
  AccountType,
  AccountTypeInfo,
  OnboardingPinellasOptions,
  OnboardingUserType,
} from '../../types/onboarding.types';
import { OnboardingStepPath } from '../../types/routes';

export function determineAccountType(userType: OnboardingUserType): AccountType {
  let accountType: AccountType;

  switch (userType) {
    case OnboardingUserType.VISITOR: {
      accountType = AccountType.PAYG;
      break;
    }
    case OnboardingUserType.RESIDENT: {
      accountType = AccountType.PRIVATE;
      break;
    }
    case OnboardingUserType.GOVERNMENT: {
      accountType = AccountType.GOVERNMENT;
      break;
    }
    case OnboardingUserType.COMMERCIAL: {
      accountType = AccountType.COMMERCIAL;
      break;
    }
    default: {
      throw new Error('Could not determine account type');
    }
  }

  return accountType;
}

export function determineAccountSubtype(pinellasUse: OnboardingPinellasOptions): AccountSubtype {
  switch (pinellasUse) {
    case OnboardingPinellasOptions.FREQUENT:
      return AccountSubtype.PBC;
    case OnboardingPinellasOptions.NO_PINELLAS:
    default:
      return AccountSubtype.NONE;
  }
}

export function getNextPageOnAccountRecommendation(account: AccountTypeInfo): string {
  const { accountType, accountSubtype } = account;

  switch (accountType) {
    case AccountType.PAYG:
      return OnboardingStepPath.ACCOUNT;
    case AccountType.PRIVATE:
      if (accountSubtype === AccountSubtype.NONE) return OnboardingStepPath.PINELLAS;
      else return OnboardingStepPath.ACCOUNT;
    case AccountType.COMMERCIAL:
      return OnboardingStepPath.TRANSPONDERS_ACTIVATION;
    default:
      return '';
  }
}

import moment from 'moment/moment';
import { IVehicle } from 'src/graphql/vehicles.graphql';
import { FL_STATE_CODE, USA_COUNTRY_ISO3 } from 'src/utils/countryStates.utils';
import { formatTime, US_LOCAL_TIME_FORMAT } from 'src/utils/time.utils';

export const DEFAULT_VEHICLE_FORM_VALUES = {
  vehicleCountry: USA_COUNTRY_ISO3,
  plateState: FL_STATE_CODE,
  effectiveStartDate: moment(),
  effectiveStartTime: moment().startOf('hour').format(US_LOCAL_TIME_FORMAT),
};

export const mapDefaultVehicleFormValues = (vehicleToEdit: IVehicle): IVehicle => {
  return {
    effectiveEndDate: vehicleToEdit?.effectiveEndDate && moment(vehicleToEdit?.effectiveEndDate),
    effectiveEndTime: vehicleToEdit?.effectiveEndTime && formatTime(vehicleToEdit?.effectiveEndTime as string),
    effectiveStartDate: vehicleToEdit?.effectiveStartDate && moment(vehicleToEdit?.effectiveStartDate),
    effectiveStartTime: vehicleToEdit?.effectiveStartTime && formatTime(vehicleToEdit?.effectiveStartTime as string),
    index: vehicleToEdit?.index,
    isRental: vehicleToEdit?.isRental,
    isTrailer: vehicleToEdit?.isTrailer,
    plateNumber: vehicleToEdit?.plateNumber,
    plateState: vehicleToEdit?.plateState,
    plateType: vehicleToEdit?.plateType,
    vehicleColor: vehicleToEdit?.vehicleColor,
    vehicleCountry: vehicleToEdit?.vehicleCountry,
    vehicleId: vehicleToEdit?.vehicleId,
    vehicleMake: vehicleToEdit?.vehicleMake,
    vehicleModel: vehicleToEdit?.vehicleModel,
    vehicleYear: vehicleToEdit?.vehicleYear,
  };
};

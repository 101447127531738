import React, { FC, useCallback, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { convertPriceToString } from '../../../utils/formatter';
import { useQuery } from '@apollo/client';
import { GET_ACCOUNT_DETAILS, IAccountDetails } from '../../../graphql/accountDetails.graphql';
import PaymentMethodCard from '../../Payments/PaymentMethods/PaymentMethodCard';
import { useContactSettingsStore } from '../../../store/account/contactSettings.store';
import CustomAddress from '../../Address/CustomAddress';
import { Controller, useFormContext } from 'react-hook-form';
import { IContactSettings } from './Contact';
import { useSortPaymentMethods } from 'src/hooks/domain/payments/useSortPaymentMethods';
import ChosePaymentMethod from 'src/components/Payments/ChosePaymentMethod/ChosePaymentMethod';
import { ADD_NEW_PAYMENT_OPTION } from 'src/constants';
import { Form, Select } from 'antd';
import { Option } from 'antd/es/mentions';
import { GET_CLOSE_ACCOUNT_REASONS } from '../../../graphql/closeAccount.graphql';
import { MainLoader } from '../../Loader/MainLoader';
import { capitalize } from 'lodash';
import { useSecurityCodeModalStore } from 'src/store/payment/securityCodeModal.store';

interface ICloseAccountDetails {
  setIsCloseAccountActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const CloseAccountDetails: FC<ICloseAccountDetails> = ({ setIsCloseAccountActive }) => {
  const {
    control,
    formState: { errors, isSubmitted, isValid },
  } = useFormContext<IContactSettings>();
  const [showNewCard, setShowNewCard] = useState(false);
  const [showExistingCard, setShowExistingCard] = useState(false);

  const { setIsNewPaymentMethod } = useSecurityCodeModalStore();

  const { reset } = useFormContext<IContactSettings>();

  const {
    closeAccountInfo: { existingCard },
    updateCloseAccountInfo,
  } = useContactSettingsStore();

  const { data: accountDetailsData } = useQuery<{ accountDetails: IAccountDetails }>(GET_ACCOUNT_DETAILS);
  const { data: closeAccountReasons, loading } = useQuery<{ closeAccountReasons: string[] }>(GET_CLOSE_ACCOUNT_REASONS);

  const convertedCurrentBalance = convertPriceToString(accountDetailsData?.accountDetails.currentBalance || 0);

  const { sortedPaymentMethods } = useSortPaymentMethods(false);

  const cancelOnClick = () => {
    updateCloseAccountInfo({ isCloseProcessActive: false, existingCard: null, isNewCardChosen: false });
    setIsCloseAccountActive(false);
    reset();
    setShowNewCard(false);
  };

  const handleChange = (value: string) => {
    if (value === ADD_NEW_PAYMENT_OPTION) {
      setIsNewPaymentMethod(true);
      setShowNewCard(true);
      setShowExistingCard(false);
      updateCloseAccountInfo({ existingCard: null, isNewCardChosen: true });
    } else {
      setIsNewPaymentMethod(false);
      const card = sortedPaymentMethods?.find((card) => {
        return card.paymentMethodId === value;
      });
      card && updateCloseAccountInfo({ existingCard: card, isNewCardChosen: false });
      setShowNewCard(false);
      setShowExistingCard(true);
    }
  };

  const setBalanceMsg = useCallback(() => {
    if (accountDetailsData?.accountDetails && accountDetailsData?.accountDetails.currentBalance > 0) {
      return (
        <Trans>
          Your current account balance is{' '}
          <span
            className={`${
              accountDetailsData?.accountDetails && accountDetailsData.accountDetails.currentBalance < 0
                ? 'text-red'
                : 'text-primary-green1'
            } font-bold`}
          >
            {convertedCurrentBalance}
          </span>
          . You will receive a refund to the payment method used to make your last payment. If that payment is
          unavailable, you will receive a check by mail. Select &apos;Save Changes&apos; below to complete your account
          closure.
        </Trans>
      );
    } else if (accountDetailsData?.accountDetails && accountDetailsData.accountDetails.currentBalance < 0) {
      return (
        <Trans>
          Your current account balance is{' '}
          <span
            className={`${
              accountDetailsData?.accountDetails && accountDetailsData?.accountDetails.currentBalance < 0
                ? 'text-red'
                : 'text-primary-green1'
            } font-bold`}
          >
            {convertedCurrentBalance}
          </span>
          . Please select which payment method you&apos;d like to use to pay your remaining balance.
        </Trans>
      );
    } else {
      return (
        <Trans>
          Looks like you don&apos;t have any balances to settle. Select &apos;Save Changes&apos; below to complete your
          account closure.
        </Trans>
      );
    }
  }, [accountDetailsData?.accountDetails.currentBalance]);

  return (
    <>
      <MainLoader loading={loading} />
      <Form.Item
        label={<Trans>Please select a reason for closing your account</Trans>}
        hasFeedback
        help={errors.reason?.message}
        validateStatus={errors.reason ? 'error' : ''}
        className="theme"
        required
      >
        <Controller
          name="reason"
          control={control}
          render={({ field }) => (
            <Select {...field} id="reason" key="reason" aria-label={t`Reason for closing your account`}>
              {closeAccountReasons?.closeAccountReasons.map((reason, index) => (
                <Option key={`${index}`} value={reason}>
                  {capitalize(reason?.replace(/_/g, ' '))}
                </Option>
              ))}
            </Select>
          )}
        />
      </Form.Item>
      <p className="subtitle mt-4">
        <Trans>
          By closing your account, you will lose the benefits of being a SunPass account holder and your transponders
          will no longer work on Florida Toll Roads. Any future toll charges incurred will be communicated in a mailed
          invoice according to your license plate number.
        </Trans>
      </p>

      <div
        className={`subtitle ${
          !showExistingCard && !showNewCard
            ? 'border-2 border-primary-yellow1 bg-primary-yellow1Fade px-6 py-4'
            : 'bg-primary-white'
        }`}
      >
        {setBalanceMsg()}
      </div>
      {accountDetailsData?.accountDetails && accountDetailsData.accountDetails.currentBalance > 0 ? (
        <p className="subtitle mt-4 italic">
          <Trans>
            Note: Before proceeding to close your account you may wish to verify or update the mailing address
            associated with your account so that your refund can be processed. If you wish to do so, select
            &apos;cancel&apos; and re-initiate the account closure once your mailing address has been updated.
          </Trans>
        </p>
      ) : null}

      {accountDetailsData?.accountDetails && accountDetailsData.accountDetails.currentBalance < 0 ? (
        <div>
          <div className="my-8 flex w-full flex-col justify-center sm:max-w-sm">
            <ChosePaymentMethod handleChange={handleChange} sortedPaymentMethods={sortedPaymentMethods} />
          </div>
          {showNewCard ? (
            <>
              <PaymentMethodCard showCVV={true} />
              <h3 className="mt-6 text-primary-blue1">
                <Trans>Billing Address</Trans>
              </h3>
              <CustomAddress isAddressUsedForPayment countyRequired={false} />

              <div className="subtitle mt-6 border-2 border-primary-yellow1 bg-primary-yellow1Fade px-6 py-4">
                <Trans>
                  When you close your account, this payment method will be charged{' '}
                  <strong>{convertPriceToString(accountDetailsData?.accountDetails.currentBalance)}</strong>. Select
                  &apos;Save Changes&apos; below to complete your account closure.
                </Trans>
              </div>
            </>
          ) : null}
          {showExistingCard ? (
            <div className="border-2 border-primary-yellow1 bg-primary-yellow1Fade px-6 py-4">
              <Trans>
                <strong>Credit Card (x{existingCard?.cardNumber.slice(-4)})</strong> will be charged{' '}
                <strong>
                  {convertPriceToString(Math.abs(accountDetailsData?.accountDetails.currentBalance || 0))}
                </strong>
                . Select &apos;Save Changes&apos; below to complete your account closure.
              </Trans>
            </div>
          ) : null}
        </div>
      ) : null}
      <div className="mx-auto mb-8 mt-4 text-center">
        <button className="text-red underline" onClick={cancelOnClick}>
          Cancel
        </button>
      </div>
      {isSubmitted && !isValid && showNewCard ? (
        <div className="form-error new-payment-content mt-4 text-center">
          <Trans>Please complete the required fields to move forward</Trans>
        </div>
      ) : null}
    </>
  );
};

export default CloseAccountDetails;

import React from 'react';
import { IAccountDetails } from '../../../../graphql/accountDetails.graphql';
import { convertPriceToString } from '../../../../utils/formatter';
import { useSortPaymentMethods } from '../../../../hooks/domain/payments/useSortPaymentMethods';
import { Trans } from '@lingui/macro';

export interface ILeftCardProps {
  account: IAccountDetails | undefined;
}

export const UsersCard = ({ account }: ILeftCardProps) => {
  const currentBalance = account?.currentBalance || 0;

  const accountNumber = account?.accountNumber;

  const { sortedPaymentMethods } = useSortPaymentMethods(true);
  const defaultPaymentMethod = sortedPaymentMethods?.[0];
  const isOptInEasyPay = defaultPaymentMethod?.isEasyPay;

  return (
    <div className={`balance-card-container users-card ${isOptInEasyPay ? 'users-card-ep' : 'users-card-no-ep'}`}>
      <div className="balance-account-container text-white">
        <div className="flex flex-col text-left">
          <span className="current-balance-text">
            <Trans>Current Balance</Trans>
          </span>
          <span className="font-bold">{convertPriceToString(currentBalance)}</span>
        </div>
        <div className="flex flex-col text-left">
          <span>
            <Trans>Account Number</Trans>
          </span>
          <span className="font-bold">{accountNumber}</span>
        </div>
      </div>
    </div>
  );
};

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';
import { ILoader } from './MainLoader';
import './Loader.scss';

export const SimpleLoader = ({ loading }: ILoader) => {
  return loading ? (
    <Spin
      className="simple-loader-container"
      indicator={
        <div className="simple-loader-icon">
          <LoadingOutlined />
        </div>
      }
    />
  ) : null;
};

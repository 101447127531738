import { Trans } from '@lingui/macro';
import React from 'react';
import { useTransponderStore } from '../../../../store/dashboard/transponder.store';
import {
  ITransponder,
  ITransponderInput,
  ITransponderList,
  IUpdateTransponderInput,
  TransponderStatus,
  TransponderType,
  UPDATE_TRANSPONDER,
} from '../../../../graphql/transponders.graphql';
import '../Transponder.scss';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import { MainLoader } from '../../../Loader/MainLoader';
import { DEFAULT_TRANSPONDERS_FETCH_PARAMS } from './defaultValues';
import { formatDate } from '../../../../utils/time.utils';

export interface ITransponderListItem {
  currentTransponder: ITransponder;
  editTransponder: (transponderNumber: string) => void;
  refetch: (
    variables?: Partial<{ transponderListInput: ITransponderInput }> | undefined,
  ) => Promise<ApolloQueryResult<{ transponderList: ITransponderList }>>;
  resetPagination?: boolean;
  setResetPagination?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TransponderListItem = ({
  currentTransponder,
  editTransponder,
  refetch,
  setResetPagination,
}: ITransponderListItem) => {
  const {
    store: { editMode, activateMode },
    setIsDeactivationModalOpen,
    setTransponderToEdit,
  } = useTransponderStore();

  const [updateTransponder, { loading: updateLoading }] = useMutation<
    { updateTransponder: boolean },
    { updateTransponderInput: IUpdateTransponderInput }
  >(UPDATE_TRANSPONDER);

  const changeStatus = async (status: TransponderStatus) => {
    try {
      await updateTransponder({
        variables: {
          updateTransponderInput: {
            transponderNumber: currentTransponder.transponderNumber,
            newStatus: status,
            friendlyName: currentTransponder.friendlyName,
          },
        },
      });
    } catch (err) {
      console.log(err);
    }
    await refetch({
      transponderListInput: DEFAULT_TRANSPONDERS_FETCH_PARAMS,
    });
    if (setResetPagination) {
      setResetPagination(true);
    }
  };

  const reactivateTransponder = async () => {
    await changeStatus(TransponderStatus.ACTIVE);
  };
  const onDeactivate = () => {
    setTransponderToEdit(currentTransponder);
    setIsDeactivationModalOpen(true);
  };

  return (
    <>
      <MainLoader loading={updateLoading} />
      <>
        {currentTransponder.status !== TransponderStatus.ACTIVE ? (
          <td className="mr-3 w-2/12">
            <span className="w-fit rounded-md bg-primary-gray6 px-2 py-1">
              <Trans>Deactivated</Trans>
            </span>
          </td>
        ) : (
          <td className="mr-3 w-2/12">{formatDate(currentTransponder.date, true)}</td>
        )}
        <td className="mx-2 w-1/4">{currentTransponder.friendlyName || '-'}</td>
        <td className="mx-2 w-1/6">
          {TransponderType[currentTransponder.transponderType as unknown as keyof typeof TransponderType]}
        </td>
        <td className="mx-2 w-1/6">{currentTransponder.transponderNumber}</td>
      </>
      <td className="ml-auto flex h-20 w-3/12 w-full flex-row items-center justify-end pl-0 pr-6 sm:w-auto">
        {currentTransponder.status === TransponderStatus.ACTIVE ? (
          <>
            <button
              className="remove-btn w-7/12 rounded-md px-5 pb-0.5 pt-1 text-center sm:w-auto"
              disabled={editMode || activateMode}
              onClick={onDeactivate}
            >
              <Trans>Deactivate</Trans>
            </button>

            <button
              className="edit-btn ml-5 w-5/12 rounded-md px-5 pb-0.5 pt-1 text-center md:w-auto"
              onClick={() => editTransponder(currentTransponder.transponderNumber)}
              disabled={editMode || activateMode}
            >
              <Trans>Edit</Trans>
            </button>
          </>
        ) : (
          <button
            className="remove-btn rounded-md px-5 pb-0.5 pt-1 text-center sm:w-auto"
            disabled={editMode || activateMode}
            onClick={reactivateTransponder}
          >
            <Trans>Reactivate</Trans>
          </button>
        )}
      </td>
    </>
  );
};

export type CountryType = 'MEX' | 'USA' | 'CAN';

export const COUNTRY_NAME_MAPPER: Record<CountryType, string> = {
  MEX: 'Mexico',
  USA: 'United States',
  CAN: 'Canada',
};

export const mapCountryToDefaultState: Record<CountryType, string | null> = {
  MEX: null,
  USA: 'FL',
  CAN: null,
};

export const USA_COUNTRY_ISO3: CountryType = 'USA';
export const CANADA_COUNTRY_ISO3: CountryType = 'CAN';
export const MEXICO_COUNTRY_ISO3: CountryType = 'MEX';
export const FL_STATE_CODE = 'FL';

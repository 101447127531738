import moment from 'moment/moment';
import { FL_STATE_CODE, USA_COUNTRY_ISO3 } from 'src/utils/countryStates.utils';
import { US_LOCAL_TIME_FORMAT } from '../../../utils/time.utils';

export const DEFAULT_VEHICLE = {
  vehicleCountry: USA_COUNTRY_ISO3,
  plateState: FL_STATE_CODE,
  effectiveStartDate: moment(),
  effectiveStartTime: moment().startOf('hour').format(US_LOCAL_TIME_FORMAT),
};

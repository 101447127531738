import { FC, useEffect } from 'react';
import styles from '../Dashboard/Activity/Activity.module.scss';
import { Trans } from '@lingui/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import ScreenSize from 'src/types/ScreenSize.enum';
import { emptyVehicle, useVehicleStore } from 'src/store/vehicles/vehicle.store';
import { usePagination } from 'src/hooks/pagination/usePagination';
import useScreenSize from 'src/hooks/screen/useScreenSize';
import { usePaginationInfo } from 'src/store/pagination/pagination.store';

interface ActivityPaginationProps {
  count: number;
  type: string;
  setVisibleActivityIndex?: React.Dispatch<React.SetStateAction<string>>;
  resetPagination?: boolean;
  setResetPagination?: React.Dispatch<React.SetStateAction<boolean>>;
}

const Pagination: FC<ActivityPaginationProps> = ({
  count,
  type,
  setVisibleActivityIndex,
  resetPagination,
  setResetPagination,
}) => {
  let page: number;
  let size: number;

  const {
    vehiclesPagination: { size: vehiclesSize, page: vehiclesPage },
    transpondersPagination: { size: transpondersSize, page: transpondersPage },
    activitiesPagination: { size: activitiesSize, page: activitiesPage },
    invoiceTransactionsPagination: { size: invoiceTransactionsSize, page: invoiceTransactionsPage },
    changeVehiclesPageNumber: changeVehiclesPage,
    changeTranspondersPageNumber: changeTranspondersPage,
    changeActivitiesPageNumber: changeActivitiesPage,
    changeInvoiceTransactionsPageNumber: changeInvoiceTransactionPage,
  } = usePaginationInfo();

  switch (type) {
    case 'vehicles':
      page = vehiclesPage;
      size = vehiclesSize;
      break;
    case 'transponders':
      page = transpondersPage;
      size = transpondersSize;
      break;
    case 'activities':
      page = activitiesPage;
      size = activitiesSize;
      break;
    case 'invoiceTransactions':
      page = invoiceTransactionsPage;
      size = invoiceTransactionsSize;
      break;
    default:
      page = 0;
      size = 0;
  }

  useEffect(() => {
    if (resetPagination === true) {
      setPagination(1);
      if (setResetPagination) {
        setResetPagination(false);
      }
      location.reload();
    }
  }, [resetPagination]);

  const paginationRange = usePagination(count, size, 1, page);
  const { screenSize } = useScreenSize();
  const pageNumber = Math.ceil(count / size);

  const { setIsFormVisible, setVehicleToEdit } = useVehicleStore();

  const setPagination = (item: number) => {
    if (type === 'vehicles') {
      setIsFormVisible(false);
      setVehicleToEdit(emptyVehicle);
      changeVehiclesPage(item);
    }
    if (type === 'activities') {
      setVisibleActivityIndex && setVisibleActivityIndex('');
      changeActivitiesPage(item);
    }
    if (type === 'transponders') {
      changeTranspondersPage(item);
    }
    if (type === 'invoiceTransactions') {
      changeInvoiceTransactionPage(item);
    }
  };

  return count ? (
    <>
      <div className={styles['pagination-container']}>
        <button
          className={`${page === 1 ? styles['btn-hidden'] : styles['pagination-btn-previous']}`}
          onClick={() => setPagination(page - 1)}
        >
          <FontAwesomeIcon icon={faChevronLeft} color="green" className="mb-1 mr-2" />
          {screenSize > ScreenSize.SM ? <Trans>Previous</Trans> : null}
        </button>
        {paginationRange?.map((number, index) => {
          if (number === '...') {
            return (
              <span key={index} className={styles['pagination-dots']}>
                &#8230;
              </span>
            );
          }
          return (
            <button
              className={number === page ? styles['current-page-btn'] : styles['pagination-btn']}
              key={index}
              onClick={() => setPagination(Number(number))}
            >
              {number}
            </button>
          );
        })}
        <button
          className={`${page === pageNumber ? styles['btn-hidden'] : styles['pagination-btn-next']}`}
          onClick={() => setPagination(page + 1)}
        >
          {screenSize > ScreenSize.SM ? <Trans>Next</Trans> : null}
          <FontAwesomeIcon icon={faChevronRight} color="green" className="mb-1 ml-2" />
        </button>
      </div>
    </>
  ) : null;
};

export default Pagination;

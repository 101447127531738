import React, { useEffect } from 'react';
import { Form } from 'antd';
import { Trans, t } from '@lingui/macro';
import { Controller, useFormContext } from 'react-hook-form';
import StyledInput from '../StyledInput/StyledInput';
import { ISecurityInfo } from '../../types/onboarding.types';
import { useRouteMonitor } from '../../hooks/location/useRouteMonitor';
import { OnboardingStepPath } from '../../types/routes';
import '../AppForm/AppForm.scss';
interface IReTypePasswordProps {
  password?: string | undefined;
  isUpdatePasswordMode?: boolean;
}

export const ReTypePassword = ({ password, isUpdatePasswordMode }: IReTypePasswordProps) => {
  const {
    control,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<ISecurityInfo>();

  const watchPassword = watch('password');

  const history = useRouteMonitor();
  const fullOnboardingHistory = history.getFullOnboardingHistory();
  const prevPath = fullOnboardingHistory.length >= 2 ? fullOnboardingHistory[fullOnboardingHistory.length - 2] : '';
  const shouldClearReTypePassword =
    prevPath !== OnboardingStepPath.TRANSPONDERS &&
    prevPath !== OnboardingStepPath.VEHICLES &&
    prevPath !== OnboardingStepPath.REVIEW;

  useEffect(() => {
    if (shouldClearReTypePassword) {
      setValue('reTypePassword', '');
      clearErrors('reTypePassword');
    }
  }, [isUpdatePasswordMode]);

  return (
    <div className="flex flex-wrap">
      <div className="mb-10 w-full sm:w-6/12">
        <Form.Item
          label={<Trans>Re-Type Password</Trans>}
          className="theme"
          help={errors.reTypePassword?.message}
          validateTrigger={['onChange', 'onBlur']}
          required
        >
          <Controller
            name="reTypePassword"
            control={control}
            render={({ field, fieldState }) => (
              <StyledInput
                field={field}
                fieldstate={fieldState}
                onPaste={(e) => e.preventDefault()}
                id="reTypePassword"
                key="reTypePassword"
                type={'password'}
                maxLength={64}
                defaultValue={password || watchPassword}
                aria-label={t`Re-type password`}
              />
            )}
          />
        </Form.Item>
      </div>
    </div>
  );
};

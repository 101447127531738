import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import React, { FC } from 'react';

interface IAccountTypeProps {
  localizedPlanName: string;
}

const AccountType: FC<IAccountTypeProps> = ({ localizedPlanName }) => {
  return (
    <Space direction="vertical" size="small">
      <div className="title">
        <Trans>Account Type</Trans>
      </div>
      <div>{localizedPlanName}</div>
    </Space>
  );
};

export default AccountType;

import { Button } from 'antd';
import React from 'react';
import './AddFunds.scss';
import { useFormContext } from 'react-hook-form';
import { IAddFunds } from './AddFunds';

interface IAddFundsButtonProps {
  value: string;
}

const AddFundsButton = ({ value }: IAddFundsButtonProps) => {
  const deposit = Number(value);

  const { watch, setValue, resetField, trigger } = useFormContext<IAddFunds>();

  const setAmount = () => {
    setValue('addFundsButton', value);
    setValue('actualAmount', Number(value));
    resetField('otherAmount');
    trigger('actualAmount');
  };

  return (
    <div className="button-container">
      <Button
        className={`funds-button ${
          watch('addFundsButton') === value && (watch('otherAmount') === undefined || watch('otherAmount') === 0)
            ? 'green-button'
            : ''
        }`}
        value={deposit}
        onClick={() => {
          setAmount();
        }}
      >
        {'$' + value}
      </Button>
    </div>
  );
};

export default AddFundsButton;

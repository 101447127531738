import * as yup from 'yup';
import { validators } from 'src/validators/validators';
import {
  ACCOUNT_NUMBER_OR_TRANSPONDER_NUMBER_REQUIRED,
  ACCOUNT_NUMBER_OR_USERNAME_REQUIRED,
  NAME_AND_RESPONSE_MAX_ERROR_MSG,
  PASSWORD_ERROR_MSG,
  RESPONSE_REQUIRED,
} from 'src/validators/onboarding/accountInfo/accountInfoErrorMessages';
import { ActionType } from './ForgotUsernameOrPassword';
import { ApolloError } from '@apollo/client';

export const basePasswordSchema = {
  postalCode: validators.postalCode,
  accountNumber: validators.accountNumberOptional,
  username: yup.string().when('accountNumber', {
    is: (accountNumber: string) => !accountNumber || accountNumber === null,
    then: validators.username(ACCOUNT_NUMBER_OR_USERNAME_REQUIRED),
  }),
};

export const baseUsernameSchema = {
  postalCode: validators.postalCode,
  accountNumber: validators.accountNumberOptional,
  transponderNumber: yup.string().when('accountNumber', {
    is: (accountNumber: string) => !accountNumber || accountNumber === null,
    then: validators.transponderNumber(ACCOUNT_NUMBER_OR_TRANSPONDER_NUMBER_REQUIRED),
  }),
};

export const passwordSchemaWithAdditionalFields = {
  ...basePasswordSchema,
  answer: yup.string().required(RESPONSE_REQUIRED).max(40, NAME_AND_RESPONSE_MAX_ERROR_MSG),
  password: validators.password.required(PASSWORD_ERROR_MSG),
  reTypePassword: validators.passwordConfirm(),
};

export const getForgotCredentialsSchemaBy = (
  actionType: ActionType,
  showSecurityQuestion: boolean,
  forgotPasswordGetSecurityQuestionError: ApolloError | undefined,
) => {
  const passwordSchema =
    showSecurityQuestion && !forgotPasswordGetSecurityQuestionError
      ? passwordSchemaWithAdditionalFields
      : basePasswordSchema;

  return yup.object().shape(actionType === 'resetPassword' ? { ...passwordSchema } : { ...baseUsernameSchema }, [
    ['username', 'accountNumber'],
    ['transponderNumber', 'accountNumber'],
  ]);
};

import { DatePicker, Form } from 'antd';
import { t } from '@lingui/macro';
import { Controller, useFormContext } from 'react-hook-form';
import { MONTH_DAY_YEAR_DATE_FORMAT } from '../../../../../constants';
import moment from 'moment';
import React from 'react';

export interface IDateInput {
  name: string;
  title: string | JSX.Element;
  defaultValue?: moment.Moment;
}

export const DateInput = ({ name, title, defaultValue }: IDateInput) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();
  return (
    <div className="w-full sm:pr-4 md:w-3/12">
      <Form.Item
        label={title}
        htmlFor={name}
        className="theme"
        help={errors?.[`${name}`]?.message as unknown as string}
      >
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <DatePicker
              id={name}
              name={name}
              format={MONTH_DAY_YEAR_DATE_FORMAT}
              disabledDate={(current) => moment() < current}
              className="w-full"
              placeholder={t`Select`}
              onChange={(value) => {
                field.onChange(value);
              }}
              onBlur={field.onBlur}
              defaultValue={defaultValue ? defaultValue : moment()}
              value={watch(name)}
            />
          )}
        />
      </Form.Item>
    </div>
  );
};

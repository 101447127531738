import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './Onboarding.scss';
import OnboardingEZPay from '../../components/Onboarding/EasyPay/OnboardingEZPay';
import OnboardingPinellas from '../../components/Onboarding/Questions/OnboardingPinellas';
import OnboardingAccountRecommendation from '../../components/Onboarding/OnboardingRecommendation/OnboardingAccountRecommendation';
import OnboardingPinellasRecommendation from '../../components/Onboarding/OnboardingRecommendation/OnboardingPinellasRecommendation';
import OnboardingAccountType from '../../components/Onboarding/Questions/OnboardingAccountType';
import OnboardingSuccess from '../../components/Onboarding/OnboardingSuccess';
import OnboardingTransponders from '../../components/Onboarding/OnboardingTransponders/OnboardingTransponders';
import OnboardingPayment from '../../components/Onboarding/OnboardingPayment/OnboardingPayment';
import OnboardingVehicles from './Vehicles/OnboardingVehicles';
import OnboardingReviewYourInformation from '../../components/Onboarding/ReviewYourInformation/OnboardingReviewYourInformation';
import OnboardingFleet from '../../components/Onboarding/Fleet/OnboardingFleet';
import { OnboardingPrepaidTransponderQuestion } from '../../components/Onboarding/Questions/OnboardingPrepaidTransponderQuestion';
import { OnboardingRoutes } from 'src/types/routes';
import OnboardingAccountInfo from './AccountInfo/OnboardingAccountInfo';

export default function OnboardingStep(): React.ReactElement {
  return (
    <Routes>
      <Route path={OnboardingRoutes.HOME} element={<OnboardingPrepaidTransponderQuestion />} />

      <Route path={OnboardingRoutes.TRANSPONDERS_ACTIVATION} element={<OnboardingTransponders />} />

      <Route path={OnboardingRoutes.ACCOUNT_TYPE} element={<OnboardingAccountType />} />

      <Route path={OnboardingRoutes.FLEET} element={<OnboardingFleet />} />

      <Route path={OnboardingRoutes.PINELLAS_RECOMMENDATION} element={<OnboardingPinellasRecommendation />} />

      <Route path={OnboardingRoutes.ACCOUNT_RECOMMENDATION} element={<OnboardingAccountRecommendation />} />

      <Route path={OnboardingRoutes.PINELLAS} element={<OnboardingPinellas />} />

      <Route path={OnboardingRoutes.ACCOUNT} element={<OnboardingAccountInfo />} />

      <Route path={OnboardingRoutes.TRANSPONDERS} element={<OnboardingTransponders />} />

      <Route path={OnboardingRoutes.VEHICLES} element={<OnboardingVehicles />} />

      <Route path={OnboardingRoutes.EASY_PAY} element={<OnboardingEZPay />} />

      <Route path={OnboardingRoutes.PAYMENT_SUMMARY} element={<OnboardingPayment />} />

      <Route path={OnboardingRoutes.SUCCESS} element={<OnboardingSuccess />} />

      <Route path={OnboardingRoutes.REVIEW} element={<OnboardingReviewYourInformation />} />
    </Routes>
  );
}

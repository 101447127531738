import { useQuery } from '@apollo/client';
import { COUNTRIES, ICountry } from '../../../graphql/countries.graphql';
import { LabelValue } from '../../../types/LabelValue.interface';
import { COUNTRY_NAME_MAPPER, CountryType } from '../../../utils/countryStates.utils';
import { useState } from 'react';

interface IUseCountryOptions {
  countries: any[]; // TODO: Should add a type for API retrieved data
  countryOptions: LabelValue[];
  errorLoading: boolean;
  loading: boolean;
}

const useCountryOptions = (): IUseCountryOptions => {
  const [isError, setIsError] = useState(false);
  const { data, loading } = useQuery(COUNTRIES, {
    errorPolicy: 'all',
    onError: () => setIsError(true),
  });

  return {
    countries: data?.countries || [],
    countryOptions:
      data?.countries?.map(
        (country: ICountry): LabelValue => ({
          value: country.code,
          label: COUNTRY_NAME_MAPPER[country.code as CountryType],
        }),
      ) || [],
    errorLoading: isError,
    loading,
  };
};

export default useCountryOptions;

import { faInfoCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t, Trans } from '@lingui/macro';
import { Form, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { AccountType } from 'src/types/onboarding.types';
import '../../../pages/Onboarding/Onboarding.scss';
import OnboardingVehicle from '../../../components/Onboarding/Vehicles/OnboardingVehicle';
import { MainLoader } from '../../../components/Loader/MainLoader';
import ScrollToTop from '../../../components/ScrollToTop/ScrollToTop';
import { AccountCreatedSuccessfullyModal } from '../../../components/Onboarding/AccountCreatedSuccessfullyModal';
import { OnboardingStepPath } from '../../../types/routes';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import { ReferenceNumber } from '../../../components/Onboarding/ReferenceNumber';
import OnboardingHeader, { OnboardingHeaderOnlyProgressProps } from '../../../components/Onboarding/OnboardingHeader';
import { useOnboardingVehicle } from '../../../hooks/domain/onboarding/vehicles/useOnboardingVehicle';
import { useOnboardingStore } from '../../../store/onboarding/onboarding.store';
import { useRouteMonitor } from '../../../hooks/location/useRouteMonitor';
import BackButton from '../../../components/BackButton/BackButton';
import { useDashboardTabBulkUpload } from '../../../hooks/domain/dashboard/useDashboardTabBulkUpload';
import { BulkUploadTemplateLink } from '../../../components/Links/BulkUploadTemplateLink';
import {
  ISignUpVehiclesTranspondersInput,
  ISignUpVehiclesTranspondersOutput,
  SIGN_UP_VEHICLES_AND_TRANSPONDERS,
} from '../../../graphql/signUp/signUpVehiclesTransponders.graphql';
import { useMutation } from '@apollo/client';
import LeaveSiteConfirmationModal from 'src/components/Modals/ConfirmationModal/LeaveSiteConfirmationModal';
import { useCallbackPrompt } from 'src/hooks/location/useCallbackPrompt';
import { SELECT_RECOMMENDED_SUNPASS_PLAN_NAME } from '../../../store/onboarding/onboarding.selectors';
import { useOnboardingErrors } from 'src/store/errors/onboardingErrors.store';
import { ActivateCalls } from 'src/components/Onboarding/ActivateFlow/ActivateCalls';

export default function OnboardingVehicles(): React.ReactElement {
  const {
    onboardingInfo: { accountType, accountId, miniTransponderQty, proTransponderQty, accountSubtype },
    updateOnboardingStore,
  } = useOnboardingStore();
  const history = useRouteMonitor();
  const planName = SELECT_RECOMMENDED_SUNPASS_PLAN_NAME(accountType, accountSubtype);
  const { activationTranspondersLoading } = ActivateCalls();
  const { canProceedToNextStep, onboardingError } = useOnboardingErrors();

  const { recaptchaChallengeElem, setShowLimitMessage, form, formState } = useOnboardingVehicle();
  const [hasTriedSubmitting, setHasTriedSubmitting] = useState(false);
  useEffect(() => {
    if (canProceedToNextStep && hasTriedSubmitting) history.goToPath(OnboardingStepPath.EASY_PAY);
  }, [canProceedToNextStep]);

  const {
    loading,
    shouldPreventAnyVehicles,
    showLimitMessage,
    isUserTryingToSubmitOverTheLimit,
    isFormDataInvalid,
    numOfVehiclesToBeRemoved,
    steps,
    removedIndex,
  } = formState;
  const {
    methods,
    methods: {
      formState: { isDirty },
    },
    handleSubmit,
    onSubmit,
    addVehicle,
    onRemoveVehicle,
    vehiclesFields,
    vehiclesCount,
  } = form;

  const bulkUploadInput = document.getElementById('upload');

  const { handleFileChange, modal, isError, vehicleBulkUploadResult, batchId, resetError, error } =
    useDashboardTabBulkUpload('vehicles', accountId);

  useEffect(() => {
    return () => resetError();
  }, []);

  const [notEnoughVehiclesError, setNotEnoughVehiclesError] = useState(false);
  const [showRedirectPrompt, setShowRedirectPrompt] = useState(false);

  useEffect(() => {
    setShowRedirectPrompt(isDirty);
  }, [isDirty]);

  const { cancelRedirect, confirmRedirect, showPrompt } = useCallbackPrompt(showRedirectPrompt);

  const [signUpVehiclesAndTransponders] = useMutation<
    { signUpVehiclesTransponders: ISignUpVehiclesTranspondersOutput },
    { signUpVehiclesTranspondersInput: ISignUpVehiclesTranspondersInput }
  >(SIGN_UP_VEHICLES_AND_TRANSPONDERS);

  const submitWithBulkUpload = () => {
    if (!isError) {
      if (vehicleBulkUploadResult && vehicleBulkUploadResult.addCount >= 101) {
        setNotEnoughVehiclesError(false);
        const vehicleAndTranspondersInput: ISignUpVehiclesTranspondersInput = {
          accountId: accountId,
          miniTranspondersCount: miniTransponderQty,
          proTranspondersCount: proTransponderQty,
          vehicleUploadBatchId: batchId,
          vehicleUploadCount: vehicleBulkUploadResult?.addCount.toString(),
        };
        signUpVehiclesAndTransponders({
          variables: { signUpVehiclesTranspondersInput: vehicleAndTranspondersInput },
        }).then((response) => {
          const result = response.data == null ? undefined : response.data;
          updateOnboardingStore({
            transponderSerialNumbers: {
              miniTransponderSerialNumber: result?.signUpVehiclesTransponders.miniTransponderSerialNumber,
              proTransponderSerialNumber: result?.signUpVehiclesTransponders.proTransponderSerialNumber,
            },
            totalTranspondersCost: response?.data?.signUpVehiclesTransponders.totalTranspondersCost,
            applicableTax: response?.data?.signUpVehiclesTransponders.tax,
          });
          history.goToPath(OnboardingStepPath.EASY_PAY);
        });
      } else {
        setNotEnoughVehiclesError(true);
      }
    }
  };

  const isFleetAccount = accountType === AccountType.COMMERCIAL;

  return (
    <>
      <ScrollToTop />
      <div className="h-full">
        <MainLoader loading={loading || activationTranspondersLoading} />
        <h5 className="onboarding-header w-full">
          <div className="w-full sm:inline sm:w-1/2">
            <span>
              {' '}
              {accountType !== AccountType.COMMERCIAL
                ? t`Great! Now add vehicle information.`
                : t`Great! Now add vehicle information. (Optional)`}
            </span>
          </div>
          <div className="w-full sm:inline sm:w-1/2">
            <Tooltip
              title={t`Once a user has created an account and logged in, a user may upload vehicles in bulk via spreadsheet`}
              placement="bottom"
            >
              <Link className="pl-5 pr-2 text-base text-primary-green1 underline" to="">
                {t`Need to bulk upload vehicles? `}
              </Link>
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="pb-1 text-primary-green1"
                style={{ width: 14, height: 14 }}
              />
            </Tooltip>
          </div>
        </h5>

        <h4 className="py-5 text-center">{planName}</h4>
        {accountType === AccountType.COMMERCIAL && (
          <div className="help-box-container">
            <div className="onboarding-help-box--fleet mb-8 w-full">
              <Trans id="Once your account has been created, you will be able to upload your list of vehicles in bulk via spreadsheet. Manual entry is not required, and you may enter however many vehicles you wish to add at this time."></Trans>
            </div>
          </div>
        )}
        <OnboardingHeader<OnboardingHeaderOnlyProgressProps>
          steps={steps}
          currentStepIndex={accountType === AccountType.PAYG ? 2 : 3}
          onlyProgressBar
        />
        <FormProvider {...methods}>
          <Form
            className="theme"
            onFinish={isFleetAccount ? submitWithBulkUpload : handleSubmit(onSubmit)}
            layout="vertical"
          >
            <ReferenceNumber />
            {isFleetAccount ? (
              <div className="bulk-onboarding mx-auto w-11/12 space-y-3 rounded-lg bg-primary-white text-left shadow-xl">
                <h1 className="mb-5 mt-3 text-left text-xl">{t`Bulk Upload Vehicles`}</h1>
                <p className="bulk-info">{t`You must download a spreadsheet specifying the vehicles you wish to add to your account. The required template is found below in Excel or CSV format.`}</p>
                <BulkUploadTemplateLink type="vehicles" name={t`Download Bulk Upload Template`} />
                <p className="file-info">{t`Max file size: 2MB. Accepted file types: csv, xls, xlsx.`}</p>
                <CustomButton
                  onClick={() => {
                    if (bulkUploadInput) bulkUploadInput.click();
                  }}
                  content={
                    <>
                      <input
                        type="file"
                        id="upload"
                        hidden
                        onChange={handleFileChange}
                        accept="text/plain, text/csv, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      />
                      <label
                        htmlFor="upload"
                        className="button-primary pt-0F.5 btn-font-sm my-0 w-full cursor-pointer xsm:w-auto sm:my-auto"
                        aria-label="Bulk Upload"
                      >
                        {t`Bulk Upload Vehicles`}
                      </label>
                    </>
                  }
                />
                {modal}
              </div>
            ) : (
              <>
                {vehiclesFields.map((field, index) => (
                  <OnboardingVehicle
                    field={field}
                    key={index}
                    index={index}
                    shouldPreventAnyVehicles={shouldPreventAnyVehicles}
                    setShowLimitMessage={setShowLimitMessage}
                    removedIndex={removedIndex}
                    onRemove={(index) => {
                      onRemoveVehicle(index);
                    }}
                  />
                ))}
                <div className="mb-2 flex flex-col items-center justify-center">
                  {vehiclesFields && vehiclesFields?.length <= 99 ? (
                    <div className="mx-1 items-center justify-center sm:flex sm:space-x-6">
                      <CustomButton
                        content="Add New Vehicle"
                        onClick={addVehicle}
                        icon={<FontAwesomeIcon icon={faPlus} size="xs" className="mr-3 " />}
                      />
                    </div>
                  ) : null}
                  {showLimitMessage ? (
                    <div className={`form-error mt-6 max-w-screen-lg px-0 text-center`}>
                      <div>
                        <Trans>
                          You&apos;ve reached the maximum number of vehicles you can add based on the transponders
                          you&apos;ve added to your cart and the vehicle limits associated with those transponders.
                        </Trans>
                      </div>
                    </div>
                  ) : null}
                  {isUserTryingToSubmitOverTheLimit ? (
                    <div className={`form-error mt-6 max-w-screen-lg text-center`}>
                      <div>
                        {t`You've exceeded the number of vehicles you can add based on the transponders you've
                        added to your cart and the vehicle limits associated with those transponders. You need to remove 
                        ${numOfVehiclesToBeRemoved} vehicles, or go back and add more transponders to your cart.`}
                      </div>
                    </div>
                  ) : null}
                </div>

                {isFormDataInvalid ? (
                  vehiclesCount > 1 ? (
                    <div className="form-error mt-5 text-center">
                      <Trans id="Please complete the required fields to move forward" />
                    </div>
                  ) : (
                    <div className="form-error mt-5 text-center">
                      <Trans id="You must add at least one vehicle to proceed" />
                    </div>
                  )
                ) : null}
              </>
            )}
            {(isError && hasTriedSubmitting) || !canProceedToNextStep ? (
              <div className="form-error mt-5 text-center">
                <Trans>{error?.message || onboardingError?.englishMsg}</Trans>
              </div>
            ) : null}
            {notEnoughVehiclesError && !isError ? (
              <div className="form-error mt-5 text-center">
                <Trans id="You must add a minimum of 101 vehicles to proceed." />
              </div>
            ) : null}
            <div className="onboarding-button-container">
              <BackButton content="Back" onBack={() => history.goBack()} blockRedirectTill={true} />
              <CustomButton
                content="Continue"
                type="submit"
                onClick={() => {
                  setShowRedirectPrompt(false);
                  handleSubmit(onSubmit);
                  setHasTriedSubmitting(true);
                }}
              />
            </div>
            {recaptchaChallengeElem}
          </Form>
        </FormProvider>
        <AccountCreatedSuccessfullyModal />
        <LeaveSiteConfirmationModal showModal={showPrompt} onOk={confirmRedirect} onCancel={cancelRedirect} />
      </div>
    </>
  );
}

import React, { FC, useState } from 'react';
import { t } from '@lingui/macro';
import { Collapse } from 'antd';
import { useFormContext } from 'react-hook-form';
import { TransponderForm } from '../../TransponderForm/TransponderForm';
import { TransponderNumberLocatingModal } from '../../Modals/ConfirmationModal/TransponderNumberLocatingModal';

const { Panel } = Collapse;

interface IOnboardingActivateTransponders {
  activationPanelCollapsed: boolean;
  setActivationPanelCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  setPlansAddedToTranspondersInActivateFlow: React.Dispatch<React.SetStateAction<number>>;
  plansAddedToTranspondersInActivateFlow: number;
}

const OnboardingActivateTransponders: FC<IOnboardingActivateTransponders> = ({
  activationPanelCollapsed,
  setActivationPanelCollapsed,
  setPlansAddedToTranspondersInActivateFlow,
  plansAddedToTranspondersInActivateFlow,
}) => {
  const {
    formState: { errors },
  } = useFormContext();

  const [showLocatingInfoModal, setShowLocatingInfoModal] = useState(false);

  const showLocateTransponderModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setShowLocatingInfoModal(true);
  };

  const containerTitle = (
    <div className="items-left flex w-full flex-col md:w-11/12 lg:flex-row lg:items-center">
      <div>{t`Activate Transponders`}</div>
      <button
        type="button"
        onClick={showLocateTransponderModal}
        className="green-link-thin block text-left lg:pl-7"
      >{t`Having trouble locating your transponder number?`}</button>
    </div>
  );

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={['1']}
      onChange={(panels: any) => setActivationPanelCollapsed(!panels[0])}
      activeKey={activationPanelCollapsed ? [] : ['1']}
      className="collapsible-container"
      expandIconPosition="end"
    >
      <TransponderNumberLocatingModal showModal={showLocatingInfoModal} onOk={() => setShowLocatingInfoModal(false)} />
      <Panel header={containerTitle} key={1} className="collapsible-container-panel" showArrow={false}>
        <div className="form-error">{errors?.hasTranspondersToActivate?.message as unknown as string}</div>
        <TransponderForm
          setPlansAddedToTranspondersInActivateFlow={setPlansAddedToTranspondersInActivateFlow}
          plansAddedToTranspondersInActivateFlow={plansAddedToTranspondersInActivateFlow}
        />
      </Panel>
    </Collapse>
  );
};

export default OnboardingActivateTransponders;

import { Trans } from '@lingui/macro';
import { FC } from 'react';
import ConfirmationModal from 'src/components/Modals/ConfirmationModal/ConfirmationModal';

interface INoPinellasWarningModalProps {
  showModal: boolean;
  onCancel: () => void;
  onOk: () => void;
  okButtonText: string;
  cancelButtonText: string;
}

const NoPinellasWarningModal: FC<INoPinellasWarningModalProps> = ({
  showModal,
  onCancel,
  onOk,
  okButtonText,
  cancelButtonText,
}) => {
  return (
    <ConfirmationModal
      title={<Trans>Proceed without Pinellas Bayway Plan?</Trans>}
      content={
        <Trans>
          You previously indicated that you wanted to create a SunPass Personal Account with the Pinellas Bayway Plan.
          If you proceed without adding the Pinellas Bayway Plan to any of your transponders, you will be set up with a
          standard SunPass Personal Account without the Pinellas Bayway Plan, and you will be charged regular toll rates
          while driving on the Pinellas Bayway. Please go back to add the Pinellas Bayway Plan to a transponder, or
          proceed without the Pinellas Bayway Plan.
        </Trans>
      }
      onCancel={onCancel}
      onOk={onOk}
      showModal={showModal}
      alignText="center"
      okButtonText={okButtonText}
      cancelButtonText={cancelButtonText}
      cancelButtonBlue={false}
    />
  );
};

export default NoPinellasWarningModal;

import { FC } from 'react';
import { Trans } from '@lingui/macro';
import useScreenSize from 'src/hooks/screen/useScreenSize';
import TranspondersActivityItemBigScreen from './TranspondersActivityItemBigScreen';
import TranspondersActivityItemSmallScreen from './TranspondersActivityItemSmallScreen';
import EmptyStateMessage, { IListType } from 'src/components/EmptyStates/EmptyStateMessage';
import {
  GET_TRANSACTION_ACTIVITY_LIST,
  ITransactionActivityList,
  ITransactionActivityListInput,
} from 'src/graphql/transactionsActivity.graphql';
import { useQuery } from '@apollo/client';
import { convertPriceToString } from 'src/utils/formatter';

interface TranspondersActivityListProps {
  currentBalance?: number;
}

const TranspondersActivityList: FC<TranspondersActivityListProps> = ({ currentBalance }) => {
  const transactionActivityListInput = {
    pagination: {
      pageSize: 5,
      pageNumber: 1,
    },
  };

  const { data: transactionActivityData } = useQuery<
    { transactionActivityList: ITransactionActivityList },
    { transactionActivityListInput: ITransactionActivityListInput }
  >(GET_TRANSACTION_ACTIVITY_LIST, {
    notifyOnNetworkStatusChange: true,
    variables: {
      transactionActivityListInput: { ...transactionActivityListInput },
    },
  });

  const recentActivities = transactionActivityData?.transactionActivityList.transactionActivities;

  const { screenSize } = useScreenSize();

  return (
    <div>
      {recentActivities && recentActivities.length > 0 ? (
        <>
          <header className="hidden flex-row justify-between px-8 pb-6 md:flex">
            <div className="w-2/12">
              <Trans>Date</Trans>
            </div>
            <div className="w-2/12">
              <Trans>Time</Trans>
            </div>
            <div className="w-4/12">
              <Trans>Location</Trans>
            </div>
            <div className="w-2/12">
              <Trans>Transponder</Trans>
            </div>
            <div className="w-1/12 text-right">
              <Trans>Charge</Trans>
            </div>
          </header>
          <ul>
            {recentActivities?.map((activity) => {
              return screenSize > 1 ? (
                <TranspondersActivityItemBigScreen key={activity.transactionNumber} activity={activity} />
              ) : (
                <TranspondersActivityItemSmallScreen key={activity.transactionNumber} activity={activity} />
              );
            })}
          </ul>
        </>
      ) : (
        <div className="text-large font-bold">
          {' '}
          <EmptyStateMessage listType={IListType.ACTIVITY} />
        </div>
      )}
      {(currentBalance && currentBalance >= 0) || !currentBalance ? null : (
        <div className="mt-10 flex items-center justify-between px-6 text-xl font-bold lg:hidden">
          <Trans>You currently owe</Trans>{' '}
          <span className="text-3xl text-red-600">{convertPriceToString(Math.abs(currentBalance || 0))}</span>
        </div>
      )}
    </div>
  );
};

export default TranspondersActivityList;

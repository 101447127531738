import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { colors } from 'src/theme/theme';

interface IVerifiedIconProps {
  isVerified: boolean;
}

const VerifiedIcon: FC<IVerifiedIconProps> = ({ isVerified }) => {
  return isVerified ? (
    <FontAwesomeIcon icon={faCheck} color={colors.primary.green1} />
  ) : (
    <FontAwesomeIcon icon={faTimes} color={colors.alerts.red} />
  );
};

export default VerifiedIcon;

import React, { ChangeEvent, useMemo, useState } from 'react';
import { useEffectUnderCondition } from '../../effect/useEffectUnderCondition';
import useErrorHandling from '../../errors/useErrorHandling';
import { BulkUploadModal } from '../../../components/Modals/LoadingModal/BulkUploadModal';
import { BulkUploadType } from '../../../components/Links/BulkUploadTemplateLink';
import { useBulkUpload } from './useBulkUpload';
import { IVehiclesBulkUploadOutput } from '../../../graphql/vehicles.graphql';
import { IErrorState } from 'src/store/errors/error.store';

interface IUseDashboardTabBulkUpload {
  handleFileChange: (event: ChangeEvent<HTMLInputElement>) => Promise<void>;
  modal: JSX.Element;
  vehicleBulkUploadResult: IVehiclesBulkUploadOutput | undefined;
  isError: boolean;
  batchId: string;
  resetError: () => void;
  error: undefined | IErrorState;
}

export const useDashboardTabBulkUpload = (type: BulkUploadType, accountId?: string): IUseDashboardTabBulkUpload => {
  const [isModalShown, setIsModalShown] = useState(false);
  const [batchId, setBatchId] = useState('');
  const [processedSuccessfully, setProcessedSuccessfully] = useState(false);
  const { resetError, isError, error } = useErrorHandling();
  const [bulkUploadProcessing, setBulkUploadProcessing] = useState(false);
  const [vehicleBulkUploadResult, setVehicleBulkUploadResult] = useState<IVehiclesBulkUploadOutput | undefined>();
  const { upload, process, uploading, reset } = useBulkUpload({
    type,
    finishProcessing: () => setBulkUploadProcessing(false),
    accountId,
  });

  useEffectUnderCondition(
    isModalShown && !isError && batchId && bulkUploadProcessing,
    () => {
      const intervalId = setInterval(async () => {
        const response = await process(batchId);
        const isProcessedSuccessfully =
          type === 'transponders'
            ? !!response.data?.transpondersBulkUploadProcess
            : !!response.data?.vehiclesBulkUploadProcess;
        setProcessedSuccessfully(isProcessedSuccessfully);
        setBulkUploadProcessing(!(response.error || isProcessedSuccessfully));
      }, 3000);
      if (isError || processedSuccessfully) {
        clearInterval(intervalId);
      }
      return () => clearInterval(intervalId);
    },
    [batchId, processedSuccessfully, isModalShown, isError, bulkUploadProcessing],
  );

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length && event.target.files.length > 0 && event.target.validity.valid) {
      setIsModalShown(true);
      const response = await upload(event.target.files[0]);
      const batchId =
        type === 'transponders'
          ? response.data?.transpondersBulkUpload?.batchId
          : response.data?.vehiclesBulkUpload?.batchId;
      if (batchId) {
        setBatchId(batchId);
        accountId === undefined && setBulkUploadProcessing(true);
      }
      if (type === 'vehicles') {
        setVehicleBulkUploadResult(response?.data?.vehiclesBulkUpload as IVehiclesBulkUploadOutput);
      }
    }
    (document.getElementById('upload') as HTMLInputElement).value = '';
  };
  const onConfirm = () => {
    setIsModalShown(false);
    reset();
    resetError();
    setProcessedSuccessfully(false);
  };

  const modal = useMemo((): JSX.Element => {
    return (
      <BulkUploadModal
        type={type}
        showModal={isModalShown}
        onConfirm={onConfirm}
        processing={bulkUploadProcessing || uploading}
        vehicleBulkUploadResult={vehicleBulkUploadResult}
      />
    );
  }, [isModalShown, onConfirm, bulkUploadProcessing, uploading]);
  return {
    handleFileChange,
    modal,
    vehicleBulkUploadResult,
    isError,
    batchId,
    resetError,
    error,
  };
};

import React, { FC, useEffect } from 'react';
import { Form } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import { t, Trans } from '@lingui/macro';
import StyledInput from '../../../StyledInput/StyledInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { IVehicleFormItemProps } from '../../../Onboarding/Vehicles/OnboardingVehiclesForm/OnboardingVehicleForm';
import '../../../../pages/Onboarding/Onboarding.scss';
import { buildErrorString, getInputName } from '../../../Onboarding/Vehicles/OnboardingVehiclesForm/vehicleForm.utils';
import { useOnboardingErrors } from 'src/store/errors/onboardingErrors.store';
import { getLicencePlateFromMessage } from 'src/pages/Onboarding/OnboardingErrors/ErrorCodesOnboardingActivation';

const LicencePlateNumber: FC<IVehicleFormItemProps> = ({ field, index, name, isEditMode, className, isInHomePage }) => {
  const {
    control,
    watch,
    setError,
    formState: { errors },
  } = useFormContext();

  const licensePlateNotKnown = watch(getInputName('notKnown', index));
  const { onboardingError } = useOnboardingErrors();
  useEffect(() => {
    if (
      onboardingError?.errorByType?.vehicle &&
      getLicencePlateFromMessage(onboardingError?.errorByType?.vehicle.errorMessage) ===
        watch(`vehicles[${index}].plateNumber`)
    ) {
      setError(name, { type: 'focus', message: t`This license plate is already in use` });
    }
  }, [onboardingError]);
  return (
    <div className={isInHomePage ? 'mt-6' : 'mb-4 w-full sm:w-6/12 sm:pr-2'}>
      <Form.Item
        {...field}
        id={name}
        className={className ? className : 'theme'}
        help={buildErrorString(name, errors, index)}
        label={isInHomePage ? <Trans>Plate Number</Trans> : <Trans>License Plate Number</Trans>}
        htmlFor={name}
        required={!isInHomePage}
      >
        <Controller
          control={control}
          name={name}
          render={({ field, fieldState }) => (
            <StyledInput
              field={field}
              fieldstate={fieldState}
              id={name}
              name={name}
              disabled={licensePlateNotKnown || isEditMode}
              maxLength={12}
              aria-label={t`License Plate Number`}
            />
          )}
        />
      </Form.Item>
      {isInHomePage ? null : (
        <span className="text-sm text-opacity-60">
          <FontAwesomeIcon
            icon={faInfoCircle}
            style={{
              width: 14,
              height: 14,
            }}
          />{' '}
          {t`Please enter letters and numbers only as they appear left to right on your license plate`}
        </span>
      )}
    </div>
  );
};

export default LicencePlateNumber;

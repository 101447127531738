import { TransponderType } from '../../../graphql/transponders.graphql';
import useTransponderPrices from '../../../hooks/domain/dashboard/transponders/useTransponderPrices';
import { t } from '@lingui/macro';
import miniTransponder from '../assets/sunpass-mini.png';
import proTransponder from '../assets/sunpass-pro.png';
import React from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import '../../../pages/Onboarding/Onboarding.scss';
import { useOnboardingStore } from '../../../store/onboarding/onboarding.store';
import { SELECT_DETAILS } from '../../../store/onboarding/onboarding.selectors';
import { AccountSubtype, AccountType } from '../../../types/onboarding.types';

export interface ITransponderInfoCardProps {
  transponderType: TransponderType;
  isDashboard: boolean;
}

interface IFeatureElem {
  text: string;
  isPositive: boolean;
}

export const TransponderInfoCard = ({ transponderType, isDashboard }: ITransponderInfoCardProps) => {
  const { miniPrice, proPrice, PBCPrice } = useTransponderPrices();
  const { accountSubtype, accountType } = useOnboardingStore(SELECT_DETAILS);

  const TRANSPONDER_FEATURES_MAP: Record<TransponderType, IFeatureElem[]> = {
    MINI: [
      { text: t`Nonremovable Windshield Sticker Adhesive `, isPositive: true },
      { text: t`Cannot be removed from windshield (nonportable)`, isPositive: false },
      { text: t`Eligible to use SunPass Plus Parking`, isPositive: true },
      { text: t`Does not work on motorcycles`, isPositive: false },
    ],
    PRO: [
      {
        text: t`All of the benefits of portable, plus interoperability with EZPass network of toll operators`,
        isPositive: true,
      },
    ],
    PORTABLE: [],
    PINELLAS: [
      { text: t`Nonremovable Windshield Sticker Adhesive `, isPositive: true },
      { text: t`Cannot be removed from windshield (nonportable)`, isPositive: false },
      { text: t`Eligible to use SunPass Plus Parking`, isPositive: true },
      { text: t`Does not work on motorcycles`, isPositive: false },
    ],
  };

  return (
    <div
      id="transponder-card-info"
      className={
        accountType === AccountType.PRIVATE && accountSubtype === AccountSubtype.PBC && !isDashboard
          ? 'transponder-pbc-card-info'
          : 'transponder-card__info order-1 w-full py-5 text-center md:mb-4 md:w-1/2 md:px-5'
      }
    >
      <p className="mt-0 text-xl">
        {transponderType === TransponderType.MINI
          ? accountType === AccountType.PRIVATE && accountSubtype === AccountSubtype.PBC && !isDashboard
            ? t`Mini Transponder + Pinellas Bayway Plan`
            : t`Mini Transponder`
          : t`Pro Transponder`}
      </p>
      <div className="my-2 text-sm uppercase tracking-tight text-primary-blue1">
        {transponderType === TransponderType.MINI ? t`1 Vehicle per Transponder` : t`Up to 5 Vehicles per Transponder`}
      </div>
      <div className="flex flex-col">
        <img
          src={transponderType === TransponderType.MINI ? miniTransponder : proTransponder}
          className="onboarding-transponders-mini"
          alt="Mini transponder"
        />
        <p className="my-5 text-3xl">
          $
          {transponderType === TransponderType.MINI
            ? accountType === AccountType.PRIVATE && accountSubtype === AccountSubtype.PBC
              ? PBCPrice.toFixed(2)
              : miniPrice.toFixed(2)
            : proPrice.toFixed(2)}
        </p>
        <ul className="onboarding-transponders-list">
          {TRANSPONDER_FEATURES_MAP[transponderType].map(({ text, isPositive }, index) => (
            <li key={index} className="flex list-none">
              <div className="mr-3">
                {isPositive ? <CheckOutlined className="icon-green" /> : <CloseOutlined className="icon-red" />}
              </div>
              <span key={index} className="mt-1">
                {text}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

import { Trans } from '@lingui/macro';
import React from 'react';

export const EmptyState = () => {
  return (
    <p className="mt-3">
      <Trans>None</Trans>
    </p>
  );
};

import { gql } from '@apollo/client';

export const REFRESH_TOKEN = gql`
  mutation refreshToken($refreshInput: RefreshTokenInput!) {
    refreshToken(refreshInput: $refreshInput) {
      token
      refreshToken
    }
  }
`;

import { useContactSettingsStore } from '../../../store/account/contactSettings.store';
import { useMutation, useQuery } from '@apollo/client';
import { EasyPayOption, IIncreaseBalanceInput, INCREASE_BALANCE } from '../../../graphql/payment.graphql';
import { GET_ACCOUNT_DETAILS, IAccountDetails } from '../../../graphql/accountDetails.graphql';
import creditCardType from 'credit-card-type';
import { IContactSettings } from '../../../components/MyAccount/ContactInformation/Contact';
import { CLOSE_ACCOUNT } from '../../../graphql/closeAccount.graphql';
import { useEffect, useMemo, useState } from 'react';
import CloseAccountConfirmationModal from '../../../components/Modals/ConfirmationModal/CloseAccountConfirmationModal';
import { useLogout } from '../../auth/useLogout';
import { useSecurityCodeModalStore } from 'src/store/payment/securityCodeModal.store';

interface ICloseModalInfo {
  modalOpen: boolean;
  onCloseConfirm: () => Promise<void>;
}

export interface IUseCloseAccountReturnType {
  close: (inputData: IContactSettings) => Promise<void>;
  closeAccountLoading: boolean;
  submitBtnDisabledForCloseAccount: boolean;
  closeModal: JSX.Element;
}

export const useCloseAccount = (): IUseCloseAccountReturnType => {
  const {
    closeAccountInfo: { existingCard, isCloseProcessActive, isNewCardChosen },
    updateCloseAccountInfo,
  } = useContactSettingsStore();
  const { data } = useQuery<{ accountDetails: IAccountDetails }>(GET_ACCOUNT_DETAILS);
  const [increaseBalance] = useMutation<{ increaseBalanceInput: IIncreaseBalanceInput }>(INCREASE_BALANCE);

  const [closeAccount, { loading: closeAccountLoading }] = useMutation<{ closeAccount: boolean }, { reason: string }>(
    CLOSE_ACCOUNT,
  );

  const {
    securityCodeModalData: { securityCode },
  } = useSecurityCodeModalStore();

  const { logout, loading: logoutLoading } = useLogout();

  const [submitBtnDisabledForCloseAccount, setSubmitBtnDisabledForCloseAccount] = useState(false);

  const [closeModalInfo, setCloseModalInfo] = useState<ICloseModalInfo>({
    modalOpen: false,
    onCloseConfirm: () => Promise.resolve(),
  });

  useEffect(() => {
    return () => {
      updateCloseAccountInfo({ isCloseProcessActive: false });
    };
  }, []);

  const chargeWithCreditCard = async (inputData?: IContactSettings) => {
    const cardType = creditCardType(inputData?.ccNumber ? inputData?.ccNumber : '');
    await increaseBalance({
      variables: {
        increaseBalanceInput: {
          paymentMethodId: existingCard?.paymentMethodId || '',
          transactionAmount: Math.abs(data?.accountDetails.currentBalance || 0),
          bankAccountNumber: '',
          bankRoutingNumber: '',
          cardNumber: existingCard?.cardNumber || inputData?.ccNumber,
          expirationMonth: existingCard?.expirationMonth || inputData?.ccExpirationMonth,
          expirationYear: existingCard?.expirationYear || inputData?.ccExpirationYear,
          securityCode: securityCode || inputData?.ccSecurityCode,
          firstName: existingCard?.firstName || inputData?.ccFirstName,
          middleName: existingCard?.middleName || inputData?.ccMiddleName,
          lastName: existingCard?.lastName || inputData?.ccLastName,
          addressLine1: existingCard?.addressLine1 || inputData?.billingAddressLine1,
          addressLine2: existingCard?.addressLine2 || inputData?.billingAddressLine2,
          city: existingCard?.city || inputData?.billingCity,
          country: existingCard?.country || inputData?.billingCountry,
          state: existingCard?.state || inputData?.billingState,
          zipCode: existingCard?.zipCode || inputData?.billingPostalCode,
          payType: existingCard?.payType || cardType[0].type.toUpperCase(),
          easyPayOption: EasyPayOption.NONE,
        },
      },
    });
  };

  const closeModal = useMemo((): JSX.Element => {
    return (
      <CloseAccountConfirmationModal
        showModal={closeModalInfo.modalOpen}
        onOk={closeModalInfo.onCloseConfirm}
        onCancel={() =>
          setCloseModalInfo((state) => {
            return {
              ...state,
              modalOpen: false,
            };
          })
        }
        cancelButtonBlue
      />
    );
  }, [closeModalInfo.modalOpen, closeModalInfo.onCloseConfirm]);

  const close = async (inputData: IContactSettings) => {
    setCloseModalInfo((state) => {
      return {
        ...state,
        modalOpen: true,
        onCloseConfirm: async () => {
          try {
            if (data?.accountDetails && data?.accountDetails?.currentBalance < 0 && existingCard) {
              await chargeWithCreditCard();
            } else if (data?.accountDetails && data?.accountDetails.currentBalance < 0 && !existingCard) {
              await chargeWithCreditCard(inputData);
            }
            await closeAccount({
              variables: {
                reason: inputData.reason,
              },
            });
            await logout(true);
          } catch (err: any) {
            setCloseModalInfo((state) => {
              return {
                ...state,
                modalOpen: false,
              };
            });
          }
        },
      };
    });
  };

  const isAnyPaymentMethodOptionSelected = useMemo(() => {
    return !!existingCard || isNewCardChosen;
  }, [existingCard, isNewCardChosen]);

  const isAboutToCloseWithPositiveBalance = useMemo(() => {
    return isCloseProcessActive && data?.accountDetails && data.accountDetails.currentBalance >= 0;
  }, [isCloseProcessActive, data?.accountDetails?.currentBalance]);

  const isAboutToCloseWithNegativeBalance = useMemo(() => {
    return isCloseProcessActive && data?.accountDetails && data.accountDetails.currentBalance < 0;
  }, [isCloseProcessActive, data?.accountDetails?.currentBalance]);

  useEffect(() => {
    if (isAnyPaymentMethodOptionSelected) {
      setSubmitBtnDisabledForCloseAccount(false);
    } else if (isAboutToCloseWithNegativeBalance && !isAnyPaymentMethodOptionSelected) {
      setSubmitBtnDisabledForCloseAccount(true);
    } else if (isAboutToCloseWithNegativeBalance && isAnyPaymentMethodOptionSelected) {
      setSubmitBtnDisabledForCloseAccount(false);
    } else if (isAboutToCloseWithPositiveBalance) {
      setSubmitBtnDisabledForCloseAccount(false);
    }
  }, [
    existingCard,
    isNewCardChosen,
    isCloseProcessActive,
    data?.accountDetails?.currentBalance,
    isAnyPaymentMethodOptionSelected,
  ]);

  return {
    close,
    closeAccountLoading: closeAccountLoading || logoutLoading,
    submitBtnDisabledForCloseAccount,
    closeModal,
  };
};

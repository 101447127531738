import React, { FC } from 'react';
import { t, Trans } from '@lingui/macro';
import { Controller, useFormContext } from 'react-hook-form';
import StyledInput from '../../StyledInput/StyledInput';
import { Form, Radio } from 'antd';
import MaskedObfuscatedInput from '../../MaskedObfuscatedInput/MaskedObfuscatedInput';
import { BANK_ACCOUNT_NUMBER_MASK, BANK_ROUTING_NUMBER_MASK } from '../../../validators/masks';
import PaymentMethodFormField, { BankAccountType } from '../Payments.enums';
import { useWalletStore } from '../../../store/dashboard/wallet.store';

const PaymentMethodBank: FC = () => {
  const { control, formState } = useFormContext();
  const { errors } = formState;
  const BANK_FIRST_NAME_ERROR = errors[PaymentMethodFormField.BANK_FIRST_NAME];
  const BANK_LAST_NAME_ERROR = errors[PaymentMethodFormField.BANK_LAST_NAME];
  const BANK_ACCOUNT_NUMBER_ERROR = errors[PaymentMethodFormField.BANK_ACCOUNT_NUMBER];
  const BANK_ACCOUNT_NUMBER_CONFIRM_ERROR = errors[PaymentMethodFormField.BANK_ACCOUNT_NUMBER_CONFIRM];
  const BANK_ROUTING_NUMBER = errors[PaymentMethodFormField.BANK_ROUTING_NUMBER];
  const ACCOUNT_TYPE = errors[PaymentMethodFormField.BANK_ACCOUNT_TYPE];

  const {
    paymentsInfo: { paymentMethodToEdit, isEditMode },
  } = useWalletStore();

  return (
    <React.Fragment>
      <div className="flex flex-wrap">
        <h1 className="text-3xl text-primary-blue1">
          <Trans>Bank Account Information</Trans>
        </h1>
        <div className="w-full" />
        <div className="mt-5 flex w-full">
          <Form.Item
            name={PaymentMethodFormField.BANK_ACCOUNT_TYPE}
            help={ACCOUNT_TYPE?.message as unknown as string}
            className={'theme bank-account-radio`'}
            required
          >
            <Controller
              name={PaymentMethodFormField.BANK_ACCOUNT_TYPE}
              control={control}
              render={({ field }) => (
                <Radio.Group
                  {...field}
                  id={PaymentMethodFormField.BANK_ACCOUNT_TYPE}
                  defaultValue={isEditMode ? paymentMethodToEdit?.payType : undefined}
                  aria-label={t`Bank account type`}
                >
                  <Radio value={BankAccountType.CHECKING} aria-label={t`Checking`}>
                    <Trans>Checking</Trans>
                  </Radio>
                  <Radio value={BankAccountType.SAVINGS} aria-label={t`Savings`}>
                    <Trans>Savings</Trans>
                  </Radio>
                </Radio.Group>
              )}
            />
          </Form.Item>
        </div>
        <div className="w-full sm:w-6/12 sm:pr-2">
          <Form.Item
            label={<Trans>First Name</Trans>}
            className="theme"
            help={BANK_FIRST_NAME_ERROR?.message as unknown as string}
            required
          >
            <Controller
              name={PaymentMethodFormField.BANK_FIRST_NAME}
              control={control}
              render={({ field, fieldState }) => (
                <StyledInput
                  key={PaymentMethodFormField.BANK_FIRST_NAME}
                  field={field}
                  fieldstate={fieldState}
                  id={PaymentMethodFormField.BANK_FIRST_NAME}
                  maxLength={40}
                  aria-label={t`First Name`}
                />
              )}
            />
          </Form.Item>
        </div>
        <div className="w-full sm:w-6/12 sm:pl-2">
          <Form.Item
            label={<Trans>Last Name</Trans>}
            className="theme"
            help={BANK_LAST_NAME_ERROR?.message as unknown as string}
            required
          >
            <Controller
              name={PaymentMethodFormField.BANK_LAST_NAME}
              control={control}
              render={({ field, fieldState }) => (
                <StyledInput
                  key={PaymentMethodFormField.BANK_LAST_NAME}
                  field={field}
                  fieldstate={fieldState}
                  id={PaymentMethodFormField.BANK_LAST_NAME}
                  maxLength={40}
                  aria-label={t`Last Name`}
                />
              )}
            />
          </Form.Item>
        </div>
        <div className="w-full sm:w-6/12 sm:pr-2">
          <Form.Item
            label={<Trans>Account Number</Trans>}
            className="theme"
            validateStatus={errors.bankAccountNumber ? 'error' : ''}
            help={BANK_ACCOUNT_NUMBER_ERROR?.message as unknown as string}
            required
          >
            <Controller
              name={PaymentMethodFormField.BANK_ACCOUNT_NUMBER}
              control={control}
              render={({ field, fieldState }) => (
                <MaskedObfuscatedInput
                  key={PaymentMethodFormField.BANK_ACCOUNT_NUMBER}
                  field={field}
                  fieldstate={fieldState}
                  value={field.value ? field.value.replace(/_|\s/g, '') : field.value}
                  id={PaymentMethodFormField.BANK_ACCOUNT_NUMBER}
                  minLength={5}
                  maxLength={17}
                  mask={BANK_ACCOUNT_NUMBER_MASK}
                  label={t`Bank account number`}
                />
              )}
            />
          </Form.Item>
        </div>
        <div className="w-full sm:w-6/12 sm:pl-2">
          <Form.Item
            label={<Trans>Confirm Account Number</Trans>}
            className="theme"
            validateStatus={errors.bankAccountNumberConfirm ? 'error' : ''}
            help={BANK_ACCOUNT_NUMBER_CONFIRM_ERROR?.message as unknown as string}
            required
          >
            <Controller
              name={PaymentMethodFormField.BANK_ACCOUNT_NUMBER_CONFIRM}
              control={control}
              render={({ field, fieldState }) => (
                <MaskedObfuscatedInput
                  key={PaymentMethodFormField.BANK_ACCOUNT_NUMBER_CONFIRM}
                  field={field}
                  fieldstate={fieldState}
                  value={field.value ? field.value.replace(/_|\s/g, '') : field.value}
                  id={PaymentMethodFormField.BANK_ACCOUNT_NUMBER_CONFIRM}
                  minLength={5}
                  maxLength={17}
                  mask={BANK_ACCOUNT_NUMBER_MASK}
                  label={t`Confirm account number`}
                />
              )}
            />
          </Form.Item>
        </div>
        <div className="w-full sm:w-6/12 sm:pr-2">
          <Form.Item
            label={<Trans>Routing Number</Trans>}
            className="theme"
            validateStatus={errors.bankRoutingNumber ? 'error' : ''}
            help={BANK_ROUTING_NUMBER?.message as unknown as string}
            required
          >
            <Controller
              name={PaymentMethodFormField.BANK_ROUTING_NUMBER}
              control={control}
              render={({ field, fieldState }) => (
                <MaskedObfuscatedInput
                  key={PaymentMethodFormField.BANK_ROUTING_NUMBER}
                  field={field}
                  fieldstate={fieldState}
                  maxLength={9}
                  id={PaymentMethodFormField.BANK_ROUTING_NUMBER}
                  mask={BANK_ROUTING_NUMBER_MASK}
                  value={field.value}
                  label={t`Routing number`}
                />
              )}
            />
          </Form.Item>
        </div>
        <div className="w-full sm:w-6/12 sm:pl-2">
          <div className="onboarding-help-box">
            <div className="text-base">
              <Trans>You may incur additional fees from your bank when using funds from a savings account.</Trans>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PaymentMethodBank;

import { FC, useEffect } from 'react';
import { MainLoader } from 'src/components/Loader/MainLoader';
import useGetVehicles from 'src/hooks/domain/dashboard/vehicle/useGetVehicles';

import { renderInformation } from 'src/components/Dashboard/VehicleView/VehiclesList/stringRenderers';
import { usePaginationInfo } from '../../../../../store/pagination/pagination.store';
import { useVehicleStore } from '../../../../../store/vehicles/vehicle.store';
const VehicleList: FC = () => {
  const { vehiclesData, vehiclesLoading, refetch } = useGetVehicles(true);

  const {
    vehiclesPagination: { size, page },
  } = usePaginationInfo();

  const {
    store: { isVehicleListChanged },
  } = useVehicleStore();

  useEffect(() => {
    if (isVehicleListChanged) {
      refetch({
        vehicleListInput: {
          pagination: {
            pageSize: size,
            pageNumber: page,
          },
        },
      });
    }
  }, [isVehicleListChanged]);

  const recentVehicles = vehiclesData?.vehicleList.vehicles.slice(0, 5) || [];

  return (
    <>
      <MainLoader loading={vehiclesLoading} />
      <ul className="mr-3">
        {recentVehicles?.map((vehicle) => {
          const { plateNumber, vehicleId } = vehicle;
          return (
            <li
              key={vehicleId}
              className="mb-3 flex h-20 cursor-pointer flex-col justify-center rounded-lg bg-white px-6 py-4 text-sm shadow-lg sm:flex-row sm:justify-between sm:text-base"
            >
              <div>
                <div>
                  <span className="font-bold md:font-normal">{plateNumber}</span>
                </div>
                <div>{renderInformation(vehicle)}</div>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default VehicleList;

import { useState } from 'react';

interface IuseObfuscatedInput {
  onFocus: () => void;
  onBlur: () => void;
  type: string;
}

const useObfuscatedInput = (): IuseObfuscatedInput => {
  const [showInputContent, setShowInputContent] = useState(true);

  const obfuscatedInputprops: IuseObfuscatedInput = {
    onFocus: () => setShowInputContent(true),
    onBlur: () => setShowInputContent(false),
    type: showInputContent ? 'text' : 'password',
  };

  return obfuscatedInputprops;
};

export default useObfuscatedInput;

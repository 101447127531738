import { create } from '../createStore';
import { devtools } from 'zustand/middleware';
import { ITransponder } from '../../graphql/transponders.graphql';

export interface ITransponderStore {
  store: ITransponderStoreInfo;
  setEditMode: (editMode: boolean) => void;
  setTransponderToEdit: (transponder: ITransponder | undefined) => void;
  setActivateMode: (activateMode: boolean) => void;
  setIsDeactivationModalOpen: (isDeactivationModalOpen: boolean) => void;
}

export interface ITransponderStoreInfo {
  editMode: boolean;
  activateMode: boolean;
  transponderToEdit: ITransponder | undefined;
  isDeactivationModalOpen: boolean;
}

const TRANSPONDER_STORE_INITIAL_STATE: ITransponderStoreInfo = {
  editMode: false,
  activateMode: false,
  transponderToEdit: undefined,
  isDeactivationModalOpen: false,
};

export const useTransponderStore = create<ITransponderStore, [['zustand/devtools', ITransponderStore]]>(
  devtools((set) => ({
    store: TRANSPONDER_STORE_INITIAL_STATE,
    setEditMode: (editMode: boolean) => {
      set(({ store }) => ({
        store: {
          ...store,
          editMode,
        },
      }));
    },
    setIsDeactivationModalOpen: (isDeactivationModalOpen: boolean) => {
      set(({ store }) => ({
        store: {
          ...store,
          isDeactivationModalOpen: isDeactivationModalOpen,
        },
      }));
    },
    setActivateMode: (activateMode: boolean) => {
      set(({ store }) => ({
        store: {
          ...store,
          activateMode,
        },
      }));
    },
    setTransponderToEdit: (transponder: ITransponder | undefined) => {
      set(({ store }) => ({
        store: {
          ...store,
          transponderToEdit: transponder,
        },
      }));
    },
  })),
);

import { IVehicle } from 'src/graphql/vehicles.graphql';
import { create } from '../createStore';
import { devtools } from 'zustand/middleware';

export const emptyVehicle = {
  effectiveEndDate: '',
  effectiveEndTime: '',
  effectiveStartDate: '',
  effectiveStartTime: '',
  plateNumber: '',
  plateState: '',
  plateType: '',
  vehicleCountry: '',
  vehicleMake: '',
  vehicleModel: '',
  vehicleYear: '',
  vehicleColor: '',
  isRental: false,
  isTrailer: false,
  licenseInfoNotKnown: false,
  index: 0,
  vehicleId: '',
};

export interface IVehicleStore {
  store: IVehicleStoreInfo;
  setVehicleToEdit: (value: IVehicle) => void;
  setVehicleToDelete: (vehicle: IVehicle) => void;
  setIsDeleteModalVisible: (value: boolean) => void;
  setIsFormVisible: (value: boolean) => void;
  setIsVehicleListChanged: (value: boolean) => void;
  triggerVehicleListRefetch: (value: boolean) => void;
}

export interface IVehicleStoreInfo {
  vehicleToEdit: IVehicle;
  vehicleToDelete: IVehicle;
  isDeleteModalVisible: boolean;
  isFormVisible: boolean;
  isVehicleListChanged: boolean;
  refetchVehicleList: boolean;
}

const VEHICLE_STORE_INITIAL_STATE: IVehicleStoreInfo = {
  vehicleToEdit: emptyVehicle,
  vehicleToDelete: emptyVehicle,
  isDeleteModalVisible: false,
  isFormVisible: false,
  isVehicleListChanged: false,
  refetchVehicleList: false,
};

export const useVehicleStore = create<IVehicleStore, [['zustand/devtools', IVehicleStore]]>(
  devtools((set) => ({
    store: VEHICLE_STORE_INITIAL_STATE,

    setVehicleToEdit: (vehicle) => {
      set(({ store }) => ({
        store: {
          ...store,
          vehicleToEdit: vehicle,
        },
      }));
    },

    setVehicleToDelete: (vehicle) => {
      set(({ store }) => ({
        store: {
          ...store,
          vehicleToDelete: vehicle,
        },
      }));
    },

    setIsDeleteModalVisible: (value) => {
      set(({ store }) => ({
        store: {
          ...store,
          isDeleteModalVisible: value,
        },
      }));
    },

    setIsFormVisible: (value) => {
      set(({ store }) => ({
        store: {
          ...store,
          isFormVisible: value,
        },
      }));
    },

    setIsVehicleListChanged: (value) => {
      set(({ store }) => ({
        store: {
          ...store,
          isVehicleListChanged: value,
        },
      }));
    },

    triggerVehicleListRefetch: (value) => {
      set(({ store }) => ({
        store: {
          ...store,
          refetchVehicleList: value,
        },
      }));
    },
  })),
);

import { IVehicle } from 'src/graphql/vehicles.graphql';
import { renderPossiblyNullPropertyName } from 'src/utils/formatter';

export const renderVehileInformation = (vehicle: IVehicle): string => {
  const { vehicleYear, vehicleMake, vehicleModel, vehicleColor } = vehicle;
  if (!vehicleYear && !vehicleMake && !vehicleModel && !vehicleColor) return '';
  else
    return `${renderPossiblyNullPropertyName(vehicleYear)} ${renderPossiblyNullPropertyName(
      vehicleMake,
    )} ${renderPossiblyNullPropertyName(vehicleModel)} ${
      renderPossiblyNullPropertyName(vehicleColor) ? `(${renderPossiblyNullPropertyName(vehicleColor)})` : ''
    }`;
};

export const renderTrailerInformation = (vehicle: IVehicle): string => {
  return `${renderPossiblyNullPropertyName(vehicle.vehicleColor)} Trailer`;
};

export const renderInformation = (vehicle: IVehicle): string => {
  const { isTrailer } = vehicle;
  return isTrailer ? renderTrailerInformation(vehicle) : renderVehileInformation(vehicle);
};

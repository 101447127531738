import { FC, useEffect, useState } from 'react';
import { TransponderType } from 'src/graphql/transponders.graphql';
import { Trans } from '@lingui/macro';
import { TransponderInfoCard } from 'src/components/Onboarding/OnboardingTransponders/TransponderInfoCard';
import { Radio } from 'antd';
import './SelectTransponderWithPlan.scss';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import BackButton from 'src/components/BackButton/BackButton';
import useTransponderPrices from 'src/hooks/domain/dashboard/transponders/useTransponderPrices';
import ReloadButton from 'src/components/ReloadButton/ReloadButton';
import { isNil } from 'lodash';
import { MUST_SELECT_TRANPONDER_TO_PROCEED } from './errorMessages';
interface ISelectTransponderWithPlan {
  proceedToNextStep: () => void;
  returnToPreviousStep: () => void;
  setTransponderType: (transponderType: TransponderType) => void;
}
const SelectTransponderWithPlan: FC<ISelectTransponderWithPlan> = ({
  proceedToNextStep,
  returnToPreviousStep,
  setTransponderType,
}) => {
  const [isPROselected, setIsPROselected] = useState<boolean | null>(null);
  const [isMINIselected, setIsMINIselected] = useState<boolean | null>(null);
  const { miniPrice, proPrice, errorLoading, PBCPrice } = useTransponderPrices();
  const [showUnselectedTranponderErrorMessage, setShowUnselectedTranponderErrorMessage] = useState(false);
  const generatePrice = (transponderType: TransponderType) => {
    switch (transponderType) {
      case TransponderType.PRO:
        return <>${PBCPrice + proPrice}</>;
      case TransponderType.MINI:
        return <>${PBCPrice + miniPrice}</>;
      case null:
        return null;
    }
  };
  useEffect(() => {
    setTransponderType(isPROselected ? TransponderType.PRO : TransponderType.MINI);
    (isPROselected || isMINIselected) && setShowUnselectedTranponderErrorMessage(false);
  }, [isPROselected, isMINIselected]);

  return (
    <div>
      {errorLoading || isNil(proPrice) || isNil(miniPrice) || isNil(PBCPrice) ? (
        <div className="page-error-container">
          <div className="page-error-message">
            <Trans>
              Unable to load this form. Please reload the page and try again.<br></br>We are sorry for the
              inconvenience.
            </Trans>
          </div>
          <ReloadButton />
        </div>
      ) : (
        <div>
          <div className="select-transponder-with-plan-container">
            <div className="my-6 text-center text-2xl font-bold text-primary-blue1">
              <Trans>Purchase Transponders</Trans>
            </div>
            <div className="flex w-full flex-col self-center">
              <div className="info-cards flex flex-col justify-between md:flex-row">
                <TransponderInfoCard transponderType={TransponderType.MINI} isDashboard={false} />{' '}
                <TransponderInfoCard transponderType={TransponderType.PRO} isDashboard={false} />
              </div>
            </div>
            <Radio.Group value={isPROselected} className="w-full">
              <div className="flex flex-col gap-4">
                <div className="resume-account-creation-card px-6 py-4 shadow-md ">
                  <Radio
                    onChange={() => {
                      setIsMINIselected(true);
                      setIsPROselected(false);
                    }}
                    value={false}
                  >
                    {' '}
                    <Trans>Mini Transponder + Pinellas Bayway Plan {generatePrice(TransponderType.MINI)}</Trans>
                  </Radio>
                </div>
                <div className="resume-account-creation-card  px-6 py-4 shadow-md">
                  <Radio
                    onChange={() => {
                      setIsPROselected(true);
                      setIsMINIselected(false);
                    }}
                    value={true}
                  >
                    <Trans>Pro Transponder + Pinellas Bayway Plan {generatePrice(TransponderType.PRO)}</Trans>
                  </Radio>
                </div>
              </div>
            </Radio.Group>
          </div>
          <div className="my-4 ml-auto w-32 text-right">
            {' '}
            <Trans>
              {' '}
              Subtotal:{' '}
              <b>
                {isPROselected === null && isMINIselected === null
                  ? '$0.00'
                  : generatePrice(isPROselected ? TransponderType.PRO : TransponderType.MINI)}
              </b>
            </Trans>
          </div>
          {showUnselectedTranponderErrorMessage ? (
            <div className="text-red text-center">{MUST_SELECT_TRANPONDER_TO_PROCEED}</div>
          ) : null}
          <div className="flex justify-between">
            <BackButton blockRedirectTill={true} onBack={() => returnToPreviousStep()}></BackButton>
            <CustomButton
              content="Continue"
              onClick={() =>
                isPROselected === null && isMINIselected === null
                  ? setShowUnselectedTranponderErrorMessage(true)
                  : proceedToNextStep()
              }
              type="button"
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default SelectTransponderWithPlan;

import { useLazyQuery } from '@apollo/client';
import { Trans } from '@lingui/macro';
import React, { useState } from 'react';
import { useLogin } from 'src/hooks/auth/useLogin';
import { IForgotUsernameOrPasswordForm } from 'src/components/ForgetUsernameOrPassword/ForgotUsernameOrPassword';
import {
  IForgotUsernameOutput,
  IForgotUsernameInput,
  FORGOT_USERNAME,
} from 'src/graphql/forgotUsernameOrPassword.graphql.';

interface IUseRetriveUsername {
  generateErrorMessage(): React.ReactElement;
  retrieveUsername(values: IForgotUsernameOrPasswordForm): void;
  resetRetrievingUsername(): void;
  onFoundUsername(): React.ReactElement | null;
  loading: boolean;
}
export const useRetrieveUsername = (onForgottenPassword: () => void): IUseRetriveUsername => {
  const [isErrorRetrievingUsername, setIsErrorRetrievingUsername] = useState<boolean>(false);
  const [foundUsername, setFoundUsername] = useState<string | null>(null);

  const [forgotUsername, { loading }] = useLazyQuery<
    { forgotUsername: IForgotUsernameOutput },
    { forgotUsernameInput: IForgotUsernameInput }
  >(FORGOT_USERNAME);

  const forgotPasswordLink = (
    <div className="my-5 text-left ">
      <div
        onClick={() => {
          onForgottenPassword();
          resetRetrievingUsername();
        }}
        className="green-link cursor-pointer"
      >
        <Trans>Forgot your password?</Trans>
      </div>
    </div>
  );

  const { loginForm, resetLoginState } = useLogin(forgotPasswordLink, false, foundUsername || '', true);

  const retrieveUsername = async (values: IForgotUsernameOrPasswordForm) => {
    const { accountNumber, transponderNumber, postalCode } = values;

    const forgotUsernameInput = accountNumber
      ? {
          zipcode: postalCode,
          accountNumber: accountNumber,
        }
      : {
          zipcode: postalCode,
          transponderNumber: transponderNumber,
        };

    try {
      await forgotUsername({
        variables: {
          forgotUsernameInput: forgotUsernameInput,
        },
      }).then((res) => {
        if (res.error) {
          setFoundUsername(null);
          setIsErrorRetrievingUsername(true);
        } else {
          setFoundUsername(res.data?.forgotUsername.username || null);
          setIsErrorRetrievingUsername(false);
        }
      });
    } catch (err) {
      console.log((err as Error).message);
    }
  };
  const resetRetrievingUsername = () => {
    resetLoginState();
    setFoundUsername(null);
    setIsErrorRetrievingUsername(false);
  };
  const generateErrorMessage = () => {
    if (isErrorRetrievingUsername) {
      return (
        <p className="text-red mt-6 text-center">
          <Trans>
            We were unable to validate your account. Please make sure you&apos;ve properly entered your postal code AND
            either your account number or your transponder number.
          </Trans>
        </p>
      );
    } else return <></>;
  };
  const onFoundUsername = () => {
    const successMsg = `Found it! Your username is ${foundUsername}. To proceed into your account, please enter your password below:`;
    if (foundUsername) {
      return (
        <div className="mt-8">
          {successMsg}
          <div className="mt-4 w-full sm:w-1/2">{loginForm}</div>
          <div className="my-3 text-center lg:text-left"></div>
        </div>
      );
    } else return null;
  };

  return {
    retrieveUsername,
    generateErrorMessage,
    resetRetrievingUsername,
    onFoundUsername,
    loading,
  };
};

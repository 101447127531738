import create from 'zustand';
import { devtools } from 'zustand/middleware';

interface IPaymentStore {
  cardType: string;
  updateCardType: (cardType: string) => void;
}

export const usePaymentStore = create<IPaymentStore, [['zustand/devtools', IPaymentStore]]>(
  devtools((set) => ({
    cardType: '',
    updateCardType: (cardType: string) => set(() => ({ cardType })),
  })),
);

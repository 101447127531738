import { t, Trans } from '@lingui/macro';
import { Button } from 'antd';
import React, { FC } from 'react';
import { IMailingAddress } from 'src/types/onboarding.types';

interface IMailingAddressProps {
  address: IMailingAddressData;
  onEdit?: () => void;
}
export interface IMailingAddressData extends IMailingAddress {
  zipcode?: string;
}
const MailingAddress: FC<IMailingAddressProps> = ({ address, onEdit }) => {
  const { addressLine1, addressLine2, city, state, postalCode, country, zipCode, zipcode } = address;
  return (
    <div>
      <div>
        <div className="title">
          <Trans>Mailing Address</Trans>
        </div>
        {onEdit ? (
          <Button type="link" onClick={onEdit} className="theme" aria-label={t`Edit Mailing Address`}>
            <span>
              <Trans>Edit</Trans>
            </span>
          </Button>
        ) : null}
      </div>
      <div>
        <div>{addressLine1}</div>
        {addressLine2 && <div>{addressLine2}</div>}
        <div>{`${city}, ${state}`}</div>
        <div>{`${postalCode || zipCode || zipcode}, ${country}`}</div>
      </div>
    </div>
  );
};

export default MailingAddress;

import { IAccountDetails } from '../../../graphql/accountDetails.graphql';
import { IAccountSettingsSecondaryContacts } from '../../../graphql/accountSettings.graphql';

export const mapAccountDetailsToUpdateInput = (accountInfo?: IAccountDetails) => {
  const secondaryContacts = accountInfo?.secondaryContacts.map((contact: IAccountSettingsSecondaryContacts) => {
    return {
      firstName: contact.firstName,
      lastName: contact.lastName,
      middleName: contact.middleName,
    };
  });
  return {
    userName: accountInfo?.username || '',
    addressLine1: accountInfo?.addressLine1 || '',
    addressLine2: accountInfo?.addressLine2 || '',
    city: accountInfo?.city || '',
    state: accountInfo?.state || '',
    country: accountInfo?.country || '',
    county: accountInfo?.country || '',
    zipCode: accountInfo?.zipcode || '',
    emailAddress: accountInfo?.emailAddress || '',
    secondaryEmailAddress: accountInfo?.secondaryEmailAddress || '',
    homePhone: accountInfo?.homePhone || '',
    workPhone: accountInfo?.workPhone || '',
    cellPhone: accountInfo?.cellPhone || '',
    secondaryContacts: accountInfo?.secondaryContacts ? secondaryContacts : [],
    deliveryFrequencyType: accountInfo?.stmtDeliveryInterval || '',
    deliveryModeType: accountInfo?.stmtDeliveryMethod || '',
    deliveryType: accountInfo?.deliveryType || '',
    language: accountInfo?.language || '',
    smsOption: accountInfo?.smsOption || false,
    currentPassword: accountInfo?.password || '',
    newPassword: '',
    retypeNewPassword: '',
    securityQuestions: accountInfo?.securityQuestions,
  };
};

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect, useRef } from 'react';
import { VehicleSearch } from './VehicleSearch/VehicleSearch';
import VehiclesList from './VehiclesList/VehiclesList';
import VehicleForm from './VehiclesList/VehicleForm';
import { MainLoader } from 'src/components/Loader/MainLoader';
import { useVehicleStore } from 'src/store/vehicles/vehicle.store';
import { useScroll } from '../../../hooks/screen/useScroll';
import useGetVehicles from 'src/hooks/domain/dashboard/vehicle/useGetVehicles';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import { useDashboardStore } from '../../../store/dashboard/dashboard.store';
import { VEHICLE_HEADER, VEHICLES_SECTION } from '../references';
import { checkIfElementExists } from '../checkIfElementExists';
import { Trans } from '@lingui/macro';
import { DashboardTabHeader } from '../DashboardTabHeader';
import useScrollOnPaginationChange from 'src/hooks/pagination/useScrollOnPaginationChange';

const Vehicles: FC = () => {
  const ref = useRef<HTMLButtonElement>(null);

  const { vehiclesData, vehiclesLoading, refetch } = useGetVehicles();

  const { scrollToElem } = useScroll();

  useScrollOnPaginationChange({ data: vehiclesData, scrollId: VEHICLE_HEADER });

  const {
    store: { vehicleToEdit, isFormVisible },
    setVehicleToEdit,
    setIsFormVisible,
  } = useVehicleStore();

  const openForm = () => {
    scrollToElem(ref);
    setIsFormVisible(true);
  };

  useEffect(() => {
    if (vehicleToEdit.vehicleId.length) {
      const vehicle = vehiclesData?.vehicleList.vehicles.find(
        (vehicle) => vehicleToEdit.vehicleId === vehicle.vehicleId,
      );
      vehicle && setVehicleToEdit(vehicle);
      openForm();
    }
  }, [vehicleToEdit]);

  const {
    setAddVehicleMode,
    store: { addVehicleMode },
  } = useDashboardStore();

  const vehicleSectionExists = checkIfElementExists(VEHICLES_SECTION);

  useEffect(() => {
    if (vehicleSectionExists && addVehicleMode) {
      scrollToElem(VEHICLES_SECTION, true);
      setAddVehicleMode(false);
    } else if (addVehicleMode) {
      setIsFormVisible(true);
    }
  }, [addVehicleMode, vehicleSectionExists]);

  return (
    <>
      <MainLoader loading={vehiclesLoading} />
      <DashboardTabHeader
        type="vehicles"
        bulkUploadLinkName={<Trans>Bulk Upload Vehicles</Trans>}
        templateUploadButtonName={<Trans>Download Bulk Upload Template</Trans>}
        headline={<Trans>My Vehicles</Trans>}
      />
      <VehicleSearch refetch={refetch} />
      {isFormVisible ? null : (
        <CustomButton
          icon={<FontAwesomeIcon icon={faPlus} className="mr-2 " />}
          onClick={openForm}
          className=" my-4 tracking-wide"
          content="Add New Vehicle"
          reference={ref}
        />
      )}
      {isFormVisible ? <VehicleForm refetch={refetch} /> : null}
      <VehiclesList vehiclesData={vehiclesData} refetch={refetch} />
    </>
  );
};

export default Vehicles;

import { PaymentMethodType } from 'src/components/Payments/Payments.enums';
import { IPaymentMethod } from 'src/components/Payments/Payments.interfaces';
import { IIncreaseBalanceInput } from 'src/graphql/payment.graphql';
import { IPaymentMethodGraphQl } from 'src/graphql/paymentMethods.graphql';

export const mapExistingPaymentMethodToSettleBallance = (
  paymentMethod: IPaymentMethodGraphQl,
  ballance: number,
  securityCode: string,
  replenishThreshold: number,
): IIncreaseBalanceInput => {
  const {
    paymentMethodId,
    bankAccountNumber,
    bankRoutingNumber,
    cardNumber,
    expirationMonth,
    expirationYear,
    firstName,
    middleName,
    lastName,
    addressLine1,
    addressLine2,
    city,
    country,
    state,
    zipCode,
    payType,
  } = paymentMethod;
  return {
    paymentMethodId,
    transactionAmount: ballance,
    bankAccountNumber,
    bankRoutingNumber,
    cardNumber,
    expirationMonth,
    expirationYear,
    securityCode,
    firstName,
    middleName,
    lastName,
    addressLine1,
    addressLine2,
    city,
    country,
    state,
    zipCode,
    payType,
    reBillThresholdAmount: replenishThreshold,
    easyPayOption: 'NONE', // TBD
  };
};

export const mapNewPaymenthMethodToSettleBallancePayment = (
  paymentMethod: IPaymentMethod,
  balance: number,
  cardType: string,
  replenishThreshold: number,
): IIncreaseBalanceInput => {
  return {
    transactionAmount: balance || 0,
    paymentMethodId: '',
    bankRoutingNumber: paymentMethod?.bankRoutingNumber || '',
    bankAccountNumber: paymentMethod?.bankAccountNumber || '',
    cardNumber: paymentMethod?.ccNumber || '',
    expirationMonth: paymentMethod?.ccExpirationMonth || 0,
    expirationYear: paymentMethod?.ccExpirationYear || 0,
    securityCode: paymentMethod?.ccSecurityCode || '',
    firstName: paymentMethod?.ccFirstName || paymentMethod?.bankFirstName || '',
    middleName: paymentMethod?.ccMiddleName || '',
    lastName: paymentMethod?.ccLastName || paymentMethod?.bankLastName || '',
    addressLine1: paymentMethod?.addressLine1 || '',
    addressLine2: paymentMethod?.addressLine2 || '',
    city: paymentMethod.city || '',
    state: paymentMethod?.state || '',
    country: paymentMethod?.country || '',
    zipCode: paymentMethod?.postalCode || '',
    easyPayOption: 'NONE',
    reBillThresholdAmount: replenishThreshold,
    payType: paymentMethod.paymentType === PaymentMethodType.BANK ? paymentMethod.bankAccountType || '' : cardType,
  };
};

export const mapPaymentMethodToAddFunds = (
  paymentMethod: IPaymentMethodGraphQl,
  amount: number,
  replenishThreshold: number,
): IIncreaseBalanceInput => {
  return {
    transactionAmount: amount,
    paymentMethodId: paymentMethod.paymentMethodId,
    bankRoutingNumber: paymentMethod.bankRoutingNumber,
    bankAccountNumber: paymentMethod.bankAccountNumber,
    cardNumber: paymentMethod.cardNumber,
    expirationMonth: paymentMethod.expirationMonth,
    expirationYear: paymentMethod.expirationYear,
    securityCode: paymentMethod.securityCode,
    firstName: paymentMethod.firstName,
    middleName: paymentMethod.middleName,
    lastName: paymentMethod.lastName,
    addressLine1: paymentMethod.addressLine1,
    addressLine2: paymentMethod.addressLine2,
    city: paymentMethod.city,
    state: paymentMethod.state,
    country: paymentMethod.country,
    zipCode: paymentMethod.zipCode,
    easyPayOption: 'NONE',
    reBillThresholdAmount: replenishThreshold,
    payType: paymentMethod.payType,
  };
};

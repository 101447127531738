import { FC, useMemo, useState } from 'react';
import useScreenSize from 'src/hooks/screen/useScreenSize';
import ActivityItemBigScreen from './ActivityItemBigScreen';
import ActivityItemSmallScreen from './ActivityItemSmallScreen';
import ActivityListHeader from './ActivityListHeader';
import { ITransactionActivity } from '../../../../graphql/transactionsActivity.graphql';
import { useNonInitialRender } from '../../../../hooks/effect/useNonInitialRender';
import { MIN_RECORDS_FOR_PAGINATION } from 'src/constants';
import Pagination from 'src/components/Pagination/Pagination';
import { usePaginationInfo } from 'src/store/pagination/pagination.store';
import EmptyStateMessage, { IListType } from 'src/components/EmptyStates/EmptyStateMessage';
import { useScroll } from 'src/hooks/screen/useScroll';
import { ACTIVITY_HEADER } from '../../references';

interface TranspondersActivityListProps {
  transactionActivities?: ITransactionActivity[];
  activitiesCount?: number;
}

const ActivityList: FC<TranspondersActivityListProps> = ({ transactionActivities, activitiesCount }) => {
  const [visibleActivityIndex, setVisibleActivityIndex] = useState('');
  const { screenSize } = useScreenSize();
  const { areActivitiesFiltersApplied } = usePaginationInfo();

  const { scrollToElem } = useScroll();

  useNonInitialRender(() => {
    if (!visibleActivityIndex) {
      scrollToElem(ACTIVITY_HEADER, true);
    }
  }, [visibleActivityIndex]);

  const activities = useMemo(() => {
    return (
      transactionActivities &&
      [...transactionActivities].sort((a, b) => (b.transactionDate < a.transactionDate ? -1 : 1))
    );
  }, [transactionActivities]);

  const transactionActivitiesLength = transactionActivities?.length || 0;

  return transactionActivitiesLength ? (
    <>
      {activitiesCount && activitiesCount > 0 ? (
        <>
          <ActivityListHeader />
          <ul>
            {activities?.map((transactionActivity) => {
              return (
                <>
                  {screenSize > 2 ? (
                    <ActivityItemBigScreen
                      key={transactionActivity.transactionNumber}
                      transactionActivity={transactionActivity}
                      visibleActivityIndex={visibleActivityIndex}
                      setVisibleActivityIndex={setVisibleActivityIndex}
                    />
                  ) : (
                    <ActivityItemSmallScreen
                      key={transactionActivity.transactionNumber}
                      transactionActivity={transactionActivity}
                      visibleActivityIndex={visibleActivityIndex}
                      setVisibleActivityIndex={setVisibleActivityIndex}
                    />
                  )}
                </>
              );
            })}
          </ul>
          {activitiesCount && activitiesCount >= MIN_RECORDS_FOR_PAGINATION ? (
            <Pagination count={activitiesCount} type="activities" setVisibleActivityIndex={setVisibleActivityIndex} />
          ) : null}
        </>
      ) : null}
    </>
  ) : (
    <EmptyStateMessage areFiltersApplied={areActivitiesFiltersApplied} listType={IListType.ACTIVITY} />
  );
};

export default ActivityList;

import { useRouteMonitor } from '../../../hooks/location/useRouteMonitor';
import { AccountType, FLEET_PLAN, OnboardingUserType, PERSONAL_PLAN } from '../../../types/onboarding.types';
import React, { ReactNode, useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { useOnboardingStore } from '../../../store/onboarding/onboarding.store';
import { SELECT_RECOMMENDED_SUNPASS_PLAN_NAME } from '../../../store/onboarding/onboarding.selectors';
import { OnboardingStepPath } from '../../../types/routes';

export interface IRecommendationCard {
  title: string;
  content?: ReactNode;
  selected?: boolean;
  onClick?: (value: any) => void;
  tabIndex?: number;
}

export interface IUseAccountRecommendation {
  selectable: boolean;
  onSubmit: () => void;
  cards: IRecommendationCard[];
  recommendedPlan: string;
}

export const useAccountRecommendation = (): IUseAccountRecommendation => {
  const history = useRouteMonitor();
  const {
    onboardingInfo: { userType, accountType, accountSubtype },
    updateOnboardingStore,
  } = useOnboardingStore();
  const recommendedPlan = SELECT_RECOMMENDED_SUNPASS_PLAN_NAME(accountType, accountSubtype);

  const selectable =
    userType === OnboardingUserType.COMMERCIAL ? ![PERSONAL_PLAN, FLEET_PLAN].includes(recommendedPlan) : true;

  const [selectedAccountType, setSelectedAccountType] = useState(accountType);

  useEffect(() => {
    setSelectedAccountType(accountType);
  }, [accountType]);
  const onSelectAccountType = (type: AccountType) => {
    setSelectedAccountType(type);
  };

  function onSubmit(): void {
    if (selectedAccountType) {
      updateOnboardingStore({ accountType: selectedAccountType });
      history.goToPath(OnboardingStepPath.ACCOUNT);
    }
  }

  let cards: IRecommendationCard[];

  switch (userType) {
    case OnboardingUserType.VISITOR:
      cards = [
        {
          title: t`Sounds great, let’s continue!`,
          content: t`I understand I will not be receiving a transponder but may upgrade my account at any time.`,
          onClick: () => onSelectAccountType(AccountType.PAYG),
          selected: selectedAccountType === AccountType.PAYG,
        },
        {
          title: t`I want to purchase a transponder and/or become a pre-paid SunPass member`,
          content: t`I travel throughout Florida frequently enough and would like the ability to pre-load funds into my account, obtain a transponder, and access SunPass Plus Parking.`,
          onClick: () => onSelectAccountType(AccountType.PRIVATE),
          selected: selectedAccountType === AccountType.PRIVATE,
        },
      ];
      break;
    case OnboardingUserType.COMMERCIAL:
      cards =
        recommendedPlan === PERSONAL_PLAN
          ? [
              {
                title: PERSONAL_PLAN,
                content: (
                  <React.Fragment>
                    <div className="mb-1">
                      <Trans>
                        I understand that having 100 or fewer transponders qualifies me for a personal account.
                      </Trans>
                    </div>
                    <Trans>
                      <strong>Note</strong>: If you&apos;d like to change your recommended plan, navigate back to the
                      previous step and change your previous selection.
                    </Trans>
                  </React.Fragment>
                ),
                selected: recommendedPlan === PERSONAL_PLAN,
                tabIndex: -1,
              },
            ]
          : [
              {
                title: t`I anticipate needing 101 or more transponders, and understand that this qualifies me for a commercial fleet account`,
                content: (
                  <React.Fragment>
                    <div className="mb-1">
                      <Trans>
                        I understand that an account is not recognized as a commercial fleet unless 101 or more
                        transponders are purchased.
                      </Trans>
                    </div>
                    <Trans>
                      <strong>Note</strong>: If you&apos;d like to change your recommended plan, navigate back to the
                      previous step and change your previous selection.
                    </Trans>
                  </React.Fragment>
                ),
                selected: recommendedPlan === FLEET_PLAN,
                tabIndex: -1,
              },
            ];
      break;
    default:
      cards = [];
  }

  return {
    onSubmit,
    cards,
    selectable,
    recommendedPlan,
  };
};

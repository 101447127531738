import React, { FC } from 'react';
import { t, Trans } from '@lingui/macro';
import ConfirmationModal from 'src/components/Modals/ConfirmationModal/ConfirmationModal';

interface IRemovePlanModal {
  showModal: boolean;
  onOk: () => void;
}

const RemovePlanModal: FC<IRemovePlanModal> = ({ showModal, onOk }) => {
  const okButtonText = t`Okay`;
  const title = (
    <>
      <h1 className="text-xl font-extrabold leading-6 xsm:leading-5">
        <Trans>Your plan will expire on September 30th</Trans>
      </h1>
    </>
  );

  const content =
    'The Pinellas Bayway Plan is an annual pass that is paid for in full ($50) at enrollment, and expires on September 30th regardless of when the pass was purchased. This transponder will be enrolled in the plan through the next September 30th, after which, you can elect to re-enroll or not. You cannot remove the plan from a transponder before September 30th.';

  return (
    <>
      {showModal && (
        <ConfirmationModal
          onOk={onOk}
          showModal={showModal}
          okButtonText={okButtonText}
          title={title}
          content={content}
          closable={true}
          alignButtons="center"
          alignText="center"
        />
      )}
    </>
  );
};
export default RemovePlanModal;

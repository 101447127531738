import { useContext, useEffect, useState } from 'react';
import {
  ADD_LINKED_CHILD_ACCOUNT,
  AddLinkedChildAccountInput,
  DELETE_LINKED_CHILD_ACCOUNT,
  GET_LINKED_ACCOUNTS,
  ILinkedAccount,
  ILinkedAccountsOutput,
  LinkedAccountStatus,
  UPDATE_LINKED_PARENT_ACCOUNT,
  UpdateLinkedParentAccountAction,
  UpdateLinkedParentAccountInput,
} from '../graphql/linkedAccounts.graphql';
import { useMutation, useQuery } from '@apollo/client';
import { showMessage } from '../utils/message.utils';
import { t } from '@lingui/macro';
import _ from 'lodash';
import { NotificationContext } from '../context/NotificationContext';

interface IUseLinkedAccounts {
  activeAccounts?: ILinkedAccount[];
  pendingAccountRequests?: ILinkedAccount[];
  childAccounts?: ILinkedAccount[];
  loading: boolean;
  approveParentAccountAccess: (accountNumber: string) => Promise<void>;
  denyParentAccountAccess: (accountNumber: string) => Promise<void>;
  removeParentAccountAccess: (accountNumber: string) => Promise<void>;
  deleteLinkedAccount: (childAccountNumber?: string) => Promise<void>;
  add: (requestData: AddLinkedChildAccountInput) => Promise<void>;
  error: boolean;
}

export const useLinkedAccounts = (skip = false): IUseLinkedAccounts => {
  const [activeAccounts, setActiveAccounts] = useState<ILinkedAccount[]>();
  const [pendingAccountRequests, setPendingAccountRequests] = useState<ILinkedAccount[]>();
  const [childAccounts, setChildAccounts] = useState<ILinkedAccount[]>();
  const [error, setError] = useState(false);
  const { data, loading: linkedAccountListLoading } = useQuery<{ linkedAccountLists: ILinkedAccountsOutput }>(
    GET_LINKED_ACCOUNTS,
    {
      skip,
      notifyOnNetworkStatusChange: true,
    },
  );

  useEffect(() => {
    setPendingAccountRequests(
      data?.linkedAccountLists?.parentAccountList.filter((account) => account.status === LinkedAccountStatus.OPEN),
    );
    setActiveAccounts(
      data?.linkedAccountLists?.parentAccountList.filter((account) => account.status === LinkedAccountStatus.ACTIVE),
    );
    setChildAccounts(
      data?.linkedAccountLists?.childAccountList.filter(
        (account) => account?.status === LinkedAccountStatus.ACTIVE || account?.status === LinkedAccountStatus.OPEN,
      ),
    );
  }, [data]);

  const notificationCtx = useContext(NotificationContext);
  const { loadNotifications } = notificationCtx || {};

  const [updateLinkedParentAccount, { loading: updateLinkAccountLoading }] = useMutation<
    { updateLinkedParentAccount: boolean },
    { updateLinkedParentAccountInput: UpdateLinkedParentAccountInput }
  >(UPDATE_LINKED_PARENT_ACCOUNT, {
    onCompleted: async () => {
      loadNotifications && (await loadNotifications(true));
    },
    awaitRefetchQueries: true,
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      {
        query: GET_LINKED_ACCOUNTS,
      },
    ],
  });
  const update = async (action: UpdateLinkedParentAccountAction, accountNumber: string) => {
    try {
      await updateLinkedParentAccount({
        variables: {
          updateLinkedParentAccountInput: {
            action,
            parentAccountNumber: accountNumber,
          },
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const [deleteLinkedChildAccount, { loading: deleteLinkedAccountLoading }] = useMutation<
    { deleteLinkedChildAccount: boolean },
    { childAccountNumber: string }
  >(DELETE_LINKED_CHILD_ACCOUNT, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_LINKED_ACCOUNTS,
      },
    ],
  });

  const deleteLinkedAccount = async (childAccountNumber?: string) => {
    try {
      childAccountNumber &&
        (await deleteLinkedChildAccount({
          variables: {
            childAccountNumber,
          },
        }));
    } catch (err) {
      console.log(err);
    }
  };

  const [addLinkedChildAccount, { loading: addLinkedAccountLoading }] = useMutation<
    { addLinkedChildAccount: boolean },
    { addLinkedChildAccountInput: AddLinkedChildAccountInput }
  >(ADD_LINKED_CHILD_ACCOUNT, {
    onError() {
      setError(true);
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_LINKED_ACCOUNTS,
      },
    ],
  });

  const add = async (input: AddLinkedChildAccountInput) => {
    try {
      setError(false);
      const respnse = await addLinkedChildAccount({
        variables: {
          addLinkedChildAccountInput: input,
        },
      });
      _.isEmpty(respnse?.errors) && showMessage('success', t`Request sent successfully.`);
    } catch (err) {
      console.log(err);
    }
  };

  return {
    activeAccounts,
    pendingAccountRequests,
    childAccounts,
    loading:
      updateLinkAccountLoading || linkedAccountListLoading || deleteLinkedAccountLoading || addLinkedAccountLoading,
    approveParentAccountAccess: (accountNumber: string) =>
      update(UpdateLinkedParentAccountAction.APPROVE, accountNumber),
    denyParentAccountAccess: (accountNumber: string) => update(UpdateLinkedParentAccountAction.DENY, accountNumber),
    removeParentAccountAccess: (accountNumber: string) => update(UpdateLinkedParentAccountAction.DELETE, accountNumber),
    deleteLinkedAccount,
    add,
    error,
  };
};

import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import MaskedInput from 'antd-mask-input';
import { ControllerFieldState, ControllerRenderProps, useFormContext, useWatch } from 'react-hook-form';
import { IAccountInfo } from '../../types/onboarding.types';
import { MaskType } from 'antd-mask-input/build/main/lib/MaskedInput';
import { concat, countNumeric } from '../../utils/string.utils';
import { InputRef } from 'antd';
import { formatPhoneNumber } from '../../utils/formatter';

type NumberInputNameType = 'cellPhone' | 'homePhone' | 'workPhone' | 'fein';

type FormatterFieldType = 'phone';

export interface ICustomMaskedInput {
  field:
    | ControllerRenderProps<IAccountInfo, 'homePhone'>
    | ControllerRenderProps<IAccountInfo, 'cellPhone'>
    | ControllerRenderProps<IAccountInfo, 'workPhone'>
    | ControllerRenderProps<IAccountInfo, 'fein'>;
  fieldState: ControllerFieldState;
  id: NumberInputNameType;
  suffix?: React.ReactNode;
  onFocus?: React.FocusEventHandler<HTMLInputElement> | undefined;
  onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => any;
  placeholder?: boolean;
  maxLength: number;
  mask: MaskType;
  ref?: React.Ref<InputRef>;
  value?: string;
  formatterType?: FormatterFieldType;
  disabled?: boolean;
  label?: string;
}

export const CustomMaskedInput = ({
  field,
  fieldState,
  id,
  suffix,
  onFocus,
  onBlur,
  placeholder,
  onChange,
  mask,
  maxLength,
  ref,
  value,
  formatterType,
  disabled,
  label,
}: ICustomMaskedInput) => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext<IAccountInfo>();
  const watchValue = useWatch({ name: id });
  const watchValueTrimmed = watchValue?.replaceAll(/_|\W/g, '');
  const [prevValue, setPrevValue] = useState<string | undefined>(watchValue);
  const [onPasteValue, setOnPasteValue] = useState<string>();
  const [onChangeValue, setOnChangeValue] = useState<string>();

  const actualLength = useMemo((): number => {
    return onPasteValue ? countNumeric(prevValue) : countNumeric(onChangeValue);
  }, [onChangeValue, onPasteValue, errors]);

  const isAboutToExceedAfterPaste = (): boolean => {
    if (!onPasteValue && countNumeric(watchValue) == actualLength) return false;
    return watchValue === prevValue || countNumeric(watchValue) <= actualLength;
  };

  useEffect(() => {
    setPrevValue(watchValueTrimmed);
    setValue(id, watchValueTrimmed);
  }, [onChangeValue, errors]);

  useEffect(() => {
    if (onPasteValue && actualLength < maxLength) {
      const newValueFormatted = concat(prevValue, onPasteValue, maxLength);
      setValue(id, newValueFormatted);
      setPrevValue(newValueFormatted);
    } else if (prevValue && actualLength >= maxLength) {
      const newValue = isAboutToExceedAfterPaste() ? prevValue : watchValue;
      setPrevValue(newValue);
      setValue(id, newValue);
    }
    setOnPasteValue(undefined);
  }, [onPasteValue]);

  useEffect(() => {
    if (formatterType === 'phone') {
      setValue(id, formatPhoneNumber(watchValue));
    }
  }, [watchValue]);

  return (
    <MaskedInput
      {...field}
      ref={ref}
      id={id}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setOnChangeValue(e.target.value?.replaceAll(/_|\W/g, ''));
        field.onChange(e);
      }}
      onPaste={(event: React.ClipboardEvent<HTMLInputElement> | undefined) => {
        event?.preventDefault();
        setOnPasteValue(event?.currentTarget.value);
      }}
      mask={mask}
      className={classNames({
        'input-error': fieldState.invalid && errors[id]?.type !== 'required',
      })}
      disabled={disabled}
      {...(value && { value: value })}
      {...(onFocus && { onFocus: onFocus })}
      {...(onChange && { onChange: onChange })}
      {...(onBlur && { onBlur: onBlur })}
      {...(suffix && { suffix: suffix })}
      {...(placeholder && { placeholder: '' })}
      {...(label && { 'aria-label': label })}
    />
  );
};

import { IVehicleListInput } from 'src/graphql/vehicles.graphql';
import { ITransponderInput } from '../../../../graphql/transponders.graphql';

export const DEFAULT_TRANSPONDERS_FETCH_PARAMS: ITransponderInput = {
  pagination: {
    pageSize: 7,
    pageNumber: 1,
  },
  filter: {
    sortColumn: 'status',
  },
};

export const DEFAULT_SHORT_TRANSPONDERS_FETCH_PARAMS: ITransponderInput = {
  pagination: {
    pageSize: 5,
    pageNumber: 1,
  },
};

export const DEFAULT_VEHICLES_FETCH_PARAM: IVehicleListInput = {
  pagination: {
    pageSize: 7,
    pageNumber: 1,
  },
};

export const DEFAULT_SHORT_VEHICLES_FETCH_PARAM: IVehicleListInput = {
  pagination: {
    pageSize: 5,
    pageNumber: 1,
  },
};

import { AccountType } from '../../../types/onboarding.types';
import React from 'react';
import { t, Trans } from '@lingui/macro';
import SelectableCard from '../../SelectableCard/SelectableCard';

interface FleetQuestionProps {
  onSelect: (type: AccountType) => void;
  error: boolean;
  selected?: AccountType;
}

export const FleetQuestion = (props: FleetQuestionProps): React.ReactElement => {
  const { onSelect, error, selected } = props;
  return (
    <React.Fragment>
      <div className="onboarding-fleet__question onboarding-container grid  grid-cols-1 md:grid-cols-2">
        <h5 className="question__title">
          <Trans>
            Based on the transponder capacities listed above, approximately how many SunPass transponders will your
            fleet require?
          </Trans>
        </h5>
        <SelectableCard
          key={AccountType.PRIVATE}
          title={t`I need 100 or fewer transponders`}
          content={t`I need 100 or fewer transponders and do not anticipate exceeding 100 transponders.`}
          onClick={() => onSelect(AccountType.PRIVATE)}
          selected={selected === AccountType.PRIVATE}
        ></SelectableCard>

        <SelectableCard
          key={AccountType.COMMERCIAL}
          title={t`I need 101 or more transponders`}
          content={t`I need 101 or more transponders, or am looking to purchase over 101 transponders with an anticipated future need for over 101 transponders.`}
          onClick={() => onSelect(AccountType.COMMERCIAL)}
          selected={selected === AccountType.COMMERCIAL}
        ></SelectableCard>
      </div>
      <div className={`form-error mt-6 text-center ${error ? 'visible' : 'hidden'}`}>
        <Trans>You must make a selection before continuing.</Trans>
      </div>
    </React.Fragment>
  );
};

import { gql } from '@apollo/client';

export const SIGN_UP_RETRIVE_DATA = gql`
  query signUpRetrieveData($signUpRetrieveDataInput: SignUpRetrieveDataInput!) {
    signUpRetrieveData(signUpRetrieveDataInput: $signUpRetrieveDataInput) {
      referenceNumber
      accountId
      accountDetailsSignUpData {
        accountType
        accountSubType
        firstName
        middleName
        lastName
        fein
        companyName
        dbaName
        secondaryContacts {
          firstName
          middleName
          lastName
        }
        addressLine1
        addressLine2
        country
        city
        state
        zipcode
        zipcodePlus
        email
        reTypeEmail
        additionalEmailAddress {
          email
          reTypeEmail
        }
        cellPhone
        homePhone
        workPhone
        smsOption
        statementType
        username
        password
        reTypePassword
        securityQuestion
        securityQuestionAnswer
        digitPin
      }
      title
      suffix
      marketOption
      parkingOption
      vehiclesTranspondersSignUpData {
        miniTranspondersCount
        proTranspondersCount
        vehicleUploadBatchId
        vehicleUploadCount
        vehicles {
          effectiveEndDate
          effectiveEndTime
          effectiveStartDate
          effectiveStartTime
          index
          plateNumber
          plateState
          plateType
          isRental
          isTrailer
          vehicleCountry
          vehicleMake
          vehicleModel
          vehicleColor
          vehicleYear
          vehicleType
          vehicleId
          vehicleNickName
        }
      }
      cleanseFlag
      nonRevenueOption
      postPaidOption
      invDiscountAmount
      invTotalOutstandingAmount
      actConvPromoCode
    }
  }
`;

export interface ISignUpRetrieveDataInput {
  referenceNumber: string;
  lastName: string;
  zipcode?: string;
  postalCode?: string;
  invoiceDetails?: string;
}

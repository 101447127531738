import React, { useEffect } from 'react';
import { t, Trans } from '@lingui/macro';
import { Form, Checkbox, Radio } from 'antd';
import { useFormContext, Controller } from 'react-hook-form';
import PriceInput from '../../PriceInput/PriceInput';
import Note from '../../Note/Note';
import { AccountType } from '../../../types/onboarding.types';
import { useQuery } from '@apollo/client';
import { REPLENISHMENT_AMOUNT } from '../../../graphql/replenishment-amount.graphql';
import { GET_ACCOUNT_DETAILS } from '../../../graphql/accountDetails.graphql';
import { IPaymentMethodForm } from '../Payments.interfaces';
import PaymentMethodFormField from '../Payments.enums';

export interface ISignUpForEasyPayProps {
  isAddingPaymentMethod: boolean | undefined;
  isEditMode: boolean | undefined;
  isEasyPayMethod: boolean | undefined;
  disableEasyPayOption: boolean | undefined;
}

const SignUpForEasyPay = ({ isEasyPayMethod, disableEasyPayOption, isEditMode }: ISignUpForEasyPayProps) => {
  const {
    watch,
    setValue,
    control,
    formState: { errors },
  } = useFormContext<IPaymentMethodForm>();

  const account = useQuery(GET_ACCOUNT_DETAILS)?.data?.accountDetails;
  const accountType = account?.accountType;

  const replenishmentAmounts = useQuery(REPLENISHMENT_AMOUNT)?.data?.replenishmentAmount;
  const replenishmentAmount: string =
    accountType !== AccountType.COMMERCIAL ? replenishmentAmounts?.personal || 0 : replenishmentAmounts?.fleet || 0;

  useEffect(() => {
    watch(PaymentMethodFormField.REPLENISHMENT_AMOUNT) === undefined &&
      setValue(PaymentMethodFormField.REPLENISHMENT_AMOUNT, Number(replenishmentAmount).toFixed(2));
  }, [watch(PaymentMethodFormField.REPLENISHMENT_AMOUNT)]);

  useEffect(() => {
    if (
      watch(PaymentMethodFormField.AUTHORIZE_REPLENISHMENT) === undefined &&
      watch(PaymentMethodFormField.IS_EASY_PAY) === true
    ) {
      setValue(PaymentMethodFormField.AUTHORIZE_REPLENISHMENT, true);
    }
  }, [watch(PaymentMethodFormField.AUTHORIZE_REPLENISHMENT), watch(PaymentMethodFormField.IS_EASY_PAY)]);

  useEffect(() => {
    if (disableEasyPayOption) {
      setValue(PaymentMethodFormField.IS_EASY_PAY, true);
    } else setValue(PaymentMethodFormField.IS_EASY_PAY, false);
  }, [disableEasyPayOption]);

  useEffect(() => {
    if (isEasyPayMethod === true) {
      setValue(PaymentMethodFormField.IS_EASY_PAY, true);
    } else {
      setValue(PaymentMethodFormField.IS_EASY_PAY, false);
    }
  }, [isEasyPayMethod]);

  return (
    <>
      <div className="mt-5 flex flex-wrap">
        <div className="w-full pb-5 text-left font-bold">
          <Trans>
            Would you like to enroll this payment method into Easy Pay for automatic replenishment on future tolls?
          </Trans>
        </div>
        <div className={`${disableEasyPayOption ? 'cursor-not-allowed' : ''} w-full text-left`}>
          <Form.Item
            name={PaymentMethodFormField.IS_EASY_PAY}
            className={`${disableEasyPayOption ? 'disable-radio-btn' : ''} theme`}
            help={errors.optInEZPay?.message as string}
            required
            validateStatus={errors.optInEZPay ? 'error' : ''}
          >
            <Controller
              name={PaymentMethodFormField.IS_EASY_PAY}
              control={control}
              render={({ field }) => (
                <Radio.Group
                  {...field}
                  id={PaymentMethodFormField.IS_EASY_PAY}
                  value={watch(PaymentMethodFormField.IS_EASY_PAY)}
                >
                  <Radio value={true}>
                    <Trans>Yes</Trans>
                  </Radio>
                  <Radio value={false}>
                    <Trans>No</Trans>
                  </Radio>
                </Radio.Group>
              )}
            />
          </Form.Item>
        </div>
      </div>

      {watch(PaymentMethodFormField.IS_EASY_PAY) && !(isEasyPayMethod === true && isEditMode === true) && (
        <React.Fragment>
          <div className="onboarding-easy-pay_recurring_payment">
            <div className="mt-2 w-full pb-5 text-left font-bold">
              <Trans>Your Replenishment Amount</Trans>
            </div>
            <div className="flex flex-col gap-8 text-left md:flex-row">
              <Form.Item
                name={PaymentMethodFormField.REPLENISHMENT_AMOUNT}
                hasFeedback
                validateStatus={errors.replenishmentThresholdAmount ? 'error' : ''}
                help={errors.replenishmentThresholdAmount?.message as string}
                initialValue={Number(replenishmentAmount).toFixed(2)}
              >
                <Controller
                  name={PaymentMethodFormField.REPLENISHMENT_AMOUNT}
                  control={control}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <PriceInput
                      id={PaymentMethodFormField.REPLENISHMENT_AMOUNT}
                      hasErrors={!!errors.replenishmentThresholdAmount}
                      value={value || Number(replenishmentAmount).toFixed(2)}
                      onChange={onChange}
                      onBlur={onBlur}
                      aria-label={t`Replenishment amount`}
                    />
                  )}
                />
              </Form.Item>

              <div className="replenishment-threshold-note">
                <Note aria-label="replenishment-threshold-note">
                  <div>
                    <Trans>
                      When your account reaches the specified low balance amount for your account type ($5 for SunPass
                      Personal Accounts), the replenishment amount you set will be automatically withdrawn from your
                      payment method and added to your SunPass balance.{' '}
                      <strong>${replenishmentAmount} minimum required</strong>.
                    </Trans>
                  </div>
                </Note>
              </div>
            </div>
          </div>
          <div className="mt-5 text-left">
            <Form.Item>
              <Controller
                name={PaymentMethodFormField.AUTHORIZE_REPLENISHMENT}
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    id={PaymentMethodFormField.AUTHORIZE_REPLENISHMENT}
                    name={PaymentMethodFormField.AUTHORIZE_REPLENISHMENT}
                    defaultChecked={true}
                    checked={watch(PaymentMethodFormField.AUTHORIZE_REPLENISHMENT)}
                    onChange={(e) => {
                      setValue(PaymentMethodFormField.AUTHORIZE_REPLENISHMENT, e.target.checked as never);
                    }}
                  >
                    <Trans>
                      <b>
                        I authorize SunPass to process payments with my credit/debit card as indicated above. Whenever
                        my prepaid toll balance is below the threshold amount, I authorize SunPass to charge my bank
                        account an amount that reflects my average monthly toll usage. I am aware that my replenishment
                        amount may vary by usage. I understand and agree that I have read, understand and accept the
                        Terms and Conditions.
                      </b>
                    </Trans>
                  </Checkbox>
                )}
              />
            </Form.Item>
          </div>
        </React.Fragment>
      )}
    </>
  );
};
export default SignUpForEasyPay;

import React, { useEffect, useState } from 'react';
import '../MyAccount.scss';
import useScreenSize from 'src/hooks/screen/useScreenSize';
import ScrollToTop from '../../ScrollToTop/ScrollToTop';
import BackLink from '../BackLink';
import { FormProvider, useForm } from 'react-hook-form';
import { Form } from 'antd';
import { ContactInformation } from './ContactInformation';
import { MailingAddressInfo } from '../../Onboarding/Account/MailingAddressInfo';
import { AdditionalContactsInfo } from '../../Onboarding/Account/AdditionalContactsInfo';
import { t } from '@lingui/macro';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQuery } from '@apollo/client';
import { GET_ACCOUNT_DETAILS, IAccountDetails } from '../../../graphql/accountDetails.graphql';
import {
  AccountType,
  IEmailAddresses,
  IFleetGovAdditionalInfo,
  IMailingAddress,
  IPhonesInfo,
} from '../../../types/onboarding.types';
import ConfirmationModal from '../../Modals/ConfirmationModal/ConfirmationModal';
import useCountryOptions from '../../../hooks/domain/address/useCountryOptions';
import useStateOptions from '../../../hooks/domain/address/useStateOptions';
import useCountyOptions from '../../../hooks/domain/address/useCountyOptions';
import { MainLoader } from '../../Loader/MainLoader';
import { CloseAccountSection } from './CloseAccountSection';
import { BillingAddressFormField } from '../../Address/AddressFormField.enum';
import { useDynamicContactSettingsValidation } from '../../../hooks/domain/contactSettings/useDynamicContactSettingsValidation';
import { CONTACT_SETTINGS_DEFAULT_VALUES, generateDefaultValuesFromAccountDetails } from './initialState';
import { useContactSubmitting } from '../../../hooks/domain/contactSettings/useContactSubmitting';
import { useContactSettingsStore } from '../../../store/account/contactSettings.store';
import { useScroll } from '../../../hooks/screen/useScroll';
import { ICreditCard } from 'src/components/Payments/Payments.interfaces';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import ScreenSize from 'src/types/ScreenSize.enum';
import { useAccountSettings } from 'src/store/account/accountSettings.store';
import SecurityCodeModal from 'src/components/Payments/PaymentMethods/SecurityCodeModal';
import { IAccountSettingsSecondaryContacts } from '../../../graphql/accountSettings.graphql';
import { usePlans } from '../../Dashboard/MyDashboard/usePlans';

export type InputPropsType = {
  disabled?: boolean;
  authorizedUserAccountType?: AccountType;
  isGovAccount?: boolean;
};

export type BillingAddressType = { [key in BillingAddressFormField]: string };

export interface ICloseAccountPayment extends ICreditCard, BillingAddressType {
  reason: string;
}

export interface IContactSettings
  extends IEmailAddresses,
    IFleetGovAdditionalInfo,
    IPhonesInfo,
    IMailingAddress,
    ICloseAccountPayment {
  additionalContacts?: IAccountSettingsSecondaryContacts[];
}

export default function Contact(): React.ReactElement {
  const [shownErrorModal, setShownErrorModal] = useState<boolean>(true);
  const [isCloseAccountActive, setIsCloseAccountActive] = useState(false);

  const { screenSize } = useScreenSize();

  const { data } = useQuery<{ accountDetails: IAccountDetails }>(GET_ACCOUNT_DETAILS);

  const { setIsTabDirty } = useAccountSettings();

  const { GOVAccount } = usePlans();

  const { schema } = useDynamicContactSettingsValidation({
    accountDetails: data?.accountDetails,
    GOVAccount,
  });

  const { data: accountDetailsData } = useQuery<{ accountDetails: IAccountDetails }>(GET_ACCOUNT_DETAILS);

  const formattedBalance = Math.abs(Number(accountDetailsData?.accountDetails.currentBalance));

  const methods = useForm<IContactSettings>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: schema ? yupResolver(schema) : undefined,
    defaultValues: data
      ? generateDefaultValuesFromAccountDetails(data?.accountDetails)
      : CONTACT_SETTINGS_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    watch,
    reset,
    formState: { isSubmitSuccessful, errors, isDirty },
  } = methods;

  const { submitForm, setIsSubmitBtnDisabled, loading, modals } = useContactSubmitting({ methods });

  const { errorLoading: countryErrorLoading } = useCountryOptions();
  const { errorLoading: stateErrorLoading } = useStateOptions({
    country: watch('country') as string,
  });
  const { errorLoading: countyErrorLoading } = useCountyOptions({
    state: watch('state') as string,
  });

  const {
    closeAccountInfo: { isCloseProcessActive, isNewCardChosen, existingCard },
  } = useContactSettingsStore();

  const { scrollToError } = useScroll();

  useEffect(() => {
    isDirty ? setIsTabDirty(true) : setIsTabDirty(false);
  }, [isDirty]);

  useEffect(() => {
    setShownErrorModal(countryErrorLoading || countyErrorLoading || stateErrorLoading);
  }, [countyErrorLoading, countryErrorLoading, stateErrorLoading]);

  useEffect(() => {
    scrollToError(errors);
  }, [errors]);

  const resetValuesToDefault = () => {
    reset(data ? generateDefaultValuesFromAccountDetails(data?.accountDetails) : CONTACT_SETTINGS_DEFAULT_VALUES);
  };

  useEffect(() => {
    resetValuesToDefault();
  }, [data]);

  const isBallanceNegative = data?.accountDetails && data.accountDetails.currentBalance < 0;
  const isPaymentChosen = isNewCardChosen || existingCard !== null;
  const shouldButtonBeHidden = isBallanceNegative && !isPaymentChosen && isCloseProcessActive;
  const areChangesUnsaved = (!shouldButtonBeHidden && isCloseAccountActive) || isDirty;

  return (
    <div className="w-full pt-5 large-tablet:pt-0">
      <MainLoader loading={loading} />
      <ScrollToTop triggerValues={isSubmitSuccessful} blockScroll={isCloseProcessActive} />
      {screenSize < ScreenSize.XL ? (
        <div className="mb-5 ml-5">
          <BackLink />
        </div>
      ) : null}
      <Form className="theme flex flex-col" layout="vertical" size="large">
        <FormProvider {...methods}>
          <ContactInformation disabled={isCloseProcessActive} />
          <MailingAddressInfo
            errorLoadingAddress={countryErrorLoading || countyErrorLoading || stateErrorLoading}
            isWallet={true}
            disabled={isCloseProcessActive}
            isCountyRequired={false}
          />
          <AdditionalContactsInfo
            withInitialQuestion={true}
            disabled={isCloseProcessActive}
            accountDetailsData={accountDetailsData?.accountDetails}
            isInDashboard
          />
          <CloseAccountSection
            setIsSubmitBtnDisabled={setIsSubmitBtnDisabled}
            isCloseAccountActive={isCloseAccountActive}
            setIsCloseAccountActive={setIsCloseAccountActive}
          />
          <ConfirmationModal
            title={t`Try Again Later`}
            content={t`Cannot load data.`}
            showModal={shownErrorModal}
            onOk={() => setShownErrorModal(false)}
            closeType
            alignButtons="center"
            alignText="center"
          />
          <SecurityCodeModal
            paymentMethod={existingCard}
            amount={formattedBalance}
            onSubmit={handleSubmit(submitForm)}
          />
          {[...modals]}
          {areChangesUnsaved ? (
            <CustomButton content="Save Changes" onClick={handleSubmit(submitForm)} className="mx-auto mt-10" />
          ) : null}
        </FormProvider>
      </Form>
    </div>
  );
}

import { gql } from '@apollo/client';
import { IAccountSecurityQuestions } from './accountDetails.graphql';

export const UPDATE_ACCOUNT_SETTINGS = gql`
  mutation updateAccountSettings($accountSettingsUpdateInput: AccountSettingsUpdateInput!) {
    updateAccountSettings(accountSettingsUpdateInput: $accountSettingsUpdateInput)
  }
`;

export interface IAccountSettingsUpdateInput {
  userName?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  country?: string;
  county?: string;
  zipCode?: string;
  emailAddress?: string;
  secondaryEmailAddress?: string;
  homePhone?: string;
  workPhone?: string;
  cellPhone?: string;
  faxPhone?: string;
  secondaryContacts?: IAccountSettingsSecondaryContacts[];
  deliveryFrequencyType?: string;
  deliveryModeType?: string;
  deliveryType?: string;
  language?: string;
  smsOption?: boolean;
  currentPassword?: string;
  newPassword?: string;
  retypeNewPassword?: string;
  securityQuestions?: IAccountSecurityQuestions;
  plannedNonUse?: string;
  surveyOptIn?: string;
  marketOption?: boolean;
  accountClosingDate?: string;
  securityPin?: string;
  replenishmentAmount?: string;
  parkingOptIn?: boolean;
}

export interface IAccountSettingsSecondaryContacts {
  firstName: string;
  lastName: string;
  middleName: string;
}

import { InvoiceTransaction } from 'src/graphql/invoiceTransactions.graphql';
import { convertPriceToString } from 'src/utils/formatter';

export const getTotalAmount = (data: InvoiceTransaction[]) => {
  return convertPriceToString(data.reduce((acc, cur) => acc + cur.invTransactionAmt, 0));
};

export const getTotalElementsLabel = (data: number) => (data <= 100 ? data : '100+');

const now = new Date();

const endYear = now.getFullYear();
const endMonth = now.getMonth() < 10 ? `0${now.getMonth() + 1}` : `${now.getMonth() + 1}`;
const endDay = now.getDate() < 10 ? `0${now.getDate()}` : `${now.getDate()}`;

export const endDate = `${endYear}-${endMonth}-${endDay}`;

now.setDate(now.getDate() - 365);

const startYear = now.getFullYear();
const startMonth = now.getMonth() < 10 ? `0${now.getMonth() + 1}` : `${now.getMonth() + 1}`;
const startDay = now.getDate() < 10 ? `0${now.getDate()}` : `${now.getDate()}`;

export const startDate = `${startYear}-${startMonth}-${startDay}`;

import React, { FC, useState } from 'react';
import { formatDate, formatTime } from '../../../../utils/time.utils';
import { ITransactionActivity } from '../../../../graphql/transactionsActivity.graphql';
import { isDigitsOnly } from '../activityUtils';
import { addPlusToBalanceIfPositive, convertPriceToString } from 'src/utils/formatter';
import { splitActivity } from '../ActivitySearch/activity.utils';
import ActivityDetails from './ActivityDetails';

interface IActivityItemBigScreenProps {
  transactionActivity: ITransactionActivity;
  visibleActivityIndex: string;
  setVisibleActivityIndex: React.Dispatch<React.SetStateAction<string>>;
}

const ActivityItemBigScreen: FC<IActivityItemBigScreenProps> = ({
  transactionActivity,
  visibleActivityIndex,
  setVisibleActivityIndex,
}) => {
  const { transactionDate, exitTime, activity, amount, exitPlaza, tagOrPlateNumber, transactionNumber } =
    transactionActivity;

  const [isBalancePositive] = useState(amount > 0);
  const [isBalanceNeutral] = useState(amount === 0);
  const areDetailsShown = visibleActivityIndex === transactionNumber;

  return (
    <li className="rounded-lg bg-white shadow-lg">
      <button
        className={`${
          areDetailsShown ? 'hidden' : 'cursor-pointer'
        } mb-3 flex w-full cursor-pointer flex-row items-center justify-between rounded-lg bg-white py-6 text-left shadow-lg md:px-8`}
        onClick={() => {
          setVisibleActivityIndex(transactionNumber);
        }}
      >
        <div className="w-2/12 ">{formatDate(transactionDate, true)}</div>
        <div className="w-1/12 ">{formatTime(exitTime)}</div>
        <div className="w-2/12">{splitActivity(activity)}</div>
        <div className="w-2/12">{activity.toLowerCase() === 'toll' ? exitPlaza : 'Payments / Adjustments'}</div>
        <div className="w-2/12">{isDigitsOnly(tagOrPlateNumber) ? tagOrPlateNumber : '-'}</div>
        <div
          className={`${isBalancePositive || isBalanceNeutral ? 'text-black' : 'text-red'} w-1/12 text-right font-bold`}
        >
          {addPlusToBalanceIfPositive(convertPriceToString(amount, false))}
        </div>
      </button>
      {areDetailsShown ? (
        <ActivityDetails
          transactionActivity={transactionActivity}
          visibleActivityIndex={visibleActivityIndex}
          setVisibleActivityIndex={setVisibleActivityIndex}
        />
      ) : null}
    </li>
  );
};

export default ActivityItemBigScreen;

import React, { FC } from 'react';

interface INoteProps {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const Note: FC<INoteProps> = ({ className = '', style, children }) => {
  return (
    <div
      style={style}
      className={`border border-solid border-primary-yellow1 bg-primary-yellow1Fade px-4 py-1 text-base leading-8 ${className}`}
    >
      {children}
    </div>
  );
};

export default Note;

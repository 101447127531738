import { useMultiStepPaymentStore } from 'src/store/multiStepPayment/multiStepPayment.store';
import { useSortPaymentMethods } from 'src/hooks/domain/payments/useSortPaymentMethods';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import NewPaymentMethodTranpondersWrapper from 'src/components/Payments/NewPaymentMethodWrappers/NewPaymentMethodTranspondersWrapper';
import { useContactSettingsStore } from 'src/store/account/contactSettings.store';
import { getPaymentMethodName } from 'src/utils/formatter';
import ChosePaymentMethod from 'src/components/Payments/ChosePaymentMethod/ChosePaymentMethod';
import { ADD_NEW_PAYMENT_OPTION, ONE_TIME_PURCHASE } from 'src/constants';
import { Trans } from '@lingui/macro';
import { useOneTimePaymentStore } from 'src/store/payment/oneTimePayment.store';

export default function ChosePaymentMethodStep() {
  const { updateCloseAccountInfo } = useContactSettingsStore();
  const {
    paymentInfo: { isNewPayment, paymentMethodId },
    updateSingleMultiPaymentStoreValues,
    nextStep,
  } = useMultiStepPaymentStore();
  const { changeIsOneTimePayment, isOneTimePayment } = useOneTimePaymentStore();
  const { sortedPaymentMethods } = useSortPaymentMethods();
  const shouldButtonBeHidden = !paymentMethodId || isNewPayment;

  const getDefaultSelectValue = () => {
    if (isNewPayment) {
      if (isOneTimePayment) {
        return ONE_TIME_PURCHASE;
      } else {
        return ADD_NEW_PAYMENT_OPTION;
      }
    } else {
      const card = sortedPaymentMethods?.find((card) => {
        return card.paymentMethodId === paymentMethodId;
      });
      return card && getPaymentMethodName(card);
    }
  };
  const handleChange = (value: string) => {
    changeIsOneTimePayment(false);
    if (value === ADD_NEW_PAYMENT_OPTION || value === ONE_TIME_PURCHASE) {
      if (value === ONE_TIME_PURCHASE) changeIsOneTimePayment(true);
      updateSingleMultiPaymentStoreValues('isNewPayment', true);
      updateCloseAccountInfo({ existingCard: null, isNewCardChosen: true });
    } else {
      const card = sortedPaymentMethods?.find((card) => {
        return card.paymentMethodId === value;
      });
      updateSingleMultiPaymentStoreValues('isNewPayment', false);
      if (card) {
        updateCloseAccountInfo({ existingCard: card, isNewCardChosen: false });
        updateSingleMultiPaymentStoreValues('paymentMethodId', value);
      }
    }
  };

  return (
    <div>
      <div className="mx-auto vsm:max-w-sm">
        <ChosePaymentMethod
          handleChange={handleChange}
          defaultValue={getDefaultSelectValue()}
          sortedPaymentMethods={sortedPaymentMethods}
        />
      </div>
      {(isNewPayment || isOneTimePayment) && <NewPaymentMethodTranpondersWrapper isOneTimePayment={isOneTimePayment} />}
      {shouldButtonBeHidden ? null : (
        <div className="flex">
          <CustomButton
            onClick={nextStep}
            className="ml-auto mr-10 mt-6"
            content={<Trans>Continue</Trans>}
            type={'submit'}
          />
        </div>
      )}
    </div>
  );
}

export enum MailingAddressFormField {
  ADDRESS_LINE_1 = 'addressLine1',
  ADDRESS_LINE_2 = 'addressLine2',
  COUNTRY = 'country',
  CITY = 'city',
  STATE = 'state',
  POSTAL_CODE = 'postalCode',
  COUNTY = 'county',
}

export enum BillingAddressFormField {
  ADDRESS_LINE_1 = 'billingAddressLine1',
  ADDRESS_LINE_2 = 'billingAddressLine2',
  COUNTRY = 'billingCountry',
  CITY = 'billingCity',
  STATE = 'billingState',
  POSTAL_CODE = 'billingPostalCode',
}

export default MailingAddressFormField;

import { t } from '@lingui/macro';

export const PRIVATE_ACCOUNT_TRANPONDER_LIMIT = 100;
export const FLLET_ACCOUNT_MAX_TRANPONDER_LIMIT = 20000;
export const FLLET_ACCOUNT_MIN_TRANPONDER_LIMIT = 101;

export const NO_TRANSPONDERS_TITLE_NOTE = t`Note: No Transponders`;
export const REACHED_PRIVATE_ACCOUNT_TRANSPONDER_LIMIT = t`You've reached the maximum number of transponders (100) for this account type`;
export const NO_TRANSPONDERS_ERROR = t`You must purchase at least one transponder to proceed`;
export const INVALID_TRANSPONDERS_ERROR = t`Invalid Transponder Number`;
export const REGISTERED_TRANSPONDERS_ERROR = t`The Transponder Number is already registered`;
export const MIN_TRANPONDERS_AMOUNT_FLEET_LIMIT = t`You must add at least (101) or more transponders for fleet accounts`;
export const MAX_TRANPONDERS_AMOUNT_FLEET_LIMIT = t`You've reached the maximum number of transponders (20000) for this account type`;

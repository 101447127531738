import { gql } from '@apollo/client';

export const VEHICLE_MAKES = gql`
  query vehicleMakes {
    vehicleMakes {
      name
    }
  }
`;

export const VEHICLE_MAKE_MODELS = gql`
  query vehicleModelsByVehicleMakeName($vehicleMakeName: String!) {
    vehicleModelsByVehicleMakeName(vehicleMakeName: $vehicleMakeName) {
      name
    }
  }
`;

export interface IVehicleMake {
  name: string;
  vehicleModels: [IVehicleModel];
}

export interface IVehicleModel {
  name: string;
  vehicleMake: IVehicleMake;
}

import { i18n } from '@lingui/core';

export const EMAIL_ERROR_MSG = i18n._('Please enter a valid email address');
export const MATCHING_EMAIL_ERROR_MSG = i18n._('Email address does not match original entry');
export const PASSWORD_ERROR_MSG = i18n._('Please enter a valid password');
export const MATCH_PASSWORD_ERROR_MSG = i18n._('Passwords must match');
export const USERNAME_ERROR_MSG = i18n._('Please enter a valid username');
export const FEIN_LENGTH_ERROR_MSG = i18n._('Please enter a 9 digit number');
export const FEIN_NUMBER_ERROR_MSG = i18n._('Please enter a valid FEIN Number');
export const PHONE_NUMBER_ERROR_MSG = i18n._(
  'You must enter a valid mobile phone number in order to opt into text alerts',
);
export const AT_LEAST_ONE_PHONE_NUMBER_ERROR_MSG = i18n._('Please enter at least one valid phone number');
export const EMAIL_REQUIRED_ERROR_MSG = i18n._('Email address is required');
export const INVALID_EMAIL_ERROR_MSG = i18n._('Invalid email');
export const FIRST_NAME_REQUIRED_ERROR_MSG = i18n._('First name is required');
export const LAST_NAME_REQUIRED_ERROR_MSG = i18n._('Last name is required');
export const COMPANY_NAME_REQUIRED_ERROR_MSG = i18n._('Company name is required');
export const ORGANIZATION_NAME_REQUIRED_ERROR_MSG = i18n._('Organization name is required');
export const DOING_BUSINESS_AS_REQUIRED_ERROR_MSG = i18n._('Doing business as is required');
export const PURCHASE_ORDER_NUMBER_REQUIRED_ERROR_MSG = i18n._('Purchase order number is required');
export const FIRST_QUESTION_ERROR_MSG = i18n._('Please select your first security question');
export const SECOND_QUESTION_ERROR_MSG = i18n._('Please select a second security question');
export const THIRD_QUESTION_ERROR_MSG = i18n._('Please select a third security question');
export const SECURITY_ANSWER_ERROR_MSG = i18n._('Security answer required');
export const CONFIRM_PASSWORD_ERROR_MSG = i18n._('Please confirm password');
export const EMAIL_ALPHANUMERIC_OR_SPECIAL_CHAR_ERROR_MSG = i18n._(
  'Please enter alphanumeric characters or the following special characters: @ .',
);
export const PHONE_MIN_ERROR_MSG = i18n._('10 Character Minimum');
export const PHONE_MAX_ERROR_MSG = i18n._('17 Character Maximum');
export const PHONE_ERROR_MSG = i18n._('Must be a valid phone number');
export const ADDRESS_MIN_ERROR_MSG = i18n._('3 Character Minimum');
export const ADDRESS2_MIN_ERROR_MSG = i18n._('1 Character Minimum');
export const CITY_MIN_ERROR_MSG = i18n._('3 Character Minimum');
export const POSTAL_CODE_ALPHANUMERIC_OR_SPECIAL_CHAR_ERROR_MSG = i18n._(
  'Please enter alphanumeric characters or the following special character: -',
);
export const POSTAL_CODE_MIN_ERROR_MSG = i18n._('5 Character Minimum');
export const POSTAL_CODE_REQUIRED_ERROR_MSG = i18n._('Postal code is required');
export const PASSWORD_MIN_NUMBERS_ERROR_MSG = i18n._('Password must contain at least one number');
export const PASSWORD_MIN_UPPERCASE_ERROR_MSG = i18n._('Password must contain at least one uppercase letter');
export const PASSWORD_MIN_LOWERCASE_ERROR_MSG = i18n._('Password must contain at least one lowercase letter');
export const PASSWORD_MIN_SYMBOLS_ERROR_MSG = i18n._('Password must contain at least special character');
export const NAME_ALPHABETICAL_OR_SPECIAL_CHAR_ERROR_MSG = i18n._(
  "Please enter alphabetical characters or the following special characters: - ' .",
);
export const NAME_AND_RESPONSE_MAX_ERROR_MSG = i18n._('40 Character Maximum');
export const SECURITY_CODE_REQUIRED = i18n._('Security code is required');
export const SECURITY_CODE_NUMBERS_ONLY = i18n._('Security code must contain only numbers');
export const SECURITY_CODE_MINIMUM = i18n._('3 Character Minimum');
export const ACCOUNT_NUMBER_OR_USERNAME_REQUIRED = i18n._('Account number or username is required');
export const ACCOUNT_NUMBER_OR_TRANSPONDER_NUMBER_REQUIRED = i18n._('Account number or transponder number is required');
export const RESPONSE_REQUIRED = i18n._('Response is a required field');
export const ADDRESS_LINE_1_REQUIRED = i18n._('Address Line 1 is required');
export const CITY_REQUIRED = i18n._('City is required');
export const COUNTRY_REQUIRED = i18n._('Country is required');
export const COUNTY_REQUIRED = i18n._('County is required');
export const STATE_PROVINCE_REQUIRED = i18n._('State/Province is required');
export const CLOSE_ACCOUNT_REQUIRED_ERROR_MSG = i18n._('Please select a reason for closing your account');
export const REFERENCE_NUMBER_REQUIRED = i18n._('Please provide reference number');

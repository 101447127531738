import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { t } from '@lingui/macro';
import Header from './Header';
import Contact from './ContactInformation/Contact';
import './MyAccount.scss';
import Notifications from './Notifications/Notifications';
import LinkedAccounts from './LinkedAccounts/LinkedAccounts';
import SunpassParking from './SunpassPlusParking/SunpassParking';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import PrivacyAndSecurity from './PrivacyAndSecuritySettings/PrivacyAndSecurity';
import useScreenSize from 'src/hooks/screen/useScreenSize';
import ScreenSize from 'src/types/ScreenSize.enum';
import TabContent from './TabContent';
import { colors } from 'src/theme/theme';
import { MyAccountParams } from '../../types/routes';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import { useAccountSettings } from 'src/store/account/accountSettings.store';
import LeaveSiteConfirmationModal from '../Modals/ConfirmationModal/LeaveSiteConfirmationModal';
import { useCallbackPrompt } from 'src/hooks/location/useCallbackPrompt';
import { useDashboardStore } from '../../store/dashboard/dashboard.store';
import { useScroll } from '../../hooks/screen/useScroll';
import { checkIfElementExists } from '../Dashboard/checkIfElementExists';
import { PARKING_SECTION_ID } from '../Dashboard/references';
import PinellasBayway from './PinellasBayway/PinellasBayway';
import { useQuery } from '@apollo/client';
import { GET_ACCOUNT_DETAILS, IAccountDetails } from '../../graphql/accountDetails.graphql';
import { AccountType } from '../../types/onboarding.types';
import { usePlans } from '../Dashboard/MyDashboard/usePlans';
import { usePaginationInfo } from '../../store/pagination/pagination.store';

export default function MyAccount(): React.ReactElement {
  const { TabPane } = Tabs;

  const [showRedirectPrompt, setShowRedirectPrompt] = useState(false);

  const { screenSize } = useScreenSize();

  const { data: accountData } = useQuery<{ accountDetails: IAccountDetails }>(GET_ACCOUNT_DETAILS);
  const accountType = accountData?.accountDetails.accountType;

  const { PAYGAccount } = usePlans();
  const isPAYGAccount = accountType === AccountType.PRIVATE && PAYGAccount;

  useEffect(() => {
    return () => {
      setActiveTab(MyAccountParams.CONTACT_INFO);
    };
  }, []);

  const { changeTranspondersPageNumber: changeTranspondersPage } = usePaginationInfo();

  const {
    activeTab,
    setActiveTab,
    isTabDirty,
    setShowConfirmationModal,
    isConfiramtionModalShown,
    setTabToChangeTo,
    tabToChangeTo,
    setIsTabDirty,
  } = useAccountSettings();

  const { cancelRedirect, confirmRedirect, showPrompt } = useCallbackPrompt(showRedirectPrompt);
  const {
    setParkingMode,
    store: { parkingMode },
  } = useDashboardStore();

  const { scrollToElem } = useScroll();

  const parkingSectionExists = checkIfElementExists(PARKING_SECTION_ID);

  useEffect(() => {
    if (parkingMode && parkingSectionExists) {
      scrollToElem(PARKING_SECTION_ID);
      setParkingMode(false);
    } else if (parkingMode) {
      setActiveTab(MyAccountParams.PARKING);
    }
  }, [parkingMode, parkingSectionExists]);

  useEffect(() => {
    if (screenSize >= ScreenSize.XL && activeTab === '0') {
      setActiveTab(MyAccountParams.CONTACT_INFO);
    }
  }, [screenSize]);

  useEffect(() => {
    setShowRedirectPrompt(isTabDirty);
  }, [isTabDirty]);

  useEffect(() => {
    if (activeTab === '0') setIsTabDirty(false);
  }, [activeTab]);

  const confirmRedirectUser = () => {
    setActiveTab(tabToChangeTo);
    setShowConfirmationModal(false);
  };

  const onCancel = () => {
    setShowConfirmationModal(false);
  };
  const onTabClick = (activeKey: string) => {
    changeTranspondersPage(1);
    if (isTabDirty) {
      setShowConfirmationModal(true);
      setTabToChangeTo(activeKey);
    } else {
      setActiveTab(activeKey);
    }
  };

  return (
    <>
      <ScrollToTop />
      <Header />
      <div className="my-account-home flex justify-center">
        <div className="mt-10 w-full lg:w-9/12">
          <div className="w-full bg-black">
            <CustomButton
              content="Need Help?"
              id={screenSize < ScreenSize.XL ? 'need-help-mob' : 'need-help'}
              className={activeTab !== '0' && screenSize < ScreenSize.XL ? 'need-help-mob' : ''}
            />
          </div>

          <div className="my-account">
            <Tabs
              destroyInactiveTabPane
              size="large"
              tabPosition="left"
              activeKey={activeTab}
              defaultActiveKey={MyAccountParams.CONTACT_INFO}
              tabBarStyle={{
                backgroundColor: colors.primary.white,
                color: colors.primary.black,
                height:
                  !isPAYGAccount && accountData?.accountDetails.accountType !== AccountType.COMMERCIAL
                    ? '350px'
                    : '232px',
                width: screenSize < ScreenSize.XL ? '100%' : '300px',
                display: activeTab !== '0' && screenSize < ScreenSize.XL ? 'none' : 'block',
                borderRadius: '7px',
                borderRightColor: 'transparent',
                boxShadow: `0 0 20px ${colors.primary.gray4}`,
                outline: 'none',
              }}
              onTabClick={(activeKey: string) => {
                onTabClick(activeKey);
              }}
            >
              <TabPane
                className="tab-content"
                tab={<TabContent label={t`Contact Information`} />}
                key={MyAccountParams.CONTACT_INFO}
              >
                <Contact />
              </TabPane>

              <TabPane
                className="tab-content"
                tab={<TabContent label={t`Privacy and Security`} />}
                key={MyAccountParams.PRIVACY}
              >
                <PrivacyAndSecurity />
              </TabPane>

              <TabPane
                className="tab-content"
                tab={<TabContent label={t`Notifications`} />}
                key={MyAccountParams.NOTIFICATIONS}
              >
                <Notifications />
              </TabPane>

              <TabPane
                className="tab-content"
                tab={<TabContent label={t`Linked Accounts`} />}
                key={MyAccountParams.LINKED}
              >
                <LinkedAccounts />
              </TabPane>

              {!isPAYGAccount && accountData?.accountDetails.accountType !== AccountType.COMMERCIAL ? (
                <TabPane
                  className="tab-content"
                  tab={<TabContent label={t`SunPass Plus Parking`} />}
                  key={MyAccountParams.PARKING}
                >
                  <SunpassParking />
                </TabPane>
              ) : null}

              {accountData?.accountDetails.accountType !== AccountType.COMMERCIAL && !isPAYGAccount ? (
                <TabPane
                  className="tab-content"
                  tab={<TabContent label={t`Pinellas Bayway Plan`} styleName="no-border" />}
                  key={MyAccountParams.PINELLAS}
                >
                  <PinellasBayway />
                </TabPane>
              ) : null}
            </Tabs>
          </div>
          <LeaveSiteConfirmationModal
            showModal={showPrompt ? showPrompt : isConfiramtionModalShown}
            onOk={showPrompt ? confirmRedirect : confirmRedirectUser}
            onCancel={showPrompt ? cancelRedirect : onCancel}
          />
        </div>
      </div>
    </>
  );
}

import { useMutation } from '@apollo/client';
import { Trans, t } from '@lingui/macro';
import { Form } from 'antd';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import StyledInput from 'src/components/StyledInput/StyledInput';
import { ILoginInput, ILoginOutput, LOGIN_USER } from 'src/graphql/loginUser.graphql';
import { useAuth } from 'src/store/authentication/authentication.store';
import { MainRoutes } from 'src/types/routes';
import { NotificationContext } from '../../context/NotificationContext';
interface IUseLogin {
  loginForm: ReactElement;
  loading: boolean;
  resetLoginState(): void;
  login: (username: string, password: string) => Promise<void>;
}

export const useLogin = (
  link?: ReactElement,
  allowAccountNumber?: boolean,
  foundUsername = '',
  reverseButtonsOrder = false,
  allignButtonsRight = false,
): IUseLogin => {
  const { control, watch, handleSubmit, setValue } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      username: foundUsername ? foundUsername : '',
      password: '',
    },
  });
  const [isFormSubmitted, setFormSubmitted] = useState(false);

  const {
    authenticateLogin,
    authenticationFailure,
    details: { authError },
    resetAuthError,
  } = useAuth();

  useEffect(() => {
    setValue('username', foundUsername || '');
  }, [foundUsername]);

  const routeHistory = useNavigate();
  const username = watch('username');
  const password = watch('password');

  const [loginUser, { loading }] = useMutation<{ loginUser: ILoginOutput }, { loginInput: ILoginInput }>(LOGIN_USER);
  const notificationCtx = useContext(NotificationContext);
  const onSubmit = async () => {
    if (username !== '' && password !== '') {
      resetAuthError();
      setFormSubmitted(true);
      try {
        await login(username, password);
      } catch (err) {
        console.log(err);
        authenticationFailure();
      }
    } else {
      setFormSubmitted(true);
      authenticationFailure();
    }
  };

  const resetLoginState = () => {
    resetAuthError();
    setFormSubmitted(false);
  };

  const login = async (username: string, password: string): Promise<void> => {
    try {
      const response = await loginUser({ variables: { loginInput: { username: username, password: password } } });
      if (response.data?.loginUser.jwt) {
        authenticateLogin(response.data.loginUser.jwt);
        notificationCtx?.loadNotifications();
        routeHistory(MainRoutes.DASHBOARD);
      }
    } catch (err) {
      console.log(err);
      authenticationFailure();
    }
  };

  const loginForm = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="w-full sm:pr-1">
        <Form.Item
          label={allowAccountNumber ? <Trans>Username or Account Number</Trans> : <Trans>Username</Trans>}
          className="signIn-form-label"
        >
          <Controller
            name="username"
            control={control}
            render={({ field, fieldState }) => (
              <StyledInput
                field={field}
                fieldstate={fieldState}
                id="username"
                key="username"
                aria-label={allowAccountNumber ? t`Username` : t`Username or Account Number`}
              />
            )}
          />
        </Form.Item>
      </div>
      <div className="w-full sm:pr-1">
        <Form.Item label={<Trans>Password</Trans>} className="signIn-form-label">
          <Controller
            name="password"
            control={control}
            render={({ field, fieldState }) => (
              <StyledInput
                field={field}
                fieldstate={fieldState}
                id="password"
                key="password"
                type={'password'}
                aria-label={t`Password`}
              />
            )}
          />
        </Form.Item>
      </div>
      {isFormSubmitted && authError && !loading ? (
        <div className="w-full sm:w-10/12">
          <span className="text-red text-sm">
            <Trans>
              We do not recognize that username or account number/password combo. If you have forgotten your username or
              password, follow the link below to retrieve or reset your username or password
            </Trans>
          </span>
        </div>
      ) : null}
      <div className={`${reverseButtonsOrder ? 'flex-col-reverse' : 'flex-col'} mt-4 flex`}>
        <CustomButton
          content="Sign In"
          type="submit"
          className={`button-sign-in ${allignButtonsRight ? 'sm:ml-auto sm:mr-0' : 'sm:ml-0 sm:mr-auto'}`}
        />
        <div className={`mx-auto ${allignButtonsRight ? 'sm:ml-auto sm:mr-0' : 'sm:ml-0 sm:mr-auto'}`}>{link}</div>
      </div>
    </form>
  );

  return { loginForm, loading, resetLoginState, login };
};

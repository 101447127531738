import { gql } from '@apollo/client';

export const REPLENISHMENT_AMOUNT = gql`
  query replenishmentAmount {
    replenishmentAmount {
      personal
      fleet
    }
  }
`;

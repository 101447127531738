import { create } from '../createStore';
import { devtools, persist } from 'zustand/middleware';
import {
  ACTIVITY_PAGINATION_STORE_INITIAL_STATE,
  ACTIVITY_SEARCH_PARAMS_INITIAL_STATE,
  TRANSPONDERS_PAGINATION_STORE_INITIAL_STATE,
  VEHICLES_PAGINATION_STORE_INITIAL_STATE,
  INVOICE_TRANSACTIONS_PAGINATION_INITIAL_STATE,
  IActivitySearchParams,
  IPaginationInfo,
} from './paginationDefaultValues';

export interface IPaginationStore {
  vehiclesPagination: IPaginationInfo;
  transpondersPagination: IPaginationInfo;
  activitiesPagination: IPaginationInfo;
  invoiceTransactionsPagination: IPaginationInfo;
  promotionPagination: IPaginationInfo;
  activitiesParams: IActivitySearchParams;
  areVehiclesFiltersApplied: boolean;
  areActivitiesFiltersApplied: boolean;
  setAreVehiclesFiltersApplied: (areVehiclesFiltersApplied: boolean) => void;
  setAreActivitiesFiltersApplied: (areActivitiesFiltersApplied: boolean) => void;
  changeVehiclesPageNumber: (page: number) => void;
  changeVehiclesPageSize: (size: number) => void;
  changeTranspondersPageNumber: (page: number) => void;
  changeTranspondersPageSize: (size: number) => void;
  changeActivitiesPageNumber: (page: number) => void;
  changeActivitiesPageSize: (size: number) => void;
  changeInvoiceTransactionsPageNumber: (page: number) => void;
  changeInvoiceTransactionsPageSize: (size: number) => void;
  changeActivitiesSearchParams: (data: Partial<IActivitySearchParams>) => void;
  resetPagination: () => void;
}

export const usePaginationInfo = create<
  IPaginationStore,
  [['zustand/devtools', IPaginationStore], ['zustand/persist', IPaginationStore]]
>(
  devtools(
    persist((set) => ({
      vehiclesPagination: VEHICLES_PAGINATION_STORE_INITIAL_STATE,
      transpondersPagination: TRANSPONDERS_PAGINATION_STORE_INITIAL_STATE,
      activitiesPagination: ACTIVITY_PAGINATION_STORE_INITIAL_STATE,
      invoiceTransactionsPagination: INVOICE_TRANSACTIONS_PAGINATION_INITIAL_STATE,
      promotionPagination: INVOICE_TRANSACTIONS_PAGINATION_INITIAL_STATE,
      activitiesParams: ACTIVITY_SEARCH_PARAMS_INITIAL_STATE,
      areVehiclesFiltersApplied: false,
      areActivitiesFiltersApplied: false,
      setAreVehiclesFiltersApplied: (areVehiclesFiltersApplied: boolean) => {
        set((state) => ({
          ...state,
          areVehiclesFiltersApplied,
        }));
      },
      setAreActivitiesFiltersApplied: (areActivitiesFiltersApplied: boolean) => {
        set((state) => ({
          ...state,
          areActivitiesFiltersApplied,
        }));
      },
      changeVehiclesPageNumber: (page: number) => {
        set(({ vehiclesPagination }) => ({
          vehiclesPagination: {
            ...vehiclesPagination,
            page,
          },
        }));
      },
      changeVehiclesPageSize: (size: number) => {
        set(({ vehiclesPagination }) => ({
          vehiclesPagination: {
            ...vehiclesPagination,
            size,
          },
        }));
      },
      changeTranspondersPageNumber: (page: number) => {
        set(({ transpondersPagination }) => ({
          transpondersPagination: {
            ...transpondersPagination,
            page,
          },
        }));
      },
      changeTranspondersPageSize: (size: number) => {
        set(({ transpondersPagination }) => ({
          transpondersPagination: {
            ...transpondersPagination,
            size,
          },
        }));
      },
      changeActivitiesPageNumber: (page: number) => {
        set(({ activitiesPagination }) => ({
          activitiesPagination: {
            ...activitiesPagination,
            page,
          },
        }));
      },
      changeActivitiesPageSize: (size: number) => {
        set(({ activitiesPagination }) => ({
          activitiesPagination: {
            ...activitiesPagination,
            size,
          },
        }));
      },
      changeInvoiceTransactionsPageNumber: (page: number) => {
        set(({ invoiceTransactionsPagination }) => ({
          invoiceTransactionsPagination: {
            ...invoiceTransactionsPagination,
            page,
          },
        }));
      },
      changeInvoiceTransactionsPageSize: (size: number) => {
        set(({ invoiceTransactionsPagination }) => ({
          invoiceTransactionsPagination: {
            ...invoiceTransactionsPagination,
            size,
          },
        }));
      },
      changePromotionPageNumber: (page: number) => {
        set(({ promotionPagination }) => ({
          promotionPagination: {
            ...promotionPagination,
            page,
          },
        }));
      },
      changePromotionPageSize: (size: number) => {
        set(({ promotionPagination }) => ({
          promotionPagination: {
            ...promotionPagination,
            size,
          },
        }));
      },
      changeActivitiesSearchParams: (data: Partial<IActivitySearchParams>) => {
        set((state) => ({
          activitiesParams: {
            ...state.activitiesParams,
            ...data,
          },
        }));
      },
      resetPagination: () => {
        set(({ activitiesPagination, transpondersPagination, vehiclesPagination, invoiceTransactionsPagination }) => ({
          activitiesPagination: {
            ...activitiesPagination,
            page: 1,
          },
          transpondersPagination: {
            ...transpondersPagination,
            page: 1,
          },
          vehiclesPagination: {
            ...vehiclesPagination,
            page: 1,
          },
          invoiceTransactionsPagination: {
            ...invoiceTransactionsPagination,
            page: 1,
          },
        }));
      },
    })),
  ),
);

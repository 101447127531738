import React, { FC } from 'react';
import { t, Trans } from '@lingui/macro';
import ConfirmationModal from 'src/components/Modals/ConfirmationModal/ConfirmationModal';

interface IMakePaymenthMethodPrimaryModal {
  showModal: boolean;
  onOk: () => void;
  onCancel: () => void;
}

const MakePaymenthMethodPrimaryModal: FC<IMakePaymenthMethodPrimaryModal> = ({ showModal, onOk, onCancel }) => {
  const cancelButtonText = t`Go Back`;
  const okButtonText = t`Confirm`;
  const title = (
    <>
      <h1 className="text-xl font-extrabold leading-6 xsm:leading-5">
        <Trans>Confirm Easy Pay Enrollment on this Payment Method</Trans>
      </h1>
    </>
  );

  const content =
    'In order to designate a payment method as primary, that payment method must be enrolled in Easy Pay. Please confirm that you’d like to enroll this payment method in Easy Pay and designate it as Primary.';

  return (
    <>
      {showModal && (
        <ConfirmationModal
          onOk={onOk}
          onCancel={onCancel}
          showModal={showModal}
          cancelButtonText={cancelButtonText}
          okButtonText={okButtonText}
          title={title}
          content={content}
          closable={true}
          alignButtons="center"
          alignText="center"
        />
      )}
    </>
  );
};
export default MakePaymenthMethodPrimaryModal;

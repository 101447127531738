import { useAddressValidation } from '../../../useAddressValidation';
import {
  AccountSubtype,
  AccountType,
  getStepsByUserTypeAndFlowType,
  IAccountInfo,
  OnboardingFlowType,
  OnboardingStepsGovernment,
  OnboardingStepsPayg,
} from '../../../../types/onboarding.types';
import React, { useEffect, useMemo, useState } from 'react';
import { getAccountInfoInitialFormStateFrom } from '../../../../components/Onboarding/Account/initialState';
import {
  ISignUpAccountDetailsInput,
  ISignUpAccountDetailsOutput,
  SIGN_UP_ACCOUNT_DETAILS,
} from '../../../../graphql/signUp/signUpDetails.graphql';
import { OnboardingStepPath } from '../../../../types/routes';
import { useMutation } from '@apollo/client';
import { useRouteMonitor } from '../../../location/useRouteMonitor';
import { useOnboardingStore } from '../../../../store/onboarding/onboarding.store';
import { useRecaptcha } from '../../../recaptcha/useRecaptcha';
import MailingAddressStandardizationModal from '../../../../components/Modals/ConfirmationModal/MailingAddressStandardizationModal';
import {
  mapAccountInfoToDetailsSignUpInput,
  prepareAddressStandardizationInputFromAccountInfo,
  updateAccountInfoAddressAfterStandardization,
} from './accountInfo.utils';
import useErrorHandling from '../../../errors/useErrorHandling';
import { SELECT_RECOMMENDED_SUNPASS_PLAN_NAME } from '../../../../store/onboarding/onboarding.selectors';
import {
  ISignUpTransponderActivationOutput,
  ISignUpTransponderActivationInput,
  SIGN_UP_TRANSPONDER_ACTIVATION,
} from 'src/graphql/signUp/signUpTransponderActivation.graphql';
import { mapAccountInfoToPatrialyActivateTranponders } from './mappers';
import { useOnboardingErrors } from 'src/store/errors/onboardingErrors.store';

export const useAccountInfo = () => {
  const { setOnboardingState, canProceedToNextStep } = useOnboardingErrors();

  const {
    onboardingInfo: { accountType, accountId, userType, onboardingFlowType, accountSubtype },
    updateOnboardingStore,
  } = useOnboardingStore();

  let steps = userType && onboardingFlowType && getStepsByUserTypeAndFlowType(userType, onboardingFlowType);

  if (accountType === AccountType.PAYG) {
    steps = OnboardingStepsPayg;
  } else if (accountType === AccountType.GOVERNMENT) {
    steps = OnboardingStepsGovernment;
  }
  const [isAddressStandardizationModalOpen, setIsAddressStandardizationModalOpen] = useState(false);

  const [isAddressStandardized, setIsAddressStandardized] = useState(true);

  //TODO FLO-3096 - refactor in scope of ticket FLO-3096
  const [accountInput, setAccountInput] = useState<IAccountInfo>(getAccountInfoInitialFormStateFrom());
  const history = useRouteMonitor();
  const { getStandardizedAddress, standardizedAddressData, standardizedAddressLoading } = useAddressValidation();

  const [accountDetailsSignup, { loading: loadingAccountSignUp }] = useMutation<
    { signUpAccountDetails: ISignUpAccountDetailsOutput },
    { signUpAccountDetailsInput: ISignUpAccountDetailsInput }
  >(SIGN_UP_ACCOUNT_DETAILS);

  const [signUpTransponderActivation, { loading: activationTranspondersLoading }] = useMutation<
    { signUpTransponderActivation: ISignUpTransponderActivationOutput },
    { signUpTransponderActivationInput: ISignUpTransponderActivationInput }
  >(SIGN_UP_TRANSPONDER_ACTIVATION);

  const { resetError } = useErrorHandling();

  useEffect(() => {
    if (canProceedToNextStep) {
      history.goToPath(OnboardingStepPath.TRANSPONDERS);
    }
  }, [canProceedToNextStep]);

  const onSubmit = async (account: IAccountInfo) => {
    resetError();
    setAccountInput(account);
    updateOnboardingStore({
      accountType: onboardingFlowType === OnboardingFlowType.PREPAID ? AccountType.PRIVATE : accountType,
      accountSubtype: onboardingFlowType === OnboardingFlowType.PREPAID ? AccountSubtype.NONE : accountSubtype,
      accountInfo: account as IAccountInfo,
      planName: SELECT_RECOMMENDED_SUNPASS_PLAN_NAME(accountType, accountSubtype),
      shouldDisplayAccountCreatedMsg: true,
    });
    try {
      const response = await getStandardizedAddress({
        variables: {
          addressValidationInput: prepareAddressStandardizationInputFromAccountInfo(account),
        },
      });
      !!response?.data?.addressValidation && setIsAddressStandardizationModalOpen(true);
    } catch (err) {
      console.log((err as Error).message);
    }
  };

  const performAccountDetailsSignUpWithPrivacyAndSecurity = async (
    account: IAccountInfo,
    accountInput: ISignUpAccountDetailsInput,
  ) => {
    try {
      const { data } = await accountDetailsSignup({
        variables: {
          signUpAccountDetailsInput: accountInput,
        },
      });
      updateOnboardingStore({
        accountType: onboardingFlowType === OnboardingFlowType.PREPAID ? AccountType.PRIVATE : accountType,
        accountSubtype: onboardingFlowType === OnboardingFlowType.PREPAID ? AccountSubtype.NONE : accountSubtype,
        accountInfo: account as IAccountInfo,
        referenceNumber: data?.signUpAccountDetails.referenceNumber,
        accountId: data?.signUpAccountDetails.accountId,
        planName: SELECT_RECOMMENDED_SUNPASS_PLAN_NAME(accountType, accountSubtype),
        shouldDisplayAccountCreatedMsg: true,
      });
      accountType === AccountType.PAYG
        ? history.goToPath(OnboardingStepPath.VEHICLES)
        : history.goToPath(OnboardingStepPath.TRANSPONDERS);
    } catch (err) {
      console.log(err);
    }
  };

  const confirmAddressStandardization = async (account: IAccountInfo) => {
    if (standardizedAddressData && isAddressStandardized) {
      account = updateAccountInfoAddressAfterStandardization(account, standardizedAddressData);
    }
    if (onboardingFlowType === OnboardingFlowType.PREPAID) {
      setOnboardingState(mapAccountInfoToPatrialyActivateTranponders(account), undefined, undefined);
      try {
        await signUpTransponderActivation({
          variables: {
            signUpTransponderActivationInput: {
              accountDetailsSignUp: mapAccountInfoToPatrialyActivateTranponders(account),
            },
          },
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        const accountInput = mapAccountInfoToDetailsSignUpInput(account, accountType, accountId);
        if (accountType !== AccountType.GOVERNMENT) {
          await performAccountDetailsSignUpWithPrivacyAndSecurity(account, accountInput);
        } else {
          updateOnboardingStore({
            accountInfo: account as IAccountInfo,
          });
          history.goToPath(OnboardingStepPath.REVIEW);
        }
      } catch (err) {
        console.log(err);
      }
    }
    setIsAddressStandardizationModalOpen(false);
  };

  const {
    submitWithRecaptcha,
    recaptchaChallengeElem,
    loading: recaptchaLoading,
  } = useRecaptcha<IAccountInfo>({
    onSubmit,
  });

  const addressStandardizationModal = useMemo(() => {
    return (
      <MailingAddressStandardizationModal
        showModal={isAddressStandardizationModalOpen}
        onOk={async () => {
          await confirmAddressStandardization(accountInput);
        }}
        onCancel={() => setIsAddressStandardizationModalOpen(false)}
        standardizedAddressData={standardizedAddressData}
        setIsAddressStandardized={setIsAddressStandardized}
      />
    );
  }, [isAddressStandardizationModalOpen]);

  return {
    onSubmit: submitWithRecaptcha,
    loading: loadingAccountSignUp || standardizedAddressLoading || recaptchaLoading || activationTranspondersLoading,
    steps,
    recaptchaChallengeElem,
    addressStandardizationModal,
  };
};

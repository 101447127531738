import React, { FC } from 'react';
import { t, Trans } from '@lingui/macro';
import ConfirmationModal from 'src/components/Modals/ConfirmationModal/ConfirmationModal';

interface ChangeTranpondersParkingModal {
  showModal: boolean;
  onOk: () => void;
  onBack: () => void;
}

const ChangeTranpondersParkingModal: FC<ChangeTranpondersParkingModal> = ({ showModal, onOk, onBack }) => {
  const okButtonText = t`Yes`;

  const content = (
    <div className="pt-10">
      <Trans>Are you sure you&apos;d like to remove SunPass Plus Parking from this transponder?</Trans>
    </div>
  );

  return (
    <>
      {showModal && (
        <ConfirmationModal
          onOk={onOk}
          showModal={showModal}
          okButtonText={okButtonText}
          content={content}
          closable={true}
          alignButtons="center"
          alignText="center"
          onCancel={onBack}
        />
      )}
    </>
  );
};
export default ChangeTranpondersParkingModal;

import { gql } from '@apollo/client';
import { TransponderStatus } from '../transponders.graphql';

export const GET_TRANSPONDER_PARKING_LIST = gql`
  query transponderPlusParkingList($transponderPlusParkingListInput: TransponderPlusParkingListInput!) {
    transponderPlusParkingList(transponderPlusParkingListInput: $transponderPlusParkingListInput) {
      transpondersPlusParking {
        index
        transponderNumber
        status
        friendlyName
        sunpassPlusParking
      }
      totalElements
      totalPages
      pageNumber
      pageSize
      numberOfElements
      first
      last
    }
  }
`;
export interface ITransponderWithParking {
  friendlyName: string;
  index: number;
  status: TransponderStatus;
  sunpassPlusParking: SunpassPlusParking;
  transponderNumber: string;
}
export interface ITransponderListResponse {
  first: boolean;
  last: boolean;
  numberOfElements: number;
  pageNumber: number;
  pageSize: number;
  totalElements: number;
  totalPages: number;
  transpondersPlusParking: [ITransponderWithParking];
}
export enum SunpassPlusParking {
  OPT_IN = 'OPT_IN',
  OPT_OUT = 'OPT_OUT',
}

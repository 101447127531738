import { Trans } from '@lingui/macro';
import { ActiveLinkedAccountItem } from './ActiveLinkedAccountItem';
import React from 'react';
import { useLinkedAccounts } from '../../../../hooks/useLinkedAccounts';
import { MainLoader } from '../../../Loader/MainLoader';
import { LinkedAccountSubSectionHeadline } from '../helpers/LinkedAccountSubSectionHeadline';
import { EmptyState } from '../helpers/EmptyState';

export const ActiveLinkedAccounts = () => {
  const { activeAccounts, loading } = useLinkedAccounts();
  return (
    <div className="mt-3">
      <MainLoader loading={loading} />
      <LinkedAccountSubSectionHeadline
        name={<Trans>Your account number and balance are visible to the following users:</Trans>}
      />
      {activeAccounts?.length ? (
        activeAccounts?.map((account, index) => <ActiveLinkedAccountItem key={index} activeAccount={account} />)
      ) : (
        <EmptyState />
      )}
    </div>
  );
};

import * as yup from 'yup';
import {
  ADD_FUNDS_MINIMUM_AMOUNT_REQUIRED_ERROR_MSG,
  AMOUNT_AND_PAYMENT_METHOD_REQUIRED_MSG,
} from '../../../components/Payments/validation/paymentErrorMessages';

export const addFundsSchema = () => {
  return yup.object().shape({
    paymentMethodId: yup.string().required(AMOUNT_AND_PAYMENT_METHOD_REQUIRED_MSG),
    actualAmount: yup.number().min(5, ADD_FUNDS_MINIMUM_AMOUNT_REQUIRED_ERROR_MSG),
  });
};

import { faCreditCard } from '@fortawesome/free-regular-svg-icons';
import { faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, List } from 'antd';
import React, { useState } from 'react';
import { colors } from '../../theme/theme';
import CollapsableContainer from '../CollapsableContainer/CollapsableContainer';
import IconLinkCard from '../LinkCards/IconLinkCard';
import ProgressSteps, { ProgressStep } from '../ProgressSteps/ProgressSteps';
import SelectableCard from '../SelectableCard/SelectableCard';
import StyledInput from '../StyledInput/StyledInput';
import StyledSelect from '../StyledSelect/StyledSelect';
import { ActionItemAndIconLinkCardId } from '../Dashboard/ActionItemBanner/ActionItemAndIconLinkCardId';

export function Styleguide(): React.ReactElement {
  const [selected, setSelected] = useState(1);
  const onSelect = (val: number) => {
    setSelected(val);
  };

  const ExampleSteps: ProgressStep[] = [
    { title: 'Step 1: ', description: 'First Step' },
    { title: 'Step Title: ', description: 'Step Description (active)' },
    { title: 'Step 3: ', description: 'Third Step' },
    { title: 'Step 4: ', description: 'Fourth Step' },
    { title: 'Step 5: ', description: 'Fifth Step' },
    { title: 'Step 6: ', description: 'Sixth Step' },
  ];

  const data = [
    {
      title: 'Primary Color',
      hex: '#295083',
    },
    {
      title: 'Warning Color',
      hex: '#B8550A',
    },
    {
      title: 'Error Color',
      hex: '#C12525',
    },
    {
      title: 'Text Color',
      hex: '#333333',
    },
    {
      title: 'Text Secondary Color',
      hex: '#777777',
    },
    {
      title: 'Border Color Base',
      hex: '#919191',
    },
    {
      title: 'Processing Color',
      hex: '#008549',
    },
    {
      title: 'Disabled Background',
      hex: '#C9C9C9',
    },
    {
      title: 'White',
      hex: colors.primary.white,
    },
    {
      title: 'Black',
      hex: colors.primary.black,
    },
    {
      title: 'Gray 1',
      hex: colors.primary.gray1,
    },
    {
      title: 'Gray 2',
      hex: colors.primary.gray2,
    },
    {
      title: 'Gray 4',
      hex: colors.primary.gray4,
    },
    {
      title: 'Blue 1',
      hex: colors.primary.blue1,
    },
    {
      title: 'Blue 3',
      hex: colors.primary.blue3,
    },
    {
      title: 'Primary Green',
      hex: '#008549',
    },
    {
      title: 'Green 3',
      hex: colors.primary.green3,
    },
    {
      title: 'Yellow 1',
      hex: colors.primary.yellow1,
    },
  ];

  return (
    <div className="mx-14 mt-8">
      <a href="#" className="fixed bottom-0 right-3">
        <FontAwesomeIcon icon={faAngleDoubleUp} size="4x" />
      </a>
      <h1 className="mt-4 text-center text-primary-blue3">Styleguide</h1>
      <section className="mb-12">
        <h3>General Info</h3>
        <ul className="list-inside list-disc">
          <li>
            We are using tailwind utility classes for basic styling. Feel free to combine tailwind classes into named
            classes using <span className="font-mono">@apply</span>
          </li>
          <li>
            Tailwind theme variables (i.e. colors) are configured in{' '}
            <span className="font-mono">src/theme/theme.js</span>
          </li>
          <li>
            We are using Ant Design as a component library. Ant Design variable overrides can be found in{' '}
            <span className="font-mono">src/theme/theme.less</span>
          </li>
          <li>
            Other common styles are found in <span className="font-mono">src/theme/</span>scss files
          </li>
        </ul>
      </section>
      <section className="mb-8">
        <h3>Quick Links</h3>
        <a className="block" href="#buttons">
          Buttons
        </a>
        <a className="block" href="#form-elements">
          Form Elements
        </a>
        <a className="block" href="#progress-stepper">
          Progress Stepper
        </a>
        <a className="block" href="#collapsible-container">
          Collapsible Container
        </a>
        <a className="block" href="#selectable-card">
          Selectable Card
        </a>
      </section>
      <section>
        <h3>Other Invision Style guides</h3>
        <a
          className="block"
          href="https://portlandwebworks.invisionapp.com/console/FTE-SunPass---UI-Concepts-Desktop-ckjt25rhz001m013pa4kn7eu9/ckquzx3j6029x01yi9pwgf3a1/play#project_console"
        >
          Typography
        </a>
        <a
          className="block"
          href="https://portlandwebworks.invisionapp.com/console/FTE-SunPass---UI-Concepts-Desktop-ckjt25rhz001m013pa4kn7eu9/ckql1tcim02en01187m6i407l/play#project_console"
        >
          Color Paletter
        </a>
      </section>
      <section>
        <h3>Color Palette</h3>
        <List
          grid={{ gutter: 16, column: 4 }}
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <Card title={item.title + ' ' + item.hex}>
                <div style={{ backgroundColor: item.hex, height: 100 }} />
              </Card>
            </List.Item>
          )}
        />
      </section>
      <section className="space-y-6">
        <h3>Components</h3>
        <section className="rounded-md border-2 border-primary-gray4 p-8">
          <h5 className="text-primary-green1" id="buttons">
            Buttons
          </h5>
          <div className="italic">**Note: Active states not yet styled.</div>
          <a
            className="underline"
            href="https://portlandwebworks.invisionapp.com/console/FTE-SunPass---UI-Concepts-Desktop-ckjt25rhz001m013pa4kn7eu9/ckr0xyybi03z701072il0b5d0/play"
          >
            Invision button style guide
          </a>
          <div className="grid grid-flow-col-dense grid-cols-4">
            <div>
              <div className="form-label">Primary Button</div>
              <button className="button-primary">Primary</button>
            </div>
            <div>
              <div className="form-label">Secondary Button</div>
              <button className="button-secondary">Secondary</button>
            </div>
            <div>
              <div className="form-label">Disabled Button</div>
              <button disabled className="button-primary">
                Disabled
              </button>
            </div>
            <div>
              <div className="form-label">Secondary Button Inverted</div>
              <div className="bg-primary-blue3 p-4">
                <button className="button-secondary inverted">Secondary Inverted</button>
              </div>
            </div>
          </div>
        </section>
        <section className="rounded-md border-2 border-primary-gray4 p-8">
          <h5 id="form-elements" className="text-primary-green1">
            Form Elements
          </h5>
          <a
            className="underline"
            href="https://portlandwebworks.invisionapp.com/console/FTE-SunPass---UI-Concepts-Desktop-ckjt25rhz001m013pa4kn7eu9/ckr2cbrwx06zc01077hzn8lk1/play"
          >
            Invision Forms Styleguide
          </a>
          <div className="mt-8 font-bold text-primary-green1">Inputs</div>

          <div className="grid grid-flow-col grid-cols-1 space-x-2 md:grid-cols-4">
            <div>
              <div className="form-label">Label</div>
              <StyledInput field={{}} fieldstate={{ invalid: false }}></StyledInput>
            </div>
            <div>
              <div className="form-label">Disabled</div>
              <StyledInput field={{}} fieldstate={{ invalid: false }} disabled={true}></StyledInput>
            </div>
            <div>
              <div className="form-label required">Required Label</div>
              <StyledInput field={{}} fieldstate={{ invalid: false }}></StyledInput>
            </div>
            <div>
              <div className="form-label required">Input with error</div>
              <StyledInput field={{}} fieldstate={{ invalid: true }}></StyledInput>
              <div className="form-error-message">This is the error message</div>
            </div>
          </div>

          <div className="mt-8 font-bold text-primary-green1">Select Inputs</div>

          <div className="grid grid-flow-col grid-cols-1 space-x-2 md:grid-cols-4">
            <div>
              <div className="form-label">Label</div>
              <StyledSelect field={{}} fieldState={{ invalid: false }} placeholder="Select">
                <option value={'Option 1'}>Option 1</option>
                <option value={'Option 2'}>Option 2</option>
                <option value={'Option 3'}>Option 3</option>
              </StyledSelect>
            </div>
            <div>
              <div className="form-label">Disabled</div>
              <StyledSelect
                field={{}}
                fieldState={{ invalid: false }}
                placeholder="Select"
                disabled={true}
              ></StyledSelect>
            </div>
            <div>
              <div className="form-label required">Required Label</div>
              <StyledSelect field={{}} fieldState={{ invalid: false }} placeholder="Select">
                <option value={'Option 1'}>Option 1</option>
                <option value={'Option 2'}>Option 2</option>
                <option value={'Option 3'}>Option 3</option>
              </StyledSelect>
            </div>
            <div>
              <div className="form-label required">Input with error</div>
              <StyledSelect field={{}} fieldState={{ invalid: true }} placeholder="Select">
                <option value={'Option 1'}>Option 1</option>
                <option value={'Option 2'}>Option 2</option>
                <option value={'Option 3'}>Option 3</option>
              </StyledSelect>
              <div className="form-error-message">This is the error message</div>
            </div>
          </div>
        </section>
        <section className="rounded-md border-2 border-primary-gray4 p-8">
          <h5 className="text-primary-green1" id="progress-stepper">
            Progress Stepper
          </h5>
          <div className="mx-auto mb-8 w-5/6 sm:w-1/2">
            <ProgressSteps steps={ExampleSteps} currentStepIndex={1}></ProgressSteps>
          </div>
        </section>
        <section className="rounded-md border-2 border-primary-gray4 p-8">
          <h5 className="text-primary-green1" id="collapsible-container">
            Collapsible Container
          </h5>
          <CollapsableContainer title="Container Title">
            <h6>Container Content</h6>
            <div>You can put anything in this container</div>
          </CollapsableContainer>
        </section>
        <section className="rounded-md border-2 border-primary-gray4 p-8">
          <h5 className="text-primary-green1" id="selectable-card">
            Selectable Card
          </h5>
          <div className="mx-4 mb-4 mt-8 text-xl">
            Selectable cards without set height and no grid or flex positioning:
          </div>
          <div>
            <SelectableCard
              title="I do not travel, or rarely travel, on the Pinellas Bayway"
              onClick={() => onSelect(1)}
              selected={selected === 1}
              autoHeight={true}
            ></SelectableCard>
            <SelectableCard
              title="I frequently travel on the Pinellas Bayway in addition to my travel throughout the state"
              content="I may, from time to time, or frequently, travel on the Pinellas Bayway in addition to my regular driving throughout the state."
              onClick={() => onSelect(2)}
              selected={selected === 2}
              autoHeight={true}
            ></SelectableCard>
            <SelectableCard
              title="I travel solely on the Pinellas Bayway"
              content="I travel on Pinellas Bayway regularly but rarely, if ever, travel throughout the rest of the state."
              onClick={() => onSelect(3)}
              selected={selected === 3}
              autoHeight={true}
            ></SelectableCard>

            <div className="mx-4 mb-4 mt-8 text-xl">Selectable cards with fixed height and arranged in grid:</div>
            <div className="grid grid-cols-1 md:grid-cols-2">
              <SelectableCard
                title="Permanent, Semi-Permanent, or Seasonal Resident"
                content="I live in Florida full or part time, frequently visit Florida, or reside in Florida for large amounts of time throughout the year."
                onClick={() => onSelect(4)}
                selected={selected === 4}
              ></SelectableCard>

              <SelectableCard
                title="Temporary Visitor"
                content="I am a temporary visitor, visit Florida on occasion, or plan on visiting in the near future."
                onClick={() => onSelect(5)}
                selected={selected === 5}
              ></SelectableCard>

              <SelectableCard
                title="Commercial Entity"
                content="I am a commercial entity interested in a fleet account to accommodate large volumes of vehicles and transponders."
                onClick={() => onSelect(6)}
                selected={selected === 6}
              ></SelectableCard>

              <SelectableCard
                title="Government Figure"
                content="I am in, or will travel to, Florida as a government figure with the intent of performing official business, and meet the following requirements."
                onClick={() => onSelect(7)}
                selected={selected === 7}
              ></SelectableCard>
            </div>
          </div>
        </section>
        <section className="rounded-md border-2 border-primary-gray4 p-8">
          <h5 className="text-primary-green1" id="selectable-card">
            Icon Link Card
          </h5>
          <IconLinkCard
            id={ActionItemAndIconLinkCardId.ADD_PAYMENT_METHOD}
            title="Add A Payment Method"
            icon={faCreditCard}
            link="/"
            classNames="mt-3"
          />
        </section>
      </section>
    </div>
  );
}

import React from 'react';
import { t } from '@lingui/macro';
import '../MyAccount.scss';
import useScreenSize from 'src/hooks/screen/useScreenSize';
import ScrollToTop from '../../ScrollToTop/ScrollToTop';
import BackLink from '../BackLink';
import { Form } from 'antd';
import { FormProvider } from 'react-hook-form';
import LeaveSiteConfirmationModal from '../../Modals/ConfirmationModal/LeaveSiteConfirmationModal';
import PrivacyAndSecuritySection from './PrivacyAndSecuritySection';
import ConfirmationModal from '../../Modals/ConfirmationModal/ConfirmationModal';
import { MainLoader } from '../../Loader/MainLoader';
import SecurityQuestionsSection from './SecurityQuestionsSection';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import ScreenSize from 'src/types/ScreenSize.enum';
import { usePrivacyAndSecurity } from '../../../hooks/domain/privacyAndSecurity/usePrivacyAndSecurity';

export default function PrivacyAndSecurity(): React.ReactElement {
  const { screenSize } = useScreenSize();

  const { methods, onSubmit, toggleUpdatePasswordMode, closeErrorModal, closeConfirmationModal, formState } =
    usePrivacyAndSecurity();

  const { loading, isFormDirty, isErrorModalShown, isSubmitSuccessful, isUpdatePasswordMode, isModalShown } = formState;

  return (
    <div className="w-full pt-5 large-tablet:pt-0">
      <MainLoader loading={loading} />
      <ScrollToTop />
      <ConfirmationModal
        title={t`Try Again Later`}
        content={t`Cannot load data.`}
        showModal={isErrorModalShown}
        onOk={closeErrorModal}
        closeType
        alignButtons="center"
        alignText="center"
      />
      <ScrollToTop triggerValues={isSubmitSuccessful} />
      {screenSize < ScreenSize.XL ? (
        <div className="mb-5 ml-5">
          <BackLink />
        </div>
      ) : null}
      <Form className="theme flex flex-col" layout="vertical" size="large" onFinish={onSubmit}>
        <FormProvider {...methods}>
          <LeaveSiteConfirmationModal
            showModal={isModalShown}
            onOk={toggleUpdatePasswordMode}
            onCancel={closeConfirmationModal}
          />

          <PrivacyAndSecuritySection
            isUpdatePasswordMode={isUpdatePasswordMode}
            toggleUpdatePasswordMode={toggleUpdatePasswordMode}
          />
          <SecurityQuestionsSection />
          {isFormDirty ? <CustomButton content="Save Changes" type="submit" className="mx-auto mt-10" /> : null}
        </FormProvider>
      </Form>
    </div>
  );
}

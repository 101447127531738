import { AccountSubtype, AccountType } from '../../../types/onboarding.types';
import { FL_STATE_CODE, USA_COUNTRY_ISO3 } from '../../../utils/countryStates.utils';
import { useOnboardingStore } from '../../../store/onboarding/onboarding.store';

export const getAccountInfoInitialFormStateFrom = () => {
  const {
    onboardingInfo: { accountInfo, accountType, accountSubtype, referenceNumber },
  } = useOnboardingStore();

  return {
    referenceNumber: referenceNumber || '',
    username: accountInfo?.username || '',
    password: accountInfo?.password || '',
    reTypePassword: accountInfo?.reTypePassword || '',
    firstSecurityQuestion: accountInfo?.firstSecurityQuestion || '',
    firstSecurityQuestionAnswer: accountInfo?.firstSecurityQuestionAnswer || '',
    secondSecurityQuestion: accountInfo?.secondSecurityQuestion || '',
    secondSecurityQuestionAnswer: accountInfo?.secondSecurityQuestionAnswer || '',
    thirdSecurityQuestion: accountInfo?.thirdSecurityQuestion || '',
    thirdSecurityQuestionAnswer: accountInfo?.thirdSecurityQuestionAnswer || '',
    firstName: accountInfo?.firstName || '',
    middleName: accountInfo?.middleName || '',
    lastName: accountInfo?.lastName || '',
    email: accountInfo?.email || '',
    reTypeEmail: accountInfo?.reTypeEmail || '',
    cellPhone: accountInfo?.cellPhone || '',
    homePhone: accountInfo?.homePhone || '',
    workPhone: accountInfo?.workPhone || '',
    smsOption: accountInfo?.smsOption ? accountInfo?.smsOption : false,
    language: accountInfo?.language || '',
    country: accountInfo?.country || USA_COUNTRY_ISO3,
    state: accountInfo?.state || FL_STATE_CODE,
    county: accountInfo?.county || '',
    addressLine1: accountInfo?.addressLine1 || '',
    addressLine2: accountInfo?.addressLine2 || '',
    city: accountInfo?.city || '',
    postalCode: accountInfo?.postalCode || '',
    additionalEmailAddresses: accountInfo?.additionalEmailAddresses || [],
    showAdditionalContacts: accountInfo?.additionalContacts?.length || false,
    additionalContacts: accountInfo?.additionalContacts || [],
    companyName: accountInfo?.companyName || '',
    organizationName: accountInfo?.organizationName || '',
    doingBusinessAs: accountInfo?.doingBusinessAs || '',
    purchaseOrderNumber: accountInfo?.purchaseOrderNumber || '',
    fein: accountInfo?.fein || '',
    statementType: accountInfo?.statementType || '',
    //TODO FLO-3096 - remove in scope of ticket FLO-3096
    accountType: accountType === AccountType.PAYG ? AccountType.PRIVATE : accountType,
    accountSubType: accountType === AccountType.PAYG ? AccountSubtype.PRIVATEVIDEO : accountSubtype,
  };
};

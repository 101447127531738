import { i18n } from '@lingui/core';
export const ALPHANUMERIC_ERROR_MSG = i18n._('Please enter alphanumeric characters only');
export const SINGLE_CHAR_MAX_ERROR_MSG = i18n._('1 Character Maximum');
export const ALPHABETICAL_ERROR_MSG = i18n._('Please enter alphabetical characters only');

export const ALPHANUMERIC_OR_SPECIAL_CHAR_ERROR_MSG = i18n._(
  "Please enter alphanumeric characters or the following special characters: - ' . ,",
);
export const REPLENISHMENT_THRESHOLD_MIN_ERROR_MSG = i18n._('Minimum of $10 is required');
export const NUMERICAL_ERROR_MSG = i18n._('Numerical values only');
export const ENTER_VALUE_ERROR_MSG = i18n._('Please enter a value');
export const ACCOUNT_NUMBER_INVALID_ERROR_MSG = i18n._('Account number must contain only digits');
export const ACCOUNT_NUMBER_REQUIRED = i18n._('Account number is required');

import { Link } from 'react-router-dom';
import React from 'react';

interface IInfoLinkProps {
  children: React.ReactNode;
  onClick?: () => void;
}

export const InfoLink = ({ children, onClick }: IInfoLinkProps) => {
  return (
    <Link
      to="#"
      onClick={onClick}
      className="mb-5 inline-block w-full  text-left text-primary-green1 underline sm:mt-5"
      aria-label="Having trouble locating transponder"
    >
      <span className="large-tablet:mt-8">{children}</span>
    </Link>
  );
};

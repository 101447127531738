import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_SECURITY_QUESTION_FOR_FORGOTTEN_PASSWORD,
  IForgotPasswordGetSecurityQuestionInput,
  IForgotPasswordGetSecurityQuestionOutput,
  IForgotPasswordUpdateInput,
  UPDATE_PASSWORD,
} from 'src/graphql/forgotUsernameOrPassword.graphql.';

export const useForgotPassword = () => {
  const [
    getSecurityQuestion,
    {
      data: forgotPasswordGetSecurityQuestionData,
      loading: forgotPasswordGetSecurityQuestionLoading,
      error: forgotPasswordGetSecurityQuestionError,
    },
  ] = useLazyQuery<
    IForgotPasswordGetSecurityQuestionOutput,
    { forgotPasswordGetSecurityQuestionInput: IForgotPasswordGetSecurityQuestionInput }
  >(GET_SECURITY_QUESTION_FOR_FORGOTTEN_PASSWORD);

  const [
    updatePassword,
    { loading: updatePasswordLoading, error: updatePasswordError, reset: updatePasswordResetError },
  ] = useMutation<{ forgotPasswordUpdateOutput: boolean }, { forgotPasswordUpdateInput: IForgotPasswordUpdateInput }>(
    UPDATE_PASSWORD,
  );

  return {
    getSecurityQuestion,
    forgotPasswordGetSecurityQuestionData,
    forgotPasswordGetSecurityQuestionLoading,
    forgotPasswordGetSecurityQuestionError,
    updatePassword,
    updatePasswordLoading,
    updatePasswordError,
    updatePasswordResetError,
  };
};

import { gql } from '@apollo/client';

export const APPLY_PROMO_CODE = gql`
  mutation applyPromotionCode($couponCode: String!) {
    applyPromotionCode(couponCode: $couponCode)
  }
`;

export interface IApplyPromoCodeInput {
  couponCode: string;
}

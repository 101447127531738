import { t } from '@lingui/macro';
import React, { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import LicencePlateNumber from '../../../atoms/form/vehicle/LicencePlateNumber';
import LicencePlateState from '../../../atoms/form/vehicle/LicencePlateState';
import LicencePlateCountry from '../../../atoms/form/vehicle/LicencePlateCountry';
import LicencePlateType from '../../../atoms/form/vehicle/LicencePlateType';
import IsTrailer from '../../../atoms/form/vehicle/IsTrailer';
import VehicleMake from '../../../atoms/form/vehicle/VehicleMake';
import VehicleModel from '../../../atoms/form/vehicle/VehicleModel';
import VehicleYear from '../../../atoms/form/vehicle/VehicleYear';
import VehicleColor from '../../../atoms/form/vehicle/VehicleColor';
import VehicleIsRental from '../../../atoms/form/vehicle/VehicleIsRental';
import EffectiveStartDate from '../../../atoms/form/vehicle/EffectiveStartDate';
import EffectiveStartTime from '../../../atoms/form/vehicle/EffectiveStartTime';
import EffectiveEndDate from '../../../atoms/form/vehicle/EffectiveEndDate';
import EffectiveEndTime from '../../../atoms/form/vehicle/EffectiveEndTime';
import '../../../../pages/Onboarding/Onboarding.scss';
import { useScroll } from 'src/hooks/screen/useScroll';

export interface IVehicleFormItemProps {
  field?: Record<any, string>;
  index?: number;
  name: string;
  isInMyDashboard?: boolean;
  isEditMode?: boolean;
  className?: string;
  isInHomePage?: boolean;
  defaultValue?: string;
}

interface IOnboardingVehicleFormProps {
  field: Record<any, string>;
  index: number;
  removedIndex?: number;
}

const OnboardingVehicleForm: FC<IOnboardingVehicleFormProps> = ({ field, index, removedIndex }) => {
  const {
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();

  const { scrollToError } = useScroll();

  useEffect(() => {
    scrollToError(errors);
  }, [errors]);

  const setFieldValues = () => {
    setValue(`vehicles[${index}].plateState`, field.plateState);
    setValue(`vehicles[${index}].vehicleCountry`, field.vehicleCountry);
    setValue(`vehicles[${index}].vehicleYear`, field.vehicleYear);
    setValue(`vehicles[${index}].vehicleColor`, field.vehicleColor);
    setValue(`vehicles[${index}].effectiveStartDate`, field.effectiveStartDate);
    setValue(`vehicles[${index}].effectiveStartTime`, field.effectiveStartTime);
    setValue(`vehicles[${index}].effectiveEndDate`, field.effectiveEndDate);
    setValue(`vehicles[${index}].effectiveEndTime`, field.effectiveEndTime);
    setValue(`vehicles[${index}].plateNumber`, field.plateNumber);
    setValue(`vehicles[${index}].plateState`, field.plateState);
    setValue(`vehicles[${index}].plateType`, field.plateType);
    setValue(`vehicles[${index}].isRental`, field.isRental);
    setValue(`vehicles[${index}].isTrailer`, field.isTrailer);
    setValue(`vehicles[${index}].vehicleMake`, field.vehicleMake);
    setValue(`vehicles[${index}].vehicleModel`, field.vehicleModel);
  };

  useEffect(() => {
    if (watch(`vehicles[${index}].vehicleYear`) == undefined) {
      setValue(`vehicles[${index}].vehicleYear`, undefined);
    }
    if (watch(`vehicles[${index}].plateNumber`) == '') setValue(`vehicles[${index}].plateNumber`, ' ');
  }, [watch(`vehicles[${index}].vehicleYear`), watch(`vehicles[${index}].isTrailer`)]);

  useEffect(() => {
    trigger(`vehicles[${index}].effectiveStartDate`);
  }, [watch(`vehicles[${index}].isRental`)]);

  useEffect(() => {
    if (removedIndex === index) {
      setFieldValues();
    }
  }, [removedIndex]);

  const basicProps: Partial<IVehicleFormItemProps> = {
    field,
    index,
  };

  return (
    <div className="flex flex-wrap">
      <LicencePlateNumber {...basicProps} name={`vehicles[${index}].plateNumber`} />
      <LicencePlateState {...basicProps} name={`vehicles[${index}].plateState`} />
      <LicencePlateCountry {...basicProps} name={`vehicles[${index}].vehicleCountry`} />
      <LicencePlateType {...basicProps} name={`vehicles[${index}].plateType`} />
      <IsTrailer {...basicProps} name={`vehicles[${index}].isTrailer`} />

      {!watch(`vehicles[${index}].isTrailer`) && (
        <>
          <VehicleMake {...basicProps} name={`vehicles[${index}].vehicleMake`} />
          <VehicleModel {...basicProps} name={`vehicles[${index}].vehicleModel`} />
          <VehicleYear {...basicProps} name={`vehicles[${index}].vehicleYear`} />
        </>
      )}

      <VehicleColor {...basicProps} name={`vehicles[${index}].vehicleColor`} />
      <VehicleIsRental {...basicProps} name={`vehicles[${index}].isRental`} />
      <EffectiveStartDate {...basicProps} name={`vehicles[${index}].effectiveStartDate`} shouldDefaultBeEmpty={false} />
      <EffectiveStartTime {...basicProps} name={`vehicles[${index}].effectiveStartTime`} />
      <EffectiveEndDate {...basicProps} name={`vehicles[${index}].effectiveEndDate`} />
      <EffectiveEndTime {...basicProps} name={`vehicles[${index}].effectiveEndTime`} />

      <span className="text-sm text-opacity-60" id={`vehicles[${index}].note`}>
        {t`Note: If you've driven through tolls before you set up your SunPass Account, you can backdate your start date to the date of your first toll invoice, and your SunPass Account Member toll rate will be applied to the tolls you've accrued since that date`}
      </span>
    </div>
  );
};

export default OnboardingVehicleForm;

import { useQuery } from '@apollo/client';
import { COUNTRY_STATES_ISO3, IState } from '../../../graphql/countries.graphql';
import { LabelValue } from '../../../types/LabelValue.interface';
import { useState } from 'react';

interface IUseStateOptionsInput {
  country: string | null;
  onCompleted?: () => void;
}

interface IUseStateOptions {
  states: any[]; // TODO: should type this
  stateOptions: LabelValue[];
  loading: boolean;
  errorLoading: boolean;
}

const useStateOptions = ({ country, onCompleted }: IUseStateOptionsInput): IUseStateOptions => {
  const [isError, setIsError] = useState(false);
  const { data, loading } = useQuery(COUNTRY_STATES_ISO3, {
    variables: { iso3: country },
    errorPolicy: 'all',
    onError: () => setIsError(true),
    skip: !country,
    onCompleted,
  });
  return {
    states: data?.country?.states || [],
    stateOptions:
      data?.countryByIso3?.states.map(
        (state: IState): LabelValue => ({
          value: state.code,
          label: state.code,
        }),
      ) || [],
    errorLoading: isError,
    loading,
  };
};

export default useStateOptions;

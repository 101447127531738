import React, { FC } from 'react';

interface IStepperProps {
  isVehiclesVisible: boolean;
  setIsVehiclesVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const Stepper: FC<IStepperProps> = ({ isVehiclesVisible, setIsVehiclesVisible }) => {
  const ActivateVehicles = () => {
    setIsVehiclesVisible(true);
  };

  const ActivateTransponders = () => {
    setIsVehiclesVisible(false);
  };

  return (
    <div className="flex w-full justify-center">
      <button
        className={`mx-2 h-7 w-7 rounded-full ${
          isVehiclesVisible ? 'bg-primary-green1' : 'bg-primary-green2'
        } hover:bg-primary-green1`}
        onClick={ActivateVehicles}
      ></button>
      <button
        className={`mx-2 h-7 w-7 rounded-full ${
          isVehiclesVisible ? 'bg-primary-green2' : 'bg-primary-green1'
        } hover:bg-primary-green1`}
        onClick={ActivateTransponders}
      ></button>
    </div>
  );
};

export default Stepper;

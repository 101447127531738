import React, { useEffect } from 'react';
import { t, Trans } from '@lingui/macro';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { AddLinkedChildAccountInput } from '../../../../graphql/linkedAccounts.graphql';
import { Form } from 'antd';
import { FormProvider } from 'antd/lib/form/context';
import StyledInput from '../../../StyledInput/StyledInput';
import { MainLoader } from '../../../Loader/MainLoader';
import { useLinkedAccounts } from '../../../../hooks/useLinkedAccounts';
import { CustomButton } from '../../../CustomButton/CustomButton';
import { linkedAccountRequestValidator } from '../../../../validators/AccountSettings/linkedAccountRequest.validator';
import { useAccountSettings } from 'src/store/account/accountSettings.store';

export const RequestAccountAccess = () => {
  const { setIsTabDirty } = useAccountSettings();
  const methods = useForm<AddLinkedChildAccountInput>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(linkedAccountRequestValidator),
    defaultValues: {
      childAccountNumber: '',
      childAccountZipCode: '',
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = methods;

  const { loading, add, error: linkAccountRequestError } = useLinkedAccounts();

  const onSubmit = async (formData: AddLinkedChildAccountInput) => {
    try {
      await add(formData);
      reset();
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    isDirty ? setIsTabDirty(true) : setIsTabDirty(false);
  }, [isDirty]);
  return (
    <div>
      <MainLoader loading={loading} />
      <div className="mt-2 font-bold text-primary-blue1">
        <Trans>
          Enter the postal code and the account number of the account holder you’d like to request access for:
        </Trans>
      </div>
      <div className="flex w-full flex-col">
        <Form className="theme w-full items-start" layout="vertical" onFinish={handleSubmit(onSubmit)}>
          <FormProvider {...methods}>
            <div className="mt-4 flex w-full flex-col sm:flex-row sm:pr-2">
              <div className="w-full sm:pr-2 md:w-6/12 lg:w-4/12 ">
                <Form.Item
                  id="childAccountZipCode"
                  className="theme"
                  hasFeedback
                  help={errors?.childAccountZipCode?.message}
                  validateTrigger={['onChange']}
                >
                  <Controller
                    name="childAccountZipCode"
                    control={control}
                    render={({ field, fieldState }) => (
                      <StyledInput
                        key="childAccountZipCode"
                        id="childAccountZipCode"
                        placeholder={t`Enter postal code`}
                        field={field}
                        fieldstate={fieldState}
                        maxLength={20}
                      />
                    )}
                  ></Controller>
                </Form.Item>
              </div>
              <div className="w-full sm:pr-2 md:w-6/12 lg:w-6/12 ">
                <Form.Item
                  id="childAccountNumber"
                  className="theme"
                  hasFeedback
                  help={errors?.childAccountNumber?.message}
                  validateTrigger={['onChange']}
                >
                  <Controller
                    name="childAccountNumber"
                    control={control}
                    render={({ field, fieldState }) => (
                      <StyledInput
                        key="childAccountNumber"
                        id="childAccountNumber"
                        placeholder={t`Enter account number`}
                        field={field}
                        fieldstate={fieldState}
                        minLength={1}
                        maxLength={10}
                      />
                    )}
                  ></Controller>
                </Form.Item>
              </div>
            </div>
            {linkAccountRequestError ? (
              <div className="text-primary-red">
                <Trans>
                  We were unable to send this linked account request because the account you requested access to either
                  does not exist or is a different account type than yours. Please review the account info you&apos;ve
                  provided and try again.
                </Trans>
              </div>
            ) : null}
            <CustomButton content={<Trans>Send Request</Trans>} className="mt-2" type="submit"></CustomButton>
          </FormProvider>
        </Form>
      </div>
    </div>
  );
};

import { Form, Select } from 'antd';
import { t, Trans } from '@lingui/macro';
import { FL_STATE_CODE, USA_COUNTRY_ISO3 } from '../../../../utils/countryStates.utils';
import { Controller, useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import { LabelValue } from '../../../../types/LabelValue.interface';
import React from 'react';
import useCountyOptions from '../../../../hooks/domain/address/useCountyOptions';
import { useAddressFields } from '../../../../hooks/domain/address/useAddressFields';
import { IAddressFormElemBaseProps } from './address.types';
const { Option } = Select;
export const County = ({ isAddressUsedForPayment, disabled, className }: IAddressFormElemBaseProps) => {
  const {
    addressFieldsState: { stateField, countyField, countryField },
  } = useAddressFields(isAddressUsedForPayment);
  const { control, watch } = useFormContext();
  const stateWatch = watch(stateField.name);
  const countryWatch = watch(countryField.name);
  const countyWatch = watch(countyField.name);
  const { countyOptions } = useCountyOptions({
    state: stateWatch as string,
  });

  return (
    <div className={className}>
      <Form.Item
        label={<Trans>County</Trans>}
        hasFeedback
        validateStatus={countyField.errorMsg ? 'error' : ''}
        help={countryWatch === USA_COUNTRY_ISO3 && stateWatch === FL_STATE_CODE ? countyField.errorMsg : null}
        required
      >
        <Controller
          name={countyField.name}
          control={control}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              key={countyField.name}
              id={countyField.name}
              className={classNames({
                'input-error': stateWatch == FL_STATE_CODE ? fieldState.invalid : null,
              })}
              value={stateWatch === FL_STATE_CODE ? countyWatch : ''}
              disabled={stateWatch !== FL_STATE_CODE || disabled}
              optionFilterProp="children"
              aria-label={t`County`}
              showSearch
            >
              {countyOptions?.map((county: LabelValue) => (
                <Option key={county.value} value={county.label} label={county.label}>
                  {county.label}
                </Option>
              ))}
            </Select>
          )}
        />
      </Form.Item>
    </div>
  );
};

import MailingAddressFormField, { BillingAddressFormField } from './AddressFormField.enum';
import * as yup from 'yup';
import BaseSchema from 'yup/lib/schema';
import { i18n } from '@lingui/core';
import { validators } from '../../validators/validators';
import {
  CANADA_COUNTRY_ISO3,
  FL_STATE_CODE,
  MEXICO_COUNTRY_ISO3,
  USA_COUNTRY_ISO3,
} from 'src/utils/countryStates.utils';
import {
  ADDRESS_LINE_1_REQUIRED,
  CITY_REQUIRED,
  COUNTRY_REQUIRED,
  COUNTY_REQUIRED,
  STATE_PROVINCE_REQUIRED,
} from 'src/validators/onboarding/accountInfo/accountInfoErrorMessages';

const addressLine1Validator = validators.address.nullable().required(i18n._(ADDRESS_LINE_1_REQUIRED));
export const addressLine2Validator = validators.address2.nullable();
const cityValidator = validators.city.nullable().required(i18n._(CITY_REQUIRED));
const countryValidator = yup.string().nullable().required(i18n._(COUNTRY_REQUIRED));
const postalCodeValidator = validators.postalCode.nullable();
const countyValidator = yup.string().when(MailingAddressFormField.STATE, {
  is: FL_STATE_CODE,
  then: yup.string().nullable(true).required(i18n._(COUNTY_REQUIRED)),
  otherwise: yup.string().nullable(true).optional(),
});
const stateValidator = yup.string().when('country', {
  is: (country: string) =>
    country === USA_COUNTRY_ISO3 || country === MEXICO_COUNTRY_ISO3 || country === CANADA_COUNTRY_ISO3,
  then: yup.string().nullable(true).required(i18n._(STATE_PROVINCE_REQUIRED)),
  otherwise: yup.string().nullable(true).optional(),
});

export const mailingAddressValidator = (countyRequired: boolean): { [key: string]: BaseSchema } => {
  return {
    [MailingAddressFormField.ADDRESS_LINE_1]: addressLine1Validator,
    [MailingAddressFormField.ADDRESS_LINE_2]: addressLine2Validator,
    [MailingAddressFormField.CITY]: cityValidator,
    [MailingAddressFormField.COUNTRY]: countryValidator,
    [MailingAddressFormField.STATE]: stateValidator,
    [MailingAddressFormField.POSTAL_CODE]: postalCodeValidator,
    [MailingAddressFormField.COUNTY]: countyRequired ? countyValidator : yup.string(),
  };
};

export const billingAddressValidator = (): { [key: string]: BaseSchema } => {
  return {
    [BillingAddressFormField.ADDRESS_LINE_1]: addressLine1Validator,
    [BillingAddressFormField.ADDRESS_LINE_2]: addressLine2Validator,
    [BillingAddressFormField.CITY]: cityValidator,
    [BillingAddressFormField.COUNTRY]: countryValidator,
    [BillingAddressFormField.STATE]: stateValidator,
    [BillingAddressFormField.POSTAL_CODE]: postalCodeValidator,
  };
};

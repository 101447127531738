import React from 'react';
import { Trans } from '@lingui/macro';
import '../../pages/Onboarding/Onboarding.scss';
import { useOnboardingStore } from '../../store/onboarding/onboarding.store';
import { SELECT_DETAILS } from '../../store/onboarding/onboarding.selectors';
import { OnboardingFlowType } from '../../types/onboarding.types';

export const ReferenceNumber = () => {
  const { referenceNumber, onboardingFlowType } = useOnboardingStore(SELECT_DETAILS);
  return referenceNumber && onboardingFlowType !== OnboardingFlowType.PREPAID ? (
    <p className="reference-number text-right font-light italic">
      <Trans>Reference Number: {referenceNumber}</Trans>
    </p>
  ) : (
    <div className="mb-10 h-1 w-full" />
  );
};

import { Form } from 'antd';
import { t, Trans } from '@lingui/macro';
import { Controller, useFormContext } from 'react-hook-form';
import StyledInput from '../../../StyledInput/StyledInput';
import React from 'react';
import { IFleetGovAdditionalInfo } from '../../../../types/onboarding.types';
import { IAccountFormElemBaseProps } from './account.types';

export const OrganizationName = ({ disabled }: IAccountFormElemBaseProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IFleetGovAdditionalInfo>();
  return (
    <div className="w-full sm:w-6/12 sm:pr-1">
      <Form.Item
        label={<Trans>Organization Name</Trans>}
        className="theme"
        help={errors.organizationName?.message}
        required
      >
        <Controller
          name="organizationName"
          control={control}
          render={({ field, fieldState }) => (
            <StyledInput
              disabled={disabled}
              field={field}
              fieldstate={fieldState}
              key="organizationName"
              id="organizationName"
              maxLength={30}
              aria-label={t`Organization Name`}
            />
          )}
        />
      </Form.Item>
    </div>
  );
};

import { t } from '@lingui/macro';
import { Controller, useFormContext } from 'react-hook-form';
import { Form, Input } from 'antd';
import React from 'react';

export const ReferenceNumberInput = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <div>
      <Form.Item
        label={t`Reference Number`}
        help={errors.referenceNumber?.message as React.ReactNode}
        hasFeedback
        validateStatus={errors.referenceNumber?.message ? 'error' : ''}
        className="theme w-full sm:w-1/3"
        required
      >
        <Controller
          name={'referenceNumber'}
          control={control}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              {...fieldState}
              id={'referenceNumber'}
              key={'referenceNumber'}
              minLength={1}
              maxLength={12}
              aria-label={t`Reference Number`}
            />
          )}
        />
      </Form.Item>
    </div>
  );
};

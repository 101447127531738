import { IPromoCodeForm } from '../../../Dashboard/Wallet/PromoCodeModal/PromoCodeModal';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { t } from '@lingui/macro';
import { Form, Input } from 'antd';

interface IPromoCodeProps {
  isPromoValid?: boolean;
  isPromoCodeApplied?: boolean;
}

const PromoCode = ({ isPromoValid, isPromoCodeApplied }: IPromoCodeProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IPromoCodeForm>();
  return (
    <Form.Item
      label={t`Promo Code`}
      htmlFor={'couponCode'}
      help={errors?.couponCode?.message}
      validateStatus={errors?.couponCode || (!isPromoValid && isPromoCodeApplied) ? 'error' : ''}
      className="promo-code"
      hasFeedback
    >
      <Controller
        name={'couponCode'}
        control={control}
        render={({ field, fieldState }) => (
          <div>
            <Input
              key={'couponCode'}
              {...field}
              {...fieldState}
              maxLength={50}
              minLength={1}
              id={'couponCode'}
              name={'couponCode'}
              value={field.value}
              aria-label={t`couponCode`}
            />
          </div>
        )}
      />
    </Form.Item>
  );
};

export default PromoCode;

import { gql } from '@apollo/client';

export const GET_NOTIFICATIONS = gql`
  query notifications {
    notifications {
      type
      values
      id
    }
  }
`;

export interface INotification {
  id: string;
  type: NotificationType;
  values: string[];
}

export enum NotificationType {
  LOW_BALANCE = 'LOW_BALANCE',
  EXPIRED_PAYMENT_METHOD = 'EXPIRED_PAYMENT_METHOD',
  UPCOMING_PAYMENT_EXPIRATION = 'UPCOMING_PAYMENT_EXPIRATION',
  NEGATIVE_BALANCE = 'NEGATIVE_BALANCE',
  RECENT_REPLENISHMENT = 'RECENT_REPLENISHMENT',
  APPROACH_REPLENISHMENT_THRESHOLD = 'APPROACH_REPLENISHMENT_THRESHOLD',
  LINKED_ACCOUNT_REQUEST = 'LINKED_ACCOUNT_REQUEST',
}

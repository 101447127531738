import { ExclamationCircleFilled } from '@ant-design/icons';
import { Input, InputProps } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { colors } from 'src/theme/theme';

/**
 * field - field from the react hook form Controller render prop (field: ControllerRenderProps<TFieldValues, TName>;)
 * fieldState - fieldState from the react hook form Controller render prop (fieldState: ControllerFieldState;)

 */
interface Props extends InputProps {
  field: any;
  fieldstate: any;
}

export default function StyledInput(props: Props): React.ReactElement {
  return (
    <Input
      {...props}
      {...props.field}
      className={`${classNames({ 'input-error': props.fieldstate?.invalid })} ${props.className}`}
      suffix={
        props.fieldstate?.invalid ? (
          <ExclamationCircleFilled style={{ color: colors.alerts.red, fontSize: '18px' }} />
        ) : (
          <span />
        )
      }
    ></Input>
  );
}

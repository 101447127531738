import React, { useEffect, useState } from 'react';
import { Trans } from '@lingui/macro';
import { Breadcrumb, Form } from 'antd';
import { Link } from 'react-router-dom';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import { MainRoutes } from '../../types/routes';
import '../Home/Home.scss';
import Options from '../atoms/form/home/Options';
import LicencePlateNumber from '../atoms/form/vehicle/LicencePlateNumber';
import InvoiceNumber from '../atoms/form/home/InvoiceNumber';
import { IHomeSearchForm } from '../HomeSearch/HomeSearch';
import { FormProvider, useForm } from 'react-hook-form';
import { CustomButton } from '../CustomButton/CustomButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLazyQuery } from '@apollo/client';
import {
  GET_INVOICE_DETAILS,
  IInvoiceDetailOutput,
  ILookupWithInvoiceInput,
  ILookupWithInvoiceOutput,
  ILookupWithoutInvoiceInput,
  ILookupWithoutInvoiceOutput,
  LOOKUP_WITH_INVOICE,
  LOOKUP_WITHOUT_INVOICE,
} from '../../graphql/lookupWithInvoice.graphql';
import NewPaymentMethodOnboardingWrapper from '../Payments/NewPaymentMethodWrappers/NewPaymentMethodOnboardingWrapper';
import LocateInvoiceNumberModal from '../Modals/ConfirmationModal/LocateInvoiceNumberModal';
import DocumentId from '../atoms/form/home/DocumentId';
import { PostalCode } from '../atoms/form/address/PostalCode';
import { useHomeStore } from '../../store/home/home.store';
import { InvoiceSchema } from '../../validators/home/home.validator';
import { newApolloClient } from '../../security/CustomApolloProvider';
import { setInvoiceToken } from '../../security/token.service';
import { MainLoader } from '../Loader/MainLoader';
import InvoiceInfoSection from './InvoiceInfoSection';

const PayATollInvoiceOrTrafficCitation = () => {
  const schema = InvoiceSchema();
  const { homeInfo } = useHomeStore();
  const [selectedOption, setSelectedOption] = useState(homeInfo.chosenOption);

  const methods = useForm<IHomeSearchForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: homeInfo,
  });

  const { handleSubmit, setValue, watch, clearErrors } = methods;

  const onInvoiceSubmit = async (values: IHomeSearchForm) => {
    setSelectedOption(values.options);
    await checkInvoice(values);
  };

  const optionsWatch = watch('options');
  const documentIdWatch = watch('documentId');
  const postalCodeWatch = watch('postalCode');
  const invoiceNumberWatch = watch('invoiceNumber');
  const licenseNumberWatch = watch('licenseNumber');

  const clearForm = () => {
    clearErrors();
    setValue('documentId', '');
    setValue('licenseNumber', '');
    setValue('invoiceNumber', '');
    setValue('postalCode', '');
    setShowError(false);
    setIsDataCorrect(false);
  };

  useEffect(() => {
    if (optionsWatch !== homeInfo.chosenOption) {
      setIsDataCorrect(false);
    }
  }, [optionsWatch]);

  useEffect(() => {
    setShowError(false);
  }, [documentIdWatch, postalCodeWatch, invoiceNumberWatch, licenseNumberWatch]);

  const {
    homeInfo: { chosenOption, licenseNumber },
  } = useHomeStore();

  const element = document.getElementById('Look up');

  useEffect(() => {
    setValue('options', chosenOption);

    if (element) element.click();
  }, [element]);

  const [lookupWithInvoice, { data: lookupWithInvoiceData, loading: lookupWithInvoiceLoading }] = useLazyQuery<
    { lookupWithInvoice: ILookupWithInvoiceOutput },
    { lookupWithInvoiceInput: ILookupWithInvoiceInput }
  >(LOOKUP_WITH_INVOICE);

  const [lookupWithoutInvoice, { data: lookupWithoutInvoiceData, loading: lookupWithoutInvoiceLoading }] = useLazyQuery<
    { lookupWithoutInvoice: ILookupWithoutInvoiceOutput },
    { lookupWithoutInvoiceInput: ILookupWithoutInvoiceInput }
  >(LOOKUP_WITHOUT_INVOICE);

  const [isDataCorrect, setIsDataCorrect] = useState(false);
  const [savedInvoiceNumber, setSavedInvoiceNumber] = useState('');
  const [showError, setShowError] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState<IInvoiceDetailOutput>();
  const [invoiceDetailsLoading, setInvoiceDetailsLoading] = useState(false);

  const getInvoiceDetails = async () => {
    const token = lookupWithInvoiceData?.lookupWithInvoice.jwt.token;
    const { data: invoiceDetailData, loading: invoiceDetailLoading } = await newApolloClient.query({
      query: GET_INVOICE_DETAILS,
      context: {
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
      },
    });
    setInvoiceDetailsLoading(invoiceDetailLoading);
    setInvoiceDetails(invoiceDetailData.invoiceDetail);
  };

  useEffect(() => {
    lookupWithInvoiceData && getInvoiceDetails();
    setInvoiceToken(lookupWithInvoiceData?.lookupWithInvoice.jwt.token);
  }, [lookupWithInvoiceData, isDataCorrect]);

  const checkInvoice = async (values: IHomeSearchForm) => {
    const { options, licenseNumber, invoiceNumber, documentId, postalCode } = values;
    if (options === 'tollInvoice' || options === 'uniformCitation') {
      try {
        await lookupWithInvoice({
          variables: {
            lookupWithInvoiceInput: {
              invoiceNumber: invoiceNumber || documentId,
              licensePlate: licenseNumber,
            },
          },
        }).then((res) => {
          if (res.error) {
            console.log(res.error);
            setIsDataCorrect(false);
            setShowError(true);
          } else {
            setSavedInvoiceNumber(invoiceNumber || documentId);
            setIsDataCorrect(true);
          }
        });
      } catch (err) {
        console.log((err as Error).message);
      }
    } else if (options === 'withoutInvoiceOrCitation') {
      try {
        await lookupWithoutInvoice({
          variables: {
            lookupWithoutInvoiceInput: {
              zipcode: postalCode,
              licensePlate: licenseNumber,
            },
          },
        }).then((res) => {
          if (res.error) {
            console.log(res.error);
            setIsDataCorrect(false);
            setShowError(true);
          } else {
            setIsDataCorrect(true);
          }
        });
      } catch (err) {
        console.log((err as Error).message);
      }
    }
  };

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className="invoice-container h-full bg-white">
        <MainLoader loading={lookupWithInvoiceLoading || lookupWithoutInvoiceLoading || invoiceDetailsLoading} />
        <LocateInvoiceNumberModal showModal={showModal} onClose={() => setShowModal(false)} />
        <ScrollToTop />
        <div className="breadcrumb">
          <Breadcrumb separator="/">
            <Breadcrumb.Item className="underline">
              <Link to={MainRoutes.ROOT}>
                <Trans>Home</Trans>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item className="underline">
              <Trans>Pay a Toll Invoice or Traffic Citation</Trans>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <h1 className="m-auto w-full px-2 py-8 pb-0 text-center font-light md:w-2/3">
          <Trans>Pay a Toll Invoice or Traffic Citation</Trans>
        </h1>
        <p className="mx-auto w-3/4 max-w-xl py-4 text-center">
          <Trans>
            If you received a Toll Enforcement Invoice or Uniform Traffic Citation, complete the form below.
          </Trans>
        </p>
        <div className="invoice-form">
          <div className="home-search">
            <FormProvider {...methods}>
              <Form onFinish={handleSubmit(onInvoiceSubmit)} name="basic" layout="vertical">
                <Options />
                <p className="mt-5 md:mr-40 md:text-left">
                  {optionsWatch === 'tollInvoice' ? (
                    <Trans>
                      If you have received a Toll Enforcement Invoice and would like to make a payment, complete the
                      fields below and select Look Up.
                    </Trans>
                  ) : optionsWatch === 'uniformCitation' ? (
                    <Trans>
                      If you have received a Uniform Traffic Citation and would like to make a payment, complete the
                      fields below and select Look Up.
                    </Trans>
                  ) : (
                    <Trans>
                      Have you recently passed through a toll lane without stopping to pay cash and you do not have an
                      active transponder? You can pay the toll(s) before receiving a Toll Enforcement Invoice or Uniform
                      Traffic Citation, by completing the fields below and selecting Look Up.
                    </Trans>
                  )}
                </p>
                {optionsWatch === 'withoutInvoiceOrCitation' && (
                  <p className="mr-40 mt-5 text-left">
                    <Trans>Enter the plate number and postal code of the registered vehicle.</Trans>
                  </p>
                )}
                <div className="buttons-container flex">
                  <LicencePlateNumber
                    name="licenseNumber"
                    className="home-search-input home-search-input-small theme"
                    isInHomePage={true}
                    defaultValue={licenseNumber}
                  />
                  {optionsWatch === 'tollInvoice' ? (
                    <InvoiceNumber />
                  ) : optionsWatch === 'uniformCitation' ? (
                    <DocumentId />
                  ) : (
                    <PostalCode className="ml-0 mt-6 md:ml-6" isInHomePage />
                  )}
                  <div className="ml-0 mt-4 md:ml-3 md:mt-14 md:self-center">
                    <button
                      type="button"
                      onClick={() => {
                        setShowModal(true);
                      }}
                      className="green-link ml-auto mr-auto cursor-pointer"
                    >
                      <Trans>Where do I find this?</Trans>
                    </button>
                  </div>
                </div>
                {showError && (
                  <div className="text-red mt-5 text-left">
                    <Trans>We were unable to locate your invoice. Please check your entries and try again.</Trans>
                  </div>
                )}
                <div className="mt-5 grid w-full grid-flow-row justify-start sm:text-left md:grid-flow-col md:justify-start md:space-x-4">
                  <CustomButton content="Look Up" type="submit" isPrimary={false} className="pb-8 pt-10" id="Look up" />
                  <button
                    onClick={clearForm}
                    className="green-link mt-3 cursor-pointer text-left sm:flex md:mt-0 md:self-center"
                    type="button"
                  >
                    <Trans>Clear</Trans>
                  </button>
                </div>
              </Form>
            </FormProvider>
          </div>
        </div>
        {isDataCorrect ? (
          <InvoiceInfoSection
            options={selectedOption}
            invoiceNumber={savedInvoiceNumber}
            lookupWithoutInvoice={lookupWithoutInvoiceData?.lookupWithoutInvoice}
            invoiceDetails={invoiceDetails}
          />
        ) : null}
      </div>
      {isDataCorrect && (
        <div className="payment-section">
          <NewPaymentMethodOnboardingWrapper isInHomePage={true} invoiceDetails={invoiceDetails} />
        </div>
      )}
    </>
  );
};

export default PayATollInvoiceOrTrafficCitation;

import { FC } from 'react';
import { ITransactionActivity } from 'src/graphql/transactionsActivity.graphql';
import { addPlusToBalanceIfPositive, convertPriceToString } from 'src/utils/formatter';
import { formatDate, formatTime } from '../../../../utils/time.utils';

interface ITranspondersActivityItemBigScreenProps {
  activity: ITransactionActivity;
}

const TranspondersActivityItemBigScreen: FC<ITranspondersActivityItemBigScreenProps> = ({ activity }) => {
  const { transactionDate, exitTime, exitPlazaName, tagOrPlateNumber, amount } = activity;

  return (
    <li className="mb-3 flex flex-row justify-between rounded-lg bg-white px-8 py-6 shadow-lg">
      <div className="w-2/12">{formatDate(transactionDate, true)}</div>
      <div className="w-2/12">{formatTime(exitTime)}</div>
      <div className="w-4/12">{exitPlazaName}</div>
      <div className="w-2/12">{tagOrPlateNumber ? `T#${tagOrPlateNumber}` : '-'}</div>
      <div className={`${amount >= 0 ? 'text-black' : 'text-red'} w-1/12 text-right font-bold`}>
        {addPlusToBalanceIfPositive(convertPriceToString(amount, false))}
      </div>
    </li>
  );
};

export default TranspondersActivityItemBigScreen;

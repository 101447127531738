import { Trans } from '@lingui/macro';
import React from 'react';
import { LabelValue } from '../../../../types/LabelValue.interface';
import { CheckboxOptionType } from 'antd/lib/checkbox/Group';

export interface ITransactionType {
  element: JSX.Element;
  key: string;
}

export const TRANSACTION_TYPES: CheckboxOptionType[] = [
  {
    label: <Trans key="tollTransactions">Toll Transactions</Trans>,
    value: 'Toll Transactions',
  },
  {
    label: <Trans key="tollAdustments">Toll Adustments</Trans>,
    value: 'Toll Adustments',
  },
  { label: <Trans key="parking">Parking</Trans>, value: 'Parking' },
  {
    label: <Trans key="statementsFee">Statement Fee</Trans>,
    value: 'Statement Fee',
  },
  { label: <Trans key="payments">Payments</Trans>, value: 'Payments' },
  { label: <Trans key="refunds">Refunds</Trans>, value: 'Refunds' },
  { label: <Trans key="salesTax">Sales Tax</Trans>, value: 'Sales Tax' },
  { label: <Trans key="nsfFree">NSF Fee</Trans>, value: 'NSF Fee' },
];

export const AGENCIES: LabelValue[] = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: 'Florida Department of Transportation',
    value: 'Florida Department of Transportation',
  },
  {
    label: 'Tampa-Hillsborough Expressway Authority (THEA)',
    value: 'Tampa-Hillsborough Expressway Authority (THEA)',
  },
  {
    label: 'Miami-Dade Expressway Authority (MDX)',
    value: 'Miami-Dade Expressway Authority (MDX)',
  },
];

export const splitActivity = (act: string) => {
  return act.split('').map((item) => {
    if (item === '/') {
      return ' ' + item + ' ';
    } else return item;
  });
};

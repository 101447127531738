import React from 'react';
import { BulkUploadTemplateLink, BulkUploadType } from '../Links/BulkUploadTemplateLink';
import { useDashboardTabBulkUpload } from '../../hooks/domain/dashboard/useDashboardTabBulkUpload';
import { CustomButton } from '../CustomButton/CustomButton';
import { TRANSPONDERS_LIST_HEADER } from './references';

interface IDashboardTabHeader {
  headline: JSX.Element | string;
  templateUploadButtonName: JSX.Element | string;
  bulkUploadLinkName: JSX.Element | string;
  type: BulkUploadType;
}

export const DashboardTabHeader = ({
  headline,
  templateUploadButtonName,
  bulkUploadLinkName,
  type,
}: IDashboardTabHeader) => {
  const { handleFileChange, modal } = useDashboardTabBulkUpload(type);

  const bulkUploadInput = document.getElementById('upload');

  return (
    <div className="mb-8 flex w-full flex-col flex-wrap justify-between md:flex-row">
      <div className="flex w-full flex-col flex-wrap items-start justify-between xsm:flex-row md:w-auto">
        <h1 id={TRANSPONDERS_LIST_HEADER} className="mb-12 mr-12 text-primary-blue1 sm:mb-auto">
          {headline}
        </h1>
        <CustomButton
          onClick={() => {
            if (bulkUploadInput) bulkUploadInput.click();
          }}
          content={
            <>
              <input
                type="file"
                id="upload"
                hidden
                onChange={handleFileChange}
                accept="text/plain, text/csv, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
              <label
                htmlFor="upload"
                className="button-primary pt-0F.5 btn-font-sm my-0 w-full cursor-pointer xsm:w-auto sm:my-auto"
                aria-label="Bulk Upload"
              >
                {bulkUploadLinkName}
              </label>
            </>
          }
        />
      </div>
      <BulkUploadTemplateLink type={type} name={templateUploadButtonName} />
      {modal}
    </div>
  );
};

import { Trans } from '@lingui/macro';
import { CustomButton } from '../../CustomButton/CustomButton';

const PaperInvoiceHelp = () => {
  const goTo = () => {
    window.location.replace('http://sunpass.mythic.sh/contact-us');
  };

  return (
    <div className="mt-10 w-full rounded-xl bg-primary-white py-10 text-left shadow-md">
      <h2 className="mt-5 px-10 text-center text-xl">
        <Trans>Did you receive a paper invoice in the mail despite having a SunPass account?</Trans>
      </h2>
      <div className="paper-invoice-help-text ml-auto mr-auto">
        <Trans>
          Please contact a customer service representative today in order to resolve your toll at a discounted rate.
        </Trans>
      </div>
      <CustomButton className="ml-auto mr-auto mt-5" content="Contact Us" onClick={goTo} />
    </div>
  );
};

export default PaperInvoiceHelp;

import React, { useMemo, useState } from 'react';
import { t } from '@lingui/macro';
import CollapsableContainer from '../../CollapsableContainer/CollapsableContainer';
import { useFormContext } from 'react-hook-form';
import { ISecurityInfo } from '../../../types/onboarding.types';
import { GET_ACCOUNT_DETAILS, IAccountDetails } from '../../../graphql/accountDetails.graphql';
import { SecurityQuestionsSectionGeneric } from '../../PrivacyAndSecurity/SecurityQuestionsSectionGeneric';
import { useQuery } from '@apollo/client';

const SecurityQuestionsSection = () => {
  const { data } = useQuery<{ accountDetails: IAccountDetails }>(GET_ACCOUNT_DETAILS, {
    fetchPolicy: 'cache-and-network',
  });
  const securityQuestions = data?.accountDetails.securityQuestions;
  const [securityQuestionsSectionCollapsed, setSecurityQuestionsSectionCollapsed] = useState<boolean>(false);
  const { watch } = useFormContext<ISecurityInfo>();

  const firstSecurityQuestionWatch = watch('firstSecurityQuestion');
  const firstSecurityQuestionAnswerWatch = watch('firstSecurityQuestionAnswer');
  const secondSecurityQuestionWatch = watch('secondSecurityQuestion');
  const secondSecurityQuestionAnswerWatch = watch('secondSecurityQuestionAnswer');
  const thirdSecurityQuestionWatch = watch('thirdSecurityQuestion');
  const thirdSecurityQuestionAnswerWatch = watch('thirdSecurityQuestionAnswer');

  const isFormSectionDirty = useMemo(() => {
    return (
      firstSecurityQuestionWatch !== securityQuestions?.firstQuestion ||
      firstSecurityQuestionAnswerWatch !== securityQuestions?.firstAnswer ||
      secondSecurityQuestionWatch !== securityQuestions?.secondQuestion ||
      secondSecurityQuestionAnswerWatch !== securityQuestions?.secondAnswer ||
      thirdSecurityQuestionWatch !== securityQuestions?.thirdQuestion ||
      thirdSecurityQuestionAnswerWatch !== securityQuestions?.thirdAnswer
    );
  }, [
    firstSecurityQuestionWatch,
    firstSecurityQuestionAnswerWatch,
    secondSecurityQuestionWatch,
    secondSecurityQuestionAnswerWatch,
    thirdSecurityQuestionWatch,
    thirdSecurityQuestionAnswerWatch,
  ]);

  return (
    <CollapsableContainer
      title={t`Security Questions`}
      onChange={(panels: any) => setSecurityQuestionsSectionCollapsed(!panels[0])}
      activeKey={securityQuestionsSectionCollapsed ? [] : ['1']}
      unsavedChanges={isFormSectionDirty}
      responsive
    >
      <SecurityQuestionsSectionGeneric />
    </CollapsableContainer>
  );
};

export default SecurityQuestionsSection;

import React, { FC, useState } from 'react';
import { formatDate, formatTime } from '../../../../utils/time.utils';
import { ITransactionActivity } from '../../../../graphql/transactionsActivity.graphql';
import { addPlusToBalanceIfPositive, convertPriceToString } from 'src/utils/formatter';
import ActivityDetails from './ActivityDetails';

interface IActivityItemSmallScreenProps {
  transactionActivity: ITransactionActivity;
  visibleActivityIndex: string;
  setVisibleActivityIndex: React.Dispatch<React.SetStateAction<string>>;
}

const ActivityItemSmallScreen: FC<IActivityItemSmallScreenProps> = ({
  transactionActivity,
  visibleActivityIndex,
  setVisibleActivityIndex,
}) => {
  const { transactionDate, exitTime, activity, amount, exitPlaza, tagOrPlateNumber, transactionNumber } =
    transactionActivity;

  const [isBalancePositive] = useState(amount > 0);
  const [isBalanceNeutral] = useState(amount === 0);

  const areDetailsShown = visibleActivityIndex === transactionNumber;
  return (
    <li className={` rounded-lg bg-white shadow-lg `}>
      <button
        className={`${
          areDetailsShown ? 'hidden' : 'cursor-pointer'
        } mb-3 flex w-full cursor-auto flex-row items-center justify-between px-6 py-4`}
        onClick={() => {
          setVisibleActivityIndex(transactionNumber);
        }}
      >
        <div className="flex flex-col text-left">
          <div>
            <span>{formatDate(transactionDate, true)}</span> at <span>{formatTime(exitTime)}</span>
          </div>
          <div className="font-bold">{activity}</div>
          <div>{activity.toLowerCase() === 'toll' ? exitPlaza : 'Payments/Adjustments'}</div>
          {tagOrPlateNumber ? <div className="w-1/12">{tagOrPlateNumber}</div> : null}
        </div>

        <div className={`${isBalancePositive || isBalanceNeutral ? 'text-black' : 'text-red'} font-bold`}>
          {addPlusToBalanceIfPositive(convertPriceToString(amount, false))}
        </div>
      </button>
      {areDetailsShown ? (
        <ActivityDetails
          transactionActivity={transactionActivity}
          visibleActivityIndex={visibleActivityIndex}
          setVisibleActivityIndex={setVisibleActivityIndex}
        />
      ) : null}
    </li>
  );
};

export default ActivityItemSmallScreen;

import React, { FC, useState } from 'react';
import './PromoContent.scss';
import {
  DashboardParams,
  DRUPAL,
  MainRoutes,
  MyAccountParams,
  PARTNERS_PAGE_DRUPAL,
  SAVINGS_PAGE_DRUPAL,
} from '../../../../types/routes';
import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_PROMOTIONS, PromoType } from '../../../../graphql/promotion.graphql';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { GET_ACCOUNT_DETAILS, IAccountDetails } from '../../../../graphql/accountDetails.graphql';
import { IPaymentMethodGraphQl, PAYMENT_METHODS } from '../../../../graphql/paymentMethods.graphql';
import { PlanName, usePlans } from '../usePlans';
import { AccountType } from '../../../../types/onboarding.types';
import useScreenSize from '../../../../hooks/screen/useScreenSize';
import ScreenSize from '../../../../types/ScreenSize.enum';
import { MainLoader } from '../../../Loader/MainLoader';

const PromoContent: FC = () => {
  const { data: promotionsData, loading: promotionLoading } = useQuery(GET_PROMOTIONS, {
    variables: {
      promotionsByPromoTypeInput: {},
    },
  });

  const { data: accountData, loading: accountLoading } = useQuery<{ accountDetails: IAccountDetails }>(
    GET_ACCOUNT_DETAILS,
  );

  const { data: paymentMethodsData, loading: paymentLoading } = useQuery<{
    paymentMethodList: IPaymentMethodGraphQl[];
  }>(PAYMENT_METHODS, {
    errorPolicy: 'all',
  });

  const [currentPromotion, setCurrentPromotion] = useState(0);

  const { screenSize } = useScreenSize();

  const accountType = accountData?.accountDetails.accountType;
  const parkingOption = accountData?.accountDetails.parkingOption;

  const { PAYGAccount, GOVAccount, plansData } = usePlans();
  const isGOVAccount = !!(accountType === AccountType.COMMERCIAL && GOVAccount);
  const isPAYGAccount = accountType === AccountType.PRIVATE && PAYGAccount;

  const isOptInEasyPay = !!paymentMethodsData
    ? !!paymentMethodsData?.paymentMethodList?.find((method) => method.isEasyPay)
    : false;

  const isPrivateWithPBCAccount =
    plansData?.planList !== null
      ? accountType === AccountType.PRIVATE && plansData?.planList.find((plan) => plan.planName === PlanName.PBC)
      : false;

  const hasUserAddedPBCPlan = isPrivateWithPBCAccount ? true : isPAYGAccount;

  const removeEasyPayFromList = () => {
    if (parkingOption || accountType === AccountType.COMMERCIAL || isPAYGAccount) {
      if (hasUserAddedPBCPlan) {
        return promotionsData?.promotions.filter(
          (promo: { type: PromoType }) =>
            promo.type !== PromoType.PARKING && promo.type !== PromoType.EASY_PAY && promo.type !== PromoType.PINELLAS,
        );
      } else {
        return promotionsData?.promotions.filter(
          (promo: { type: PromoType }) => promo.type !== PromoType.PARKING && promo.type !== PromoType.EASY_PAY,
        );
      }
    } else if (hasUserAddedPBCPlan) {
      return promotionsData?.promotions.filter(
        (promo: { type: PromoType }) => promo.type !== PromoType.EASY_PAY && promo.type !== PromoType.PINELLAS,
      );
    } else {
      return promotionsData?.promotions.filter((promo: { type: PromoType }) => promo.type !== PromoType.EASY_PAY);
    }
  };

  const removeOnlyPinellasAndParking = () => {
    if (hasUserAddedPBCPlan) {
      return promotionsData?.promotions.filter(
        (promo: { type: PromoType }) => promo.type !== PromoType.PARKING && promo.type !== PromoType.PINELLAS,
      );
    } else {
      return promotionsData?.promotions.filter((promo: { type: PromoType }) => promo.type !== PromoType.PARKING);
    }
  };

  const removeOnlyPinellas = () => {
    return promotionsData?.promotions.filter((promo: { type: PromoType }) => promo.type !== PromoType.PINELLAS);
  };

  const getTargetedPromotions = () => {
    if (isOptInEasyPay || isGOVAccount) {
      return removeEasyPayFromList();
    } else if (parkingOption || accountType === AccountType.COMMERCIAL || isPAYGAccount) {
      return removeOnlyPinellasAndParking();
    } else if (hasUserAddedPBCPlan) {
      return removeOnlyPinellas();
    } else {
      return promotionsData?.promotions;
    }
  };

  const promotions = getTargetedPromotions();

  const promotionsLength = !!promotions ? promotions?.length : 0;

  const getPromotionContent = () => {
    const promoPhotoLink = promotionsData ? promotions[currentPromotion].image.desktop : ''; //TODO: will be changed in CR
    const promoText = promotionsData ? promotions[currentPromotion].body : '';
    const promoTitle = 'No title';
    const promoType = promotionsData ? promotions[currentPromotion].type : 'NONE';

    const promoLink = () => {
      switch (promoType) {
        case PromoType.EASY_PAY:
          return (
            <Link
              to={`${MainRoutes.DASHBOARD}/${DashboardParams.WALLET}`}
              className={`dashboard-links relative mt-1 inline w-20 pb-px text-center text-base tracking-tight`}
            >
              <Trans>Learn more</Trans>
            </Link>
          );
        case PromoType.PARKING:
          return (
            <Link
              to={`${MainRoutes.MY_ACCOUNT}/${MyAccountParams.PARKING}`}
              className={`dashboard-links relative mt-1 inline w-20 pb-px text-center text-base tracking-tight`}
            >
              <Trans>Learn more</Trans>
            </Link>
          );
        case PromoType.PINELLAS:
          return (
            <Link
              to={`${MainRoutes.MY_ACCOUNT}/${MyAccountParams.PINELLAS}`}
              className={`dashboard-links relative mt-1 inline w-20 pb-px text-center text-base tracking-tight`}
            >
              <Trans>Learn more</Trans>
            </Link>
          );
        case PromoType.SUNPASS_SAVINGS:
          return (
            <a
              href={SAVINGS_PAGE_DRUPAL}
              className="dashboard-links relative mt-1 inline w-20 pb-px text-center text-base tracking-tight"
              aria-label="Sunpass Savings"
              target="_blank"
              rel="noreferrer"
            >
              <span className="large-tablet:mt-8">
                <Trans>Learn more</Trans>
              </span>
            </a>
          );
        case PromoType.INTEROPERABILITY:
          return (
            <a
              href={PARTNERS_PAGE_DRUPAL}
              className="dashboard-links relative mt-1 inline w-20 pb-px text-center text-base tracking-tight"
              aria-label="Interoperability"
              target="_blank"
              rel="noreferrer"
            >
              <span className="large-tablet:mt-8">
                <Trans>Learn more</Trans>
              </span>
            </a>
          );
        case PromoType.TOLL_RELIEF_PROGRAM:
          return (
            <a
              href={DRUPAL}
              className="dashboard-links relative mt-1 inline w-20 pb-px text-center text-base tracking-tight"
              aria-label="Toll Relief Program"
              target="_blank"
              rel="noreferrer"
            >
              <span className="large-tablet:mt-8">
                <Trans>Learn more</Trans>
              </span>
            </a>
          );
      }
    };

    return (
      <div className="promo-content flex text-left sm:items-center">
        <img src={promoPhotoLink} alt="sunpass plus icon" className="sunpass-logo border-gray-300" />
        <div className="ml-0 mt-2 sm:ml-10 sm:mt-0">
          <h2 className="promo-header mb-3 mt-4">
            <Trans>{promoTitle}</Trans>
          </h2>
          <p className="promo-text">
            <Trans>{promoText}</Trans>
          </p>
          {promoLink()}
        </div>
      </div>
    );
  };

  const nextPromotion = () => {
    if (currentPromotion + 1 === promotionsLength) {
      setCurrentPromotion(0);
    } else {
      setCurrentPromotion(currentPromotion + 1);
    }
  };

  const previousPromotion = () => {
    if (currentPromotion - 1 < 0) {
      setCurrentPromotion(promotionsLength - 1);
    } else {
      setCurrentPromotion(currentPromotion - 1);
    }
  };

  return (
    <>
      <MainLoader loading={accountLoading || paymentLoading || promotionLoading} />
      {promotionsLength !== 0 ? (
        <div className="promo-section mb-10 mt-10 flex border-t border-gray-300 pt-10 sm:items-center">
          {promotionsLength > 1 ? (
            <button
              className={`previous-next-btn ${screenSize <= ScreenSize.SM ? 'mobile-left-button' : ''}`}
              onClick={previousPromotion}
            >
              <FontAwesomeIcon icon={faChevronLeft} color="white" />
            </button>
          ) : null}
          {getPromotionContent()}
          {promotionsLength > 1 ? (
            <button
              className={`previous-next-btn ${screenSize <= ScreenSize.SM ? 'mobile-right-button' : ''}`}
              onClick={nextPromotion}
            >
              <FontAwesomeIcon icon={faChevronRight} color="white" />
            </button>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default PromoContent;

import { gql } from '@apollo/client';
import { IUserNames } from './signUp/signUpDetails.graphql';

export const SEND_GOV_ONBOARDING_EMAIL = gql`
  mutation sendGovOnboardingEmail($govOnboardingEmailInput: GovOnboardingEmailInput!) {
    sendGovOnboardingEmail(govOnboardingEmailInput: $govOnboardingEmailInput)
  }
`;

export interface IGovOnboardingEmailInput extends Omit<IUserNames, 'middleName'> {
  agencyName: string;
  emailAddress: string;
  phoneNumber: string;
}

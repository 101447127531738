import { t, Trans } from '@lingui/macro';
import { Button, Space } from 'antd';
import React, { FC } from 'react';
import { IMailingAddress } from 'src/types/onboarding.types';

interface IMailingAddressProps {
  address: IMailingAddress;
  onEdit: () => void;
}

const MailingAddress: FC<IMailingAddressProps> = ({ address, onEdit }) => {
  const { addressLine1, addressLine2, city, state, postalCode, country } = address;
  return (
    <Space direction="vertical" size="small">
      <Space align="start" size="middle">
        <div className="title">
          <Trans>Mailing Address</Trans>
        </div>
        <Button type="link" onClick={() => onEdit()} className="theme" aria-label={t`Edit Mailing Address`}>
          <span>
            <Trans>Edit</Trans>
          </span>
        </Button>
      </Space>
      <div>
        <div>{addressLine1}</div>
        {addressLine2 && <div>{addressLine2}</div>}
        <div>{`${city}, ${state}`}</div>
        <div>{`${postalCode}, ${country}`}</div>
      </div>
    </Space>
  );
};

export default MailingAddress;

import { i18n } from '@lingui/core';

export const TRANSPONDER_NUMBER_MATCH_ERROR_MSG = i18n._('Transponder numbers must match');
export const TRANSPONDER_QUANTITY_ERROR_MSG = i18n._('Amount must be a whole number');
export const TRANSPONDER_NUMBER_REQUIRED_ERROR_MSG = i18n._('Transponder number is required');
export const TRANSPONDER_NUMBER_DIGITS_ONLY_ERROR_MSG = i18n._('Transponder number must contain only digits');
export const TRANSPONDER_NUMBER_LENGTH_ERROR_MSG = i18n._('9 digit minimum; 13 digit maximum');
export const TRANSPONDER_TYPE_REQUIRED_ERROR_MSG = i18n._('Transponder type is required');
export const TRANSPONDER_OR_PLATE_NUMBER_ERROR_MSG = i18n._('13 Character Maximum');
export const END_DATE_ERROR_MSG = i18n._('Please make sure your end date is after your start date');
export const MIN_DATE_ERROR_MSG = i18n._('Please make sure your end date/time is after your start date/time');

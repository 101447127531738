import { useMutation, useQuery } from '@apollo/client';
import { GET_ACCOUNT_DETAILS, IAccountDetails } from '../../../graphql/accountDetails.graphql';
import { useEffect, useState } from 'react';
import { useAccountSettings } from '../../../store/account/accountSettings.store';
import { useForm } from 'react-hook-form';
import { ISecurityInfo } from '../../../types/onboarding.types';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import {
  securityQuestionsValidator,
  usernamePasswordsValidator,
} from '../../../validators/onboarding/accountInfo/accountInfo.validator';
import {
  generatePrivacySettingsDefaultValues,
  PRIVACY_AND_SECURITY_SETTINGS_DEFAULT_VALUES,
} from '../../../components/MyAccount/PrivacyAndSecuritySettings/initialState';
import { useScroll } from '../../screen/useScroll';
import { IAccountSettingsUpdateInput, UPDATE_ACCOUNT_SETTINGS } from '../../../graphql/accountSettings.graphql';
import { getChangedProperties } from '../../../utils/object.utils';
import { mapAccountDetailsToUpdateInput } from '../../../components/MyAccount/PrivacyAndSecuritySettings/mapper';
import { showMessage } from '../../../utils/message.utils';

export const usePrivacyAndSecurity = () => {
  const { data, loading: accountDetailsLoading } = useQuery<{ accountDetails: IAccountDetails }>(GET_ACCOUNT_DETAILS);
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [isErrorModalShown, setIsErrorModalShown] = useState<boolean>(true);
  const [isUpdatePasswordMode, setUpdatePasswordMode] = useState(false);
  const [isModalShown, setIsModalShown] = useState(false);
  const { setIsTabDirty } = useAccountSettings();
  const methods = useForm<ISecurityInfo>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        ...usernamePasswordsValidator(data?.accountDetails.accountType, isUpdatePasswordMode),
        ...securityQuestionsValidator(data?.accountDetails.accountType),
      }),
    ),
    defaultValues:
      !data || !isUpdatePasswordMode
        ? PRIVACY_AND_SECURITY_SETTINGS_DEFAULT_VALUES
        : generatePrivacySettingsDefaultValues(data?.accountDetails),
  });

  const {
    getValues,
    handleSubmit,
    watch,
    reset,
    resetField,
    clearErrors,
    formState: { isSubmitSuccessful, errors, isDirty },
  } = methods;

  const { scrollToError } = useScroll();

  useEffect(() => {
    scrollToError(errors);
  }, [errors]);

  useEffect(() => {
    isDirty ? setIsTabDirty(true) : setIsTabDirty(false);
  }, [isDirty]);

  useEffect(() => {
    setIsErrorModalShown(accountDetailsLoading);
  }, [accountDetailsLoading]);

  const resetValuesToDefault = () => {
    reset(
      data ? generatePrivacySettingsDefaultValues(data?.accountDetails) : PRIVACY_AND_SECURITY_SETTINGS_DEFAULT_VALUES,
    );
  };

  useEffect(() => {
    resetValuesToDefault();
  }, [data]);

  const [updateAccountSettings, { loading: updateLoading }] = useMutation<
    { updateResult: boolean },
    { accountSettingsUpdateInput: IAccountSettingsUpdateInput }
  >(UPDATE_ACCOUNT_SETTINGS, {
    refetchQueries: [
      {
        query: GET_ACCOUNT_DETAILS,
      },
    ],
  });

  const securityQuestionsForApiRequest = {
    firstQuestion: watch('firstSecurityQuestion') || '',
    firstAnswer: watch('firstSecurityQuestionAnswer') || '',
    secondQuestion: watch('secondSecurityQuestion') || '',
    secondAnswer: watch('secondSecurityQuestionAnswer') || '',
    thirdQuestion: watch('thirdSecurityQuestion') || '',
    thirdAnswer: watch('thirdSecurityQuestionAnswer') || '',
  };

  const updatePasswordAndSecurityQuestions = async () => {
    try {
      await updateAccountSettings({
        variables: {
          accountSettingsUpdateInput: getChangedProperties<IAccountSettingsUpdateInput>(
            mapAccountDetailsToUpdateInput(data?.accountDetails),
            {
              currentPassword: watch('oldPassword'),
              newPassword: watch('password'),
              retypeNewPassword: watch('reTypePassword'),
              securityQuestions: securityQuestionsForApiRequest,
            },
          ),
        },
      });
      setUpdatePasswordMode(false);
      showMessage('success');
      reset();
    } catch (err) {
      showMessage('error');
    }
  };

  const updateSecurityQuestions = async () => {
    try {
      await updateAccountSettings({
        variables: {
          accountSettingsUpdateInput: getChangedProperties<IAccountSettingsUpdateInput>(
            mapAccountDetailsToUpdateInput(data?.accountDetails),
            {
              userName: watch('username') || '',
              securityQuestions: securityQuestionsForApiRequest,
            },
          ),
        },
      });
      setIsSubmitError(false);
      showMessage('success');
      reset();
    } catch (err) {
      showMessage('error');
    }
  };

  const onSubmit = async () => {
    if (isUpdatePasswordMode) {
      await updatePasswordAndSecurityQuestions();
    } else {
      await updateSecurityQuestions();
    }
  };

  const toggleUpdatePasswordMode = () => {
    if (!isModalShown && (getValues('password') || getValues('oldPassword') || getValues('reTypePassword'))) {
      setIsModalShown(true);
    } else {
      setUpdatePasswordMode((prevState) => !prevState);
      resetField('password');
      resetField('reTypePassword');
      resetField('oldPassword');
      clearErrors();
      setIsModalShown(false);
    }
  };

  return {
    methods,
    formState: {
      loading: accountDetailsLoading || updateLoading,
      isFormDirty: isDirty && !isSubmitError,
      isErrorModalShown,
      isUpdatePasswordMode,
      isSubmitSuccessful,
      isModalShown,
    },
    onSubmit: handleSubmit(onSubmit),
    toggleUpdatePasswordMode,
    closeErrorModal: () => setIsErrorModalShown(false),
    closeConfirmationModal: () => setIsModalShown(false),
  };
};

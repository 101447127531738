import { useLazyQuery, useMutation } from '@apollo/client';
import {
  ISignUpTransponderActivationInput,
  ISignUpTransponderActivationOutput,
  SIGN_UP_TRANSPONDER_ACTIVATION,
} from 'src/graphql/signUp/signUpTransponderActivation.graphql';
import { IVehicleSignUpInput } from 'src/graphql/signUp/signUpVehiclesTransponders.graphql';
import { useOnboardingErrors } from 'src/store/errors/onboardingErrors.store';
import {
  IValidateTransponderNumberInput,
  IValidateTransponderNumberOutput,
  VALIDATE_TRANSPONDER_NUMBER,
} from '../../../graphql/validateTransponderNumber.graphql';
import { useOnboardingStore } from '../../../store/onboarding/onboarding.store';
import { TransponderType } from '../../../graphql/transponders.graphql';
interface IActivateCalls {
  validateTranponders: (transponderNumbers: string[]) => void;
  validateVehicles: (vehicles: IVehicleSignUpInput[]) => void;
  activationTranspondersLoading: boolean;
  validateTransponderNumberLoading: boolean;
}
export const ActivateCalls = (plansAddedToTranspondersInActivateFlow?: number): IActivateCalls => {
  const { onboardingState } = useOnboardingErrors();
  const {
    onboardingInfo: { miniTransponderQty, proTransponderQty },
    updateOnboardingStore,
  } = useOnboardingStore();

  const [signUpTransponderActivation, { loading: activationTranspondersLoading }] = useMutation<
    { signUpTransponderActivation: ISignUpTransponderActivationOutput },
    { signUpTransponderActivationInput: ISignUpTransponderActivationInput }
  >(SIGN_UP_TRANSPONDER_ACTIVATION);

  const [validateTransponderNumber, { loading: validateTransponderNumberLoading }] = useLazyQuery<
    { validateTransponderNumber: IValidateTransponderNumberOutput },
    { validateTransponderNumberInput: IValidateTransponderNumberInput }
  >(VALIDATE_TRANSPONDER_NUMBER);

  const onSuccess = (responseData: any) => {
    if (responseData) {
      if (responseData?.validateTransponderNumber.transponderType) {
        if (responseData?.validateTransponderNumber.transponderType === 'STK') {
          updateOnboardingStore({
            miniTransponderQty: miniTransponderQty + 1,
          });
        } else if (responseData?.validateTransponderNumber.transponderType === TransponderType.PRO) {
          updateOnboardingStore({
            proTransponderQty: proTransponderQty + 1,
          });
        }
      }
    }
  };

  const validateTranponders = async (transponderNumbers: string[]) => {
    if (transponderNumbers) {
      try {
        for (const transponderNumber of transponderNumbers) {
          await validateTransponderNumber({
            variables: {
              validateTransponderNumberInput: {
                transponderNumber: transponderNumber,
              },
            },
            onCompleted: onSuccess,
          });
        }
      } catch (err) {
        console.log(err);
      }
      updateOnboardingStore({
        miniTransponderQtyAssignedToPlan: plansAddedToTranspondersInActivateFlow,
        proTransponderQtyAssignedToPlan: 0,
      });
    }
    if (onboardingState?.accountDetailsSignUp) {
      try {
        await signUpTransponderActivation({
          variables: {
            signUpTransponderActivationInput: { ...onboardingState, transponderNumbers },
          },
        });
      } catch (err) {
        console.log(err);
      }
    }
  };
  const validateVehicles = async (vehicles: IVehicleSignUpInput[]) => {
    if (onboardingState?.accountDetailsSignUp && onboardingState?.transponderNumbers) {
      try {
        await signUpTransponderActivation({
          variables: {
            signUpTransponderActivationInput: { ...onboardingState, vehicles },
          },
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  return { validateTranponders, validateVehicles, activationTranspondersLoading, validateTransponderNumberLoading };
};

import { Trans } from '@lingui/macro';
import React from 'react';
import { ILinkedAccount } from '../../../../graphql/linkedAccounts.graphql';
import { MainLoader } from '../../../Loader/MainLoader';
import { useLinkedAccounts } from '../../../../hooks/useLinkedAccounts';
import { CustomButton } from '../../../CustomButton/CustomButton';

interface IPendingRequestItemProps {
  account: ILinkedAccount;
}

export const PendingRequestItem = ({ account }: IPendingRequestItemProps) => {
  const { approveParentAccountAccess, denyParentAccountAccess, loading } = useLinkedAccounts();
  return (
    <>
      <MainLoader loading={loading} />
      <div className="mb-7 mt-3 flex flex-row items-center ">
        <span className="mt-1 flex-1 break-words">{account?.owner}</span>
        <div className="flex flex-1 items-center gap-4">
          <CustomButton
            content={<Trans>Approve</Trans>}
            onClick={() => approveParentAccountAccess(account.accountNumber)}
          ></CustomButton>
          <button
            className="mb-0 text-primary-red underline"
            onClick={() => denyParentAccountAccess(account.accountNumber)}
          >
            <Trans>Decline</Trans>
          </button>
        </div>
      </div>
    </>
  );
};

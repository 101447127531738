import React, { useContext, useMemo } from 'react';
import { NotificationType } from '../../graphql/notifications.graphql';
import { Trans } from '@lingui/macro';
import { concatRoutes, DashboardParams, MainRoutes } from '../../types/routes';
import {
  BALANCE_SECTION_ID,
  EASY_PAY_SECTION_ID,
  PAYMENT_METHODS_SECTION_ID,
} from '../../components/Dashboard/references';
import { useNavigate } from 'react-router-dom';
import { useScroll } from '../screen/useScroll';
import { useLinkedAccounts } from '../useLinkedAccounts';
import { INotificationItemProps } from '../../components/Alerts/NotificationItem';
import { NotificationContext } from '../../context/NotificationContext';
import { convertPriceToString } from '../../utils/formatter';
import { formatDateForNotification } from '../../utils/time.utils';

type INotificationItem = {
  title: JSX.Element | string;
  content: JSX.Element | string;
  actionName: JSX.Element | string;
  action: () => void;
};

export const useNotifications = ({ id, values, type }: INotificationItemProps) => {
  const navigate = useNavigate();
  const { scrollToElem } = useScroll();
  const { approveParentAccountAccess } = useLinkedAccounts(true);
  const notificationCtx = useContext(NotificationContext);
  const { dismiss } = notificationCtx || {};
  const notificationItem = useMemo((): INotificationItem | undefined => {
    switch (type) {
      case NotificationType.LOW_BALANCE:
        return {
          title: <Trans>Your balance is running low!</Trans>,
          content: <Trans>Your current balance is {convertPriceToString(Number.parseFloat(values[0]))}.</Trans>,
          actionName: <Trans>Add Funds</Trans>,
          action: () => {
            navigate({ pathname: concatRoutes(MainRoutes.DASHBOARD, '/', DashboardParams.WALLET) });
            setTimeout(() => scrollToElem(BALANCE_SECTION_ID), 100);
          },
        };
      case NotificationType.EXPIRED_PAYMENT_METHOD:
        return {
          title: <Trans>Your payment method has expired!</Trans>,
          content: <Trans>Add a new payment method to avoid disruption to service.</Trans>,
          actionName: <Trans>Add Payment Method</Trans>,
          action: () => {
            navigate({ pathname: concatRoutes(MainRoutes.DASHBOARD, '/', DashboardParams.WALLET) });
            setTimeout(() => scrollToElem(PAYMENT_METHODS_SECTION_ID), 100);
          },
        };
      case NotificationType.UPCOMING_PAYMENT_EXPIRATION:
        return {
          title: <Trans>Your payment method is approaching expiration!</Trans>,
          content: <Trans>Update payment information to avoid disruption to service.</Trans>,
          actionName: <Trans>Update Payment Method</Trans>,
          action: () => {
            navigate({ pathname: concatRoutes(MainRoutes.DASHBOARD, '/', DashboardParams.WALLET) });
            setTimeout(() => scrollToElem(PAYMENT_METHODS_SECTION_ID), 100);
          },
        };
      case NotificationType.NEGATIVE_BALANCE:
        return {
          title: <Trans>You have a negative balance!</Trans>,
          content: (
            <Trans>
              You currently owe {convertPriceToString(Math.abs(Number.parseFloat(values[0])))} in unpaid tolls.
            </Trans>
          ),
          actionName: <Trans>Add Funds</Trans>,
          action: () => {
            navigate({ pathname: concatRoutes(MainRoutes.DASHBOARD, '/', DashboardParams.WALLET) });
            setTimeout(() => scrollToElem(BALANCE_SECTION_ID), 100);
          },
        };
      case NotificationType.RECENT_REPLENISHMENT:
        return {
          title: <Trans>Your account balance has been replenished!</Trans>,
          content: (
            <Trans>
              Your replenishment amount of {convertPriceToString(Number.parseFloat(values[0]))} was added to your
              account balance on {formatDateForNotification(values[1])}.
            </Trans>
          ),
          actionName: <Trans>View E-Z Pay Settings</Trans>,
          action: () => {
            navigate({ pathname: concatRoutes(MainRoutes.DASHBOARD, '/', DashboardParams.WALLET) });
            setTimeout(() => scrollToElem(EASY_PAY_SECTION_ID), 100);
          },
        };
      case NotificationType.APPROACH_REPLENISHMENT_THRESHOLD:
        return {
          title: <Trans>Your account balance is approaching replenishment!</Trans>,
          content: (
            <Trans>
              Your account balance is currently set to replenish when the balance reaches{' '}
              {convertPriceToString(Number.parseFloat(values[0]))}.
            </Trans>
          ),
          actionName: <Trans>View E-Z Pay Settings</Trans>,
          action: () => {
            navigate({ pathname: concatRoutes(MainRoutes.DASHBOARD, '/', DashboardParams.WALLET) });
            setTimeout(() => scrollToElem(EASY_PAY_SECTION_ID), 100);
          },
        };
      case NotificationType.LINKED_ACCOUNT_REQUEST:
        return {
          title: <Trans>You have a linked account request!</Trans>,
          content: <Trans>{values[0]} would like access to your account. Would you like to accept?</Trans>,
          actionName: <Trans>Accept Request</Trans>,
          action: async () => {
            await approveParentAccountAccess(values[1]);
            dismiss && dismiss(id);
          },
        };
      default:
        break;
    }
  }, [type, values]);
  return {
    notificationItem,
  };
};

import MailingAddressFormField from 'src/components/Address/AddressFormField.enum';
import { IPaymentMethodGraphQl } from 'src/graphql/paymentMethods.graphql';
import { FL_STATE_CODE, USA_COUNTRY_ISO3 } from 'src/utils/countryStates.utils';
import PaymentMethodFormField, { PaymentMethodType } from './Payments.enums';
import { IPaymentMethod, IPaymentMethodForm } from './Payments.interfaces';

export const onboardingDefaultValues = (paymentMethod: IPaymentMethod | undefined): Partial<IPaymentMethodForm> => {
  return paymentMethod ? paymentMethod : { ...defaultPaymentValues, [PaymentMethodFormField.SAVE_CARD]: true };
};

export const multiStepPaymentDefaultValues = (
  paymentMethod: IPaymentMethod | undefined,
): Partial<IPaymentMethodForm> => {
  return paymentMethod
    ? { ...paymentMethod, bankAccountNumberConfirm: paymentMethod.bankAccountNumber }
    : defaultPaymentValues;
};

export const editDefaultPaymentMethodValues = (
  paymentMethodToEdit: IPaymentMethodGraphQl | null,
  isEditMode: boolean,
) => {
  return (
    (paymentMethodToEdit &&
      isEditMode && {
        [PaymentMethodFormField.PAYMENT_TYPE]: paymentMethodToEdit.isBankAccount
          ? PaymentMethodType.BANK
          : PaymentMethodType.CARD,
        [PaymentMethodFormField.CC_FIRST_NAME]: paymentMethodToEdit.firstName,
        [PaymentMethodFormField.CC_MIDDLE_NAME]: paymentMethodToEdit.middleName,
        [PaymentMethodFormField.CC_LAST_NAME]: paymentMethodToEdit.lastName,
        [PaymentMethodFormField.BANK_FIRST_NAME]: paymentMethodToEdit.firstName,
        [PaymentMethodFormField.BANK_LAST_NAME]: paymentMethodToEdit.lastName,
        [PaymentMethodFormField.CC_NUMBER]: paymentMethodToEdit.cardNumber,
        [PaymentMethodFormField.CC_EXPIRATION_MONTH]: paymentMethodToEdit.expirationMonth,
        [PaymentMethodFormField.CC_EXPIRATION_YEAR]: paymentMethodToEdit.expirationYear,
        [PaymentMethodFormField.BANK_ACCOUNT_NUMBER]: paymentMethodToEdit.bankAccountNumber,
        [PaymentMethodFormField.BANK_ACCOUNT_NUMBER_CONFIRM]: paymentMethodToEdit.bankAccountNumber,
        [PaymentMethodFormField.BANK_ROUTING_NUMBER]: paymentMethodToEdit.bankRoutingNumber,
        [PaymentMethodFormField.IS_PRIMARY]: paymentMethodToEdit.isPrimary,
        [PaymentMethodFormField.IS_EASY_PAY]: paymentMethodToEdit.isEasyPay,
        [MailingAddressFormField.ADDRESS_LINE_1]: paymentMethodToEdit.addressLine1,
        [MailingAddressFormField.ADDRESS_LINE_2]: paymentMethodToEdit.addressLine2,
        [MailingAddressFormField.CITY]: paymentMethodToEdit.city,
        [MailingAddressFormField.POSTAL_CODE]: paymentMethodToEdit.zipCode,
        [MailingAddressFormField.STATE]: paymentMethodToEdit.state,
        [MailingAddressFormField.COUNTRY]: paymentMethodToEdit.country,
      }) ||
    defaultPaymentValues
  );
};

export const defaultPaymentValues: Partial<IPaymentMethod> = {
  [PaymentMethodFormField.IS_PRIMARY]: true,
  [PaymentMethodFormField.PAYMENT_TYPE]: PaymentMethodType.CARD,
  [MailingAddressFormField.STATE]: FL_STATE_CODE,
  [MailingAddressFormField.COUNTRY]: USA_COUNTRY_ISO3,
};

import { gql } from '@apollo/client';
import { SunpassPlusParking } from './transponderPlusParkingList.graphql';

export const UPDATE_TRANSPONDER_PLUS_PARKING = gql`
  mutation updateTransponderPlusParkingList(
    $updateTransponderPlusParkingListInput: UpdateTransponderPlusParkingListInput!
  ) {
    updateTransponderPlusParkingList(updateTransponderPlusParkingListInput: $updateTransponderPlusParkingListInput)
  }
`;

export interface IUpdateTransponderPlusParkingListInput {
  optInForAll: boolean;
  optOutForAll: boolean;
  transpondersPlusParking: [IUpdateTransponderPlusParkingInput];
}
interface IUpdateTransponderPlusParkingInput {
  transponderNumber: string;
  sunpassPlusParking: SunpassPlusParking;
}

import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { t, Trans } from '@lingui/macro';
import { Checkbox, Form, Input } from 'antd';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useOnboardingStore } from 'src/store/onboarding/onboarding.store';
import { AccountSubtype, OnboardingFlowType } from 'src/types/onboarding.types';
import { PinellasBaywayModal } from '../Modals/ConfirmationModal/PinellasBaywayModal';

interface ITransponderFormProps {
  setPlansAddedToTranspondersInActivateFlow: React.Dispatch<React.SetStateAction<number>>;
  plansAddedToTranspondersInActivateFlow: number;
}

export const TransponderForm = ({
  setPlansAddedToTranspondersInActivateFlow,
  plansAddedToTranspondersInActivateFlow,
}: ITransponderFormProps) => {
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext();

  const {
    onboardingInfo: { onboardingFlowType },
  } = useOnboardingStore();

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'transpondersToActivate',
  });

  const [listInitialized, setListInitialized] = useState(false);
  const [openPBCModal, setOpenPBCModal] = useState(false);

  useEffect(() => {
    if (fields.length === 0 && !listInitialized) {
      append({
        number: undefined,
        type: undefined,
        pinellas: hasPinellasPlan ? true : undefined,
      });
      setListInitialized(true);
    }
  }, []);

  const {
    onboardingInfo: { accountSubtype },
  } = useOnboardingStore();

  const hasPinellasPlan = accountSubtype === AccountSubtype.PBC;

  return (
    <div className="w-full">
      {fields.map((field, index) => {
        return (
          <div key={field.id}>
            <div className="mt-2 md:space-x-16">
              <div className="md:flex md:space-x-16">
                <Form.Item
                  {...field}
                  hasFeedback
                  validateStatus={
                    (errors?.transpondersToActivate?.[index]?.number?.message as unknown as string) ? 'error' : ''
                  }
                  label={<Trans>Transponder Number</Trans>}
                  className="override-to-column theme w-full md:w-5/12"
                  required
                >
                  <Controller
                    control={control}
                    name={`transpondersToActivate[${index}].number`}
                    render={({ field, fieldState }) => (
                      <Input
                        {...field}
                        className={classNames({
                          'input-error': fieldState.invalid,
                          'transponder-number': true,
                        })}
                        onChange={(event) => {
                          field.onChange(event);
                          trigger([
                            `transpondersToActivate[${index}].number`,
                            `transpondersToActivate[${index}].confirmNumber`,
                          ]);
                        }}
                        name={`transpondersToActivate[${index}].number`}
                        key={`transpondersToActivate[${index}].number`}
                        id={`transpondersToActivate[${index}].number`}
                        minLength={9}
                        maxLength={13}
                        aria-label={t`Transponder number`}
                      />
                    )}
                  />
                  <div className="form-error">
                    {errors?.transpondersToActivate?.[index]?.number?.message as unknown as string}
                  </div>
                </Form.Item>
                <Form.Item
                  {...field}
                  hasFeedback
                  validateStatus={
                    (errors?.transpondersToActivate?.[index]?.confirmNumber?.message as unknown as string)
                      ? 'error'
                      : ''
                  }
                  label={<Trans>Confirm Transponder Number</Trans>}
                  className="override-to-column theme w-full md:w-5/12"
                  required
                >
                  <Controller
                    control={control}
                    name={`transpondersToActivate[${index}].confirmNumber`}
                    render={({ field, fieldState }) => (
                      <Input
                        {...field}
                        className={classNames({
                          'input-error': fieldState.invalid,
                          'transponder-number': true,
                        })}
                        onChange={(event) => {
                          field.onChange(event);
                        }}
                        name={`transpondersToActivate[${index}].confirmNumber`}
                        key={`transpondersToActivate[${index}].confirmNumber`}
                        id={`transpondersToActivate[${index}].confirmNumber`}
                        maxLength={13}
                        aria-label={t`Confirm transponder number`}
                      />
                    )}
                  />
                  <div className="form-error">
                    {errors?.transpondersToActivate?.[index]?.confirmNumber?.message as unknown as string}
                  </div>
                </Form.Item>
              </div>
            </div>
            {onboardingFlowType === OnboardingFlowType.PREPAID ? (
              <div className="flex">
                <Form.Item {...field}>
                  <Controller
                    control={control}
                    name={`transpondersToActivate[${index}].pinellas`}
                    render={({ field, fieldState }) => (
                      <Checkbox
                        {...field}
                        className={classNames({ 'input-error': fieldState.invalid })}
                        onChange={(event) => {
                          if (event.target.checked) {
                            setPlansAddedToTranspondersInActivateFlow(plansAddedToTranspondersInActivateFlow + 1);
                          } else {
                            if (plansAddedToTranspondersInActivateFlow !== 0) {
                              setPlansAddedToTranspondersInActivateFlow(plansAddedToTranspondersInActivateFlow - 1);
                            }
                          }
                          field.onChange(event);
                        }}
                        defaultChecked={index === 0}
                        name={`transpondersToActivate[${index}].pinellas`}
                        key={`transpondersToActivate[${index}].pinellas`}
                        id={`transpondersToActivate[${index}].pinellas`}
                        aria-label={t`Add the Pinellas Bayway Commuter Plan to this transponder.`}
                      >
                        Add the Pinellas Bayway Commuter Plan to this transponder.
                      </Checkbox>
                    )}
                  />
                </Form.Item>
                <button
                  onClick={() => setOpenPBCModal(true)}
                  className="apply-text green-link ml-3 cursor-pointer"
                  type="button"
                >
                  <Trans>{`What's this?`}</Trans>
                </button>
              </div>
            ) : null}
            <PinellasBaywayModal
              onOk={() => {
                setOpenPBCModal(false);
              }}
              showModal={openPBCModal}
            />
            <button
              className={`flex cursor-pointer items-center space-x-2 text-alerts-red ${
                index === 0 ? 'hidden' : ''
              } underline`}
              onClick={() => remove(index)}
            >
              <MinusCircleOutlined className="mr-2" />
              <Trans>Remove Transponder</Trans>
            </button>
          </div>
        );
      })}
      <button
        onClick={(e) => {
          e.preventDefault();
          append({ type: undefined, pinellas: hasPinellasPlan ? false : undefined });
        }}
        className={`green-link-thin mt-6 inline-block cursor-pointer space-x-2 underline ${
          fields && fields.length <= 99 ? '' : 'hidden'
        }`}
      >
        <PlusCircleOutlined className="mr-2" />
        <Trans>Add Another Transponder</Trans>
      </button>
    </div>
  );
};

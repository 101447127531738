import React, { FC, useEffect, useState } from 'react';
import { InputProps } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { colors } from 'src/theme/theme';
import { CustomMaskedInput } from '../StyledInput/CustomMaskedInput';

interface MaskedObfuscatedInputProps extends InputProps {
  field: any;
  fieldstate: any;
  value?: any;
  mask: string;
  maxLength: number;
  label?: string;
}

const MaskedObfuscatedInput: FC<MaskedObfuscatedInputProps> = ({ field, fieldstate, mask, maxLength, label }) => {
  const [isObfuscated, setObfuscated] = useState(true);
  let elem: HTMLInputElement;

  const changeCurrentTargetType = (currentTarget: HTMLInputElement) => {
    if (isObfuscated) {
      if (currentTarget.value === mask.replace(/0/g, '_')) {
        currentTarget.type = 'text';
        currentTarget.value = '';
      } else {
        currentTarget.type = 'password';
      }
    } else {
      currentTarget.type = 'text';
      elem.focus();
    }
  };

  const focusHandler = (e: React.FocusEvent) => {
    const currentTarget = e.currentTarget as HTMLInputElement;
    currentTarget.type = 'text';
    field.onFocus && field.onFocus(e.currentTarget);
  };

  const blurHandler = (e: React.FocusEvent) => {
    const currentTarget = e.currentTarget as HTMLInputElement;
    changeCurrentTargetType(currentTarget);
    field.onBlur && field.onBlur(currentTarget);
    setObfuscated((value) => !value);
  };

  useEffect(() => {
    elem = document.getElementById(field.name) as HTMLInputElement;
    if (field.value) {
      elem.type = 'password';
    }
  }, []);

  return (
    <CustomMaskedInput
      id={field.name}
      field={field}
      fieldState={fieldstate}
      onBlur={blurHandler}
      onFocus={focusHandler}
      suffix={fieldstate?.invalid ? <ExclamationCircleFilled style={{ color: colors.alerts.red }} /> : <span />}
      mask={mask}
      maxLength={maxLength}
      placeholder
      {...(label && { label: label })}
    />
  );
};

export default MaskedObfuscatedInput;

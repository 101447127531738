import { useFormContext } from 'react-hook-form';
import { convertMaskToPlaceholder, PhoneMask } from '../../../validators/masks';
import React, { useEffect, useMemo, useState } from 'react';
import { AccountType, IPhonesInfo } from '../../../types/onboarding.types';
import { useOnboardingStore } from '../../../store/onboarding/onboarding.store';
import { SELECT_DETAILS } from '../../../store/onboarding/onboarding.selectors';
import { InputPropsType } from '../../MyAccount/ContactInformation/Contact';
import { WorkPhone } from '../../atoms/form/account/WorkPhone';
import { MobilePhone } from '../../atoms/form/account/MobilePhone';
import { HomePhone } from '../../atoms/form/account/HomePhone';

export const PhonesInfo = ({ disabled }: InputPropsType) => {
  const {
    control,
    watch,
    getFieldState,
    setValue,
    trigger,
    formState: { errors, dirtyFields },
  } = useFormContext<IPhonesInfo>();
  const [phonePlaceholder] = useState<string | null>(convertMaskToPlaceholder(PhoneMask));
  const { accountType } = useOnboardingStore(SELECT_DETAILS);

  const watchHomePhone = watch('homePhone');
  const watchCellPhone = watch('cellPhone');
  const watchWorkPhone = watch('workPhone');

  const isOneOfThePhoneNumbersTouched = useMemo(() => {
    const { isTouched: isCellPhoneTouched, isDirty: isCellPhoneDirty } = getFieldState('cellPhone');
    const { isTouched: isHomePhoneTouched, isDirty: isHomePhoneDirty } = getFieldState('homePhone');
    const { isTouched: isWorkPhoneTouched, isDirty: isWorkPhoneDirty } = getFieldState('workPhone');
    return (
      isWorkPhoneTouched ||
      isHomePhoneTouched ||
      isCellPhoneTouched ||
      isCellPhoneDirty ||
      isHomePhoneDirty ||
      isWorkPhoneDirty
    );
  }, [watchHomePhone, watchCellPhone, watchWorkPhone]);

  useEffect(() => {
    if (watchCellPhone === phonePlaceholder) {
      setValue('cellPhone', '');
    }
    if (watchHomePhone === phonePlaceholder) {
      setValue('homePhone', '');
    }
    if (watchWorkPhone === phonePlaceholder) {
      setValue('workPhone', '');
    }
    if (isOneOfThePhoneNumbersTouched) {
      trigger('cellPhone');
      trigger('homePhone');
      trigger('workPhone');
    }
  }, [watchCellPhone, watchHomePhone, watchWorkPhone, dirtyFields]);

  const workPhone = useMemo(() => {
    return <WorkPhone disabled={disabled} />;
  }, [errors.workPhone, control, watch('workPhone'), isOneOfThePhoneNumbersTouched, disabled]);

  return (
    <>
      <div className="flex flex-wrap">
        <MobilePhone disabled={disabled} />
        {accountType === AccountType.GOVERNMENT ? workPhone : null}
        {accountType !== AccountType.GOVERNMENT ? <HomePhone disabled={disabled} /> : null}
      </div>
      {!accountType || accountType !== AccountType.GOVERNMENT ? (
        <div className="flex flex-wrap">{workPhone}</div>
      ) : null}
    </>
  );
};

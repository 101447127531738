import { IAccountDetails } from '../../../graphql/accountDetails.graphql';
import { ISecurityInfo } from '../../../types/onboarding.types';

export const PRIVACY_AND_SECURITY_SETTINGS_DEFAULT_VALUES: ISecurityInfo = {
  username: '',
  oldPassword: '',
  password: '',
  reTypePassword: '',
  firstSecurityQuestion: '',
  firstSecurityQuestionAnswer: '',
  secondSecurityQuestion: '',
  secondSecurityQuestionAnswer: '',
  thirdSecurityQuestion: '',
  thirdSecurityQuestionAnswer: '',
};

export const generatePrivacySettingsDefaultValues = (accountDetails?: IAccountDetails) => {
  return {
    username: accountDetails?.username || '',
    oldPassword: '',
    password: '',
    reTypePassword: '',
    firstSecurityQuestion: accountDetails?.securityQuestions.firstQuestion,
    firstSecurityQuestionAnswer: accountDetails?.securityQuestions.firstAnswer,
    secondSecurityQuestion: accountDetails?.securityQuestions.secondQuestion,
    secondSecurityQuestionAnswer: accountDetails?.securityQuestions.secondAnswer,
    thirdSecurityQuestion: accountDetails?.securityQuestions.thirdQuestion,
    thirdSecurityQuestionAnswer: accountDetails?.securityQuestions.thirdAnswer,
  };
};

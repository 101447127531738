import React, { useEffect } from 'react';
import { t, Trans } from '@lingui/macro';
import Note from '../../../Note/Note';
import { Controller, useFormContext } from 'react-hook-form';
import { IEasyPayWallet } from './EasyPay';
import { Form } from 'antd';
import PriceInput from '../../../PriceInput/PriceInput';

interface IReplenishmentThresholdProps {
  replenishmentAmount: string;
  isEasyPay: boolean | undefined;
}

export const ReplenishmentThreshold = ({ replenishmentAmount, isEasyPay }: IReplenishmentThresholdProps) => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<IEasyPayWallet>();

  useEffect(() => {
    setValue('recurringPaymentAmount', Number(replenishmentAmount).toFixed(2));
  }, []);

  return (
    <>
      <div className="mb-5 mt-5 w-full text-left font-semibold text-primary-blue1">
        <Trans>Your Replenishment Amount</Trans>
      </div>
      <div className="mb-5 w-full sm:flex">
        <div className="mb-2 ml-0 text-left">
          <Form.Item
            name="recurringPaymentAmount"
            hasFeedback
            validateStatus={errors.recurringPaymentAmount?.message ? 'error' : ''}
            className={'theme form-item-tight m-0 inline-block'}
            help={errors.recurringPaymentAmount?.message}
          >
            <Controller
              name="recurringPaymentAmount"
              control={control}
              render={({ field: { value, onChange, onBlur } }) => (
                <PriceInput
                  id="recurringPaymentAmount"
                  hasErrors={!!errors.recurringPaymentAmount}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  disabled={!isEasyPay}
                  aria-label={t`Set Recurring Payment Amount`}
                />
              )}
            />
          </Form.Item>
        </div>
        <div className="sm:ml-5">
          <Note aria-label="replenishment-threshold-note" className="note-text">
            <Trans>
              When your account reaches the specified low balance amount for your account type ($5 for SunPass Personal
              Accounts), the replenishment amount you set will be automatically withdrawn from your payment method and
              added to your SunPass balance. <b>$10 minimum required</b>
            </Trans>
          </Note>
        </div>
      </div>
    </>
  );
};

import React, { FC, useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import './Contact.scss';
import { useQuery } from '@apollo/client';
import { GET_ACCOUNT_DETAILS } from '../../../graphql/accountDetails.graphql';
import { SELECT_RECOMMENDED_SUNPASS_PLAN_NAME } from '../../../store/onboarding/onboarding.selectors';
import { determineAccountSubtype } from '../../../store/onboarding/onboarding.utils';
import CollapsableContainer from '../../CollapsableContainer/CollapsableContainer';
import CloseAccountDetails from './CloseAccountDetails';
import { useContactSettingsStore } from '../../../store/account/contactSettings.store';
import { CustomButton } from 'src/components/CustomButton/CustomButton';

interface ICloseAccountSection {
  setIsSubmitBtnDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  isCloseAccountActive: boolean;
  setIsCloseAccountActive: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CloseAccountSection: FC<ICloseAccountSection> = ({
  setIsSubmitBtnDisabled,
  isCloseAccountActive,
  setIsCloseAccountActive,
}) => {
  const [showAccountInfo, setShowAccountInfo] = useState(false);
  const account = useQuery(GET_ACCOUNT_DETAILS)?.data?.accountDetails;
  const accountType = account?.accountType;
  const accountSubType = determineAccountSubtype(accountType);
  const accountNumber = account?.accountNumber;
  const planName = SELECT_RECOMMENDED_SUNPASS_PLAN_NAME(accountType, accountSubType);
  const {
    closeAccountInfo: { isCloseProcessActive },
    updateCloseAccountInfo,
    resetStore,
  } = useContactSettingsStore();

  const toggleShowAccountInfo = () => {
    setShowAccountInfo(!showAccountInfo);
  };

  useEffect(() => {
    resetStore();
  }, []);

  const closeAccountOnClick = () => {
    updateCloseAccountInfo({ isCloseProcessActive: true });
    setIsCloseAccountActive(true);
    account.currentBalance >= 0 && setIsSubmitBtnDisabled(false);
  };

  return (
    <>
      <CollapsableContainer
        title={t`Account Information`}
        onChange={toggleShowAccountInfo}
        activeKey={!showAccountInfo ? [] : ['1']}
        unsavedChanges={isCloseAccountActive}
        responsive
      >
        <div className="account-stub mt-4 w-full text-left">
          {showAccountInfo ? (
            <>
              <div className="flex justify-between">
                <span>
                  <span className="title">
                    <Trans>Account type:</Trans>
                  </span>
                  <span className="subtitle ml-2">{planName}</span>
                </span>
              </div>
              <div className="mt-3 flex">
                <div className="title">
                  <Trans>Account Number:</Trans>
                </div>
                <div className="subtitle ml-2">{accountNumber}</div>
              </div>
              {isCloseProcessActive ? (
                <CloseAccountDetails setIsCloseAccountActive={setIsCloseAccountActive} />
              ) : (
                <CustomButton
                  content="Close Account"
                  onClick={closeAccountOnClick}
                  className="home-search-submit mb-5 mt-5"
                />
              )}
            </>
          ) : null}
        </div>
      </CollapsableContainer>
    </>
  );
};

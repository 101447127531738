import React, { FC } from 'react';
import { t, Trans } from '@lingui/macro';
import ConfirmationModal from 'src/components/Modals/ConfirmationModal/ConfirmationModal';

interface OptOutModal {
  showModal: boolean;
  onOk: () => void;
  onCancel: () => void;
}

const OptOutModal: FC<OptOutModal> = ({ showModal, onOk, onCancel }) => {
  const okButtonText = t`Wallet Tab`;
  const cancelButtonText = t`Go Back`;
  const content = (
    <div className="pt-10">
      <Trans>
        You must enroll in Easy Pay and ensure that you have a valid card payment method on your account before you can
        enroll in SunPass Plus Parking. You can do so on the Wallet tab of your dashboard before you can come back and
        continue to enroll in SunPass Plus Parking.
      </Trans>
    </div>
  );

  return (
    <>
      {showModal && (
        <ConfirmationModal
          onOk={onOk}
          onCancel={onCancel}
          showModal={showModal}
          okButtonText={okButtonText}
          content={content}
          closable={true}
          alignButtons="center"
          alignText="center"
          cancelButtonText={cancelButtonText}
        />
      )}
    </>
  );
};
export default OptOutModal;

import { gql } from '@apollo/client';

export const SECURITY_QUESTIONS = gql`
  query securityQuestions {
    securityQuestions {
      label
      value
    }
  }
`;

export interface ISecurityQuestion {
  label: string;
  value: string;
}

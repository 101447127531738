import { useMediaQuery } from 'react-responsive';
import ScreenSize from 'src/types/ScreenSize.enum';
import { screens } from '../../theme/theme';

interface IUseScreenSize {
  screenSize: ScreenSize;
}

const useScreenSize = (): IUseScreenSize => {
  const isSm = useMediaQuery({ query: `(min-width: ${screens.sm})` });
  const isMd = useMediaQuery({ query: `(min-width: ${screens.md})` });
  const isLargeTablet = useMediaQuery({ query: `(min-width: ${screens['large-tablet']})` });
  const isLg = useMediaQuery({ query: `(min-width: ${screens.lg})` });
  const isXl = useMediaQuery({ query: `(min-width: ${screens.xl})` });
  const is2Xl = useMediaQuery({ query: `(min-width: ${screens['2xl']})` });

  const getScreenSize = (): ScreenSize => {
    if (is2Xl) return ScreenSize.XXL;
    if (isXl) return ScreenSize.XL;
    if (isLg) return ScreenSize.LG;
    if (isLargeTablet) return ScreenSize.LARGE_TABLET;
    if (isMd) return ScreenSize.MD;
    if (isSm) return ScreenSize.SM;
    return ScreenSize.XS;
  };

  return {
    screenSize: getScreenSize(),
  };
};

export default useScreenSize;

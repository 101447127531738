import { ISecurityQuestionProps, SecurityQuestion } from './SecurityQuestion';
import { ISecurityAnswerProps, SecurityAnswer } from './SecurityAnswer';
import React, { useMemo } from 'react';
import { Trans } from '@lingui/macro';
import { useQuery } from '@apollo/client';
import { ISecurityQuestion, SECURITY_QUESTIONS } from '../../graphql/securityQuestions.graphql';
import { useFormContext } from 'react-hook-form';
import { ISecurityInfo } from '../../types/onboarding.types';

export const SecurityQuestionsSectionGeneric = () => {
  const { watch, getValues } = useFormContext<ISecurityInfo>();
  const queries = {
    securityQuestions: useQuery<{ securityQuestions: ISecurityQuestion[] }>(SECURITY_QUESTIONS),
  };

  const filteredQuestions = useMemo(() => {
    return queries.securityQuestions.data?.securityQuestions?.filter(
      (question) =>
        question.value !== getValues('firstSecurityQuestion') &&
        question.value !== getValues('secondSecurityQuestion') &&
        question.value !== getValues('thirdSecurityQuestion'),
    );
  }, [watch('firstSecurityQuestion'), watch('secondSecurityQuestion'), watch('thirdSecurityQuestion'), queries]);

  const securityQuestions: { question: ISecurityQuestionProps; answer: ISecurityAnswerProps }[] = useMemo(() => {
    return [
      {
        question: {
          fieldName: 'firstSecurityQuestion',
          label: <Trans>First Security Question</Trans>,
          questions: filteredQuestions,
        },
        answer: {
          label: <Trans>First Security Question Answer</Trans>,
          fieldName: 'firstSecurityQuestionAnswer',
        },
      },
      {
        question: {
          fieldName: 'secondSecurityQuestion',
          label: <Trans>Second Security Question</Trans>,
          questions: filteredQuestions,
        },
        answer: {
          label: <Trans>Second Security Question Answer</Trans>,
          fieldName: 'secondSecurityQuestionAnswer',
        },
      },
      {
        question: {
          fieldName: 'thirdSecurityQuestion',
          label: <Trans>Third Security Question</Trans>,
          questions: filteredQuestions,
        },
        answer: {
          label: <Trans>Third Security Question Answer</Trans>,
          fieldName: 'thirdSecurityQuestionAnswer',
        },
      },
    ];
  }, [filteredQuestions]);
  return (
    <div className="flex flex-wrap">
      <div className="w-full">
        {securityQuestions.map(({ question, answer }, index: number) => (
          <div key={`${index}-${question.fieldName}-${answer.fieldName}`}>
            <SecurityQuestion key={`${index}-${question.fieldName}`} {...question} />
            <SecurityAnswer key={`${index}-${answer.fieldName}`} {...answer} />
          </div>
        ))}
      </div>
    </div>
  );
};

import { ITransactionActivityListInput } from 'src/graphql/transactionsActivity.graphql';
import { getDatesForActivityList } from './pagination.utils';

export interface IPaginationInfo {
  size: number;
  page: number;
}

export type IActivitySearchParams = Omit<ITransactionActivityListInput, 'pagination'>;

export const { initialStartDate, initialEndDate } = getDatesForActivityList();

export const VEHICLES_PAGINATION_STORE_INITIAL_STATE: IPaginationInfo = {
  page: 1,
  size: 7,
};

export const ACTIVITY_PAGINATION_STORE_INITIAL_STATE: IPaginationInfo = {
  page: 1,
  size: 7,
};

export const TRANSPONDERS_PAGINATION_STORE_INITIAL_STATE: IPaginationInfo = {
  page: 1,
  size: 7,
};

export const INVOICE_TRANSACTIONS_PAGINATION_INITIAL_STATE: IPaginationInfo = {
  page: 1,
  size: 7,
};

export const PROMOTION_PAGINATION_INITIAL_STATE: IPaginationInfo = {
  page: 1,
  size: 7,
};

export const ACTIVITY_SEARCH_PARAMS_INITIAL_STATE: IActivitySearchParams = {
  plateNumber: '',
  transponderNumber: '',
  agencyName: '',
  endDate: initialEndDate,
  startDate: initialStartDate,
  searchDate: '',
  location: '',
  transactionType: '',
};

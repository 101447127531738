import { createContext } from 'react';
import { INotificationStatusInfo, INotificationView } from './NotificationProvider';

interface INotificationContext {
  loadNotifications: (loadWithoutReset?: boolean) => Promise<void>;
  loading: boolean;
  notifications: INotificationView[] | undefined;
  dismiss: (id: string) => void;
  dismissAll: () => void;
  notificationInfo: INotificationStatusInfo;
  clear: () => void;
}

export const NotificationContext = createContext<INotificationContext | null>(null);

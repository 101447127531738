import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

/**
 *
 * For scroling to the top of pages when changing routes
 * https://reactrouter.com/web/guides/scroll-restoration
 */

interface IScrollToTopTrigger {
  triggerValues?: boolean;
  blockInitial?: boolean;
  blockScroll?: boolean;
}
export default function ScrollToTop({ triggerValues, blockInitial, blockScroll }: IScrollToTopTrigger): null {
  const { pathname } = useLocation();
  const [initialRender, setInitialRender] = useState(false);
  const resetScrollingBehavior = () => {
    document.documentElement?.classList.remove('auto-scroll');
    document.documentElement?.classList.add('smooth-scroll');
  };
  const setAutoScrollingBehavior = () => {
    document.documentElement?.classList.remove('smooth-scroll');
    document.documentElement?.classList.add('auto-scroll');
  };
  useEffect(() => {
    if (!blockScroll) {
      setAutoScrollingBehavior();

      setTimeout(() => {
        !blockInitial && setInitialRender(true);
        initialRender && window.scrollTo(0, 0);
      }, 1);

      setInitialRender(true);

      setTimeout(resetScrollingBehavior, 2);
    }
  }, [pathname, triggerValues]);

  return null;
}

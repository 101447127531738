import { IOnboardingInfo } from '../../../types/onboarding.types';
import { IEZPayOnboarding } from './OnboardingEZPay';

export const INITIAL_FORM_STATE_EZ_PAY = ({
  optInEZPay,
  recurringPaymentAmount,
  preloadedFunds,
}: IOnboardingInfo): IEZPayOnboarding => {
  return {
    optInEZPay: !!optInEZPay,
    recurringPaymentAmount: recurringPaymentAmount ? `${recurringPaymentAmount}` : '0.00',
    depositAmount: preloadedFunds ? `${preloadedFunds}` : '0.00',
    confirmDepositAmount: preloadedFunds ? `${preloadedFunds}` : '0.00',
  };
};

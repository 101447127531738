import { FL_STATE_CODE, USA_COUNTRY_ISO3 } from '../../../utils/countryStates.utils';
import { IContactSettings } from './Contact';
import { IAccountDetails } from '../../../graphql/accountDetails.graphql';
import { formatPhoneNumber } from '../../../utils/formatter';
import {
  IAccountSettingsSecondaryContacts,
  IAccountSettingsUpdateInput,
} from '../../../graphql/accountSettings.graphql';

export const CONTACT_SETTINGS_DEFAULT_VALUES: IContactSettings = {
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  country: '',
  county: '',
  cellPhone: '',
  homePhone: '',
  workPhone: '',
  email: '',
  reTypeEmail: '',
  fein: '',
  postalCode: '',
  companyName: '',
  additionalEmailAddresses: [],
  doingBusinessAs: '',
  organizationName: '',
  purchaseOrderNumber: '',
  additionalContacts: [],
  ccFirstName: '',
  ccLastName: '',
  ccMiddleName: '',
  ccNumber: '',
  ccSecurityCode: '',
  billingState: FL_STATE_CODE,
  billingCountry: USA_COUNTRY_ISO3,
  billingPostalCode: '',
  billingCity: '',
  billingAddressLine1: '',
  billingAddressLine2: '',
  reason: '',
};

export const generateDefaultValuesFromAccountDetails = (accountDetails?: IAccountDetails) => {
  return {
    addressLine1: accountDetails?.addressLine1,
    addressLine2: accountDetails?.addressLine2,
    city: accountDetails?.city,
    state: accountDetails?.state,
    country: accountDetails?.country,
    county: '',
    cellPhone: formatPhoneNumber(accountDetails?.cellPhone),
    homePhone: formatPhoneNumber(accountDetails?.homePhone),
    workPhone: formatPhoneNumber(accountDetails?.workPhone),
    email: accountDetails?.emailAddress,
    reTypeEmail: accountDetails?.emailAddress,
    fein: '',
    postalCode: accountDetails?.zipcode,
    companyName: '',
    additionalEmailAddresses: accountDetails?.secondaryEmailAddress
      ? [{ email: accountDetails?.secondaryEmailAddress, reTypeEmail: accountDetails?.secondaryEmailAddress }]
      : [],
    doingBusinessAs: '',
    organizationName: '',
    purchaseOrderNumber: '',
    additionalContacts: accountDetails?.secondaryContacts.map((contact) => {
      return {
        firstName: contact.firstName,
        middleName: contact.middleName,
        lastName: contact.lastName,
      };
    }),
    ccFirstName: '',
    ccLastName: '',
    ccMiddleName: '',
    ccNumber: '',
    ccSecurityCode: '',
    billingState: FL_STATE_CODE,
    billingCountry: USA_COUNTRY_ISO3,
    billingPostalCode: '',
    billingCity: '',
    billingAddressLine1: '',
    billingAddressLine2: '',
  };
};
interface IMappingAccountSetting extends Partial<IContactSettings>, Partial<IAccountSettingsUpdateInput> {}

export const fixedAdditionalContacts = (data: IContactSettings): IAccountSettingsSecondaryContacts[] => {
  if (data.additionalContacts && data.additionalContacts[0].lastName !== null) {
    return data.additionalContacts.map((contact) => {
      return {
        firstName: contact.firstName,
        middleName: contact.middleName,
        lastName: contact.lastName,
      };
    });
  }
  return [];
};

export const mapChangesToUpdateAccountSettings = (
  changes: IContactSettings,
  inputData: IContactSettings,
  accountDetails: IAccountDetails | undefined,
): IMappingAccountSetting => {
  return {
    userName: accountDetails?.username,
    addressLine1: changes.addressLine1 ? changes.addressLine1 : inputData.addressLine1,
    addressLine2: changes.addressLine2 ? changes.addressLine2 : inputData.addressLine2,
    city: changes.city ? changes.city : inputData.city,
    state: changes.state ? changes.state : inputData.state || '',
    country: changes.country ? changes.country : inputData.country,
    county: changes.county ? changes.county : inputData.county,
    emailAddress: changes.email ? changes.email : inputData.email,
    secondaryEmailAddress: changes.additionalEmailAddresses?.[0]?.email
      ? changes.additionalEmailAddresses?.[0]?.email
      : inputData.additionalEmailAddresses?.[0]?.email,
    cellPhone: changes.cellPhone ? changes.cellPhone : inputData.cellPhone,
    homePhone: changes.homePhone ? changes.homePhone : inputData.homePhone,
    workPhone: changes.workPhone ? changes.workPhone : inputData.workPhone,
    replenishmentAmount: accountDetails?.replenishAmount.toFixed(2).toString(),
    secondaryContacts: changes.additionalContacts
      ? fixedAdditionalContacts(changes)
      : fixedAdditionalContacts(inputData),
    zipCode: changes.postalCode ? changes.postalCode : inputData.postalCode,
    faxPhone: '',
  };
};

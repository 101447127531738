import { DependencyList, EffectCallback, useEffect, useState } from 'react';

export const useWithTrigger = (triggerCondition: boolean | string, effect: EffectCallback, deps?: DependencyList) => {
  const [trigger, setTrigger] = useState(false);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    let effectsReturns: void | (() => void) = () => {};

    if (triggerCondition) setTrigger(true);

    if (trigger) effectsReturns = effect();

    if (effectsReturns && typeof effectsReturns === 'function') {
      return effectsReturns;
    }
  }, deps);
};

import { Controller, useFormContext } from 'react-hook-form';
import { Form, Select } from 'antd';
import { INotificationsSettings } from '../MyAccount/Notifications/Notifications';
import { capitalizeInitials } from '../../utils/string.utils';
import { useQuery } from '@apollo/client';
import { GET_CORRESPONDENCE_TYPES } from '../../graphql/correspondenceTypes.graphql';
import { MainLoader } from '../Loader/MainLoader';
import { t, Trans } from '@lingui/macro';
interface IMonthlyStatementProps {
  label: string | JSX.Element;
  required?: boolean;
}
const MonthlyStatement = ({ label, required }: IMonthlyStatementProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<INotificationsSettings>();

  const { data: correspondenceTypesData, loading: correspondenceTypesLoading } = useQuery<{
    correspondenceTypes: string[];
  }>(GET_CORRESPONDENCE_TYPES);
  const { Option } = Select;

  return (
    <div className="notifications w-full">
      <MainLoader loading={correspondenceTypesLoading} />
      <Form.Item
        label={label}
        className="theme monthly-statement-input"
        help={errors.deliveryModeType?.message}
        required={!!required}
      >
        <Controller
          name="deliveryModeType"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              id="deliveryModeType"
              key="deliveryModeType"
              optionFilterProp="children"
              showSearch
              defaultValue={'Email'}
              aria-label={t`Statement Type`}
            >
              {correspondenceTypesData?.correspondenceTypes?.map((type, index) => (
                <Option key={index} value={type}>
                  {capitalizeInitials(type)}
                </Option>
              ))}
            </Select>
          )}
        />
        <div className="mt-5 italic">
          <Trans>Emailed statements are free. Mailed statements cost $1.50</Trans>
        </div>
      </Form.Item>
    </div>
  );
};

export default MonthlyStatement;

import { gql } from '@apollo/client';

export const VALIDATE_TRANSPONDER_NUMBER = gql`
  query validateTransponderNumber($validateTransponderNumberInput: ValidateTransponderNumberInput!) {
    validateTransponderNumber(validateTransponderNumberInput: $validateTransponderNumberInput) {
      message
      transponderType
    }
  }
`;

export interface IValidateTransponderNumberOutput {
  message: string;
  transponderType: string;
}

export interface IValidateTransponderNumberInput {
  transponderNumber: string;
}

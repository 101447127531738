import { IPaymentMethod } from 'src/components/Payments/Payments.interfaces';
import { EasyPayOption } from 'src/graphql/payment.graphql';
import { devtools } from 'zustand/middleware';
import { create } from '../createStore';
import { PaymentMethodType } from 'src/components/Payments/Payments.enums';

export const PURCHASE_TRANSPONDER_INITIAL_STATE: IPurchaseTransponderInput = {
  paymentAmount: 0,
  paymentMethodId: '',
  promoCode: '',
  easyPayOption: EasyPayOption.NONE,
  additionalFundAmount: 0,
  cardType: '',
  numOfProTransponders: 0,
  numOfStickerTransponders: 0,
  isNewPayment: false,
};

interface useMultiStepPaymentStoreState {
  currentStep: number;
  paymentInfo: IPurchaseTransponderInput;
  isSecurityCodeModalVisible: boolean;
  setSecurityCodeModalVisible: (value: boolean) => void;
  nextStep: () => void;
  previousStep: () => void;
  resetStore: () => void;
  updateSingleMultiPaymentStoreValues: (propertyToUpdate: string, value: number | string | boolean) => void;
  updatePaymentInformation: (paymentMethod: IPaymentMethod) => void;
  replenishmentAmount: string;
  setReplenishmentAmount: (value: string) => void;
}

export const useMultiStepPaymentStore = create<
  useMultiStepPaymentStoreState,
  [['zustand/devtools', useMultiStepPaymentStoreState]]
>(
  devtools((set) => ({
    currentStep: 0,
    paymentInfo: PURCHASE_TRANSPONDER_INITIAL_STATE,
    isSecurityCodeModalVisible: false,
    replenishmentAmount: '',
    setSecurityCodeModalVisible: (value: boolean) => set((state) => ({ ...state, isSecurityCodeModalVisible: value })),
    setReplenishmentAmount: (value: string) => set((state) => ({ ...state, replenishAmount: value })),
    resetStore: () => set(() => ({ currentStep: 0, paymentInfo: PURCHASE_TRANSPONDER_INITIAL_STATE })),
    nextStep: () => set((state) => ({ ...state, currentStep: state.currentStep + 1 })),
    updateSingleMultiPaymentStoreValues: (propertyToUpdate: string, value: number | string | boolean) =>
      set((state) => ({ ...state, paymentInfo: { ...state.paymentInfo, [`${propertyToUpdate}`]: value } })),
    previousStep: () => set((state) => ({ ...state, currentStep: state.currentStep - 1 })),
    updatePaymentInformation: (paymentMethod: IPaymentMethod) =>
      set((state) => ({
        ...state,
        paymentInfo: {
          ...state.paymentInfo,
          isNewPayment: true,
          paymentMethod: {
            state: paymentMethod.state,
            postalCode: paymentMethod.postalCode,
            country: paymentMethod.country,
            county: paymentMethod.county,
            city: paymentMethod.city,
            addressLine2: paymentMethod.addressLine2,
            addressLine1: paymentMethod.addressLine1,
            paymentType: paymentMethod.paymentType,
            ccFirstName: paymentMethod.ccFirstName,
            ccMiddleName: paymentMethod.ccMiddleName,
            optInEZPay: paymentMethod.optInEZPay,
            ccLastName: paymentMethod.ccLastName,
            ccNumber: paymentMethod.ccNumber,
            ccExpirationMonth: paymentMethod.ccExpirationMonth,
            ccExpirationYear: paymentMethod.ccExpirationYear,
            ccSecurityCode: paymentMethod.ccSecurityCode,
            bankFirstName: paymentMethod.bankFirstName,
            bankLastName: paymentMethod.bankLastName,
            bankAccountNumber: paymentMethod.bankAccountNumber,
            bankRoutingNumber: paymentMethod.bankRoutingNumber,
            bankAccountType: paymentMethod.bankAccountType,
            cardType: paymentMethod.paymentType === PaymentMethodType.BANK ? paymentMethod.bankAccountType : '',
          },
        },
      })),
  })),
);

export interface IPurchaseTransponderInput {
  paymentAmount: number;
  paymentMethodId: string;
  promoCode?: string;
  cardType: string;
  easyPayOption: EasyPayOption;
  additionalFundAmount: number;
  numOfProTransponders: number;
  numOfStickerTransponders: number;
  isNewPayment: boolean;
  paymentMethod?: IPaymentMethod;
}

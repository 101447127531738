import { gql } from '@apollo/client';

export const GET_LINKED_ACCOUNTS = gql`
  query getLinkedAccounts {
    linkedAccountLists {
      childAccountList {
        status
        accountNumber
        owner
        accountBalance
      }
      parentAccountList {
        owner
        accountNumber
        status
      }
    }
  }
`;

export const ADD_LINKED_CHILD_ACCOUNT = gql`
  mutation addLinkedChildAccount($addLinkedChildAccountInput: AddLinkedChildAccountInput!) {
    addLinkedChildAccount(addLinkedChildAccountInput: $addLinkedChildAccountInput)
  }
`;

export const DELETE_LINKED_CHILD_ACCOUNT = gql`
  mutation deleteLinkedChildAccount($childAccountNumber: String!) {
    deleteLinkedChildAccount(childAccountNumber: $childAccountNumber)
  }
`;

export const UPDATE_LINKED_PARENT_ACCOUNT = gql`
  mutation updateLinkedParentAccount($updateLinkedParentAccountInput: UpdateLinkedParentAccountInput!) {
    updateLinkedParentAccount(updateLinkedParentAccountInput: $updateLinkedParentAccountInput)
  }
`;

export interface ILinkedAccountsOutput {
  childAccountList: ILinkedAccount[];
  parentAccountList: ILinkedAccount[];
}

export interface ILinkedAccount {
  status: LinkedAccountStatus;
  accountNumber: string;
  owner: string;
  accountBalance: number;
}

export enum LinkedAccountStatus {
  ACTIVE = 'ACTIVE',
  OPEN = 'OPEN',
}

export interface AddLinkedChildAccountInput {
  childAccountNumber: string;
  childAccountZipCode: string;
}

export interface UpdateLinkedParentAccountInput {
  parentAccountNumber: string;
  action: UpdateLinkedParentAccountAction;
}

export enum UpdateLinkedParentAccountAction {
  APPROVE = 'APPROVE',
  DENY = 'DENY',
  DELETE = 'DELETE',
}

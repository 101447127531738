import React from 'react';
import './MyAccount.scss';
import useScreenSize from 'src/hooks/screen/useScreenSize';
import ScreenSize from 'src/types/ScreenSize.enum';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { colors } from 'src/theme/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps {
  label: string;
  styleName?: string;
}

export default function TabContent(props: IProps): React.ReactElement {
  const { screenSize } = useScreenSize();
  const { label, styleName } = props;

  return (
    <div>
      <div className={`custom-tab-pane ${styleName}`}>
        {label}
        {screenSize <= ScreenSize.LG ? (
          <div className={screenSize >= ScreenSize.LG ? 'right-chevron' : 'right-chevron mobile-padding'}>
            <FontAwesomeIcon icon={faChevronRight} color={colors.primary.blue2} size="1x"></FontAwesomeIcon>
          </div>
        ) : null}
      </div>
    </div>
  );
}

import { gql } from '@apollo/client';
import { TransponderType } from '../transponders.graphql';

export const ADD_PLAN = gql`
  mutation addPlan($addPlanInput: AddPlanInput!) {
    addPlan(addPlanInput: $addPlanInput) {
      planPrice
      transponderCost
      tax
    }
  }
`;

export interface IAddPlanInput {
  planName: string;
  transponderNumber: string;
  transponderType: TransponderType;
}
export interface IAddPlanOutput {
  planPrice: string;
  transponderCost: string;
  tax: string;
}

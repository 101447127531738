export const truncate = (value: string, n: number) => {
  if (value.length <= n) return value;
  return value.slice(0, n);
};

export const stripNonNumeric = (value?: string): string | undefined => {
  return value?.match(/\d+/g)?.join('');
};

export const concat = (first?: string, second?: string, maxLength?: number): string => {
  const mergedStrings = [stripNonNumeric(first), stripNonNumeric(second)].join('');
  return maxLength ? truncate(mergedStrings, maxLength) : mergedStrings;
};

export const countNumeric = (value?: string): number => {
  const length = value?.match(/\d+/g)?.join('')?.length;
  return length ? length : 0;
};

export const isPriceRegex = (value: string | undefined): boolean => {
  if (!value) return false;
  const re = /^\d*[.|,]?\d{0,2}$/;
  return re.test(value);
};

export const capitalizeInitials = (value: string | undefined) => {
  return value
    ?.split(' ')
    ?.map((name) => name[0]?.toUpperCase() + name?.substring(1)?.toLowerCase())
    ?.join(' ');
};

export const isPositiveNumber = (value: string | undefined): boolean => {
  return !!value && !isNaN(parseFloat(value)) && parseFloat(value) >= 0;
};

import { Form, Input } from 'antd';
import { t, Trans } from '@lingui/macro';
import { Controller, useFormContext } from 'react-hook-form';
import { IHomeSearchForm } from '../../../HomeSearch/HomeSearch';
import LocateInvoiceNumberModal from '../../../Modals/ConfirmationModal/LocateInvoiceNumberModal';
import { useState } from 'react';

interface IInvoiceNumberProps {
  isInHomeSearch?: boolean;
}

const InvoiceNumber = ({ isInHomeSearch }: IInvoiceNumberProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IHomeSearchForm>();

  const [showModal, setShowModal] = useState(false);

  return (
    <div className="ml-0 mt-6 md:ml-6">
      <LocateInvoiceNumberModal showModal={showModal} onClose={() => setShowModal(false)} />
      <Form.Item
        className="home-search-input home-search-input-small theme"
        htmlFor="invoiceNumber"
        label={t`Invoice Number`}
        name="invoiceNumber"
        hasFeedback
        validateStatus={errors?.invoiceNumber?.message ? 'error' : ''}
        help={errors.invoiceNumber?.message as unknown as string}
      >
        <Controller
          name="invoiceNumber"
          control={control}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              {...fieldState}
              key="invoiceNumber"
              id="invoiceNumber"
              onChange={(event) => {
                field.onChange(event);
              }}
              aria-label={t`Invoice Number`}
            />
          )}
        />
      </Form.Item>
      {isInHomeSearch ? (
        <div className="mt-2 flex w-full">
          <button
            type="button"
            onClick={() => {
              setShowModal(true);
            }}
            className="green-link ml-auto mr-auto cursor-pointer"
          >
            <Trans>Where do I find this?</Trans>
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default InvoiceNumber;

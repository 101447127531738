import { IPaymentMethodGraphQl } from '../../graphql/paymentMethods.graphql';
import { create } from '../createStore';
import { devtools } from 'zustand/middleware';

interface IWalletStore {
  paymentsInfo: IPaymentsInfo;
  updatePaymentInfo: (input: Partial<IPaymentsInfo>) => void;
}

interface IPaymentsInfo {
  paymentMethodToEdit: IPaymentMethodGraphQl | null;
  isEditMode: boolean;
  isMethodLimitReached: boolean;
  isPaymentMethodFormActive: boolean;
  newPaymentMethodAdded: boolean;
}

const PAYMENTS_INITIAL_STATE: IPaymentsInfo = {
  paymentMethodToEdit: null,
  isEditMode: false,
  isMethodLimitReached: false,
  isPaymentMethodFormActive: false,
  newPaymentMethodAdded: false,
};

export const useWalletStore = create<IWalletStore, [['zustand/devtools', IWalletStore]]>(
  devtools((set) => ({
    paymentsInfo: PAYMENTS_INITIAL_STATE,
    updatePaymentInfo: (input: Partial<IPaymentsInfo>) => {
      set((state) => ({
        paymentsInfo: {
          ...state.paymentsInfo,
          ...input,
        },
      }));
    },
  })),
);

import React, { FC } from 'react';

interface ITableRowProps {
  col1: React.ReactNode;
  col2?: number;
  col3?: number;
  col4: string;
  className?: string;
}

const TableRow: FC<ITableRowProps> = ({ col1, col2, col3, col4, className = '' }) => {
  return (
    <tr className={className}>
      <td>{col1}</td>
      <td className="text-left">{col2 ? `$${col2.toFixed(2)}` : ''}</td>
      <td className="text-left">{col3 ? `x${col3}` : ''}</td>
      <td className="text-right">{col4}</td>
    </tr>
  );
};

export default TableRow;

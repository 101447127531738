import { useOnboardingStore } from '../../../../store/onboarding/onboarding.store';
import { useEffect, useState } from 'react';
import { getAccountInfoSchema } from '../../../../validators/onboarding/accountInfo/accountInfo.validator';
import { useForm } from 'react-hook-form';
import { IAccountInfo } from '../../../../types/onboarding.types';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { getAccountInfoInitialFormStateFrom } from '../../../../components/Onboarding/Account/initialState';
import useCountryOptions from '../../address/useCountryOptions';
import useStateOptions from '../../address/useStateOptions';
import useCountyOptions from '../../address/useCountyOptions';
import { useQuery } from '@apollo/client';
import { ISecurityQuestion, SECURITY_QUESTIONS } from '../../../../graphql/securityQuestions.graphql';
import { useScroll } from '../../../screen/useScroll';

export const useAccountInfoForm = () => {
  const {
    onboardingInfo: { accountInfo, accountType },
  } = useOnboardingStore();
  const [errorLoadingAddress, setErrorLoadingAddress] = useState(false);
  const [errorLoadingSecurityQuestions, setErrorLoadingSecurityQuestions] = useState(false);
  const [isRedirectPromptShown, setIsRedirectPromptShown] = useState(false);

  const schema = getAccountInfoSchema(accountType);

  const methods = useForm<IAccountInfo>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    //TODO FLO-3096 - refactor in scope of ticket FLO-3096
    defaultValues: getAccountInfoInitialFormStateFrom(),
  });

  const {
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = methods;

  const stateWatch = watch('state');
  const countryWatch = watch('country');

  const { errorLoading: countryErrorLoading } = useCountryOptions();
  const { errorLoading: stateErrorLoading } = useStateOptions({
    country: countryWatch as string,
  });
  const { errorLoading: countyErrorLoading } = useCountyOptions({
    state: stateWatch as string,
  });

  const queries = {
    securityQuestions: useQuery<{ securityQuestions: ISecurityQuestion[] }>(SECURITY_QUESTIONS),
  };

  useEffect(() => {
    if (countryErrorLoading || stateErrorLoading || countyErrorLoading) {
      setErrorLoadingAddress(true);
    }

    if (!queries.securityQuestions.loading && queries.securityQuestions.error) {
      setErrorLoadingSecurityQuestions(true);
    }
  }, [queries.securityQuestions]);

  useEffect(() => {
    if (accountInfo?.additionalContacts?.length) {
      setValue('showAdditionalContacts', true);
    }
  }, [accountInfo?.additionalContacts]);

  const { scrollToError } = useScroll();

  useEffect(() => {
    scrollToError(errors);
  }, [errors]);

  return {
    errorLoadingAddress,
    errorLoadingSecurityQuestions,
    isRedirectPromptShown,
    showRedirectPrompt: () => setIsRedirectPromptShown(true),
    hideRedirectPrompt: () => setIsRedirectPromptShown(false),
    handleSubmit,
    methods,
  };
};

import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';
import { MainRoutes } from 'src/types/routes';

export const forgotUsernameOrPasswordLink = (
  <div className="my-3 text-center lg:text-right">
    <Link to={MainRoutes.FORGOT_USERNAME_OR_PASSWORD} className="green-link">
      <Trans>Having trouble accessing your account?</Trans>
    </Link>
  </div>
);

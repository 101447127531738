export enum ActionItemAndIconLinkCardId {
  ADD_VEHICLE = 'AddVehicle',
  ADD_FUNDS = 'AddFunds',
  SET_UP_EASY_PAY = 'SetUpEasyPay',
  ADD_PAYMENT_METHOD = 'AddPaymentMethod',
  ORDER_TRANSPONDER = 'OrderTransponder',
  PARKING = 'ExploreSunPassPlusParking',
  ACTIVATE_TRANSPONDER = 'ActivateTransponder',
  PURCHASE_TRANSPONDER = 'PurchaseTransponder',
  EDIT_ACCOUNT_INFO = 'EditAccountInfo',
}

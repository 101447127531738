import React, { FC } from 'react';
import { t } from '@lingui/macro';
import ConfirmationModal from '../ConfirmationModal';
import { MainRoutes } from 'src/types/routes';
import { useRouteMonitor } from 'src/hooks/location/useRouteMonitor';
import { useOnboardingStore } from '../../../../store/onboarding/onboarding.store';
import { SELECT_DETAILS } from '../../../../store/onboarding/onboarding.selectors';

interface IConfirmationModalProps {
  showModal: boolean;
  onOk: () => void;
  onCancel: () => void;
}

const ExitAccountCreation: FC<IConfirmationModalProps> = ({ showModal, onOk, onCancel }) => {
  const { referenceNumber } = useOnboardingStore(SELECT_DETAILS);
  const { goToPath } = useRouteMonitor();
  const cancelButtonText = t`No`;
  const okButtonText = t`Yes`;
  const title = t`Exit Account Creation?`;
  const content = t`Are you sure you'd like to exit the account creation process? You can come back later and resume account creation using your reference number: ${referenceNumber}`;
  const onConfirm = () => {
    onOk();
    goToPath(MainRoutes.ROOT);
  };
  return (
    <>
      {showModal && (
        <ConfirmationModal
          onOk={onConfirm}
          onCancel={onCancel}
          showModal={showModal}
          cancelButtonText={cancelButtonText}
          okButtonText={okButtonText}
          title={title}
          content={content}
          alignButtons="center"
          alignText="center"
        />
      )}
    </>
  );
};
export default ExitAccountCreation;

import React, { useEffect } from 'react';
import { Checkbox, Form } from 'antd';
import { Trans } from '@lingui/macro';
import { useFormContext, Controller } from 'react-hook-form';
import { INotificationsSettings } from '../MyAccount/Notifications/Notifications';

interface IMessageNotificationsProps {
  label?: string | JSX.Element;
}

const MessageNotifications = ({ label }: IMessageNotificationsProps) => {
  const {
    watch,
    setValue,
    trigger,
    control,
    formState: { errors },
  } = useFormContext<INotificationsSettings>();

  const smsOptionWatch = watch('smsOption');
  const smsStartValue = smsOptionWatch === undefined ? false : smsOptionWatch;

  useEffect(() => {
    smsOptionWatch === undefined && setValue('smsOption', smsStartValue);
    trigger('smsOption');
  }, [smsOptionWatch]);

  return (
    <div className="notifications w-full">
      <Form.Item
        {...(label && { label: label })}
        validateStatus={errors.smsOption ? 'error' : ''}
        help={errors.smsOption ? errors.smsOption.message : ''}
        validateTrigger={['onChange']}
      >
        <div className="flex flex-row">
          <div className="mr-3 inline">
            <Controller
              name="smsOption"
              control={control}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  id="smsOption"
                  checked={smsOptionWatch}
                  className={`${errors.smsOption && watch('smsOption') ? 'checkbox--error' : ''}`}
                />
              )}
            />
          </div>
          <label htmlFor="smsOption" className="mb-5 leading-7">
            <Trans>
              <b>Yes, I would like to receive account alerts via text message.</b> Once this box is checked, you will
              receive a text message to confirm your opt-in selection. Please reply YES. Reply STOP at any time to
              cancel. Message and data rates may apply. You may also opt-out of receiving text messages by logging into
              your account and updating your communication preferences.
            </Trans>
          </label>
        </div>
      </Form.Item>
    </div>
  );
};

export default MessageNotifications;

import { useLocation, useNavigate } from 'react-router';
import { useCallback, useEffect, useState } from 'react';
import { useBlocker } from './useBlocker';
import { OnboardingStepPath } from '../../types/routes';

export interface IUseCallbackPrompt {
  showPrompt: boolean;
  confirmRedirect: () => void;
  cancelRedirect: () => void;
}

export const useCallbackPrompt = (when: boolean): IUseCallbackPrompt => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPrompt, setShowPrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState<any>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const cancelRedirect = useCallback(() => {
    setShowPrompt(false);
  }, []);

  const handleBlockNavigation = useCallback(
    (nextLocation: any) => {
      if (
        nextLocation.location.pathname === OnboardingStepPath.SUCCESS &&
        location.pathname === OnboardingStepPath.PAYMENT_SUMMARY
      ) {
        setConfirmedNavigation(true);
      }

      if (!confirmedNavigation && nextLocation.location.pathname !== location.pathname) {
        setShowPrompt(true);
        setLastLocation(nextLocation);
        return false;
      }
      return true;
    },
    [confirmedNavigation, location],
  );

  const confirmRedirect = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.location.pathname);
      setConfirmedNavigation(false);
    }
  }, [lastLocation, confirmedNavigation]);

  useBlocker(handleBlockNavigation, when);

  return {
    showPrompt,
    confirmRedirect,
    cancelRedirect,
  };
};

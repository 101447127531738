import * as yup from 'yup';
import { validators } from '../../validators';
import { TRANSPONDER_QUANTITY_ERROR_MSG, TRANSPONDER_NUMBER_MATCH_ERROR_MSG } from './transpondersErrorMessages';
import { MAX_TRANSPONDER_PLANS_ERROR_MSG } from '../vehicles/vehicleErrorMessages';

export const onboardingTransponderActivationValidator = yup.object().shape({
  transponders: yup.array().of(
    yup.object().shape({
      number: validators.transponderNumber(),
      confirmNumber: validators.confirmTransponderNumber('number').required(TRANSPONDER_NUMBER_MATCH_ERROR_MSG),
      friendlyName: yup.string(),
    }),
  ),
});

export const onboardingTranspondersPurchaseValidator = yup.object().shape({
  miniTransponderQty: yup
    .number()
    .integer(TRANSPONDER_QUANTITY_ERROR_MSG)
    .typeError(TRANSPONDER_QUANTITY_ERROR_MSG)
    .nullable(),
  proTransponderQty: yup.number().integer(TRANSPONDER_QUANTITY_ERROR_MSG).typeError(TRANSPONDER_QUANTITY_ERROR_MSG),
  miniTransponderQtyAssignedToPlan: yup
    .number()
    .integer(TRANSPONDER_QUANTITY_ERROR_MSG)
    .typeError(TRANSPONDER_QUANTITY_ERROR_MSG)
    .nullable()
    .test('maxPlansAdded', MAX_TRANSPONDER_PLANS_ERROR_MSG, function (value) {
      const { miniTransponderQty } = this.parent;
      if (value) {
        return value <= miniTransponderQty;
      } else {
        return true;
      }
    }),
  proTransponderQtyAssignedToPlan: yup
    .number()
    .integer(TRANSPONDER_QUANTITY_ERROR_MSG)
    .typeError(TRANSPONDER_QUANTITY_ERROR_MSG)
    .nullable()
    .test('maxPlansAdded', MAX_TRANSPONDER_PLANS_ERROR_MSG, function (value) {
      const { proTransponderQty } = this.parent;
      if (value) {
        return value <= proTransponderQty;
      } else {
        return true;
      }
    }),
});

export const onboardingTranspondersActivationValidator = yup.object().shape({
  transpondersToActivate: yup.array().of(
    yup.object().shape({
      number: validators.transponderNumber(),
      confirmNumber: validators.confirmTransponderNumber('number').required(TRANSPONDER_NUMBER_MATCH_ERROR_MSG),
    }),
  ),
});

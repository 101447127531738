import { FC, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Form } from 'antd';
import ScrollToTop from '../../../ScrollToTop/ScrollToTop';
import { Trans } from '@lingui/macro';
import { ApolloQueryResult } from '@apollo/client';
import {
  ITransactionActivityList,
  ITransactionActivityListInput,
} from '../../../../graphql/transactionsActivity.graphql';
import { ActivityAdvancedFilters } from './AdvanceFilters/ActivityAdvancedFilters';
import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { ActivityBaseSearch } from './BaseSearch/ActivityBaseSearch';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { MainLoader } from '../../../Loader/MainLoader';
import { GRAPHQL_DATE_FORMAT } from '../../../../utils/time.utils';
import { useFileApi } from '../../../../hooks/file/useFileApi';
import { activitySearchValidator } from '../../../../validators/dashboard/activity/activitySearch.validator';
import { validators } from '../../../../validators/validators';
import { usePaginationInfo } from '../../../../store/pagination/pagination.store';
import { initialEndDate, initialStartDate } from 'src/store/pagination/paginationDefaultValues';
import { useScroll } from 'src/hooks/screen/useScroll';
import { ACTIVITY_HEADER } from '../../references';

export interface ITransactionActivitySearch
  extends Omit<ITransactionActivityListInput, 'transponderNumber' | 'plateNumber'> {
  transponderOrPlateNumber?: string;
}

interface IActivitySearch {
  transactionActivityData: { transactionActivityList: ITransactionActivityList } | undefined;
  refetch: (
    variables?:
      | Partial<{
          transactionActivityListInput: ITransactionActivityListInput;
        }>
      | undefined,
  ) => Promise<
    ApolloQueryResult<{
      transactionActivityList: ITransactionActivityList;
    }>
  >;
  transactionActivityLoading: boolean;
}

export const ActivitySearch: FC<IActivitySearch> = ({
  transactionActivityData,
  refetch,
  transactionActivityLoading,
}) => {
  const [showAdvanceFilters, setShowAdvanceFilters] = useState(false);

  const {
    activitiesPagination: { size },
    changeActivitiesSearchParams,
    setAreActivitiesFiltersApplied,
  } = usePaginationInfo();
  const { loading, downloadFile } = useFileApi();

  const { scrollToElem } = useScroll();

  const methods = useForm<ITransactionActivitySearch>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(activitySearchValidator),
    defaultValues: {
      pagination: {
        pageSize: size,
        pageNumber: 1,
      },
      transponderOrPlateNumber: '',
      agencyName: '',
      endDate: '',
      startDate: '',
      searchDate: '',
      location: '',
      transactionType: '',
    },
  });

  const {
    handleSubmit,
    getValues,
    reset,
    formState: { isDirty },
  } = methods;
  const refToPrevSearchInput = useRef<ITransactionActivitySearch>({
    ...getValues(),
  });

  const onSubmit = async (searchInput: ITransactionActivitySearch) => {
    const { transponderNumber } = validators;
    const isTransponderNumberEntered = await transponderNumber().isValid(searchInput.transponderOrPlateNumber);
    const requestInput: ITransactionActivityListInput = {
      pagination: {
        pageSize: size,
        pageNumber: 1,
      },
      endDate: searchInput.endDate ? moment(searchInput.endDate).format(GRAPHQL_DATE_FORMAT) : initialEndDate,
      startDate: searchInput.startDate ? moment(searchInput.startDate).format(GRAPHQL_DATE_FORMAT) : initialStartDate,
      agencyName: searchInput?.agencyName,
      location: searchInput?.location,
      searchDate: searchInput?.searchDate,
      plateNumber: !isTransponderNumberEntered ? searchInput.transponderOrPlateNumber : undefined,
      transactionType: searchInput.transactionType || undefined,
      transponderNumber: isTransponderNumberEntered ? searchInput.transponderOrPlateNumber : undefined,
    };
    try {
      await refetch({
        transactionActivityListInput: requestInput,
      });

      refToPrevSearchInput.current = {
        ...requestInput,
      };
      changeActivitiesSearchParams(requestInput);

      setAreActivitiesFiltersApplied(isDirty);
    } catch (err) {
      console.log(err);
    } finally {
      scrollToElem(ACTIVITY_HEADER, true);
    }
  };

  const resetFilters = async () => {
    await onSubmit({
      pagination: {
        pageSize: size,
        pageNumber: 1,
      },
      transactionType: '',
      startDate: initialStartDate,
      endDate: initialEndDate,
    });

    await reset({
      ...getValues(),
      transactionType: '',
      agencyName: '',
      searchDate: '',
      startDate: '',
      endDate: '',
      transponderOrPlateNumber: '',
    });
    setAreActivitiesFiltersApplied(false);
    setShowAdvanceFilters(false);
  };

  const resetAndHideFilters = async () => {
    setShowAdvanceFilters(false);
    await resetFilters();
  };

  return (
    <div className="w-full pt-5 large-tablet:pt-0">
      <ScrollToTop />
      <MainLoader loading={transactionActivityLoading || loading} />
      <Form
        className="theme flex flex-col items-start"
        layout="vertical"
        size="large"
        onFinish={handleSubmit(onSubmit)}
      >
        <FormProvider {...methods}>
          <ActivityBaseSearch resetFilters={resetFilters} />
          <Button
            type="link"
            className="dashboard-links mb-8 block self-start md:mb-2"
            onClick={() => setShowAdvanceFilters(!showAdvanceFilters)}
          >
            <span className="mt-8 text-sm md:mt-auto">
              {showAdvanceFilters ? (
                <>
                  <Trans>Hide Advanced Search</Trans> <MinusSquareOutlined className="ml-2" />
                </>
              ) : (
                <>
                  <Trans>Show Advanced Search</Trans>
                  <PlusSquareOutlined className="ml-2" />
                </>
              )}
            </span>
          </Button>
          {showAdvanceFilters ? <ActivityAdvancedFilters hideAdvanceFilters={resetAndHideFilters} /> : null}
          <Button
            className="mb-10 mt-5 h-5 w-full self-end rounded-md sm:mt-auto sm:w-auto"
            onClick={() =>
              downloadFile('transactionActivities', {
                ...refToPrevSearchInput.current,
                pagination: {
                  ...refToPrevSearchInput.current.pagination,
                  pageSize: transactionActivityData?.transactionActivityList.totalElements,
                },
              })
            }
            disabled={!transactionActivityData?.transactionActivityList.totalElements}
          >
            <span className="text-sm font-bold leading-none">
              <Trans>EXPORT AS CSV</Trans>
            </span>
          </Button>
        </FormProvider>
      </Form>
    </div>
  );
};

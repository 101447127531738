import { useRecaptcha } from '../../../recaptcha/useRecaptcha';
import moment from 'moment/moment';
import { GRAPHQL_DATE_FORMAT, US_LOCAL_TIME_FORMAT } from '../../../../utils/time.utils';
import {
  ISignUpVehiclesTranspondersInput,
  ISignUpVehiclesTranspondersOutput,
  SIGN_UP_VEHICLES_AND_TRANSPONDERS,
} from '../../../../graphql/signUp/signUpVehiclesTransponders.graphql';
import { concatRoutes, OnboardingStepPath } from '../../../../types/routes';
import { DEFAULT_VEHICLE } from '../../../../components/Onboarding/Vehicles/initialState';
import { useScroll } from '../../../screen/useScroll';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { mapVehicles } from '../../../../components/Onboarding/Vehicles/vehicleForm.utils';
import { useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { getVehiclesSchemaForOnboarding } from '../../../../validators/onboarding/vehicles/vehicle.validator';
import { useOnboardingStore } from '../../../../store/onboarding/onboarding.store';
import { useRouteMonitor } from '../../../location/useRouteMonitor';
import {
  getStepsByUserTypeAndFlowType,
  OnboardingFlowType,
  OnboardingStepsPayg,
  OnboardingStepsResidentActivateFlow,
} from '../../../../types/onboarding.types';
import { IVehicle } from '../../../../graphql/vehicles.graphql';
import { ActivateCalls } from 'src/components/Onboarding/ActivateFlow/ActivateCalls';
import { useOnboardingErrors } from 'src/store/errors/onboardingErrors.store';

export type IVehicleInputFormType = { vehicles: [IVehicle] };

export const useOnboardingVehicle = () => {
  const { scrollToElem, scrollToError } = useScroll();
  const [isRedirectPromptShown, setIsRedirectPromptShown] = useState(false);

  const {
    onboardingInfo: {
      vehicles,
      userType,
      accountType,
      miniTransponderQty,
      proTransponderQty,
      hasTranspondersToActivate,
      onboardingFlowType,
      accountId,
    },
    updateOnboardingStore,
  } = useOnboardingStore();

  const history = useRouteMonitor();

  const vehicleLimit =
    accountType === 'PAYG' || hasTranspondersToActivate ? 100 : miniTransponderQty + proTransponderQty * 5;

  const [showLimitMessage, setShowLimitMessage] = useState<boolean>(false);
  let steps =
    userType && onboardingFlowType
      ? getStepsByUserTypeAndFlowType(userType, onboardingFlowType)
      : OnboardingStepsResidentActivateFlow;
  if (accountType === 'PAYG') {
    steps = OnboardingStepsPayg;
  }

  const [removedIndex, setRemovedIndex] = useState(undefined);

  const [signUpVehiclesAndTransponders, { loading: signUpLoading }] = useMutation<
    { signUpVehiclesTransponders: ISignUpVehiclesTranspondersOutput },
    { signUpVehiclesTranspondersInput: ISignUpVehiclesTranspondersInput }
  >(SIGN_UP_VEHICLES_AND_TRANSPONDERS);

  const methods = useForm({
    resolver: yupResolver(getVehiclesSchemaForOnboarding(accountType)),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      vehicles: vehicles?.length ? mapVehicles(vehicles) : [DEFAULT_VEHICLE],
    } as any,
  });

  const {
    control,
    handleSubmit,
    getValues,
    formState: { isValid, isSubmitted, isValidating, isSubmitSuccessful, isSubmitting, errors },
  } = methods;

  const {
    fields: vehiclesFields,
    update: vehiclesUpdate,
    remove: vehiclesRemove,
  } = useFieldArray({
    control,
    name: 'vehicles',
  });
  const { setOnboardingState, canProceedToNextStep } = useOnboardingErrors();
  const { validateVehicles } = ActivateCalls();
  const vehiclesCount = getValues('vehicles')?.length;
  const onRemoveVehicle = (index: number) => {
    vehiclesRemove(index);
    const filteredVehicles = vehicles?.filter((vehicle, _index) => _index !== index);
    updateOnboardingStore({ vehicles: filteredVehicles });

    if (index <= vehicleLimit) {
      setShowLimitMessage(false);
    }
  };

  const addVehicle = () => {
    if (vehiclesCount >= vehicleLimit && onboardingFlowType === OnboardingFlowType.PURCHASE) {
      setShowLimitMessage(true);
    } else {
      vehiclesUpdate(vehiclesCount, DEFAULT_VEHICLE);
      setRemovedIndex(vehiclesCount);
      setTimeout(() => {
        scrollToElem(`vehicles.[${vehiclesCount}].header`, true);
      }, 100);
    }
  };

  useEffect(() => {
    if (canProceedToNextStep && isSubmitted && isSubmitSuccessful) {
      history.goToPath(concatRoutes(OnboardingStepPath.EASY_PAY));
    }
  }, [isSubmitted, isSubmitSuccessful, canProceedToNextStep]);

  const onSubmit = ({ vehicles }: IVehicleInputFormType) => {
    const vehiclesWithMoments = vehicles.map((vehicle) => {
      const transformed = { ...vehicle } as any;
      transformed.effectiveStartDate = moment(vehicle.effectiveStartDate);
      transformed.effectiveStartTime = moment(vehicle.effectiveStartTime, US_LOCAL_TIME_FORMAT);
      if (vehicle.effectiveEndDate) {
        transformed.effectiveEndDate = moment(vehicle.effectiveEndDate);
        transformed.effectiveEndTime = moment(vehicle.effectiveEndTime, US_LOCAL_TIME_FORMAT);
      }
      return transformed;
    });
    const vehicleAndTranspondersInput: ISignUpVehiclesTranspondersInput = {
      accountId: accountId,
      miniTranspondersCount: miniTransponderQty,
      proTranspondersCount: proTransponderQty,
      portableTranspondersCount: 0,
      vehicleUploadBatchId: '',
      vehicleUploadCount: '0',
      vehicles: vehiclesWithMoments.map((vehicle) => {
        const transformed = { ...vehicle } as any;
        transformed.effectiveStartDate = moment(vehicle?.effectiveStartDate).format(GRAPHQL_DATE_FORMAT);
        transformed.effectiveStartTime = moment(vehicle?.effectiveStartTime).format('hh:mm');
        if (vehicle.effectiveEndDate) {
          transformed.effectiveEndDate = moment(vehicle?.effectiveEndDate).format(GRAPHQL_DATE_FORMAT);
          transformed.effectiveEndTime = moment(vehicle?.effectiveEndTime).format('hh:mm');
        }
        if (vehicle.isTrailer === true) {
          transformed.vehicleModel = '';
          transformed.vehicleMake = '';
          transformed.vehicleYear = '';
        }
        delete transformed.notKnown;
        return transformed;
      }),
    };
    if (!isUserOverTheLimit) {
      if (hasTranspondersToActivate && vehicleAndTranspondersInput.vehicles) {
        validateVehicles(vehicleAndTranspondersInput.vehicles);
        updateOnboardingStore({
          vehicles: vehiclesWithMoments,
        });
        setOnboardingState(undefined, undefined, vehicleAndTranspondersInput.vehicles);
      } else {
        signUpVehiclesAndTransponders({
          variables: { signUpVehiclesTranspondersInput: vehicleAndTranspondersInput },
        }).then((response) => {
          const result = response.data == null ? undefined : response.data;
          updateOnboardingStore({
            vehicles: vehiclesWithMoments,
            transponderSerialNumbers: {
              miniTransponderSerialNumber: result?.signUpVehiclesTransponders.miniTransponderSerialNumber,
              proTransponderSerialNumber: result?.signUpVehiclesTransponders.proTransponderSerialNumber,
            },
            totalTranspondersCost: response?.data?.signUpVehiclesTransponders.totalTranspondersCost,
            applicableTax: response?.data?.signUpVehiclesTransponders.tax,
          });
          history.goToPath(OnboardingStepPath.EASY_PAY);
        });
      }
    }
  };

  const {
    submitWithRecaptcha,
    recaptchaChallengeElem,
    loading: recaptchaLoading,
  } = useRecaptcha<IVehicleInputFormType>({ onSubmit });

  useEffect(() => {
    scrollToError(errors);
  }, [errors]);

  const [isUserOverTheLimit, setIsUserOverTheLimit] = useState(false);

  useEffect(() => {
    if (vehiclesCount > vehicleLimit) setIsUserOverTheLimit(true);
    else setIsUserOverTheLimit(false);
  }, [vehicleLimit, vehiclesCount]);

  const isUserTryingToSubmit = isSubmitting || isSubmitted;

  const isFormDataInvalid = useMemo(() => {
    return isSubmitted && !isValid && !isValidating && !isSubmitSuccessful && !isSubmitting;
  }, [isSubmitted, isValid, isValidating, isSubmitSuccessful, isSubmitting]);

  return {
    recaptchaChallengeElem,
    setShowLimitMessage,
    form: {
      handleSubmit: handleSubmit,
      onSubmit: submitWithRecaptcha,
      hideRedirectPrompt: () => setIsRedirectPromptShown(false),
      showRedirectPrompt: () => setIsRedirectPromptShown(true),
      addVehicle,
      onRemoveVehicle,
      vehiclesFields,
      methods,
      vehiclesCount,
    },
    formState: {
      steps,
      removedIndex,
      loading: recaptchaLoading || signUpLoading,
      isRedirectPromptShown,
      numOfVehiclesToBeRemoved: vehiclesCount - vehicleLimit,
      shouldPreventAnyVehicles: vehicleLimit === 0,
      showLimitMessage,
      isUserTryingToSubmitOverTheLimit: isUserOverTheLimit && isUserTryingToSubmit,
      isFormDataInvalid,
    },
  };
};

import { useEffect } from 'react';
import { DashboardTabHeader } from '../DashboardTabHeader';
import { Trans } from '@lingui/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { TransponderList } from './TransponderList/TransponderList';
import { TransponderActivation } from './TransponderActivation';
import { useTransponderStore } from '../../../store/dashboard/transponder.store';
import { useParams } from 'react-router-dom';
import { ParamTypes } from '../Dashboard';
import TransponderPurchase from './TransponderPurchase/TransponderPurchase';
import { useQuery } from '@apollo/client';
import { ITransponderInput, GET_TRANSPONDER_LIST, ITransponderList } from 'src/graphql/transponders.graphql';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import { MainLoader } from 'src/components/Loader/MainLoader';
import { useDashboardStore } from '../../../store/dashboard/dashboard.store';
import { useScroll } from '../../../hooks/screen/useScroll';
import {
  ACTIVATE_NEW_TRANSPONDER_SECTION_ID,
  PURCHASE_TRANSPONDER_SECTION_ID,
  TRANSPONDERS_HEADER,
} from '../references';
import { checkIfElementExists } from '../checkIfElementExists';
import EmptyStateMessage, { IListType } from 'src/components/EmptyStates/EmptyStateMessage';
import { usePaginationInfo } from 'src/store/pagination/pagination.store';
import useScrollOnPaginationChange from 'src/hooks/pagination/useScrollOnPaginationChange';

export const Transponders = () => {
  const {
    transpondersPagination: { size, page },
  } = usePaginationInfo();

  const transponderListInput = {
    pagination: {
      pageSize: size,
      pageNumber: page,
    },
  };

  const {
    data: transpondersData,
    refetch,
    loading: transponderListLoading,
  } = useQuery<{ transponderList: ITransponderList }, { transponderListInput: ITransponderInput }>(
    GET_TRANSPONDER_LIST,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        transponderListInput: {
          ...transponderListInput,
          filter: { sortColumn: 'status' },
        },
      },
    },
  );

  const { scrollToElem } = useScroll();

  useScrollOnPaginationChange({ data: transpondersData, scrollId: TRANSPONDERS_HEADER });

  const {
    setTransponderToEdit,
    setEditMode,
    setActivateMode,
    store: { activateMode, editMode },
  } = useTransponderStore();

  const { activeTab } = useParams<Partial<ParamTypes>>();

  useEffect(() => {
    setTransponderToEdit(undefined);
    setEditMode(false);
    setActivateMode(false);
  }, [activeTab]);

  const {
    setActivateTransponderMode,
    setPurchaseTransponderMode,
    store: { activateTransponderMode, purchaseTransponderMode },
  } = useDashboardStore();

  const activateTransponderSectionExists = checkIfElementExists(ACTIVATE_NEW_TRANSPONDER_SECTION_ID);
  const purchaseTransponderSectionExists = checkIfElementExists(PURCHASE_TRANSPONDER_SECTION_ID);
  const transponderListLength = transpondersData?.transponderList?.transponders?.length;

  useEffect(() => {
    if (activateTransponderMode) {
      setActivateMode(true);
      if (activateTransponderSectionExists) {
        scrollToElem(ACTIVATE_NEW_TRANSPONDER_SECTION_ID, true);
        setActivateTransponderMode(false);
      }
    } else if (purchaseTransponderMode && purchaseTransponderSectionExists) {
      scrollToElem(PURCHASE_TRANSPONDER_SECTION_ID, true);
      setPurchaseTransponderMode(false);
    }
  }, [
    activateTransponderMode,
    purchaseTransponderMode,
    purchaseTransponderSectionExists,
    activateTransponderSectionExists,
  ]);

  return (
    <>
      <MainLoader loading={transponderListLoading} />
      <DashboardTabHeader
        type="transponders"
        bulkUploadLinkName={<Trans>Transponder Status Upload</Trans>}
        templateUploadButtonName={<Trans>Download Bulk Upload Template</Trans>}
        headline={<Trans>My Transponders</Trans>}
      />

      {!editMode && !activateMode ? (
        <CustomButton
          icon={<FontAwesomeIcon icon={faPlus} className="mr-2 " />}
          onClick={() => setActivateMode(true)}
          content="Activate New Transponder"
          className="mb-8"
        />
      ) : null}
      {activateMode ? <TransponderActivation /> : null}
      {transponderListLength && transponderListLength > 0 ? (
        <TransponderList refetch={refetch} transpondersData={transpondersData} />
      ) : (
        <EmptyStateMessage listType={IListType.TRANSPONDERS} />
      )}
      <TransponderPurchase />
    </>
  );
};

import { InputNumber } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import '../../../pages/Onboarding/Onboarding.scss';
import { useEffect } from 'react';

export interface ITransponderQuantityControllerProps {
  fieldName: string;
  label: string;
}

export const TransponderController = ({ fieldName, label }: ITransponderQuantityControllerProps) => {
  const { control, setValue, watch } = useFormContext();

  useEffect(() => {
    const value = watch(fieldName).toString();
    if (value.includes('.')) {
      setValue(fieldName, Number(value.substring(0, value.indexOf('.'))));
    }
  }, [watch(fieldName)]);

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field }) => (
        <InputNumber
          {...field}
          precision={0}
          decimalSeparator={','}
          id={fieldName}
          aria-label={label}
          min={0}
          maxLength={5}
          max={99999}
          onChange={(value) => {
            if (value === null) {
              setValue(fieldName, 0);
            } else {
              setValue(fieldName, value);
            }
          }}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          className="text-large md:text-xl"
        ></InputNumber>
      )}
    />
  );
};

import React, { useEffect, useState } from 'react';
import { Trans } from '@lingui/macro';
import '../MyAccount.scss';

import {
  ITransponderList,
  ITransponderInput,
  GET_TRANSPONDER_LIST,
  ITransponder,
} from 'src/graphql/transponders.graphql';
import { useQuery } from '@apollo/client';
import { Radio } from 'antd';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import { MainLoader } from 'src/components/Loader/MainLoader';
import ScrollToTop from 'src/components/ScrollToTop/ScrollToTop';
import useScreenSize from 'src/hooks/screen/useScreenSize';
import ScreenSize from 'src/types/ScreenSize.enum';
import BackLink from '../BackLink';
import TranspondersWithPlansList from './TranspondersWithPlansList';
import AddPlanProccess from './AddPlanProccess';
import { addPlanStore } from 'src/store/addPlan/addPlan.store';
import RemovePlanModal from './RemovePlanModal';
import { usePaginationInfo } from 'src/store/pagination/pagination.store';
import { useAccountSettings } from 'src/store/account/accountSettings.store';
import LeaveSiteConfirmationModal from 'src/components/Modals/ConfirmationModal/LeaveSiteConfirmationModal';
import useTransponderPrices from 'src/hooks/domain/dashboard/transponders/useTransponderPrices';
import PurchaseTransponderWithPlan from './PurchaseTransponderWithPlan/PurchaseTransponderWithPlan';

export default function PinellasBayway(): React.ReactElement {
  const [isUnsavedModalShown, setIsUnsavedModalShown] = useState(false);
  const [hasAlreadySetIninitialCheck, setHasAlreadySetIninitialCheck] = useState(false);
  const [alreadyHasPlan, setAlreadyHasPlan] = useState(false);
  const [tranponderToAddPlan, setTranponderToAddPlan] = useState<ITransponder | null>(null);
  const [isAddModeOn, setIsAddModeOn] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [isPurchaseMode, setIsPurchaseMode] = useState(false);
  const [isTryingToChangeSelection, setIsTryingToChangeSelection] = useState(false);
  const [hasTransponderWithPlan, sethasTransponderWithPlan] = useState(false);
  const {
    transpondersPagination: { size: transpondersSize, page: transpondersPage },
    resetPagination,
  } = usePaginationInfo();
  const { setIsTabDirty } = useAccountSettings();

  const { data: transpondersListData, loading: transponderListLoading } = useQuery<
    { transponderList: ITransponderList },
    { transponderListInput: ITransponderInput }
  >(GET_TRANSPONDER_LIST, {
    notifyOnNetworkStatusChange: true,
    variables: {
      transponderListInput: {
        pagination: {
          pageSize: transpondersSize,
          pageNumber: transpondersPage,
        },
        filter: { sortColumn: 'planDateAdded' },
        filterHasPlan: false,
      },
    },
  });

  const checkIfAnyTranspondersHasPlan = (transponderList: ITransponderList): boolean => {
    return transponderList?.transponders.some((transponder) => transponder.plan !== null);
  };
  const onChange = () => {
    setAlreadyHasPlan(!alreadyHasPlan);
    setIsTryingToChangeSelection(true);
    setIsAddModeOn(false);
  };
  const { nextStep, currentStep, resetStepper } = addPlanStore();
  const { screenSize } = useScreenSize();
  const { PBCPrice } = useTransponderPrices();

  useEffect(() => {
    if (!hasAlreadySetIninitialCheck && transpondersListData) {
      sethasTransponderWithPlan(checkIfAnyTranspondersHasPlan(transpondersListData?.transponderList));
      setAlreadyHasPlan(checkIfAnyTranspondersHasPlan(transpondersListData?.transponderList));
      setHasAlreadySetIninitialCheck(true);
    }
  }, [transpondersListData]);

  const onRemovePlans = () => {
    if (transpondersListData && checkIfAnyTranspondersHasPlan(transpondersListData?.transponderList)) {
      setShowRemoveModal(true);
    } else if (isAddModeOn) {
      setIsUnsavedModalShown(true);
    } else {
      setAlreadyHasPlan(false);
      setIsTryingToChangeSelection(false);
    }
  };
  useEffect(() => {
    resetStepper();
  }, []);

  const onUnsavedDataModalConfirm = () => {
    setIsUnsavedModalShown(false);
    setIsAddModeOn(false);
    setTranponderToAddPlan(null);
    setAlreadyHasPlan(false);
    resetStepper();
    resetPagination();
  };

  useEffect(() => {
    if (tranponderToAddPlan !== null || isAddModeOn || isTryingToChangeSelection) {
      setIsTabDirty(true);
    }
  }, [tranponderToAddPlan, isAddModeOn]);

  return (
    <>
      {' '}
      {screenSize < ScreenSize.XL ? (
        <div className="mb-5 ml-5">
          <BackLink />
        </div>
      ) : null}
      <div className="card pinellas-card  w-full pb-0">
        <div
          className={`mb-4 flex w-full flex-col justify-between pb-2 sm:flex-row ${alreadyHasPlan ? 'border-b-2' : ''}`}
        >
          <p className="my-0 mr-8 mt-1  text-left font-bold">
            <Trans>Would you like to add the Pinellas Bayway Plan onto your account?</Trans>
          </p>
          <Radio.Group className="radio-btn-group mb-10" value={alreadyHasPlan}>
            <Radio value={true} onChange={onChange}>
              <Trans>Yes</Trans>
            </Radio>
            <Radio value={false} onChange={onRemovePlans}>
              <Trans>No</Trans>
            </Radio>
          </Radio.Group>
        </div>
        {isPurchaseMode ? (
          <PurchaseTransponderWithPlan setIsPurchaseMode={setIsPurchaseMode} isPurchaseMode={isPurchaseMode} />
        ) : (
          <>
            {alreadyHasPlan ? (
              !hasTransponderWithPlan && !isAddModeOn ? (
                <div className="my-4">
                  <div className="my-4">
                    <Trans>You currently don’t have the Pinellas Bayway Plan on any of your transponders.</Trans>
                  </div>
                  <div>
                    {!isAddModeOn ? (
                      <CustomButton
                        content="Purchase & Manage Plans"
                        onClick={() => setIsAddModeOn(true)}
                        className="my-3"
                      />
                    ) : null}
                  </div>
                  {isAddModeOn && currentStep === 0 ? (
                    <TranspondersWithPlansList
                      shouldDisplayAllTransponders={isAddModeOn}
                      setTransponderToAddPlan={setTranponderToAddPlan}
                      transponderToAddPlan={tranponderToAddPlan}
                    />
                  ) : null}
                </div>
              ) : (
                <div>
                  {' '}
                  {isAddModeOn && currentStep === 0 ? (
                    <div className="my-6">
                      <Trans>
                        You can either{' '}
                        <p
                          className="inline cursor-pointer text-primary-green4 underline"
                          onClick={() => setIsPurchaseMode(true)}
                        >
                          <Trans>purchase a new transponder with the Pinellas Bayway Plan</Trans>
                        </p>
                        , or you may choose to add the Pinellas Bayway Plan onto one of your existing transponders
                        listed below for ${PBCPrice}.{' '}
                      </Trans>
                    </div>
                  ) : null}
                  {currentStep === 0 ? (
                    <TranspondersWithPlansList
                      shouldDisplayAllTransponders={isAddModeOn}
                      setTransponderToAddPlan={setTranponderToAddPlan}
                      transponderToAddPlan={tranponderToAddPlan}
                    />
                  ) : null}
                  {isAddModeOn ? null : (
                    <div className="mt-5 md:ml-3">
                      <CustomButton content="Purchase & Manage Plans" onClick={() => setIsAddModeOn(true)} />
                    </div>
                  )}
                </div>
              )
            ) : null}
            {tranponderToAddPlan !== null && currentStep === 0 ? (
              <div className="ml-auto mt-6 flex w-1/2 flex-col gap-4 sm:w-1/3">
                <div className="ml-auto w-32">
                  {' '}
                  <Trans>
                    Subtotal: <b>${PBCPrice.toFixed(2)}</b>
                  </Trans>
                </div>
                <CustomButton content="Continue" onClick={() => nextStep()} type="button" />
              </div>
            ) : null}
          </>
        )}
      </div>
      {currentStep !== 0 && !isPurchaseMode ? (
        <div className="card multistep-container mx-0">
          <AddPlanProccess tranponderToAddPlan={tranponderToAddPlan && tranponderToAddPlan} />
        </div>
      ) : null}
      <div className="w-full pt-5  large-tablet:pt-0">
        <MainLoader loading={transponderListLoading} />
        <ScrollToTop />

        <div className="card pinellas-card mt-10 flex w-full">
          <div className="w-full text-left">
            <h3 className="mb-6 text-primary-blue1">
              <Trans>About Pinellas Bayway Plan</Trans>
            </h3>
            <div className="mb-10 flex flex-col justify-between sm:flex-row">
              <div className="w-full sm:w-1/2">
                <p>
                  <Trans>
                    This is a yearly pass system for two-axle vehicles that expires on September 30th regardless of when
                    the $50 pass per vehicle was purchased. Pass is valid at all three Pinellas Bayway Plazas and
                    available to all motorists.
                  </Trans>
                </p>
              </div>
              <div className="ml-auto w-full text-left sm:ml-6 sm:w-1/2">
                <h4 className="mb-6 text-xl font-normal">
                  <Trans>Cost</Trans>
                </h4>
                <div>
                  <p className="my-0 text-3xl">
                    <Trans>${PBCPrice}/year</Trans>
                  </p>
                  <span className="text-sm uppercase">
                    <Trans>per enrolled transponder</Trans>
                  </span>
                </div>
              </div>
            </div>
            <div>
              <h3 className="mb-6 text-xl font-normal">
                <Trans>How to get</Trans>
              </h3>
              <p>
                <Trans>
                  You may activate the Pinellas Bayway Plan by selecting ‘yes’ above, and then choosing which
                  transponder(s) you’d like to add the plan to. You may also purchase the plan in person at the Pinellas
                  Bayway Administration Building located at the East Plaza or by calling the SunPass Customer Service
                  Center, 1-888-TOLL-FLA (1-888-865-5352).
                </Trans>
              </p>
            </div>
          </div>
        </div>
        <RemovePlanModal showModal={showRemoveModal} onOk={() => setShowRemoveModal(false)} />
        <LeaveSiteConfirmationModal
          showModal={isUnsavedModalShown}
          onOk={onUnsavedDataModalConfirm}
          onCancel={() => setIsUnsavedModalShown(false)}
        />
      </div>
    </>
  );
}

import { useQuery } from '@apollo/client';
import MoneyIcon from '../assets/Money-Icon.svg';
import {
  GET_INVOICE_TRANSACTIONS,
  IInvoiceTransactionList,
  IInvoiceTransactionListInput,
} from 'src/graphql/invoiceTransactions.graphql';
import UnpaidTollsItem from './UnpaidTollsItem';
import './UnpaidTolls.scss';
import { endDate, startDate } from './unpaidTolls.utils';
import { usePaginationInfo } from 'src/store/pagination/pagination.store';
import Pagination from 'src/components/Pagination/Pagination';
import { MainLoader } from 'src/components/Loader/MainLoader';
import useScrollOnPaginationChange from 'src/hooks/pagination/useScrollOnPaginationChange';
import { UNPAID_TOLLS_HEADER } from '../references';

const UnpaidTollsList = () => {
  const {
    invoiceTransactionsPagination: { size, page },
  } = usePaginationInfo();

  const { data: unpaidTollsData, loading } = useQuery<
    { invoiceTransactionList: IInvoiceTransactionList },
    { invoiceTransactionListInput: IInvoiceTransactionListInput }
  >(GET_INVOICE_TRANSACTIONS, {
    variables: {
      invoiceTransactionListInput: {
        pagination: { pageSize: size, pageNumber: page },
        filterPositiveAmtDue: true,
        startDate,
        endDate,
      },
    },
  });

  useScrollOnPaginationChange({ data: unpaidTollsData, scrollId: UNPAID_TOLLS_HEADER });

  const unpaidTolls = unpaidTollsData?.invoiceTransactionList?.invoiceTransactions;
  const totalNumberOfUnpaidTolls = unpaidTollsData?.invoiceTransactionList?.totalElements || 0;
  const sumOfUnpaidTolls = unpaidTollsData?.invoiceTransactionList?.sumOfInvTransactionAmt?.toFixed(2);

  return (
    <>
      <MainLoader loading={loading} />
      {unpaidTolls && unpaidTolls.length > 0 ? (
        <div id={UNPAID_TOLLS_HEADER} className="mb-8 w-full lg:w-1/2">
          <div className="mb-8 flex flex-col justify-between sm:flex-row sm:items-center">
            <div className="mb-8 mt-4 flex items-center sm:mb-0">
              <h1 className="mr-2 text-3xl font-bold">Unpaid Tolls</h1>
              <div className="unpaid-tolls-number">{totalNumberOfUnpaidTolls}</div>
            </div>
            <div className="flex pt-2 text-sm tracking-tight">
              You currently owe <span className="unpaid-tolls-amount">${sumOfUnpaidTolls}</span>
            </div>
          </div>
          <div>
            {unpaidTolls.map((item) => (
              <UnpaidTollsItem invoice={item} key={item.index} />
            ))}
          </div>
          {totalNumberOfUnpaidTolls > size ? (
            <Pagination count={totalNumberOfUnpaidTolls} type={'invoiceTransactions'} />
          ) : null}
        </div>
      ) : (
        <div className="my-20 flex w-full flex-col items-center lg:w-1/2">
          <img src={MoneyIcon} alt="money icon" className="mb-8 w-20" />
          <h1 className="mb-8">Nice Job!</h1>
          <p className="text-center text-2xl">You don&apos;t have any unpaid tolls at this moment.</p>
        </div>
      )}
    </>
  );
};

export default UnpaidTollsList;

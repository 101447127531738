import { Form, Radio } from 'antd';
import { Trans } from '@lingui/macro';
import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import { ITransactionActivityListInput } from '../../../../../graphql/transactionsActivity.graphql';

export const SearchDate = () => {
  const { control, watch } = useFormContext<ITransactionActivityListInput>();
  return (
    <div className="my-4 w-full sm:my-0">
      <Form.Item label={<Trans id="Date Type" />} className="theme no-label-padding">
        <Controller
          name="searchDate"
          control={control}
          render={({ field }) => (
            <Radio.Group
              {...field}
              id="searchDate"
              onChange={(value) => {
                field.onChange(value);
              }}
            >
              <Radio value="Posted Date" checked={watch('searchDate') === 'Posted Date'}>
                <Trans id="Posted Date" />
              </Radio>
              <Radio value="Transaction Date" checked={watch('searchDate') === 'Transaction Date'}>
                <Trans id="Transaction Date" />
              </Radio>
            </Radio.Group>
          )}
        />
      </Form.Item>
    </div>
  );
};

import FrequentlyAskedQuestions from './FrequentlyAskedQuestions';
import UnpaidTollsHeader from './UnpaidTollsHeader';
import UnpaidTollsList from './UnpaidTollsList';
import PaperInvoiceHelp from './PaperInvoiceHelp';

const UnpaidTolls = () => {
  return (
    <>
      <UnpaidTollsHeader />
      <div className="flex flex-col gap-x-8 lg:flex-row">
        <UnpaidTollsList />
        <FrequentlyAskedQuestions />
      </div>
      <PaperInvoiceHelp />
    </>
  );
};

export default UnpaidTolls;

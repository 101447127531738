import React, { FC } from 'react';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from 'src/theme/theme';

interface ICancelButton {
  handleClick: () => void;
}

const CancelButton: FC<ICancelButton> = ({ handleClick }) => {
  return (
    <button className="flex cursor-pointer items-center" onClick={handleClick} type="button">
      <FontAwesomeIcon icon={faAngleLeft} color={colors.primary.blue1} size="2x"></FontAwesomeIcon>
      <div className="ml-2 font-bold text-primary-blue1 underline">Cancel</div>
    </button>
  );
};

export default CancelButton;

import * as React from 'react';
import { FC } from 'react';
import NewPaymentMethodContent from '../PaymentMethods/NewPaymentMethodContent';
import { defaultPaymentValues } from '../NewPaymentMethodsDefaultValues';
import { IPaymentMethod } from '../Payments.interfaces';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import { PaymentMethodType } from '../Payments.enums';

interface INewPaymentMethodAddPlanProccess {
  onSubmit: (paymentMethod: IPaymentMethod) => void;
  isLoading: boolean;
  onBack: () => void;
  showCVV: boolean;
}
const NewPaymentMethodAddPlanProccess: FC<INewPaymentMethodAddPlanProccess> = ({
  onSubmit,
  isLoading,
  onBack,
  showCVV,
}) => {
  const navigationElement: JSX.Element = (
    <div className="flex flex-col-reverse justify-center gap-5 py-6 sm:flex-row sm:px-0">
      <CustomButton
        type="button"
        content="Cancel"
        isPrimary={false}
        onClick={() => {
          onBack();
        }}
      />
      <CustomButton type="submit" content="Continue" />
    </div>
  );

  return (
    <div>
      <NewPaymentMethodContent
        defaultFormValues={defaultPaymentValues}
        isOnboardingPayment={false}
        isLoading={isLoading}
        onSubmit={onSubmit}
        navigationElements={navigationElement}
        defaultPaymentMethod={PaymentMethodType.CARD}
        shouldBeWrappedInCard={false}
        showCVV={showCVV}
        shouldShowSaveForFutureCheckBox={true}
      />
    </div>
  );
};
export default NewPaymentMethodAddPlanProccess;

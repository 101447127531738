import { i18n } from '@lingui/core';

export const PAYMENT_TYPE_REQUIRED_ERROR_MSG = i18n._('Payment Type Method is required');
export const CREDIT_CARD_NUMBER_INVALID_MSG = i18n._('Please enter a valid credit card number');
export const CREDIT_CARD_NUMBER_NOT_SUPPORTED_MSG = i18n._('Valid card types are Visa, Mastercard, Discover and Amex');
export const ROUTING_NUMBER_INVALID_MSG = i18n._('Please enter a valid routing number');
export const ACCOUNT_NUMBER_INVALID_MSG = i18n._('Please enter a valid account number');
export const LAST_NAME_REQUIRED = i18n._('Last name is required');
export const FIRST_NAME_REQUIRED = i18n._('First name is required');
export const SECURITY_CODE_INVALID_MSG = i18n._('Enter valid security code');
export const EXPIRATION_MONTH_INVALID_MSG = i18n._('Enter a valid expiration month and year');
export const EXPIRATION_MONTH_REQUIRED_MSG = i18n._('Expiration month is required');
export const EXPIRATION_YEAR_INVALID_MSG = i18n._('Expiration year is invalid');
export const EXPIRATION_YEAR_REQUIRED_MSG = i18n._('Expiration year is required');
export const PAYMENT_METHOD_REQUIRED_ERROR_MSG = i18n._('Payment method is required');
export const AMOUNT_REQUIRED_ERROR_MSG = i18n._('Amount is required');
export const AMOUNT_AND_PAYMENT_METHOD_REQUIRED_MSG = i18n._('Please enter an amount and a payment method to proceed');
export const ACCOUNT_NUMBER_MATCH_ERROR_MSG = i18n._('Numbers must match');
export const ACCOUNT_TYPE_REQUIRED_ERROR_MSG = i18n._('Account type is required');
export const ADD_FUNDS_MINIMUM_AMOUNT_REQUIRED_ERROR_MSG = i18n._('Minimum amount for adding funds is $5');
export const EASY_PAY_REQUIRED_ERROR_MSG = i18n._('Easy Pay option is required');

import { Form, Select } from 'antd';
import { t, Trans } from '@lingui/macro';
import { Controller, useFormContext } from 'react-hook-form';
import { LabelValue } from '../../../../types/LabelValue.interface';
import React from 'react';
import { useAddressFields } from '../../../../hooks/domain/address/useAddressFields';
import useCountryOptions from '../../../../hooks/domain/address/useCountryOptions';
import { IAddressFormElemBaseProps } from './address.types';
const { Option } = Select;
export const Country = ({ isAddressUsedForPayment, disabled, className }: IAddressFormElemBaseProps) => {
  const {
    addressFieldsState: { countryField, countyField },
  } = useAddressFields(isAddressUsedForPayment);
  const { control, setValue, trigger } = useFormContext();
  const { countryOptions } = useCountryOptions();
  return (
    <div className={className}>
      <Form.Item
        label={<Trans>Country</Trans>}
        hasFeedback
        validateStatus={countryField.errorMsg ? 'error' : ''}
        help={countryField.errorMsg}
        required
        className="theme"
      >
        <Controller
          name={countryField.name}
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              disabled={disabled}
              key={countryField.name}
              id={countryField.name}
              onChange={async (value) => {
                field.onChange(value);
                setValue('state', undefined);
                setValue('county', undefined);
                await trigger(countyField.name);
              }}
              optionFilterProp="children"
              aria-label={t`Country`}
              showSearch
            >
              {countryOptions?.map((country: LabelValue) => (
                <Option key={country.value} value={country.value}>
                  {country.label}
                </Option>
              ))}
            </Select>
          )}
        />
      </Form.Item>
    </div>
  );
};

import { AnyObject } from 'yup/lib/types';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_CREDIT_CARD_TYPES } from '../../../graphql/creditCardTypes.graphql';
import { bankAccountSchema, creditCardSchema, paymentSchema } from './payment.validator';

interface IUsePaymentValidatorSchema {
  bankSchema?: any;
  cardSchema?: any;
  wholePaymentSchema?: any;
  areCardTypesLading: boolean;
}

export const usePaymentValidatorSchema = (showCVV = false, creditCardOnly = false): IUsePaymentValidatorSchema => {
  const [bankSchema, setBankSchema] = useState<AnyObject>();
  const [cardSchema, setCreditCardSchema] = useState<AnyObject>();
  const [wholePaymentSchema, setWholePaymentSchema] = useState<AnyObject>();
  const { data, loading: areCardTypesLading } = useQuery<{ creditCardTypes: string[] }>(GET_CREDIT_CARD_TYPES);

  useEffect(() => {
    setBankSchema(bankAccountSchema());
    setCreditCardSchema(creditCardSchema(data?.creditCardTypes || [], creditCardOnly, showCVV));
    setWholePaymentSchema(paymentSchema(data?.creditCardTypes || [], showCVV));
  }, [data]);

  return {
    bankSchema,
    cardSchema,
    wholePaymentSchema,
    areCardTypesLading,
  };
};

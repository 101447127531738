import { Row } from 'antd';
import { useMultiStepPaymentStore } from 'src/store/multiStepPayment/multiStepPayment.store';
import NewPaymentMethodContent from '../PaymentMethods/NewPaymentMethodContent';
import { multiStepPaymentDefaultValues } from '../NewPaymentMethodsDefaultValues';
import { IPaymentMethod } from '../Payments.interfaces';
import { CustomButton } from 'src/components/CustomButton/CustomButton';

interface INewPaymentMethodTranpondersWrapperProps {
  isOneTimePayment?: boolean;
}

const NewPaymentMethodTranpondersWrapper = ({ isOneTimePayment }: INewPaymentMethodTranpondersWrapperProps) => {
  const {
    updatePaymentInformation,
    nextStep,
    paymentInfo: { paymentMethod },
    setReplenishmentAmount,
  } = useMultiStepPaymentStore();

  const navigationElement: JSX.Element = (
    <Row className="flex">
      <CustomButton type="submit" content="Continue" className="ml-auto mr-10" />
    </Row>
  );
  const onSumbit = (paymentMethod: IPaymentMethod) => {
    updatePaymentInformation(paymentMethod);
    nextStep();
  };
  const defaultPaymentMethod = multiStepPaymentDefaultValues(paymentMethod);
  return (
    <div>
      <NewPaymentMethodContent
        defaultFormValues={defaultPaymentMethod}
        isOnboardingPayment={false}
        isLoading={false}
        onSubmit={onSumbit}
        navigationElements={navigationElement}
        defaultPaymentMethod={paymentMethod?.paymentType}
        shouldBeWrappedInCard={false}
        showCVV={true}
        isOneTimePayment={isOneTimePayment}
        shouldShowSaveForFutureCheckBox={true}
        setReplenishmentAmount={setReplenishmentAmount}
        isAddingPaymentMethod={!isOneTimePayment}
      />
    </div>
  );
};
export default NewPaymentMethodTranpondersWrapper;

import { gql } from '@apollo/client';
import { IFilter } from './filter.graphql';
import { IPaginationInput } from './vehicles.graphql';

export const TRANSPONDER_PRICES = gql`
  query transponderPrices {
    transponderPrices {
      type
      price
    }
  }
`;

export const GET_TRANSPONDER_LIST = gql`
  query transponderList($transponderListInput: TransponderListInput!) {
    transponderList(transponderListInput: $transponderListInput) {
      first
      last
      numberOfElements
      pageNumber
      pageSize
      totalElements
      totalPages
      transponders {
        index
        date
        lastUsedDate
        friendlyName
        transponderType
        transponderNumber
        status
        plan {
          planId
          planName
          dateAdded
          endDate
          status
          transponderNumber
          checkState
          deletable
          isRenewable
          planDescription
        }
      }
    }
  }
`;

export const ACTIVATE_TRANSPONDERS = gql`
  mutation transpondersActivation($transpondersActivationInput: TranspondersActivationInput!) {
    transpondersActivation(transpondersActivationInput: $transpondersActivationInput)
  }
`;

export const UPDATE_TRANSPONDER = gql`
  mutation updateTransponder($updateTransponderInput: UpdateTransponderInput!) {
    updateTransponder(updateTransponderInput: $updateTransponderInput)
  }
`;

export const BULK_UPLOAD_TRANSPONDERS = gql`
  mutation transpondersBulkUpload($file: UploadFile!) {
    transpondersBulkUpload(input: $file) {
      batchId
    }
  }
`;

export const BULK_UPLOAD_TRANSPONDERS_PROCESS = gql`
  query transpondersBulkUploadProcess($batchId: String!) {
    transpondersBulkUploadProcess(batchId: $batchId)
  }
`;

export interface ITranspondersUploadOutput {
  batchId: string;
}

export interface IUpdateTransponderInput {
  newStatus?: TransponderStatus;
  transponderNumber: string;
  vehicleRowId?: string;
  friendlyName?: string;
}

export enum TransponderStatus {
  INVENTORY = 'INVENTORY',
  ACTIVE = 'ACTIVE',
  RETURNED = 'RETURNED',
  DAMAGED = 'DAMAGED',
  RETURNDEF = 'RETURNDEF',
  LOST = 'LOST',
  STOLEN = 'STOLEN',
  SHIPVEND = 'SHIPVEND',
  TESTED = 'TESTED',
  EXPIRED = 'EXPIRED',
  RETAINED = 'RETAINED',
  SOLD = 'SOLD',
  LOSTINMAIL = 'LOSTINMAIL',
  FOUND = 'FOUND',
  REVOKED = 'REVOKED',
  SWAPNORETURN = 'SWAPNORETURN',
  INACTIVE = 'INACTIVE',
  DISPOSED = 'DISPOSED',
  LOSTTRANSIT = 'LOSTTRANSIT',
  INTRANSIT = 'INTRANSIT',
  DELETED = 'DELETED',
  CUSTRETAINED = 'CUSTRETAINED',
  UNREGISTERED = '24',
  REPLACED = '32',
}

export enum TransponderType {
  MINI = 'MINI',
  PRO = 'PRO',
  PORTABLE = 'PORTABLE',
  PINELLAS = 'PINELLAS',
}

export interface ITranspondersActivationInput {
  transponderNumbers: string[];
}

export interface ITransponderList {
  first: boolean;
  last: boolean;
  numberOfElements: number;
  pageNumber: number;
  pageSize: number;
  totalElements: number;
  totalPages: number;
  transponders: [ITransponder];
}

export interface ITransponder {
  date: string;
  lastUsedDate: string;
  dateAssigned: string;
  deposit: string;
  end: string;
  friendlyName: string;
  index: number;
  transponderType: TransponderType;
  plateNumber: string;
  plan: IPlan;
  speedSuspended: string;
  start: string;
  status: TransponderStatus;
  transponderNumber: string;
  vehicleType: string;
}

export interface ITransponderInput {
  activeSearch?: boolean;
  pagination?: IPaginationInput;
  transponderNumber?: string;
  filter?: IFilter;
  filterHasPlan?: boolean;
  filterHasStatusActive?: boolean;
  filterHasTypeMiniOrPro?: boolean;
}

export interface IPlan {
  planId: string;
  planName: string;
  dateAdded: moment.Moment;
  endDate: moment.Moment;
  status: string;
  transponderNumber: string;
  checkState: number;
  deletable: boolean;
  isRenewable: boolean;
  planDescription: string;
}

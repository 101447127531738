import * as yup from 'yup';
import { validators } from '../../validators';
import { TRANSPONDER_NUMBER_MATCH_ERROR_MSG } from '../../onboarding/transponders/transpondersErrorMessages';

export const transponderActivationSchema = yup.object().shape({
  transponderNumber: validators.transponderNumber(),
  confirmTransponderNumber: validators
    .confirmTransponderNumber('transponderNumber')
    .required(TRANSPONDER_NUMBER_MATCH_ERROR_MSG),
});

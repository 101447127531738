import {
  LAST_NAME_REQUIRED_ERROR_MSG,
  REFERENCE_NUMBER_REQUIRED,
} from 'src/validators/onboarding/accountInfo/accountInfoErrorMessages';
import { validators } from 'src/validators/validators';
import * as yup from 'yup';
export const resumeAccountCreationSchema = () => {
  return yup.object().shape({
    referenceNumber: yup.string().required(REFERENCE_NUMBER_REQUIRED),
    lastName: validators.name.required(LAST_NAME_REQUIRED_ERROR_MSG),
    postalCode: validators.postalCode,
  });
};

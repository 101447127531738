import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { AccountType } from '../../types/onboarding.types';
import { useOnboardingStore } from '../../store/onboarding/onboarding.store';
import { SELECT_DETAILS, SELECT_RECOMMENDED_SUNPASS_PLAN_NAME } from '../../store/onboarding/onboarding.selectors';
import { MainRoutes } from '../../types/routes';
import SuccessMessageContent from '../Dashboard/Transponders/TransponderPurchase/SuccessMessageContent';
import { t } from '@lingui/macro';
import { resetAllStores } from 'src/store/createStore';

export default function OnboardingSuccess(): React.ReactElement {
  const { accountType, accountSubtype } = useOnboardingStore(SELECT_DETAILS);
  const planName = SELECT_RECOMMENDED_SUNPASS_PLAN_NAME(accountType, accountSubtype);
  const history = useNavigate();

  const accountFirstTitle = t`That's it, you've finished enrolling!`;
  const accountSecondTitle = t`You have successfully signed up for a`;
  const accountMessage = t`You will receive a confirmation email shortly with details of your recently purchased`;

  const governmentAccountFirstTitle = t`SunPass Account Creation Pending`;
  const governmentAccountSecondTitle = t`Your SunPass Government Account is under review!`;
  const governmentAccountMessage = t`You will receive an email shortly with details of your application for a SunPass Government Account. A SunPass representative will reach out to you with more information about the status of your account creation.`;

  useEffect(() => {
    return () => {
      resetAllStores();
    };
  }, []);

  if (accountType !== AccountType.GOVERNMENT) {
    return (
      <div>
        <SuccessMessageContent
          firstTitle={accountFirstTitle}
          secondTitle={accountSecondTitle}
          planName={planName}
          message={accountMessage}
          goBack={() => history(MainRoutes.DASHBOARD)}
          goBackDirection={t`Go to My Dashboard`}
          isOnboardingStep
        />
      </div>
    );
  } else {
    return (
      <div>
        <SuccessMessageContent
          firstTitle={governmentAccountFirstTitle}
          secondTitle={governmentAccountSecondTitle}
          message={governmentAccountMessage}
          goBack={() => history('/')}
          goBackDirection={t`Return to SunPass.com`}
          isOnboardingStep
          isWaitingForGovermentalApproval={true}
        />
      </div>
    );
  }
}

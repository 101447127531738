import { t, Trans } from '@lingui/macro';
import { Alert, Button, Space } from 'antd';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { TRANSPONDERS_TOP } from 'src/components/Dashboard/references';
import useScreenSize from 'src/hooks/screen/useScreenSize';
import { useScroll } from 'src/hooks/screen/useScroll';
import ScreenSize from 'src/types/ScreenSize.enum';
import './OneTimePurchase.scss';
import TableRow from './TableRow';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IPromoCodeForm } from '../../../Dashboard/Wallet/PromoCodeModal/PromoCodeModal';
import { useLazyQuery } from '@apollo/client';
import {
  IVerifyPromotionCodeOutput,
  PromoCodeType,
  VERIFY_PROMO_CODE,
} from '../../../../graphql/verifyPromotionCode.graphql';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { colors } from '../../../../theme/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { validationSchemaForPromoCode } from '../../../../validators/dashboard/transponders/promoCode.validator';
import CouponCode from '../../../atoms/form/oneTImePurchase/CouponCode';
import { showMessage } from 'src/utils/message.utils';
import { OnboardingFlowType } from '../../../../types/onboarding.types';

interface IOneTimePurchaseProps {
  miniTransponderQty: number;
  miniTransponderCharge: number;
  proTransponderQty: number;
  proTransponderCharge: number;
  miniTransponderQtyAssignedToPlan?: number;
  proTransponderQtyAssignedToPlan?: number;
  salesTax: number;
  subTotal: number;
  initialAccountDeposit?: number;
  onEdit?: () => void;
  isDashboard?: boolean;
  setPromoCodeAmount: React.Dispatch<React.SetStateAction<number>>;
  setPromoCode: React.Dispatch<React.SetStateAction<string | undefined>>;
  promoCodeAmount: number;
  isPBCAccount?: boolean;
  pbcPlanPrice?: number;
  setIsPromoCodeValidAndApplied?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPromoCodeInvalid?: React.Dispatch<React.SetStateAction<boolean>>;
  setPromoCodeType?: React.Dispatch<React.SetStateAction<string | null>>;
  promoType?: string | null;
  onboardingFlowType?: OnboardingFlowType;
}

const OneTimePurchase: FC<IOneTimePurchaseProps> = ({
  miniTransponderQty = 0,
  miniTransponderCharge = 0,
  proTransponderCharge = 0,
  proTransponderQty = 0,
  miniTransponderQtyAssignedToPlan = 0,
  proTransponderQtyAssignedToPlan = 0,
  pbcPlanPrice = 0,
  salesTax,
  initialAccountDeposit = 0,
  subTotal,
  onEdit,
  isDashboard,
  setPromoCodeAmount,
  setPromoCode,
  promoCodeAmount,
  isPBCAccount,
  setIsPromoCodeValidAndApplied,
  setIsPromoCodeInvalid,
  setPromoCodeType,
  promoType,
  onboardingFlowType,
}) => {
  const { screenSize } = useScreenSize();

  const { scrollToElem } = useScroll();

  const [isPromoCodeApplied, setIsPromoCodeApplied] = useState(false);
  const [isPromoValid, setIsPromoValid] = useState(false);
  const [isPromoEmpty, setIsPromoEmpty] = useState(true);
  const [isPromoChecked, setIsPromoChecked] = useState(false);

  useEffect(() => {
    if (isPromoEmpty && isPromoChecked && setIsPromoCodeValidAndApplied) {
      setIsPromoCodeValidAndApplied(false);
    }
    if (isPromoEmpty && setIsPromoCodeInvalid) {
      setIsPromoCodeInvalid(false);
    }
  }, [isPromoEmpty, isPromoChecked]);

  const [verifyPromoCode] = useLazyQuery<{ verifyPromotionCode: IVerifyPromotionCodeOutput }, { couponCode: string }>(
    VERIFY_PROMO_CODE,
  );

  const methods = useForm<IPromoCodeForm>({
    resolver: yupResolver(validationSchemaForPromoCode),
    mode: 'onChange',
  });

  const { watch, resetField, setValue } = methods;

  const promoCodeWatch = watch('couponCode');

  const applyingPromoCodeCompletedUnsuccessfully = () => {
    setIsPromoCodeApplied(true);
    setIsPromoValid(false);
    setPromoCodeAmount(0);
    setPromoCode('');
    if (setPromoCodeType) {
      setPromoCodeType(null);
    }
    if (setIsPromoCodeValidAndApplied) {
      setIsPromoCodeValidAndApplied(false);
    }
    if (setIsPromoCodeInvalid) {
      setIsPromoCodeInvalid(true);
    }
  };

  const checkPromoType = async (data: IVerifyPromotionCodeOutput | undefined) => {
    if (setPromoCodeType && data) {
      data?.mountType === null ? setPromoCodeType(PromoCodeType.TOLL) : setPromoCodeType(data?.mountType);
    }
  };

  const applyingPromoCodeCompletedSuccessfully = async (data: IVerifyPromotionCodeOutput | undefined) => {
    setPromoCode(promoCodeWatch);
    setIsPromoCodeApplied(true);
    await checkPromoType(data).then(() => {
      if (data?.couponStatus === 'VALID') {
        if (
          (data?.mountType === PromoCodeType.STK && miniTransponderQty === 0) ||
          (data?.mountType === PromoCodeType.PRO && proTransponderQty === 0) ||
          (isDashboard && data?.mountType === null)
        ) {
          applyingPromoCodeCompletedUnsuccessfully();
        } else {
          if (
            onboardingFlowType !== OnboardingFlowType.PREPAID ||
            (onboardingFlowType === OnboardingFlowType.PREPAID && data?.mountType === null)
          ) {
            setIsPromoValid(true);
            if (setIsPromoCodeValidAndApplied) {
              setIsPromoCodeValidAndApplied(true);
            }
            if (setIsPromoCodeInvalid) {
              setIsPromoCodeInvalid(false);
            }
            data && setPromoCodeAmount(Number(data?.amount));
          } else if (
            onboardingFlowType === OnboardingFlowType.PREPAID &&
            (data?.mountType === PromoCodeType.PRO || data?.mountType === PromoCodeType.STK)
          ) {
            setIsPromoValid(false);
            if (setIsPromoCodeInvalid) {
              setIsPromoCodeInvalid(true);
            }
            setPromoCodeAmount(0);
          }
        }
      } else {
        setIsPromoValid(false);
        if (setIsPromoCodeValidAndApplied) {
          setIsPromoCodeValidAndApplied(false);
        }
        if (setIsPromoCodeInvalid) {
          setIsPromoCodeInvalid(true);
        }
        setPromoCodeAmount(0);
        if (setPromoCodeType) {
          setPromoCodeType(null);
        }
      }
    });
  };

  const onSuccess = (responseData: any) => {
    if (responseData.error) {
      applyingPromoCodeCompletedUnsuccessfully();
    } else {
      setPromoCodeAmount(responseData.verifyPromotionCode.amount);
      applyingPromoCodeCompletedSuccessfully(responseData.verifyPromotionCode);
    }
  };

  const checkPromoCode = async () => {
    setIsPromoChecked(true);
    if (promoCodeWatch === '' || promoCodeWatch === undefined) {
      setIsPromoEmpty(true);
      setPromoCodeAmount(0);
    } else {
      setIsPromoEmpty(false);
      try {
        await verifyPromoCode({
          variables: {
            couponCode: promoCodeWatch,
          },
          onCompleted: onSuccess,
        });
      } catch (err) {
        showMessage('error', t`Invalid promo code`);
        setPromoCodeAmount(0);
        console.log((err as Error).message);
      }
    }
  };

  const promoAmountToDisplay = () => {
    if (Number(promoCodeAmount) > 0 && !(promoType === PromoCodeType.TOLL || promoType === null)) {
      return `-$${Number(promoCodeAmount).toFixed(2)}`;
    }
    return '';
  };

  const finalPrice = useMemo(() => {
    return subTotal.toFixed(2);
  }, [subTotal, promoCodeAmount]);

  const nonMobile: JSX.Element = (
    <Space direction="vertical" size="large" className="w-full">
      <Space align="end" size="middle">
        {isDashboard ? (
          <>
            <h3 className="text-primary-blue1">
              <Trans>Payment Summary</Trans>
            </h3>
          </>
        ) : (
          <>
            <h3 className="text-primary-blue1">
              <Trans>Purchase Summary</Trans>
            </h3>
          </>
        )}{' '}
        {onEdit ? (
          <Button type="link" onClick={onEdit} className="theme" aria-label={t`Edit One Time Purchase`}>
            <span>
              <Trans>Edit</Trans>
            </span>
          </Button>
        ) : null}
      </Space>

      <div className="ml-10">
        <table className="oneTimePurchaseTable">
          <thead>
            <tr>
              <th></th>
              <th className="text-left">
                <Trans>PRICE</Trans>
              </th>
              <th className="text-left">
                <Trans>QTY</Trans>
              </th>
              <th className="text-right">
                <Trans>TOTAL</Trans>
              </th>
            </tr>
          </thead>
          <tbody>
            {((miniTransponderQty > 0 && isDashboard) ||
              (miniTransponderQty > 0 && onboardingFlowType === OnboardingFlowType.PURCHASE)) && (
              <TableRow
                col1={isPBCAccount ? <Trans>Pinellas Bayway Plan</Trans> : <Trans>Mini Transponder</Trans>}
                col2={miniTransponderCharge}
                col3={miniTransponderQty}
                col4={`$${(miniTransponderCharge * miniTransponderQty).toFixed(2)}`}
              />
            )}
            {((proTransponderQty > 0 && isDashboard && !isPBCAccount) ||
              (proTransponderQty > 0 && !isPBCAccount && onboardingFlowType === OnboardingFlowType.PURCHASE)) && (
              <TableRow
                col1={<Trans>Pro Transponder</Trans>}
                col2={proTransponderCharge}
                col3={proTransponderQty}
                col4={`$${(proTransponderCharge * proTransponderQty).toFixed(2)}`}
              />
            )}
            {(miniTransponderQtyAssignedToPlan > 0 || proTransponderQtyAssignedToPlan > 0) && !isPBCAccount && (
              <TableRow
                col1={<Trans>Pinellas Bayway Plan</Trans>}
                col2={pbcPlanPrice}
                col3={miniTransponderQtyAssignedToPlan + proTransponderQtyAssignedToPlan}
                col4={`$${((miniTransponderQtyAssignedToPlan + proTransponderQtyAssignedToPlan) * pbcPlanPrice).toFixed(
                  2,
                )}`}
              />
            )}
            {initialAccountDeposit ? (
              <TableRow col1={<Trans>Initial Account Deposit</Trans>} col4={`$${initialAccountDeposit.toFixed(2)}`} />
            ) : (
              ''
            )}
            <TableRow
              className={isPromoCodeApplied || isPromoEmpty ? 'coupon-applied' : 'coupon'}
              col1={
                <>
                  <div className="flex w-52">
                    <FormProvider {...methods}>
                      <CouponCode isPromoValid={isPromoValid} isPromoCodeApplied={isPromoCodeApplied} />
                    </FormProvider>
                    {isPromoCodeApplied ? (
                      <button
                        onClick={() => {
                          setValue('couponCode', '');
                          setPromoCode('');
                          setPromoCodeAmount(0);
                          setIsPromoCodeApplied(false);
                          setIsPromoValid(false);
                          setIsPromoChecked(false);
                          if (setIsPromoCodeInvalid) {
                            setIsPromoCodeInvalid(false);
                          }
                          if (setIsPromoCodeValidAndApplied) {
                            setIsPromoCodeValidAndApplied(true);
                          }
                        }}
                        className="red-link ml-3 mt-5 cursor-pointer"
                      >
                        <Trans>Cancel</Trans>
                      </button>
                    ) : (
                      <button onClick={() => checkPromoCode()} className="apply-text green-link ml-3 cursor-pointer">
                        <Trans>Apply</Trans>
                      </button>
                    )}
                  </div>
                  {isPromoCodeApplied && isPromoValid && (
                    <div className="flex">
                      <p className="italic text-primary-green1">
                        <Trans>Applied</Trans>
                      </p>
                      <FontAwesomeIcon
                        className="ml-2"
                        icon={faCheck}
                        color={colors.primary.green1}
                        size="lg"
                      ></FontAwesomeIcon>
                    </div>
                  )}
                  {isPromoCodeApplied && !isPromoValid ? (
                    <div className="flex">
                      <p className="text-primary-red">
                        <Trans>Invalid promo code</Trans>
                      </p>
                    </div>
                  ) : null}
                  {isPromoEmpty && isPromoChecked && (
                    <div className="flex">
                      <p className="text-primary-red">
                        <Trans>Please enter a valid promo code</Trans>
                      </p>
                    </div>
                  )}
                  {(promoType === PromoCodeType.TOLL || promoType === null) &&
                    isPromoCodeApplied &&
                    isPromoValid &&
                    isDashboard !== true && (
                      <Alert
                        className="promo-message"
                        message={`Your toll credit of $${promoCodeAmount} will be applied after your new account has been established`}
                        type="success"
                      />
                    )}
                </>
              }
              col4={promoAmountToDisplay()}
            />

            <TableRow
              className="border-t border-gray-300"
              col1={
                <strong>
                  <Trans>Subtotal</Trans>
                </strong>
              }
              col4={`$${Number(finalPrice).toFixed(2)}`}
            />
          </tbody>
          <tfoot>
            <TableRow col1={<Trans>Sales Tax</Trans>} col4={`$${salesTax.toFixed(2)}`} />
          </tfoot>
        </table>
      </div>
    </Space>
  );

  const mobile: JSX.Element = (
    <Space direction="vertical" size="large" className="w-full">
      <div className="flex items-end justify-between">
        {isDashboard ? (
          <>
            <h3 className="text-primary-blue1">
              <Trans>Payment Summary</Trans>
            </h3>
            <Button
              type="link"
              onClick={() => {
                scrollToElem(TRANSPONDERS_TOP);
              }}
              className="theme"
              aria-label={t`Edit One Time Purchase`}
            >
              <span>
                <Trans>Edit</Trans>
              </span>
            </Button>
          </>
        ) : (
          <>
            <h3 className="text-primary-blue1">
              <Trans>One Time Purchase</Trans>
            </h3>
            {onEdit ? (
              <Button
                type="link"
                onClick={onEdit}
                className="theme"
                style={{ paddingRight: 0 }}
                aria-label={t`Edit One Time Purchase`}
              >
                <span>
                  <Trans>Edit</Trans>
                </span>
              </Button>
            ) : null}
          </>
        )}
      </div>
      {miniTransponderQty > 0 && (
        <Space direction="vertical" className="w-full">
          <div>
            <Trans>Mini Transponder</Trans>
          </div>
          <div className="flex justify-between">
            <Space>
              <span>${miniTransponderCharge}</span>
              <span>x{miniTransponderQty}</span>
            </Space>
            <div>${(miniTransponderCharge * miniTransponderQty).toFixed(2)}</div>
          </div>
        </Space>
      )}
      {proTransponderQty > 0 && (
        <Space direction="vertical" className="w-full">
          <div>
            <Trans>Pro Transponder</Trans>
          </div>
          <div className="flex justify-between">
            <Space>
              <span>${proTransponderCharge}</span>
              <span>x{proTransponderQty}</span>
            </Space>
            <div>${(proTransponderCharge * proTransponderQty).toFixed(2)}</div>
          </div>
        </Space>
      )}
      <div className="flex justify-between">
        <div>
          <Trans>Initial Account Deposit</Trans>
        </div>
        <div>${(+initialAccountDeposit).toFixed(2)}</div>
      </div>
      <div className="flex justify-between">
        <div className="flex-col">
          <div className="promo-input-mobile flex-col">
            <FormProvider {...methods}>
              <CouponCode isPromoValid={isPromoValid} isPromoCodeApplied={isPromoCodeApplied} />
            </FormProvider>
            {isPromoCodeApplied && isPromoValid && (
              <div className="flex">
                <p className="italic text-primary-green1">
                  <Trans>Applied</Trans>
                </p>
                <FontAwesomeIcon
                  className="ml-2"
                  icon={faCheck}
                  color={colors.primary.green1}
                  size="lg"
                ></FontAwesomeIcon>
              </div>
            )}
            {isPromoCodeApplied && !isPromoValid && (
              <div className="flex">
                <p className="text-primary-red">
                  <Trans>Invalid promo code</Trans>
                </p>
              </div>
            )}
            {isPromoEmpty && (
              <div className="flex">
                <p className="italic text-primary-red">
                  <Trans>Please enter a valid promo code</Trans>
                </p>
              </div>
            )}
            {isPromoCodeApplied ? (
              <button
                onClick={() => {
                  resetField('couponCode');
                  setPromoCodeAmount(0);
                  setPromoCode('');
                  setIsPromoCodeApplied(false);
                  setIsPromoChecked(false);
                  if (setIsPromoCodeInvalid) {
                    setIsPromoCodeInvalid(false);
                  }
                }}
                className="red-link mt-0 cursor-pointer"
              >
                <Trans>Cancel</Trans>
              </button>
            ) : (
              <button onClick={() => checkPromoCode()} className="green-link mt-0 cursor-pointer">
                <Trans>Apply</Trans>
              </button>
            )}
          </div>
        </div>
        <div>{promoCodeAmount !== 0 ? `-$${promoCodeAmount.toFixed(2)}` : `$${promoCodeAmount.toFixed(2)}`}</div>
      </div>
      <div className="flex justify-between border-t border-gray-300 pt-4">
        <strong>
          <Trans>Subtotal</Trans>
        </strong>
        <div>${Number(finalPrice).toFixed(2)}</div>
      </div>
      <div className="flex justify-between pt-4">
        <Trans>Sales Tax</Trans>
        <div>${salesTax.toFixed(2)}</div>
      </div>
    </Space>
  );

  return <>{screenSize > ScreenSize.XS ? nonMobile : mobile}</>;
};

export default OneTimePurchase;

import { TransponderType } from '../../../graphql/transponders.graphql';
import useTransponderPrices from '../../../hooks/domain/dashboard/transponders/useTransponderPrices';
import { t, Trans } from '@lingui/macro';
import React, { useCallback, useState } from 'react';
import { IPurchaseTransponderQtyInputType } from './PurchaseTranspondersContent';
import { useOnboardingStore } from '../../../store/onboarding/onboarding.store';
import { SELECT_DETAILS } from '../../../store/onboarding/onboarding.selectors';
import { AccountSubtype, AccountType } from '../../../types/onboarding.types';
import { InputNumber, Switch } from 'antd';
import { PinellasBaywayModal } from '../../Modals/ConfirmationModal/PinellasBaywayModal';
import { TransponderController } from './TransponderQuantityController';
import { mapTransponderTypeToField } from '../../../mappers/mapTransponderTypeToField';
import { useMultiStepPaymentStore } from '../../../store/multiStepPayment/multiStepPayment.store';
import { IAccountDetails } from '../../../graphql/accountDetails.graphql';
import './OnboardingTransponders.scss';

export interface ITransponderQuantity {
  regular: number;
  assignedToPlan?: number;
}
export interface ITransponderSelectCard {
  transponderType: TransponderType;
  quantity: ITransponderQuantity;
  isDashboard: boolean;
  isOptInPBCMini: boolean;
  isOptInPBCPro: boolean;
  setIsOptInPBCMini: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOptInPBCPro: React.Dispatch<React.SetStateAction<boolean>>;
  account?: IAccountDetails;
}

export const TransponderSelectCard = ({
  transponderType,
  quantity,
  isDashboard,
  isOptInPBCMini,
  isOptInPBCPro,
  setIsOptInPBCMini,
  setIsOptInPBCPro,
}: ITransponderSelectCard) => {
  const { accountSubtype, accountType } = useOnboardingStore(SELECT_DETAILS);
  const { miniPrice, proPrice, PBCPrice } = useTransponderPrices();

  const isPrivateAccountWithPBC =
    accountType === AccountType.PRIVATE && accountSubtype === AccountSubtype.PBC && !isDashboard;
  const transponderPrice =
    transponderType === TransponderType.MINI ? (isPrivateAccountWithPBC ? PBCPrice : miniPrice) : proPrice;

  const getQuantityByQuantityType = useCallback(
    (type: IPurchaseTransponderQtyInputType): number => {
      return type === 'regular' ? quantity.regular : quantity.assignedToPlan || 0;
    },
    [quantity.regular, quantity.assignedToPlan],
  );

  const getFinalPriceForTransponders = (type: IPurchaseTransponderQtyInputType) => {
    return (getQuantityByQuantityType(type) * (type === 'regular' ? transponderPrice || 0 : PBCPrice)).toFixed(2);
  };

  const [showPBCModal, setShowPBCModal] = useState(false);

  const { updateSingleMultiPaymentStoreValues } = useMultiStepPaymentStore();

  const updateTranspondersQty = (value: number | null, transponderType: TransponderType) => {
    if (value === null) {
      updateSingleMultiPaymentStoreValues(mapTransponderTypeToField(transponderType), 0);
    } else updateSingleMultiPaymentStoreValues(mapTransponderTypeToField(transponderType), value);
  };

  const {
    paymentInfo: { numOfProTransponders, numOfStickerTransponders },
  } = useMultiStepPaymentStore();

  const pbcPlanInputForPrivateAccount = (inputType: 'mini' | 'pro') => {
    return (
      <div>
        {!isDashboard && accountSubtype !== AccountSubtype.PBC && accountType !== AccountType.COMMERCIAL && (
          <div className="mt-5 flex">
            <Switch
              checked={inputType === 'mini' ? isOptInPBCMini : isOptInPBCPro}
              className="ant-switch pbc-switch"
              onChange={() => {
                if (inputType === 'mini') {
                  setIsOptInPBCMini(!isOptInPBCMini);
                } else {
                  setIsOptInPBCPro(!isOptInPBCPro);
                }
              }}
              aria-label={t`Opt in Easy Pay`}
            />
            <div className="inputs-container ">
              <p className="text-large opt-in-pbc opt-in-text ml-2">{t`Opt into Pinellas Bayway Commuter Plan.`}</p>
              <button
                className="green-link ml-5 "
                type="button"
                onClick={() => {
                  setShowPBCModal(true);
                }}
              >
                {t`What's this?`}
              </button>
            </div>
          </div>
        )}
        {!isDashboard && accountSubtype !== AccountSubtype.PBC && inputType === 'mini' && isOptInPBCMini ? (
          <div className="inputs-container border-primary-gray-3 mt-6 flex border-t-2 pt-6">
            <p className="opt-in-pbc opt-in-text text-base">
              <Trans>How many of these transponders would you like to add the Pinellas Bayway Commuter Plan to?</Trans>
            </p>
            <div className={`transponder-card-inputs flex flex-col justify-between`}>
              <div className={`onboarding-transponders-price mb-3`}>
                <TransponderController
                  key="miniQtyPlan"
                  fieldName="miniTransponderQtyAssignedToPlan"
                  label={t`Mini Transponder Quantity Assigned To Plan`}
                />
                <div className="transponder-price pl-6 text-right text-xl">
                  ${getFinalPriceForTransponders('assignedToPlan')}
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {!isDashboard && accountSubtype !== AccountSubtype.PBC && inputType === 'pro' && isOptInPBCPro ? (
          <div className="inputs-container border-primary-gray-3 mt-6 flex border-t-2 pt-6">
            <p className="opt-in-pbc opt-in-text text-base">
              <Trans>How many of these transponders would you like to add the Pinellas Bayway Commuter Plan to?</Trans>
            </p>
            <div className={`transponder-card-inputs flex flex-col justify-between`}>
              <div className={`onboarding-transponders-price mb-3`}>
                <TransponderController
                  key="proQtyPlan"
                  fieldName="proTransponderQtyAssignedToPlan"
                  label={t`Pro Transponder Quantity Assigned To Plan`}
                />
                <div className="transponder-price pl-6 text-right text-xl">
                  ${getFinalPriceForTransponders('assignedToPlan')}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div className="onboarding-transponders-price-container">
      <div className="flex w-full flex-col self-center">
        {transponderType === TransponderType.MINI ? (
          <>
            <div className="inputs-container flex h-10">
              <p
                className={`transponder-type-text mb-0 self-center pb-3 text-xl md:pb-0 ${
                  isPrivateAccountWithPBC ? 'md:text-2xl' : 'md:text-3xl'
                }`}
              >
                {isPrivateAccountWithPBC
                  ? `${t`Mini Transponder + Pinellas Bayway Plan`} ($${transponderPrice.toFixed(2)})`
                  : `${t`Mini Transponder`} ($${transponderPrice.toFixed(2)})`}
              </p>
              <div className={`transponder-card-inputs flex flex-col justify-between`}>
                <div className={`onboarding-transponders-price mb-3`}>
                  {isDashboard ? (
                    <InputNumber
                      key="miniQty"
                      value={numOfStickerTransponders}
                      precision={0}
                      maxLength={5}
                      max={99999}
                      decimalSeparator={','}
                      onChange={(e) => {
                        updateTranspondersQty(e, TransponderType.MINI);
                      }}
                      id="miniTransponderQty"
                      aria-label={t`Mini Transponder Quantity`}
                      min={0}
                      className="text-xl"
                    ></InputNumber>
                  ) : (
                    <TransponderController
                      key="miniQty"
                      fieldName="miniTransponderQty"
                      label={t`Mini Transponder Quantity`}
                    />
                  )}
                  <div className="transponder-price text-right text-xl">${getFinalPriceForTransponders('regular')}</div>
                </div>
              </div>
            </div>
            {!isPrivateAccountWithPBC && !isDashboard && pbcPlanInputForPrivateAccount('mini')}
          </>
        ) : (
          <>
            <div>
              <div className="inputs-container flex">
                <p
                  className={`transponder-type-text mb-0 self-center pb-3 text-xl md:pb-0 ${
                    isPrivateAccountWithPBC ? 'md:text-2xl' : 'md:text-3xl'
                  }`}
                >
                  `{t`Pro Transponder`} (${transponderPrice.toFixed(2)})
                </p>
                <div className={`transponder-card-inputs flex flex-col justify-between`}>
                  <div className={`onboarding-transponders-price mb-3`}>
                    {isDashboard ? (
                      <InputNumber
                        key="proQty"
                        precision={0}
                        maxLength={5}
                        max={99999}
                        decimalSeparator={','}
                        value={numOfProTransponders}
                        onChange={(e) => {
                          updateTranspondersQty(e, TransponderType.PRO);
                        }}
                        id="proTransponderQty"
                        aria-label={t`Pro Transponder Quantity`}
                        min={0}
                        className="text-xl"
                      ></InputNumber>
                    ) : (
                      <TransponderController
                        key="proQty"
                        fieldName="proTransponderQty"
                        label={t`Pro Transponder Quantity`}
                      />
                    )}
                    <div className="transponder-price pl-6 text-right text-xl">
                      ${getFinalPriceForTransponders('regular')}
                    </div>
                  </div>
                </div>
              </div>
              {!isPrivateAccountWithPBC && !isDashboard && pbcPlanInputForPrivateAccount('pro')}
            </div>
          </>
        )}
      </div>
      <PinellasBaywayModal
        onOk={() => {
          setShowPBCModal(false);
        }}
        showModal={showPBCModal}
      />
    </div>
  );
};

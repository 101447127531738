import { Form } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import StyledInput from '../StyledInput/StyledInput';
import React from 'react';
import { t } from '@lingui/macro';
import '../AppForm/AppForm.scss';
export interface ISecurityAnswerProps {
  label: JSX.Element | string;
  fieldName: string;
}

export const SecurityAnswer = ({ label, fieldName }: ISecurityAnswerProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="flex flex-wrap">
      <div className="input-margins w-full">
        <Form.Item
          label={label}
          className="theme"
          help={errors?.[`${fieldName}`]?.message as unknown as string}
          required
        >
          <Controller
            name={fieldName}
            control={control}
            render={({ field, fieldState }) => (
              <StyledInput
                field={field}
                fieldstate={fieldState}
                id={fieldName}
                key={fieldName}
                maxLength={40}
                aria-label={t`${fieldName}`}
              />
            )}
          />
        </Form.Item>
      </div>
    </div>
  );
};

import * as yup from 'yup';
import { t } from '@lingui/macro';

export const InvoiceSchema = () => {
  return yup.object().shape({
    options: yup.string().required(t`Required`),
    invoiceNumber: yup
      .string()
      .nullable()
      .when('options', {
        is: 'tollInvoice',
        then: yup.string().required('Enter your invoice number'),
      }),
    licenseNumber: yup.string().required(t`Enter your license number`),
    documentId: yup
      .string()
      .nullable()
      .when('options', {
        is: 'uniformCitation',
        then: yup.string().required('Enter your document ID'),
      }),
    postalCode: yup
      .string()
      .nullable()
      .when('options', {
        is: 'withoutInvoiceOrCitation',
        then: yup.string().required('Enter your postal code'),
      }),
  });
};

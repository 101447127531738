import { Trans } from '@lingui/macro';
import { Steps } from 'antd';
import { FC, useContext, useEffect, useState } from 'react';
import BackButton from 'src/components/BackButton/BackButton';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import ChosePaymentMethod from 'src/components/Payments/ChosePaymentMethod/ChosePaymentMethod';
import NewPaymentMethodAddPlanProccess from 'src/components/Payments/NewPaymentMethodWrappers/NewPaymentMethodAddPlanProccess';
import { IPaymentMethod } from 'src/components/Payments/Payments.interfaces';
import { ADD_NEW_PAYMENT_OPTION, ONE_TIME_PURCHASE } from 'src/constants';
import { ITransponder, TransponderType } from 'src/graphql/transponders.graphql';
import { useSortPaymentMethods } from 'src/hooks/domain/payments/useSortPaymentMethods';
import { addPlanStore } from 'src/store/addPlan/addPlan.store';
import AddPlansPaymentSummary from './AddPlansPaymentSummary';
import { useMutation } from '@apollo/client';
import { ADD_PLAN, IAddPlanInput, IAddPlanOutput } from 'src/graphql/plans/addPlan.graphql';
import { mapNewPaymentMethodToPurchasePlan, mapPaymentMethodToPurchasePlan } from './mappers';
import { showMessage } from 'src/utils/message.utils';
import { MainLoader } from 'src/components/Loader/MainLoader';
import SuccessMessageContent from 'src/components/Dashboard/Transponders/TransponderPurchase/SuccessMessageContent';
import { useNavigate } from 'react-router-dom';
import { IPurchasePlanInput, IPurchasePlanOutput, PURCHASE_PLAN } from 'src/graphql/plans/purchasePlans.graphql';
import { APPLY_PROMO_CODE, IApplyPromoCodeInput } from 'src/graphql/applyPromoCodeAccount.graphql';
import { useAccountSettings } from 'src/store/account/accountSettings.store';
import { usePaymentStore } from 'src/store/payment/payment.store';
import useTransponderPrices from 'src/hooks/domain/dashboard/transponders/useTransponderPrices';
import { useOneTimePaymentStore } from 'src/store/payment/oneTimePayment.store';
import { mapAddUpdatePaymentMethod } from 'src/components/Payments/mappers';
import {
  ADD_UPDATE_PAYMENT_METHOD,
  IAddUpdatePaymentMethodInput,
  PAYMENT_METHODS,
} from 'src/graphql/paymentMethods.graphql';
import { NotificationContext } from 'src/context/NotificationContext';
import { EasyPayOption } from '../../../graphql/payment.graphql';

interface IAddPlanProccess {
  tranponderToAddPlan?: ITransponder | null;
  transponderType?: TransponderType;
}

const AddPlanProccess: FC<IAddPlanProccess> = ({ tranponderToAddPlan, transponderType }) => {
  const navigate = useNavigate();

  const redirectToDashboard = () => {
    navigate('dashboard/my-dashboard');
    setHasSuccessfullyCompletedTransaction(false);
    resetStepper();
  };
  const generatePrice = (transponderType?: TransponderType) => {
    if (transponderType) {
      switch (transponderType) {
        case TransponderType.PRO:
          return <b>${Number(PBCPrice + proPrice).toFixed(2)}</b>;
        case TransponderType.MINI:
          return <b>${Number(PBCPrice + miniPrice).toFixed(2)}</b>;
        case null:
          return null;
      }
    } else {
      return <b>${PBCPrice.toFixed(2)}</b>;
    }
  };
  const { changeIsOneTimePayment, isOneTimePayment } = useOneTimePaymentStore();
  const { miniPrice, proPrice, PBCPrice } = useTransponderPrices();
  const { nextStep, previousStep, currentStep, resetStepper } = addPlanStore();
  const { Step } = Steps;
  const [isNewPayment, setIsNewPayment] = useState<boolean | null>(null);
  const [paymentMethodId, setPaymentMethodId] = useState<string>();
  const [dataToPurchasePlan, setDataToPurchasePlan] = useState<IPurchasePlanInput>();
  const [hasSuccessfullyCompletedTransaction, setHasSuccessfullyCompletedTransaction] = useState(false);
  const [salesTax, setSalesTax] = useState('0');
  const [amountDue, setAmountDue] = useState('');
  const [promoCodeAmount, setPromoCodeAmount] = useState(0);
  const [promoCode, setPromoCode] = useState<string | undefined>('');
  const [newPaymentMethod, setNewPaymentMethod] = useState<IPaymentMethod>();
  const [isPromoInvalid, setIsPromoInvalid] = useState(false);
  const [applyPromoCode] = useMutation<{ applyPromoCodeInput: IApplyPromoCodeInput }>(APPLY_PROMO_CODE);
  const { setIsTabDirty } = useAccountSettings();
  const [addPlan, { loading: isAddingPlanssLoading, data: addPlanResponse }] = useMutation<{
    addPlan: IAddPlanOutput;
    addPlanInput: IAddPlanInput;
  }>(ADD_PLAN);

  const [purchasePlan, { loading: isPurchasePlansLoading }] = useMutation<{
    purchasePlan: IPurchasePlanOutput;
    purchasePlanInput: IPurchasePlanInput;
  }>(PURCHASE_PLAN);
  const notificationCtx = useContext(NotificationContext);
  const { loadNotifications } = notificationCtx || {};
  const [addPaymentMethod, { loading: isAddingLoading }] = useMutation<IAddUpdatePaymentMethodInput>(
    ADD_UPDATE_PAYMENT_METHOD,
    {
      onCompleted: async () => {
        loadNotifications && (await loadNotifications(true));
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: PAYMENT_METHODS,
        },
      ],
    },
  );

  const payForPlans = async () => {
    const purchasePlanInput = {
      addressLine1: dataToPurchasePlan?.addressLine1,
      addressLine2: dataToPurchasePlan?.addressLine2,
      bankAccountNumber: dataToPurchasePlan?.bankAccountNumber,
      bankRoutingNumber: dataToPurchasePlan?.bankRoutingNumber,
      cardNumber: dataToPurchasePlan?.cardNumber,
      cardType:
        dataToPurchasePlan?.cardNumber !== '' ? dataToPurchasePlan?.cardType : newPaymentMethod?.bankAccountType,
      city: dataToPurchasePlan?.city,
      country: dataToPurchasePlan?.country,
      easyPayOption:
        dataToPurchasePlan?.cardNumber !== ''
          ? dataToPurchasePlan?.easyPayOption
          : newPaymentMethod?.optInEZPay === true
          ? EasyPayOption.EASY_PAY
          : EasyPayOption.NONE,
      expirationMonth: dataToPurchasePlan?.expirationMonth,
      expirationYear: dataToPurchasePlan?.expirationYear,
      firstName: dataToPurchasePlan?.firstName,
      lastName: dataToPurchasePlan?.lastName,
      middleName: dataToPurchasePlan?.middleName,
      paymentAmount: dataToPurchasePlan?.paymentAmount,
      paymentMethodId: dataToPurchasePlan?.paymentMethodId,
      planName: dataToPurchasePlan?.planName,
      promoCode: promoCode,
      securityCode: dataToPurchasePlan?.securityCode,
      state: dataToPurchasePlan?.state,
      transponderNumber: dataToPurchasePlan?.transponderNumber,
      zipCode: dataToPurchasePlan?.zipCode,
    };
    if (dataToPurchasePlan?.bankAccountNumber !== '') {
      delete purchasePlanInput.expirationMonth;
      delete purchasePlanInput.expirationYear;
    }

    try {
      await purchasePlan({
        variables: {
          purchasePlanInput: purchasePlanInput,
        },
      });
      if (newPaymentMethod && isNewPayment === true && !isOneTimePayment) {
        const addUpdatePaymentMethodInput = mapAddUpdatePaymentMethod(newPaymentMethod, newPaymentMethod, cardType);
        try {
          await addPaymentMethod({
            variables: {
              addUpdatePaymentMethodInput,
            },
          });
        } catch (err) {
          console.log(err);
        }
      }

      showMessage('success');
      setHasSuccessfullyCompletedTransaction(true);
      setIsNewPayment(null);
      setPaymentMethodId(undefined);
      setDataToPurchasePlan(undefined);
    } catch (err) {
      console.log(err);
      showMessage('error');
    }
    if (!!promoCode && promoCode !== '') {
      try {
        await applyPromoCode({ variables: { couponCode: promoCode } });
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleChange = (value: string) => {
    changeIsOneTimePayment(false);
    const chosenPaymentMethod = sortedPaymentMethods?.find((card) => {
      return card.paymentMethodId === value;
    });
    if (value === ADD_NEW_PAYMENT_OPTION || value === ONE_TIME_PURCHASE) {
      if (value === ONE_TIME_PURCHASE) changeIsOneTimePayment(true);
      setIsNewPayment(true);
    } else {
      if (chosenPaymentMethod && addPlanResponse) {
        const sum = Number(amountDue) + Number(salesTax);
        if (tranponderToAddPlan) {
          setDataToPurchasePlan(
            mapPaymentMethodToPurchasePlan(chosenPaymentMethod, String(sum.toFixed(2)), tranponderToAddPlan),
          );
        }
        if (transponderType) {
          setDataToPurchasePlan(
            mapPaymentMethodToPurchasePlan(chosenPaymentMethod, String(sum.toFixed(2)), null, transponderType),
          );
        }
      }
      setPaymentMethodId(value);
      setIsNewPayment(false);
    }
  };
  const { cardType } = usePaymentStore();
  const onSubmit = (formValues: IPaymentMethod) => {
    setNewPaymentMethod(formValues);
    if (tranponderToAddPlan && addPlanResponse) {
      setDataToPurchasePlan(
        mapNewPaymentMethodToPurchasePlan(
          formValues,
          tranponderToAddPlan,
          cardType,
          String(Number(addPlanResponse.addPlan.planPrice) + Number(addPlanResponse.addPlan.tax)),
        ),
      );
    }
    if (transponderType && addPlanResponse)
      setDataToPurchasePlan(
        mapNewPaymentMethodToPurchasePlan(
          formValues,
          null,
          cardType,
          String(Number(addPlanResponse.addPlan.planPrice) + Number(addPlanResponse.addPlan.tax)),
          transponderType,
        ),
      );

    nextStep();
  };

  useEffect(() => {
    if (hasSuccessfullyCompletedTransaction) setIsTabDirty(false);
  }, [hasSuccessfullyCompletedTransaction]);

  useEffect(() => {
    const orderPlan = async () => {
      if (tranponderToAddPlan) {
        const { transponderNumber } = tranponderToAddPlan;
        const addPlanResponse = await addPlan({
          variables: {
            addPlanInput: {
              planName: 'PBC',
              transponderNumber,
              transponderType: '',
            },
          },
        });
        setSalesTax(addPlanResponse?.data?.addPlan.tax || '0');
        setAmountDue(addPlanResponse?.data?.addPlan.planPrice || '0');
      }
      if (transponderType) {
        const addPlanResponse = await addPlan({
          variables: {
            addPlanInput: {
              planName: 'PBC',
              transponderNumber: '',
              transponderType: transponderType,
            },
          },
        });
        setSalesTax(addPlanResponse?.data?.addPlan.tax || '0');
        if (addPlanResponse?.data?.addPlan?.planPrice && addPlanResponse?.data?.addPlan.transponderCost) {
          const totalPrice = addPlanResponse?.data?.addPlan?.planPrice + addPlanResponse?.data?.addPlan.transponderCost;
          setAmountDue(totalPrice);
        }
      }
    };
    orderPlan();
  }, [tranponderToAddPlan, transponderType]);

  const { sortedPaymentMethods } = useSortPaymentMethods();
  const displaySuccessScreen = () => {
    return hasSuccessfullyCompletedTransaction ? (
      <div>
        {' '}
        <SuccessMessageContent
          message={'You have successfully made a payment!'}
          goBack={redirectToDashboard}
          goBackDirection={`Go to My Dashboard`}
        />
      </div>
    ) : (
      <>
        <div>
          <AddPlansPaymentSummary
            setPromoCode={setPromoCode}
            setPromoCodeAmount={setPromoCodeAmount}
            promoCodeAmount={promoCodeAmount}
            transponderType={transponderType}
            setIsPromoInvalid={setIsPromoInvalid}
          />
        </div>
        <div className="mt-6 flex justify-between">
          <BackButton blockRedirectTill={true} onBack={() => previousStep()} />
          <CustomButton disabled={isPromoInvalid} content={'Continue'} onClick={() => payForPlans()} />
        </div>
      </>
    );
  };

  return (
    <div>
      <MainLoader loading={isAddingPlanssLoading || isPurchasePlansLoading || isAddingLoading} />
      <div className="flex justify-center">
        <Steps size={'small'} current={currentStep}>
          <Step />
          <Step />
          <Step />
        </Steps>
      </div>
      {currentStep === 1 ? (
        <div>
          <h4 className="my-12 text-center text-xl font-bold text-primary-blue1 md:px-10">
            <Trans>Which payment method do you intend on using for this purchase?</Trans>
          </h4>
          <div className="mx-auto w-96">
            <ChosePaymentMethod
              handleChange={handleChange}
              sortedPaymentMethods={sortedPaymentMethods}
              defaultValue={paymentMethodId ? paymentMethodId : undefined}
            />
          </div>
          {isNewPayment ? (
            <div>
              <NewPaymentMethodAddPlanProccess
                onSubmit={onSubmit}
                onBack={() => previousStep()}
                isLoading={false}
                showCVV={true}
              />
            </div>
          ) : (
            <>
              <div className="ml-auto w-32">
                <Trans>Subtotal: {generatePrice(transponderType)}</Trans>
              </div>
              <div className="mt-6 flex justify-between">
                <BackButton blockRedirectTill={true} onBack={() => previousStep()} />
                <CustomButton content={'Continue'} onClick={() => nextStep()} disabled={isNewPayment === null} />
              </div>
            </>
          )}
        </div>
      ) : null}
      {currentStep === 2 ? displaySuccessScreen() : null}
    </div>
  );
};
export default AddPlanProccess;

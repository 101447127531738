import React, { FC } from 'react';
import styles from '../Activity.module.scss';
import sunpassLogo from '../../../../assets/SunPassLogo-Blue.svg';
import { ITransactionActivity } from 'src/graphql/transactionsActivity.graphql';
import { formatDate, formatTime } from '../../../../utils/time.utils';
import { Link } from 'react-router-dom';
import { GET_ACCOUNT_DETAILS, IAccountDetails } from '../../../../graphql/accountDetails.graphql';
import { useQuery } from '@apollo/client';
import { Trans } from '@lingui/macro';
import { isDigitsOnly } from '../activityUtils';
import { convertPriceToString, setBalanceColor } from 'src/utils/formatter';
import { CustomButton } from 'src/components/CustomButton/CustomButton';

interface IActivityDetails {
  transactionActivity: ITransactionActivity;
  visibleActivityIndex: string;
  setVisibleActivityIndex: React.Dispatch<React.SetStateAction<string>>;
}

const ActivityDetails: FC<IActivityDetails> = ({ transactionActivity, setVisibleActivityIndex }) => {
  const { transactionDate, exitTime, balance, amount, transactionNumber, activity, exitPlaza, tagOrPlateNumber } =
    transactionActivity;

  const { data: accountDetailsData } = useQuery<{ accountDetails: IAccountDetails }>(GET_ACCOUNT_DETAILS);

  const collapseDetails = () => {
    setVisibleActivityIndex('');
  };

  return accountDetailsData ? (
    <>
      <div className={`my-4 rounded-lg bg-white px-10 pb-10 pt-12 shadow-lg`}>
        <div className={`${styles['details-main']}`}>
          <div className={`${styles['details-left']}`}>
            <div>
              <img className={`${styles['details-left-logo']}`} src={sunpassLogo} alt="SunPass logo" />
            </div>
            <div>
              <h1 className={`${styles['details-left-header']}`}>
                <Trans>Receipt</Trans>
              </h1>
              <h2 className={`${styles['details-left-date']}`}>
                <span>{formatDate(transactionDate, true)}</span> at <span>{formatTime(exitTime)}</span>
              </h2>
              <p className="mb-0">
                <span className="font-bold">
                  <Trans>Recipient:</Trans>
                </span>{' '}
                {accountDetailsData.accountDetails.customerName}
              </p>

              {tagOrPlateNumber && !isDigitsOnly(tagOrPlateNumber) ? (
                <p className="mb-0">
                  <span className="font-bold">
                    <Trans>License Plate:</Trans>
                  </span>{' '}
                  {tagOrPlateNumber}
                </p>
              ) : null}

              {tagOrPlateNumber && isDigitsOnly(tagOrPlateNumber) ? (
                <p>
                  <span className="font-bold">
                    <Trans>Transponder:</Trans>
                  </span>{' '}
                  {tagOrPlateNumber}
                </p>
              ) : null}

              {exitPlaza ? (
                <p className="mb-0">
                  <span className="font-bold">
                    <Trans>Location:</Trans>
                  </span>{' '}
                  {exitPlaza}
                </p>
              ) : null}

              <p className="mb-0">
                <span className="font-bold">
                  <Trans>Transaction Type:</Trans>
                </span>{' '}
                {activity}
              </p>
            </div>
          </div>

          <div className={`${styles['details-right']} w-5/12`}>
            <h1 className={styles['details-right-header']}>
              <Trans>Transaction Invoice</Trans>
              <br />#{transactionNumber}
            </h1>
            <div className={styles['details-right-balance-table']}>
              <div className={styles['details-right-balance-toll']}>
                <div>
                  <Trans>Transaction Amount</Trans>
                </div>
                <div className="text-right"> {convertPriceToString(amount)}</div>
              </div>
              <div className={styles['details-right-balance-balance']}>
                <div>
                  <Trans>Remaining Balance</Trans>
                </div>
                <div className={`text-right ${setBalanceColor(convertPriceToString(balance))}`}>
                  {convertPriceToString(balance)}
                </div>
              </div>
            </div>
            <div>
              <p className={styles['details-right-balance-disclaimer']}>
                <Trans>
                  *if this looks incorrect or you would like to dispute this toll in any way, you must{' '}
                  <Link to="/">
                    <span className={styles['details-right-balance-disclaimer-link']}>
                      contact a SunPass customer service representative
                    </span>
                  </Link>{' '}
                  in order to resolve any accumulated toll charges affiliated with your account.
                </Trans>
              </p>
            </div>
            <div>
              <div>
                <Trans>ACCOUNT NUMBER</Trans>
              </div>
              <div className="font-bold">{accountDetailsData.accountDetails.accountNumber}</div>
            </div>
          </div>
        </div>
        <div className={styles['buttons-container']}>
          <CustomButton className="mx-4 mb-4" onClick={collapseDetails} content="Close" isPrimary={false} />
          <CustomButton className="mx-4 mb-4" content="Print Receipt" />
        </div>
      </div>
    </>
  ) : null;
};

export default ActivityDetails;

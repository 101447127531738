import * as yup from 'yup';
import { t } from '@lingui/macro';

export const getNotificationSettingsSchema = (isPhoneNumberEmpty: boolean) =>
  yup.object().shape({
    smsOption: yup
      .boolean()
      .nullable()
      .notRequired()
      .test(
        'cellPhoneRequired',
        t`A mobile phone number is required in order to opt into text alerts, and you currently do not have a mobile phone number on file. To opt into text alerts, first go to the Contact Information tab and add a mobile phone number. `,
        (value) => {
          return !(value === true && isPhoneNumberEmpty);
        },
      ),
  });

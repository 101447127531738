import React, { FC } from 'react';
import CarIcon from '../../assets/Yellow-Vehicle-Icon.svg';

const VehiclesAndTranspondersHeader: FC<any> = () => {
  return (
    <>
      <div className="mb-10 flex items-center border-t border-gray-300 pt-10">
        <img src={CarIcon} alt="money icon" />
        <h1 className="ml-3 text-xl font-bold sm:ml-6 sm:text-3xl ">Vehicles and Transponders</h1>
      </div>
    </>
  );
};

export default VehiclesAndTranspondersHeader;

import { gql } from '@apollo/client';
import { IVehicle } from '../vehicles.graphql';

export const SIGN_UP_VEHICLES_AND_TRANSPONDERS = gql`
  mutation signUpVehiclesAndTransponders($signUpVehiclesTranspondersInput: SignUpVehiclesTranspondersInput!) {
    signUpVehiclesTransponders(signUpVehiclesTranspondersInput: $signUpVehiclesTranspondersInput) {
      proTransponderSerialNumber
      miniTransponderSerialNumber
      totalTranspondersCost
      tax
    }
  }
`;

export interface ISignUpVehiclesTranspondersInput {
  accountId?: string;
  miniTranspondersCount?: number;
  proTranspondersCount?: number;
  portableTranspondersCount?: number;
  vehicleUploadBatchId?: string;
  vehicleUploadCount?: string;
  vehicles?: IVehicleSignUpInput[];
}

export interface IVehicleSignUpInput extends Omit<IVehicle, 'licenseInfoNotKnown' | 'index' | 'vehicleId'> {
  effectiveEndDate: string;
  effectiveEndTime: string;
  effectiveStartDate: string;
  effectiveStartTime: string;
}

export interface ISignUpVehiclesTranspondersOutput {
  accountId: string;
  miniTransponderSerialNumber: string;
  proTransponderSerialNumber: string;
  tax: number;
  totalTranspondersCost: number;
}

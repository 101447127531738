import React, { useState } from 'react';
import Transponders from './Transponders/Transponders';
import Vehicles from './Vehicles/Vehicles';
import VehiclesAndTranspondersHeader from './VehiclesAndTranspondersHeader';
import ScreenSize from 'src/types/ScreenSize.enum';
import useScreenSize from 'src/hooks/screen/useScreenSize';
import Stepper from './Stepper';
import { IAccountDetails } from '../../../../graphql/accountDetails.graphql';
import { AccountType } from '../../../../types/onboarding.types';
import { usePlans } from '../usePlans';

interface VehiclesAndTranspondersProps {
  accountDetails: IAccountDetails | undefined;
}

const VehiclesAndTransponders = ({ accountDetails }: VehiclesAndTranspondersProps) => {
  const { screenSize } = useScreenSize();
  const [isVehiclesVisible, setIsVehiclesVisible] = useState(true);
  const accountType = accountDetails?.accountType;
  const { PAYGAccount } = usePlans();

  const isPAYGAccount = accountType === AccountType.PRIVATE && PAYGAccount;

  return (
    <div className="mt-10">
      <VehiclesAndTranspondersHeader />
      {screenSize > ScreenSize.SM ? (
        <div className="flex">
          <Vehicles />
          {!isPAYGAccount && <Transponders />}
        </div>
      ) : (
        <>
          <div className={isVehiclesVisible ? undefined : 'hidden'}>
            <Vehicles />
          </div>
          {!isPAYGAccount ? (
            <div className={isVehiclesVisible ? 'hidden' : undefined}>
              <Transponders />
            </div>
          ) : null}
          <Stepper isVehiclesVisible={isVehiclesVisible} setIsVehiclesVisible={setIsVehiclesVisible} />
        </>
      )}
    </div>
  );
};

export default VehiclesAndTransponders;

import { FC } from 'react';
import { Form, Select } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import { t, Trans } from '@lingui/macro';
import { IVehicleFormItemProps } from '../../../Onboarding/Vehicles/OnboardingVehiclesForm/OnboardingVehicleForm';
import { COLORS } from '../../../Onboarding/Vehicles/vehicleForm.utils';
import { buildErrorString } from '../../../Onboarding/Vehicles/OnboardingVehiclesForm/vehicleForm.utils';
import classNames from 'classnames';

const { Option } = Select;

const VehicleColor: FC<IVehicleFormItemProps> = ({ field, index, name }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="mb-4 w-full sm:w-3/12 sm:pr-2">
      <Form.Item
        {...field}
        help={buildErrorString(name, errors, index)}
        label={<Trans id="Color" />}
        htmlFor={name}
        className="theme"
        hasFeedback
        validateStatus={errors?.vehicles?.[0]?.vehicleColor || errors.vehicleColor ? 'error' : ''}
      >
        <Controller
          control={control}
          name={name}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              {...fieldState}
              className={classNames({ 'input-error': fieldState.invalid })}
              id={name}
              placeholder={t`Select`}
              aria-label={t`Color`}
            >
              {COLORS.map((vehicleColor: any) => (
                <Option key={vehicleColor} value={vehicleColor}>
                  {vehicleColor}
                </Option>
              ))}
            </Select>
          )}
        />
      </Form.Item>
    </div>
  );
};

export default VehicleColor;

import type { Blocker, Transition, History } from 'history';
import { UNSAFE_NavigationContext } from 'react-router-dom';
import { useContext, useEffect } from 'react';

type ExtendNavigator = Navigator & Pick<History, 'block'>;

export const useBlocker = (blocker: Blocker, when = true): void => {
  const { navigator } = useContext(UNSAFE_NavigationContext);

  useEffect(() => {
    if (!when) return;

    const unblock = (navigator as unknown as ExtendNavigator).block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
};

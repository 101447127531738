import { Trans } from '@lingui/macro';
import React, { FC } from 'react';

interface IAccountTypeProps {
  localizedPlanName?: string;
}

const AccountType: FC<IAccountTypeProps> = ({ localizedPlanName }) => {
  return (
    <>
      {localizedPlanName ? (
        <div>
          <div className="title">
            <Trans>Account Type</Trans>
          </div>
          <div>
            <Trans>{localizedPlanName}</Trans>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default AccountType;

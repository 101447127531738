import { gql } from '@apollo/client';
import { AccountType } from '../types/onboarding.types';

export const LOGIN_USER = gql`
  mutation loginUser($loginInput: LoginInput!) {
    loginUser(loginInput: $loginInput) {
      jwt {
        token
        refreshToken
      }
    }
  }
`;

export interface ILoginInput {
  username?: string;
  transponderNumber?: string;
  accountNumber?: string;
  password: string;
}

export interface ILoginOutput {
  accountNumber: string;
  permission: string;
  accountType: AccountType;
  isFirstTimeLogin: boolean;
  isNixieFlag: boolean;
  suggestedAmount: string;
  isTempPassword: boolean;
  isChallengeQuestion: boolean;
  isUserName: boolean;
  isSurvey: boolean;
  jwt: IAuthToken;
}

export interface IAuthToken {
  token: string;
  refreshToken: string;
}

import { Trans } from '@lingui/macro';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useScroll } from 'src/hooks/screen/useScroll';
import { concatRoutes, DashboardParams, MainRoutes } from 'src/types/routes';
import { CustomButton } from '../CustomButton/CustomButton';
import { PAY_TOLLS_SUMMARY } from './references';

interface IMakePaymentButton {
  isSettleBalanceSectionShown: boolean;
  currentBalance: number;
  setIsSettleBalanceSectionShown: (isSettleBalanceSectionShown: boolean) => void;
  activeTab: DashboardParams;
}
const MakePaymentButton: FC<IMakePaymentButton> = ({
  isSettleBalanceSectionShown,
  currentBalance,
  setIsSettleBalanceSectionShown,
  activeTab,
}) => {
  const history = useNavigate();
  const { scrollToElem } = useScroll();

  const RedirectToUnpaidTolls = () => {
    if (activeTab !== DashboardParams.UNPAID_TOLLS) {
      history({ pathname: concatRoutes(MainRoutes.DASHBOARD, '/', DashboardParams.UNPAID_TOLLS) });
    }
    setIsSettleBalanceSectionShown(true);
  };

  const onMakePaymentClick = () => {
    RedirectToUnpaidTolls();
    setTimeout(() => scrollToElem(PAY_TOLLS_SUMMARY, true), 100);
  };

  const onClose = () => {
    setIsSettleBalanceSectionShown(false);
  };

  return (
    <div className="grow">
      {' '}
      {currentBalance < 0 && !isSettleBalanceSectionShown ? (
        <CustomButton
          className="mx-auto mt-5  sm:ml-auto sm:mr-0"
          onClick={onMakePaymentClick}
          content={<Trans>Make Payment</Trans>}
        />
      ) : null}
      {isSettleBalanceSectionShown ? (
        <CustomButton
          className="mx-auto mt-5 sm:ml-auto sm:mr-0"
          onClick={onClose}
          content={<Trans>Close</Trans>}
          isPrimary={false}
        />
      ) : null}
    </div>
  );
};
export default MakePaymentButton;

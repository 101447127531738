import React, { useState } from 'react';
import { AccountType } from '../../../types/onboarding.types';
import { useRouteMonitor } from '../../../hooks/location/useRouteMonitor';
import { Trans } from '@lingui/macro';
import BackButton from '../../BackButton/BackButton';
import { TranspondersComparison } from './TranspondersComparison';
import { FleetQuestion } from './FleetQuestion';
import { useOnboardingStore } from '../../../store/onboarding/onboarding.store';
import { OnboardingStepPath } from '../../../types/routes';
import useTransponderPrices from '../../../hooks/domain/dashboard/transponders/useTransponderPrices';
import { CustomButton } from 'src/components/CustomButton/CustomButton';

export default function OnboardingFleet(): React.ReactElement {
  const {
    onboardingInfo: { accountType },
    updateOnboardingStore,
  } = useOnboardingStore();
  const { miniPrice, proPrice, errorLoading: priceErrorLoading } = useTransponderPrices();
  const [error, setError] = useState(false);

  const [selected, setSelected] = useState(accountType);

  const onSelect = (type: AccountType) => {
    setError(false);
    setSelected(type);
  };

  const history = useRouteMonitor();

  function onSubmit(): void {
    if (!!selected) {
      setError(false);
      updateOnboardingStore({
        accountType: selected,
      });
      history.goToPath(OnboardingStepPath.ACCOUNT_RECOMMENDATION);
    } else {
      setError(true);
    }
  }

  return (
    <div className="onboarding-fleet h-full">
      <div className="onboarding-fleet__header">
        <h5 className="main-header onboarding-header">
          <Trans>First, determine how many transponders you will need...</Trans>
        </h5>
        <h4 className="secondary-header py-5 text-center">
          <Trans>How many transponders do you need for your fleet?</Trans>
        </h4>
      </div>
      <TranspondersComparison miniPrice={miniPrice} proPrice={proPrice} />
      <FleetQuestion {...{ error, selected, onSelect }} />
      {priceErrorLoading && (
        <div className="form-error text-center">
          <Trans>Unable to retrieve transponder prices. Please try again later.</Trans>
        </div>
      )}
      <div className="onboarding-fleet__button-container onboarding-button-container">
        <BackButton></BackButton>
        <CustomButton content="Continue" onClick={onSubmit} />
      </div>
    </div>
  );
}

import { Form } from 'antd';
import { t, Trans } from '@lingui/macro';
import { Controller, useFormContext } from 'react-hook-form';
import StyledInput from '../../../StyledInput/StyledInput';
import React from 'react';
import { IUserNames } from '../../../../graphql/signUp/signUpDetails.graphql';
interface ILastName {
  className?: string;
}
export const LastName = ({ className }: ILastName) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IUserNames>();
  return (
    <div className={className ? className : 'mb-4 w-full sm:w-5/12'}>
      <Form.Item label={<Trans>Last Name</Trans>} className="theme" help={errors.lastName?.message} required>
        <Controller
          name="lastName"
          control={control}
          render={({ field, fieldState }) => (
            <StyledInput
              field={field}
              fieldstate={fieldState}
              id="lastName"
              key="lastName"
              maxLength={40}
              aria-label={t`Last Name`}
            />
          )}
        />
      </Form.Item>
    </div>
  );
};

import { t, Trans } from '@lingui/macro';
import React from 'react';
import { OnboardingUserType } from 'src/types/onboarding.types';
import BackButton from '../../BackButton/BackButton';
import SelectableCard from '../../SelectableCard/SelectableCard';
import ScrollToTop from '../../ScrollToTop/ScrollToTop';
import '../../../pages/Onboarding/Onboarding.scss';
import { useOnboardingStore } from '../../../store/onboarding/onboarding.store';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import { useOnboardingQuestion } from './useOnboardingQuestion';

export default function OnboardingAccountType(): React.ReactElement {
  const {
    onboardingInfo: { userType },
  } = useOnboardingStore();
  const { selected, onSelect, onSubmit, error } = useOnboardingQuestion<OnboardingUserType>({
    defaultAnswerSelected: userType,
  });

  return (
    <div className="h-full">
      <ScrollToTop />
      <h5 className="onboarding-header text-center">
        <Trans>Before we get started, tell us a little about you!</Trans>
      </h5>
      <h4 className="py-5 text-center">
        <Trans>I am a...</Trans>
      </h4>
      <div className="onboarding-container grid grid-cols-1 md:grid-cols-2">
        <SelectableCard
          key={OnboardingUserType.RESIDENT}
          title={t`Permanent, Semi-Permanent, or Seasonal Resident`}
          content={t`I live in Florida full or part time, frequently visit Florida, or reside in Florida for large amounts of time throughout the year.`}
          onClick={() => onSelect(OnboardingUserType.RESIDENT)}
          selected={selected === OnboardingUserType.RESIDENT}
        ></SelectableCard>

        <SelectableCard
          key={OnboardingUserType.VISITOR}
          title={t`Temporary Visitor`}
          content={t`I am a temporary visitor, visit Florida on occasion, or plan on visiting in the near future.`}
          onClick={() => onSelect(OnboardingUserType.VISITOR)}
          selected={selected === OnboardingUserType.VISITOR}
        ></SelectableCard>

        <SelectableCard
          key={OnboardingUserType.COMMERCIAL}
          title={t`Commercial Entity`}
          content={t`I am a commercial entity interested in a fleet account to accommodate large volumes of vehicles and transponders.`}
          onClick={() => onSelect(OnboardingUserType.COMMERCIAL)}
          selected={selected === OnboardingUserType.COMMERCIAL}
        ></SelectableCard>

        <SelectableCard
          key={OnboardingUserType.GOVERNMENT}
          title={t`Government Figure`}
          content={
            <Trans>
              I am in, or will travel to, Florida as a government figure with the intent of performing official
              business, and meet the following <button className="green-link">requirements.</button>
            </Trans>
          }
          onClick={() => onSelect(OnboardingUserType.GOVERNMENT)}
          selected={selected === OnboardingUserType.GOVERNMENT}
        ></SelectableCard>
      </div>
      <div className={`form-error mt-6 text-center ${error ? 'visible' : 'hidden'}`}>
        <Trans>You must make a selection before continuing.</Trans>
      </div>
      <div className="onboarding-footer-text">
        <Trans>
          Preliminary information is collected for the purpose of recommending the best plan for your lifestyle. This
          information is not stored, sold, or used for any other purpose.
        </Trans>
      </div>
      <div className="onboarding-button-container">
        <BackButton></BackButton>
        <CustomButton onClick={onSubmit} content="Continue" type={'submit'} />
      </div>
    </div>
  );
}

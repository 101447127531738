import React, { FC } from 'react';
import { t } from '@lingui/macro';
import ConfirmationModal from './ConfirmationModal';

interface IConfirmationModalProps {
  showModal: boolean;
  onOk: () => void;
  onCancel?: (nextLocation: any) => void | (() => void);
  modalTitle?: string;
  modalContent?: string;
  modalCancelButtonText?: string;
  modalOkButtonText?: string;
}

const LeaveSiteConfirmationModal: FC<IConfirmationModalProps> = ({
  showModal,
  onOk,
  onCancel,
  modalTitle,
  modalContent,
  modalCancelButtonText,
  modalOkButtonText,
}) => {
  const cancelButtonText = modalCancelButtonText || t`Cancel`;
  const okButtonText = modalOkButtonText || t`Continue`;
  const title = modalTitle || t`Unsaved Data`;
  const content =
    modalContent ||
    t`Any information you have already entered will be lost. Select Continue to discard changes, or select Cancel to go back.`;

  return (
    <>
      {showModal && (
        <ConfirmationModal
          onOk={onOk}
          onCancel={onCancel}
          showModal={showModal}
          cancelButtonText={cancelButtonText}
          okButtonText={okButtonText}
          closable={true}
          title={title}
          content={content}
          alignButtons="center"
          alignText="center"
        />
      )}
    </>
  );
};
export default LeaveSiteConfirmationModal;

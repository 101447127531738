import { create } from '../createStore';
import { devtools } from 'zustand/middleware';

export interface IHomeStoreInfo {
  chosenOption: string;
  invoiceNumber: string;
  licenseNumber: string;
  documentId: string;
  postalCode: string;
}

export interface IHomeStore {
  homeInfo: IHomeStoreInfo;
  updateHomeStore: (invoiceData: IHomeStoreInfo) => void;
}

export const HOME_STORE_INITIAL_STATE: IHomeStoreInfo = {
  chosenOption: 'tollInvoice',
  invoiceNumber: '',
  licenseNumber: '',
  documentId: '',
  postalCode: '',
};

export const useHomeStore = create<IHomeStore, [['zustand/devtools', IHomeStore]]>(
  devtools((set) => ({
    homeInfo: HOME_STORE_INITIAL_STATE,
    updateHomeStore: (invoiceData: IHomeStoreInfo) => {
      set((state) => ({
        homeInfo: {
          ...state,
          chosenOption: invoiceData.chosenOption,
          invoiceNumber: invoiceData.invoiceNumber,
          licenseNumber: invoiceData.licenseNumber,
          documentId: invoiceData.documentId,
          postalCode: invoiceData.postalCode,
        },
      }));
    },
  })),
);

import { NotificationContext } from './NotificationContext';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { GET_NOTIFICATIONS, INotification } from '../graphql/notifications.graphql';
import { localStorageService } from '../services/localStorage';

export interface INotificationView extends INotification {
  dismissed: boolean;
}

export interface INotificationStatusInfo {
  numOfNotDismissedNotifications: number | undefined;
  hasNewNotifications: boolean;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const NotificationProvider = (props: PropsWithChildren<{}>) => {
  const [load, { loading }] = useLazyQuery<{ notifications: INotification[] }>(GET_NOTIFICATIONS, {
    fetchPolicy: 'network-only',
  });

  const [notifications, setNotifications] = useState<INotificationView[] | undefined>();
  const [notificationInfo, setNotificationInfo] = useState<INotificationStatusInfo>({
    hasNewNotifications: false,
    numOfNotDismissedNotifications: undefined,
  });
  useEffect(() => {
    const sessionNotifications = localStorageService.get('notifications') as INotificationView[] | undefined;
    sessionNotifications && setNotifications(sessionNotifications);
  }, []);

  useEffect(() => {
    localStorageService.set('notifications', JSON.stringify(notifications));
    setNotificationInfo((prevState) => {
      const filteredNotifications = notifications?.filter((notification) => !notification.dismissed)?.length || 0;
      return {
        ...prevState,
        hasNewNotifications: !!notifications?.some((notification) => !notification.dismissed),
        numOfNotDismissedNotifications: filteredNotifications > 0 ? filteredNotifications : -1,
      };
    });
  }, [notifications]);

  const dismiss = (id: string) => {
    setNotifications((prevState) => {
      return prevState?.map((notification) => {
        return {
          ...notification,
          dismissed: notification.id === id ? true : notification.dismissed,
        };
      });
    });
  };

  const dismissAll = () => {
    setNotifications((prevState) => {
      return prevState?.map((notification) => {
        return {
          ...notification,
          dismissed: true,
        };
      });
    });
  };

  const loadNotifications = async (loadWithoutReset = false) => {
    !loadWithoutReset && setNotifications(undefined);
    const response = await load();
    setNotifications(
      response?.data?.notifications?.map((notification) => {
        return {
          ...notification,
          dismissed: false,
        };
      }),
    );
  };

  const clear = async () => {
    localStorageService.clear();
    setNotifications(undefined);
  };

  return (
    <NotificationContext.Provider
      value={{
        dismissAll,
        dismiss,
        notifications,
        loading,
        loadNotifications,
        notificationInfo,
        clear,
      }}
      {...props}
    />
  );
};

import React, { useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import ReloadButton from '../../ReloadButton/ReloadButton';
import { useFormContext } from 'react-hook-form';
import CollapsableContainer from '../../CollapsableContainer/CollapsableContainer';
import { IAccountInfo } from '../../../types/onboarding.types';
import { Username } from '../../PrivacyAndSecurity/Username';
import { Password } from '../../PrivacyAndSecurity/Password';
import { ReTypePassword } from '../../PrivacyAndSecurity/ReTypePassword';
import { SecurityQuestionsSectionGeneric } from '../../PrivacyAndSecurity/SecurityQuestionsSectionGeneric';

export type IPrivacyAndSecurity = {
  errorLoadingSecurityQuestions: boolean;
};

export const OnboardingPrivacyAndSecurity = ({ errorLoadingSecurityQuestions }: IPrivacyAndSecurity) => {
  const [securityCollapsed, setSecurityCollapsed] = useState(false);

  const {
    formState: { errors, isValid, isSubmitted },
  } = useFormContext<IAccountInfo>();

  useEffect(() => {
    if (!isValid && isSubmitted) {
      setSecurityCollapsed(false);
    }
  }, [errors, isSubmitted, isValid]);

  return (
    <CollapsableContainer
      title={t`Privacy and Security`}
      onChange={(panels: any) => setSecurityCollapsed(!panels[0])}
      activeKey={securityCollapsed ? [] : ['1']}
    >
      {errorLoadingSecurityQuestions ? (
        <div className="page-error-container">
          <div className="page-error-message">
            <Trans>
              Unable to load this form. Please reload the page and try again.
              <br />
              We are sorry for the inconvenience.
            </Trans>
          </div>
          <ReloadButton />
        </div>
      ) : (
        <>
          <Username className="w-full sm:w-6/12 sm:pr-1" />
          <Password />
          <ReTypePassword />
          <SecurityQuestionsSectionGeneric />
        </>
      )}
    </CollapsableContainer>
  );
};

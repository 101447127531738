export const BULK_UPLOAD_ERROR_CODES_TO_BE_HANDLED: number[] = [1614, 1615, 1616, 1617];

export const AUTH_ERROR_CODES_TO_BE_HANDLED: number[] = [1004];

export const SERVER_ERRORS: number[] = [500, 501, 502, 504];

export const TRANSPONDER_ACTIVATION_SIGN_UP_ERROR = 1307;

export const VEHICLES_MISSING_ERROR = 1253;

export const INVALID_PRE_SET_AMOUNT = 1259;

export const INCORRECT_TOTAL_AMOUNT = 1273;

export const INCORRECT_USERNAME = 1186;

export const INVALID_TRANSPONDER = 1252;

export const REGISTERED_TRANSPONDER = 1251;

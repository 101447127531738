import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ChangeEvent } from 'react';
import { colors } from 'src/theme/theme';
import './PriceInput.scss';
import { isPositiveNumber, isPriceRegex } from '../../utils/string.utils';
import { formatToPrice } from '../../utils/formatter';

interface Props {
  id: string;
  value: string;
  onChange: (value: string | React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (value: string | React.ChangeEvent<HTMLInputElement>) => void;
  defaultValue?: string;
  hasErrors: boolean;
  disabled?: boolean;
}

export default function PriceInput(props: Props): React.ReactElement {
  const { value, onChange, onBlur, defaultValue, disabled, hasErrors, id } = props;
  const defaultV = defaultValue && defaultValue !== '' ? formatToPrice(defaultValue) : '0.00';

  const handleOnBlur = () => {
    if (!isPositiveNumber(value)) {
      onChange(defaultV);
      onBlur(defaultV);
      return;
    }

    const formattedValue = formatToPrice(value);

    onChange(formattedValue);
    onBlur(formattedValue);
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const currValue = event.target.value;
    if (currValue === '' || isPriceRegex(currValue)) {
      onChange(currValue.replace(',', '.'));
    }
  };

  return (
    <div className={`price-input-container ${hasErrors ? 'price-input-error' : ''}`}>
      <div className={`price-icon-container ${disabled ? 'price-icon-container-disabled' : ''}`}>
        <FontAwesomeIcon className="price-icon" icon={faDollarSign} color={colors.primary.white}></FontAwesomeIcon>
      </div>
      <input
        {...props}
        id={id}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        value={value}
        autoComplete="off"
        className={`price-input` + (hasErrors ? ' input-error' : '')}
        disabled={disabled ? disabled : false}
      ></input>
    </div>
  );
}

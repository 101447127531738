import { gql } from '@apollo/client';

export const GET_SECURITY_QUESTION_FOR_FORGOTTEN_PASSWORD = gql`
  query forgotPasswordGetSecurityQuestion(
    $forgotPasswordGetSecurityQuestionInput: ForgotPasswordGetSecurityQuestionInput!
  ) {
    forgotPasswordGetSecurityQuestion(forgotPasswordGetSecurityQuestionInput: $forgotPasswordGetSecurityQuestionInput) {
      securityQuestion
    }
  }
`;

export const FORGOT_USERNAME = gql`
  query forgotUsername($forgotUsernameInput: ForgotUsernameInput!) {
    forgotUsername(forgotUsernameInput: $forgotUsernameInput) {
      username
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation forgotPasswordUpdate($forgotPasswordUpdateInput: ForgotPasswordUpdateInput!) {
    forgotPasswordUpdate(forgotPasswordUpdateInput: $forgotPasswordUpdateInput)
  }
`;

export interface IForgotPasswordUpdateInput {
  accountNumber?: string;
  newPassword: string;
  securityAnswer: string;
  username?: string;
  zipcode: string;
}

export interface IForgotPasswordGetSecurityQuestionInput {
  accountNumber?: string;
  username?: string;
  zipcode: string;
}

export interface IForgotPasswordGetSecurityQuestionOutput {
  forgotPasswordGetSecurityQuestion: { securityQuestion: string };
}

export interface IForgotUsernameInput {
  accountNumber?: string;
  transponderNumber?: string;
  zipcode: string;
}
export interface IForgotUsernameOutput {
  username: string;
}

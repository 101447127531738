import { UseFormReturn } from 'react-hook-form';
import { IContactSettings } from '../../../components/MyAccount/ContactInformation/Contact';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ACCOUNT_DETAILS, IAccountDetails } from '../../../graphql/accountDetails.graphql';
import { IAccountSettingsUpdateInput, UPDATE_ACCOUNT_SETTINGS } from '../../../graphql/accountSettings.graphql';
import React, { useEffect, useMemo, useState } from 'react';
import { useContactSettingsStore } from '../../../store/account/contactSettings.store';
import {
  CONTACT_SETTINGS_DEFAULT_VALUES,
  generateDefaultValuesFromAccountDetails,
  mapChangesToUpdateAccountSettings,
} from '../../../components/MyAccount/ContactInformation/initialState';
import { useCloseAccount } from './useCloseAccount';
import { BillingAddressFormField } from '../../../components/Address/AddressFormField.enum';
import { FL_STATE_CODE, USA_COUNTRY_ISO3 } from '../../../utils/countryStates.utils';
import { useSubmitBtnDisable } from './useSubmitBtnDisable';
import RemovePhoneNumberConfirmationModal from '../../../components/Modals/ConfirmationModal/RemovePhoneNumberConfirmationModal';
import { reformatPhoneNumber } from '../../../validators/masks';
import { showMessage } from 'src/utils/message.utils';
import { useSecurityCodeModalStore } from 'src/store/payment/securityCodeModal.store';
import { IAddressValidationOutput } from '../../../graphql/addressValidation.graphql';
import { useAddressValidation } from '../../useAddressValidation';
import {
  getObjectKeys,
  isElementIncluded,
  getObjectDiff,
  generateObjectOfDiffrences,
} from '../../../utils/object.utils';
import MailingAddressStandardizationModal from '../../../components/Modals/ConfirmationModal/MailingAddressStandardizationModal';

export interface IUseContactSubmittingReturnType {
  submitForm: (inputData: IContactSettings) => Promise<void>;
  setIsSubmitBtnDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  modals: JSX.Element[];
}

interface IUseContactSubmittingProps {
  methods: UseFormReturn<IContactSettings>;
}

export const useContactSubmitting = ({ methods }: IUseContactSubmittingProps): IUseContactSubmittingReturnType => {
  const { data, refetch } = useQuery<{ accountDetails: IAccountDetails }>(GET_ACCOUNT_DETAILS);

  const {
    watch,
    reset,
    resetField,
    formState: { isSubmitSuccessful, isDirty },
  } = methods;

  const [isSubmitError, setIsSubmitError] = useState(false);
  const [showRemovePhoneModal, setShowRemovePhoneModal] = useState(false);
  const [formData, setFormData] = useState<IContactSettings>(CONTACT_SETTINGS_DEFAULT_VALUES);
  const [isAddressStandardizationModalOpen, setIsAddressStandardizationModalOpen] = useState(false);
  const [isAddressStandardized, setIsAddressStandardized] = useState(true);

  const {
    setShowSecurityCodeModal,
    securityCodeModalData: { securityCode, isNewPaymentMethod },
  } = useSecurityCodeModalStore();

  const { setIsSubmitBtnDisabled } = useSubmitBtnDisable({
    isSubmitError,
    isSubmitSuccessful,
    isDirty,
  });

  const [updateAccountSettings, { loading: updateAccountSettingsLoading }] = useMutation<
    { updateResult: boolean },
    { accountSettingsUpdateInput: IAccountSettingsUpdateInput }
  >(UPDATE_ACCOUNT_SETTINGS, {
    refetchQueries: [
      {
        query: GET_ACCOUNT_DETAILS,
      },
    ],
  });

  const {
    closeAccountInfo: { isCloseProcessActive },
  } = useContactSettingsStore();

  const { close, closeModal, closeAccountLoading } = useCloseAccount();

  useEffect(() => {
    resetField(BillingAddressFormField.STATE, { defaultValue: FL_STATE_CODE });
    resetField(BillingAddressFormField.COUNTRY, { defaultValue: USA_COUNTRY_ISO3 });
  }, [isCloseProcessActive, data]);

  const confirmRemovingPhoneNumber = async () => {
    await updateAccountData(formData);
    setShowRemovePhoneModal(false);
  };

  const isCellPhoneEmpty = () => {
    return watch('cellPhone') === '';
  };

  const mobilePhoneNumber = reformatPhoneNumber(data?.accountDetails.cellPhone);

  const removePhoneNumberConfirmationModal = useMemo((): JSX.Element => {
    return (
      <RemovePhoneNumberConfirmationModal
        showModal={showRemovePhoneModal}
        onOk={confirmRemovingPhoneNumber}
        onCancel={() => setShowRemovePhoneModal(false)}
        mobilePhoneNumber={mobilePhoneNumber}
      />
    );
  }, [showRemovePhoneModal, confirmRemovingPhoneNumber]);

  const updateAccountData = async (inputData: IContactSettings) => {
    const prevContactData = generateDefaultValuesFromAccountDetails(data?.accountDetails);
    const propertiesToChange = getObjectDiff(prevContactData, inputData);
    const changes = generateObjectOfDiffrences(propertiesToChange, inputData);
    const mappedValues = mapChangesToUpdateAccountSettings(
      changes as unknown as IContactSettings,
      inputData,
      data?.accountDetails,
    );

    try {
      await updateAccountSettings({
        variables: {
          accountSettingsUpdateInput: mappedValues,
        },
      });
      await refetch();
      setIsSubmitError(false);
      reset();
      showMessage('success');
    } catch (err) {
      setIsSubmitError(true);
      showMessage('error');
    }
  };

  const isBalanceNegative = data && data.accountDetails.currentBalance < 0;

  const language = data ? data.accountDetails.language : 'English';

  const { getStandardizedAddress, standardizedAddressData, standardizedAddressLoading } = useAddressValidation();

  const submitForm = async (inputData: IContactSettings) => {
    setFormData(inputData);
    const addressValidationInput = {
      addressLine1: inputData.addressLine1,
      addressLine2: !!inputData.addressLine2 ? inputData.addressLine2 : '',
      country: inputData.country,
      state: !!inputData.state ? inputData.state : '',
      city: inputData.city,
      zipcode: !!inputData.postalCode ? inputData.postalCode : '',
      language: language,
    };

    const prevContactData = generateDefaultValuesFromAccountDetails(data?.accountDetails);
    const propertiesToChange = getObjectDiff(prevContactData, inputData);
    const objectKeyes = getObjectKeys(addressValidationInput);
    const shouldUseAdressStandarization = isElementIncluded(objectKeyes, propertiesToChange);

    if (shouldUseAdressStandarization) {
      try {
        const response = await getStandardizedAddress({
          variables: {
            addressValidationInput: addressValidationInput,
          },
        });

        response?.data?.addressValidation && setIsAddressStandardizationModalOpen(true);
      } catch (err) {
        console.log((err as Error).message);
      }
    } else {
      confirmAddressStandardization(inputData);
    }
  };

  const closeWithSecurityModal = async (inputData: IContactSettings) => {
    if (!securityCode && !isNewPaymentMethod && isBalanceNegative) {
      setShowSecurityCodeModal(true);
    } else {
      setShowSecurityCodeModal(false);
      await close(inputData);
    }
  };

  const mapAddressToStandardizedForm = (standardizedAddressResult: IAddressValidationOutput) => {
    return {
      ...formData,
      addressLine1: standardizedAddressResult.addressLine1,
      addressLine2: standardizedAddressResult.addressLine2,
      country: standardizedAddressResult.country,
      state: standardizedAddressResult.state,
      city: standardizedAddressResult.city,
      zipCode: standardizedAddressResult.zipcode,
    };
  };

  const confirmAddressStandardization = async (inputData: IContactSettings) => {
    if (isCloseProcessActive) {
      await closeWithSecurityModal(inputData);
    } else {
      if (isCellPhoneEmpty()) {
        setShowRemovePhoneModal(true);
      } else {
        if (isAddressStandardized && standardizedAddressData) {
          await updateAccountData(mapAddressToStandardizedForm(standardizedAddressData.addressValidation));
        } else {
          await updateAccountData(inputData);
        }
      }
    }
    setIsAddressStandardizationModalOpen(false);
  };

  const standardizationModal = useMemo(() => {
    return (
      <MailingAddressStandardizationModal
        showModal={isAddressStandardizationModalOpen}
        onOk={async () => await confirmAddressStandardization(formData)}
        onCancel={() => setIsAddressStandardizationModalOpen(false)}
        standardizedAddressData={standardizedAddressData}
        setIsAddressStandardized={setIsAddressStandardized}
      />
    );
  }, [isAddressStandardizationModalOpen, standardizedAddressData]);

  return {
    submitForm,
    loading: closeAccountLoading || updateAccountSettingsLoading || standardizedAddressLoading,
    setIsSubmitBtnDisabled,
    modals: [closeModal, removePhoneNumberConfirmationModal, standardizationModal],
  };
};

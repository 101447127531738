import ScrollToTop from '../../ScrollToTop/ScrollToTop';
import { t, Trans } from '@lingui/macro';
import SelectableCard from '../../SelectableCard/SelectableCard';
import { OnboardingFlowType } from '../../../types/onboarding.types';
import BackButton from '../../BackButton/BackButton';
import { CustomButton } from '../../CustomButton/CustomButton';
import { Link } from 'react-router-dom';
import { useOnboardingQuestion } from './useOnboardingQuestion';
import { useOnboardingStore } from '../../../store/onboarding/onboarding.store';
import { SELECT_DETAILS } from '../../../store/onboarding/onboarding.selectors';
import { useState } from 'react';
import { useRouteMonitor } from 'src/hooks/location/useRouteMonitor';
import AbandonAccountCreationModal from 'src/components/Modals/ConfirmationModal/accountCreation/AbandonAccountCreationModal';
import { MainRoutes } from 'src/types/routes';
import { resetAllStores } from 'src/store/createStore';

export const OnboardingPrepaidTransponderQuestion = () => {
  const { onboardingFlowType } = useOnboardingStore(SELECT_DETAILS);
  const [isExitModalOpen, setIsExitModalOpen] = useState(false);
  const { onSubmit, onSelect, error, selected } = useOnboardingQuestion<OnboardingFlowType>({
    defaultAnswerSelected: onboardingFlowType,
  });
  const history = useRouteMonitor();

  const onConfirmAbandoningAccountCreation = () => {
    resetAllStores();
    history.goBack();
  };

  return (
    <div className="h-full">
      <ScrollToTop />
      <h5 className="onboarding-header text-center">
        <Trans>Before we get started, tell us a little about you!</Trans>
      </h5>
      <h4 className="py-5 text-center">
        <Trans>Do you already have a transponder?</Trans>
      </h4>
      <div className="onboarding-container">
        <SelectableCard
          key={OnboardingFlowType.PURCHASE}
          title={t`No, I do not have a transponder.`}
          content={t`Select this option if you do not already have a transponder, but would like to proceed to create a SunPass account. You will be able to purchase transponders as part of the account creation process.`}
          onClick={() => onSelect(OnboardingFlowType.PURCHASE)}
          selected={selected === OnboardingFlowType.PURCHASE}
          autoHeight
        ></SelectableCard>

        <SelectableCard
          key={OnboardingFlowType.PREPAID}
          title={t`Yes, I already have a transponder in my possession.`}
          content={t`Select this option if you already have a transponder, but need to activate it and create an account.`}
          onClick={() => onSelect(OnboardingFlowType.PREPAID)}
          selected={selected === OnboardingFlowType.PREPAID}
          autoHeight
        ></SelectableCard>

        <p className="mx-auto my-0 mt-10 text-center">
          <Trans>
            Already started the account creation process and have a reference number? <br />
            <Link to={MainRoutes.RESUME_ACCOUNT_CREATION} className="underline">
              Resume account creation.
            </Link>
          </Trans>
        </p>
      </div>
      <div className={`form-error mt-6 text-center ${error ? 'visible' : 'hidden'}`}>
        <Trans>You must make a selection before continuing.</Trans>
      </div>
      <div className="onboarding-footer-text">
        <Trans>
          Preliminary information is collected for the purpose of recommending the best plan for your lifestyle. This
          information is not stored, sold, or used for any other purpose.
        </Trans>
      </div>
      <div className="onboarding-button-container">
        <BackButton
          content="Cancel"
          onBack={() => {
            setIsExitModalOpen(true);
          }}
          blockRedirectTill={true}
        />
        <AbandonAccountCreationModal
          showModal={isExitModalOpen}
          onCancel={() => setIsExitModalOpen(false)}
          onOk={onConfirmAbandoningAccountCreation}
        />
        <CustomButton onClick={onSubmit} content="Continue" type={'submit'} />
      </div>
    </div>
  );
};

import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { sortPaymentMethods } from 'src/components/Dashboard/Wallet/EasyPay/paymentMethods.utils';
import { IPaymentMethodGraphQl, PAYMENT_METHODS } from 'src/graphql/paymentMethods.graphql';

interface IUseSortPaymentMethods {
  sortedPaymentMethods: IPaymentMethodGraphQl[] | undefined;
  isPaymentMethodListLoading: boolean;
  storedCards: IPaymentMethodGraphQl[] | undefined;
  methodsWithEasyPay: IPaymentMethodGraphQl[] | undefined;
  cardsWithEasyPay: IPaymentMethodGraphQl[] | undefined;
  accountsWithEasyPay: IPaymentMethodGraphQl[] | undefined;
  doesUserHaveCardOrAccountWithEZAndStoredCard: boolean | undefined;
  doesUserHaveNoPaymentMethodsAndStoredCard: boolean | undefined;
  doesUserHaveOnlyOnePMWithEasyPayAndNoStoredCards: boolean | undefined;
}
export const useSortPaymentMethods = (allowBankPayment = true, isOnboardingPayment = false): IUseSortPaymentMethods => {
  let paymentMethodList: { paymentMethodList: IPaymentMethodGraphQl[] } | undefined = undefined;
  let isPaymentMethodListLoading = false;
  if (!isOnboardingPayment) {
    const { data: paymentMethods, loading: paymentMethodListLoading } = useQuery<{
      paymentMethodList: IPaymentMethodGraphQl[];
    }>(PAYMENT_METHODS, {
      errorPolicy: 'all',
    });
    paymentMethodList = paymentMethods;
    isPaymentMethodListLoading = paymentMethodListLoading;
  }

  const sortedPaymentMethods = useMemo(() => {
    return sortPaymentMethods(paymentMethodList?.paymentMethodList, allowBankPayment);
  }, [paymentMethodList, isPaymentMethodListLoading]);

  const storedCards = sortedPaymentMethods?.filter((method) => method.cardNumber !== '' && method.isEasyPay === false);
  const methodsWithEasyPay = sortedPaymentMethods?.filter((method) => method.isEasyPay === true);

  const cardsWithEasyPay = methodsWithEasyPay?.filter((method) => method.cardNumber !== '');
  const accountsWithEasyPay = methodsWithEasyPay?.filter((method) => method.bankAccountNumber !== '');

  const doesUserHaveCardOrAccountWithEZAndStoredCard =
    ((cardsWithEasyPay && cardsWithEasyPay?.length === 1) ||
      (accountsWithEasyPay && accountsWithEasyPay?.length === 1)) &&
    storedCards?.length !== 0;

  const doesUserHaveNoPaymentMethodsAndStoredCard = methodsWithEasyPay?.length === 0 && storedCards?.length === 1;

  const doesUserHaveOnlyOnePMWithEasyPayAndNoStoredCards =
    methodsWithEasyPay?.length === 1 && storedCards?.length === 0;

  return {
    sortedPaymentMethods,
    isPaymentMethodListLoading,
    storedCards,
    methodsWithEasyPay,
    cardsWithEasyPay,
    accountsWithEasyPay,
    doesUserHaveCardOrAccountWithEZAndStoredCard,
    doesUserHaveNoPaymentMethodsAndStoredCard,
    doesUserHaveOnlyOnePMWithEasyPayAndNoStoredCards,
  };
};

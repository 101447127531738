import { FC } from 'react';
import { Trans } from '@lingui/macro';
import ClockIcon from '../../assets/Yellow-Clock-Icon.svg';
import { Link } from 'react-router-dom';
import { convertPriceToString } from '../../../../utils/formatter';
import { DashboardParams, MainRoutes } from '../../../../types/routes';

interface ITranspondersActivityHeaderProps {
  currentBalance?: number;
}

const TranspondersActivityHeader: FC<ITranspondersActivityHeaderProps> = ({ currentBalance }) => {
  return (
    <div className="mb-6 mt-10 flex items-center justify-between border-t border-gray-300 pt-10 lg:justify-start">
      <div className="flex items-center justify-start">
        <img src={ClockIcon} alt="clock icon" />
        <h1 className="ml-3 text-xl font-bold sm:ml-6 sm:text-3xl">
          <Trans>Recent Toll Activity</Trans>
        </h1>
      </div>
      <Link
        to={`${MainRoutes.DASHBOARD}/${DashboardParams.ACTIVITY}`}
        className={`dashboard-links mt-1 inline w-24 text-center text-base`}
      >
        <Trans>View All</Trans>
      </Link>
      {(currentBalance && currentBalance >= 0) || !currentBalance ? null : (
        <div className="ml-auto hidden w-72 justify-self-end pr-8 text-right text-xl font-bold lg:block">
          <>
            <Trans>You currently owe</Trans>{' '}
            <span className="text-2xl text-red-600">{convertPriceToString(Math.abs(currentBalance || 0))}</span>
          </>
        </div>
      )}
    </div>
  );
};

export default TranspondersActivityHeader;

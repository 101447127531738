import jwtDecode, { JwtPayload } from 'jwt-decode';
import moment from 'moment';

const TOKEN = 'accessToken';
const INVOICE_TOKEN = 'invoiceToken';
const REFRESH_TOKEN = 'refreshToken';

export const setAccessToken = (token?: string): void => {
  if (token) {
    localStorage.setItem(TOKEN, token);
  }
};

export const setInvoiceToken = (token?: string): void => {
  if (token) {
    localStorage.setItem(INVOICE_TOKEN, token);
  }
};

export const getInvoiceToken = (): string | null => {
  return localStorage.getItem(INVOICE_TOKEN);
};

export const getAccessToken = (): string | null => {
  return localStorage.getItem(TOKEN);
};

export const setRefreshToken = (refreshToken?: string): void => {
  if (refreshToken) {
    localStorage.setItem(REFRESH_TOKEN, refreshToken);
  }
};

export const clearSession = (): void => {
  localStorage.clear();
};

export const getRefreshToken = (): string | null => {
  return localStorage.getItem(REFRESH_TOKEN);
};

export const isTokenValid = () => {
  const token = getAccessToken();
  if (!token) return true;
  const { exp } = jwtDecode<JwtPayload>(token);
  if (exp) {
    const expirationTimeInSeconds = exp * 1000;
    const now = moment().toDate();
    return expirationTimeInSeconds >= now.getTime();
  } else {
    return false;
  }
};

import { baseUsernameSchema } from '../../components/ForgetUsernameOrPassword/ForgotUsernameOrPassword.schema';
import { validators } from '../validators';
import {
  NAME_AND_RESPONSE_MAX_ERROR_MSG,
  PASSWORD_ERROR_MSG,
  RESPONSE_REQUIRED,
  USERNAME_ERROR_MSG,
} from '../onboarding/accountInfo/accountInfoErrorMessages';
import * as yup from 'yup';

export const getAccessOnlineAccountSchemaByCurrentStep = (step: number, isSubmitted: boolean) => {
  let schema;
  if (step === 1) {
    schema = {
      ...baseUsernameSchema,
    };
  } else if (step === 2) {
    schema = {
      answer: yup.string().required(RESPONSE_REQUIRED).max(40, NAME_AND_RESPONSE_MAX_ERROR_MSG),
    };
  } else if (step === 3) {
    schema = {
      username: validators.username(USERNAME_ERROR_MSG),
      password: validators.password.required(PASSWORD_ERROR_MSG),
      reTypePassword: validators.passwordConfirm(isSubmitted),
    };
  }
  if (schema) return yup.object().shape(schema, [['transponderNumber', 'accountNumber']]);
  throw Error('Unsupported step number for Access Online Account schema');
};

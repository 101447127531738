import { BalanceHeader } from './BalanceHeader';
import React from 'react';
import { BalanceCards } from './BalanceCards';

export const Balance = () => {
  return (
    <div>
      <BalanceHeader />
      <BalanceCards />
    </div>
  );
};

import { yupResolver } from '@hookform/resolvers/yup';
import { t, Trans } from '@lingui/macro';
import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { MainLoader } from '../../../Loader/MainLoader';
import styles from '../Vehicles.module.scss';
import moment from 'moment';
import { ApolloQueryResult, useQuery } from '@apollo/client';
import { IVehicleList, IVehicleListInput } from 'src/graphql/vehicles.graphql';
import { getVehicleSchemaForDashboard } from 'src/validators/onboarding/vehicles/vehicle.validator';
import IsTrailer from 'src/components/atoms/form/vehicle/IsTrailer';
import LicencePlateCountry from 'src/components/atoms/form/vehicle/LicencePlateCountry';
import LicencePlateNumber from 'src/components/atoms/form/vehicle/LicencePlateNumber';
import LicencePlateState from 'src/components/atoms/form/vehicle/LicencePlateState';
import LicencePlateType from 'src/components/atoms/form/vehicle/LicencePlateType';
import VehicleMake from 'src/components/atoms/form/vehicle/VehicleMake';
import VehicleModel from 'src/components/atoms/form/vehicle/VehicleModel';
import VehicleYear from 'src/components/atoms/form/vehicle/VehicleYear';
import VehicleColor from 'src/components/atoms/form/vehicle/VehicleColor';
import VehicleIsRental from 'src/components/atoms/form/vehicle/VehicleIsRental';
import EffectiveStartDate from 'src/components/atoms/form/vehicle/EffectiveStartDate';
import EffectiveStartTime from 'src/components/atoms/form/vehicle/EffectiveStartTime';
import EffectiveEndDate from 'src/components/atoms/form/vehicle/EffectiveEndDate';
import EffectiveEndTime from 'src/components/atoms/form/vehicle/EffectiveEndTime';
import { emptyVehicle } from 'src/store/vehicles/vehicle.store';
import { useScroll } from '../../../../hooks/screen/useScroll';
import LeaveSiteConfirmationModal from 'src/components/Modals/ConfirmationModal/LeaveSiteConfirmationModal';
import { useCallbackPrompt } from 'src/hooks/location/useCallbackPrompt';
import { DEFAULT_VEHICLE } from 'src/components/Onboarding/Vehicles/initialState';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import { mapDefaultVehicleFormValues } from './defaultValues';
import { useVehicleForm } from 'src/hooks/vehicles/useVehicleForm';
import { IAccountDetails, GET_ACCOUNT_DETAILS } from 'src/graphql/accountDetails.graphql';

export interface IVehicleFormSubmit {
  plateNumber: string;
  plateType: string;
  plateState: string;
  vehicleCountry: string;
  isTrailer: boolean;
  vehicleMake: string;
  vehicleModel: string;
  vehicleYear: number;
  vehicleColor: string;
  isRental: boolean;
  effectiveStartDate: moment.Moment;
  effectiveStartTime: string;
  effectiveEndDate: moment.Moment;
  effectiveEndTime: string;
  plateTypeDesc: string;
}

interface IVehicleForm {
  refetch: (
    variables?: Partial<{ vehicleListInput: IVehicleListInput }> | undefined,
  ) => Promise<ApolloQueryResult<{ vehicleList: IVehicleList }>>;
}

const VehicleForm: FC<IVehicleForm> = ({ refetch }) => {
  const [showRedirectPrompt, setShowRedirectPrompt] = useState(false);

  const { cancelRedirect, confirmRedirect, showPrompt } = useCallbackPrompt(showRedirectPrompt);

  const {
    loading,
    ref,
    isEditMode,
    isFormVisible,
    vehicleToEdit,
    closeForm,
    setIsFormVisible,
    setVehicleToEdit,
    onSubmit,
    showErrorMessage,
  } = useVehicleForm({ refetch });
  const { data } = useQuery<{ accountDetails: IAccountDetails }>(GET_ACCOUNT_DETAILS);

  const { scrollToError } = useScroll();

  const accountType = data?.accountDetails.accountType;

  const methods = useForm<any>({
    resolver: yupResolver(getVehicleSchemaForDashboard(accountType, isEditMode)),
    mode: 'onChange',
    defaultValues: vehicleToEdit.vehicleId.length ? mapDefaultVehicleFormValues(vehicleToEdit) : { ...DEFAULT_VEHICLE },
  });

  const {
    watch,
    handleSubmit,
    formState: { errors },
  } = methods;

  useEffect(() => {
    scrollToError(errors);
  }, [errors]);

  useEffect(() => {
    setShowRedirectPrompt(isFormVisible);
  }, [isFormVisible]);

  const confirmRedirectUser = async () => {
    await confirmRedirect();
    setIsFormVisible(false);
    setVehicleToEdit(emptyVehicle);
  };
  const isValidDate = watch('effectiveStartDate')?.isValid();

  return (
    <>
      <MainLoader loading={loading} />
      <FormProvider {...methods}>
        <form ref={ref} onSubmit={handleSubmit(onSubmit)}>
          <div className="mx-auto my-6 rounded-md bg-white p-10 shadow-xl">
            <div className={`flex flex-wrap ${styles['form-container']} `}>
              <LicencePlateNumber name={'plateNumber'} isEditMode={isEditMode} />
              <LicencePlateState name={'plateState'} isEditMode={isEditMode} />
              <LicencePlateCountry name={'vehicleCountry'} isEditMode={isEditMode} />
              <LicencePlateType name={'plateType'} isEditMode={isEditMode} />
              <IsTrailer name={'isTrailer'} isEditMode={isEditMode} />

              {!watch('isTrailer') && (
                <>
                  <VehicleMake name={'vehicleMake'} />
                  <VehicleModel name={'vehicleModel'} />
                  <VehicleYear name={'vehicleYear'} />
                </>
              )}

              <VehicleColor name={'vehicleColor'} />
              <VehicleIsRental name={'isRental'} />

              <div className="mb-4 flex w-full">
                <EffectiveStartDate name={'effectiveStartDate'} shouldDefaultBeEmpty={!isValidDate} />
                <EffectiveStartTime name={'effectiveStartTime'} />
                <EffectiveEndDate name={'effectiveEndDate'} isInMyDashboard />
                <EffectiveEndTime name={'effectiveEndTime'} isInMyDashboard />
              </div>
              <span className="text-sm text-opacity-60" id={'note'}>
                {t`Note: If you've driven through tolls before you set up your SunPass Account, you can backdate your start date to the date of your first toll invoice, and your SunPass Account Member toll rate will be applied to the tolls you've accrued since that date`}
              </span>
              {showErrorMessage && (
                <div className="form-error mt-5 text-center">
                  <Trans id="Effective start date cannot be future date. Reminder that Florida and the SunPass system are based in EST. If the device you're using is in a different timezone, it could be the cause of this error." />
                </div>
              )}
            </div>
            <div className={styles['buttons-container']}>
              <CustomButton
                className="mx-4 mb-4 w-full sm:w-auto"
                content="Cancel"
                isPrimary={false}
                onClick={closeForm}
              />{' '}
              <CustomButton type="submit" className="mx-4 mb-4 w-full sm:w-auto" content="Save Changes" />
            </div>
          </div>
        </form>
      </FormProvider>
      <LeaveSiteConfirmationModal showModal={showPrompt} onOk={confirmRedirectUser} onCancel={cancelRedirect} />
    </>
  );
};

export default VehicleForm;

import { Trans } from '@lingui/macro';
import { Form } from 'antd';
import React, { FC, useEffect } from 'react';
import './HomeSearch.scss';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InvoiceNumber from '../atoms/form/home/InvoiceNumber';
import Options from '../atoms/form/home/Options';
import LicencePlateNumber from '../atoms/form/vehicle/LicencePlateNumber';
import { MainRoutes } from '../../types/routes';
import { useNavigate } from 'react-router-dom';
import DocumentId from '../atoms/form/home/DocumentId';
import { PostalCode } from '../atoms/form/address/PostalCode';
import { HOME_STORE_INITIAL_STATE, IHomeStoreInfo, useHomeStore } from '../../store/home/home.store';
import { InvoiceSchema } from '../../validators/home/home.validator';

export interface IHomeSearchForm {
  options: string;
  invoiceNumber: string;
  licenseNumber: string;
  documentId: string;
  postalCode: string;
}

const schema = InvoiceSchema();

const HomeSearch: FC = () => {
  const { updateHomeStore } = useHomeStore();

  const methods = useForm<IHomeSearchForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: HOME_STORE_INITIAL_STATE,
  });

  const { handleSubmit, setValue, watch, clearErrors, resetField } = methods;

  const optionsWatch = watch('options');

  const onSubmit = (formData: IHomeSearchForm) => {
    const invoiceData: IHomeStoreInfo = {
      chosenOption: optionsWatch,
      invoiceNumber: formData.invoiceNumber,
      licenseNumber: formData.licenseNumber,
      documentId: formData.documentId,
      postalCode: formData.postalCode,
    };
    updateHomeStore(invoiceData);
    history(MainRoutes.PAY_A_TOLL_INVOICE_OT_TRAFFIC_CITATION);
  };

  const clearForm = () => {
    clearErrors();
    resetField('postalCode');
    resetField('documentId');
    resetField('invoiceNumber');
    resetField('licenseNumber');
  };

  useEffect(() => {
    setValue('options', 'tollInvoice');
  }, []);

  const history = useNavigate();

  return (
    <div className="home-search">
      <FormProvider {...methods}>
        <Form onFinish={handleSubmit(onSubmit)} name="basic" layout="vertical">
          <Options />
          <div className="flex space-x-4">
            <LicencePlateNumber
              name="licenseNumber"
              className="home-search-input home-search-input-small theme"
              isInHomePage
            />
            {optionsWatch === 'tollInvoice' ? (
              <InvoiceNumber isInHomeSearch />
            ) : optionsWatch === 'uniformCitation' ? (
              <DocumentId isInHomeSearch />
            ) : (
              <PostalCode className="mt-6" isInHomePage />
            )}
          </div>

          <div className="mt-5 grid w-full grid-flow-row justify-start sm:text-left md:grid-flow-col md:justify-start md:space-x-4">
            <CustomButton content="Look Up" type="submit" isPrimary={false} className="pb-8 pt-10" />
            <button
              onClick={clearForm}
              type="button"
              className="green-link mt-3 cursor-pointer text-left sm:flex md:mt-0 md:self-center"
            >
              <Trans>Clear</Trans>
            </button>
          </div>
        </Form>
      </FormProvider>
    </div>
  );
};

export default HomeSearch;

import { gql } from '@apollo/client';
import { AccountSubtype, AccountType } from '../../types/onboarding.types';

export const SIGN_UP_ACCOUNT_DETAILS = gql`
  mutation signUpAccountDetails($signUpAccountDetailsInput: SignUpAccountDetailsInput!) {
    signUpAccountDetails(signUpAccountDetailsInput: $signUpAccountDetailsInput) {
      referenceNumber
      accountId
    }
  }
`;

export interface IUserNames {
  firstName: string;
  lastName: string;
  middleName?: string;
}

export interface ISignUpAccountDetailsInput extends IUserNames {
  accountId?: string;
  accountType: AccountType;
  accountSubType?: AccountSubtype;
  fein?: string;
  companyName?: string;
  dbaName?: string;
  secondaryContacts?: ISecondaryContactInput[];
  addressLine1: string;
  addressLine2: string;
  country: string;
  city: string;
  state: string;
  zipcode: string;
  zipcodePlus?: string;
  email: string;
  reTypeEmail: string;
  additionalEmailAddress?: IAdditionalEmailAddressInput;
  cellPhone?: string;
  homePhone?: string;
  workPhone?: string;
  smsOption?: boolean;
  language?: string;
  statementType?: string;
  username: string;
  password: string;
  reTypePassword: string;
  securityQuestion: string;
  securityQuestionAnswer: string;
  digitPin?: string;
}

export interface IAdditionalEmailAddressInput {
  email: string;
  reTypeEmail: string;
}

export interface ISecondaryContactInput extends IUserNames {
  id: number;
}

export interface ISignUpAccountDetailsOutput {
  referenceNumber: string;
  accountId: string;
}

// Format Phone number (123) 456-7890
import { IPaymentMethodGraphQl } from '../graphql/paymentMethods.graphql';
import { t } from '@lingui/macro';
import { IAccountDetails } from '../graphql/accountDetails.graphql';
import { isUndefined } from 'lodash';

export const formatPhoneNumber = (phoneNumber: string | undefined): string => {
  return phoneNumber ? phoneNumber.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : '';
};

export const getProperPhoneNumber = (accountDetails: IAccountDetails | undefined): string => {
  return accountDetails
    ? accountDetails.cellPhone
      ? formatPhoneNumber(accountDetails.cellPhone)
      : accountDetails.homePhone
      ? formatPhoneNumber(accountDetails.homePhone)
      : formatPhoneNumber(accountDetails.workPhone)
    : '-';
};

export const convertPriceToString = (currentBalance: number, hasDollarSign = true): string => {
  const formattedBalance = Math.abs(currentBalance || 0).toFixed(2);

  if (hasDollarSign) {
    return currentBalance >= 0 ? `$${formattedBalance}` : `-$${formattedBalance}`;
  } else {
    return currentBalance >= 0 ? `${formattedBalance}` : `-${formattedBalance}`;
  }
};

export const addPlusToBalanceIfPositive = (balance: string) => {
  return balance[0] === '-' || balance === '0.00' ? balance : `+${balance}`;
};

export const getPaymentMethodName = (
  { isBankAccount, bankAccountNumber, cardNumber, isPrimary }: IPaymentMethodGraphQl,
  showPrimary?: boolean,
): string => {
  const paymentMethodName = isBankAccount
    ? `${t`Account`} (x${bankAccountNumber.slice(-3)}) `
    : `${t`Card Payment`} (x${cardNumber.slice(-4)}) `;
  return `${showPrimary && isPrimary ? `${t`(Primary)`}` : ''} ${paymentMethodName} `;
};

export const formatToPrice = (value: string | undefined): string => {
  if (!value) return '';
  return parseFloat(value).toFixed(2);
};

export const setBalanceColor = (balance: string) => {
  return Number(balance?.replace('$', '')) >= 0 ? 'text-black' : 'text-red-600';
};

export const formatAmount = (value: string | undefined): string => {
  const formattedAmount = Math.abs(Number(value)).toFixed(2);
  if (!value) return '';
  return Number(value) < 0 ? '-$' + formattedAmount : '$' + formattedAmount.toString();
};

export const formatTransponderNumber = (transponderNumber: string) => {
  if (transponderNumber.length) {
    const tempArr = transponderNumber.split('');
    return `#${tempArr
      .map((item, index) => {
        if ((index + 1) % 4 === 0 && index < tempArr.length - 1) {
          item = `${item}-`;
        }
        return item;
      })
      .join('')}`;
  }
};
export const formatTransponderPrice = (subtotal: number) => {
  if (!isUndefined(subtotal)) {
    return `$ ${subtotal.toFixed(2)}`;
  }
  return '--';
};

export const renderPossiblyNullPropertyName = (property: string | null): string => {
  return property !== null ? property : '';
};
export const convertYNOptionToBoolean = (response: string | undefined): boolean => {
  switch (response?.toLowerCase()) {
    case 'y':
      return true;
    case 'n':
      return false;
    default:
      return false;
  }
};

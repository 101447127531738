import { t, Trans } from '@lingui/macro';
import ReloadButton from '../../ReloadButton/ReloadButton';
import CollapsableContainer from '../../CollapsableContainer/CollapsableContainer';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputPropsType } from '../../MyAccount/ContactInformation/Contact';
import CustomAddress from '../../Address/CustomAddress';

export type IMailingAddress = {
  errorLoadingAddress?: boolean;
  isWallet?: boolean;
  isCountyRequired: boolean;
} & InputPropsType;

export const MailingAddressInfo = ({ errorLoadingAddress, isWallet, disabled, isCountyRequired }: IMailingAddress) => {
  const [mailingCollapsed, setMailingCollapsed] = useState(false);

  const {
    watch,
    formState: { errors, isValid, isSubmitted },
    getFieldState,
  } = useFormContext();

  const isFormSectionDirty = useMemo(() => {
    return (
      getFieldState('addressLine1').isDirty ||
      getFieldState('addressLine2').isDirty ||
      getFieldState('city').isDirty ||
      getFieldState('state').isDirty ||
      getFieldState('county').isDirty ||
      getFieldState('postalCode').isDirty ||
      getFieldState('country').isDirty
    );
  }, [
    watch('addressLine1'),
    watch('addressLine2'),
    watch('city'),
    watch('state'),
    watch('county'),
    watch('postalCode'),
    watch('country'),
  ]);
  useEffect(() => {
    if (!isValid && isSubmitted) {
      setMailingCollapsed(false);
    }
  }, [errors, isSubmitted, isValid]);

  return (
    <CollapsableContainer
      title={t`Mailing Address`}
      onChange={(panels: any) => setMailingCollapsed(!panels[0])}
      activeKey={mailingCollapsed ? [] : ['1']}
      {...(isWallet && { unsavedChanges: isFormSectionDirty })}
      responsive
    >
      {errorLoadingAddress ? (
        <div className="page-error-container">
          <div className="page-error-message">
            <Trans>
              Unable to load this form. Please reload the page and try again.
              <br />
              We are sorry for the inconvenience.
            </Trans>
          </div>
          <ReloadButton />
        </div>
      ) : (
        <>
          <CustomAddress countyRequired={isCountyRequired} disabled={disabled} />
        </>
      )}
    </CollapsableContainer>
  );
};

import React, { useEffect, useState } from 'react';
import { useScript } from './useScript';
import { RECAPTCHA_ID, RECAPTCHA_SCRIPT_ID, RECAPTCHA_SITE_KEYS, RECAPTCHA_URL } from '../../constants';
import { getRecaptchaResponse } from '../../services/recaptcha';

declare global {
  interface Window {
    handleSubmit?: () => void;
  }
}

interface IUseRecaptcha<T> {
  onSubmit: (formInputModel: T) => void;
}

export const useRecaptcha = <T,>({ onSubmit }: IUseRecaptcha<T>) => {
  const recaptchaChallengeElem: JSX.Element = <div id={RECAPTCHA_ID}></div>;
  const [loading, setLoading] = useState(false);
  const [formModel, setFormModel] = useState<any>();

  useScript(RECAPTCHA_URL, RECAPTCHA_SCRIPT_ID, () =>
    grecaptcha.ready(() => {
      if (document.getElementById(RECAPTCHA_ID)) {
        grecaptcha.render(document.getElementById(RECAPTCHA_ID) || '', {
          sitekey: RECAPTCHA_SITE_KEYS,
          size: 'invisible',
          callback: () => setSubmitTriggered(true),
        });
      }
    }),
  );

  const [submitTriggered, setSubmitTriggered] = useState(false);

  useEffect(() => {
    submitTriggered && handleSubmit();
    setSubmitTriggered(false);
  }, [submitTriggered]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const {
        data: {
          verifyRecaptcha: { isSuccess },
        },
      } = await getRecaptchaResponse();
      if (isSuccess) {
        onSubmit(formModel);
      }
      grecaptcha.reset();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  useEffect(() => {
    if (!window.handleSubmit) {
      window.handleSubmit = handleSubmit;
    } else {
      delete window.handleSubmit;
      window.handleSubmit = handleSubmit;
    }
  }, [handleSubmit]);

  const verifyBeforeSubmit = async (formInputModel: T) => {
    try {
      await grecaptcha.execute();
      setFormModel(formInputModel);
    } catch (err) {
      grecaptcha.reset();
    }
  };
  return {
    submitWithRecaptcha: verifyBeforeSubmit,
    recaptchaChallengeElem,
    loading,
  };
};

import moment from 'moment';
import _ from 'lodash';

export const GRAPHQL_DATE_FORMAT = 'yyyy-MM-DD';
export const GRAPHQL_TIME_FORMAT = 'HH:mm:ss';
export const US_LOCAL_TIME_FORMAT = 'h:mm a';

export const usLocalToGraphQL = (time: string) => moment(time, US_LOCAL_TIME_FORMAT).format(GRAPHQL_TIME_FORMAT);

export const HOURS = (() => {
  const items: string[] = [];
  new Array(24).fill(24).forEach((acc, index) => {
    items.push(moment({ hour: index }).format(US_LOCAL_TIME_FORMAT));
    items.push(moment({ hour: index, minute: 30 }).format(US_LOCAL_TIME_FORMAT));
  });
  return items;
})();

export const STARTING_YEAR = 1960;
export const YEARS = Array.from(
  { length: moment().toDate().getFullYear() - STARTING_YEAR + 2 },
  (_, i) => i + STARTING_YEAR,
).reverse();

export const getArrayOfYearsFrom = (year: number) => Array.from({ length: 7 }, (_, i) => i + year);

export const formatDate = (dateString: string, usFormat?: boolean) => {
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const date = moment(dateString).toDate();
  if (usFormat) return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  else return `${monthNames[date.getMonth()]} ${date.getDate()} ${date.getFullYear()}`;
};

export const formatDateForNotification = (dateString: string, usFormat?: boolean) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const date = moment(dateString).toDate();
  if (usFormat) return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  else return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
};

export const extractTimeFromISOString = (ISOString: string) => {
  return ISOString.slice(-8);
};

export const formatTime = (time: string, capitalLetters?: boolean) => {
  let hours: string | number = time.split(':')[0];
  const minutes = time.split(':')[1];
  const amPm = capitalLetters ? (Number(hours) >= 12 ? 'PM' : 'AM') : Number(hours) >= 12 ? 'pm' : 'am';

  if (!time || !hours || !minutes) {
    return '';
  }

  if (parseInt(hours) > 12) {
    hours = parseInt(hours) - 12;
    if (hours < 10) {
      hours = `0${hours}`;
    }
  } else if (hours === '00') {
    hours = '12';
  }

  return `${hours}:${minutes} ${amPm}`;
};

export const getTime = (dateTimeString: string) => {
  return dateTimeString.toString().split(' ')[4];
};

export const MONTH_NUMBERS = _.range(1, 13);

import { useQuery } from '@apollo/client';
import { IVehicleMake, VEHICLE_MAKES } from '../../../../graphql/vehicleMakes.graphql';
import { useState } from 'react';

interface IUseVehicleMakes {
  vehicleMakes: IVehicleMake[] | undefined;
  errorLoading: boolean;
  loading: boolean;
}

const useVehicleMakes = (): IUseVehicleMakes => {
  const [isError, setIsError] = useState(false);
  const { data, loading } = useQuery<{ vehicleMakes: IVehicleMake[] }>(VEHICLE_MAKES, {
    errorPolicy: 'all',
    onError: () => setIsError(true),
  });
  return {
    vehicleMakes: data?.vehicleMakes,
    errorLoading: isError,
    loading: loading,
  };
};

export default useVehicleMakes;

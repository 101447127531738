import { useMutation, useQuery } from '@apollo/client';
import { Row } from 'antd';
import * as React from 'react';
import { FC, useContext, useState } from 'react';
import CancelButton from 'src/components/Dashboard/Wallet/PaymentMethods/CancelButton';
import {
  ADD_UPDATE_PAYMENT_METHOD,
  IAddUpdatePaymentMethodInput,
  PAYMENT_METHODS,
} from 'src/graphql/paymentMethods.graphql';
import { useScroll } from 'src/hooks/screen/useScroll';
import { showMessage } from 'src/utils/message.utils';
import { mapAddUpdatePaymentMethod } from '../mappers';
import NewPaymentMethodContent from '../PaymentMethods/NewPaymentMethodContent';
import { editDefaultPaymentMethodValues } from '../NewPaymentMethodsDefaultValues';
import { PaymentMethodFormField, PaymentMethodType } from '../Payments.enums';
import { IPaymentMethodForm } from '../Payments.interfaces';
import { useWalletStore } from 'src/store/dashboard/wallet.store';
import { PAYMENT_METHOD_ACTIVITY } from 'src/components/Dashboard/Wallet/PaymentMethods/PaymentMethodItem';
import { sessionStorageService } from 'src/services/sessionStorage';
import { useOnboardingStore } from 'src/store/onboarding/onboarding.store';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import { useNavigate } from 'react-router-dom';
import { concatRoutes, DashboardParams, MainRoutes } from '../../../types/routes';
import { useLocation } from 'react-router';
import { useDashboardStore } from '../../../store/dashboard/dashboard.store';
import { ADD_FUNDS_SECTION_ID, SELECT_PAYMENT_METHOD_SECTION_ID } from '../../Dashboard/references';
import { NotificationContext } from '../../../context/NotificationContext';
import OptOutOfEasyPayModal from '../../Modals/ConfirmationModal/OptOutOfEasyPayModal';
import { useSortPaymentMethods } from '../../../hooks/domain/payments/useSortPaymentMethods';
import { GET_ACCOUNT_DETAILS } from '../../../graphql/accountDetails.graphql';
import { usePlans } from '../../Dashboard/MyDashboard/usePlans';
import { AccountType } from '../../../types/onboarding.types';

interface INewPaymentMethodWalletWrapper {
  paymentMethodListRef: React.RefObject<HTMLElement>;
  refetch: () => void;
}
const NewPaymentMethodWalletWrapper: FC<INewPaymentMethodWalletWrapper> = ({ paymentMethodListRef, refetch }) => {
  const [showOptOutOfEasyPayModal, setShowOptOutOfEasyPayModal] = useState(false);
  const { methodsWithEasyPay } = useSortPaymentMethods(true);

  const account = useQuery(GET_ACCOUNT_DETAILS)?.data?.accountDetails;
  const accountType = account?.accountType;

  const { GOVAccount } = usePlans();
  const isGOVAccount = !!(accountType === AccountType.COMMERCIAL && GOVAccount);

  const {
    updatePaymentInfo,
    paymentsInfo: { paymentMethodToEdit, isEditMode },
  } = useWalletStore();

  const {
    setAddFundsMode,
    store: { shouldAddPaymentMethodFromMyDashboard, shouldAddPaymentMethodFromMyWallet },
  } = useDashboardStore();

  const {
    onboardingInfo: { cardType },
  } = useOnboardingStore();

  const notificationCtx = useContext(NotificationContext);
  const { loadNotifications } = notificationCtx || {};

  const { scrollToElem } = useScroll();
  const [addUpdatePaymentMethod, { loading: isAddingUpdating }] = useMutation<IAddUpdatePaymentMethodInput>(
    ADD_UPDATE_PAYMENT_METHOD,
    {
      onCompleted: async () => {
        loadNotifications && (await loadNotifications(true));
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: PAYMENT_METHODS,
        },
      ],
    },
  );

  const history = useNavigate();
  const location = useLocation();

  const closePaymentForm = () => {
    scrollToElem(paymentMethodListRef, true);
    updatePaymentInfo({
      isEditMode: false,
      isMethodLimitReached: false,
      paymentMethodToEdit: null,
    });
    setTimeout(() => updatePaymentInfo({ isPaymentMethodFormActive: false }), 800);
  };

  const navigationElement: JSX.Element = (
    <Row className="onboarding-button-container grid grid-cols-3 items-center">
      <CancelButton handleClick={closePaymentForm} />
      <CustomButton type="submit" content="Save" />
    </Row>
  );

  const [formValues, setFormValues] = useState<IPaymentMethodForm>();

  const addOrUpdatePaymentMethod = async (paymentMethod: IPaymentMethodForm | undefined) => {
    const addUpdatePaymentMethodInput =
      paymentMethod &&
      mapAddUpdatePaymentMethod(
        paymentMethod,
        paymentMethodToEdit,
        cardType,
        paymentMethod.replenishmentThresholdAmount,
        paymentMethod.optInEZPay,
        paymentMethod.authorizeReplenishment,
      );
    try {
      await addUpdatePaymentMethod({
        variables: {
          addUpdatePaymentMethodInput,
        },
      });
      if (paymentMethodToEdit?.paymentMethodId) {
        sessionStorageService.setPaymentMethodStatus(
          paymentMethodToEdit?.paymentMethodId,
          PAYMENT_METHOD_ACTIVITY.CHANGES_SAVED,
        );
      } else {
        updatePaymentInfo({ newPaymentMethodAdded: true });
      }
      refetch();
      showMessage('success');
    } catch (err) {
      showMessage('error');
      console.error((err as Error).message);
    }
    closePaymentForm();
    if (
      location.pathname === concatRoutes(MainRoutes.DASHBOARD, '/', DashboardParams.WALLET) &&
      shouldAddPaymentMethodFromMyDashboard
    ) {
      setAddFundsMode(true);
      setTimeout(() => history(concatRoutes(MainRoutes.DASHBOARD, '/', DashboardParams.MY_DASHBOARD)), 800);
    } else if (
      location.pathname === concatRoutes(MainRoutes.DASHBOARD, '/', DashboardParams.WALLET) &&
      shouldAddPaymentMethodFromMyWallet
    ) {
      setAddFundsMode(true);
      scrollToElem(ADD_FUNDS_SECTION_ID, true);
    }
  };

  const onSubmit = async (paymentMethod: IPaymentMethodForm) => {
    setFormValues(paymentMethod);
    const isUserEditingPMAndRemovingEasyPay =
      isEditMode &&
      paymentMethod[PaymentMethodFormField.IS_EASY_PAY] === false &&
      paymentMethodToEdit?.isEasyPay === true &&
      methodsWithEasyPay?.length === 1;
    isUserEditingPMAndRemovingEasyPay
      ? setShowOptOutOfEasyPayModal(true)
      : await addOrUpdatePaymentMethod(paymentMethod);
  };

  const defaultPaymentMethod = editDefaultPaymentMethodValues(paymentMethodToEdit, isEditMode);

  const mapToPaymentMethod = (isBankAccount: boolean | undefined) => {
    return isBankAccount ? PaymentMethodType.BANK : PaymentMethodType.CARD;
  };

  return (
    <div id={SELECT_PAYMENT_METHOD_SECTION_ID}>
      <OptOutOfEasyPayModal
        showModal={showOptOutOfEasyPayModal}
        onOk={() => addOrUpdatePaymentMethod(formValues)}
        onCancel={() => setShowOptOutOfEasyPayModal(false)}
      />
      <NewPaymentMethodContent
        defaultFormValues={defaultPaymentMethod}
        isOnboardingPayment={false}
        isLoading={isAddingUpdating}
        onSubmit={onSubmit}
        navigationElements={navigationElement}
        defaultPaymentMethod={mapToPaymentMethod(paymentMethodToEdit?.isBankAccount)}
        isEditMode={isEditMode}
        shouldBeWrappedInCard={true}
        showCVV={true}
        isAddingPaymentMethod={!isEditMode}
        isEasyPayMethod={paymentMethodToEdit?.isEasyPay === true}
        diasbleEasyPayEnrollment={isGOVAccount}
        shouldShowSaveForFutureCheckBox={true}
      />
    </div>
  );
};
export default NewPaymentMethodWalletWrapper;

import { FC } from 'react';
import TranspondersActivityList from './TranspondersActivityList';
import TranspondersActivityHeader from './TranspondersActivityHeader';
import { useQuery } from '@apollo/client';
import { GET_ACCOUNT_DETAILS, IAccountDetails } from '../../../../graphql/accountDetails.graphql';

const TranspondersActivity: FC = () => {
  const { data } = useQuery<{ accountDetails: IAccountDetails }>(GET_ACCOUNT_DETAILS);

  return (
    <>
      <TranspondersActivityHeader currentBalance={data?.accountDetails.currentBalance} />
      <TranspondersActivityList currentBalance={data?.accountDetails.currentBalance} />
    </>
  );
};

export default TranspondersActivity;

import { IPaymentMethod } from 'src/components/Payments/Payments.interfaces';
import { IPaymentMethodGraphQl } from 'src/graphql/paymentMethods.graphql';
import { mapExistingPaymentMethodToSettleBallance, mapNewPaymenthMethodToSettleBallancePayment } from './mappers';

interface IMapSettleBallanceInput {
  isNewPayment: boolean | null;
  formValues: IPaymentMethod | undefined;
  ballanceToSettle: number;
  cardType: string;
  replenishThreshold: number | undefined;
  securityCode: string;
  paymentMethod: IPaymentMethodGraphQl | undefined;
}
export const getMappedInputToSettleBallance = ({
  isNewPayment,
  formValues,
  ballanceToSettle,
  replenishThreshold,
  securityCode,
  paymentMethod,
  cardType,
}: IMapSettleBallanceInput) => {
  let mappedPaymentMethod;
  if (isNewPayment) {
    if (formValues)
      mappedPaymentMethod = mapNewPaymenthMethodToSettleBallancePayment(
        formValues,
        ballanceToSettle,
        cardType,
        replenishThreshold || 0,
      );
  } else {
    if (paymentMethod)
      mappedPaymentMethod = mapExistingPaymentMethodToSettleBallance(
        paymentMethod,
        ballanceToSettle,
        securityCode,
        replenishThreshold || 0,
      );
  }
  return mappedPaymentMethod;
};

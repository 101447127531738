import { Trans } from '@lingui/macro';
import { FC } from 'react';
import useScreenSize from 'src/hooks/screen/useScreenSize';
import ScreenSize from 'src/types/ScreenSize.enum';

interface IDueTodayProps {
  amount: number;
}

const DueToday: FC<IDueTodayProps> = ({ amount = 0 }) => {
  const { screenSize } = useScreenSize();

  return (
    <div className={`flex w-full justify-between ${screenSize > ScreenSize.XS ? 'items-center' : 'items-start'}`}>
      <h3 className="text-primary-blue1">
        <Trans>Amount Due Today</Trans>
      </h3>
      <div className="mr-8 text-2xl font-bold">${(+amount).toFixed(2)}</div>
    </div>
  );
};

export default DueToday;

import React, { FC } from 'react';
import { Trans, t } from '@lingui/macro';
import { Link } from 'react-router-dom';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, Menu, MenuProps, Space } from 'antd';
import useScreenSize from '../../hooks/screen/useScreenSize';
import ScreenSize from 'src/types/ScreenSize.enum';
import { colors } from 'src/theme/theme.js';
import { MainLoader } from '../Loader/MainLoader';
import { MainRoutes } from '../../types/routes';
import { useLogout } from '../../hooks/auth/useLogout';

const DropDownMenu: FC = () => {
  const { screenSize } = useScreenSize();
  const { logout, loading } = useLogout();

  const handleSelect: MenuProps['onClick'] = async ({ key }) => {
    if (key === 'logout') {
      await logout();
    }
  };

  const menu = (
    <Menu
      getPopupContainer={(trigger) => trigger.parentElement as HTMLElement}
      onClick={(key) => handleSelect(key)}
      items={[
        {
          label: (
            <Link to={MainRoutes.MY_ACCOUNT} aria-label={t`Account Settings`}>
              <Trans>Account Settings</Trans>
            </Link>
          ),
          key: 'account-settings',
        },
        {
          label: (
            <button aria-label={t`Logout`}>
              <Trans>Logout</Trans>
            </button>
          ),
          key: 'logout',
        },
      ]}
    />
  );

  return (
    <>
      <MainLoader loading={loading} />
      <Dropdown overlay={menu} trigger={['click']}>
        <button
          className="ant-dropdown-link"
          onClick={(e) => {
            e.preventDefault();
          }}
          aria-label={t`My Account`}
        >
          <Space className="font-bold text-gray-700">
            <FontAwesomeIcon icon={faUserCircle} color={colors.primary.gray1} size={'2x'} />
            {screenSize > ScreenSize.LG ? <Trans>My Account</Trans> : null}
          </Space>
        </button>
      </Dropdown>
    </>
  );
};

export default DropDownMenu;

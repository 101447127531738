import React from 'react';
import { OnboardingPinellasOptions } from 'src/types/onboarding.types';
import BackButton from '../../BackButton/BackButton';
import SelectableCard from '../../SelectableCard/SelectableCard';
import ScrollToTop from '../../ScrollToTop/ScrollToTop';

import '../../../pages/Onboarding/Onboarding.scss';
import { t, Trans } from '@lingui/macro';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import { useOnboardingQuestion } from './useOnboardingQuestion';
import { useOnboardingStore } from '../../../store/onboarding/onboarding.store';

export default function OnboardingPinellas(): React.ReactElement {
  const {
    onboardingInfo: { pinellasUse },
  } = useOnboardingStore();
  const { onSubmit, onSelect, error, selected } = useOnboardingQuestion<OnboardingPinellasOptions>({
    defaultAnswerSelected: pinellasUse,
  });
  return (
    <div className="h-full">
      <ScrollToTop />
      <h5 className="onboarding-header text-center">
        <Trans>Just one more quick question!</Trans>
      </h5>
      <h4 className="py-5 text-center">
        <Trans>Do you frequently travel on the Pinellas Bayway?</Trans>
      </h4>
      <div className="onboarding-container onboarding-pinellas-question">
        <SelectableCard
          key={OnboardingPinellasOptions.NO_PINELLAS}
          title={t`I rarely travel, or never travel, on the Pinellas Bayway.`}
          onClick={() => onSelect(OnboardingPinellasOptions.NO_PINELLAS)}
          selected={selected === OnboardingPinellasOptions.NO_PINELLAS}
          autoHeight={true}
        ></SelectableCard>
        <SelectableCard
          key={OnboardingPinellasOptions.FREQUENT}
          title={t`I frequently travel on the Pinellas Bayway in addition to my travel throughout the state.`}
          content={t`I frequently travel on the Pinellas Bayway in addition to my regular driving throughout the state.`}
          onClick={() => onSelect(OnboardingPinellasOptions.FREQUENT)}
          selected={selected === OnboardingPinellasOptions.FREQUENT}
          autoHeight={true}
        ></SelectableCard>
      </div>
      <div className={`form-error mt-6 text-center ${error ? 'visible' : 'hidden'}`}>
        <Trans>You must make a selection before continuing.</Trans>
      </div>
      <div className="onboarding-button-container">
        <BackButton></BackButton>
        <CustomButton content="Continue" onClick={onSubmit} />
      </div>
    </div>
  );
}

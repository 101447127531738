import { useQuery } from '@apollo/client';
import React, { FC } from 'react';
import { DEFAULT_SHORT_TRANSPONDERS_FETCH_PARAMS } from 'src/components/Dashboard/Transponders/TransponderList/defaultValues';
import { MainLoader } from 'src/components/Loader/MainLoader';
import {
  GET_TRANSPONDER_LIST,
  ITransponderInput,
  ITransponderList,
  TransponderStatus,
} from 'src/graphql/transponders.graphql';

const TranspondersList: FC = ({}) => {
  const { data: transpondersListData, loading: transponderListLoading } = useQuery<
    { transponderList: ITransponderList },
    { transponderListInput: ITransponderInput }
  >(GET_TRANSPONDER_LIST, {
    notifyOnNetworkStatusChange: true,
    variables: {
      transponderListInput: DEFAULT_SHORT_TRANSPONDERS_FETCH_PARAMS,
    },
  });

  const recentTransponders = transpondersListData?.transponderList.transponders.slice(0, 5) || [];

  return (
    <>
      <MainLoader loading={transponderListLoading} />
      <ul className="md:ml-3">
        {recentTransponders?.map((transponder) => {
          const { transponderType, transponderNumber, friendlyName, status } = transponder;
          return (
            <li
              key={transponderNumber}
              className={
                'mb-3 flex h-20 cursor-pointer flex-col justify-center rounded-lg bg-white px-6 py-4 text-sm shadow-lg sm:flex-row sm:justify-between sm:text-base'
              }
            >
              <div className="flex w-full items-center justify-between">
                <div className="flex flex-col">
                  <div className="font-bold md:font-normal">
                    <span>{transponderType}</span> | <span>{transponderNumber}</span>
                  </div>
                  <div>{friendlyName ? friendlyName : 'Unlabeled Transponder'}</div>
                </div>
                <div>
                  {status === TransponderStatus.INACTIVE ? (
                    <span className="rounded bg-gray-300 px-2 py-1 text-xs sm:text-sm">Deactivated</span>
                  ) : null}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default TranspondersList;

import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import App from './components/App/App';
import reportWebVitals from './reportWebVitals';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { BrowserRouter } from 'react-router-dom';
import { messages as messagesEn } from './locales/en/messages';
import { messages as messagesEs } from './locales/es/messages';
import { Languages } from './types/language.types';
import { detect, fromNavigator, fromStorage, fromUrl } from '@lingui/detect-locale';
import { startsWith } from 'lodash';
import CustomApolloProvider from './security/CustomApolloProvider';
import { en, es } from 'make-plural/plurals';
import { NotificationProvider } from './context/NotificationProvider';

const container = document.getElementById('root');
const root = createRoot(container as Element);

i18n.loadLocaleData('en', { plurals: en });
i18n.loadLocaleData('es', { plurals: es });

i18n.load({
  en: messagesEn,
  es: messagesEs,
});

const DEFAULT_FALLBACK = () => Languages.EN;
const browserLang = detect(fromUrl('lang'), fromStorage('lang'), fromNavigator(), DEFAULT_FALLBACK);

if (browserLang && startsWith(browserLang, Languages.ES)) {
  i18n.activate(Languages.ES);
} else {
  i18n.activate(Languages.EN);
}

root.render(
  <React.StrictMode>
    <I18nProvider i18n={i18n}>
      <CustomApolloProvider>
        <BrowserRouter>
          <NotificationProvider>
            <App />
          </NotificationProvider>
        </BrowserRouter>
      </CustomApolloProvider>
    </I18nProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { FC } from 'react';
import { DatePicker, Form } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import { t, Trans } from '@lingui/macro';
import { MONTH_DAY_YEAR_DATE_FORMAT } from '../../../../constants';
import moment from 'moment';
import { buildErrorString, getInputName } from '../../../Onboarding/Vehicles/OnboardingVehiclesForm/vehicleForm.utils';
import classNames from 'classnames';

const TODAY = moment();
interface IEffectiveStartDate {
  field?: Record<any, string>;
  index?: number;
  name: string;
  shouldDefaultBeEmpty: boolean;
}
const EffectiveStartDate: FC<IEffectiveStartDate> = ({ field, index, name, shouldDefaultBeEmpty }) => {
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="mb-4 w-full sm:w-3/12 sm:pr-2">
      <Form.Item
        {...field}
        help={buildErrorString(name, errors, index)}
        label={<Trans id="Effective Start Date" />}
        htmlFor={name}
        className="theme"
        required
      >
        <Controller
          control={control}
          name={name}
          render={({ field, fieldState }) => (
            <DatePicker
              id={name}
              name={name}
              format={MONTH_DAY_YEAR_DATE_FORMAT}
              onChange={(value) => {
                field.onChange(value);
                trigger([getInputName('effectiveEndTime', index), getInputName('effectiveEndDate', index)]);
              }}
              placeholder={t`Select`}
              allowClear={false}
              value={shouldDefaultBeEmpty ? undefined : field.value}
              defaultValue={shouldDefaultBeEmpty ? undefined : TODAY}
              className={`${classNames({ 'input-error': fieldState?.invalid })} ${'w-full'}`}
            />
          )}
        />
      </Form.Item>
    </div>
  );
};

export default EffectiveStartDate;

import { Trans } from '@lingui/macro';
import React from 'react';
import { IInvoiceDetailOutput, ILookupWithoutInvoiceOutput } from '../../graphql/lookupWithInvoice.graphql';

export interface IInvoiceInfoSectionProps {
  options: string;
  invoiceNumber: string;
  lookupWithoutInvoice?: ILookupWithoutInvoiceOutput;
  invoiceDetails?: IInvoiceDetailOutput;
}

const InvoiceInfoSection = ({
  options,
  lookupWithoutInvoice,
  invoiceNumber,
  invoiceDetails,
}: IInvoiceInfoSectionProps) => {
  return (
    <div className="invoice-form mt-12">
      <div className="invoice-info">
        <h1 className="text-left text-2xl">
          {options === 'tollInvoice' ? (
            <Trans>Toll Enforcement Invoice #{invoiceNumber}</Trans>
          ) : options === 'uniformCitation' ? (
            <Trans>Uniform Traffic Citation #{invoiceNumber}</Trans>
          ) : (
            <Trans>Unpaid Balance Total</Trans>
          )}
        </h1>
        <div className="my-5 md:ml-16">
          <div className="flex">
            <p>
              {options === 'tollInvoice' ? (
                <Trans>Toll Enforcement Invoice #{invoiceNumber}</Trans>
              ) : options === 'uniformCitation' ? (
                <Trans>Uniform Traffic Citation #{invoiceNumber}</Trans>
              ) : (
                <Trans>All owed unpaid balances</Trans>
              )}
            </p>
            <p className="amount">
              $
              {options === 'withoutInvoiceOrCitation'
                ? lookupWithoutInvoice?.amountDue
                : invoiceDetails?.totalAmountDue}
            </p>
          </div>
          <div className="subtotal flex">
            <p className="bold">
              <Trans>Subtotal</Trans>
            </p>
            <p className="amount">
              $
              {options === 'withoutInvoiceOrCitation'
                ? lookupWithoutInvoice?.amountDue
                : invoiceDetails?.totalAmountDue}
            </p>
          </div>
        </div>
        <div className="flex">
          <h1 className="text-left text-2xl">
            <Trans>Amount Due Today</Trans>
          </h1>
          <h1 className="amount text-2xl">
            ${options === 'withoutInvoiceOrCitation' ? lookupWithoutInvoice?.amountDue : invoiceDetails?.totalAmountDue}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default InvoiceInfoSection;

import * as yup from 'yup';
import { array, object } from 'yup';
import { validators } from '../../validators';
import { mailingAddressValidator } from '../../../components/Address/mailingAddressValidator';
import { AccountType } from '../../../types/onboarding.types';
import {
  AT_LEAST_ONE_PHONE_NUMBER_ERROR_MSG,
  COMPANY_NAME_REQUIRED_ERROR_MSG,
  DOING_BUSINESS_AS_REQUIRED_ERROR_MSG,
  EMAIL_ERROR_MSG,
  EMAIL_REQUIRED_ERROR_MSG,
  FEIN_LENGTH_ERROR_MSG,
  FEIN_NUMBER_ERROR_MSG,
  FIRST_NAME_REQUIRED_ERROR_MSG,
  FIRST_QUESTION_ERROR_MSG,
  INVALID_EMAIL_ERROR_MSG,
  LAST_NAME_REQUIRED_ERROR_MSG,
  MATCH_PASSWORD_ERROR_MSG,
  MATCHING_EMAIL_ERROR_MSG,
  ORGANIZATION_NAME_REQUIRED_ERROR_MSG,
  PASSWORD_ERROR_MSG,
  PHONE_NUMBER_ERROR_MSG,
  PURCHASE_ORDER_NUMBER_REQUIRED_ERROR_MSG,
  SECOND_QUESTION_ERROR_MSG,
  SECURITY_ANSWER_ERROR_MSG,
  THIRD_QUESTION_ERROR_MSG,
  USERNAME_ERROR_MSG,
} from './accountInfoErrorMessages';
import { IPlan } from '../../../graphql/plans/plans.graphql';

export const phonesValidator = {
  cellPhone: yup
    .string()
    .nullable()
    .when('smsOption', {
      is: true,
      then: yup.string().test('mobileRequiredForTextAlerts', PHONE_NUMBER_ERROR_MSG, function (cell) {
        return validators.phone.required().isValidSync(cell);
      }),
      otherwise: yup.string().when(['homePhone', 'workPhone'], {
        is: (home: string, work: string) => !home && !work,
        then: validators.phone.required(AT_LEAST_ONE_PHONE_NUMBER_ERROR_MSG),
        otherwise: validators.phone,
      }),
    }),
  homePhone: yup
    .string()
    .nullable()
    .when(['cellPhone', 'workPhone'], {
      is: (cell: string, work: string) => !cell && !work,
      then: validators.phone.required(AT_LEAST_ONE_PHONE_NUMBER_ERROR_MSG),
      otherwise: validators.phone,
    }),
  workPhone: yup
    .string()
    .nullable()
    .when(['cellPhone', 'homePhone'], {
      is: (cell: string, home: string) => !cell && !home,
      then: validators.phone.required(AT_LEAST_ONE_PHONE_NUMBER_ERROR_MSG),
      otherwise: validators.phone,
    }),
};

export const emailsValidator = {
  email: yup
    .string()
    .nullable()
    .required(EMAIL_REQUIRED_ERROR_MSG)
    .test('email', EMAIL_ERROR_MSG, (value) => validators.email.isValid(value)),
  reTypeEmail: validators.emailConfirm('email').nullable().required(EMAIL_ERROR_MSG),
  additionalEmailAddresses: array().of(
    object().shape({
      email: validators.email.required(INVALID_EMAIL_ERROR_MSG),
      reTypeEmail: validators.emailConfirm('email').required(MATCHING_EMAIL_ERROR_MSG),
    }),
  ),
};

export const additionalContactsValidator = (accountType?: AccountType) => {
  return {
    additionalContacts: yup
      .array()
      .test('additionalContactsRequired', ORGANIZATION_NAME_REQUIRED_ERROR_MSG, (additionalContacts) =>
        accountType === AccountType.COMMERCIAL
          ? array()
              .of(
                object().shape({
                  firstName: validators.name.required(FIRST_NAME_REQUIRED_ERROR_MSG).nullable(),
                  middleName: validators.singleChar.nullable(),
                  lastName: validators.name.required(LAST_NAME_REQUIRED_ERROR_MSG).nullable(),
                }),
              )
              .isValidSync(additionalContacts)
          : array()
              .of(
                object().shape({
                  firstName: validators.name.nullable(),
                  middleName: validators.singleChar.nullable(),
                  lastName: validators.name.nullable(),
                }),
              )
              .isValidSync(additionalContacts),
      ),
  };
};

export const fleetGovernmentAdditionalInfoValidator = (accountType?: AccountType, GOVAccount?: IPlan) => {
  return {
    companyName: yup
      .string()
      .nullable()
      .test('companyNameRequired', COMPANY_NAME_REQUIRED_ERROR_MSG, (companyName) =>
        accountType === AccountType.COMMERCIAL
          ? GOVAccount
            ? true
            : yup.string().required().isValidSync(companyName)
          : true,
      ),

    organizationName: yup
      .string()
      .nullable()
      .test('organizationNameRequired', ORGANIZATION_NAME_REQUIRED_ERROR_MSG, (organizationName) =>
        (accountType === AccountType.COMMERCIAL && GOVAccount) || accountType === AccountType.GOVERNMENT
          ? yup
              .string()
              .matches(/^\s*\S.*$/, ORGANIZATION_NAME_REQUIRED_ERROR_MSG)
              .required()
              .isValidSync(organizationName)
          : true,
      ),
    doingBusinessAs: yup
      .string()
      .test('doingBusinessAsRequired', DOING_BUSINESS_AS_REQUIRED_ERROR_MSG, (doingBusinessAss) =>
        accountType === AccountType.COMMERCIAL || accountType === AccountType.GOVERNMENT
          ? yup
              .string()
              .matches(/^\s*\S.*$/, DOING_BUSINESS_AS_REQUIRED_ERROR_MSG)
              .required()
              .isValidSync(doingBusinessAss)
          : true,
      ),
    purchaseOrderNumber: yup
      .string()
      .test('purchaseOrderNumberRequired', PURCHASE_ORDER_NUMBER_REQUIRED_ERROR_MSG, (purchaseOrderNumber) =>
        (accountType === AccountType.COMMERCIAL && GOVAccount) || accountType === AccountType.GOVERNMENT
          ? yup
              .string()
              .matches(/^\s*\S.*$/, PURCHASE_ORDER_NUMBER_REQUIRED_ERROR_MSG)
              .required()
              .isValidSync(purchaseOrderNumber)
          : true,
      ),
    fein: yup
      .string()
      .nullable()
      .test('feinRequired', FEIN_NUMBER_ERROR_MSG, (fein) =>
        accountType === AccountType.COMMERCIAL ? (GOVAccount ? true : yup.string().required().isValidSync(fein)) : true,
      )
      .test('feinValid', FEIN_LENGTH_ERROR_MSG, (fein) =>
        accountType === AccountType.COMMERCIAL ? (GOVAccount ? true : validators.fein.isValidSync(fein)) : true,
      ),
  };
};

export const securityQuestionsValidator = (accountType?: AccountType) => {
  return {
    firstSecurityQuestion: yup
      .string()
      .nullable()
      .test('securityQuestionRequired', FIRST_QUESTION_ERROR_MSG, (securityQuestion) =>
        accountType !== AccountType.GOVERNMENT ? yup.string().required().isValidSync(securityQuestion) : true,
      ),

    firstSecurityQuestionAnswer: yup
      .string()
      .nullable()
      .test('securityQuestionAnswerRequired', SECURITY_ANSWER_ERROR_MSG, (securityQuestionAnswer) =>
        accountType !== AccountType.GOVERNMENT ? yup.string().required().isValidSync(securityQuestionAnswer) : true,
      ),
    secondSecurityQuestion: yup
      .string()
      .nullable()
      .test('securityQuestionRequired', SECOND_QUESTION_ERROR_MSG, (securityQuestion) =>
        accountType !== AccountType.GOVERNMENT ? yup.string().required().isValidSync(securityQuestion) : true,
      ),

    secondSecurityQuestionAnswer: yup
      .string()
      .nullable()
      .test('securityQuestionAnswerRequired', SECURITY_ANSWER_ERROR_MSG, (securityQuestionAnswer) =>
        accountType !== AccountType.GOVERNMENT ? yup.string().required().isValidSync(securityQuestionAnswer) : true,
      ),
    thirdSecurityQuestion: yup
      .string()
      .nullable()
      .test('securityQuestionRequired', THIRD_QUESTION_ERROR_MSG, (securityQuestion) =>
        accountType !== AccountType.GOVERNMENT ? yup.string().required().isValidSync(securityQuestion) : true,
      ),

    thirdSecurityQuestionAnswer: yup
      .string()
      .nullable()
      .test('securityQuestionAnswerRequired', SECURITY_ANSWER_ERROR_MSG, (securityQuestionAnswer) =>
        accountType !== AccountType.GOVERNMENT ? yup.string().required().isValidSync(securityQuestionAnswer) : true,
      ),
  };
};

export const usernamePasswordsValidator = (accountType?: AccountType, isPasswordResetMode?: boolean) => {
  if (isPasswordResetMode) {
    return {
      username: yup.string().test('usernameRequired', USERNAME_ERROR_MSG, (username) => {
        if (accountType !== AccountType.GOVERNMENT) {
          return validators.username().isValidSync(username);
        }
        return true;
      }),

      oldPassword: yup.string().test('passwordRequired', PASSWORD_ERROR_MSG, (password) => {
        if (accountType !== AccountType.GOVERNMENT) {
          return validators.password.isValidSync(password);
        }
        return true;
      }),

      password: yup.string().test('passwordRequired', PASSWORD_ERROR_MSG, (password) => {
        if (accountType !== AccountType.GOVERNMENT) {
          return validators.password.isValidSync(password);
        }
        return true;
      }),

      reTypePassword: yup.string().oneOf([yup.ref('password')], MATCH_PASSWORD_ERROR_MSG),
    };
  } else {
    return {
      username: yup.string().test('usernameRequired', USERNAME_ERROR_MSG, (username) => {
        if (accountType !== AccountType.GOVERNMENT) {
          return validators.username().isValidSync(username);
        }
        return true;
      }),
      oldPassword: yup.string(),
      password: yup.string(),
      reTypePassword: yup.string(),
    };
  }
};

export const getAccountInfoSchema = (accountType?: AccountType) => {
  return yup.object().shape(
    {
      firstName: validators.name.required(FIRST_NAME_REQUIRED_ERROR_MSG).nullable(),
      middleName: validators.singleChar,
      lastName: validators.name.required(LAST_NAME_REQUIRED_ERROR_MSG),
      language: yup.string().required(),
      ...emailsValidator,
      ...fleetGovernmentAdditionalInfoValidator(accountType),
      ...phonesValidator,
      smsOption: yup.boolean().nullable().notRequired(),
      ...mailingAddressValidator(true),
      ...additionalContactsValidator,
      ...usernamePasswordsValidator(accountType, true),
      ...securityQuestionsValidator(accountType),
    },
    [
      ['cellPhone', 'workPhone'],
      ['cellPhone', 'homePhone'],
      ['workPhone', 'homePhone'],
    ],
  );
};

import { IPaymentMethod } from '../Payments.interfaces';
import { EasyPayOption } from '../../../graphql/payment.graphql';
import { IInvoiceDetailOutput } from '../../../graphql/lookupWithInvoice.graphql';
import creditCardType from 'credit-card-type';
import { mapCreditCardTypeNameToApiType } from '../../../utils/creditCard.util';
import { CreditCardTypeCardBrandId } from 'credit-card-type/dist/types';
import { IOnboardingInfo } from '../../../types/onboarding.types';
import { IPlansTransponderActivationSignUp } from '../../../graphql/signUp/signUpTransponderActivation.graphql';
import moment from 'moment';
import { GRAPHQL_DATE_FORMAT } from '../../../utils/time.utils';

export const mapSignUpTransponderActivationInput = (
  onboardingInfo: IOnboardingInfo,
  plansSignUpInput: IPlansTransponderActivationSignUp,
) => {
  return {
    plansSignUp: plansSignUpInput,
    accountDetailsSignUp: {
      firstName: onboardingInfo.accountInfo?.firstName || '',
      lastName: onboardingInfo.accountInfo?.lastName || '',
      fein: onboardingInfo.accountInfo?.fein || '',
      companyName: onboardingInfo.accountInfo?.companyName || '',
      dbaName: onboardingInfo.accountInfo?.doingBusinessAs || '',
      secondaryContacts: onboardingInfo.accountInfo?.additionalContacts || [],
      addressLine1: onboardingInfo.accountInfo?.addressLine1 || '',
      addressLine2: onboardingInfo.accountInfo?.addressLine2 || '',
      country: onboardingInfo.accountInfo?.country || '',
      city: onboardingInfo.accountInfo?.city || '',
      state: onboardingInfo.accountInfo?.state || '',
      zipcode: onboardingInfo.accountInfo?.postalCode || '',
      zipcodePlus: '',
      email: onboardingInfo.accountInfo?.email || '',
      reTypeEmail: onboardingInfo.accountInfo?.reTypeEmail || '',
      additionalEmailAddress: {
        email: '',
        reTypeEmail: '',
      },
      cellPhone: onboardingInfo.accountInfo?.cellPhone || '',
      homePhone: onboardingInfo.accountInfo?.homePhone || '',
      workPhone: onboardingInfo.accountInfo?.workPhone || '',
      smsOption: onboardingInfo.accountInfo?.smsOption || false,
      language: onboardingInfo.accountInfo?.language || '',
      statementType: 'EMAIL|MONTHLY|Detail', //TODO FLO-3081
      username: onboardingInfo.accountInfo?.username || '',
      password: onboardingInfo.accountInfo?.password || '',
      reTypePassword: onboardingInfo.accountInfo?.reTypePassword || '',
      securityQuestion: onboardingInfo.accountInfo?.firstSecurityQuestion || '',
      securityQuestionAnswer: onboardingInfo.accountInfo?.firstSecurityQuestionAnswer || '',
      digitPin: '',
    },
    transponderNumbers: onboardingInfo.transpondersToActivate
      ? onboardingInfo.transpondersToActivate.map((transponder) => transponder.number)
      : [],
    vehicles: onboardingInfo.vehicles?.map((vehicle) => {
      return {
        ...vehicle,
        effectiveEndTime: moment(vehicle.effectiveEndTime).format('hh:mm'),
        effectiveStartTime: moment(vehicle.effectiveStartTime).format('hh:mm'),
        effectiveStartDate: moment(vehicle.effectiveStartDate).format(GRAPHQL_DATE_FORMAT),
        effectiveEndDate: moment(vehicle.effectiveEndDate).format(GRAPHQL_DATE_FORMAT),
      };
    }),
  };
};

export const mapInvoicePaymentInput = (
  paymentMethod?: IPaymentMethod,
  invoiceDetails?: IInvoiceDetailOutput,
  creditCardTypes?: string[],
) => {
  return {
    addressLine1: paymentMethod?.addressLine1,
    addressLine2: paymentMethod?.addressLine2 || '',
    bankAccountNumber: paymentMethod?.bankAccountNumber || '',
    bankRoutingNumber: paymentMethod?.bankRoutingNumber || '',
    cardNumber: paymentMethod?.ccNumber || '',
    city: paymentMethod?.city,
    country: paymentMethod?.country,
    easyPayOption: EasyPayOption.NONE,
    cellPhone: '',
    email: '',
    expirationMonth: paymentMethod?.ccExpirationMonth as number,
    expirationYear: paymentMethod?.ccExpirationYear as number,
    firstName: paymentMethod?.ccFirstName || paymentMethod?.bankFirstName || '',
    invoiceNumber: invoiceDetails?.invoiceNumber || '',
    lastName: paymentMethod?.ccLastName || paymentMethod?.bankLastName || '',
    licensePlate: invoiceDetails?.licensePlate || '',
    licenseState: invoiceDetails?.state || '',
    middleName: paymentMethod?.ccMiddleName || paymentMethod?.bankLastName || '',
    payType:
      paymentMethod?.paymentType === 'bank'
        ? paymentMethod.bankAccountType
        : creditCardType(paymentMethod?.ccNumber || '')
            .map(({ type }) => mapCreditCardTypeNameToApiType[type])
            .filter((type) => creditCardTypes?.includes(type as CreditCardTypeCardBrandId))[0] || '',
    paymentAmount: invoiceDetails?.totalAmountDue.toString() || '',
    paymentMethodId: '',
    securityCode: paymentMethod?.ccSecurityCode || '',
    state: paymentMethod?.state || '',
    zipcode: paymentMethod?.postalCode || '',
    zipcodePlus: '',
  };
};

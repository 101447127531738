import ConfirmationModal from '../Modals/ConfirmationModal/ConfirmationModal';
import React from 'react';
import { useOnboardingStore } from '../../store/onboarding/onboarding.store';
import { t, Trans } from '@lingui/macro';
import { OnboardingFlowType } from '../../types/onboarding.types';

export const AccountCreatedSuccessfullyModal = () => {
  const {
    onboardingInfo: { shouldDisplayAccountCreatedMsg, referenceNumber, onboardingFlowType },
    updateOnboardingStore,
  } = useOnboardingStore();

  return (
    <ConfirmationModal
      title={t`Your account creation is in progress!`}
      content={
        <>
          <p>
            <Trans>
              Your unique reference number is: <strong>{referenceNumber}</strong>
            </Trans>
          </p>
          <p className="mb-0">
            <Trans>
              Going forward, if you leave the account creation process or if you get disrupted for any other reason, you
              can come back and use this reference number (along with your postal code and last name) to resume account
              creation.
            </Trans>
          </p>
        </>
      }
      showModal={shouldDisplayAccountCreatedMsg && onboardingFlowType === OnboardingFlowType.PURCHASE}
      onOk={() => updateOnboardingStore({ shouldDisplayAccountCreatedMsg: false })}
      alignButtons="center"
      alignText="center"
    />
  );
};

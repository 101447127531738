import * as yup from 'yup';
import { i18n } from '@lingui/core';
import { isUndefined } from 'lodash';
import moment from 'moment';
import { AnyObject } from 'yup/lib/types';
import { CreditCardTypeCardBrandId } from 'credit-card-type/dist/types';
import { mailingAddressValidator } from 'src/components/Address/mailingAddressValidator';
import {
  ACCOUNT_NUMBER_INVALID_MSG,
  CREDIT_CARD_NUMBER_INVALID_MSG,
  CREDIT_CARD_NUMBER_NOT_SUPPORTED_MSG,
  EXPIRATION_MONTH_INVALID_MSG,
  EXPIRATION_MONTH_REQUIRED_MSG,
  EXPIRATION_YEAR_INVALID_MSG,
  FIRST_NAME_REQUIRED,
  LAST_NAME_REQUIRED,
  ACCOUNT_NUMBER_MATCH_ERROR_MSG,
  PAYMENT_TYPE_REQUIRED_ERROR_MSG,
  ROUTING_NUMBER_INVALID_MSG,
  SECURITY_CODE_INVALID_MSG,
  EXPIRATION_YEAR_REQUIRED_MSG,
  ACCOUNT_TYPE_REQUIRED_ERROR_MSG,
  EASY_PAY_REQUIRED_ERROR_MSG,
} from './paymentErrorMessages';
import creditCardType from 'credit-card-type';
import { CreditCardType, mapCreditCardTypeNameToApiType } from '../../../utils/creditCard.util';
import PaymentMethodFormField, { PaymentMethodType } from '../Payments.enums';
import { validators } from 'src/validators/validators';

export const bankAccountSchema = (bankAccountOnly = false) => {
  return {
    [PaymentMethodFormField.BANK_ACCOUNT_TYPE]: yup.string().when(PaymentMethodFormField.PAYMENT_TYPE, {
      is: (paymentType: PaymentMethodType) => paymentType === PaymentMethodType.BANK || bankAccountOnly,
      then: validators.name.required(ACCOUNT_TYPE_REQUIRED_ERROR_MSG),
    }),

    [PaymentMethodFormField.BANK_FIRST_NAME]: yup.string().when(PaymentMethodFormField.PAYMENT_TYPE, {
      is: (paymentType: PaymentMethodType) => paymentType === PaymentMethodType.BANK || bankAccountOnly,
      then: validators.name.nullable().required(FIRST_NAME_REQUIRED),
    }),

    [PaymentMethodFormField.BANK_LAST_NAME]: yup.string().when(PaymentMethodFormField.PAYMENT_TYPE, {
      is: (paymentType: PaymentMethodType) => paymentType === PaymentMethodType.BANK || bankAccountOnly,
      then: validators.name.nullable().required(i18n._(LAST_NAME_REQUIRED)),
    }),

    [PaymentMethodFormField.BANK_ACCOUNT_NUMBER]: yup.string().when(PaymentMethodFormField.PAYMENT_TYPE, {
      is: (paymentType: PaymentMethodType) => paymentType === PaymentMethodType.BANK || bankAccountOnly,
      then: yup
        .string()
        .transform((value) => value?.replace(/_/g, ''))
        .min(5, ACCOUNT_NUMBER_INVALID_MSG)
        .max(17, ACCOUNT_NUMBER_INVALID_MSG)
        .matches(/^[0-9]*$/, ACCOUNT_NUMBER_INVALID_MSG)
        .required(ACCOUNT_NUMBER_INVALID_MSG),
      otherwise: yup.string().nullable(),
    }),

    [PaymentMethodFormField.BANK_ACCOUNT_NUMBER_CONFIRM]: yup.string().when(PaymentMethodFormField.PAYMENT_TYPE, {
      is: (paymentType: PaymentMethodType) => paymentType === PaymentMethodType.BANK || bankAccountOnly,
      then: validators
        .confirm(PaymentMethodFormField.BANK_ACCOUNT_NUMBER, ACCOUNT_NUMBER_MATCH_ERROR_MSG)
        .transform((confirmAccountNumber: string) =>
          confirmAccountNumber ? confirmAccountNumber.replace(/_|\s/g, '') : confirmAccountNumber,
        )
        .min(5, ACCOUNT_NUMBER_INVALID_MSG)
        .max(17, ACCOUNT_NUMBER_INVALID_MSG)
        .matches(/^[0-9]*$/, ACCOUNT_NUMBER_INVALID_MSG)
        .required(ACCOUNT_NUMBER_INVALID_MSG),
      otherwise: yup.string().nullable(),
    }),

    [PaymentMethodFormField.BANK_ROUTING_NUMBER]: yup.string().when(PaymentMethodFormField.PAYMENT_TYPE, {
      is: (paymentType: PaymentMethodType) => paymentType === PaymentMethodType.BANK || bankAccountOnly,
      then: yup
        .string()
        .min(9, ROUTING_NUMBER_INVALID_MSG)
        .max(9, ROUTING_NUMBER_INVALID_MSG)
        .matches(/^[0-9]*$/, ROUTING_NUMBER_INVALID_MSG)
        .required(ROUTING_NUMBER_INVALID_MSG),
      otherwise: yup.string().nullable(),
    }),
  };
};

export const creditCardSchema = (allowedCreditCards: string[], creditCardOnly?: boolean, showCVV?: boolean) => {
  return {
    [PaymentMethodFormField.CC_FIRST_NAME]: validators.name.when(PaymentMethodFormField.PAYMENT_TYPE, {
      is: (paymentType: PaymentMethodType) => paymentType === PaymentMethodType.CARD || creditCardOnly,
      then: yup.string().trim().nullable().required(FIRST_NAME_REQUIRED),
    }),

    [PaymentMethodFormField.CC_MIDDLE_NAME]: validators.singleChar.nullable(),

    [PaymentMethodFormField.CC_LAST_NAME]: validators.name.when(PaymentMethodFormField.PAYMENT_TYPE, {
      is: (paymentType: PaymentMethodType) => paymentType === PaymentMethodType.CARD || creditCardOnly,
      then: yup.string().trim().nullable().required(LAST_NAME_REQUIRED),
    }),

    [PaymentMethodFormField.CC_NUMBER]: yup.string().when(PaymentMethodFormField.PAYMENT_TYPE, {
      is: (paymentType: PaymentMethodType) => paymentType === PaymentMethodType.CARD || creditCardOnly,
      then: yup
        .string()
        .required(CREDIT_CARD_NUMBER_INVALID_MSG)
        .transform((currentValue: string) => (currentValue !== null ? currentValue.replace(/ /g, '') : currentValue))
        .trim()
        .matches(/^[0-9]*$/, CREDIT_CARD_NUMBER_INVALID_MSG)
        .test('Credit type validation', CREDIT_CARD_NUMBER_NOT_SUPPORTED_MSG, (cardNumber) =>
          validateCreditCardNumberByType(allowedCreditCards, cardNumber),
        ),
      otherwise: yup.string().nullable(),
    }),

    [PaymentMethodFormField.CC_EXPIRATION_MONTH]: yup.number().when(PaymentMethodFormField.PAYMENT_TYPE, {
      is: (paymentType: PaymentMethodType) => paymentType === PaymentMethodType.CARD || creditCardOnly,
      then: yup
        .number()
        .required(EXPIRATION_MONTH_REQUIRED_MSG)
        .test('expirationDate', EXPIRATION_MONTH_INVALID_MSG, (value: number | undefined, context: AnyObject) => {
          if (!isUndefined(value) && !isUndefined(context.parent.ccExpirationYear)) {
            const endOfCurrentMonth = moment().endOf('month');
            const endOfExpMonth = moment([context.parent.ccExpirationYear, value - 1]).endOf('month');
            return moment(endOfCurrentMonth).isSameOrBefore(endOfExpMonth);
          }
          return true;
        }),
      otherwise: yup.number().nullable(),
    }),

    [PaymentMethodFormField.CC_EXPIRATION_YEAR]: yup.number().when(PaymentMethodFormField.PAYMENT_TYPE, {
      is: (paymentType: PaymentMethodType) => paymentType === PaymentMethodType.CARD || creditCardOnly,
      then: yup
        .number()
        .test('expirationYear', EXPIRATION_YEAR_INVALID_MSG, (value: number | undefined) => {
          if (!isUndefined(value)) {
            return moment(value).isSameOrAfter(moment().year());
          }
          return true;
        })
        .required(EXPIRATION_YEAR_REQUIRED_MSG),
      otherwise: yup.number().nullable(),
    }),

    [PaymentMethodFormField.CC_SECURITY_CODE]: yup.string().when(PaymentMethodFormField.PAYMENT_TYPE, {
      is: (paymentType: PaymentMethodType) => (paymentType === PaymentMethodType.CARD || creditCardOnly) && showCVV,
      then: yup
        .string()
        .trim()
        .required(SECURITY_CODE_INVALID_MSG)
        .transform((code: string) => (code ? code.replace(/_|\s/g, '') : code))
        .min(3, SECURITY_CODE_INVALID_MSG)
        .max(4, SECURITY_CODE_INVALID_MSG),
      otherwise: yup.string().nullable(),
    }),
  };
};
export const paymentSchema = (allowedCreditCards: string[], showCVV: boolean) => {
  return yup.object().shape({
    [PaymentMethodFormField.PAYMENT_TYPE]: yup.string().required(PAYMENT_TYPE_REQUIRED_ERROR_MSG),
    [PaymentMethodFormField.IS_EASY_PAY]: yup.boolean().required(EASY_PAY_REQUIRED_ERROR_MSG),
    ...creditCardSchema(allowedCreditCards, false, showCVV),
    ...bankAccountSchema(),
    ...mailingAddressValidator(false),
  });
};

export const validateCreditCardNumberByType = (
  allowedCreditCards: string[],
  cardNumber: string | undefined,
): boolean => {
  const lenWithoutWhitespaces = cardNumber?.replace(/ /g, '')?.length;
  const creditCardTypeMatched = creditCardType(cardNumber || '')
    .map(({ type }) => mapCreditCardTypeNameToApiType[type])
    .filter((type) => allowedCreditCards.includes(type as CreditCardTypeCardBrandId))[0];
  return isCreditCardTypeMatchLength(creditCardTypeMatched, lenWithoutWhitespaces);
};

const isCreditCardTypeMatchLength = (creditCard: CreditCardType, lenWithoutWhitespaces: number | undefined) => {
  if (creditCard?.toString() === '' || !lenWithoutWhitespaces) return false;
  if (creditCard === 'AMEX') {
    return lenWithoutWhitespaces === 15;
  } else if (creditCard === 'MASTERCARD') {
    return lenWithoutWhitespaces === 16;
  } else if (creditCard === 'VISA') {
    return lenWithoutWhitespaces === 13 || lenWithoutWhitespaces === 16 || lenWithoutWhitespaces === 19;
  } else if (creditCard === 'DISCOVER') {
    return lenWithoutWhitespaces >= 16 && lenWithoutWhitespaces <= 19;
  } else {
    return false;
  }
};

import { gql } from '@apollo/client';

export const CLOSE_ACCOUNT = gql`
  mutation closeAccount($reason: String!) {
    closeAccount(reason: $reason)
  }
`;

export const GET_CLOSE_ACCOUNT_REASONS = gql`
  query closeAccountReasons {
    closeAccountReasons
  }
`;

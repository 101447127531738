import { create } from '../createStore';
import { devtools } from 'zustand/middleware';

export type ErrorType = 'bulkUpload' | 'unknown' | 'internal-server' | 'authError' | 'addressValidation';

export interface IErrorState {
  message: string;
  code: number;
  type: ErrorType;
}

interface IErrorStore {
  error?: IErrorState;
  isError: boolean;
  setError: (error: IErrorState) => void;
  resetError: () => void;
}

export const useErrorStore = create<IErrorStore, [['zustand/devtools', IErrorStore]]>(
  devtools((set) => ({
    isError: false,
    setError: (error) => {
      set((state) => ({
        error: {
          ...state.error,
          ...error,
        },
        isError: true,
      }));
    },
    resetError: () => {
      set((state) => ({
        ...state,
        error: undefined,
        isError: false,
      }));
    },
  })),
);

import React, { useEffect, useState } from 'react';
import { Trans } from '@lingui/macro';
import '../MyAccount.scss';
import useScreenSize from 'src/hooks/screen/useScreenSize';
import ScrollToTop from '../../ScrollToTop/ScrollToTop';
import BackLink from '../BackLink';
import ScreenSize from 'src/types/ScreenSize.enum';
import {
  GET_TRANSPONDER_PARKING_LIST,
  ITransponderListResponse,
  ITransponderWithParking,
  SunpassPlusParking,
} from 'src/graphql/tranponderParking/transponderPlusParkingList.graphql';
import { useQuery } from '@apollo/client';
import { ITransponderInput } from 'src/graphql/transponders.graphql';
import SunpassParkingInfo from './SunpassParkingInfo';
import { Radio } from 'antd';
import { MainLoader } from 'src/components/Loader/MainLoader';
import TransponderListWithParking from './TransponderListWithParking';
import OptInModal from './OptInModal';
import OptOutModal from './OptOutModal';
import { IPaymentMethodGraphQl, PAYMENT_METHODS } from 'src/graphql/paymentMethods.graphql';
import { useNavigate } from 'react-router-dom';
import { DashboardParams, MainRoutes } from 'src/types/routes';
import NoTranspondersModal from './NoTranspondersModal';
import { SortDirectionType } from 'src/graphql/filter.graphql';
import { useAccountSettings } from 'src/store/account/accountSettings.store';

export default function SunpassParking(): React.ReactElement {
  const [showNoTranpondersModal, setShowNoTranpondersModal] = useState(false);
  const [wishToOptIn, setWishToOptIn] = useState(false);
  const [showOptOutModal, setShowOptOutModal] = useState(false);
  const [showOptInModal, setShoowOptInModal] = useState(false);

  const { screenSize } = useScreenSize();
  const navigate = useNavigate();
  const { data: transpondersListData, loading: transponderListLoading } = useQuery<
    { transponderPlusParkingList: ITransponderListResponse },
    { transponderPlusParkingListInput: ITransponderInput }
  >(GET_TRANSPONDER_PARKING_LIST, {
    notifyOnNetworkStatusChange: true,
    variables: {
      transponderPlusParkingListInput: {
        pagination: {
          pageSize: 7,
          pageNumber: 1,
        },
        filter: { sortColumn: 'sunpassPlusParking', sortDirection: SortDirectionType.ASC },
      },
    },
  });
  const { data: paymentMethodsData, loading: isPaymentMethodsFetcherLoading } = useQuery<{
    paymentMethodList: IPaymentMethodGraphQl[];
  }>(PAYMENT_METHODS, {
    initialFetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
  });
  const { setIsTabDirty } = useAccountSettings();

  const onWalletTabConfirmation = () => navigate(`${MainRoutes.DASHBOARD}/${DashboardParams.WALLET}`);
  const onTranponderTabConfirmation = () => navigate(`${MainRoutes.DASHBOARD}/${DashboardParams.TRANSPONDERS}`);

  const hasTranponderWithParkingPlan = (transpondersList: [ITransponderWithParking]) => {
    return transpondersList.some((transponder) => transponder.sunpassPlusParking === SunpassPlusParking.OPT_IN);
  };
  const optOutOfparking = () => {
    if (
      transpondersListData?.transponderPlusParkingList?.transpondersPlusParking &&
      hasTranponderWithParkingPlan(transpondersListData?.transponderPlusParkingList?.transpondersPlusParking)
    ) {
      setShowOptOutModal(true);
    } else {
      setWishToOptIn(false);
    }
  };
  const optInForParking = () => {
    if (
      paymentMethodsData &&
      paymentMethodsData?.paymentMethodList.length > 0 &&
      paymentMethodsData?.paymentMethodList.some(
        (paymenthMethod: IPaymentMethodGraphQl) => paymenthMethod.isEasyPay === true,
      )
    ) {
      if (transpondersListData?.transponderPlusParkingList?.transpondersPlusParking) {
        setShowNoTranpondersModal(true);
      } else {
        setWishToOptIn(true);
        setIsTabDirty(true);
      }
    } else {
      setShoowOptInModal(true);
    }
  };
  useEffect(() => {
    if (transpondersListData?.transponderPlusParkingList?.transpondersPlusParking) {
      setWishToOptIn(
        hasTranponderWithParkingPlan(transpondersListData?.transponderPlusParkingList?.transpondersPlusParking),
      );
    }
  }, [transpondersListData]);
  return (
    <div>
      <MainLoader loading={transponderListLoading || isPaymentMethodsFetcherLoading} />
      <div className="parking-card w-full pt-5 large-tablet:pt-0">
        <ScrollToTop />
        {screenSize < ScreenSize.XL ? (
          <div className="mb-5 ml-5">
            <BackLink />
          </div>
        ) : null}
        <div className=" card flex w-full flex-col">
          <div className="flex w-full flex-col justify-between sm:flex-row">
            <p className="my-0 mr-8 mt-1 text-left font-bold">
              <Trans>Would you like to add the SunPass Plus Parking Plan onto your account?</Trans>
            </p>
            <Radio.Group className="radio-btn-group mb-10" value={wishToOptIn}>
              <Radio value={true} onChange={optInForParking}>
                <Trans>Yes</Trans>
              </Radio>
              <Radio value={false} onChange={optOutOfparking}>
                <Trans>No</Trans>
              </Radio>
            </Radio.Group>
          </div>
          {wishToOptIn ? (
            <div className="mt-4">
              <TransponderListWithParking />
            </div>
          ) : null}
        </div>
      </div>
      <SunpassParkingInfo />
      <NoTranspondersModal
        showModal={showNoTranpondersModal}
        onBack={() => setShowNoTranpondersModal(false)}
        onOk={onTranponderTabConfirmation}
      />
      <OptOutModal showModal={showOptOutModal} onOk={() => setShowOptOutModal(false)} />
      <OptInModal
        showModal={showOptInModal}
        onOk={onWalletTabConfirmation}
        onCancel={() => setShoowOptInModal(false)}
      />
    </div>
  );
}

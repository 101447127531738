import { Form, Input } from 'antd';
import { Trans } from '@lingui/macro';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IAnswerForConfirmationForm } from './ForgotUsernameOrPassword';

export const Response = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IAnswerForConfirmationForm>();
  return (
    <div className="w-full sm:w-1/2">
      <Form.Item
        label={<Trans>Response</Trans>}
        className={`theme forgot-username-or-password-input`}
        help={errors.answer?.message as React.ReactNode}
        hasFeedback
        validateStatus={errors.answer?.message ? 'error' : ''}
      >
        <Controller
          name="answer"
          control={control}
          render={({ field, fieldState }) => (
            <Input {...field} {...fieldState} id="response" key="response" maxLength={40} />
          )}
        />
      </Form.Item>
    </div>
  );
};

import { t } from '@lingui/macro';
import { Form, Select } from 'antd';
import React, { FC, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { IAddFunds } from 'src/components/Dashboard/MyDashboard/AddFunds/AddFunds';
import { IEasyPayWallet } from 'src/components/Dashboard/Wallet/EasyPay/EasyPay';
import { ADD_NEW_PAYMENT_OPTION, ONE_TIME_PURCHASE } from 'src/constants';
import { IPaymentMethodGraphQl } from 'src/graphql/paymentMethods.graphql';
import { getPaymentMethodName } from 'src/utils/formatter';
import './ChosePaymentMethod.scss';
import { isLimitOfPaymentMethodsReached } from './ChosePaymentMethods.utils';
import { AMOUNT_AND_PAYMENT_METHOD_REQUIRED_MSG } from '../validation/paymentErrorMessages';

interface IChosePaymentMethodFormItem {
  handleChange(value: string): void;
  defaultValue?: string | undefined;
  sortedPaymentMethods: IPaymentMethodGraphQl[] | undefined;
  showAddNewPaymentMethod?: boolean;
  disabled?: boolean;
}
const ChosePaymentMethodFormItem: FC<IChosePaymentMethodFormItem> = ({
  defaultValue,
  handleChange,
  sortedPaymentMethods,
  disabled = false,
}) => {
  const { Option } = Select;

  const {
    formState: { errors, isSubmitted },
    control,
    watch,
  } = useFormContext<IEasyPayWallet | IAddFunds>();

  const otherAmountWatch = watch('otherAmount');
  const addFundsButtonWatch = watch('addFundsButton');
  const paymentMethodIdWatch = watch('paymentMethodId');

  const showErrorMessage = useMemo(() => {
    return isSubmitted
      ? otherAmountWatch === undefined && addFundsButtonWatch === undefined && !!paymentMethodIdWatch
      : false;
  }, [otherAmountWatch, addFundsButtonWatch, paymentMethodIdWatch, isSubmitted]);

  return (
    <>
      <div className="select payment-method mb-8 w-full">
        <Form.Item
          className="theme pt-0 text-left"
          name="paymentMethods"
          hasFeedback
          validateStatus={errors.paymentMethodId ? 'error' : ''}
          help={errors.paymentMethodId?.message}
          required
        >
          <Controller
            name="paymentMethodId"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                defaultValue={defaultValue}
                id="paymentMethodId"
                key="paymentMethodId"
                className={`${errors?.paymentMethodId ? 'ant-select-focused' : ''} my-8 w-full`}
                optionFilterProp="children"
                placeholder={t`Payment Method`}
                onChange={handleChange}
                disabled={disabled}
                aria-label={t`Select Payment Method`}
              >
                {sortedPaymentMethods &&
                  sortedPaymentMethods.map((paymentMethod) => (
                    <Option value={paymentMethod.paymentMethodId} key={paymentMethod.paymentMethodId}>
                      {getPaymentMethodName(paymentMethod, true)}
                    </Option>
                  ))}
                {!isLimitOfPaymentMethodsReached(sortedPaymentMethods || []) ? (
                  <Option value={ADD_NEW_PAYMENT_OPTION} key={ADD_NEW_PAYMENT_OPTION}>
                    {t`Add new payment method`}
                  </Option>
                ) : null}
                <Option value={ONE_TIME_PURCHASE} key={ONE_TIME_PURCHASE}>
                  {t`Make a one time payment`}
                </Option>
              </Select>
            )}
          />
        </Form.Item>
        {showErrorMessage ? (
          <div className="text-red ml-4 mt-3 text-left">{AMOUNT_AND_PAYMENT_METHOD_REQUIRED_MSG}</div>
        ) : null}
      </div>
    </>
  );
};
export default ChosePaymentMethodFormItem;

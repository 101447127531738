import { useCallback, useEffect, useState } from 'react';
import { useCloseAccount } from './useCloseAccount';
import { useContactSettingsStore } from '../../../store/account/contactSettings.store';

interface IUseSubmitBtnDisable {
  isSubmitError: boolean;
  isSubmitSuccessful: boolean;
  isDirty: boolean;
}
export const useSubmitBtnDisable = ({ isSubmitError, isSubmitSuccessful, isDirty }: IUseSubmitBtnDisable) => {
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(false);
  const { submitBtnDisabledForCloseAccount } = useCloseAccount();

  const {
    closeAccountInfo: { isCloseProcessActive },
  } = useContactSettingsStore();

  useEffect(() => {
    setIsSubmitBtnDisabled(submitBtnDisabledForCloseAccount);
  }, [submitBtnDisabledForCloseAccount]);

  useEffect(() => {
    if (isCloseProcessActive) {
      setIsSubmitBtnDisabled(submitBtnDisabledForCloseAccount);
    } else {
      if (isFormAllowedToBeSubmitted()) {
        setIsSubmitBtnDisabled(true);
      } else {
        setIsSubmitBtnDisabled(false);
      }
    }
  }, [isSubmitSuccessful, isDirty, isSubmitError, submitBtnDisabledForCloseAccount, isCloseProcessActive]);

  const isFormAllowedToBeSubmitted = useCallback(() => {
    return (isSubmitSuccessful || !isDirty) && !isSubmitError;
  }, [isDirty, isSubmitSuccessful, isSubmitError]);

  return {
    isSubmitBtnDisabled,
    setIsSubmitBtnDisabled,
  };
};

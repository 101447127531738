import React, { FC } from 'react';
import { t, Trans } from '@lingui/macro';
import ConfirmationModal from 'src/components/Modals/ConfirmationModal/ConfirmationModal';

interface IOptOutOfEasyPayModalProps {
  showModal: boolean;
  onOk: () => void;
  onCancel: () => void;
}

const OptOutOfEasyPayModal: FC<IOptOutOfEasyPayModalProps> = ({ showModal, onOk, onCancel }) => {
  const okButtonText = t`Confirm`;
  const cancelButtonText = t`Cancel`;
  const title = t`Remove Payment Method & Opt Out of Easy Pay?`;

  const content = (
    <div>
      <Trans>
        Please confirm that you&apos;d like to remove all Easy Pay payment methods and opt out of Easy Pay. Your account
        will no longer make automatic replenishments & payments, and you&apos;ll have to add funds and pay tolls
        manually.
      </Trans>
    </div>
  );

  return (
    <>
      {showModal && (
        <ConfirmationModal
          onOk={onOk}
          showModal={showModal}
          okButtonText={okButtonText}
          content={content}
          closable={true}
          alignButtons="center"
          alignText="center"
          cancelButtonText={cancelButtonText}
          onCancel={onCancel}
          title={title}
        />
      )}
    </>
  );
};
export default OptOutOfEasyPayModal;

import { t } from '@lingui/macro';
import { Form, Input } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';

const AccountNumber = ({ disabled }: { disabled?: boolean }) => {
  const {
    control,
    trigger,
    watch,
    formState: { errors },
  } = useFormContext();

  return (
    <Form.Item
      label={t`Account Number`}
      className="theme forgot-username-or-password-input"
      help={errors.accountNumber?.message as React.ReactNode}
      hasFeedback
      validateStatus={errors.accountNumber?.message ? 'error' : ''}
    >
      <Controller
        name={'accountNumber'}
        control={control}
        render={({ field, fieldState }) => (
          <Input
            {...field}
            {...fieldState}
            id={'accountNumber'}
            key={'accountNumber'}
            minLength={1}
            maxLength={10}
            disabled={disabled}
            onChange={(value: any) => {
              field.onChange(value);
              if (watch('accountNumber') == '') {
                field.onChange(undefined);
              }
              trigger(['username', 'transponderNumber']);
            }}
            aria-label={t`account number`}
          />
        )}
      />
    </Form.Item>
  );
};

export default AccountNumber;

import MoneyIcon from '../../assets/Yellow-Money-Icon.svg';
import { Trans } from '@lingui/macro';
import { useState } from 'react';
import { useScroll } from '../../../../hooks/screen/useScroll';
import AddFunds from '../../MyDashboard/AddFunds/AddFunds';
import { CustomButton } from '../../../CustomButton/CustomButton';
import {
  ADD_FUNDS_SECTION_ID,
  BALANCE_SECTION_ID,
  EASY_PAY_SECTION_ID,
  PAYMENT_METHODS_SECTION_ID,
} from '../../references';
import './Balance.scss';
import { PromoCodeModal } from '../PromoCodeModal/PromoCodeModal';
export const BalanceHeader = () => {
  const { scrollToElem } = useScroll();

  const [isAddFundsVisible, setIsAddFundsVisible] = useState(false);
  const [isPromoModalVisible, setIsPromoModalVisible] = useState(false);
  const handleClick = () => {
    !isAddFundsVisible ? setIsAddFundsVisible(true) : setIsAddFundsVisible(false);
  };

  const buttonText = isAddFundsVisible ? 'Close' : 'Add Funds';

  return (
    <>
      <div className="my-wallet-header">
        <div className="mb-6 flex items-center justify-between">
          <h1 className=" text-left text-4xl text-primary-blue1">
            <Trans>My Wallet</Trans>
          </h1>
          <button className="dashboard-links" onClick={() => setIsPromoModalVisible(true)}>
            <Trans>Add Toll Credit</Trans>
          </button>
        </div>
        <div className="links ml-auto mr-0 flex gap-x-2">
          <p className="font-medium">JUMP TO:</p>
          <div className="flex gap-x-2 sm:gap-x-8">
            <button className="dashboard-links mb-10 cursor-pointer" onClick={() => scrollToElem(BALANCE_SECTION_ID)}>
              Balance
            </button>
            <button
              className="dashboard-links mb-10 cursor-pointer"
              onClick={() => scrollToElem(PAYMENT_METHODS_SECTION_ID)}
            >
              Payment Methods
            </button>
            <button className="dashboard-links mb-10 cursor-pointer" onClick={() => scrollToElem(EASY_PAY_SECTION_ID)}>
              EasyPay Enrollment
            </button>
          </div>
        </div>
      </div>
      <div className="mb-6 mt-5 flex items-center justify-between lg:justify-start " id={ADD_FUNDS_SECTION_ID}>
        <div className="flex items-center justify-start">
          <img src={MoneyIcon} alt="clock icon" />
          <h1 className="ml-3 text-xl font-bold sm:ml-6 sm:text-3xl">
            <Trans>Balance</Trans>
          </h1>
          <CustomButton className="add-funds-button ml-5" content={<Trans>{buttonText}</Trans>} onClick={handleClick} />
        </div>
      </div>
      <PromoCodeModal isPromoModalVisible={isPromoModalVisible} setIsPromoModalVisible={setIsPromoModalVisible} />
      {isAddFundsVisible ? (
        <div className="add-funds-my-wallet mb-4 mt-10 h-full ">
          <AddFunds inExpandedContainer />
        </div>
      ) : null}
    </>
  );
};

import React, { FC } from 'react';
import { t } from '@lingui/macro';
import { IPaymentMethodGraphQl } from 'src/graphql/paymentMethods.graphql';
import { ITransponder } from 'src/graphql/transponders.graphql';
import { concatRoutes, DashboardParams, MainRoutes } from 'src/types/routes';
import ActionItem from './ActionItem';
import { ActionItemAndIconLinkCardId } from './ActionItemAndIconLinkCardId';

interface IItemsPrivate {
  paymentMethods: IPaymentMethodGraphQl[] | undefined;
  parkingOption: string | undefined;
  activatedTransponders: undefined | ITransponder[];
  isOptInEasyPay: boolean;
}

const ItemsPrivate: FC<IItemsPrivate> = ({ paymentMethods, parkingOption, activatedTransponders, isOptInEasyPay }) => {
  return (
    <div className="action-items grid grid-cols-1 grid-rows-4 gap-0 md:gap-1 lg:grid-cols-2 lg:grid-rows-2">
      <ActionItem
        id={ActionItemAndIconLinkCardId.ADD_PAYMENT_METHOD}
        title={t`Add Payment Method`}
        autoHeight={true}
        selected={!!paymentMethods && paymentMethods?.length != 0}
        link={concatRoutes(MainRoutes.DASHBOARD, '/', DashboardParams.WALLET)}
      />
      <ActionItem
        id={ActionItemAndIconLinkCardId.PARKING}
        title={t`Explore SunPass Plus Parking`}
        autoHeight={true}
        selected={parkingOption === 'Y'}
        link={MainRoutes.MY_ACCOUNT}
      />
      <ActionItem
        id={ActionItemAndIconLinkCardId.ACTIVATE_TRANSPONDER}
        title={t`Activate Transponder`}
        autoHeight={true}
        selected={!!activatedTransponders && activatedTransponders.length !== 0}
        link={concatRoutes(MainRoutes.DASHBOARD, '/', DashboardParams.TRANSPONDERS)}
      />
      <ActionItem
        id={ActionItemAndIconLinkCardId.SET_UP_EASY_PAY}
        title={t`Set up Easy Pay`}
        autoHeight={true}
        selected={isOptInEasyPay}
        link={concatRoutes(MainRoutes.DASHBOARD, '/', DashboardParams.WALLET)}
      />
    </div>
  );
};

export default ItemsPrivate;

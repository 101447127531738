import { useLocation, useNavigate } from 'react-router';
import { MainRoutes, mapPathToOnboardingStep, OnboardingStep, OnboardingStepPath } from '../../types/routes';
import { usePrevLocation } from './usePrevLocation';
import { useOnboardingStore } from '../../store/onboarding/onboarding.store';
import { useCallback, useMemo, useState } from 'react';
import { useAuth } from '../../store/authentication/authentication.store';

interface IOnboardingHistory {
  goBack: (state?: { [key: string]: any }) => void;
  goToPath: (path: string, state?: { [key: string]: any }) => void;
  checkHistory: () => void;
  getOnboardingHistory: () => string[];
  getFullOnboardingHistory: () => string[];
  isStepRightAfterReferenceCreation: () => boolean;
}

const onboardingHistory: string[] = [];
const fullOnboardingHistory: string[] = [];

/**
 * Hook to navigate between onboarding screens.
 * useHistory().back does not account for the necessity of the steps to be navigated in a specific order
 * @returns IOnboardingHistory
 */

export const useRouteMonitor = (): IOnboardingHistory => {
  const history = useNavigate();
  const location = useLocation();
  const prevLocation = usePrevLocation(location);
  const { resetOnboardingStore } = useOnboardingStore();

  const clearHistory = () => {
    onboardingHistory.splice(0, onboardingHistory.length);
    fullOnboardingHistory.splice(0, fullOnboardingHistory.length);
  };

  const getOnboardingHistory = (): string[] => {
    return onboardingHistory;
  };

  const getFullOnboardingHistory = (): string[] => {
    return fullOnboardingHistory;
  };

  const isBeforeOrOnCurrentOnboardingStepOf = (step: OnboardingStep) => {
    const onboardingStep = mapPathToOnboardingStep[location.pathname as OnboardingStepPath];
    return onboardingStep.valueOf() <= step.valueOf();
  };

  const isAfterOnboardingStepOf = (step: OnboardingStep) => {
    return !isBeforeOrOnCurrentOnboardingStepOf(step);
  };

  const [isOnboardingBlocked, setIsOnboardingBlocked] = useState(false);

  const {
    details: { isAuthenticated },
  } = useAuth();

  const isIllegalOnboardingRedirectForced = useMemo((): boolean => {
    return getPathOrigin(location.pathname) === OnboardingStepPath.INITIAL && isOnboardingBlocked;
  }, [location.pathname, isOnboardingBlocked]);

  const isDashboardDefaultAuthRedirectForced = useMemo(() => {
    return (
      getPathOrigin(prevLocation.pathname) === MainRoutes.ROOT &&
      getPathOrigin(location.pathname) === MainRoutes.DASHBOARD
    );
  }, [prevLocation, location]);

  const isUserLogOutRedirectForced = useMemo(() => {
    return location.pathname === MainRoutes.ROOT && !isAuthenticated;
  }, [location.pathname, isAuthenticated]);

  const isStepRightAfterReferenceCreation = () => {
    const history = getOnboardingHistory();
    return history[history.length - 2] === OnboardingStepPath.ACCOUNT;
  };

  const isRedirectToDashboardDuringOnboardingForced = useMemo(() => {
    return (
      getPathOrigin(location.pathname) === MainRoutes.DASHBOARD &&
      getPathOrigin(prevLocation.pathname) === OnboardingStepPath.INITIAL
    );
  }, [location.pathname, prevLocation.pathname]);

  const isRedirectToHomeForcedDuringOnboarding = useMemo(() => {
    return getPathOrigin(prevLocation.pathname) === OnboardingStepPath.INITIAL && location.pathname === MainRoutes.ROOT;
  }, [prevLocation.pathname, location.pathname]);

  const isRedirectToAfter = useCallback(
    (redirectTo: MainRoutes, stepAfter: OnboardingStep) => {
      return isAfterOnboardingStepOf(stepAfter) && location.pathname === redirectTo;
    },
    [location.pathname],
  );

  const isRedirectToBefore = useCallback(
    (redirectTo: MainRoutes, stepBefore: OnboardingStep) => {
      return isBeforeOrOnCurrentOnboardingStepOf(stepBefore) && location.pathname === redirectTo;
    },
    [location.pathname],
  );

  const isRedirectToNextOnboardingStep = useMemo(() => {
    return onboardingHistory[onboardingHistory.length - 1] !== location.pathname;
  }, [onboardingHistory, location.pathname]);

  const checkHistory = (): void => {
    if (isIllegalOnboardingRedirectForced) goToPath(MainRoutes.DASHBOARD);
    if (isDashboardDefaultAuthRedirectForced) setIsOnboardingBlocked(true);
    if (isUserLogOutRedirectForced) setIsOnboardingBlocked(false);
    if (isRedirectToDashboardDuringOnboardingForced) {
      setIsOnboardingBlocked(true);
      clearHistory();
      resetOnboardingStore();
    }
    if (isRedirectToHomeForcedDuringOnboarding) resetOnboardingStore();
    if (getPathOrigin(location.pathname) !== OnboardingStepPath.INITIAL) {
      goToPath(location.pathname);
    } else {
      if (location.pathname !== OnboardingStepPath.INITIAL && onboardingHistory.length) {
        if (isRedirectToBefore(MainRoutes.ROOT, OnboardingStep.ACCOUNT)) {
          clearHistory();
          goToPath(MainRoutes.ROOT);
        }
        if (isRedirectToAfter(MainRoutes.DASHBOARD, OnboardingStep.ACCOUNT)) {
          clearHistory();
          goToPath(MainRoutes.DASHBOARD);
        }
        if (isRedirectToNextOnboardingStep) {
          clearHistory();
          goToPath(OnboardingStepPath.INITIAL);
        }
      } else {
        clearHistory();
        onboardingHistory.push(location.pathname);
      }
    }
  };

  const goToPath = (path: string, state?: { [key: string]: any }): void => {
    if (location.pathname === OnboardingStepPath.INITIAL) {
      clearHistory();
      fullOnboardingHistory.push(location.pathname);
      onboardingHistory.push(location.pathname);
    }
    onboardingHistory[onboardingHistory.length - 1] !== path && onboardingHistory.push(path);
    fullOnboardingHistory.push(path);
    history(path, { state: state });
  };

  /**
   * Navigate back within the onboarding workflow
   * @param state State object containing any data to pass to the next path.  See react router location docs https://v5.reactrouter.com/web/api/location
   */

  const goBack = (state?: { [key: string]: any }) => {
    onboardingHistory.pop();
    if (onboardingHistory.length) {
      fullOnboardingHistory.push(onboardingHistory[onboardingHistory.length - 1]);
      history(onboardingHistory[onboardingHistory.length - 1], { replace: true, state: state });
    } else {
      goToPath(MainRoutes.ROOT);
    }
  };
  return {
    goToPath,
    goBack,
    checkHistory,
    getOnboardingHistory,
    getFullOnboardingHistory,
    isStepRightAfterReferenceCreation,
  };
};

export const getPathOrigin = (pathname: string) => {
  return `/${pathname.split('/')[1]}`;
};

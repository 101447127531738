import { Location } from 'react-router-dom';
import { useEffect, useRef } from 'react';

export const usePrevLocation = (location: Location): Location => {
  const prevLocationRef = useRef(location);
  useEffect(() => {
    prevLocationRef.current = location;
  }, [location]);
  return prevLocationRef.current;
};

import React, { FC } from 'react';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import { t, Trans } from '@lingui/macro';
import ThumbsUp from '../Onboarding/assets/thumbs_up.svg';
import { useNavigate } from 'react-router-dom';
import { CustomButton } from 'src/components/CustomButton/CustomButton';

const AccountClosure: FC = () => {
  const navigate = useNavigate();

  const redirectToHome = () => {
    navigate('/');
  };

  return (
    <div className="h-full">
      <ScrollToTop />
      <h5 className="onboarding-header">{t`Account Closure Complete`}</h5>
      <h1 className="md:2/3 m-auto w-full px-2 py-8 text-center font-light xl:w-1/3">
        <Trans>You have successfully closed your SunPass Account</Trans>
      </h1>
      <div className="onboarding-success-container">
        <div className="flex w-full flex-col items-center">
          <img className="w-30" src={ThumbsUp} alt="Success message image" />
          <div className="mt-8 w-full text-center sm:w-3/4">
            {t`Thank you for being a valued SunPass customer, we hope to see you again soon`}!
          </div>
          <CustomButton className=" mt-20" content="Return to sunpass.com" onClick={redirectToHome} />
        </div>
      </div>
    </div>
  );
};

export default AccountClosure;

import { gql } from '@apollo/client';

export const VERIFY_PROMO_CODE = gql`
  query verifyPromotionCode($couponCode: String!) {
    verifyPromotionCode(couponCode: $couponCode) {
      amount
      couponStatus
      mountType
    }
  }
`;

export interface IVerifyPromoCodeInput {
  couponCode: string;
}

export interface IVerifyPromotionCodeOutput {
  amount: string;
  couponStatus: string;
  mountType: string;
}

export enum PromoCodeType {
  STK = 'STK',
  PRO = 'PRO',
  TOLL = 'TOLL',
}

import { FC, useCallback, useMemo, useState } from 'react';
import { Trans } from '@lingui/macro';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import {
  ITransponderInput,
  ITransponderList,
  IUpdateTransponderInput,
  TransponderStatus,
  UPDATE_TRANSPONDER,
} from '../../../../graphql/transponders.graphql';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTransponderStore } from '../../../../store/dashboard/transponder.store';
import { TransponderListItem } from './TransponderListItem';
import '../Transponder.scss';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { useNonInitialRender } from '../../../../hooks/effect/useNonInitialRender';
import { TransponderEdit } from '../TransponderEdit';
import { DEFAULT_TRANSPONDERS_FETCH_PARAMS } from './defaultValues';
import { IFilter, SortDirectionType } from '../../../../graphql/filter.graphql';
import Pagination from 'src/components/Pagination/Pagination';
import { usePaginationInfo } from 'src/store/pagination/pagination.store';
import { PURCHASE_TRANSPONDER_QUANTITY_ID, TRANSPONDERS_HEADER } from '../../references';
import { useScroll } from 'src/hooks/screen/useScroll';
import DeactivateTranspondersModal from '../../../Modals/ConfirmationModal/DeactivateTranspondersModal';

interface ITransponderListProps {
  refetch: (
    variables?: Partial<{ transponderListInput: ITransponderInput }> | undefined,
  ) => Promise<ApolloQueryResult<{ transponderList: ITransponderList }>>;
  transpondersData: { transponderList: ITransponderList } | undefined;
}

export const TransponderList: FC<ITransponderListProps> = ({ refetch, transpondersData }) => {
  const { transpondersPagination } = usePaginationInfo();
  const totalPages = transpondersData?.transponderList?.totalPages;
  const totalCount = transpondersData?.transponderList.totalElements || 0;
  const [resetPagination, setResetPagination] = useState(false);

  const {
    store: { transponderToEdit, isDeactivationModalOpen, editMode },
    setEditMode,
    setTransponderToEdit,
    setIsDeactivationModalOpen,
  } = useTransponderStore();

  const { scrollToElem } = useScroll();

  const [filter, setFilter] = useState<IFilter>();

  const onConfirmDeactivateAndReplaceModal = async () => {
    await deactivateTransponder();
    setIsDeactivationModalOpen(false);
    scrollToElem(PURCHASE_TRANSPONDER_QUANTITY_ID, false);
  };

  const onConfirmDeactivateWithoutReplacingModal = async () => {
    await deactivateTransponder();
    setIsDeactivationModalOpen(false);
    scrollTo(0, 0);
  };

  useNonInitialRender(() => {
    (async () => {
      await refetch({
        transponderListInput: {
          pagination: transpondersPagination
            ? {
                pageNumber: transpondersPagination.page,
                pageSize: transpondersPagination.size,
              }
            : DEFAULT_TRANSPONDERS_FETCH_PARAMS.pagination,
          filter,
        },
      });
    })();
  }, [filter]);

  const editTransponder = (transponderNumber: string) => {
    setTransponderToEdit(
      transpondersData?.transponderList.transponders.find(
        (transponder) => transponder.transponderNumber === transponderNumber,
      ),
    );
    setEditMode(true);
  };

  const calculateSortDirection = (columnName: string): SortDirectionType => {
    if (filter?.sortColumn === columnName) {
      return filter.sortDirection === SortDirectionType.ASC ? SortDirectionType.DESC : SortDirectionType.ASC;
    }
    return SortDirectionType.DESC;
  };

  const [updateTransponder] = useMutation<
    { updateTransponder: boolean },
    { updateTransponderInput: IUpdateTransponderInput }
  >(UPDATE_TRANSPONDER);

  const changeStatus = async (status: TransponderStatus) => {
    try {
      await updateTransponder({
        variables: {
          updateTransponderInput: {
            transponderNumber: transponderToEdit?.transponderNumber || '',
            newStatus: status,
            friendlyName: transponderToEdit?.friendlyName,
          },
        },
      });
    } catch (err) {
      console.log(err);
    }
    await refetch({
      transponderListInput: DEFAULT_TRANSPONDERS_FETCH_PARAMS,
    });
    setResetPagination(true);
  };
  const deactivateTransponder = async () => {
    await changeStatus(TransponderStatus.CUSTRETAINED);
  };

  const selectFilterArrowIcon = useCallback(
    (currFilterColName: string): IconDefinition => {
      if (filter?.sortColumn !== currFilterColName) return faCaretDown;
      return filter?.sortColumn === currFilterColName && filter?.sortDirection === SortDirectionType.ASC
        ? faCaretDown
        : faCaretUp;
    },
    [filter],
  );

  const listHeader = useMemo(() => {
    return (
      <thead id={TRANSPONDERS_HEADER}>
        <tr>
          <th className="w-1/6 pl-6 pt-6 text-left font-normal">
            <Trans key="headerDateActivated">Date Activated</Trans>
          </th>
          <th
            className="w-1/4 cursor-pointer pl-2 pt-6 text-left font-normal"
            onClick={() =>
              setFilter({ sortColumn: 'friendlyName', sortDirection: calculateSortDirection('friendlyName') })
            }
          >
            <button>
              <Trans key="headerLabel">Friendly Name</Trans>
            </button>
            <FontAwesomeIcon icon={selectFilterArrowIcon('friendlyName')} className="ml-2 " />
          </th>
          <th
            className="w-1/6 cursor-pointer pl-2 pt-6 text-left font-normal"
            onClick={() =>
              setFilter({
                sortColumn: 'transponderType',
                sortDirection: calculateSortDirection('transponderType'),
              })
            }
          >
            <button>
              <Trans key="headerType">Type</Trans>
            </button>
            <FontAwesomeIcon icon={selectFilterArrowIcon('transponderType')} className="ml-2 " />
          </th>
          <th
            className="w-1/6 cursor-pointer pl-2 pt-6 text-left font-normal"
            onClick={() =>
              setFilter({
                sortColumn: 'transponderNumber',
                sortDirection: calculateSortDirection('transponderNumber'),
              })
            }
          >
            <button>
              <Trans key="headerTransponderNumber">Transponder Number</Trans>
            </button>
            <FontAwesomeIcon icon={selectFilterArrowIcon('transponderNumber')} className="ml-2 " />
          </th>
          <th className="w-3/12"></th>
        </tr>
      </thead>
    );
  }, [filter]);

  return (
    <div>
      <div id="transponder-table" className="transponder-table w-full overflow-auto">
        {editMode ? <TransponderEdit refetch={refetch} /> : null}
        <table className="w-full">
          {listHeader}
          <tbody>
            {transpondersData?.transponderList.transponders.map((transponder, index: number) => (
              <tr key={index} className="h-20 break-words">
                <TransponderListItem
                  currentTransponder={transponder}
                  editTransponder={editTransponder}
                  refetch={refetch}
                  resetPagination={resetPagination}
                  setResetPagination={setResetPagination}
                />
              </tr>
            ))}
          </tbody>
        </table>
        {totalPages && totalPages >= 2 ? (
          <Pagination
            count={totalCount}
            type={'transponders'}
            resetPagination={resetPagination}
            setResetPagination={setResetPagination}
          />
        ) : null}
      </div>
      <DeactivateTranspondersModal
        showModal={isDeactivationModalOpen}
        onConfirmDeactivateAndReplaceModal={onConfirmDeactivateAndReplaceModal}
        onConfirmDeactivateWithoutReplacingModal={onConfirmDeactivateWithoutReplacingModal}
        onCancel={() => setIsDeactivationModalOpen(false)}
      />
    </div>
  );
};

import { Trans } from '@lingui/macro';
import { Form } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import PriceInput from '../../PriceInput/PriceInput';
import Note from '../../Note/Note';
import React, { useEffect } from 'react';

export interface IPreloadFunds {
  minimumDepositAmount: number;
}

export interface IDepositAmounts {
  depositAmount: string;
  confirmDepositAmount: string;
}

export const PreloadFunds = ({ minimumDepositAmount }: IPreloadFunds) => {
  const {
    control,
    watch,
    formState: { errors },
    trigger,
  } = useFormContext<IDepositAmounts>();

  useEffect(() => {
    trigger('confirmDepositAmount');
  }, [watch('depositAmount')]);

  return (
    <section className="card--vertical mb-10">
      <h3 className="text-primary-blue1">
        <Trans>Pre-load funds into your account</Trans>
      </h3>
      <div className="form-items-container-inline items-start">
        <Form.Item
          label={<Trans>Set Deposit Amount</Trans>}
          name="depositAmount"
          hasFeedback
          validateStatus={errors.depositAmount ? 'error' : ''}
          className={'theme form-item-tight m-0 inline-block'}
          help={errors.depositAmount?.message as unknown as string}
          validateTrigger={['onChange', 'onBlur']}
        >
          <Controller
            name="depositAmount"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <PriceInput
                id="depositAmount"
                hasErrors={!!errors.depositAmount}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </Form.Item>

        <Form.Item
          label={<Trans>Confirm Deposit Amount</Trans>}
          name="confirmDepositAmount"
          className={'theme form-item-tight m-0 inline-block w-60'}
          hasFeedback
          validateStatus={errors.confirmDepositAmount ? 'error' : ''}
          help={errors.confirmDepositAmount?.message as unknown as string}
          validateTrigger={['onChange', 'onBlur']}
        >
          <Controller
            name="confirmDepositAmount"
            control={control}
            render={({ field: { value, onChange, onBlur } }) => (
              <PriceInput
                id="confirmDepositAmount"
                hasErrors={!!errors.confirmDepositAmount}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </Form.Item>
        <Note
          aria-label="replenishment-threshold-note"
          className="preload-note"
          style={{ minHeight: '43px', paddingTop: '5px' }}
        >
          <strong>
            ${minimumDepositAmount}&nbsp;
            <Trans>Minimum Required</Trans>
          </strong>
        </Note>
      </div>
    </section>
  );
};

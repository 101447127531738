import { create } from '../createStore';
import { AccountSubtype, AccountType, IOnboardingInfo } from '../../types/onboarding.types';
import { devtools } from 'zustand/middleware';
import { PaymentMethodType } from 'src/components/Payments/Payments.enums';
import { IPaymentMethod } from 'src/components/Payments/Payments.interfaces';
import { SELECT_RECOMMENDED_SUNPASS_PLAN_NAME } from './onboarding.selectors';

export const ONBOARDING_INITIAL_STATE: IOnboardingInfo = {
  onboardingStarted: false,
  shouldDisplayAccountCreatedMsg: false,
  miniTransponderQty: 0,
  miniTransponderQtyAssignedToPlan: 0,
  proTransponderQty: 0,
  proTransponderQtyAssignedToPlan: 0,
  preloadedFunds: 0,
  totalTranspondersCost: 0,
  applicableTax: 0,
  chosenSubtype: AccountSubtype.NONE,

  transpondersToActivate: [],
  accountInfo: {
    doingBusinessAs: '',
    language: 'English',
    addressLine2: '',
    addressLine1: '',
    city: '',
    country: '',
    postalCode: '',
    state: '',
    statementType: 'Email',
    reTypePassword: '',
    username: '',
    firstSecurityQuestionAnswer: '',
    firstSecurityQuestion: '',
    secondSecurityQuestion: '',
    secondSecurityQuestionAnswer: '',
    thirdSecurityQuestionAnswer: '',
    thirdSecurityQuestion: '',
    reTypeEmail: '',
    password: '',
    lastName: '',
    firstName: '',
    email: '',
  },
  vehicles: [],
  easyPay: {},
  cartTotal: 0,
  accountType: AccountType.PAYG,
  accountSubtype: AccountSubtype.NONE,
  paymentMethodType: PaymentMethodType.CARD,
  transponderSerialNumbers: {
    miniTransponderSerialNumber: '',
    proTransponderSerialNumber: '',
  },
  cardType: '',
  referenceNumber: '',
  accountId: '',
  planName: 'NONE',
};

export interface IOnboardingStore {
  onboardingInfo: IOnboardingInfo;
  updateOnboardingPaymentMethod: (paymentMethod: IPaymentMethod) => void;
  updateOnboardingStore: (data: Partial<IOnboardingInfo>) => void;
  resetOnboardingStore: () => void;
}

export const useOnboardingStore = create<IOnboardingStore, [['zustand/devtools', IOnboardingStore]]>(
  devtools((set) => ({
    onboardingInfo: ONBOARDING_INITIAL_STATE,
    updateOnboardingPaymentMethod: (paymentMethod) => {
      set((state) => ({
        onboardingInfo: {
          ...state.onboardingInfo,
          paymentMethod: {
            state: paymentMethod.state,
            postalCode: paymentMethod.postalCode,
            country: paymentMethod.country,
            county: paymentMethod.county,
            city: paymentMethod.city,
            addressLine2: paymentMethod.addressLine2,
            addressLine1: paymentMethod.addressLine1,
            paymentType: paymentMethod.paymentType,
            ccFirstName: paymentMethod.ccFirstName,
            ccMiddleName: paymentMethod.ccMiddleName,
            ccLastName: paymentMethod.ccLastName,
            ccNumber: paymentMethod.ccNumber,
            ccExpirationMonth: paymentMethod.ccExpirationMonth,
            ccExpirationYear: paymentMethod.ccExpirationYear,
            ccSecurityCode: paymentMethod.ccSecurityCode,
            bankFirstName: paymentMethod.bankFirstName,
            bankLastName: paymentMethod.bankLastName,
            bankAccountNumber: paymentMethod.bankAccountNumber,
            bankRoutingNumber: paymentMethod.bankRoutingNumber,
          },
          address: {
            addressLine1: paymentMethod.addressLine1,
            addressLine2: paymentMethod.addressLine2,
            city: paymentMethod.city,
            state: paymentMethod.state,
            postalCode: paymentMethod.postalCode,
            country: paymentMethod.country,
          },
        },
      }));
    },
    updateOnboardingStore: (data) => {
      set((state) => ({
        onboardingInfo: {
          ...state.onboardingInfo,
          ...data,
          planName: SELECT_RECOMMENDED_SUNPASS_PLAN_NAME(
            data.accountType || state.onboardingInfo.accountType,
            data.accountSubtype || AccountSubtype.NONE,
          ),
        },
      }));
    },
    resetOnboardingStore: () => {
      set(() => ({
        onboardingInfo: ONBOARDING_INITIAL_STATE,
      }));
    },
  })),
);

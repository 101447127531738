import React, { FC } from 'react';
import { Trans } from '@lingui/macro';
import useScreenSize from 'src/hooks/screen/useScreenSize';
import { VEHICLE_HEADER } from '../../references';

const VehiclesListHeader: FC = () => {
  const { screenSize } = useScreenSize();

  return (
    <header className={'flex justify-start px-8 py-4'} id={VEHICLE_HEADER}>
      <div className={`${screenSize > 1 ? '' : 'hidden'} w-2/12`}>
        <Trans>License Plate</Trans>
      </div>
      <div className={`${screenSize > 1 ? '' : 'hidden'} w-3/12 pl-4`}>
        <Trans>Vehicle Information</Trans>
      </div>
    </header>
  );
};

export default VehiclesListHeader;

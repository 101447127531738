import React, { FC } from 'react';
import { t, Trans } from '@lingui/macro';
import ConfirmationModal from './ConfirmationModal';

interface IConfirmationModalProps {
  showModal: boolean;
  onOk: () => void;
  onCancel?: (nextLocation: any) => void | (() => void);
  cancelButtonBlue?: boolean;
}

const CloseAccountConfirmationModal: FC<IConfirmationModalProps> = ({ showModal, onOk, onCancel }) => {
  const cancelButtonText = t`Go Back`;
  const okButtonText = t`Confirm`;
  const title = (
    <>
      <h1 className="text-xl font-extrabold leading-6 xsm:leading-5">
        <Trans>Confirm Account Closure</Trans>
      </h1>
    </>
  );
  const content = t`By closing your account, you will lose the benefits of being a SunPass account holder and your transponders will no longer work on Florida Toll Roads. Any future toll charges incurred will be communicated in a mailed invoice according to your license plate number. Please confirm that you'd like to close your account.`;

  return (
    <>
      {showModal && (
        <ConfirmationModal
          onOk={onOk}
          onCancel={onCancel}
          showModal={showModal}
          cancelButtonText={cancelButtonText}
          okButtonText={okButtonText}
          title={title}
          content={content}
          closable={true}
          alignButtons="center"
          alignText="center"
        />
      )}
    </>
  );
};
export default CloseAccountConfirmationModal;

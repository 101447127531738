import React from 'react';
import { Steps } from 'antd';
import { map } from 'lodash';
import './ProgressSteps.scss';

const { Step } = Steps;

function ProgressSteps({
  steps,
  currentStepIndex,
}: {
  steps: ProgressStep[];
  currentStepIndex: number;
}): React.ReactElement {
  const currentStepLabel = steps.length > currentStepIndex ? steps[currentStepIndex].title : '';
  const currentStepDesc = steps.length > currentStepIndex ? steps[currentStepIndex].description : '';
  return (
    <>
      <div className="mb-6 text-center">
        <span className="font-bold">{currentStepLabel}</span>
        {currentStepDesc}
      </div>
      <Steps responsive={false} size="small" current={currentStepIndex}>
        {map(steps, (step: ProgressStep, index: number) => (
          <Step style={{ margin: 0 }} key={index} />
        ))}
      </Steps>
    </>
  );
}

export interface ProgressStep {
  title: string;
  description: string;
}

export default ProgressSteps;

import React, { ReactNode, useMemo } from 'react';
import { getAccessToken, getRefreshToken, isTokenValid, setAccessToken, setRefreshToken } from './token.service';
import { ApolloClient, ApolloLink, ApolloProvider, fromPromise, InMemoryCache, Operation } from '@apollo/client';
import { useAuth } from '../store/authentication/authentication.store';
import { REFRESH_TOKEN } from '../graphql/refreshToken.graphql';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import useErrorHandling from '../hooks/errors/useErrorHandling';
import { resetAllStores } from '../store/createStore';
import { i18n } from '@lingui/core';
import { CustomGraphQLErrorExtensions } from '../hooks/errors/errorHandler.types';
import { MainRoutes } from '../types/routes';

const SERVER_GRAPHQL_URL = '/graphql';

export const appendClientOperationName = (operation: Operation) => {
  return `${SERVER_GRAPHQL_URL}/${operation.operationName}`;
};

export const newApolloClient = new ApolloClient({
  uri: (operation) => appendClientOperationName(operation),
  cache: new InMemoryCache(),
});

let isRefreshing = false;

export const validateTokens = (): Promise<void> => {
  if (!isTokenValid()) {
    return refreshTokens();
  }
  return new Promise((resolve) => resolve());
};

export const refreshTokens = async (): Promise<void> => {
  const prevAccessToken = getAccessToken();
  const prevRefreshToken = getRefreshToken();
  const {
    data: {
      refreshToken: { refreshToken, token },
    },
  } = await newApolloClient.mutate({
    mutation: REFRESH_TOKEN,
    variables: { refreshInput: { token: prevAccessToken, refreshToken: prevRefreshToken } },
  });
  setAccessToken(token);
  setRefreshToken(refreshToken);
};

const CustomApolloProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const { invalidateAuthentication } = useAuth();
  const { catchError } = useErrorHandling();

  const client = useMemo(() => {
    const createLinks = () => {
      const httpLink = createUploadLink({
        uri: (operation) => appendClientOperationName(operation),
      });
      const authLink = new ApolloLink((operation, forward) => {
        const token = getAccessToken();
        operation.setContext(({ headers = {} }) => ({
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
          },
        }));
        return forward(operation);
      });

      const refreshLink = new ApolloLink((operation, forward) => {
        if (isTokenValid()) {
          return forward(operation);
        } else {
          if (!isRefreshing) {
            isRefreshing = true;
            return fromPromise(
              refreshTokens()
                .then(() => {
                  isRefreshing = false;
                  return forward(operation);
                })
                .catch(async () => {
                  isRefreshing = false;
                  await client.cache.reset();
                  await client.clearStore();
                  resetAllStores();
                  invalidateAuthentication();
                  return forward(operation);
                }),
            ).flatMap(() => forward(operation));
          }
          return forward(operation);
        }
      });

      const errorLink = onError((response) => {
        const errorExtensions: CustomGraphQLErrorExtensions = handleApolloErrors(response);
        catchError(errorExtensions);
      });
      return ApolloLink.from([errorLink, refreshLink, authLink.concat(httpLink as unknown as ApolloLink)]);
    };

    return new ApolloClient({
      link: createLinks(),
      cache: new InMemoryCache(),
      connectToDevTools: true,
    });
  }, []);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

const handleApolloErrors = (errorResponse: ErrorResponse): CustomGraphQLErrorExtensions => {
  const DEFAULT_ERROR_MSG_FOR_UNKNOWN = i18n._('Unknown error ');
  let errorExtensions: CustomGraphQLErrorExtensions | undefined;
  const { graphQLErrors, networkError } = errorResponse;
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      if (message.includes('502') || message.includes('503')) {
        location.assign(MainRoutes.MAINTENANCE);
      }
      errorExtensions = {
        ...extensions,
        rawMsg: message,
        failedActionName: path?.[0],
      } as CustomGraphQLErrorExtensions;
    });
  } else if (networkError && 'statusCode' in networkError) {
    if (
      networkError.message.includes('502') ||
      networkError.message.includes('503') ||
      networkError.statusCode === 502 ||
      networkError.statusCode === 503
    ) {
      location.assign(MainRoutes.MAINTENANCE);
    }
    console.error(`[Network error]: ${networkError}`);
    errorExtensions = {
      code: networkError.statusCode,
      englishMsg: networkError.message,
      spanishMsg: networkError.message,
      rawMsg: networkError.message,
    };
  } else {
    errorExtensions = {
      code: -1,
      englishMsg: DEFAULT_ERROR_MSG_FOR_UNKNOWN,
      spanishMsg: DEFAULT_ERROR_MSG_FOR_UNKNOWN,
      rawMsg: DEFAULT_ERROR_MSG_FOR_UNKNOWN,
    };
  }
  return errorExtensions as CustomGraphQLErrorExtensions;
};

export default CustomApolloProvider;

import * as React from 'react';
import { Steps } from 'antd';
import './MultistepPayment.scss';
import ChosePaymentMethodStep from './ChosePaymentMethodStep';
import { useMultiStepPaymentStore } from 'src/store/multiStepPayment/multiStepPayment.store';
import PaymentSummaryStep from './PaymentSummaryStep';
import PaymentSucces from './SuccesfullMessageStep';
import { t } from '@lingui/macro';

export default function MultiStepPayment() {
  const { Step } = Steps;

  const currentStep = useMultiStepPaymentStore((state) => state.currentStep);

  return (
    <div className="mx-auto my-10 w-full rounded-lg bg-white px-6 py-6 shadow-lg">
      <div className="multistep-container ml-6 flex justify-center pt-2">
        <Steps size={'small'} current={currentStep}>
          <Step />
          <Step />
          <Step />
        </Steps>
      </div>
      {currentStep === 0 && (
        <>
          <div className="px-4 py-8 text-center text-3xl font-bold text-primary-blue1">
            {t`Which payment method do you intend on using for this purchase?`}
          </div>
          <ChosePaymentMethodStep />
        </>
      )}
      {currentStep === 1 && <PaymentSummaryStep />}
      {currentStep === 2 && <PaymentSucces />}
    </div>
  );
}

import { gql } from '@apollo/client';

export const LOGOUT_USER = gql`
  mutation logoutUser($logoutInput: LogoutInput!) {
    logoutUser(logoutInput: $logoutInput)
  }
`;

export interface ILogoutInput {
  token: string | null;
  refreshToken: string | null;
}

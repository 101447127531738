import { DependencyList, EffectCallback, useEffect, useState } from 'react';

export const useNonInitialRender = (effect: EffectCallback, deps?: DependencyList) => {
  const [initialRender, setInitialRender] = useState(false);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    let effectsReturns: void | (() => void) = () => {};

    if (initialRender) {
      effectsReturns = effect();
    }
    setInitialRender(true);
    if (effectsReturns && typeof effectsReturns === 'function') {
      return effectsReturns;
    }
  }, deps);
};

import {
  AccountSubtype,
  AccountType,
  FLEET_PINELLAS_PLAN,
  FLEET_PLAN,
  FLEET_PLAN_DESCRIPTION,
  GOVT_ACCOUNT,
  GOVT_ACCOUNT_DESCRIPTION,
  GOVT_PINELLAS_PLAN,
  NO_DESCRIPTION,
  PAYG_PLAN,
  PAYG_PLAN_DESCRIPTION,
  PERSONAL_PLAN,
  PERSONAL_PLAN_DESCRIPTION,
  PERSONAL_PLUS_PLAN,
  PINELLAS_PLANS_DESCRIPTION,
} from '../../types/onboarding.types';
import { IOnboardingStore } from './onboarding.store';

export const SELECT_RECOMMENDED_SUNPASS_PLAN_NAME = (accountType?: AccountType, accountSubType?: AccountSubtype) => {
  if (accountType === AccountType.PRIVATE && accountSubType === AccountSubtype.NONE) {
    return PERSONAL_PLAN;
  } else if (accountType === AccountType.PRIVATE && accountSubType === AccountSubtype.PBC) {
    return PERSONAL_PLUS_PLAN;
  } else if (accountType === AccountType.PRIVATE && accountSubType === AccountSubtype.PB) {
    return PERSONAL_PLAN;
  } else if (accountType === AccountType.GOVERNMENT && accountSubType === AccountSubtype.NONE) {
    return GOVT_ACCOUNT;
  } else if (accountType === AccountType.GOVERNMENT && accountSubType === AccountSubtype.PBC) {
    return GOVT_PINELLAS_PLAN;
  } else if (accountType === AccountType.GOVERNMENT && accountSubType === AccountSubtype.PB) {
    return GOVT_PINELLAS_PLAN;
  } else if (accountType === AccountType.COMMERCIAL && accountSubType === AccountSubtype.NONE) {
    return FLEET_PLAN;
  } else if (accountType === AccountType.COMMERCIAL && accountSubType === AccountSubtype.PB) {
    return FLEET_PINELLAS_PLAN;
  } else if (accountType === AccountType.PAYG) {
    return PAYG_PLAN;
  }
  return 'NONE';
};

export const SELECT_SUNPASS_PLAN_DESCRIPTION = ({ onboardingInfo }: IOnboardingStore): string => {
  const accountType = onboardingInfo.accountType;
  const accountSubType = onboardingInfo.accountSubtype;

  if (accountType === AccountType.PRIVATE && accountSubType === AccountSubtype.NONE) {
    return PERSONAL_PLAN_DESCRIPTION;
  } else if (accountType === AccountType.GOVERNMENT && accountSubType === AccountSubtype.NONE) {
    return GOVT_ACCOUNT_DESCRIPTION;
  } else if (accountType === AccountType.COMMERCIAL && accountSubType === AccountSubtype.NONE) {
    return FLEET_PLAN_DESCRIPTION;
  } else if (accountType === AccountType.PAYG && accountSubType === AccountSubtype.NONE) {
    return PAYG_PLAN_DESCRIPTION;
  } else if (
    accountType === AccountType.PRIVATE &&
    (accountSubType === AccountSubtype.PBC || accountSubType === AccountSubtype.PB)
  ) {
    return PINELLAS_PLANS_DESCRIPTION;
  }

  return NO_DESCRIPTION;
};

export const SELECT_DETAILS = (state: IOnboardingStore) => state.onboardingInfo;

import { Trans } from '@lingui/macro';
import React from 'react';
export const EmptyState = () => {
  return (
    <div className="flex flex-col justify-center p-6 text-center">
      <b>
        <Trans>No Notifications</Trans>
      </b>
      <div>
        <Trans>Good News! You don&apos;t have any new notifications at this time.</Trans>
      </div>
    </div>
  );
};

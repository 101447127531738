import * as yup from 'yup';
import { validators } from '../../validators';
import { AccountType } from '../../../types/onboarding.types';
import moment from 'moment/moment';
import {
  PLATE_NUMBER_REQUIRED_ERROR_MSG,
  VEHICLE_COUNTRY_REQUIRED_ERROR_MSG,
  EFFECTIVE_END_TIME_REQUIRED_ERROR_MSG,
  END_TIME_AFTER_START_TIME_ERROR_MSG,
  EFFECTIVE_START_DATE_REQUIRED_ERROR_MSG,
  EFFECTIVE_END_DATE_REQUIRED_ERROR_MSG,
  EFFECTIVE_START_TIME_REQUIRED_ERROR_MSG,
  PLATE_STATE_REQUIRED_ERROR_MSG,
  PLATE_TYPE_REQUIRED_ERROR_MSG,
  VEHICLE_MAKE_REQUIRED_ERROR_MSG,
  VEHICLE_MODEL_REQUIRED_ERROR_MSG,
  VEHICLE_REQUIRED_ERROR_MSG,
  VEHICLE_YEAR_REQUIRED_ERROR_MSG,
  EFFECTIVE_START_DATE_IN_FUTURE_ERROR_MSG,
  FIELD_IS_REQUIRED,
} from './vehicleErrorMessages';

export const getVehiclesSchemaForOnboarding = (accountType?: AccountType) => {
  return yup.object().shape({
    vehicles: yup
      .array()
      .of(
        yup.object().shape({
          plateNumber: validators.alphanumeric.required(PLATE_NUMBER_REQUIRED_ERROR_MSG),

          vehicleCountry: yup.string().required(VEHICLE_COUNTRY_REQUIRED_ERROR_MSG),

          plateState: validators.string.required(PLATE_STATE_REQUIRED_ERROR_MSG),

          isTrailer: yup.boolean().required(FIELD_IS_REQUIRED),

          isRental: yup.boolean().test('rentalRequired', FIELD_IS_REQUIRED, function (value) {
            if (accountType === AccountType.COMMERCIAL) {
              return true;
            }
            return yup.boolean().required().isValidSync(value);
          }),

          vehicleMake: yup
            .string()
            .nullable(true)
            .when('isTrailer', {
              is: (isTrailer: boolean) => !isTrailer,
              then: yup.string().required(VEHICLE_MAKE_REQUIRED_ERROR_MSG),
            }),

          vehicleModel: yup
            .string()
            .nullable(true)
            .when('isTrailer', {
              is: (isTrailer: boolean) => !isTrailer,
              then: yup.string().nullable(true).required(VEHICLE_MODEL_REQUIRED_ERROR_MSG),
            }),

          vehicleYear: yup
            .number()
            .nullable(true)
            .when('isTrailer', {
              is: (isTrailer: boolean) => !isTrailer,
              then: yup
                .number()
                .nullable(true)
                .typeError(VEHICLE_YEAR_REQUIRED_ERROR_MSG)
                .required(VEHICLE_YEAR_REQUIRED_ERROR_MSG),
            }),

          plateType: yup.string().when('notKnown', {
            is: (notKnown: boolean) => !notKnown,
            then: yup.string().required(PLATE_TYPE_REQUIRED_ERROR_MSG),
            otherwise: yup.string().nullable(),
          }),

          effectiveStartDate: validators
            .date(EFFECTIVE_START_DATE_REQUIRED_ERROR_MSG)
            .test('startDateInFuture', EFFECTIVE_START_DATE_IN_FUTURE_ERROR_MSG, function (startDate, context) {
              if (!context.parent.isRental) {
                return !moment(startDate).isAfter(moment());
              } else return true;
            })
            .required(EFFECTIVE_START_DATE_REQUIRED_ERROR_MSG),

          effectiveStartTime: yup.string().required(EFFECTIVE_START_TIME_REQUIRED_ERROR_MSG),

          effectiveEndDate: yup.date().when(['isRental', 'effectiveEndTime'], (isRental, effectiveEndTime) => {
            if (isRental) {
              return validators
                .minDate('effectiveStartDate', EFFECTIVE_END_DATE_REQUIRED_ERROR_MSG)
                .required(EFFECTIVE_END_DATE_REQUIRED_ERROR_MSG);
            } else {
              if (effectiveEndTime !== undefined && effectiveEndTime !== null) {
                return validators
                  .minDate('effectiveStartDate', EFFECTIVE_END_DATE_REQUIRED_ERROR_MSG)
                  .required(EFFECTIVE_END_DATE_REQUIRED_ERROR_MSG);
              } else {
                return validators.minDate('effectiveStartDate', '').optional();
              }
            }
          }),

          effectiveEndTime: yup
            .string()
            .test('endTimeRequiredForRental', EFFECTIVE_END_TIME_REQUIRED_ERROR_MSG, function (value) {
              const date: number = this.parent.effectiveEndDate;
              if (this.parent.isRental) {
                return validators.string.required().isValidSync(value);
              } else {
                if (this.parent.effectiveEndDate === undefined || this.parent.effectiveEndDate === null) {
                  return true;
                } else {
                  if (!(this.parent.effectiveEndDate instanceof Date) || isNaN(date)) {
                    return true;
                  } else return validators.string.required().isValidSync(value);
                }
              }
            })
            .test('minDateTime', END_TIME_AFTER_START_TIME_ERROR_MSG, function (value) {
              const { effectiveStartDate, effectiveStartTime, effectiveEndDate } = this.parent;
              const startDateTime: Date = moment(effectiveStartTime, 'HH:mm A').startOf('minute').toDate();
              if (effectiveStartDate) {
                startDateTime.setFullYear(
                  effectiveStartDate.getFullYear(),
                  effectiveStartDate.getMonth(),
                  effectiveStartDate.getDate(),
                );
              }

              if (value) {
                const endDateTime = moment(value, 'HH:mm A').startOf('minute').toDate();
                if (effectiveEndDate) {
                  endDateTime.setFullYear(
                    effectiveEndDate.getFullYear(),
                    effectiveEndDate.getMonth(),
                    effectiveEndDate.getDate(),
                  );
                }
                return startDateTime <= endDateTime;
              } else {
                return true;
              }
            }),
        }),
      )
      .required(VEHICLE_REQUIRED_ERROR_MSG),
  });
};

export const getVehicleSchemaForDashboard = (accountType?: AccountType, isEditMode?: boolean) => {
  return yup
    .object()
    .shape({
      plateNumber: validators.alphanumeric.when('notKnown', {
        is: (notKnown: boolean) => !notKnown,
        then: isEditMode
          ? yup.string().nullable()
          : validators.string.required(PLATE_NUMBER_REQUIRED_ERROR_MSG).nullable(),
        otherwise: yup.string().nullable(),
      }),

      vehicleCountry: yup.string().when('notKnown', {
        is: (notKnown: boolean) => !notKnown,
        then: isEditMode
          ? yup.string().nullable()
          : yup.string().required(VEHICLE_COUNTRY_REQUIRED_ERROR_MSG).nullable(),
        otherwise: yup.string().nullable(),
      }),

      plateState: yup.string().when('notKnown', {
        is: (notKnown: boolean) => !notKnown,
        then: isEditMode
          ? yup.string().nullable()
          : validators.string.required(PLATE_STATE_REQUIRED_ERROR_MSG).nullable(),
        otherwise: yup.string().nullable(),
      }),

      isTrailer: yup.mixed().required(FIELD_IS_REQUIRED),

      isRental: yup.mixed().test('rentalRequired', FIELD_IS_REQUIRED, function (value) {
        if (accountType === AccountType.COMMERCIAL) {
          return true;
        }
        return yup.boolean().required().isValidSync(value);
      }),

      vehicleMake: yup.string().when('isTrailer', {
        is: (isTrailer: boolean) => !isTrailer,
        then: yup.string().nullable().required(VEHICLE_MAKE_REQUIRED_ERROR_MSG),
      }),

      vehicleModel: yup.string().when('isTrailer', {
        is: (isTrailer: boolean) => !isTrailer,
        then: yup.string().nullable().required(VEHICLE_MODEL_REQUIRED_ERROR_MSG),
      }),

      vehicleYear: yup.number().when('isTrailer', {
        is: (isTrailer: boolean) => !isTrailer,
        then: yup
          .number()
          .nullable()
          .typeError(VEHICLE_YEAR_REQUIRED_ERROR_MSG)
          .required(VEHICLE_YEAR_REQUIRED_ERROR_MSG),
      }),

      plateType: yup.string().when('notKnown', {
        is: (notKnown: boolean) => !notKnown,
        then: isEditMode ? yup.string().nullable() : yup.string().required(PLATE_TYPE_REQUIRED_ERROR_MSG).nullable(),
        otherwise: yup.string().nullable(),
      }),

      effectiveStartDate: validators
        .date(EFFECTIVE_START_DATE_REQUIRED_ERROR_MSG)
        .test('startDateInFuture', EFFECTIVE_START_DATE_IN_FUTURE_ERROR_MSG, function (startDate, context) {
          if (!context.parent.isRental) {
            return !moment(startDate).isAfter(moment());
          } else return true;
        })
        .required(EFFECTIVE_START_DATE_REQUIRED_ERROR_MSG),

      effectiveStartTime: yup.string().required(EFFECTIVE_START_TIME_REQUIRED_ERROR_MSG),

      effectiveEndDate: yup.date().when(['isRental', 'effectiveEndTime'], (isRental, effectiveEndTime) => {
        if (isRental) {
          return validators
            .minDate('effectiveStartDate', EFFECTIVE_END_DATE_REQUIRED_ERROR_MSG)
            .required(EFFECTIVE_END_DATE_REQUIRED_ERROR_MSG);
        } else {
          if (effectiveEndTime !== undefined && effectiveEndTime !== null) {
            return validators
              .minDate('effectiveStartDate', EFFECTIVE_END_DATE_REQUIRED_ERROR_MSG)
              .required(EFFECTIVE_END_DATE_REQUIRED_ERROR_MSG);
          } else {
            return validators.minDate('effectiveStartDate', '').optional().nullable();
          }
        }
      }),

      effectiveEndTime: yup
        .string()
        .test('endTimeRequiredForRental', EFFECTIVE_END_TIME_REQUIRED_ERROR_MSG, function (value) {
          const date: number = this.parent.effectiveEndDate;
          if (this.parent.isRental) {
            return validators.string.required().isValidSync(value);
          } else {
            if (this.parent.effectiveEndDate === undefined || this.parent.effectiveEndDate === null) {
              return true;
            } else {
              if (!(this.parent.effectiveEndDate instanceof Date) || isNaN(date)) {
                return true;
              } else return validators.string.required().isValidSync(value);
            }
          }
        })
        .test('minDateTime', END_TIME_AFTER_START_TIME_ERROR_MSG, function (endTime) {
          const { effectiveStartDate, effectiveStartTime, effectiveEndDate } = this.parent;
          const startDateTime: Date = moment(effectiveStartTime, 'HH:mm A').startOf('minute').toDate();
          const endDateTime: Date = moment(endTime, 'HH:mm A').startOf('minute').toDate();
          if (effectiveEndDate) {
            if (moment(effectiveEndDate).isSame(effectiveStartDate, 'day')) {
              return endDateTime >= startDateTime;
            } else return moment(effectiveEndDate).isAfter(effectiveStartDate);
          } else return true;
        }),
    })
    .required(VEHICLE_REQUIRED_ERROR_MSG);
};

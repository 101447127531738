import { useQuery } from '@apollo/client';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from '@lingui/macro';
import { useEffect } from 'react';
import { ALERTS, IAlertsData } from 'src/graphql/alerts.graphql';
import { useDashboardStore } from 'src/store/dashboard/dashboard.store';
import { colors } from 'src/theme/theme';
import { MainLoader } from '../Loader/MainLoader';

const HomePageAlert = () => {
  const { setShowAlert } = useDashboardStore();

  const { data: alertsData, loading: alertsLoading } = useQuery<IAlertsData>(ALERTS);

  const alertMessage = alertsData?.alerts[0].message;

  useEffect(() => {
    if (sessionStorage.getItem('showModal') === 'false') {
      setShowAlert(false);
    }
  }, []);

  const handleClick = () => {
    setShowAlert(false);
    sessionStorage.setItem('showModal', 'false');
  };

  const content = (
    <>
      <MainLoader loading={alertsLoading} />
      <div className="alert">
        <div className="alert-main">
          <FontAwesomeIcon
            className="bell-icon"
            icon={faBell}
            color={colors.primary.gray1}
            size={'2x'}
            aria-label={t`Notifications icon`}
          />
          <div>
            <>{alertMessage}</>
          </div>
        </div>
        <button className="x-icon-wrapper" onClick={handleClick} aria-label={t`Close Alert`}>
          <FontAwesomeIcon
            className="x-icon"
            icon={faX}
            color={colors.primary.gray1}
            size={'2x'}
            aria-label={t`Notifications icon`}
          />
        </button>
      </div>
    </>
  );

  return <>{alertsData ? <div className="alert-container">{content}</div> : null}</>;
};

export default HomePageAlert;

import { useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { t, Trans } from '@lingui/macro';
import { Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { MINIMUM_AMOUNT } from 'src/graphql/minimumAmount.graphql';
import { REPLENISHMENT_AMOUNT } from 'src/graphql/replenishment-amount.graphql';
import { useRouteMonitor } from 'src/hooks/location/useRouteMonitor';
import {
  AccountType,
  getStepsByUserTypeAndFlowType,
  OnboardingStepsPayg,
  OnboardingStepsResidentActivateFlow,
} from 'src/types/onboarding.types';
import AppForm from '../../AppForm/AppForm';
import BackButton from '../../BackButton/BackButton';
import BannerCard, { InfoColumn } from '../../InfoCard/InfoCard';
import SampleBanner from '../assets/sample-banner.jpg';
import '../../../pages/Onboarding/Onboarding.scss';
import OnboardingHeader, { OnboardingHeaderWholeViewProps } from '../OnboardingHeader';
import ScrollToTop from '../../ScrollToTop/ScrollToTop';
import { FormRequiredFieldsMsg } from '../Account/FormRequiredFieldsMsg';
import { EnrollEasyPay } from './EnrollEasyPay';
import { PreloadFunds } from './PreloadFunds';
import { getEZPaySchemaForOnboarding } from '../../../validators/onboarding/ezPay/ezPay.validator';
import { INITIAL_FORM_STATE_EZ_PAY } from './initialState';
import { useOnboardingStore } from '../../../store/onboarding/onboarding.store';
import { SELECT_RECOMMENDED_SUNPASS_PLAN_NAME } from '../../../store/onboarding/onboarding.selectors';
import { useCallbackPrompt } from '../../../hooks/location/useCallbackPrompt';
import LeaveSiteConfirmationModal from '../../Modals/ConfirmationModal/LeaveSiteConfirmationModal';
import { MainLoader } from '../../Loader/MainLoader';
import { OnboardingStepPath } from '../../../types/routes';
import { useScroll } from '../../../hooks/screen/useScroll';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import { ReferenceNumber } from '../ReferenceNumber';

export interface IEZPayOnboarding {
  optInEZPay: boolean;
  recurringPaymentAmount: string;
  depositAmount: string;
  confirmDepositAmount: string;
}

export default function OnboardingEZPay(): React.ReactElement {
  const { updateOnboardingStore, onboardingInfo: store } = useOnboardingStore();
  const { userType, recurringPaymentAmount, preloadedFunds, accountType, accountSubtype, onboardingFlowType } = store;

  const history = useRouteMonitor();

  const recommendedPlan = SELECT_RECOMMENDED_SUNPASS_PLAN_NAME(accountType, accountSubtype);

  let steps =
    userType && onboardingFlowType
      ? getStepsByUserTypeAndFlowType(userType, onboardingFlowType)
      : OnboardingStepsResidentActivateFlow;
  if (accountType === 'PAYG') {
    steps = OnboardingStepsPayg;
  }

  const minimumAmounts = useQuery(MINIMUM_AMOUNT)?.data?.minimumAmount;
  const replenishmentAmounts = useQuery(REPLENISHMENT_AMOUNT)?.data?.replenishmentAmount;
  const minimumRecurringAmount =
    accountType !== AccountType.COMMERCIAL
      ? minimumAmounts?.minimumRecurringPersonal || 0
      : minimumAmounts?.minimumRecurringFleet || 0;
  const minimumDepositAmount =
    accountType !== AccountType.COMMERCIAL
      ? minimumAmounts?.minimumDepositPersonal || 0
      : minimumAmounts?.minimumDepositFleet || 0;

  const replenishmentAmount =
    accountType !== AccountType.COMMERCIAL ? replenishmentAmounts?.personal || 0 : replenishmentAmounts?.fleet || 0;

  const schema = getEZPaySchemaForOnboarding(minimumRecurringAmount, minimumDepositAmount);

  const initialValues = INITIAL_FORM_STATE_EZ_PAY(store);

  const methods = useForm<IEZPayOnboarding>({
    resolver: yupResolver(schema),
    defaultValues: initialValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const {
    handleSubmit,
    setValue,
    formState: { isDirty, isSubmitSuccessful, isSubmitted, errors },
  } = methods;

  const [showRedirectPrompt, setShowRedirectPrompt] = useState(false);
  const { cancelRedirect, confirmRedirect, showPrompt } = useCallbackPrompt(showRedirectPrompt);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (isDirty && !isSubmitSuccessful && !isSubmitted && !formSubmitted) {
      setShowRedirectPrompt(true);
    } else {
      setShowRedirectPrompt(false);
    }
  }, [isDirty, isSubmitted, isSubmitSuccessful, formSubmitted]);

  useEffect(() => {
    if (!minimumAmounts) {
      return;
    } else {
      setValue('recurringPaymentAmount', recurringPaymentAmount || minimumRecurringAmount.toFixed(2));
      setValue('depositAmount', preloadedFunds || minimumDepositAmount.toFixed(2));
      setValue('confirmDepositAmount', preloadedFunds || minimumDepositAmount.toFixed(2));
    }
  }, [minimumAmounts]);

  function onSubmit(data: any): void {
    setLoading(true);
    const { optInEZPay } = data;
    if (optInEZPay) {
      const { recurringPaymentAmount, sunpassPlusParking } = data;
      updateOnboardingStore({
        easyPay: { optInEZPay: optInEZPay },
        recurringPaymentAmount: parseFloat(recurringPaymentAmount),
        sunpassPlusParking,
        preloadedFunds: parseFloat(data.depositAmount),
      });
    } else {
      updateOnboardingStore({
        easyPay: { optInEZPay: optInEZPay },
        preloadedFunds: parseFloat(data.depositAmount),
      });
    }
    setFormSubmitted(true);
  }

  useEffect(() => {
    if (isSubmitted && isSubmitSuccessful && formSubmitted && !showRedirectPrompt) {
      setLoading(false);
      history.goToPath(OnboardingStepPath.PAYMENT_SUMMARY);
    }
  }, [isSubmitted, isSubmitSuccessful, formSubmitted, showRedirectPrompt]);

  const { scrollToError } = useScroll();

  useEffect(() => {
    scrollToError(errors);
  }, [errors]);

  const infoCols = [
    new InfoColumn(
      (
        <h3 className="mb-6 text-primary-blue1">
          <Trans>About EasyPay</Trans>
        </h3>
      ),
      (
        <div>
          <div className="mb-3 font-bold">
            <Trans>
              Pick a date you would like funds added to your account, set an amount, and let us worry about the rest!
            </Trans>
          </div>
          <div>
            {t`When a`} {recommendedPlan} {t`reaches a pre-set low balance threshold (minimum/default is`} $
            {`${replenishmentAmount?.toFixed(2)}`}
            {t`, it is automatically funded with a pre-set amount via credit card, as determined at the time of
              enrollment.`}
          </div>
        </div>
      ),
    ),
    new InfoColumn(
      <h4 className="mb-6 text-primary-blue1">Benefits</h4>,
      (
        <ul className="ml-5.5 list-disc text-xl text-primary-green1">
          <li className="mb-2">
            <span className="text-base leading-7 text-primary-black">
              <Trans>No stress, hassle, or unpaid fines</Trans>
            </span>
          </li>
          <li className="mb-2">
            <span className="text-base leading-7 text-primary-black">
              <Trans>Automatically withdrawn from your account, on your schedule, at your convenience</Trans>
            </span>
          </li>
          <li className="mb-2">
            <span className="text-base leading-7 text-primary-black">
              <Trans>Adjustable to your toll usage levels and frequency of travel</Trans>
            </span>
          </li>
        </ul>
      ),
    ),
  ];

  return (
    <div className="h-full">
      <ScrollToTop />
      <MainLoader loading={loading} />
      <OnboardingHeader<OnboardingHeaderWholeViewProps>
        title={t`Opt into EasyPay and pre-load funds today!`}
        steps={steps}
        currentStepIndex={accountType === AccountType.PAYG ? 3 : 4}
        planName={recommendedPlan}
      />

      <AppForm layout={'vertical'} size={'large'} onFinish={handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <ReferenceNumber />
          <PreloadFunds minimumDepositAmount={minimumDepositAmount} />
          <BannerCard bannerSrc={SampleBanner} bannerAlt={'A Sample Expressway'} infoCols={infoCols}></BannerCard>

          <EnrollEasyPay minimumRecurringAmount={minimumRecurringAmount} />

          <FormRequiredFieldsMsg />

          <Row className="onboarding-button-container grid grid-cols-3 items-center">
            <BackButton></BackButton>
            <CustomButton content="Continue" type="submit" />
          </Row>
          <LeaveSiteConfirmationModal showModal={showPrompt} onOk={confirmRedirect} onCancel={cancelRedirect} />
        </FormProvider>
      </AppForm>
    </div>
  );
}

import useTransponderPrices from '../../../hooks/domain/dashboard/transponders/useTransponderPrices';
import { useOnboardingStore } from '../../../store/onboarding/onboarding.store';
import { SELECT_DETAILS } from '../../../store/onboarding/onboarding.selectors';
import { useMultiStepPaymentStore } from '../../../store/multiStepPayment/multiStepPayment.store';
import { useMemo } from 'react';
import { ITransponderQuantity } from './TransponderSelectCard';

interface IUsePurchaseTransponderInputContentReturnType {
  subTotal: number;
  miniTransponderQty: ITransponderQuantity;
  proTransponderQty: ITransponderQuantity;
}

export const usePurchaseTransponderInputContent = (
  isDashboard: boolean,
  isOptInPBCMini: boolean,
  isOptInPBCPro: boolean,
  isPBCAccount: boolean,
): IUsePurchaseTransponderInputContentReturnType => {
  const { miniPrice, proPrice, PBCPrice } = useTransponderPrices();
  const { miniTransponderQty, miniTransponderQtyAssignedToPlan, proTransponderQty, proTransponderQtyAssignedToPlan } =
    useOnboardingStore(SELECT_DETAILS);
  const {
    paymentInfo: { numOfProTransponders, numOfStickerTransponders },
  } = useMultiStepPaymentStore();

  const subTotal = useMemo(() => {
    if (!isDashboard) {
      if (isPBCAccount) {
        return PBCPrice * miniTransponderQty;
      } else {
        const proFinalPrice = isOptInPBCPro
          ? PBCPrice * proTransponderQtyAssignedToPlan + proPrice * proTransponderQty
          : proPrice * proTransponderQty;
        const miniFinalPrice = isOptInPBCMini
          ? PBCPrice * miniTransponderQtyAssignedToPlan + miniPrice * miniTransponderQty
          : miniPrice * miniTransponderQty;
        return miniFinalPrice + proFinalPrice;
      }
    } else {
      return proPrice * numOfProTransponders + miniPrice * numOfStickerTransponders;
    }
  }, [
    miniTransponderQty,
    proTransponderQty,
    miniTransponderQtyAssignedToPlan,
    proTransponderQtyAssignedToPlan,
    numOfProTransponders,
    numOfStickerTransponders,
    miniPrice,
    proPrice,
    isOptInPBCPro,
    isOptInPBCMini,
  ]);

  return {
    subTotal,
    miniTransponderQty: {
      regular: !isDashboard ? miniTransponderQty : numOfStickerTransponders,
      assignedToPlan: !isDashboard ? miniTransponderQtyAssignedToPlan : undefined,
    },
    proTransponderQty: {
      regular: !isDashboard ? proTransponderQty : numOfProTransponders,
      assignedToPlan: !isDashboard ? proTransponderQtyAssignedToPlan : undefined,
    },
  };
};

import { t, Trans } from '@lingui/macro';
import { Form, Input } from 'antd';
import { FormProvider } from 'antd/lib/form/context';
import { Controller, useForm } from 'react-hook-form';
import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTransponderStore } from '../../../store/dashboard/transponder.store';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import { ITransponderList, IUpdateTransponderInput, UPDATE_TRANSPONDER } from '../../../graphql/transponders.graphql';
import { MainLoader } from '../../Loader/MainLoader';
import { useSubmitEffects } from '../../../hooks/effect/useSubmitEffects';
import './Transponder.scss';
import ScrollToTop from '../../ScrollToTop/ScrollToTop';
import { capitalizeInitials } from '../../../utils/string.utils';
import LeaveSiteConfirmationModal from 'src/components/Modals/ConfirmationModal/LeaveSiteConfirmationModal';
import { useCallbackPrompt } from 'src/hooks/location/useCallbackPrompt';
import { InfoLink } from 'src/components/Links/InfoLink';
import { showMessage } from 'src/utils/message.utils';
import { CustomButton } from 'src/components/CustomButton/CustomButton';

export interface TransponderEditForm {
  friendlyName: string;
}

interface ITransponderEditProps {
  refetch: () => Promise<ApolloQueryResult<{ transponderList: ITransponderList }>>;
}

export const TransponderEdit = ({ refetch }: ITransponderEditProps) => {
  const [showRedirectPrompt, setShowRedirectPrompt] = useState(false);

  const { cancelRedirect, confirmRedirect, showPrompt } = useCallbackPrompt(showRedirectPrompt);

  const {
    store: { transponderToEdit },
    setTransponderToEdit,
    setEditMode,
    setIsDeactivationModalOpen,
  } = useTransponderStore();

  const methods = useForm<TransponderEditForm>({
    mode: 'onChange',
    defaultValues: {
      friendlyName: transponderToEdit?.friendlyName || '',
    },
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful, isSubmitted },
  } = methods;

  const [updateTransponder, { loading: updateLoading }] = useMutation<
    { updateTransponder: boolean },
    { updateTransponderInput: IUpdateTransponderInput }
  >(UPDATE_TRANSPONDER);

  const onSubmit = async (data: TransponderEditForm) => {
    try {
      if (transponderToEdit) {
        await updateTransponder({
          variables: {
            updateTransponderInput: {
              friendlyName: data.friendlyName,
              transponderNumber: transponderToEdit?.transponderNumber,
            },
          },
        });
        await refetch();
      }
      showMessage('success');
    } catch (err) {
      showMessage('error');
      console.log(err);
    }
  };

  const closeEdit = () => {
    setEditMode(false);
    setTransponderToEdit(undefined);
  };

  useSubmitEffects({ isSubmitExecuted: isSubmitted && isSubmitSuccessful, effect: closeEdit });

  const refToElem = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (refToElem && refToElem.current) {
      refToElem.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, []);

  useEffect(() => {
    if (transponderToEdit) {
      setShowRedirectPrompt(true);
    } else {
      setShowRedirectPrompt(false);
    }
  }, [transponderToEdit]);

  const confirmRedirectUser = () => {
    reset();
    confirmRedirect();
  };

  const generateTransponderEditFormHeadline = useCallback(() => {
    return ` ${transponderToEdit?.friendlyName || ''} ${
      transponderToEdit?.transponderType ? capitalizeInitials(transponderToEdit?.transponderType) : ''
    } ${t`Transponder`} (#${transponderToEdit?.transponderNumber})`;
  }, [transponderToEdit]);

  return (
    <>
      <MainLoader loading={updateLoading} />
      <ScrollToTop blockInitial triggerValues={isSubmitSuccessful && isSubmitted} />
      <div className="mx-auto flex w-full justify-center  rounded-lg bg-white p-6 shadow-lg sm:w-9/12" ref={refToElem}>
        <div className="max-w-sm">
          <h1 className="mb-5 text-xl">
            <Trans>Editing</Trans>
            {generateTransponderEditFormHeadline()}
          </h1>
          <div>
            <h1 className="mb-12  inline-block text-3xl text-primary-blue1 sm:mb-auto">
              <Trans>Transponder Information</Trans>
            </h1>
            <InfoLink onClick={() => setIsDeactivationModalOpen(true)}>
              <Trans>Report Lost, Stolen, or Defective</Trans>
            </InfoLink>
          </div>
          <Form onFinish={handleSubmit(onSubmit)}>
            <FormProvider {...methods}>
              <div className="md:flex md:space-x-16 ">
                <div className="w-full md:flex md:space-x-16">
                  <Form.Item className="w-full">
                    <label className="form-label">
                      <Trans>Friendly Name</Trans>
                    </label>
                    <Controller
                      control={control}
                      name="friendlyName"
                      render={({ field, fieldState }) => (
                        <Input
                          {...field}
                          className={classNames({
                            'input-error': fieldState.invalid,
                            'transponder-name': true,
                          })}
                          onChange={field.onChange}
                          name="friendlyName"
                          key="friendlyName"
                          maxLength={30}
                          aria-label={t`friendly name`}
                        />
                      )}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="mx-0 mt-1 flex w-full flex-col justify-center sm:mx-auto sm:mt-7 sm:w-auto sm:flex-row">
                <CustomButton
                  onClick={closeEdit}
                  className="mx-0 mb-5 mt-2 sm:mx-3 sm:mb-0"
                  content="Cancel"
                  type={'reset'}
                  isPrimary={false}
                />
                <CustomButton className="px-15 mx-0 mt-2 sm:mx-3" content="Save Changes" type={'submit'} />
              </div>
            </FormProvider>
          </Form>
          <LeaveSiteConfirmationModal showModal={showPrompt} onOk={confirmRedirectUser} onCancel={cancelRedirect} />
        </div>
      </div>
    </>
  );
};

import { useLinkedAccounts } from '../../../../hooks/useLinkedAccounts';
import { MainLoader } from '../../../Loader/MainLoader';
import { Trans } from '@lingui/macro';
import React from 'react';
import { ChildAccountItem } from './ChildAccountItem';
import { LinkedAccountSubSectionHeadline } from '../helpers/LinkedAccountSubSectionHeadline';
import { EmptyState } from '../helpers/EmptyState';
import { ILinkedAccount, LinkedAccountStatus } from '../../../../graphql/linkedAccounts.graphql';

export const ChildActiveAccounts = () => {
  const { loading, childAccounts } = useLinkedAccounts();
  const compareChildAccounts = ({ status: aStatus }: ILinkedAccount, { status: bStatus }: ILinkedAccount): number => {
    if (aStatus == bStatus) return 0;
    return aStatus === LinkedAccountStatus.ACTIVE && bStatus === LinkedAccountStatus.OPEN ? 1 : -1;
  };
  return (
    <div className="mt-3">
      <MainLoader loading={loading} />
      <LinkedAccountSubSectionHeadline name={<Trans>You have been granted access to the following accounts:</Trans>} />
      {childAccounts?.length ? (
        childAccounts
          ?.sort(compareChildAccounts)
          ?.map((account, index) => <ChildAccountItem key={index} account={account} />)
      ) : (
        <EmptyState />
      )}
    </div>
  );
};

import { FC } from 'react';
import { ApolloQueryResult, useMutation } from '@apollo/client';
import { DELETE_VEHICLE, IVehicleList, IVehicleListInput } from 'src/graphql/vehicles.graphql';
import VehiclesListHeader from './VehiclesListHeader';
import VehicleItemBigScreen from './VehicleItemBigScreen';
import useScreenSize from 'src/hooks/screen/useScreenSize';
import VehicleItemSmallScreen from './VehicleItemSmallScreen';
import ConfirmationModal from 'src/components/Modals/ConfirmationModal/ConfirmationModal';
import { t } from '@lingui/macro';
import { emptyVehicle, useVehicleStore } from 'src/store/vehicles/vehicle.store';
import { MainLoader } from 'src/components/Loader/MainLoader';
import { renderInformation } from './stringRenderers';
import Pagination from 'src/components/Pagination/Pagination';
import { usePaginationInfo } from 'src/store/pagination/pagination.store';
import EmptyStateMessage, { IListType } from 'src/components/EmptyStates/EmptyStateMessage';

interface IVehiclesProps {
  vehiclesData: { vehicleList: IVehicleList } | undefined;
  refetch: (
    variables?: Partial<{ vehicleListInput: IVehicleListInput }> | undefined,
  ) => Promise<ApolloQueryResult<{ vehicleList: IVehicleList }>>;
}

const VehiclesList: FC<IVehiclesProps> = ({ vehiclesData, refetch }) => {
  const {
    vehiclesPagination: { size, page },
    areVehiclesFiltersApplied,
  } = usePaginationInfo();

  const [deleteVehicle, { loading: deleteVehicleLoading }] = useMutation<{ deleteVehicle: boolean; vehicleId: string }>(
    DELETE_VEHICLE,
  );

  const { vehicleList } = vehiclesData || {};

  const {
    store: { vehicleToDelete, isDeleteModalVisible, refetchVehicleList },
    setIsDeleteModalVisible,
    setIsFormVisible,
    setVehicleToDelete,
    setIsVehicleListChanged,
    triggerVehicleListRefetch,
  } = useVehicleStore();

  const { vehicleId, plateNumber } = vehicleToDelete;

  const deleteMethod = async (vehicleId: string) => {
    try {
      await deleteVehicle({ variables: { vehicleId } });
      await refetch({
        vehicleListInput: {
          pagination: {
            pageSize: size,
            pageNumber: page,
          },
        },
      });
      triggerVehicleListRefetch(false);
    } catch (err) {
      triggerVehicleListRefetch(true);
      console.log((err as Error).message);
    }
    setVehicleToDelete(emptyVehicle);
    setIsDeleteModalVisible(false);
    setIsFormVisible(false);
    setIsVehicleListChanged(true);
    scrollTo({ top: 0, behavior: 'smooth' });
  };

  const cancelMethod = () => {
    setVehicleToDelete(emptyVehicle);
    setIsDeleteModalVisible(false);
  };

  const { screenSize } = useScreenSize();

  const totalCount = vehiclesData?.vehicleList.totalElements || 0;

  return (
    <>
      <MainLoader loading={deleteVehicleLoading} />
      {vehicleList?.vehicles.length && !refetchVehicleList ? (
        <div className="pt-4">
          <VehiclesListHeader />
          <ul id="vehicles-list">
            {vehicleList?.vehicles.map((vehicle) => {
              return (
                <li
                  key={vehicle.vehicleId}
                  className={`mb-3 flex justify-between rounded-lg bg-white shadow-lg ${
                    screenSize > 1 ? 'cursor-pointer flex-row px-8 py-6' : 'flex-col px-6 py-4 sm:flex-row'
                  }`}
                >
                  {screenSize > 1 ? (
                    <VehicleItemBigScreen key={vehicle.vehicleId} vehicle={vehicle} />
                  ) : (
                    <VehicleItemSmallScreen key={vehicle.vehicleId} vehicle={vehicle} />
                  )}
                </li>
              );
            })}
          </ul>
          <ConfirmationModal
            onOk={() => deleteMethod(vehicleId)}
            onCancel={cancelMethod}
            showModal={isDeleteModalVisible}
            cancelButtonText={t`Go Back`}
            okButtonText={t`Confirm`}
            title={`${plateNumber} ${renderInformation(vehicleToDelete)}`}
            content={t`Please confirm that you would like to remove this vehicle from your SunPass account.`}
            alignButtons="center"
            alignText="center"
          />
          {vehicleList?.totalPages >= 2 ? <Pagination count={totalCount} type={'vehicles'} /> : null}
        </div>
      ) : (
        <div className="mt-6">
          <EmptyStateMessage listType={IListType.VEHICLES} areFiltersApplied={areVehiclesFiltersApplied} />
        </div>
      )}
    </>
  );
};

export default VehiclesList;

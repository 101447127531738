import { Form, Select } from 'antd';
import { t, Trans } from '@lingui/macro';
import { Controller, useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import React from 'react';
import { LabelValue } from '../../../../types/LabelValue.interface';
import { useAddressFields } from '../../../../hooks/domain/address/useAddressFields';
import useStateOptions from '../../../../hooks/domain/address/useStateOptions';
import { IAddressFormElemBaseProps } from './address.types';

const { Option } = Select;
export const State = ({ isAddressUsedForPayment, disabled, className }: IAddressFormElemBaseProps) => {
  const {
    addressFieldsState: { stateField, countryField, countyField },
  } = useAddressFields(isAddressUsedForPayment);
  const { control, trigger, watch } = useFormContext();
  const countryWatch = watch(countryField.name);
  const { stateOptions } = useStateOptions({
    country: countryWatch as string,
  });
  return (
    <div className={className}>
      <Form.Item
        label={<Trans>State/Province</Trans>}
        hasFeedback
        validateStatus={stateField.errorMsg ? 'error' : ''}
        help={stateField.errorMsg}
        required
        className="theme"
      >
        <Controller
          name={stateField.name}
          control={control}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              disabled={disabled}
              key={stateField.name}
              id={stateField.name}
              className={classNames({ 'input-error': fieldState.invalid })}
              onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                field.onChange(e);
                await trigger(countyField.name);
              }}
              optionFilterProp="children"
              aria-label={t`State/Province`}
              showSearch
            >
              {stateOptions?.map((state: LabelValue) => (
                <Option key={state.value} value={state.value} label={state.label}>
                  {state.label}
                </Option>
              ))}
            </Select>
          )}
        />
      </Form.Item>
    </div>
  );
};

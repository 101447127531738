import { Form } from 'antd';
import { t, Trans } from '@lingui/macro';
import { Controller, useFormContext } from 'react-hook-form';
import StyledInput from '../../../StyledInput/StyledInput';
import React, { useEffect } from 'react';
import { useAddressFields } from '../../../../hooks/domain/address/useAddressFields';
import { IAddressFormElemBaseProps } from './address.types';
import useErrorHandling from '../../../../hooks/errors/useErrorHandling';
import { IMailingAddress } from '../../../../types/onboarding.types';
import { ERROR_CODE } from '../../../../types/errors.enum';
import { useEffectUnderCondition } from '../../../../hooks/effect/useEffectUnderCondition';

export const PostalCode = ({
  isAddressUsedForPayment,
  disabled,
  className,
  isInHomePage,
}: IAddressFormElemBaseProps) => {
  const {
    addressFieldsState: { postalCodeField },
  } = useAddressFields(isAddressUsedForPayment);

  const {
    watch,
    setError,
    trigger,
    formState: { errors },
  } = useFormContext<IMailingAddress>();

  const { error } = useErrorHandling();

  useEffect(() => {
    if (error?.code === ERROR_CODE.INVALID_POSTAL_CODE) {
      setError(
        'postalCode',
        {
          message: error?.message,
        },
        {
          shouldFocus: true,
        },
      );
    }
  }, [error, error?.code, error?.message, errors]);

  useEffectUnderCondition(
    !!errors?.postalCode?.message,
    () => {
      trigger('postalCode');
    },
    [errors, error?.code, watch('postalCode')],
  );

  const { control } = useFormContext();
  return (
    <div className={className}>
      <Form.Item
        className="theme"
        label={<Trans>Postal Code</Trans>}
        help={postalCodeField.errorMsg}
        required={!isInHomePage}
        validateTrigger="onChange"
      >
        <Controller
          name={postalCodeField.name}
          control={control}
          render={({ field, fieldState }) => (
            <StyledInput
              disabled={disabled}
              field={field}
              fieldstate={fieldState}
              id={postalCodeField.name}
              key={postalCodeField.name}
              maxLength={20}
              aria-label={t`Postal code`}
            />
          )}
        />
      </Form.Item>
    </div>
  );
};

import { MyAccountParams } from 'src/types/routes';
import { create } from '../createStore';
import { devtools } from 'zustand/middleware';

export interface IAccountSettingsStore {
  activeTab: string | MyAccountParams;
  tabToChangeTo: string;
  setTabToChangeTo: (key: string | MyAccountParams) => void;
  setActiveTab: (key: string | MyAccountParams) => void;
  isTabDirty: boolean;
  setIsTabDirty: (isDirty: boolean) => void;
  isConfiramtionModalShown: boolean;
  setShowConfirmationModal: (isConfiramtionModalShown: boolean) => void;
}

export const useAccountSettings = create<
  IAccountSettingsStore,
  [['zustand/devtools', IAccountSettingsStore], ['zustand/persist', IAccountSettingsStore]]
>(
  devtools((set) => ({
    tabToChangeTo: '0',
    activeTab: MyAccountParams.CONTACT_INFO,
    isTabDirty: false,
    isConfiramtionModalShown: false,
    setShowConfirmationModal: (isConfiramtionModalShown) => {
      set(() => ({
        isConfiramtionModalShown: isConfiramtionModalShown,
      }));
    },
    setTabToChangeTo: (key) => {
      set(() => ({
        tabToChangeTo: key,
      }));
    },
    setIsTabDirty: (isDirty) => {
      set(() => ({
        isTabDirty: isDirty,
      }));
    },
    setActiveTab: (key) => {
      set(() => ({
        activeTab: key,
      }));
    },
  })),
);

import PostalCode from '../../../components/ForgetUsernameOrPassword/PostalCode';
import AccountNumber from '../../../components/ForgetUsernameOrPassword/AccountNumber';
import { Trans } from '@lingui/macro';
import TransponderNumber from '../../../components/ForgetUsernameOrPassword/TransponderNumber';
import { ActionType } from '../../../components/ForgetUsernameOrPassword/ForgotUsernameOrPassword';
import React from 'react';
import { IInputElemDefaultProp } from '../../../components/atoms/form/form.types';

export const AccessOnlineFormFirstStepForm = ({ disabled }: IInputElemDefaultProp) => {
  return (
    <>
      <div className="mb-4 w-full sm:w-1/3">
        <PostalCode disabled={disabled} />
      </div>
      <div className="flex flex-col items-center gap-4 sm:flex-row sm:items-stretch">
        <div className="w-full sm:w-1/3">
          <AccountNumber disabled={disabled} />
        </div>

        <div className="sm:mt-10">
          - <Trans>OR</Trans> -
        </div>

        <div className="w-full sm:w-1/3">
          <TransponderNumber actionType={ActionType.RETRIEVE_USERNAME} />
        </div>
      </div>
    </>
  );
};

import React, { FC } from 'react';
import { t, Trans } from '@lingui/macro';
import ConfirmationModal from 'src/components/Modals/ConfirmationModal/ConfirmationModal';

interface OptOutModal {
  showModal: boolean;
  onOk: () => void;
}

const OptOutModal: FC<OptOutModal> = ({ showModal, onOk }) => {
  const okButtonText = t`Okay`;

  const content = (
    <div className="pt-10">
      <Trans>
        If you&apos;d like to opt out of the SunPass Plus Parking Plan, you must select which transponder(s) you&apos;d
        like to remove the plan from.
      </Trans>
    </div>
  );

  return (
    <>
      {showModal && (
        <ConfirmationModal
          onOk={onOk}
          showModal={showModal}
          okButtonText={okButtonText}
          content={content}
          closable={true}
          alignButtons="center"
          alignText="center"
        />
      )}
    </>
  );
};
export default OptOutModal;

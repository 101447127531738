import { Trans } from '@lingui/macro';
import { PendingRequestItem } from './PendingRequestItem';
import React from 'react';
import { useLinkedAccounts } from '../../../../hooks/useLinkedAccounts';
import { MainLoader } from '../../../Loader/MainLoader';
import { LinkedAccountSubSectionHeadline } from '../helpers/LinkedAccountSubSectionHeadline';
import { EmptyState } from '../helpers/EmptyState';

export const PendingRequests = () => {
  const { pendingAccountRequests, loading } = useLinkedAccounts();
  return (
    <div className="mt-6">
      <MainLoader loading={loading} />
      <LinkedAccountSubSectionHeadline name={<Trans>Pending Requests</Trans>} />
      {pendingAccountRequests?.length ? (
        pendingAccountRequests.map((account, index) => <PendingRequestItem key={index} account={account} />)
      ) : (
        <EmptyState />
      )}
    </div>
  );
};

import React from 'react';
import { t, Trans } from '@lingui/macro';
import './SunPassPlusParking.scss';
import { useNavigate } from 'react-router';
import { MainRoutes } from '../../../../types/routes';

export const SunPassPlusParking = () => {
  const history = useNavigate();
  return (
    <>
      <div className="sunpass-parking_container w-full">
        <div className="flex flex-col lg:flex-row">
          <div className="mr-5">
            <div className=" mb-2 text-left font-semibold text-primary-blue1">
              <Trans>Welcome to SunPass Plus Parking!</Trans>
            </div>
            <p className="text-left">
              {t`Easy Pay automatically provides you the convenience of using your transponder to pay for parking at most of Florida's major airports, Port Canaveral, and the Hard Rock Stadium in Miami. Only Easy Pay customers whose primary means of automatic replenishment is a credit or debit card are eligible for SunPass Plus parking.`}
            </p>
            <p className="text-left">
              <Trans>
                {' '}
                To learn more about SunPass Plus Parking and manage your plan, head on over the
                <button
                  className="ml-1 mr-1 text-primary-green1 underline"
                  onClick={() => history(MainRoutes.MY_ACCOUNT)}
                >
                  SunPass Plus Parking
                </button>
                page in your account settings.{' '}
              </Trans>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

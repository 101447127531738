import { useMutation, useQuery } from '@apollo/client';
import { Row } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import BackButton from 'src/components/BackButton/BackButton';
import {
  getAccountPlansInputFrom,
  ISignUpPlansInput,
  ISignUpPlansOutput,
  SIGN_UP_ACCOUNT_PLANS,
} from 'src/graphql/signUp/signUpPlans.graphql';
import { useRecaptcha } from 'src/hooks/recaptcha/useRecaptcha';
import { useRouteMonitor } from 'src/hooks/location/useRouteMonitor';
import { useOnboardingStore } from 'src/store/onboarding/onboarding.store';
import { OnboardingStepPath } from 'src/types/routes';
import NewPaymentMethodContent from '../PaymentMethods/NewPaymentMethodContent';
import { onboardingDefaultValues } from '../NewPaymentMethodsDefaultValues';
import { IPaymentMethod } from '../Payments.interfaces';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import {
  IPlansTransponderActivationSignUp,
  ISignUpTransponderActivationInput,
  ISignUpTransponderActivationOutput,
  SIGN_UP_TRANSPONDER_ACTIVATION,
} from '../../../graphql/signUp/signUpTransponderActivation.graphql';
import { TransponderType } from '../../../graphql/transponders.graphql';
import { EasyPayOption } from '../../../graphql/payment.graphql';
import {
  ISignUpAccountConfirmationInput,
  ISignUpAccountConfirmationOutput,
  SIGN_UP_CONFIRMATION,
} from '../../../graphql/signUp/signUpConfirmation.graphql';
import { APPLY_PROMO_CODE, IApplyPromoCodeInput } from '../../../graphql/applyPromoCodeAccount.graphql';
import { showMessage } from '../../../utils/message.utils';
import { t } from '@lingui/macro';
import { IInvoiceDetailOutput, INVOICE_PAYMENT } from '../../../graphql/lookupWithInvoice.graphql';
import { newApolloClient } from '../../../security/CustomApolloProvider';
import { getInvoiceToken } from '../../../security/token.service';
import { mapInvoicePaymentInput } from './mapper';
import { GET_CREDIT_CARD_TYPES } from '../../../graphql/creditCardTypes.graphql';
import { useOnboardingErrors } from 'src/store/errors/onboardingErrors.store';

interface NewPaymentMethodOnboardingWrapperProps {
  promoCode?: string;
  isOptInEasyPay?: boolean;
  isInHomePage?: boolean;
  invoiceDetails?: IInvoiceDetailOutput;
  isPromoCodeInvalid?: boolean;
  totalDueToday?: number;
}

const NewPaymentMethodOnboardingWrapper = ({
  promoCode,
  isOptInEasyPay,
  isInHomePage,
  invoiceDetails,
  isPromoCodeInvalid,
  totalDueToday,
}: NewPaymentMethodOnboardingWrapperProps) => {
  const history = useRouteMonitor();
  const {
    onboardingInfo,
    onboardingInfo: {
      paymentMethod,
      hasTranspondersToActivate,
      proTransponderQtyAssignedToPlan,
      miniTransponderQtyAssignedToPlan,
      accountId,
      transponderSerialNumbers,
      accountInfo,
      totalTranspondersCost,
      recurringPaymentAmount,
      replenishmentThresholdAmount,
    },
    updateOnboardingPaymentMethod,
  } = useOnboardingStore();

  const { onboardingState } = useOnboardingErrors();

  const [accountPlansSignUp, { loading: signUpLoading }] = useMutation<
    { signUpPlans: ISignUpPlansOutput },
    { signUpPlansInput: ISignUpPlansInput }
  >(SIGN_UP_ACCOUNT_PLANS);

  const [activateTransponders, { loading: activationTranspondersLoading }] = useMutation<
    { signUpTransponderActivation: ISignUpTransponderActivationOutput },
    { signUpTransponderActivationInput: ISignUpTransponderActivationInput }
  >(SIGN_UP_TRANSPONDER_ACTIVATION);

  const [onboardingConfirmation, { loading: onboardingConfirmationLoading }] = useMutation<
    { signUpAccountConfirmation: ISignUpAccountConfirmationOutput },
    { signUpAccountConfirmationInput: ISignUpAccountConfirmationInput }
  >(SIGN_UP_CONFIRMATION);

  const [applyPromoCode] = useMutation<{ applyPromoCodeInput: IApplyPromoCodeInput }>(APPLY_PROMO_CODE);
  const [isCardSaved, setIsCardSaved] = useState(false);
  const { data } = useQuery<{ creditCardTypes: string[] }>(GET_CREDIT_CARD_TYPES);
  const payInvoice = async (paymentMethod: IPaymentMethod) => {
    const token = getInvoiceToken();
    const input = mapInvoicePaymentInput(paymentMethod, invoiceDetails, data?.creditCardTypes);
    const { data: invoicePaymentData } = await newApolloClient.mutate({
      mutation: INVOICE_PAYMENT,
      variables: {
        invoicePaymentInput: input,
      },
      context: {
        headers: {
          Authorization: token ? `Bearer ${token}` : '',
        },
      },
    });
    console.log(invoicePaymentData);
  };

  const onSubmit = async (paymentMethod: IPaymentMethod) => {
    if (isInHomePage) {
      await payInvoice(paymentMethod);
    } else {
      updateOnboardingPaymentMethod(paymentMethod);
      const signUpPlansInput = getAccountPlansInputFrom(paymentMethod, onboardingInfo);
      const { paymentDetails, planList } = signUpPlansInput;
      if (hasTranspondersToActivate) {
        const plansSignUpInput: IPlansTransponderActivationSignUp = {
          paymentDetails,
          saveCard: isOptInEasyPay ? 'EASYPAY' : isCardSaved ? 'STOREDCARD' : 'NONE',
          planList: [
            ...(proTransponderQtyAssignedToPlan
              ? [
                  {
                    transponderType: TransponderType.PRO,
                    quantity: proTransponderQtyAssignedToPlan,
                  },
                ]
              : []),

            ...(miniTransponderQtyAssignedToPlan
              ? [
                  {
                    transponderType: TransponderType.MINI,
                    quantity: miniTransponderQtyAssignedToPlan,
                  },
                ]
              : []),
          ],
          reBillThresholdAmount: 10,
        };

        const singUpInput = {
          ...onboardingState,
          plansSignUp: plansSignUpInput,
          transactionTotalAmount: totalDueToday,
          prepaidAmount: recurringPaymentAmount,
          reBillThresholdAmount: replenishmentThresholdAmount,
        };
        console.log(singUpInput);
        await activateTransponders({
          variables: {
            signUpTransponderActivationInput: singUpInput,
          },
        });
      } else {
        const plansSignUpResponse = await accountPlansSignUp({
          variables: { signUpPlansInput },
        });
        const confirmationResponse = await onboardingConfirmation({
          variables: {
            signUpAccountConfirmationInput: {
              accountId,
              plansSignUp: {
                planList: planList,
                miniTransponderSerialNumber: transponderSerialNumbers.miniTransponderSerialNumber,
                proTransponderSerialNumber: transponderSerialNumbers.proTransponderSerialNumber,
                saveCard: isOptInEasyPay
                  ? EasyPayOption.EASY_PAY
                  : isCardSaved
                  ? EasyPayOption.STORED_CARD
                  : EasyPayOption.NONE,
                paymentDetails: paymentDetails,
              },
              signUpPassword: accountInfo?.password,
              totalTranspondersCost: Number(totalTranspondersCost.toFixed(2)),
            },
          },
        });
        if (
          plansSignUpResponse?.data?.signUpPlans.accountId &&
          confirmationResponse?.data?.signUpAccountConfirmation.accountNumber
        ) {
          if (!!promoCode && promoCode !== '') {
            try {
              await applyPromoCode({ variables: { couponCode: promoCode } });
              showMessage(
                'success',
                t`Your credit was applied successfully and your account balance will be adjusted accordingly`,
              );
            } catch (err) {
              console.error(err);
            }
          }
        }
      }
      history.goToPath(OnboardingStepPath.SUCCESS);
    }
  };

  const {
    recaptchaChallengeElem,
    loading: recaptchaLoading,
    submitWithRecaptcha,
  } = useRecaptcha<IPaymentMethod>({ onSubmit });

  const isLoading = recaptchaLoading || signUpLoading || activationTranspondersLoading || onboardingConfirmationLoading;

  const navigationElement: JSX.Element = (
    <>
      <Row className="onboarding-button-container flex justify-center">
        <BackButton content={isInHomePage ? 'Cancel' : 'Back'} />
        <CustomButton
          type="submit"
          disabled={isPromoCodeInvalid}
          content={isInHomePage ? 'Submit Payment' : 'Continue'}
        />
      </Row>
      {recaptchaChallengeElem}
    </>
  );

  const showSaveForFutureCheckBox = isOptInEasyPay === false;

  const defaultPaymentMethod = onboardingDefaultValues(paymentMethod);
  return (
    <div>
      <NewPaymentMethodContent
        defaultFormValues={defaultPaymentMethod}
        isOnboardingPayment={true}
        isLoading={isLoading}
        onSubmit={submitWithRecaptcha}
        navigationElements={navigationElement}
        defaultPaymentMethod={paymentMethod?.paymentType}
        shouldBeWrappedInCard={true}
        showCVV={true}
        isOptInEasyPay={isOptInEasyPay}
        setIsCardSaved={setIsCardSaved}
        shouldShowSaveForFutureCheckBox={showSaveForFutureCheckBox}
      />
    </div>
  );
};

export default NewPaymentMethodOnboardingWrapper;

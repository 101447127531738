import { faCreditCard, faUniversity } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import * as React from 'react';
import { colors } from '../../../theme/theme';
import SelectableCard from '../../SelectableCard/SelectableCard';
import { PaymentMethodType } from '../Payments.enums';

export interface ISelectPaymentMethodProps {
  onChangePaymentType(PaymentMethodType: PaymentMethodType): void;
  paymentMethodType: PaymentMethodType | undefined;
  onlyCreditCard?: boolean;
}

const SelectPaymentMethod: React.FC<ISelectPaymentMethodProps> = ({
  onChangePaymentType,
  paymentMethodType,
  onlyCreditCard,
}) => {
  return (
    <>
      <h2 className="mt-3 py-5 text-center">
        <Trans>Select Payment Method</Trans>
      </h2>
      <div className="flex justify-center">
        <div className="mb-2" style={{ width: 305 }}>
          <SelectableCard
            key={PaymentMethodType.CARD}
            selected={paymentMethodType == PaymentMethodType.CARD}
            title={''}
            onClick={() => onChangePaymentType(PaymentMethodType.CARD)}
            autoHeight={true}
            content={
              <div>
                <FontAwesomeIcon icon={faCreditCard} color={colors.primary.black} size="lg" />
                <span className="title pl-3 pr-2">
                  <Trans>Credit or Debit Card</Trans>
                </span>
              </div>
            }
          />
        </div>

        {onlyCreditCard === true ? null : (
          <div className="mb-2" style={{ width: 260 }}>
            <SelectableCard
              key={PaymentMethodType.BANK}
              selected={paymentMethodType == PaymentMethodType.BANK}
              title={''}
              onClick={() => onChangePaymentType(PaymentMethodType.BANK)}
              autoHeight={true}
              content={
                <div>
                  <FontAwesomeIcon icon={faUniversity} color={colors.primary.black} size="lg" />
                  <span className="title pl-3 pr-2">
                    <Trans>Bank Account</Trans>
                  </span>
                </div>
              }
            />
          </div>
        )}
      </div>
    </>
  );
};

export default SelectPaymentMethod;

import React from 'react';
import ProgressSteps, { ProgressStep } from '../ProgressSteps/ProgressSteps';
import '../../pages/Onboarding/Onboarding.scss';

type OnboardingHeaderBaseProps = {
  steps: ProgressStep[];
  currentStepIndex: number;
};

export interface OnboardingHeaderWholeViewProps extends OnboardingHeaderBaseProps {
  title: string;
  planName: string;
}

export interface OnboardingHeaderOnlyProgressProps extends OnboardingHeaderBaseProps {
  onlyProgressBar: boolean;
}

export default function OnboardingHeader<Type extends OnboardingHeaderBaseProps>(
  props: Extract<Type, { title: string; planName: string }> extends { onlyProgressBar: boolean }
    ? OnboardingHeaderOnlyProgressProps
    : OnboardingHeaderWholeViewProps,
): React.ReactElement {
  return (
    <>
      {(props as unknown as OnboardingHeaderOnlyProgressProps)?.onlyProgressBar ? null : (
        <>
          <h5 className="onboarding-header">{(props as OnboardingHeaderWholeViewProps)?.title}</h5>
          <h4 className="py-5 text-center">{(props as OnboardingHeaderWholeViewProps)?.planName}</h4>
        </>
      )}
      <div className="mx-auto mb-10 w-5/6 sm:w-1/2">
        <ProgressSteps steps={props.steps} currentStepIndex={props.currentStepIndex}></ProgressSteps>
      </div>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import './Banner.scss';
import { useQuery } from '@apollo/client';
import { GET_ACCOUNT_DETAILS, IAccountDetails } from '../../../graphql/accountDetails.graphql';
import { IPaymentMethodGraphQl, PAYMENT_METHODS } from '../../../graphql/paymentMethods.graphql';
import { IAuthContext, useAuth } from '../../../store/authentication/authentication.store';
import { MainLoader } from '../../Loader/MainLoader';
import ActionItems from './ActionItems';
import { GET_TRANSPONDER_LIST, ITransponderList } from '../../../graphql/transponders.graphql';
import useGetVehicles from 'src/hooks/domain/dashboard/vehicle/useGetVehicles';
import { useVehicleStore } from '../../../store/vehicles/vehicle.store';
import {
  DEFAULT_TRANSPONDERS_FETCH_PARAMS,
  DEFAULT_VEHICLES_FETCH_PARAM,
} from '../Transponders/TransponderList/defaultValues';

export default function Banner(): React.ReactElement {
  const { data: accountData, loading: accountLoading } = useQuery<{ accountDetails: IAccountDetails }>(
    GET_ACCOUNT_DETAILS,
  );

  const { data: paymentMethodsData, loading: paymentLoading } = useQuery<{
    paymentMethodList: IPaymentMethodGraphQl[];
  }>(PAYMENT_METHODS, {
    errorPolicy: 'all',
  });

  const { data: transpondersData, loading: transponderListLoading } = useQuery<{ transponderList: ITransponderList }>(
    GET_TRANSPONDER_LIST,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        transponderListInput: DEFAULT_TRANSPONDERS_FETCH_PARAMS,
      },
    },
  );

  const { vehiclesData, vehiclesLoading, refetch } = useGetVehicles();

  const [visible, setVisible] = useState(true);

  const {
    details: { isReminderShownToUser },
    reminderDismissed,
  } = useAuth((state: IAuthContext) => state);

  const {
    store: { isVehicleListChanged, refetchVehicleList },
  } = useVehicleStore();

  useEffect(() => {
    refetch({ vehicleListInput: DEFAULT_VEHICLES_FETCH_PARAM });
  }, [isVehicleListChanged]);

  return (
    <>
      {visible && isReminderShownToUser && (
        <>
          <MainLoader loading={accountLoading || paymentLoading || vehiclesLoading || transponderListLoading} />
          <div className="home">
            <div className="banner-content">
              <section className="banner w-full rounded-xl bg-primary-white text-left shadow-md">
                <div className="h-full">
                  <h1 className="pointer mb-5 mt-3 px-2 text-center text-2xl text-primary-blue1 sm:text-3xl md:text-4xl">
                    <Trans>Just one more thing...</Trans>
                  </h1>
                  <h2 className="banner-text mb-2 px-2 text-center text-lg font-normal text-opacity-60 sm:text-xl md:text-opacity-100">
                    <Trans>Finish your account setup by completing the following items.</Trans>
                  </h2>
                  <ActionItems
                    accountDetails={accountData?.accountDetails}
                    paymentMethodsData={paymentMethodsData}
                    transponderList={transpondersData?.transponderList}
                    vehicles={refetchVehicleList ? undefined : vehiclesData?.vehicleList?.vehicles}
                    setVisible={setVisible}
                  />
                </div>
                <span
                  className="cancel-reminder_button text-primary-green1"
                  aria-label="Finished setting up my account"
                  onClick={reminderDismissed}
                >
                  <button className="mb-0 mt-3 underline">{t`No thanks, I'm finished setting up my account`}</button>
                </span>
              </section>
            </div>
          </div>
        </>
      )}
    </>
  );
}

import { FC } from 'react';
import { Form, Select } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import { t, Trans } from '@lingui/macro';
import classNames from 'classnames';
import { LabelValue } from 'src/types/LabelValue.interface';
import useCountryOptions from '../../../../hooks/domain/address/useCountryOptions';
import { IVehicleFormItemProps } from '../../../Onboarding/Vehicles/OnboardingVehiclesForm/OnboardingVehicleForm';
import { buildErrorString, getInputName } from '../../../Onboarding/Vehicles/OnboardingVehiclesForm/vehicleForm.utils';
import { useStateTriggerToDefault } from 'src/hooks/domain/address/useStateTriggerToDefault';

const { Option } = Select;

const LicencePlateCountry: FC<IVehicleFormItemProps> = ({ field, index, name, isEditMode }) => {
  const {
    control,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  useStateTriggerToDefault(getInputName('vehicleCountry', index), getInputName('plateState', index));

  const licensePlateNotKnown = watch(getInputName('notKnown', index));

  const { countryOptions } = useCountryOptions();

  return (
    <div className="mb-4 w-full sm:mb-0 sm:w-3/12">
      <Form.Item
        {...field}
        help={buildErrorString(name, errors, index)}
        className="theme"
        label={<Trans id="License Plate Country" />}
        htmlFor={name}
        required
      >
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              id={name}
              onChange={(value) => {
                field.onChange(value);
                setValue(getInputName('plateState', index), undefined);
                clearErrors(getInputName('plateState', index));
              }}
              disabled={licensePlateNotKnown || isEditMode}
              className={classNames({ 'input-error': fieldState.invalid })}
              placeholder={t`Select`}
              optionFilterProp="children"
              showSearch
              aria-label={t`License Plate Country`}
            >
              {countryOptions?.map((country: LabelValue) => (
                <Option key={country.value} value={country.value}>
                  {country.label}
                </Option>
              ))}
            </Select>
          )}
        />
      </Form.Item>
    </div>
  );
};

export default LicencePlateCountry;

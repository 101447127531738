import { Trans } from '@lingui/macro';
import miniTransponder from '../../Onboarding/assets/sunpass-mini.png';
import proTransponder from '../../Onboarding/assets/sunpass-pro.png';
import ConfirmationModal from './ConfirmationModal';
import React from 'react';

interface ITransponderNumberLocatingModalProps {
  showModal: boolean;
  onOk: () => void;
}

export const TransponderNumberLocatingModal = ({ showModal, onOk }: ITransponderNumberLocatingModalProps) => {
  return (
    <ConfirmationModal
      wide
      title={<Trans>How to Locate Your Transponder Number</Trans>}
      alignButtons="center"
      content={
        <div className="flex flex-col items-center justify-evenly text-center leading-5 sm:flex-row sm:items-end">
          <div className="m-2 flex w-full flex-col items-center justify-center xsm:w-1/2 sm:w-1/3">
            <img src={miniTransponder} className="onboarding-transponders-mini mb-7" alt="Mini transponder" />
            <p className="w-full sm:w-2/3">
              <Trans>The SunPass Mini Transponder number is located on the front side of the transponder</Trans>
            </p>
          </div>
          <div className=" m-2 flex w-full flex-col items-center justify-center xsm:w-1/2 sm:w-1/3">
            <img src={proTransponder} className="onboarding-transponders-pro mb-7" alt="Pro transponder" />
            <p className="w-full sm:w-2/3">
              <Trans>
                The SunPass PRO Transponder number is located on the mounting strip on the back side of the transponder
              </Trans>
            </p>
          </div>
        </div>
      }
      showModal={showModal}
      closeType
      onOk={onOk}
      alignText="center"
    />
  );
};

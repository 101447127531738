import create from 'zustand';
import { devtools } from 'zustand/middleware';

interface IAddPlan {
  currentStep: number;
  nextStep: () => void;
  previousStep: () => void;
  resetStepper: () => void;
}

export const addPlanStore = create<IAddPlan, [['zustand/devtools', IAddPlan]]>(
  devtools((set) => ({
    currentStep: 0,
    nextStep: () => set((state) => ({ currentStep: state.currentStep + 1 })),
    previousStep: () => set((state) => ({ currentStep: state.currentStep - 1 })),
    resetStepper: () => set(() => ({ currentStep: 0 })),
  })),
);

import React from 'react';
import { v4 as uuid } from 'uuid';
import './InfoCard.scss';

interface InfoColumnInterface {
  id: string;
  title: React.ReactElement;
  body: React.ReactElement;
}

export class InfoColumn implements InfoColumnInterface {
  id: string;
  title: React.ReactElement;
  body: React.ReactElement;
  constructor(title: React.ReactElement, body: React.ReactElement) {
    this.id = uuid();
    this.title = title;
    this.body = body;
  }
}

interface Props {
  bannerSrc: string;
  bannerAlt: string;
  infoCols: InfoColumn[];
}

export default function InfoCard(props: Props): React.ReactElement {
  return (
    <div className="card--vertical  info-card">
      <img className="info-card__banner" src={props.bannerSrc} alt={props.bannerAlt}></img>
      <div className="info-card__body">
        {props.infoCols.map((c) => (
          <div key={c.id} className="mb-7">
            {c.title}
            {c.body}
          </div>
        ))}
      </div>
    </div>
  );
}

import React, { useMemo, useRef, useState } from 'react';
import CollapsableContainer from '../../CollapsableContainer/CollapsableContainer';
import { t } from '@lingui/macro';
import { FleetGovernmentAdditionalInfo } from '../../Onboarding/Account/FleetGovernmentAdditionalInfo';
import { PhonesInfo } from '../../Onboarding/Account/PhonesInfo';
import { useFormContext } from 'react-hook-form';
import { IContactSettings, InputPropsType } from './Contact';
import { EmailAddresses } from '../../Onboarding/Account/EmailAddresses';
import { convertMaskToPlaceholder, PhoneMask } from '../../../validators/masks';
import { IAdditionalEmailAddressInput } from '../../../graphql/signUp/signUpDetails.graphql';
import { isEqual } from 'lodash';
import { useQuery } from '@apollo/client';
import { GET_ACCOUNT_DETAILS } from '../../../graphql/accountDetails.graphql';
import { usePlans } from '../../Dashboard/MyDashboard/usePlans';
import { AccountType } from '../../../types/onboarding.types';

export const ContactInformation = ({ disabled }: InputPropsType) => {
  const [contactInfoCollapsed, setContactInfoCollapsed] = useState<boolean>(false);
  const { watch, getFieldState, getValues } = useFormContext<IContactSettings>();
  const [phonePlaceholder] = useState<string | null>(convertMaskToPlaceholder(PhoneMask));
  const watchHomePhone = watch('homePhone');
  const watchCellPhone = watch('cellPhone');
  const watchWorkPhone = watch('workPhone');
  const additionalEmailWatch = getValues('additionalEmailAddresses')?.[0]?.email;
  const additionalReTypeEmailWatch = getValues('additionalEmailAddresses')?.[0]?.reTypeEmail;

  const account = useQuery(GET_ACCOUNT_DETAILS)?.data?.accountDetails;
  const accountType = account?.accountType;

  const { GOVAccount } = usePlans();
  const isGOVAccount = !!(accountType === AccountType.COMMERCIAL && GOVAccount);

  const prevAdditionalEmailsRef = useRef<IAdditionalEmailAddressInput[] | undefined>(watch('additionalEmailAddresses'));

  const isFormSectionDirty = useMemo(() => {
    const { isDirty: isCellPhoneDirty } = getFieldState('cellPhone');
    const { isDirty: isHomePhoneDirty } = getFieldState('homePhone');
    const { isDirty: isWorkPhoneDirty } = getFieldState('workPhone');
    const homePhoneLength = watchHomePhone?.replaceAll(/\W|_/g, '')?.length;
    const cellPhoneLength = watchCellPhone?.replaceAll(/\W|_/g, '')?.length;
    const workPhoneLength = watchWorkPhone?.replaceAll(/\W|_/g, '')?.length;
    return (
      (isCellPhoneDirty && (watchCellPhone === phonePlaceholder || phonePlaceholder === '')) ||
      (isCellPhoneDirty && cellPhoneLength && cellPhoneLength >= 1) ||
      (isHomePhoneDirty && (watchHomePhone === phonePlaceholder || watchHomePhone === '')) ||
      (isHomePhoneDirty && homePhoneLength && homePhoneLength >= 1) ||
      (isWorkPhoneDirty && workPhoneLength && workPhoneLength >= 1) ||
      getFieldState('email').isDirty ||
      getFieldState('reTypeEmail').isDirty ||
      !isEqual(prevAdditionalEmailsRef.current, watch('additionalEmailAddresses'))
    );
  }, [
    watchCellPhone,
    watchHomePhone,
    watchWorkPhone,
    watch('email'),
    watch('reTypeEmail'),
    watch('additionalEmailAddresses'),
    additionalEmailWatch,
    additionalReTypeEmailWatch,
  ]);

  return (
    <>
      <CollapsableContainer
        title={t`Contact Information`}
        onChange={(panels: any) => setContactInfoCollapsed(!panels[0])}
        activeKey={contactInfoCollapsed ? [] : ['1']}
        noTopMargin
        unsavedChanges={isFormSectionDirty}
        responsive
      >
        <EmailAddresses disabled={disabled} />
        <FleetGovernmentAdditionalInfo
          disabled={disabled}
          authorizedUserAccountType={accountType}
          isGovAccount={isGOVAccount}
        />
        <PhonesInfo disabled={disabled} />
      </CollapsableContainer>
    </>
  );
};

import { FC } from 'react';
import { Form, Select } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import { t, Trans } from '@lingui/macro';
import { LabelValue } from 'src/types/LabelValue.interface';
import useVehicleModelOptions from '../../../../hooks/domain/dashboard/vehicle/useVehicleModelOptions';
import { IVehicleFormItemProps } from '../../../Onboarding/Vehicles/OnboardingVehiclesForm/OnboardingVehicleForm';
import { buildErrorString, getInputName } from '../../../Onboarding/Vehicles/OnboardingVehiclesForm/vehicleForm.utils';
import classNames from 'classnames';

const { Option } = Select;

const VehicleModel: FC<IVehicleFormItemProps> = ({ field, index, name }) => {
  const {
    watch,
    control,
    formState: { errors },
  } = useFormContext();

  const make = watch(getInputName('vehicleMake', index));

  const { vehicleModelOptions } = useVehicleModelOptions(make);

  return (
    <div className="mb-4 w-full sm:mb-0 sm:w-6/12">
      <Form.Item
        {...field}
        help={buildErrorString(name, errors, index)}
        label={<Trans id="Model" />}
        htmlFor={name}
        className="theme"
        hasFeedback
        validateStatus={errors?.vehicles?.[0]?.vehicleModel || errors.vehicleModel ? 'error' : ''}
        required
      >
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              {...fieldState}
              id={name}
              className={classNames({ 'input-error': fieldState.invalid })}
              showSearch
              placeholder={t`Select`}
              optionFilterProp="children"
              aria-label={t`Model`}
            >
              {vehicleModelOptions?.map((model: LabelValue) => (
                <Option key={model.value} value={model.value}>
                  {model.label}
                </Option>
              ))}
            </Select>
          )}
        />
      </Form.Item>
    </div>
  );
};

export default VehicleModel;

import { Trans } from '@lingui/macro';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { DashboardParams, MainRoutes } from 'src/types/routes';
const SunpassParkingInfo: FC = () => {
  return (
    <div className="card my-2 mt-4 pt-4">
      <h3 className="header-color my-2">
        <Trans>About SunPass Parking</Trans>
      </h3>
      <div className="flex gap-6">
        <div className="flex-1 ">
          <div>
            <Trans>
              EasyPay automatically provides you the convenience of using your transponder to pay for parking at most of
              Florida&apos;s major airports as well as the Hard Rock Stadium in Miami.
              <br /> <br /> Only EasyPay customers whose primary means of automatic replenishment is a credit or debit
              card are eligible for SunPass Plus parking.
            </Trans>
          </div>
          <div className="mt-10">
            {' '}
            <h3 className="my-2 text-xl">
              <Trans>Where to use</Trans>
            </h3>
            <ol className="list-disc pl-4">
              <li>
                <Trans>Miami International Airport</Trans>
              </li>
              <li>
                <Trans>Fort Lauderdale-Hollywood International Airport</Trans>
              </li>
              <li>
                <Trans>Hard Rock Stadium</Trans>
              </li>
              <li>
                <Trans>Palm Beach International Airport</Trans>
              </li>
              <li>
                <Trans>Tampa International Airport</Trans>
              </li>
              <li>
                <Trans>Orlando International Airport</Trans>
              </li>
            </ol>
          </div>
        </div>
        <div className="flex-1">
          <h3 className="mb-2 text-xl">
            <Trans>How to Get SunPass Plus Parking</Trans>
          </h3>
          <ol>
            <li>
              <Trans>
                1. Go to{' '}
                <Link
                  to={`${MainRoutes.DASHBOARD}/${DashboardParams.WALLET}`}
                  className={`dashboard-links relative mt-1 inline w-20 pb-px text-center text-base tracking-tight`}
                >
                  your wallet
                </Link>
              </Trans>
            </li>
            <li>
              <Trans>2. Activate Easy Pay</Trans>
            </li>
            <li>
              <Trans>3. Set your replenishment amount</Trans>
            </li>
            <li>
              <Trans>
                4. Come back to this page and select which transponders you&apos;d like to add the SunPass Plus Parking
                plan to
              </Trans>
            </li>
            <li>
              <Trans>5. You are set and ready to roll!</Trans>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};
export default SunpassParkingInfo;

import React from 'react';
import { UsersCard } from './UsersCard';
import './Balance.scss';
import { BalanceSumarryCard } from './BalanceSumarryCard';
import { useQuery } from '@apollo/client';
import { GET_ACCOUNT_DETAILS } from '../../../../graphql/accountDetails.graphql';

export const BalanceCards = () => {
  const account = useQuery(GET_ACCOUNT_DETAILS)?.data?.accountDetails;
  return (
    <div className="cards-container">
      <UsersCard account={account} />
      <BalanceSumarryCard account={account} />
    </div>
  );
};

import React, { FC } from 'react';
import { t, Trans } from '@lingui/macro';
import ConfirmationModal from 'src/components/Modals/ConfirmationModal/ConfirmationModal';

interface NoTranspondersModal {
  showModal: boolean;
  onOk: () => void;
  onBack: () => void;
}

const NoTranspondersModal: FC<NoTranspondersModal> = ({ showModal, onOk, onBack }) => {
  const okButtonText = t`Transponder tab`;

  const content = (
    <div className="pt-10">
      <Trans>
        You must activate a transponder before you can opt into SunPass Plus Parking. You can do so on the Transponders
        tab of your dashboard before you can come back and continue to enroll in SunPass Plus Parking.
      </Trans>
    </div>
  );

  return (
    <>
      {showModal && (
        <ConfirmationModal
          onOk={onOk}
          showModal={showModal}
          okButtonText={okButtonText}
          content={content}
          closable={true}
          alignButtons="center"
          alignText="center"
          cancelButtonText="Go Back"
          onCancel={onBack}
        />
      )}
    </>
  );
};
export default NoTranspondersModal;

import * as yup from 'yup';
import { validators } from '../../validators';
import { AnyObject } from 'yup/lib/types';
import { isEmpty } from 'lodash';
import moment from 'moment/moment';
import {
  END_DATE_ERROR_MSG,
  TRANSPONDER_OR_PLATE_NUMBER_ERROR_MSG,
} from '../../onboarding/transponders/transpondersErrorMessages';

export const activitySearchValidator = yup.object().shape({
  transponderOrPlateNumber: validators.alphanumeric.max(13, TRANSPONDER_OR_PLATE_NUMBER_ERROR_MSG),
  endDate: yup
    .string()
    .nullable()
    .test('endDateBeforeStart', END_DATE_ERROR_MSG, (endDate: string | undefined | null, context: AnyObject) => {
      if (isEmpty(endDate) || isEmpty(context.parent.startDate)) return true;
      return moment(endDate).isAfter(context.parent.startDate);
    }),
  startDate: yup.string().nullable(),
});

import { Form } from 'antd';
import { t, Trans } from '@lingui/macro';
import { Controller, useFormContext } from 'react-hook-form';
import StyledInput from '../../../StyledInput/StyledInput';
import React from 'react';
import { IFleetGovAdditionalInfo } from '../../../../types/onboarding.types';

export const PurchaseOrderNumber = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IFleetGovAdditionalInfo>();
  return (
    <div className="w-full sm:w-6/12">
      <Form.Item
        label={<Trans>Purchase Order Number</Trans>}
        className="theme"
        help={errors.purchaseOrderNumber?.message}
        required
      >
        <Controller
          name="purchaseOrderNumber"
          control={control}
          render={({ field, fieldState }) => (
            <StyledInput
              field={field}
              fieldstate={fieldState}
              key="purchaseOrderNumber"
              id="purchaseOrderNumber"
              maxLength={25}
              aria-label={t`Purchase order number`}
            />
          )}
        />
      </Form.Item>
    </div>
  );
};

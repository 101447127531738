import { CustomGraphQLErrorExtensions } from 'src/hooks/errors/errorHandler.types';
import { create } from '../createStore';
import { devtools } from 'zustand/middleware';
import {
  IAccountDetailsTransponderActivationSignUpInput,
  ISignUpTransponderActivationInput,
} from 'src/graphql/signUp/signUpTransponderActivation.graphql';
import { IVehicleSignUpInput } from 'src/graphql/signUp/signUpVehiclesTransponders.graphql';

interface IOnboardingErrorStore {
  onboardingError?: CustomGraphQLErrorExtensions;
  isOnboardingError: boolean;
  canProceedToNextStep: boolean;
  setCanProceedToNextStep: (canProceedToNextStep: boolean) => void;
  setOnboardingError: (onboardingError: CustomGraphQLErrorExtensions) => void;
  resetOnboardingError: () => void;
  onboardingState?: ISignUpTransponderActivationInput;
  setOnboardingState: (
    accountDetailsSignUp: IAccountDetailsTransponderActivationSignUpInput | undefined,
    transponderNumbers: string[] | undefined,
    vehicles: IVehicleSignUpInput[] | undefined,
  ) => void;
}

export const useOnboardingErrors = create<IOnboardingErrorStore, [['zustand/devtools', IOnboardingErrorStore]]>(
  devtools((set) => ({
    isOnboardingError: false,
    onboardingState: undefined,
    canProceedToNextStep: false,
    setOnboardingState: (accountDetailsSignUp, transponderNumbers, vehicles) => {
      if (accountDetailsSignUp) {
        set((state) => ({
          ...state,
          onboardingState: { ...state.onboardingState, accountDetailsSignUp },
        }));
      }
      if (transponderNumbers) {
        set((state) => ({
          ...state,
          onboardingState: { ...state.onboardingState, transponderNumbers },
        }));
      }
      if (vehicles) {
        set((state) => ({
          ...state,
          onboardingState: { ...state.onboardingState, vehicles },
        }));
      }
    },
    setOnboardingError: (onboardingError) => {
      set((state) => ({
        ...state,
        canProceedToNextStep: false,
        onboardingError,
        isOnboardingError: true,
      }));
    },
    resetOnboardingError: () => {
      set((state) => ({
        ...state,
        canProceedToNextStep: false,
        onboardingError: undefined,
        isOnboardingError: false,
      }));
    },
    setCanProceedToNextStep: (canProceedToNextStep) => {
      set((state) => ({
        ...state,
        canProceedToNextStep: canProceedToNextStep,
      }));
    },
  })),
);

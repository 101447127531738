import { faBell } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans, t } from '@lingui/macro';
import { useContext, useState } from 'react';
import { colors } from 'src/theme/theme';
import ScreenSize from 'src/types/ScreenSize.enum';
import './Alerts.scss';
import { NotificationContext } from '../../context/NotificationContext';
import useScreenSize from '../../hooks/screen/useScreenSize';
import AlertsDropdown from './AlertsDropdown';

const Alerts = () => {
  const [isDropDownShown, setIsDropDownShown] = useState(false);

  const { screenSize } = useScreenSize();
  const notificationCtx = useContext(NotificationContext);
  const { notificationInfo } = notificationCtx || {};

  return (
    <div className="alert-notifications " id="alerts">
      <button
        className="flex cursor-pointer items-center text-base"
        onClick={() => setIsDropDownShown(!isDropDownShown)}
        type="button"
        aria-label={t`Notifications`}
      >
        <FontAwesomeIcon
          className="mr-1"
          icon={faBell}
          color={colors.primary.gray1}
          size={'2x'}
          aria-label={t`Notifications icon`}
        />
        {notificationInfo?.hasNewNotifications ? <div className="bell-allert"></div> : null}
        {screenSize > ScreenSize.LG ? (
          <span className="ml-3 mr-6 font-bold text-primary-gray1 hover:text-primary-gray1">
            <Trans>Notifications</Trans>
          </span>
        ) : null}
      </button>
      {isDropDownShown ? <AlertsDropdown setIsDropDownShown={setIsDropDownShown} /> : null}
    </div>
  );
};
export default Alerts;

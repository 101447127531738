import { t } from '@lingui/macro';
import { Form, Input } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import { ActionType } from './ForgotUsernameOrPassword';
import { IInputElemDefaultProp } from '../atoms/form/form.types';

interface ITransponderNumberProps extends IInputElemDefaultProp {
  actionType: any;
}

const TransponderNumber = ({ actionType, disabled }: ITransponderNumberProps) => {
  const {
    control,
    trigger,
    formState: { errors },
    watch,
  } = useFormContext();
  return (
    <Form.Item
      label={t`Transponder Number`}
      className={`theme forgot-username-or-password-input ${
        actionType === ActionType.RETRIEVE_USERNAME ? null : 'hidden'
      }`}
      help={errors.transponderNumber?.message as React.ReactNode}
      hasFeedback
      validateStatus={errors.transponderNumber?.message ? 'error' : ''}
    >
      <Controller
        name={'transponderNumber'}
        control={control}
        render={({ field, fieldState }) => (
          <Input
            {...field}
            {...fieldState}
            id={'transponderNumber'}
            key={'transponderNumber'}
            disabled={disabled}
            minLength={9}
            maxLength={13}
            onChange={(value: any) => {
              field.onChange(value);
              if (watch('transponderNumber') == '') {
                field.onChange(undefined);
              }
              trigger(['username', 'accountNumber']);
            }}
            aria-label={t`transponder number`}
          />
        )}
      />
    </Form.Item>
  );
};

export default TransponderNumber;

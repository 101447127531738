import { Form, Radio } from 'antd';
import { Trans } from '@lingui/macro';
import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import { TRANSACTION_TYPES } from '../activity.utils';
import { ITransactionActivityListInput } from '../../../../../graphql/transactionsActivity.graphql';

export const TransactionType = () => {
  const { control } = useFormContext<ITransactionActivityListInput>();
  return (
    <div className="mb-0 w-full sm:mb-3 md:w-7/12">
      <Form.Item label={<Trans>Transaction Type</Trans>}>
        <Controller
          control={control}
          name="transactionType"
          key="transactionType"
          render={({ field }) => (
            <Radio.Group
              {...field}
              id="searchDate"
              onChange={(value) => {
                field.onChange(value);
              }}
            >
              {TRANSACTION_TYPES.map(({ value, label }, index) => {
                return (
                  <Radio value={value} key={index}>
                    {label}
                  </Radio>
                );
              })}
            </Radio.Group>
          )}
        />
      </Form.Item>
    </div>
  );
};

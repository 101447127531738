import * as React from 'react';
import { useEffect, useState } from 'react';
import PurchaseTranspondersContent from 'src/components/Onboarding/OnboardingTransponders/PurchaseTranspondersContent';
import MultiStepPayment from './MultistepPayment';
import { useMultiStepPaymentStore } from 'src/store/multiStepPayment/multiStepPayment.store';
import { useQuery } from '@apollo/client';
import { GET_ACCOUNT_DETAILS, IAccountDetails } from '../../../../graphql/accountDetails.graphql';
import { AccountType } from 'src/types/onboarding.types';
import { generateLimitMessage } from '../transponders.utils';

export default function TransponderPurchase() {
  const {
    paymentInfo: { numOfProTransponders, numOfStickerTransponders },
  } = useMultiStepPaymentStore();

  const { resetStore } = useMultiStepPaymentStore();

  useEffect(() => {
    return () => {
      resetStore();
    };
  }, []);

  const account = useQuery<{ accountDetails: IAccountDetails }>(GET_ACCOUNT_DETAILS)?.data?.accountDetails;

  const [isOptInPBCMini, setIsOptInPBCMini] = useState(false);
  const [isOptInPBCPro, setIsOptInPBCPro] = useState(false);
  const limitErrorMessage = generateLimitMessage(
    numOfStickerTransponders,
    numOfProTransponders,
    account?.accountType || AccountType.PRIVATE,
  );

  return (
    <div className="flex flex-col gap-4">
      <div className="mx-auto my-10 rounded-lg bg-white px-6 py-6 shadow-lg md:px-12">
        <PurchaseTranspondersContent
          isDashboard={true}
          isOptInPBCMini={isOptInPBCMini}
          isOptInPBCPro={isOptInPBCPro}
          setIsOptInPBCMini={setIsOptInPBCMini}
          setIsOptInPBCPro={setIsOptInPBCPro}
          account={account}
        />
      </div>
      {(numOfStickerTransponders > 0 || numOfProTransponders > 0) && limitErrorMessage === null && <MultiStepPayment />}
    </div>
  );
}

import React from 'react';
import { Trans } from '@lingui/macro';
import '../MyAccount.scss';
import ScrollToTop from '../../ScrollToTop/ScrollToTop';
import BackLink from '../BackLink';
import { PendingRequests } from './ParentPendingRequests/PendingRequests';
import { ActiveLinkedAccounts } from './ActiveAccounts/ActiveLinkedAccounts';
import { ChildActiveAccounts } from './ChildAccounts/ChildActiveAccounts';
import { RequestAccountAccess } from './ChildAccounts/RequestAccountAccess';
import useScreenSize from '../../../hooks/screen/useScreenSize';
import ScreenSize from 'src/types/ScreenSize.enum';
import './LinkedAccounts.scss';
export default function LinkedAccounts(): React.ReactElement {
  const { screenSize } = useScreenSize();

  return (
    <div className="linked-acounts-cards w-full pt-5 large-tablet:pt-0">
      <ScrollToTop />
      {screenSize < ScreenSize.XL ? (
        <div className="mb-5 ml-5">
          <BackLink />
        </div>
      ) : null}
      <div className="card linked-accounts-card-container flex w-full text-sm md:text-base">
        <div className="w-full text-left">
          <h3 className="text-primary-blue1">
            <Trans>Linked Accounts</Trans>
          </h3>
          <PendingRequests />
          <ActiveLinkedAccounts />
        </div>
      </div>
      <div className="mx-auto max-w-5xl text-sm md:text-base ">
        <div className="card linked-accounts-card-container mt-8 flex w-full flex-col">
          <ChildActiveAccounts />
          <RequestAccountAccess />
        </div>
        <div className="mx-2 mt-5 italic sm:mx-8 md:mx-auto md:px-12 xl:px-10">
          <Trans>
            Note: When you link your account with another account holder, they are able to view your account number and
            your account balance only, and they can not make any changes on your account. They must request access in
            order for you to link your account to their’s.
          </Trans>
        </div>
      </div>
    </div>
  );
}

import { useContext, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { t, Trans } from '@lingui/macro';
import {
  IOrderTransponderInput,
  IOrderTransponderOutput,
  IPurchaseTransponderInput,
  ORDER_TRANSPONDER,
  PURCHASE_TRANSPONDER,
} from 'src/graphql/purchaseTransponder.graphql';

import { mapPaymentInfo } from 'src/mappers/mapPaymentInformation';
import { useContactSettingsStore } from 'src/store/account/contactSettings.store';
import { useMultiStepPaymentStore } from 'src/store/multiStepPayment/multiStepPayment.store';
import { MainLoader } from 'src/components/Loader/MainLoader';
import { useScroll } from 'src/hooks/screen/useScroll';
import { ACCOUNT_TYPE_NAME } from 'src/types/onboarding.types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { colors } from 'src/theme/theme';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import SecurityCodeModal from 'src/components/Payments/PaymentMethods/SecurityCodeModal';
import {
  ADD_UPDATE_PAYMENT_METHOD,
  IAddUpdatePaymentMethodInput,
  IPaymentMethodGraphQl,
  PAYMENT_METHODS,
} from 'src/graphql/paymentMethods.graphql';

import { usePaymentStore } from 'src/store/payment/payment.store';
import useTransponderPrices from 'src/hooks/domain/dashboard/transponders/useTransponderPrices';
import { GET_ACCOUNT_DETAILS, IAccountDetails } from 'src/graphql/accountDetails.graphql';
import OneTimePurchase from 'src/components/Payments/PaymentSummary/OneTimePurchase/OneTimePurchase';
import PurchaseInformation from 'src/components/Payments/PaymentSummary/PurchaseInformation';
import ConfirmPaymentModal from 'src/components/Modals/ConfirmationModal/ConfirmPaymentModal';
import { getPaymentMethodName } from 'src/utils/formatter';
import { useSecurityCodeModalStore } from 'src/store/payment/securityCodeModal.store';
import { mapAddUpdatePaymentMethod } from 'src/components/Payments/mappers';
import { NotificationContext } from '../../../../context/NotificationContext';
import { APPLY_PROMO_CODE, IApplyPromoCodeInput } from '../../../../graphql/applyPromoCodeAccount.graphql';
import { showMessage } from '../../../../utils/message.utils';
import { useOneTimePaymentStore } from 'src/store/payment/oneTimePayment.store';
import { PromoCodeType } from '../../../../graphql/verifyPromotionCode.graphql';

export default function PaymentSummaryStep() {
  const { nextStep, previousStep, paymentInfo, replenishmentAmount } = useMultiStepPaymentStore();
  const { miniPrice, proPrice, loading: transponderPricesLoading } = useTransponderPrices();
  const contactStore = useContactSettingsStore((state) => state.closeAccountInfo);
  const { cardType } = usePaymentStore();

  const [showConfirmPaymentModal, setShowConfirmPaymentModal] = useState(false);
  const [amount, setAmount] = useState<number | null>(null);
  const [noTaxAmount, setNoTaxAmout] = useState(0);
  const [infoForSummary, setInfoForSummary] = useState<IPurchaseTransponderInput | undefined>(
    mapPaymentInfo(paymentInfo, contactStore),
  );

  const { isOneTimePayment } = useOneTimePaymentStore();
  const [promoCodeAmount, setPromoCodeAmount] = useState<number>(0);
  const [promoCode, setPromoCode] = useState<string | undefined>('');
  const [isPromoCodeValidAndApplied, setIsPromoCodeValidAndApplied] = useState(true);
  const [promoType, setPromoType] = useState<string | null>(null);

  const { numOfStickerTransponders, numOfProTransponders } = paymentInfo;

  const [finalMiniTransponderQty, setFinalMiniTransponderQty] = useState(numOfStickerTransponders);
  const [finalProTransponderQty, setFinalProTransponderQty] = useState(numOfProTransponders);

  useEffect(() => {
    if (promoType === PromoCodeType.PRO && isPromoCodeValidAndApplied) {
      setFinalProTransponderQty(numOfProTransponders - 1);
    } else if (promoType === PromoCodeType.STK && isPromoCodeValidAndApplied) {
      setFinalMiniTransponderQty(numOfStickerTransponders - 1);
    }

    if (promoCode === '') {
      setFinalProTransponderQty(numOfProTransponders);
      setFinalMiniTransponderQty(numOfStickerTransponders);
    }
  }, [promoCode, isPromoCodeValidAndApplied, promoType]);

  const [applyPromoCode] = useMutation<{ applyPromoCodeInput: IApplyPromoCodeInput }>(APPLY_PROMO_CODE);

  const {
    securityCodeModalData: { securityCode },
    setShowSecurityCodeModal,
  } = useSecurityCodeModalStore();

  const { data: paymentMethodList } = useQuery<{
    paymentMethodList: IPaymentMethodGraphQl[];
  }>(PAYMENT_METHODS, {
    errorPolicy: 'all',
  });

  const [orderTransponder, { data: orderTransponderResponse, loading: isTransponderOrderingLoading }] = useMutation<{
    orderTransponder: IOrderTransponderOutput;
    orderTransponderInput: IOrderTransponderInput;
  }>(ORDER_TRANSPONDER);

  const [purchaseTransponder, { loading: isTransponderPurchasingLoading }] = useMutation<{
    purchaseTransponderInput: IPurchaseTransponderInput;
  }>(PURCHASE_TRANSPONDER);

  const notificationCtx = useContext(NotificationContext);
  const { loadNotifications } = notificationCtx || {};

  const [addPaymentMethod, { loading: isAddingLoading }] = useMutation<IAddUpdatePaymentMethodInput>(
    ADD_UPDATE_PAYMENT_METHOD,
    {
      onCompleted: async () => {
        loadNotifications && (await loadNotifications(true));
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: PAYMENT_METHODS,
        },
      ],
    },
  );

  const paymentMethod = paymentMethodList?.paymentMethodList?.find(
    (item) => item.paymentMethodId === infoForSummary?.paymentMethodId,
  );

  const { data: accountData } = useQuery<{ accountDetails: IAccountDetails }>(GET_ACCOUNT_DETAILS);

  const tax = orderTransponderResponse?.orderTransponder.tax;

  const { scrollToElem } = useScroll();

  const getPlanName = () => {
    const accountType = accountData?.accountDetails?.accountType;
    if (accountType) return ACCOUNT_TYPE_NAME[`${accountType}`];
    else return '';
  };

  useEffect(() => {
    const makeOrder = async () => {
      if (paymentInfo) {
        const { data } = await orderTransponder({
          variables: {
            orderTransponderInput: {
              numOfProTransponders: finalProTransponderQty,
              numOfStickerTransponders: finalMiniTransponderQty,
            },
          },
        });
        data && setNoTaxAmout(data.orderTransponder.amountDue - data.orderTransponder.tax);
        data && setAmount(data.orderTransponder.amountDue);
        data && setInfoForSummary(mapPaymentInfo(paymentInfo, contactStore, cardType, data.orderTransponder.amountDue));
      }
    };
    makeOrder();
  }, [finalMiniTransponderQty, finalProTransponderQty, promoCodeAmount]);

  const purchaseTranspondersQtyElement = 'purchaseTranspondersQty';

  const onEditPurchase = (): void => {
    scrollToElem(purchaseTranspondersQtyElement, true);
  };

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref && ref.current && scrollToElem(ref);
  }, []);

  const callPurchaseMutation = async () => {
    await purchaseTransponder({
      variables: {
        purchaseTransponderInput: {
          ...infoForSummary,
          securityCode: securityCode || infoForSummary?.securityCode,
        },
      },
    });

    if (paymentInfo.isNewPayment && paymentInfo.paymentMethod && !isOneTimePayment) {
      const savePaymentMethodInput = mapAddUpdatePaymentMethod(
        paymentInfo.paymentMethod,
        paymentInfo.paymentMethod,
        cardType,
        replenishmentAmount,
        paymentInfo.paymentMethod.optInEZPay,
        true,
      );
      await addPaymentMethod({
        variables: {
          addUpdatePaymentMethodInput: savePaymentMethodInput,
        },
      });
    }
    if (!!promoCode && promoCode !== '') {
      try {
        await applyPromoCode({ variables: { couponCode: promoCode } });
        showMessage(
          'success',
          t`Your credit was applied successfully and your account balance will be adjusted accordingly`,
        );
      } catch (err) {
        console.error(err);
      }
    }
    nextStep();
  };

  const checkPaymentMethod = async () => {
    try {
      if (!paymentInfo.isNewPayment && !paymentMethod?.isBankAccount) {
        setShowSecurityCodeModal(true);
      } else if (!paymentInfo.isNewPayment && paymentMethod?.isBankAccount) {
        setShowConfirmPaymentModal(true);
      } else {
        await callPurchaseMutation();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const confirmPayment = async () => {
    setShowConfirmPaymentModal(false);
    setShowSecurityCodeModal(false);
    await callPurchaseMutation();
  };

  const paymentContent = (
    <OneTimePurchase
      miniTransponderCharge={miniPrice}
      proTransponderCharge={proPrice}
      miniTransponderQty={numOfStickerTransponders || 0}
      proTransponderQty={numOfProTransponders || 0}
      salesTax={tax || 0}
      subTotal={noTaxAmount}
      onEdit={onEditPurchase}
      isDashboard={true}
      setPromoCodeAmount={setPromoCodeAmount}
      setPromoCode={setPromoCode}
      promoCodeAmount={promoCodeAmount}
      setIsPromoCodeValidAndApplied={setIsPromoCodeValidAndApplied}
      setPromoCodeType={setPromoType}
    />
  );

  return (
    <>
      <div ref={ref}>
        <MainLoader loading={isTransponderOrderingLoading || isTransponderPurchasingLoading || isAddingLoading} />
        <PurchaseInformation
          accountInfo={accountData?.accountDetails || {}}
          planName={getPlanName()}
          isLoading={transponderPricesLoading}
          totalPrice={amount || 0}
          paymentContent={paymentContent}
        />
      </div>
      <div className="mx-12 my-4 flex items-center justify-between">
        <button className="flex items-center" onClick={previousStep}>
          <FontAwesomeIcon icon={faAngleLeft} color={colors.primary.blue1} size="2x"></FontAwesomeIcon>
          <div className="ml-2 font-bold text-primary-blue1 underline">
            <Trans>Back</Trans>
          </div>
        </button>
        <CustomButton
          className="px-15 mx-0 mt-2 sm:mx-3"
          disabled={!isPromoCodeValidAndApplied}
          content="Continue"
          onClick={checkPaymentMethod}
        />
      </div>
      <SecurityCodeModal paymentMethod={paymentMethod} amount={amount} onSubmit={confirmPayment} />
      {paymentMethod && infoForSummary && (
        <ConfirmPaymentModal
          showModal={showConfirmPaymentModal}
          onOk={confirmPayment}
          onCancel={() => {
            setShowConfirmPaymentModal(false);
          }}
          value={infoForSummary?.paymentAmount}
          paymentMethodName={getPaymentMethodName(paymentMethod)}
        />
      )}
    </>
  );
}

import { useQuery } from '@apollo/client';
import { IPlateType, PLATE_TYPES } from '../../../../graphql/countries.graphql';
import { useState } from 'react';

interface IUsePlateTypesOptionsInput {
  state: string;
  country: string;
  onCompleted?: () => void;
}

interface IUsePlateTypesOptions {
  plateTypes: IPlateType[];
  errorLoading: boolean;
  plateTypesLoading: boolean;
}

const usePlateTypes = ({ state, country, onCompleted }: IUsePlateTypesOptionsInput): IUsePlateTypesOptions => {
  const [isError, setIsError] = useState(false);
  const { data: plateTypesData, loading: plateTypesLoading } = useQuery(PLATE_TYPES, {
    variables: { state: { code: state, country: { code: country } } },
    errorPolicy: 'all',
    onError: () => setIsError(true),
    skip: !state,
    onCompleted,
  });

  return {
    plateTypes: plateTypesData?.plateTypesByStateCode || [],
    errorLoading: isError,
    plateTypesLoading,
  };
};

export default usePlateTypes;

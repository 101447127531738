import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import classNames from 'classnames';
import React from 'react';

/**
 * field - field from the react hook form Controller render prop (field: ControllerRenderProps<TFieldValues, TName>;)
 * fieldState - fieldState from the react hook form Controller render prop (fieldState: ControllerFieldState;)

 */

interface Props extends SelectProps<any> {
  field: any;
  fieldState: any;
}

export default function StyledSelect(props: Props): React.ReactElement {
  return (
    <Select
      {...props}
      {...props.field}
      onChange={(value: any) => {
        if (props.onChange) {
          props.onChange(value, {} as any);
        }
        props.field.onChange(value);
      }}
      className={`${classNames({ 'input-error': props.fieldState?.invalid })} ${props.className}`}
      suffixIcon
    ></Select>
  );
}

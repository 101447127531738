import { gql } from '@apollo/client';

export const STANDARDIZE_ADDRESS = gql`
  query addressValidation($addressValidationInput: AddressValidationInput!) {
    addressValidation(addressValidationInput: $addressValidationInput) {
      addressLine1
      addressLine2
      country
      state
      city
      county
      countyFis
      zipcode
      zipcodePlus
      RDBIIndicator
      returnStatus
      returnCode
      returnMessage
    }
  }
`;

export interface IAddressValidationOutput {
  addressLine1: string;
  addressLine2: string;
  country: string;
  state: string;
  city: string;
  county: string;
  countyFis: string;
  zipcode: string;
  zipcodePlus: string;
  RDBIIndicator: string;
  returnStatus: string;
  returnCode: string;
  returnMessage: string;
}

export interface IAddressValidationInput {
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  language: string;
  state: string;
  zipcode: string;
}

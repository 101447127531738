import React, { FC } from 'react';
import { t, Trans } from '@lingui/macro';
import ConfirmationModal from './ConfirmationModal';

interface IConfirmPaymentModalProps {
  showModal: boolean;
  onOk: () => void;
  onCancel?: (nextLocation: any) => void | (() => void);
  cancelButtonBlue?: boolean;
  value: number;
  paymentMethodName: string;
}

const ConfirmPaymentModal: FC<IConfirmPaymentModalProps> = ({
  showModal,
  onOk,
  onCancel,
  value,
  paymentMethodName,
}) => {
  const okButtonText = t`Confirm`;
  const title = (
    <>
      <h1 className="text-xl font-extrabold leading-6 xsm:leading-5">
        <Trans>Confirm Payment</Trans>
      </h1>
    </>
  );

  const content = t`Please confirm that $${Number(value).toFixed(2)} will be charged to ${paymentMethodName}`;

  return (
    <>
      {showModal && (
        <ConfirmationModal
          onOk={onOk}
          onCancel={onCancel}
          showModal={showModal}
          okButtonText={okButtonText}
          title={title}
          content={content}
          closable={true}
          alignButtons="center"
          alignText="center"
        />
      )}
    </>
  );
};
export default ConfirmPaymentModal;

import { useMemo } from 'react';
import MailingAddressFormField, { BillingAddressFormField } from '../../../components/Address/AddressFormField.enum';
import { useFormContext } from 'react-hook-form';

export interface IAddressField {
  name: string;
  errorMsg?: string;
}

export interface IAddressFieldsSate {
  addressLine1Field: IAddressField;
  addressLine2Field: IAddressField;
  countryField: IAddressField;
  cityField: IAddressField;
  stateField: IAddressField;
  postalCodeField: IAddressField;
  countyField: IAddressField;
}

export const useAddressFields = (addressForPayment?: boolean) => {
  const { formState } = useFormContext();
  const { errors } = formState;

  const addressFieldsState = useMemo((): IAddressFieldsSate => {
    const addressLine1 = addressForPayment
      ? BillingAddressFormField.ADDRESS_LINE_1
      : MailingAddressFormField.ADDRESS_LINE_1;
    const addressLine2 = addressForPayment
      ? BillingAddressFormField.ADDRESS_LINE_2
      : MailingAddressFormField.ADDRESS_LINE_2;
    const country = addressForPayment ? BillingAddressFormField.COUNTRY : MailingAddressFormField.COUNTRY;
    const city = addressForPayment ? BillingAddressFormField.CITY : MailingAddressFormField.CITY;
    const state = addressForPayment ? BillingAddressFormField.STATE : MailingAddressFormField.STATE;
    const postalCode = addressForPayment ? BillingAddressFormField.POSTAL_CODE : MailingAddressFormField.POSTAL_CODE;
    const county = MailingAddressFormField.COUNTY;
    return {
      addressLine1Field: {
        name: addressLine1,
        errorMsg: errors[addressLine1]?.message as unknown as string,
      },
      addressLine2Field: {
        name: addressLine2,
        errorMsg: errors[addressLine2]?.message as unknown as string,
      },
      countryField: {
        name: country,
        errorMsg: errors[country]?.message as unknown as string,
      },
      cityField: {
        name: city,
        errorMsg: errors[city]?.message as unknown as string,
      },
      stateField: {
        name: state,
        errorMsg: errors[state]?.message as unknown as string,
      },
      postalCodeField: {
        name: postalCode,
        errorMsg: errors[postalCode]?.message as unknown as string,
      },
      countyField: {
        name: county,
        errorMsg: errors[county]?.message as unknown as string,
      },
    };
  }, [errors, formState]);
  return {
    addressFieldsState,
  };
};

import { ProgressStep } from 'src/components/ProgressSteps/ProgressSteps';
import { Transponder } from './transponder';
import { IVehicle } from '../graphql/vehicles.graphql';
import { IPaymentMethod } from 'src/components/Payments/Payments.interfaces';
import { PaymentMethodType } from 'src/components/Payments/Payments.enums';
import {
  IAdditionalEmailAddressInput,
  ISecondaryContactInput,
  IUserNames,
} from '../graphql/signUp/signUpDetails.graphql';

export interface IOnboardingTransponders {
  miniTransponderQty: number;
  miniTransponderQtyAssignedToPlan: number;
  proTransponderQty: number;
  proTransponderQtyAssignedToPlan: number;
  hasTranspondersToActivate?: boolean;
  transpondersToActivate: Transponder[];
}

export interface IOnboardingInfo extends IOnboardingTransponders {
  totalTranspondersCost: number;
  chosenSubtype: AccountSubtype;
  applicableTax: number;
  onboardingFlowType?: OnboardingFlowType;
  onboardingStarted: boolean;
  shouldDisplayAccountCreatedMsg: boolean;
  userType?: OnboardingUserType;
  pinellasUse?: OnboardingPinellasOptions;
  accountType: AccountType;
  accountSubtype: AccountSubtype;
  optInEZPay?: boolean;
  recurringPaymentAmount?: number;
  replenishmentThresholdAmount?: number;
  sunpassPlusParking?: boolean;
  preloadedFunds: number;
  accountInfo?: IAccountInfo;
  paymentMethod?: IPaymentMethod;
  address?: IMailingAddress;
  vehicles: IVehicle[];
  easyPay: IEasyPay;
  cartTotal: number;
  paymentMethodType: PaymentMethodType;
  transponderSerialNumbers: TransponderSerialNumbers;
  cardType: string;
  referenceNumber: string;
  accountId: string;
  planName: string;
}

export interface TransponderSerialNumbers {
  proTransponderSerialNumber?: string;
  miniTransponderSerialNumber?: string;
}

export interface IAccountInfo extends ISecurityInfo, IContactInfo, IMailingAddress {}
export type IAccountInfoPropertyNames = keyof IAccountInfo;
export interface ISecurityInfo {
  username: string;
  password: string;
  oldPassword?: string;
  reTypePassword: string;
  firstSecurityQuestion: string;
  firstSecurityQuestionAnswer: string;
  secondSecurityQuestion: string;
  secondSecurityQuestionAnswer: string;
  thirdSecurityQuestion: string;
  thirdSecurityQuestionAnswer: string;
}

export interface IContactInfo extends IEmailAddresses, IFleetGovAdditionalInfo, IPhonesInfo, IUserNames {
  smsOption?: boolean;
  language?: string;
  additionalContacts?: ISecondaryContactInput[];
  showAdditionalContacts?: number | boolean;
  statementType: string;
  accountType?: AccountType;
  accountSubType?: AccountSubtype;
}

export interface IEmailAddresses {
  email: string;
  reTypeEmail: string;
  additionalEmailAddresses?: IAdditionalEmailAddressInput[];
}

export interface IFleetGovAdditionalInfo {
  companyName?: string;
  doingBusinessAs?: string; // Note: this field is not currently included in the API, but FTE wants it for the future (see comments on FLO-557)
  fein?: string;
  organizationName?: string;
  purchaseOrderNumber?: string;
}

export interface IPhonesInfo {
  cellPhone?: string;
  homePhone?: string;
  workPhone?: string;
}

export interface IMailingAddress {
  zipCode?: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  postalCode?: string;
  state?: string;
  county?: string;
  country: string;
}

export interface AccountTypeInfo {
  accountType: AccountType;
  accountSubtype: AccountSubtype;
}

export interface IEasyPay {
  optInEZPay?: boolean;
  recurringPaymentAmount?: string;
  replenishmentThresholdAmount?: string;
  depositAmount?: string;
  confirmDepositAmount?: string;
  sunpassPlusParking?: boolean;
}

export enum OnboardingFlowType {
  PREPAID = 'PREPAID',
  PURCHASE = 'PURCHASE',
}

export enum OnboardingUserType {
  RESIDENT = 'RESIDENT',
  VISITOR = 'VISITOR',
  COMMERCIAL = 'COMMERCIAL',
  GOVERNMENT = 'GOVERNMENT',
}

export enum OnboardingPinellasOptions {
  NO_PINELLAS = 'NO', // Never or rarely uses the Pinellas Bayway
  FREQUENT = 'FREQUENT', // Frequently uses Pinellas Bayway
}

export enum AccountType {
  PAYG = 'PAYG', //type PAYG is only for onboarding logic, DO NOT use it as an account type in requests, use account type PRIVATE and subtype PRIVATEVIDEO
  PRIVATE = 'PRIVATE',
  COMMERCIAL = 'COMMERCIAL',
  GOVERNMENT = 'GOVERNMENT',
}

export enum ACCOUNT_TYPE_NAME {
  PAYG = 'Pay as you go',
  PRIVATE = 'Private',
  COMMERCIAL = 'Commercial',
  GOVERNMENT = 'Govermnment',
}

export enum AccountSubtype {
  PB = 'PB', // pinellas bayway add on
  PBC = 'PBC', // pinellas bayway commuter only
  PRIVATEVIDEO = 'PRIVATEVIDEO', //Pay as you go (visitor)
  NONE = 'NONE',
}

export const OnboardingStepsResidentActivateFlow: ProgressStep[] = [
  { title: 'Step 1: ', description: 'Establish Account Type' },
  { title: 'Step 2: ', description: 'Fill Out Contact Information' },
  { title: 'Step 3: ', description: 'Activate a Transponder' },
  { title: 'Step 4: ', description: 'Fill Out Vehicle Information' },
  { title: 'Step 5: ', description: 'Opt into Easy Pay and Preload Funds' },
  { title: 'Step 6: ', description: 'Complete Your Order' },
];

export const OnboardingStepsResidentPurchaseFlow: ProgressStep[] = [
  { title: 'Step 1: ', description: 'Establish Account Type' },
  { title: 'Step 2: ', description: 'Fill Out Contact Information' },
  { title: 'Step 3: ', description: 'Purchase a Transponder' },
  { title: 'Step 4: ', description: 'Fill Out Vehicle Information' },
  { title: 'Step 5: ', description: 'Opt into Easy Pay and Preload Funds' },
  { title: 'Step 6: ', description: 'Complete Your Order' },
];

export const OnboardingStepsCommercialActivateFlow: ProgressStep[] = [
  { title: 'Step 1: ', description: 'Establish Account Type' },
  { title: 'Step 2: ', description: 'Fill Out Contact Information' },
  { title: 'Step 3: ', description: 'Activate a Transponder' },
  { title: 'Step 4: ', description: 'Fill Out Vehicle Information' },
  { title: 'Step 5: ', description: 'Opt into Easy Pay and Preload Funds' },
  { title: 'Step 6: ', description: 'Complete Your Order' },
];

export const OnboardingStepsCommercialPurchaseFlow: ProgressStep[] = [
  { title: 'Step 1: ', description: 'Establish Account Type' },
  { title: 'Step 2: ', description: 'Fill Out Contact Information' },
  { title: 'Step 3: ', description: 'Purchase a Transponder' },
  { title: 'Step 4: ', description: 'Add Vehicles' },
  { title: 'Step 5: ', description: 'Opt into Easy Pay and Preload Funds' },
  { title: 'Step 6: ', description: 'Complete Your Order' },
];

export const OnboardingStepsPayg: ProgressStep[] = [
  { title: 'Step 1: ', description: 'Establish Account Type' },
  { title: 'Step 2: ', description: 'Fill Out Contact Information' },
  { title: 'Step 3: ', description: 'Fill Out Vehicle Information' },
  { title: 'Step 4: ', description: 'Opt into Easy Pay and Preload Funds' },
  { title: 'Step 5: ', description: 'Complete Your Order' },
];

export const OnboardingStepsPaygActivateFlow: ProgressStep[] = [
  { title: 'Step 1: ', description: 'Establish Account Type' },
  { title: 'Step 2: ', description: 'Fill Out Contact Information' },
  { title: 'Step 3: ', description: 'Activate a Transponder' },
  { title: 'Step 4: ', description: 'Fill Out Vehicle Information' },
  { title: 'Step 5: ', description: 'Opt into Easy Pay and Preload Funds' },
  { title: 'Step 6: ', description: 'Complete Your Order' },
];

export const OnboardingStepsPaygPurchaseFlow: ProgressStep[] = [
  { title: 'Step 1: ', description: 'Establish Account Type' },
  { title: 'Step 2: ', description: 'Fill Out Contact Information' },
  { title: 'Step 3: ', description: 'Purchase a Transponder' },
  { title: 'Step 4: ', description: 'Fill Out Vehicle Information' },
  { title: 'Step 5: ', description: 'Opt into Easy Pay and Preload Funds' },
  { title: 'Step 6: ', description: 'Complete Your Order' },
];

export const OnboardingStepsGovernment: ProgressStep[] = [
  { title: 'Step 1: ', description: 'Establish Account Type' },
  { title: 'Step 2: ', description: 'Fill Out Contact Information' },
  { title: 'Step 3: ', description: 'Review Your Information' },
];

const StepsByUserType = {
  [OnboardingUserType.RESIDENT]: OnboardingStepsResidentActivateFlow,
  [OnboardingUserType.COMMERCIAL]: OnboardingStepsCommercialActivateFlow,
  [OnboardingUserType.VISITOR]: OnboardingStepsPaygActivateFlow,
  [OnboardingUserType.GOVERNMENT]: OnboardingStepsGovernment,
};

export const getStepsByUserTypeAndFlowType = (
  userType: OnboardingUserType,
  flowType: OnboardingFlowType,
): ProgressStep[] => {
  if (flowType === OnboardingFlowType.PURCHASE) {
    switch (userType) {
      case OnboardingUserType.VISITOR:
        return OnboardingStepsPaygPurchaseFlow;
      case OnboardingUserType.COMMERCIAL:
        return OnboardingStepsCommercialPurchaseFlow;
      case OnboardingUserType.RESIDENT:
        return OnboardingStepsResidentPurchaseFlow;
    }
  }
  return StepsByUserType[userType];
};

export const PERSONAL_PLAN = /*i18n*/ `SunPass Personal Account`;
export const PERSONAL_PLUS_PLAN = /*i18n*/ `SunPass Account for Pinellas Bayway Commuters`;
export const FLEET_PLAN = /*i18n*/ `Commercial Fleet Account`;
export const FLEET_PINELLAS_PLAN = /*i18n*/ `Commercial Fleet Account + Pinellas Bayway Plan`;
export const PAYG_PLAN = /*i18n*/ `SunPass Pay-As-You-Go Account`;
export const GOVT_ACCOUNT = /*i18n*/ `SunPass Government Account`;
export const GOVT_PINELLAS_PLAN = /*i18n*/ `SunPass Government Account + Pinellas Bayway Plan`;

export const PERSONAL_PLAN_DESCRIPTION =
  'Private Accounts are for non-commercial users with owned, leased or rented vehicle and/or trailers and must meet the following requirements.';
export const PINELLAS_PLANS_DESCRIPTION =
  'The $50 yearly Commuter Pass transponder, which allows two-axle vehicles unlimited passage through all three Pinellas Bayway toll plazas without stopping, expires every year on Sept. 30, regardless of date of purchase. '; // For either PERSONAL_PLUS_PLAN or PINELLAS_STANDALONE_PLAN
export const FLEET_PLAN_DESCRIPTION =
  'Commercial Accounts are for companies or businesses with owned, leased or rented vehicle and/or trailers and must meet the following requirements.';
export const PAYG_PLAN_DESCRIPTION =
  'Meta text about the pay as you go programMeta text about the pay as you go programMeta text about the pay as you go programMeta text about the pay as you go programMeta text about the pay as you go program'; // 3-line placeholder text until description is available
export const GOVT_ACCOUNT_DESCRIPTION =
  'Government Accounts are for public agencies at the Federal, State or Local level and must meet the following requirements.';
export const NO_DESCRIPTION = 'No description';

declare global {
  interface Window {
    grecaptcha: ReCaptchaInstance;
    captchaOnLoad: () => void;
  }
}

interface ReCaptchaInstance {
  ready: (cb: () => any) => void;
  execute: (key: string | undefined, options: ReCaptchaExecuteOptions) => Promise<string>;
  render: (id: string, options: ReCaptchaRenderOptions) => any;
}

interface ReCaptchaExecuteOptions {
  action: string;
}

interface ReCaptchaRenderOptions {
  sitekey: string;
  size: 'invisible';
}

import { useEffect, useMemo, useState } from 'react';
import { IPaymentMethodForm } from 'src/components/Payments/Payments.interfaces';
import { checkIfPaymentFormIsDirty } from 'src/components/Payments/utils/checkIfPaymentFormIsDirty';

export const useCheckIfPaymentFormIsDirty = (formValues: IPaymentMethodForm) => {
  const [isFormDirty, setIsFormDirty] = useState(false);

  const memoizedFormValues = useMemo(() => formValues, [formValues]);

  useEffect(() => {
    const isDirty = checkIfPaymentFormIsDirty(formValues);
    if (isDirty) {
      setIsFormDirty(true);
    } else {
      setIsFormDirty(false);
    }
  }, [memoizedFormValues]);

  return {
    isFormDirty,
  };
};

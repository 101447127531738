import { LabelValue } from '../../../../types/LabelValue.interface';
import useVehicleMakes from './useVehicleMakes';
import { IVehicleMake } from '../../../../graphql/vehicleMakes.graphql';

interface IUseVehicleMakeOptions {
  vehicleMakeOptions: LabelValue[];
  errorLoading: boolean;
  loading: boolean;
}

const useVehicleMakeOptions = (): IUseVehicleMakeOptions => {
  const { vehicleMakes, errorLoading, loading } = useVehicleMakes();
  return {
    vehicleMakeOptions:
      vehicleMakes?.map(
        (vehicleMake: IVehicleMake): LabelValue => ({ value: vehicleMake.name, label: vehicleMake.name }),
      ) || [],
    errorLoading: errorLoading,
    loading: loading,
  };
};

export default useVehicleMakeOptions;

import React, { FC, useEffect, useMemo, useState } from 'react';
import { Trans } from '@lingui/macro';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from 'antd';
import PaymentMethodCard from './PaymentMethodCard';
import PaymentMethodBank from './PaymentMethodBank';
import { FormRequiredFieldsMsg } from '../../Onboarding/Account/FormRequiredFieldsMsg';
import { MainLoader } from '../../Loader/MainLoader';
import CustomAddress from '../../Address/CustomAddress';
import { useScroll } from '../../../hooks/screen/useScroll';
import SaveForFutureCheckBox from '../NewPaymentMethodElements/SaveForFuture';
import PaymentMethodFormField, { PaymentMethodType } from '../Payments.enums';
import { IPaymentMethod, IPaymentMethodForm } from '../Payments.interfaces';
import SelectPaymentMethod from '../NewPaymentMethodElements/SelectPaymentMethod';
import { defaultPaymentValues } from '../NewPaymentMethodsDefaultValues';
import { usePaymentValidatorSchema } from '../validation/usePaymentValidatorSchema';
import LeaveSiteConfirmationModal from '../../Modals/ConfirmationModal/LeaveSiteConfirmationModal';
import { useCheckIfPaymentFormIsDirty } from 'src/hooks/useCheckIfPaymentFormIsDirty';
import { useCallbackPrompt } from 'src/hooks/location/useCallbackPrompt';
import './NewPaymentMethodContent.scss';
import SignUpForEasyPay from '../NewPaymentMethodElements/SignUpForEasyPay';
import { useSortPaymentMethods } from '../../../hooks/domain/payments/useSortPaymentMethods';

export interface INewPaymentMethodContent {
  isOnboardingPayment: boolean;
  defaultFormValues: Partial<IPaymentMethod> | undefined;
  onSubmit(paymentMethod: IPaymentMethod): void;
  isLoading: boolean;
  navigationElements?: JSX.Element;
  defaultPaymentMethod: PaymentMethodType | undefined;
  isEditMode?: boolean;
  shouldBeWrappedInCard: boolean;
  showCVV: boolean;
  setIsPaymentFormDirty?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCardSaved?: React.Dispatch<React.SetStateAction<boolean>>;
  isOptInEasyPay?: boolean;
  isAddingPaymentMethod?: boolean;
  diasbleEasyPayEnrollment?: boolean;
  isEasyPayMethod?: boolean;
  shouldShowSaveForFutureCheckBox?: boolean;
  isOneTimePayment?: boolean;
  setReplenishmentAmount?: (value: string) => void;
}

const NewPaymentMethodContent: FC<INewPaymentMethodContent> = ({
  isOnboardingPayment,
  defaultFormValues,
  onSubmit,
  isLoading,
  navigationElements,
  defaultPaymentMethod,
  isEditMode = false,
  shouldBeWrappedInCard,
  showCVV,
  setIsPaymentFormDirty,
  setIsCardSaved,
  isAddingPaymentMethod,
  isEasyPayMethod,
  diasbleEasyPayEnrollment,
  shouldShowSaveForFutureCheckBox,
  isOneTimePayment,
  setReplenishmentAmount,
}) => {
  const { wholePaymentSchema, areCardTypesLading } = usePaymentValidatorSchema(showCVV);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [paymentMethodType, setPaymentMethodType] = useState<PaymentMethodType>(
    defaultPaymentMethod || PaymentMethodType.CARD,
  );

  const {
    sortedPaymentMethods,
    storedCards,
    methodsWithEasyPay,
    doesUserHaveCardOrAccountWithEZAndStoredCard,
    doesUserHaveOnlyOnePMWithEasyPayAndNoStoredCards,
    doesUserHaveNoPaymentMethodsAndStoredCard,
  } = useSortPaymentMethods(true, isOnboardingPayment);

  const methods = useForm<IPaymentMethodForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(wholePaymentSchema),
    defaultValues: defaultFormValues,
  });

  const {
    getValues,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = methods;
  const fieldNames = Object.values(PaymentMethodFormField);

  const watchValues = watch(fieldNames);

  const formValues = useMemo(() => getValues(), [watchValues]);

  const { isFormDirty } = useCheckIfPaymentFormIsDirty(formValues);

  const { showPrompt, cancelRedirect, confirmRedirect } = useCallbackPrompt(isFormDirty);

  const paymentTypeWatch = watch(PaymentMethodFormField.PAYMENT_TYPE);

  const confirmRedirectUser = () => {
    reset();
    confirmRedirect();
  };

  useEffect(() => {
    if (setReplenishmentAmount) {
      const replenishmentAmount: string = watch('replenishmentThresholdAmount');
      setReplenishmentAmount(replenishmentAmount);
    }
  }, [watch('replenishmentThresholdAmount')]);

  const onDirtyChangeContinue = () => {
    if (showPrompt) {
      confirmRedirectUser();
    } else {
      reset({ ...defaultPaymentValues, paymentType: paymentMethodType });
      setIsModalVisible(false);
    }
  };

  const onDirtyChangeCancel = () => {
    if (showPrompt) {
      cancelRedirect();
    } else {
      setIsModalVisible(false);
    }
  };

  const onChangePaymentType = (paymentType: PaymentMethodType) => {
    setPaymentMethodType(paymentType);
    if (isFormDirty) {
      setIsModalVisible(true);
    } else {
      reset({ ...defaultPaymentValues, paymentType: paymentType });
    }
  };

  useEffect(() => {
    setIsPaymentFormDirty && setIsPaymentFormDirty(isFormDirty);
  }, [isFormDirty]);

  const { scrollToError } = useScroll();

  useEffect(() => {
    scrollToError(errors);
  }, [errors]);

  useEffect(() => {
    if (setIsCardSaved) {
      !!watch('saveCard') ? setIsCardSaved(watch('saveCard')) : setIsCardSaved(false);
    }
  }, [watch('saveCard'), paymentMethodType]);

  const showEasyPayOptions = isEditMode
    ? true
    : isOneTimePayment === true
    ? false
    : doesUserHaveOnlyOnePMWithEasyPayAndNoStoredCards ||
      doesUserHaveCardOrAccountWithEZAndStoredCard ||
      (methodsWithEasyPay?.length === 0 && storedCards?.length !== 0) ||
      sortedPaymentMethods?.length === 0;

  useEffect(() => {
    if (!(shouldShowSaveForFutureCheckBox && paymentMethodType === PaymentMethodType.CARD)) {
      setValue(PaymentMethodFormField.SAVE_CARD, false);
    } else {
      setValue(PaymentMethodFormField.SAVE_CARD, true);
    }
  }, [shouldShowSaveForFutureCheckBox]);

  const disableEasyPayOption = isEditMode
    ? !!isEasyPayMethod
    : isAddingPaymentMethod
    ? doesUserHaveCardOrAccountWithEZAndStoredCard ||
      doesUserHaveNoPaymentMethodsAndStoredCard ||
      (paymentTypeWatch === PaymentMethodType.BANK && doesUserHaveOnlyOnePMWithEasyPayAndNoStoredCards) ||
      (sortedPaymentMethods?.length === 0 && paymentTypeWatch === PaymentMethodType.BANK)
    : false;

  useEffect(() => {
    if (
      (sortedPaymentMethods?.length === 0 || (methodsWithEasyPay?.length === 1 && storedCards?.length === 0)) &&
      paymentTypeWatch === PaymentMethodType.CARD
    ) {
      setValue(PaymentMethodFormField.IS_EASY_PAY, undefined);
    } else {
      setValue(PaymentMethodFormField.IS_EASY_PAY, disableEasyPayOption);
    }
  }, [disableEasyPayOption, sortedPaymentMethods, paymentMethodType]);

  return (
    <>
      <MainLoader loading={isLoading || areCardTypesLading} />
      {!isEditMode ? (
        <SelectPaymentMethod
          onChangePaymentType={onChangePaymentType}
          paymentMethodType={paymentTypeWatch}
          onlyCreditCard={isAddingPaymentMethod && storedCards?.length === 0 && methodsWithEasyPay?.length === 2}
        />
      ) : null}
      <FormProvider {...methods}>
        <Form className="payment-method-form" layout="vertical" size="large" onFinish={handleSubmit(onSubmit)}>
          <section className={shouldBeWrappedInCard ? 'card--vertical my-10' : ' my-10 sm:px-8 md:px-12'}>
            <div className="new-payment-content">
              {paymentTypeWatch == PaymentMethodType.CARD && <PaymentMethodCard showCVV={showCVV} />}
              {paymentTypeWatch == PaymentMethodType.BANK && <PaymentMethodBank />}
              <h1 className="payment-address mb-3 mt-8 text-left text-3xl text-primary-blue1">
                <Trans>Billing Address</Trans>
              </h1>
              <CustomAddress countyRequired={false} />
              {showEasyPayOptions && !diasbleEasyPayEnrollment ? (
                <SignUpForEasyPay
                  isAddingPaymentMethod={isAddingPaymentMethod}
                  isEditMode={isEditMode}
                  isEasyPayMethod={isEasyPayMethod}
                  disableEasyPayOption={disableEasyPayOption}
                />
              ) : null}
              {shouldShowSaveForFutureCheckBox &&
              paymentMethodType === PaymentMethodType.CARD &&
              (doesUserHaveOnlyOnePMWithEasyPayAndNoStoredCards || sortedPaymentMethods?.length === 0) &&
              watch(PaymentMethodFormField.IS_EASY_PAY) === false ? (
                <SaveForFutureCheckBox disabled={!isOnboardingPayment && isAddingPaymentMethod} />
              ) : null}
            </div>
          </section>
          <FormRequiredFieldsMsg />
          <>{navigationElements}</>
        </Form>
      </FormProvider>
      <LeaveSiteConfirmationModal
        showModal={isModalVisible || showPrompt}
        onOk={onDirtyChangeContinue}
        onCancel={onDirtyChangeCancel}
      />
    </>
  );
};

export default NewPaymentMethodContent;

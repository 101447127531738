import { DeepRequired, FieldErrorsImpl, FieldValues } from 'react-hook-form';

export const buildErrorString = (
  fieldName: string,
  errors: FieldErrorsImpl<DeepRequired<FieldValues>>,
  index?: number,
) => {
  let str1;
  let str2;
  let str3;
  if (index !== undefined) {
    const nameOfFormAndElementIndex = fieldName.split('.')[0].replaceAll(/\W/g, '');
    str1 = nameOfFormAndElementIndex.slice(0, -1);
    str2 = nameOfFormAndElementIndex.slice(-1);
    str3 = fieldName.split('.')[1];
  }

  const errorString =
    index !== undefined ? errors?.[`${str1}`]?.[`${str2}`]?.[`${str3}`]?.message : errors?.[`${fieldName}`]?.message;

  return errorString as unknown as string;
};

export const getInputName = (name: string, index?: number) => {
  return index !== undefined ? `vehicles[${index}].${name}` : name;
};

import { useQuery } from '@apollo/client';
import { COUNTIES } from '../../../graphql/counties.graphql';
import { LabelValue } from '../../../types/LabelValue.interface';
import { FL_STATE_CODE } from '../../../utils/countryStates.utils';
import { useState } from 'react';

interface IUseCountyOptionsInput {
  state: string | null;
}
interface IUseCountyOptions {
  countyOptions: LabelValue[];
  loading: boolean;
  errorLoading: boolean;
}

const useCountyOptions = ({ state }: IUseCountyOptionsInput): IUseCountyOptions => {
  const [isError, setIsError] = useState(false);
  const { data, loading } = useQuery(COUNTIES, {
    errorPolicy: 'all',
    onError: () => setIsError(true),
  });
  return {
    countyOptions:
      state === FL_STATE_CODE
        ? data?.counties?.map((county: { id: string; name: string }, index: number) => ({
            value: index + 1,
            label: county.name,
          })) || []
        : [],
    errorLoading: isError,
    loading,
  };
};

export default useCountyOptions;

import { Trans } from '@lingui/macro';
import { FC } from 'react';
import TableRow from 'src/components/Payments/PaymentSummary/OneTimePurchase/TableRow';
import { useQuery } from '@apollo/client';
import { MINIMUM_AMOUNT } from '../../../../graphql/minimumAmount.graphql';
import { AccountType } from '../../../../types/onboarding.types';
import { GET_ACCOUNT_DETAILS, IAccountDetails } from '../../../../graphql/accountDetails.graphql';
interface IPurchaseInformationTable {
  ballance: number;
  isMinimumRequired?: boolean;
}
const PurchaseInformationTable: FC<IPurchaseInformationTable> = ({ ballance, isMinimumRequired }) => {
  const owedTolls = Math.abs(ballance);

  const minimumAmounts = useQuery(MINIMUM_AMOUNT)?.data?.minimumAmount;

  const { data: accountData } = useQuery<{ accountDetails: IAccountDetails }>(GET_ACCOUNT_DETAILS, {
    nextFetchPolicy: 'cache-and-network',
  });

  const minimumDepositAmount =
    accountData?.accountDetails.accountType !== AccountType.COMMERCIAL
      ? minimumAmounts?.minimumDepositPersonal || 0
      : minimumAmounts?.minimumDepositFleet || 0;

  const subtotal = minimumDepositAmount + owedTolls;
  return (
    <div>
      <h4 className="my-5 text-center text-primary-blue1 lg:my-10">
        <Trans>SunPass users save an average of 25% on their tolls by having a SunPass account!</Trans>
      </h4>
      <h3 className="text-primary-blue1">
        <Trans>Unpaid Tolls Total</Trans>
      </h3>
      <div className="mx-8">
        <table className="w-full">
          <tbody>
            <TableRow col1={<Trans>All owed unpaid tolls</Trans>} col4={`$${owedTolls.toFixed(2)}`} />
            {isMinimumRequired ? (
              <TableRow
                col1={
                  <Trans>
                    Amount required to restore minimum account balance <br />
                    ($5.00 for private accounts)
                  </Trans>
                }
                col4={minimumDepositAmount}
              />
            ) : null}

            <TableRow
              className="border-t border-gray-300"
              col1={
                <strong>
                  <Trans>Subtotal</Trans>
                </strong>
              }
              col4={subtotal}
            />
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default PurchaseInformationTable;

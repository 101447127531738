import { create } from '../createStore';
import { devtools, persist } from 'zustand/middleware';
import { clearSession, setAccessToken, setRefreshToken } from '../../security/token.service';
import { IAuthToken } from '../../graphql/loginUser.graphql';
import { ONBOARDING_INITIAL_STATE, useOnboardingStore } from '../onboarding/onboarding.store';
import { sessionStorageService } from '../../services/sessionStorage';

export interface IAuthContext {
  details: IAuthContextDetails;
  authenticateLogin: (tokens: IAuthToken) => void;
  authenticationFailure: () => void;
  invalidateAuthentication: (blockDefaultRedirect?: boolean) => void;
  reminderDismissed: () => void;
  resetAuthError: () => void;
}

export interface IAuthContextDetails {
  isAuthenticated: boolean;
  authError: boolean;
  isReminderShownToUser: boolean;
}

const AUTH_CONTEXT_INITIAL_STATE: IAuthContextDetails = {
  isAuthenticated: false,
  authError: false,
  isReminderShownToUser: false,
};

export const useAuth = create<IAuthContext, [['zustand/devtools', IAuthContext], ['zustand/persist', IAuthContext]]>(
  devtools(
    persist(
      (set) => ({
        details: AUTH_CONTEXT_INITIAL_STATE,
        authenticateLogin: ({ token, refreshToken }) => {
          setAccessToken(token);
          setRefreshToken(refreshToken);
          set((state) => ({
            details: {
              ...state.details,
              authError: false,
              referenceId: undefined,
              isAuthenticated: true,
              isReminderShownToUser: true,
            },
          }));
        },
        authenticationFailure: () => {
          set((state) => ({
            details: {
              ...state.details,
              authError: true,
              isAuthenticated: false,
              isReminderShownToUser: false,
              referenceId: undefined,
            },
          }));
        },
        invalidateAuthentication: (blockDefaultRedirect = false) => {
          !blockDefaultRedirect && window.history.pushState({}, '', '/');
          useOnboardingStore.setState({
            onboardingInfo: ONBOARDING_INITIAL_STATE,
          });
          sessionStorageService.clear();
          set((state) => ({
            details: {
              ...state.details,
              authError: false,
              isAuthenticated: false,
              isReminderShownToUser: false,
              referenceId: undefined,
            },
          }));
          clearSession();
        },
        reminderDismissed: () => {
          set((state) => ({
            details: {
              ...state.details,
              isReminderShownToUser: false,
            },
          }));
        },
        resetAuthError: () => {
          set((state) => ({
            details: {
              ...state.details,
              authError: false,
            },
          }));
        },
      }),
      {
        name: 'auth-context-storage',
        getStorage: () => localStorage,
      },
    ),
  ),
);

import { Form } from 'antd';
import { t, Trans } from '@lingui/macro';
import { Controller, useFormContext } from 'react-hook-form';
import StyledInput from '../../../StyledInput/StyledInput';
import React from 'react';
import { useAddressFields } from '../../../../hooks/domain/address/useAddressFields';
import { IAddressFormElemBaseProps } from './address.types';

export const City = ({ isAddressUsedForPayment, disabled, className }: IAddressFormElemBaseProps) => {
  const {
    addressFieldsState: { cityField },
  } = useAddressFields(isAddressUsedForPayment);
  const { control } = useFormContext();
  return (
    <div className={className}>
      <Form.Item className="theme" label={<Trans>City</Trans>} name={cityField.name} help={cityField.errorMsg} required>
        <Controller
          name={cityField.name}
          control={control}
          render={({ field, fieldState }) => (
            <StyledInput
              disabled={disabled}
              field={field}
              fieldstate={fieldState}
              id={cityField.name}
              key={cityField.name}
              maxLength={40}
              aria-label={t`City`}
            />
          )}
        />
      </Form.Item>
    </div>
  );
};

import { isEqual } from 'lodash';
import { FL_STATE_CODE, USA_COUNTRY_ISO3 } from 'src/utils/countryStates.utils';
import MailingAddressFormField from '../../Address/AddressFormField.enum';
import PaymentMethodFormField from '../Payments.enums';
import { IPaymentMethodForm } from '../Payments.interfaces';

export const checkIfPaymentFormIsDirty = (values: IPaymentMethodForm): boolean => {
  return !isEqual(defaultValues, getPaymentDataFrom(values));
};
const defaultValues = {
  [PaymentMethodFormField.CC_FIRST_NAME]: false,
  [PaymentMethodFormField.CC_MIDDLE_NAME]: false,
  [PaymentMethodFormField.CC_LAST_NAME]: false,
  [PaymentMethodFormField.CC_NUMBER]: false,
  [PaymentMethodFormField.CC_EXPIRATION_MONTH]: false,
  [PaymentMethodFormField.CC_EXPIRATION_YEAR]: false,
  [PaymentMethodFormField.CC_SECURITY_CODE]: false,
  [PaymentMethodFormField.BANK_ACCOUNT_NUMBER]: false,
  [PaymentMethodFormField.BANK_FIRST_NAME]: false,
  [PaymentMethodFormField.BANK_LAST_NAME]: false,
  [PaymentMethodFormField.BANK_ACCOUNT_NUMBER_CONFIRM]: false,
  [PaymentMethodFormField.BANK_ROUTING_NUMBER]: false,
  [MailingAddressFormField.ADDRESS_LINE_1]: false,
  [MailingAddressFormField.ADDRESS_LINE_2]: false,
  [MailingAddressFormField.CITY]: false,
  [MailingAddressFormField.STATE]: false,
  [MailingAddressFormField.POSTAL_CODE]: false,
  [MailingAddressFormField.COUNTRY]: false,
};

const getPaymentDataFrom = (values: IPaymentMethodForm) => {
  return {
    [PaymentMethodFormField.CC_FIRST_NAME]: !!values[PaymentMethodFormField.CC_FIRST_NAME],
    [PaymentMethodFormField.CC_MIDDLE_NAME]: !!values[PaymentMethodFormField.CC_MIDDLE_NAME],
    [PaymentMethodFormField.CC_LAST_NAME]: !!values[PaymentMethodFormField.CC_LAST_NAME],
    [PaymentMethodFormField.CC_NUMBER]: !!values[PaymentMethodFormField.CC_NUMBER],
    [PaymentMethodFormField.CC_EXPIRATION_MONTH]: !!values[PaymentMethodFormField.CC_EXPIRATION_MONTH],
    [PaymentMethodFormField.CC_EXPIRATION_YEAR]: !!values[PaymentMethodFormField.CC_EXPIRATION_YEAR],
    [PaymentMethodFormField.CC_SECURITY_CODE]: !!values[PaymentMethodFormField.CC_SECURITY_CODE],
    [PaymentMethodFormField.BANK_ACCOUNT_NUMBER]: !!values[PaymentMethodFormField.BANK_ACCOUNT_NUMBER],
    [PaymentMethodFormField.BANK_FIRST_NAME]: !!values[PaymentMethodFormField.BANK_FIRST_NAME],
    [PaymentMethodFormField.BANK_LAST_NAME]: !!values[PaymentMethodFormField.BANK_LAST_NAME],
    [PaymentMethodFormField.BANK_ACCOUNT_NUMBER_CONFIRM]: !!values[PaymentMethodFormField.BANK_ACCOUNT_NUMBER_CONFIRM],
    [PaymentMethodFormField.BANK_ROUTING_NUMBER]: !!values[PaymentMethodFormField.BANK_ROUTING_NUMBER],
    [MailingAddressFormField.ADDRESS_LINE_1]: !!values[MailingAddressFormField.ADDRESS_LINE_1],
    [MailingAddressFormField.ADDRESS_LINE_2]: !!values[MailingAddressFormField.ADDRESS_LINE_2],
    [MailingAddressFormField.CITY]: !!values[MailingAddressFormField.CITY],
    [MailingAddressFormField.STATE]: values[MailingAddressFormField.STATE] !== FL_STATE_CODE,
    [MailingAddressFormField.POSTAL_CODE]: !!values[MailingAddressFormField.POSTAL_CODE],
    [MailingAddressFormField.COUNTRY]: values[MailingAddressFormField.COUNTRY] !== USA_COUNTRY_ISO3,
  };
};

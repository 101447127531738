import { gql } from '@apollo/client';
import { IPaymentMethod } from 'src/components/Payments/Payments.interfaces';
import { IOnboardingInfo } from 'src/types/onboarding.types';
import { TransponderType } from '../transponders.graphql';

export const SIGN_UP_ACCOUNT_PLANS = gql`
  mutation accountPlansSignUp($signUpPlansInput: SignUpPlansInput!) {
    signUpPlans(signUpPlansInput: $signUpPlansInput) {
      accountId
    }
  }
`;

export interface ISignUpPlansInput {
  accountId: string;
  miniTransponderSerialNumber?: string;
  proTransponderSerialNumber?: string;
  planList: IPlanSignUpInput[];
  paymentDetails: IPaymentDetailsSignUpInput;
  saveCard?: string;
  promoCode?: string;
  reBillThresholdAmount?: number;
}

export interface IPlanSignUpInput {
  quantity: number;
  transponderType: string;
}

export interface ISignUpPlansOutput {
  accountId: string;
}

export interface IPaymentDetailsSignUpInput {
  bankRoutingNumber?: string;
  bankAccountNumber?: string;
  payType?: string;
  cardNumber?: string;
  expirationMonth?: number;
  expirationYear?: number;
  securityCode?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  country?: string;
  zipcode?: string;
}

export const getAccountPlansInputFrom = (
  {
    addressLine1,
    addressLine2,
    bankAccountNumber,
    bankRoutingNumber,
    ccNumber,
    city,
    country,
    ccExpirationMonth,
    ccExpirationYear,
    paymentType,
    bankFirstName,
    bankLastName,
    ccFirstName,
    ccLastName,
    ccMiddleName,
    ccSecurityCode,
    postalCode,
    state,
    bankAccountType,
  }: IPaymentMethod,
  onboardingData: IOnboardingInfo,
): ISignUpPlansInput => {
  const {
    transponderSerialNumbers: { miniTransponderSerialNumber, proTransponderSerialNumber },
    accountId,
    cardType,
  } = onboardingData;

  return {
    accountId: accountId,
    paymentDetails: {
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      bankAccountNumber: bankAccountNumber,
      bankRoutingNumber: bankRoutingNumber,
      cardNumber: ccNumber,
      city: city,
      country: country,
      expirationMonth: ccExpirationMonth,
      expirationYear: ccExpirationYear,
      firstName: paymentType === 'bank' ? bankFirstName : ccFirstName,
      middleName: ccMiddleName,
      lastName: paymentType === 'bank' ? bankLastName : ccLastName,
      payType: paymentType === 'bank' ? bankAccountType : cardType,
      zipcode: postalCode,
      securityCode: ccSecurityCode,
      state: state,
    },
    proTransponderSerialNumber: proTransponderSerialNumber,
    miniTransponderSerialNumber: miniTransponderSerialNumber,
    planList: getPlanListFrom(onboardingData),
  };
};

const getPlanListFrom = ({
  miniTransponderQty,
  accountSubtype,
  proTransponderQty,
}: IOnboardingInfo): IPlanSignUpInput[] => {
  let planList: IPlanSignUpInput[] = [];
  console.log(miniTransponderQty, proTransponderQty);
  if (miniTransponderQty !== 0 && accountSubtype) {
    planList = [
      ...planList,
      {
        quantity: miniTransponderQty,
        transponderType: TransponderType.MINI,
      },
    ];
  }
  if (proTransponderQty !== 0 && accountSubtype) {
    planList = [
      ...planList,
      {
        quantity: proTransponderQty,
        transponderType: TransponderType.PRO,
      },
    ];
  }
  return planList;
};

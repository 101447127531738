import { PaymentMethodType } from 'src/components/Payments/Payments.enums';
import { EasyPayOption } from 'src/graphql/payment.graphql';
import { ICloseAccountInfo } from 'src/store/account/contactSettings.store';
import { IPurchaseTransponderInput } from 'src/store/multiStepPayment/multiStepPayment.store';

export const mapPaymentInfo = (
  newPaymentInfo: IPurchaseTransponderInput,
  contactStore: ICloseAccountInfo,
  cardType?: string,
  paymentAmount?: number,
) => {
  if (!contactStore.isNewCardChosen) {
    const { existingCard } = contactStore;
    return {
      paymentAmount: paymentAmount || 0,
      paymentMethodId: existingCard?.paymentMethodId || '',
      bankRoutingNumber: existingCard?.bankRoutingNumber || '',
      bankAccountNumber: existingCard?.bankAccountNumber || '',
      promoCode: '',
      cardType: existingCard?.bankAccountNumber ? '' : existingCard?.payType,
      cardNumber: existingCard?.cardNumber || '',
      expirationMonth: existingCard?.expirationMonth,
      expirationYear: existingCard?.expirationYear,
      securityCode: existingCard?.securityCode || '',
      firstName: existingCard?.firstName || '',
      middleName: existingCard?.middleName || '',
      lastName: existingCard?.lastName || '',
      addressLine1: existingCard?.addressLine1 || '',
      addressLine2: existingCard?.addressLine2 || '',
      city: existingCard?.city || '',
      state: existingCard?.state || '',
      country: existingCard?.country || '',
      zipCode: existingCard?.zipCode || '',
      easyPayOption: existingCard?.isEasyPay ? EasyPayOption.EASY_PAY : EasyPayOption.STORED_CARD,
      additionalFundAmount: 0,
      numOfProTransponders: newPaymentInfo?.numOfProTransponders,
      numOfStickerTransponders: newPaymentInfo?.numOfStickerTransponders,
    };
  } else {
    const { paymentMethod } = newPaymentInfo;

    return {
      paymentAmount: paymentAmount || 0,
      paymentMethodId: newPaymentInfo?.paymentMethodId || '',
      bankRoutingNumber: paymentMethod?.bankRoutingNumber || '',
      bankAccountNumber: paymentMethod?.bankAccountNumber || '',
      promoCode: newPaymentInfo.promoCode,
      cardType: paymentMethod?.paymentType === PaymentMethodType.BANK ? paymentMethod.bankAccountType : cardType,
      cardNumber: paymentMethod?.ccNumber || '',
      expirationMonth: paymentMethod?.ccExpirationMonth,
      expirationYear: paymentMethod?.ccExpirationYear,
      securityCode: paymentMethod?.ccSecurityCode || '',
      firstName: paymentMethod?.ccFirstName || paymentMethod?.bankFirstName || '',
      middleName: paymentMethod?.ccMiddleName || '',
      lastName: paymentMethod?.ccLastName || paymentMethod?.bankLastName || '',
      addressLine1: paymentMethod?.addressLine1 || '',
      addressLine2: paymentMethod?.addressLine2 || '',
      city: paymentMethod?.city || '',
      state: paymentMethod?.state || '',
      country: paymentMethod?.country || '',
      zipCode: paymentMethod?.postalCode || '',
      easyPayOption: newPaymentInfo.easyPayOption ? EasyPayOption.EASY_PAY : EasyPayOption.NONE,
      additionalFundAmount: 0,
      numOfProTransponders: newPaymentInfo?.numOfProTransponders,
      numOfStickerTransponders: newPaymentInfo?.numOfStickerTransponders,
    };
  }
};

import { useQuery } from '@apollo/client';
import { DEFAULT_SHORT_VEHICLES_FETCH_PARAM } from 'src/components/Dashboard/Transponders/TransponderList/defaultValues';
import { GET_VEHICLE_LIST, IVehicleList, IVehicleListInput } from 'src/graphql/vehicles.graphql';
import { usePaginationInfo } from 'src/store/pagination/pagination.store';

const useGetVehicles = (shortList = false) => {
  const {
    vehiclesPagination: { size, page },
  } = usePaginationInfo();

  const vehicleListInput: IVehicleListInput = shortList
    ? DEFAULT_SHORT_VEHICLES_FETCH_PARAM
    : {
        pagination: {
          pageSize: size,
          pageNumber: page,
        },
      };

  const {
    data: vehiclesData,
    loading: vehiclesLoading,
    refetch,
  } = useQuery<{ vehicleList: IVehicleList }, { vehicleListInput: IVehicleListInput }>(GET_VEHICLE_LIST, {
    variables: {
      vehicleListInput,
    },
    notifyOnNetworkStatusChange: true,
  });

  return { vehiclesData, vehiclesLoading, refetch };
};

export default useGetVehicles;

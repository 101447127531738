import { Trans } from '@lingui/macro';

import { isNil } from 'lodash';
import React, { FC, useEffect, useState } from 'react';

import ReloadButton from 'src/components/ReloadButton/ReloadButton';
import { PURCHASE_TRANSPONDER_SECTION_ID } from '../../Dashboard/references';
import useTransponderPrices from '../../../hooks/domain/dashboard/transponders/useTransponderPrices';
import { TransponderType } from '../../../graphql/transponders.graphql';
import { TransponderInfoCard } from './TransponderInfoCard';
import { TransponderSelectCard } from './TransponderSelectCard';
import { usePurchaseTransponderInputContent } from './usePurchaseTransponderInputContent';
import {
  MAX_TRANSPONDER_PLANS_ERROR_MSG,
  NO_TRANSPONDERS_TO_ADD_PLAN,
} from '../../../validators/onboarding/vehicles/vehicleErrorMessages';
import { useOnboardingStore } from '../../../store/onboarding/onboarding.store';
import { SELECT_DETAILS } from '../../../store/onboarding/onboarding.selectors';
import { AccountSubtype, AccountType } from '../../../types/onboarding.types';
import { GET_ACCOUNT_DETAILS, IAccountDetails } from '../../../graphql/accountDetails.graphql';
import { useFormContext } from 'react-hook-form';
import { generateLimitMessage } from 'src/components/Dashboard/Transponders/transponders.utils';
import { useMultiStepPaymentStore } from 'src/store/multiStepPayment/multiStepPayment.store';
import { useQuery } from '@apollo/client';

export type IPurchaseTransponderQtyInputType = 'regular' | 'assignedToPlan';

interface IPurchaseTranspondersContent {
  isDashboard: boolean;
  showErrorMini?: boolean;
  showErrorPro?: boolean;
  isOptInPBCMini: boolean;
  isOptInPBCPro: boolean;
  setIsOptInPBCMini: React.Dispatch<React.SetStateAction<boolean>>;
  setIsOptInPBCPro: React.Dispatch<React.SetStateAction<boolean>>;
  account?: IAccountDetails;
}

const PurchaseTranspondersContent: FC<IPurchaseTranspondersContent> = ({
  isDashboard,
  showErrorMini,
  showErrorPro,
  isOptInPBCMini,
  isOptInPBCPro,
  setIsOptInPBCMini,
  setIsOptInPBCPro,
  account,
}) => {
  const isValid = true;
  const isSubmitted = false;
  const { miniPrice, proPrice, errorLoading } = useTransponderPrices();
  const [limitMessage, setLimitMessage] = useState<string | null>(null);
  let accountType;
  if (isDashboard) {
    const { data: accountData } = useQuery<{ accountDetails: IAccountDetails }>(GET_ACCOUNT_DETAILS);
    accountType = accountData?.accountDetails.accountType;
  } else {
    accountType = useOnboardingStore(SELECT_DETAILS).accountType;
  }
  const { accountSubtype } = useOnboardingStore(SELECT_DETAILS);
  const isPBCAccount = accountSubtype === AccountSubtype.PBC;
  const { miniTransponderQty, proTransponderQty, subTotal } = usePurchaseTransponderInputContent(
    isDashboard,
    isOptInPBCMini,
    isOptInPBCPro,
    isPBCAccount,
  );
  const [hasUserChangedInputs, setHasUserChangedInputs] = useState(false);
  const [showAddPlanMiniWarningMessage, setShowAddPlanMiniWarningMessage] = useState(false);
  const [showAddPlanProWarningMessage, setShowAddPlanProWarningMessage] = useState(false);
  const {
    paymentInfo: { numOfProTransponders, numOfStickerTransponders },
  } = useMultiStepPaymentStore();
  const proQty = !isDashboard ? useFormContext().watch('proTransponderQty') || 0 : 0;
  const miniQty = !isDashboard ? useFormContext().watch('miniTransponderQty') || 0 : 0;

  useEffect(() => {
    if (accountSubtype !== AccountSubtype.PBC) {
      if (isOptInPBCMini && miniQty === 0) {
        setShowAddPlanMiniWarningMessage(true);
        setIsOptInPBCMini(false);
      } else if (miniQty > 0) {
        setShowAddPlanMiniWarningMessage(false);
      }
      if (isOptInPBCPro && proQty === 0) {
        setShowAddPlanProWarningMessage(true);
        setIsOptInPBCPro(false);
      } else if (proQty > 0) {
        setShowAddPlanProWarningMessage(false);
      }
    } else {
      setShowAddPlanProWarningMessage(false);
      setShowAddPlanMiniWarningMessage(false);
    }
  }, [miniQty, proQty, isOptInPBCMini, isOptInPBCPro]);

  useEffect(() => {
    if (numOfStickerTransponders !== 0 || numOfProTransponders !== 0) {
      setHasUserChangedInputs(true);
    }
  }, [numOfProTransponders, numOfStickerTransponders]);

  useEffect(() => {
    hasUserChangedInputs &&
      setLimitMessage(
        generateLimitMessage(
          numOfStickerTransponders,
          numOfProTransponders,
          account?.accountType || AccountType.PRIVATE,
        ),
      );
  }, [numOfProTransponders, numOfStickerTransponders, hasUserChangedInputs]);

  return (
    <>
      {errorLoading || isNil(proPrice) || isNil(miniPrice) ? (
        <div className="page-error-container">
          <div className="page-error-message">
            <Trans>
              Unable to load this form. Please reload the page and try again.<br></br>We are sorry for the
              inconvenience.
            </Trans>
          </div>
          <ReloadButton />
        </div>
      ) : (
        <>
          <span id={PURCHASE_TRANSPONDER_SECTION_ID}></span>
          {isDashboard ? (
            <div className="mt-6 text-center text-3xl font-bold text-primary-blue1">Purchase Transponders</div>
          ) : null}
          <div id="purchaseTranspondersQty" className="mt-8 flex w-full flex-col flex-wrap justify-center md:flex-row">
            <div
              className={
                accountType === AccountType.PRIVATE && accountSubtype === AccountSubtype.PBC
                  ? 'flex justify-center'
                  : 'info-cards flex flex-col justify-between md:flex-row'
              }
            >
              <TransponderInfoCard transponderType={TransponderType.MINI} isDashboard={isDashboard} />
              {accountType === AccountType.PRIVATE && accountSubtype === AccountSubtype.PBC && !isDashboard ? null : (
                <TransponderInfoCard transponderType={TransponderType.PRO} isDashboard={isDashboard} />
              )}
            </div>
            <div className="w-full">
              <TransponderSelectCard
                quantity={miniTransponderQty}
                transponderType={TransponderType.MINI}
                isDashboard={isDashboard}
                isOptInPBCMini={isOptInPBCMini}
                isOptInPBCPro={isOptInPBCPro}
                setIsOptInPBCMini={setIsOptInPBCMini}
                setIsOptInPBCPro={setIsOptInPBCPro}
                account={account}
              />
              {showErrorMini && !showAddPlanMiniWarningMessage && !isPBCAccount ? (
                <div className="form-error mb-5 mt-5 text-center">{MAX_TRANSPONDER_PLANS_ERROR_MSG}</div>
              ) : null}
              {showAddPlanMiniWarningMessage && (
                <div className="form-error mb-5 mt-5 text-center">{NO_TRANSPONDERS_TO_ADD_PLAN}</div>
              )}
            </div>
            {accountType === AccountType.PRIVATE && accountSubtype === AccountSubtype.PBC && !isDashboard ? null : (
              <div className="w-full">
                <TransponderSelectCard
                  quantity={proTransponderQty}
                  transponderType={TransponderType.PRO}
                  isDashboard={isDashboard}
                  isOptInPBCMini={isOptInPBCMini}
                  isOptInPBCPro={isOptInPBCPro}
                  setIsOptInPBCMini={setIsOptInPBCMini}
                  setIsOptInPBCPro={setIsOptInPBCPro}
                  account={account}
                />
                {showErrorPro && !showAddPlanProWarningMessage && (
                  <div className="form-error mt-5 text-center">{MAX_TRANSPONDER_PLANS_ERROR_MSG}</div>
                )}
                {showAddPlanProWarningMessage && (
                  <div className="form-error mb-5 mt-5 text-center">{NO_TRANSPONDERS_TO_ADD_PLAN}</div>
                )}
              </div>
            )}
            <div className="text-red mt-8 text-center">{limitMessage}</div>
            <div className="order-6 mt-5 flex w-full justify-around pb-2 pt-8 md:justify-end">
              <div className="onboarding-transponders-subtotal w-full md:w-1/2">
                <div className="mr-12 text-3xl font-light">
                  <span>
                    <Trans>Subtotal</Trans>
                  </span>
                </div>
                <div className="text-large w-42 onboarding-transponder-subtotal-value-container ml-3 text-right text-xl">
                  <span className="onboarding-transponder-subtotal-value">${subTotal?.toFixed(2)}</span>
                </div>
              </div>
            </div>
          </div>

          {isSubmitted && !isValid && (
            <div className="form-error text-center">
              <Trans>Please complete the required fields to move forward</Trans>
            </div>
          )}
          <div className="order-last mt-10 w-full items-center justify-center sm:space-x-6 md:order-2 md:flex">
            <span className="text-xl text-primary-gray3">
              <Trans>Want to purchase transponders at a store near you?</Trans>
            </span>
            <button className="green-link-thin" type="button">
              <Trans>View Retail Locations</Trans>
            </button>
          </div>
        </>
      )}
    </>
  );
};
export default PurchaseTranspondersContent;

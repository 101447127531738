import { t, Trans } from '@lingui/macro';
import { Form, Radio } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import React, { useEffect } from 'react';
import PriceInput from '../../PriceInput/PriceInput';
import Note from '../../Note/Note';
import { useOnboardingStore } from '../../../store/onboarding/onboarding.store';
import { SELECT_DETAILS } from '../../../store/onboarding/onboarding.selectors';
import './EnrollEasyPay.scss';
import { IEZPayOnboarding } from './OnboardingEZPay';
import { AccountType } from '../../../types/onboarding.types';

export interface IEnrollEasyPay {
  minimumRecurringAmount: string;
}

export const EnrollEasyPay = ({ minimumRecurringAmount }: IEnrollEasyPay) => {
  const { accountType, easyPay } = useOnboardingStore(SELECT_DETAILS);
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<IEZPayOnboarding>();

  const optInEZPay = easyPay.optInEZPay;

  useEffect(() => {
    if (watch('optInEZPay') !== optInEZPay) {
      setValue('optInEZPay', optInEZPay ? optInEZPay : watch('optInEZPay'));
    }
  }, []);

  return (
    <div className="onboarding-easy-pay card--vertical my-10 rounded-lg bg-white px-8 shadow-lg">
      <div className="enroll-easy-pay_header ">{t`Enroll in Easy Pay`}</div>

      <div className="flex flex-wrap ">
        <div className="w-full pb-5 font-bold text-primary-blue1 ">
          <Trans>Would you like to opt into Easy Pay?*</Trans>
        </div>
        <div className="w-full">
          <Form.Item name="optInEZPay" className={'theme '} help={errors.optInEZPay?.message as string} required>
            <Controller
              name="optInEZPay"
              control={control}
              render={({ field }) => (
                <Radio.Group {...field} id="optInEZPay">
                  <Radio value={true}>
                    <Trans>Yes</Trans>
                  </Radio>
                  <Radio value={false}>
                    <Trans>No</Trans>
                  </Radio>
                </Radio.Group>
              )}
            />
          </Form.Item>
        </div>
      </div>

      {watch('optInEZPay') && (
        <React.Fragment>
          <div className="onboarding-easy-pay_recurring_payment">
            <div className="reacurring-payment_label">
              <Trans>Set Replenishment Amount</Trans>
            </div>
            <div className="flex flex-col gap-8 md:flex-row">
              <Form.Item
                name="recurringPaymentAmount"
                hasFeedback
                validateStatus={errors.recurringPaymentAmount ? 'error' : ''}
                help={errors.recurringPaymentAmount?.message as string}
                initialValue={minimumRecurringAmount}
              >
                <Controller
                  name="recurringPaymentAmount"
                  control={control}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <PriceInput
                      id="recurringPaymentAmount"
                      hasErrors={!!errors.recurringPaymentAmount}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      aria-label={t`Recurring payment amount`}
                    />
                  )}
                />
              </Form.Item>

              <div className="replenishment-threshold-note">
                <Note aria-label="replenishment-threshold-note">
                  <div>
                    <Trans>
                      When your account reaches the specified low balance amount for your account type ($5 for SunPass
                      Personal Accounts), the replenishment amount you set will be automatically withdrawn from your
                      payment method and added to your SunPass balance.{' '}
                      <strong>${minimumRecurringAmount} minimum required</strong>.
                    </Trans>
                  </div>
                </Note>
              </div>
            </div>
          </div>
          {accountType !== AccountType.COMMERCIAL && accountType !== AccountType.PAYG && (
            <div>
              <div className="flex flex-wrap ">
                <p className="w-full font-bold text-primary-blue1">
                  <Trans>Welcome to SunPass Plus Parking!</Trans>
                </p>
                <p className="w-full ">
                  <Trans>
                    By signing up for Easy Pay, all of your transponders will automatically be enrolled in SunPass Plus
                    Parking, which provides you the convenience of using your transponder to pay for parking at most of
                    Florida’s major airports as well as the Hard Rock Stadium in Miami.
                  </Trans>
                </p>
                <p className="mt-5 w-full italic">
                  If you’d like to disable SunPass Plus Parking, you will be able to do so on the Transponders tab of
                  your dashboard once your account is created.
                </p>
              </div>
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

import { FC } from 'react';
import { Form, Select } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import { t, Trans } from '@lingui/macro';
import { LabelValue } from 'src/types/LabelValue.interface';
import useVehicleMakeOptions from '../../../../hooks/domain/dashboard/vehicle/useVehicleMakeOptions';
import { IVehicleFormItemProps } from '../../../Onboarding/Vehicles/OnboardingVehiclesForm/OnboardingVehicleForm';
import { buildErrorString, getInputName } from '../../../Onboarding/Vehicles/OnboardingVehiclesForm/vehicleForm.utils';
import classNames from 'classnames';

const { Option } = Select;

const VegicleMake: FC<IVehicleFormItemProps> = ({ field, index, name }) => {
  const {
    setValue,
    control,
    formState: { errors },
  } = useFormContext();

  const { vehicleMakeOptions } = useVehicleMakeOptions();

  return (
    <div className="mb-4 w-full sm:w-6/12 sm:pr-2">
      <Form.Item
        {...field}
        id={name}
        className="theme"
        help={buildErrorString(name, errors, index)}
        label={<Trans>Make</Trans>}
        htmlFor={name}
        hasFeedback
        validateStatus={errors?.vehicles?.[0]?.vehicleMake || errors.vehicleMake ? 'error' : ''}
        required
      >
        <Controller
          control={control}
          name={name}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              {...fieldState}
              id={name}
              className={classNames({ 'input-error': fieldState.invalid })}
              onChange={(value) => {
                field.onChange(value);
                setValue(getInputName('vehicleModel', index), null);
              }}
              showSearch
              placeholder={t`Select`}
              optionFilterProp="children"
              aria-label={t`Make`}
            >
              {vehicleMakeOptions?.map((vehicleMake: LabelValue) => (
                <Option key={vehicleMake.value} value={vehicleMake.value}>
                  {vehicleMake.label}
                </Option>
              ))}
            </Select>
          )}
        />
      </Form.Item>
    </div>
  );
};

export default VegicleMake;

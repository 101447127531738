import { Form, Input } from 'antd';
import { FormProvider } from 'antd/lib/form/context';
import { Trans, t } from '@lingui/macro';
import { Controller, useForm } from 'react-hook-form';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useMutation } from '@apollo/client';
import { ACTIVATE_TRANSPONDERS, ITranspondersActivationInput } from '../../../graphql/transponders.graphql';
import { MainLoader } from '../../Loader/MainLoader';
import ScrollToTop from '../../ScrollToTop/ScrollToTop';
import { useSubmitEffects } from '../../../hooks/effect/useSubmitEffects';
import { TransponderNumberLocatingModal } from '../../Modals/ConfirmationModal/TransponderNumberLocatingModal';
import { transponderActivationSchema } from '../../../validators/dashboard/transponders/transponderActivation.validator';
import LeaveSiteConfirmationModal from 'src/components/Modals/ConfirmationModal/LeaveSiteConfirmationModal';
import { useCallbackPrompt } from 'src/hooks/location/useCallbackPrompt';
import { InfoLink } from '../../Links/InfoLink';
import { useTransponderStore } from '../../../store/dashboard/transponder.store';
import { showMessage } from 'src/utils/message.utils';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import './TransponderActivation.scss';
import { useDashboardStore } from '../../../store/dashboard/dashboard.store';
import { ACTIVATE_NEW_TRANSPONDER_SECTION_ID } from '../references';

export interface ITransponderFormActivationForm {
  transponderNumber: string;
  confirmTransponderNumber: string;
  friendlyName: string;
}

export const TransponderActivation = () => {
  const [showLocatingInfoModal, setShowLocatingInfoModal] = useState(false);
  const [showRedirectPrompt, setShowRedirectPrompt] = useState(false);

  const { cancelRedirect, confirmRedirect, showPrompt } = useCallbackPrompt(showRedirectPrompt);

  const methods = useForm<ITransponderFormActivationForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(transponderActivationSchema),
    defaultValues: {
      transponderNumber: '',
      confirmTransponderNumber: '',
      friendlyName: '',
    },
  });

  const {
    handleSubmit,
    control,
    trigger,
    reset,
    formState: { isSubmitSuccessful, isSubmitted, errors },
  } = methods;

  const {
    store: { activateMode },
  } = useTransponderStore();

  const [activateTransponders, { loading: activationLoading }] = useMutation<
    { transpondersActivation: boolean },
    { transpondersActivationInput: ITranspondersActivationInput }
  >(ACTIVATE_TRANSPONDERS);

  const onSubmit = async (data: ITransponderFormActivationForm) => {
    try {
      await activateTransponders({
        variables: {
          transpondersActivationInput: {
            transponderNumbers: [data.transponderNumber],
          },
        },
      });
      showMessage('success');
    } catch (err) {
      showMessage('error');
      console.log(err);
    }
  };

  const { setActivateMode } = useTransponderStore();

  const ref = useRef<HTMLDivElement>(null);

  useSubmitEffects({ isSubmitExecuted: isSubmitted && isSubmitSuccessful, effect: () => setActivateMode(false) });

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    if (activateMode) {
      setShowRedirectPrompt(true);
    } else {
      setShowRedirectPrompt(false);
    }
  }, [activateMode]);

  const resetValuesToDefault = () => {
    reset();
  };

  const confirmRedirectUser = () => {
    resetValuesToDefault();
    confirmRedirect();
  };

  const {
    store: { activateTransponderMode },
  } = useDashboardStore();

  return (
    <>
      {activateTransponderMode ? <ScrollToTop triggerValues={isSubmitted && isSubmitSuccessful} blockInitial /> : null}
      <MainLoader loading={activationLoading} />
      <TransponderNumberLocatingModal showModal={showLocatingInfoModal} onOk={() => setShowLocatingInfoModal(false)} />
      <div
        ref={ref}
        className="mx-auto my-10 w-full rounded-lg bg-white px-6 py-6 shadow-lg lg:w-9/12"
        id={ACTIVATE_NEW_TRANSPONDER_SECTION_ID}
      >
        <div className="transponder-activation-form ">
          <h1 className="mb-5 text-xl">
            <Trans>Activate New Transponder</Trans>
          </h1>
        </div>

        <div>
          <h1 className="mb-12 mr-12 inline-block text-3xl text-primary-blue1 sm:mb-auto">
            <Trans>Transponder Information</Trans>
          </h1>
          <InfoLink onClick={() => setShowLocatingInfoModal(true)}>
            <Trans>Having trouble locating your transponder number?</Trans>
          </InfoLink>
        </div>

        <Form onFinish={handleSubmit(onSubmit)}>
          <FormProvider {...methods}>
            <div className="">
              <div className="flex flex-col place-content-between md:flex-row md:gap-4">
                <Form.Item
                  hasFeedback
                  validateStatus={errors?.transponderNumber?.message ? 'error' : ''}
                  label={<Trans>Transponder Number</Trans>}
                  className="override-to-column theme w-full md:w-6/12"
                  required={activateMode}
                >
                  <Controller
                    control={control}
                    name="transponderNumber"
                    render={({ field, fieldState }) => (
                      <Input
                        {...field}
                        className={classNames({
                          'input-error': fieldState.invalid,
                        })}
                        onChange={(event) => {
                          field.onChange(event);
                          trigger(['transponderNumber', 'confirmTransponderNumber']);
                        }}
                        name="transponderNumber"
                        key="transponderNumber"
                        minLength={9}
                        maxLength={13}
                        aria-label={t`transponder number`}
                      />
                    )}
                  />
                  <div className="form-error">{errors?.transponderNumber?.message}</div>
                </Form.Item>
                <Form.Item
                  hasFeedback
                  validateStatus={errors?.confirmTransponderNumber?.message ? 'error' : ''}
                  label={<Trans>Confirm Transponder Number</Trans>}
                  className="override-to-column theme w-full md:w-6/12"
                  required={activateMode}
                >
                  <Controller
                    control={control}
                    name="confirmTransponderNumber"
                    render={({ field, fieldState }) => (
                      <Input
                        {...field}
                        className={classNames({
                          'input-error': fieldState.invalid,
                        })}
                        name="confirmTransponderNumber"
                        key="confirmTransponderNumber"
                        maxLength={13}
                        aria-label={t`confirm transponder number`}
                      />
                    )}
                  />
                  <div className="form-error">{errors?.confirmTransponderNumber?.message}</div>
                </Form.Item>
              </div>
            </div>
            <div className="mx-0 mt-7 flex w-full flex-col justify-center sm:mx-auto sm:w-auto sm:flex-row">
              <CustomButton
                onClick={() => setActivateMode(false)}
                className="mx-0 mb-5 mt-2 sm:mx-3 sm:mb-0"
                content="Cancel"
                type={'reset'}
                isPrimary={false}
              />

              <CustomButton className="px-15 mx-0 mt-2 sm:mx-3" content="Save Changes" type={'submit'} />
            </div>
          </FormProvider>
        </Form>
        <LeaveSiteConfirmationModal showModal={showPrompt} onOk={confirmRedirectUser} onCancel={cancelRedirect} />
      </div>
    </>
  );
};

import { PAYMENT_METHOD_ACTIVITY } from '../components/Dashboard/Wallet/PaymentMethods/PaymentMethodItem';

export interface IStorage {
  clear(): void;

  getItem(key: string): string | null;

  setItem(key: string, data: string): void;
}

type SessionStorageItems = 'paymentActivity' | 'notifications' | 'parkingActivity';

const PAYMENT_ACTIVITY: SessionStorageItems = 'paymentActivity';
const SUNPASS_PLUS_PARKING_ACTIVITY: SessionStorageItems = 'parkingActivity';
export interface IPaymentMethodStatus {
  paymentMethodId: string;
  status: PAYMENT_METHOD_ACTIVITY;
}
export interface ITranponderParkingStatus {
  tranponderNumber: string;
}

class SessionStorageService {
  local: IStorage = sessionStorage;

  get(key: SessionStorageItems): any {
    const data = this.local.getItem(key);
    return data ? JSON.parse(data) : null;
  }

  set(key: SessionStorageItems, data: any): void {
    this.local.setItem(key, data);
  }

  clear(): void {
    this.local.clear();
  }

  getPaymentMethodStatusById(paymentMethodId: string): PAYMENT_METHOD_ACTIVITY | undefined {
    const statuses = this.get(PAYMENT_ACTIVITY) as IPaymentMethodStatus[];
    return statuses?.find((statusItem) => statusItem.paymentMethodId === paymentMethodId)?.status;
  }

  getAllPaymentMethods(): string[] {
    const statuses = this.get(PAYMENT_ACTIVITY) as IPaymentMethodStatus[];
    return statuses?.map((e) => e.paymentMethodId) || [];
  }

  removePaymentMethod(paymentMethodId: string): void {
    const statuses = this.get(PAYMENT_ACTIVITY) as IPaymentMethodStatus[];
    this.set(
      PAYMENT_ACTIVITY,
      JSON.stringify([...statuses.filter((payment) => payment.paymentMethodId !== paymentMethodId)]),
    );
  }

  setPaymentMethodStatus(paymentMethodId: string, status?: PAYMENT_METHOD_ACTIVITY): void {
    const statuses = this.get(PAYMENT_ACTIVITY) as IPaymentMethodStatus[];
    this.set(
      PAYMENT_ACTIVITY,
      JSON.stringify(
        statuses
          ? ([
              ...statuses.filter((statusItem) => statusItem.paymentMethodId !== paymentMethodId),
              {
                paymentMethodId,
                status,
              },
            ] as IPaymentMethodStatus[])
          : [
              {
                paymentMethodId,
                status,
              },
            ],
      ),
    );
  }
  changeParkingOnTransponder(tranponderNumber: string): void {
    const changedList = this.get(SUNPASS_PLUS_PARKING_ACTIVITY);
    if (changedList === null) {
      this.set(SUNPASS_PLUS_PARKING_ACTIVITY, JSON.stringify([tranponderNumber]));
    } else if (changedList.includes(tranponderNumber)) {
      return;
    } else {
      const newList = [...changedList, tranponderNumber];
      this.set(SUNPASS_PLUS_PARKING_ACTIVITY, JSON.stringify(newList));
    }
  }
  getChangedTranponderParkings(): string[] {
    return this.get(SUNPASS_PLUS_PARKING_ACTIVITY);
  }
}

export const sessionStorageService = new SessionStorageService();

import { useFormContext } from 'react-hook-form';
import { Trans } from '@lingui/macro';
import VerifiedIcon from '../VerifiedIcon/VerifiedIcon';
import {
  AT_LEAST_ONE_SPECIAL_CHAR,
  AT_LEAST_ONE_UPPER_ONE_LOWER_ONE_NUMBER,
  ONLY_VALID_CHARACTERS,
} from '../../validators/regex';
import React from 'react';

interface IPasswordPolicyProps {
  inputName: string;
}

export const PasswordPolicy = ({ inputName }: IPasswordPolicyProps) => {
  const { watch } = useFormContext();
  return (
    <div className="w-full sm:w-6/12 sm:pl-1">
      <div className="onboarding-help-box">
        <b>
          <Trans>Passwords must have</Trans>
        </b>
        <ul className="list-none">
          <li>
            <VerifiedIcon isVerified={(watch(inputName) || '').length >= 8} />
            <span className="pl-2">
              <Trans>8 to 64 characters</Trans>
            </span>
          </li>
          <li>
            <VerifiedIcon isVerified={AT_LEAST_ONE_UPPER_ONE_LOWER_ONE_NUMBER.test(watch(inputName) || '')} />
            <span className="pl-2">
              <Trans>At least one uppercase, one lowercase letter, and one number</Trans>
            </span>
          </li>
          <li>
            <VerifiedIcon
              isVerified={
                AT_LEAST_ONE_SPECIAL_CHAR.test(watch(inputName) || '') &&
                ONLY_VALID_CHARACTERS.test(watch(inputName) || '')
              }
            />
            <span className="pl-2">
              <Trans>At least one of the following special characters: ! @ # $ % * ( ) - _ + = ~ ; , .</Trans>
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

import React, { FC, ReactNode } from 'react';
import { Modal } from 'antd';
import { t } from '@lingui/macro';
import './ConfirmationModal.scss';
import { CloseOutlined } from '@ant-design/icons';
import { colors } from 'src/theme/theme';
import { CustomButton } from 'src/components/CustomButton/CustomButton';

interface IConfirmationModalProps {
  title?: ReactNode;
  content: ReactNode;
  showModal: boolean;
  onOk?: () => void;
  onCancel?: (nextLocation: any) => void | (() => void);
  closeType?: boolean;
  closable?: boolean;
  okButtonText?: string;
  cancelButtonText?: string;
  cancelButtonBlue?: boolean;
  alignButtons?: 'center';
  alignText?: 'center';
  wide?: boolean;
  modifyHeader?: boolean;
  cancelOnly?: boolean;
  disableBtn?: boolean;
  showButtons?: boolean;
}

const ConfirmationModal: FC<IConfirmationModalProps> = ({
  title,
  content,
  showModal,
  onOk,
  onCancel,
  closeType,
  closable,
  okButtonText,
  cancelButtonText,
  alignButtons,
  alignText,
  wide = true,
  modifyHeader,
  cancelOnly,
  disableBtn,
  cancelButtonBlue,
  showButtons = true,
}) => {
  cancelButtonText = cancelButtonText ? cancelButtonText : t`Cancel`;
  okButtonText = okButtonText ? okButtonText : t`Continue`;

  return (
    <>
      {showModal && (
        <Modal
          {...(wide && { width: 800 })}
          title={title ? <h1 className={`text-xl ${alignText === 'center' ? 'text-center' : ''}`}>{title}</h1> : null}
          visible={showModal}
          onOk={onOk}
          className={`confirmation-modal_container ${modifyHeader ? 'bulk-upload-header' : ''}`}
          onCancel={onCancel ? onCancel : onOk}
          closable={closable === false ? closable : true}
          closeIcon={<CloseOutlined style={{ color: colors.primary.blue1, fontSize: 20, transform: 'scaleX(1.1)' }} />}
          footer={[
            <>
              {showButtons ? (
                <div
                  key="buttons"
                  className={`m-7 flex justify-center gap-10 ${alignButtons === 'center' ? 'text-center' : ''}`}
                >
                  {onCancel && (
                    <CustomButton
                      className={`pt-8 ${!!cancelButtonBlue ? 'button-secondary' : 'button-primary'}`}
                      disabled={disableBtn}
                      content={closeType ? t`Close` : cancelButtonText}
                      onClick={onCancel}
                      isPrimary={false}
                    />
                  )}
                  {!cancelOnly && (
                    <CustomButton
                      className="pt-8"
                      disabled={disableBtn}
                      content={closeType ? t`Close` : okButtonText}
                      onClick={onOk}
                    />
                  )}
                </div>
              ) : null}
            </>,
          ]}
        >
          <div className={` ${alignText === 'center' ? 'text-center' : ''}`}>{content}</div>
        </Modal>
      )}
    </>
  );
};
export default ConfirmationModal;

import { Trans } from '@lingui/macro';
import { FC, useContext, useEffect } from 'react';
import { NotificationContext } from 'src/context/NotificationContext';
import { INotificationView } from 'src/context/NotificationProvider';
import { SimpleLoader } from '../Loader/SimpleLoader';
import { EmptyState } from './EmptyState';
import NotificationItem from './NotificationItem';

interface IDropDown {
  setIsDropDownShown: (isShown: boolean) => void;
}

const compareNotificationsDismiss = (
  { dismissed: isFirstNotificationDismissed }: INotificationView,
  { dismissed: isSecondNotificationDismissed }: INotificationView,
): number => {
  if (isFirstNotificationDismissed == isSecondNotificationDismissed) return 0;
  return isFirstNotificationDismissed && !isSecondNotificationDismissed ? 1 : -1;
};

const AlertsDropdown: FC<IDropDown> = ({ setIsDropDownShown }) => {
  const checkIfClickedOutside = (event: Event) => {
    setIsDropDownShown(
      document != null && !!document?.getElementById('alerts')?.contains(event.target as HTMLInputElement),
    );
  };
  const notificationCtx = useContext(NotificationContext);
  const { notificationInfo, dismissAll, loading, notifications } = notificationCtx || {};
  const { hasNewNotifications, numOfNotDismissedNotifications } = notificationInfo || {};

  useEffect(() => {
    window.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      window.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, []);

  return (
    <div className="dropdown">
      <div className="dropdown-content">
        <div className="dropdown-content-header flex items-center justify-between p-2">
          <div className="flex p-2">
            <div className="dropdown-content-header-title">
              <>
                <Trans>Notifications</Trans>
                {!hasNewNotifications ? ' (0)' : null}
              </>
            </div>
            {hasNewNotifications ? (
              <div className="dropdown-content-header-notification-counter mx-2">{numOfNotDismissedNotifications}</div>
            ) : null}
          </div>
          {hasNewNotifications ? (
            <button
              className="mb-0 mr-2 cursor-pointer text-primary-green1 underline"
              onClick={() => dismissAll && dismissAll()}
            >
              <Trans>Dismiss All</Trans>
            </button>
          ) : null}
        </div>
        <div className="dropdown-content-items relative">
          <>
            {' '}
            {notifications && notifications.length ? (
              notifications
                ?.sort(compareNotificationsDismiss)
                ?.map((notification) => (
                  <NotificationItem
                    key={notification.id}
                    {...notification}
                    closeDropdown={() => setIsDropDownShown(false)}
                  />
                ))
            ) : (
              <EmptyState />
            )}
            <SimpleLoader loading={!!loading} />
          </>
        </div>
      </div>
    </div>
  );
};
export default AlertsDropdown;

import { useOnboardingStore } from '../../../store/onboarding/onboarding.store';
import { useCallback, useState } from 'react';
import {
  AccountSubtype,
  AccountType,
  OnboardingFlowType,
  OnboardingPinellasOptions,
  OnboardingUserType,
} from '../../../types/onboarding.types';
import { useRouteMonitor } from '../../../hooks/location/useRouteMonitor';
import { OnboardingStepPath } from '../../../types/routes';
import { determineAccountSubtype } from '../../../store/onboarding/onboarding.utils';
import { OnboardingStepsUtils } from '../../../utils/onboardingStepsUtilsType';

interface IUseOnboardingQuestionProps<T> {
  defaultAnswerSelected?: T;
}

interface IUseOnboardingQuestionReturnType<T> {
  onSelect: (select: T) => void;
  error: boolean;
  onSubmit: () => void;
  selected: T | undefined;
}

interface OnboardingActions {
  update: () => void;
  redirect: () => void;
}

export const useOnboardingQuestion = <T,>(
  props?: IUseOnboardingQuestionProps<T>,
): IUseOnboardingQuestionReturnType<T> => {
  const {
    updateOnboardingStore,
    onboardingInfo: { accountSubtype, hasTranspondersToActivate, userType, onboardingFlowType },
  } = useOnboardingStore();

  const [selected, setSelected] = useState<T | undefined>(props?.defaultAnswerSelected);
  const [error, setError] = useState(false);
  const history = useRouteMonitor();

  const onSelect = (select: T) => {
    setError(false);
    setSelected(select);
  };

  const updateStoreOnPurchaseInitialStep = () => {
    if (selected && (selected as unknown as OnboardingUserType) === OnboardingUserType.VISITOR) {
      updateOnboardingStore({
        onboardingStarted: true,
        userType: selected as unknown as OnboardingUserType,
        accountType: AccountType.PAYG,
        accountSubtype: AccountSubtype.NONE,
      });
      history.goToPath(OnboardingStepPath.ACCOUNT_RECOMMENDATION);
    } else if (selected && (selected as unknown as OnboardingUserType) === OnboardingUserType.COMMERCIAL) {
      updateOnboardingStore({
        onboardingStarted: true,
        userType: selected as unknown as OnboardingUserType,
        accountSubtype: AccountSubtype.NONE,
        accountType: AccountType.COMMERCIAL,
      });
      history.goToPath(OnboardingStepPath.FLEET);
    } else if (selected && (selected as unknown as OnboardingUserType) === OnboardingUserType.RESIDENT) {
      updateOnboardingStore({
        onboardingStarted: true,
        userType: selected as unknown as OnboardingUserType,
        accountType: AccountType.PRIVATE,
        accountSubtype: AccountSubtype.NONE,
      });
    } else {
      updateOnboardingStore({
        onboardingStarted: true,
        userType: selected as unknown as OnboardingUserType,
        accountType: AccountType.GOVERNMENT,
        accountSubtype: AccountSubtype.NONE,
      });
      history.goToPath(OnboardingStepPath.ACCOUNT);
    }
  };

  const redirectPurchaseInitialStep = () => {
    if (selected && (selected as unknown as OnboardingUserType) === OnboardingUserType.VISITOR) {
      history.goToPath(OnboardingStepPath.ACCOUNT_RECOMMENDATION);
    } else if (selected && (selected as unknown as OnboardingUserType) === OnboardingUserType.COMMERCIAL) {
      history.goToPath(OnboardingStepPath.FLEET);
    } else if (selected && (selected as unknown as OnboardingUserType) === OnboardingUserType.RESIDENT) {
      history.goToPath(OnboardingStepPath.PINELLAS);
    } else {
      history.goToPath(OnboardingStepPath.ACCOUNT);
    }
  };

  const updateStoreOnPrepaidInitialStep = () => {
    const hasAccountSubTypeChanged =
      accountSubtype === AccountSubtype.NONE ||
      accountSubtype !== determineAccountSubtype(selected as unknown as OnboardingPinellasOptions);
    const nextAccountSubType = hasAccountSubTypeChanged
      ? determineAccountSubtype(selected as unknown as OnboardingPinellasOptions)
      : accountSubtype;
    updateOnboardingStore({
      pinellasUse: selected as unknown as OnboardingPinellasOptions,
      accountSubtype: nextAccountSubType,
      userType: onboardingFlowType === OnboardingFlowType.PURCHASE ? userType : OnboardingUserType.VISITOR,
    });
  };

  const redirectOnPrepaidInitialStep = () => {
    if ((selected as unknown as OnboardingPinellasOptions) === OnboardingPinellasOptions.FREQUENT) {
      history.goToPath(OnboardingStepPath.PINELLAS_RECOMENDATION);
    } else {
      history.goToPath(OnboardingStepPath.ACCOUNT);
    }
  };

  const redirectOnInitialStep = () => {
    if (selected && (selected as unknown as OnboardingFlowType) === OnboardingFlowType.PURCHASE) {
      updateOnboardingStore({ hasTranspondersToActivate: false });
      history.goToPath(OnboardingStepPath.ACCOUNT_TYPE);
    } else {
      updateOnboardingStore({
        accountType: AccountType.PRIVATE,
        accountSubtype: AccountSubtype.NONE,
        hasTranspondersToActivate: true,
      });
      history.goToPath(OnboardingStepPath.ACCOUNT);
    }
  };

  const updateStoreOnInitialStep = () => {
    updateOnboardingStore({
      onboardingFlowType: selected as unknown as OnboardingFlowType,
      accountType: AccountType.PRIVATE,
      userType:
        (selected as unknown as OnboardingFlowType) === OnboardingFlowType.PURCHASE
          ? undefined
          : OnboardingUserType.RESIDENT,
    });
  };

  const redirectOnTranspondersActivationStep = () => {
    history.goToPath(OnboardingStepPath.VEHICLES);
  };

  const updateStoreOnTranspondersActivationStep = () => {
    // TODO add mutation to activate transponders
  };

  const resolveActionsBasedOnSelect = (select?: T): OnboardingActions => {
    if (OnboardingStepsUtils.isOnboardingInitialStep(selected)) {
      return {
        update: updateStoreOnInitialStep,
        redirect: redirectOnInitialStep,
      };
    } else if (OnboardingStepsUtils.isOnboardingPurchaseInitialStep(selected)) {
      return {
        update: updateStoreOnPurchaseInitialStep,
        redirect: redirectPurchaseInitialStep,
      };
    } else if (OnboardingStepsUtils.isOnboardingPrepaidInitialStep(selected)) {
      return {
        update: updateStoreOnPrepaidInitialStep,
        redirect: redirectOnPrepaidInitialStep,
      };
    } else if (hasTranspondersToActivate) {
      return {
        update: updateStoreOnTranspondersActivationStep,
        redirect: redirectOnTranspondersActivationStep,
      };
    }
    setError(true);
    throw Error(`Provided select value: ${select} has not defined actions.`);
  };

  const onSubmit = useCallback(() => {
    setError(false);
    const actions = resolveActionsBasedOnSelect(selected);
    actions.update();
    actions.redirect();
  }, [selected]);

  return {
    onSelect,
    error,
    onSubmit,
    selected,
  };
};

import {
  AUTH_ERROR_CODES_TO_BE_HANDLED,
  BULK_UPLOAD_ERROR_CODES_TO_BE_HANDLED,
  SERVER_ERRORS,
} from '../../services/errorHandling/error.codes';
import { parseMessage } from '../../services/errorHandling/error.service';
import { ErrorType, IErrorState } from '../../store/errors/error.store';
import { CustomGraphQLErrorExtensions } from './errorHandler.types';

export const getErrorState = (extensions: CustomGraphQLErrorExtensions, message: string): IErrorState => {
  const errorGroupType = getErrorCodeGroupType(extensions.code, extensions?.failedActionName);
  switch (errorGroupType) {
    case 'bulkUpload':
      return {
        message: parseMessage(extensions.code, extensions.englishMsg),
        code: extensions.code,
        type: 'bulkUpload',
      };
    case 'addressValidation':
      return {
        message,
        code: extensions.code,
        type: 'addressValidation',
      };
    case 'internal-server':
      return {
        message,
        code: extensions.code,
        type: 'internal-server',
      };
    case 'authError':
      return {
        message,
        code: extensions.code,
        type: 'authError',
      };
    case 'unknown':
      return {
        message,
        code: extensions.code,
        type: 'unknown',
      };
    default:
      return {
        message,
        code: extensions.code,
        type: 'unknown',
      };
  }
};

export const getErrorCodeGroupType = (code: number, failedActionName?: string): ErrorType => {
  if (BULK_UPLOAD_ERROR_CODES_TO_BE_HANDLED.includes(code)) {
    return 'bulkUpload';
  } else if (AUTH_ERROR_CODES_TO_BE_HANDLED.includes(code)) {
    return 'authError';
  } else if (SERVER_ERRORS.includes(code)) {
    return 'internal-server';
  } else if (failedActionName === 'addressValidation') {
    return 'addressValidation';
  } else {
    return 'unknown';
  }
};

import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useScroll } from '../screen/useScroll';

interface IuseScrollOnPaginationChange {
  data: any;
  scrollId: string;
}

const useScrollOnPaginationChange: FC<IuseScrollOnPaginationChange> = ({ data, scrollId }) => {
  const [isTabActive, setTabActive] = useState(false);
  const { scrollToElem } = useScroll();

  const location = useLocation();

  useEffect(() => {
    if (isTabActive) {
      scrollToElem(scrollId, true);
    } else {
      setTabActive(true);
    }
  }, [data]);

  useEffect(() => {
    setTabActive(false);
  }, [location.pathname]);

  return null;
};

export default useScrollOnPaginationChange;

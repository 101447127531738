import { t, Trans } from '@lingui/macro';
import { Breadcrumb, Form } from 'antd';
import { FormProvider, useForm } from 'react-hook-form';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import './ResumeAccountCreation.scss';
import { resumeAccountCreationSchema } from './ResumeAccountCreationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLazyQuery } from '@apollo/client';
import { ISignUpRetrieveDataInput, SIGN_UP_RETRIVE_DATA } from 'src/graphql/signUp/signUpRetriveData.graphql';
import { useRouteMonitor } from 'src/hooks/location/useRouteMonitor';
import { MainRoutes, OnboardingStepPath } from 'src/types/routes';
import { useOnboardingStore } from 'src/store/onboarding/onboarding.store';
import { LastName } from 'src/components/atoms/form/account/LastName';
import PostalCode from 'src/components/ForgetUsernameOrPassword/PostalCode';
import { ReferenceNumberInput } from 'src/components/atoms/form/account/ReferenceNumberInput';
import { Link } from 'react-router-dom';
import { AccountSubtype } from '../../../types/onboarding.types';

export const ResumeAccountCreation = () => {
  const methods = useForm<ISignUpRetrieveDataInput>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(resumeAccountCreationSchema()),
    defaultValues: {
      referenceNumber: '',
      postalCode: '',
      lastName: '',
    },
  });
  const { updateOnboardingStore } = useOnboardingStore();
  const history = useRouteMonitor();

  const { handleSubmit, reset } = methods;

  const [getSignUpData] = useLazyQuery<
    { signUpRetrieveData: any },
    { signUpRetrieveDataInput: ISignUpRetrieveDataInput }
  >(SIGN_UP_RETRIVE_DATA, { errorPolicy: 'all' });

  const onSuccess = (responseData: any) => {
    const finalData = responseData?.signUpRetrieveData;

    updateOnboardingStore({
      referenceNumber: finalData.referenceNumber,
      accountType: finalData.accountDetailsSignUpData.accountType,
      accountSubtype: !!finalData.accountDetailsSignUpData.accountSubtype
        ? finalData.accountDetailsSignUpData.accountSubtype
        : AccountSubtype.NONE,
      accountInfo: {
        doingBusinessAs: finalData.accountDetailsSignUpData.dbaName,
        ...finalData.accountDetailsSignUpData,
        postalCode: finalData.accountDetailsSignUpData.zipcode,
        firstSecurityQuestion: finalData.accountDetailsSignUpData.securityQuestion,
        firstSecurityQuestionAnswer: finalData.accountDetailsSignUpData.securityQuestionAnswer,
        additionalContacts: finalData.accountDetailsSignUpData.secondaryContacts,
        showAdditionalContacts: finalData.accountDetailsSignUpData.secondaryContacts.length !== 0 ? true : false,
      },
      accountId: finalData.accountId,
    });

    history.goToPath(OnboardingStepPath.ACCOUNT);
  };

  const onSubmit = async (formValues: ISignUpRetrieveDataInput) => {
    const { referenceNumber, lastName, postalCode, invoiceDetails } = formValues;
    try {
      getSignUpData({
        variables: { signUpRetrieveDataInput: { referenceNumber, lastName, zipcode: postalCode, invoiceDetails } },
        onCompleted: onSuccess,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="breadcrumb ">
        <Breadcrumb separator="/">
          <Breadcrumb.Item className="underline">
            <Link to={MainRoutes.ROOT}>
              <Trans>Home</Trans>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="underline">
            <Trans>Resume Account Creation</Trans>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="w-100 resume-account-creation-content py-10">
        <div className="pb-11 text-center">
          <h1 className="text-2x mb-4 font-normal">
            <Trans>Resume Account Creation</Trans>
          </h1>
          <div className="mx-auto max-w-xl">
            <Trans>
              If you previously started the account creation process but were disrupted for any reason, you can enter
              reference number along with your last name and postal code below to pick up where you left off.
            </Trans>
          </div>
        </div>
        <div className="card resume-account-creation-card">
          <div className="mb-4">
            <Trans>Please provide the details below to resume your previously started account creation:</Trans>
          </div>
          <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
            <FormProvider {...methods}>
              <ReferenceNumberInput />
              <div className="flex flex-col gap-4 sm:flex-row">
                <LastName className="w-full sm:w-1/3" />
                <PostalCode className="w-full sm:w-1/3" />
              </div>
              <div className="my-4 flex flex-col items-center gap-2 sm:flex-row">
                <CustomButton content={t`Resume`} type={'submit'} />
                <button
                  type="button"
                  className="mt-1 inline text-base underline"
                  onClick={() => {
                    reset();
                  }}
                >
                  <Trans>Cancel</Trans>
                </button>
              </div>
            </FormProvider>
          </Form>
        </div>
      </div>
    </>
  );
};

import { ILinkedAccount, LinkedAccountStatus } from '../../../../graphql/linkedAccounts.graphql';
import React, { useMemo } from 'react';
import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';
import { useLinkedAccounts } from '../../../../hooks/useLinkedAccounts';
import { MainLoader } from '../../../Loader/MainLoader';
import { convertPriceToString } from '../../../../utils/formatter';
import useScreenSize from '../../../../hooks/screen/useScreenSize';

export interface IChildActiveAccountItemProps {
  account?: ILinkedAccount;
}

export const ChildAccountItem = ({ account }: IChildActiveAccountItemProps) => {
  const { accountBalance, accountNumber, status, owner } = account || {};
  const { deleteLinkedAccount, loading } = useLinkedAccounts();
  const getColorOfBalance = (balance?: number): string => {
    if (balance === 0 || !balance) return '';
    return balance < 0 ? 'text-primary-red' : 'text-primary-green1';
  };

  const { screenSize } = useScreenSize();

  const item = useMemo((): JSX.Element => {
    return (
      <>
        <span className="flex-1 break-words">{owner}</span>
        <span className="flex-1">{accountNumber}</span>
        <div className="flex-1">
          {status === LinkedAccountStatus.ACTIVE ? (
            <div className="flex gap-2">
              <span className={`w-full  ${getColorOfBalance(accountBalance)}`}>
                {convertPriceToString(accountBalance || 0)}
              </span>
              <Link to="#" className="text-primary-red underline " onClick={() => deleteLinkedAccount(accountNumber)}>
                <Trans>Remove</Trans>
              </Link>
            </div>
          ) : (
            <>
              <span className="italic">
                <Trans>Request Pending</Trans>
              </span>
            </>
          )}
        </div>
      </>
    );
  }, [accountNumber, accountBalance, owner, status, screenSize]);

  return (
    <div className="my-5 flex w-full flex-row  md:gap-12">
      <MainLoader loading={loading} />
      {item}
    </div>
  );
};

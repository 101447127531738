import VerifiedIcon from '../VerifiedIcon/VerifiedIcon';
import React from 'react';
import { Trans } from '@lingui/macro';
import { AT_LEAST_ONE_CHAR_ONE_NUMBER } from '../../validators/regex';

export const UsernamePolicy = (props: { usernameWatch: string }) => {
  return (
    <div className="w-full sm:w-6/12 sm:pl-1">
      <div className="onboarding-help-box">
        <b>
          <Trans>Usernames must have</Trans>
        </b>
        <ul className="list-none">
          <li>
            <VerifiedIcon isVerified={(props.usernameWatch || '').length >= 6} />
            <span className="pl-2">
              <Trans>6 to 30 characters</Trans>
            </span>
          </li>
          <li>
            <VerifiedIcon isVerified={AT_LEAST_ONE_CHAR_ONE_NUMBER.test(props.usernameWatch || '')} />
            <span className="pl-2">
              <Trans>At least 1 letter, 1 number, and no special characters</Trans>
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

import ClockIcon from '../../assets/Yellow-Clock-Icon.svg';
import { Trans } from '@lingui/macro';
import React from 'react';
import { PAYMENT_METHODS_SECTION_ID } from '../../references';

export const PaymentMethodsHeader = () => {
  return (
    <div className="mb-6 mt-10 flex items-center justify-between border-t border-gray-300 pt-10 lg:justify-start">
      <div className="flex items-center justify-start">
        <img src={ClockIcon} alt="clock icon" />
        <h1 id={PAYMENT_METHODS_SECTION_ID} className="ml-3 text-xl font-bold sm:ml-6 sm:text-3xl">
          <Trans>Payment Methods</Trans>
        </h1>
      </div>
    </div>
  );
};

import { useEffect } from 'react';

export const useScript = (url: string, scriptId: string, callback?: () => void) => {
  useEffect(() => {
    let existingScript = document.getElementById(scriptId);
    if (!existingScript) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = scriptId;
      script.async = true;
      if (callback) script.onload = callback;
      document.body.appendChild(script);
      script.src = url + `?${Math.random()}`;
      existingScript = script;
    }
    return () => {
      existingScript && document.body.removeChild(existingScript);
    };
  }, []);
};

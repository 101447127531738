import { faArrowRight, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { colors } from 'src/theme/theme';
import './ActionItem.scss';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import { useNavigate } from 'react-router';
import { useDashboardStore } from '../../../store/dashboard/dashboard.store';
import { ActionItemAndIconLinkCardId } from './ActionItemAndIconLinkCardId';

interface IActionItemProps {
  id: string;
  title: string;
  selected?: boolean;
  autoHeight?: boolean;
  disabled?: boolean;
  tooltip?: string;
  height?: number;
  link: string;
}

export default function ActionItem({
  id,
  title,
  selected,
  autoHeight,
  disabled,
  tooltip,
  height,
  link,
}: IActionItemProps): React.ReactElement {
  const history = useNavigate();
  const {
    setAddEasyPayMode,
    setAddPaymentMethodMode,
    setAddVehicleMode,
    setAddFundsMode,
    setActivateTransponderMode,
    setPurchaseTransponderMode,
    setParkingMode,
  } = useDashboardStore();

  const checkActionItemId = () => {
    switch (id) {
      case ActionItemAndIconLinkCardId.SET_UP_EASY_PAY:
        setAddEasyPayMode(true);
        break;
      case ActionItemAndIconLinkCardId.ADD_PAYMENT_METHOD:
        setAddPaymentMethodMode(true);
        break;
      case ActionItemAndIconLinkCardId.ACTIVATE_TRANSPONDER:
        setActivateTransponderMode(true);
        break;
      case ActionItemAndIconLinkCardId.ADD_VEHICLE:
        setAddVehicleMode(true);
        break;
      case ActionItemAndIconLinkCardId.ADD_FUNDS:
        setAddFundsMode(true);
        break;
      case ActionItemAndIconLinkCardId.ORDER_TRANSPONDER:
        setPurchaseTransponderMode(true);
        break;
      case ActionItemAndIconLinkCardId.PARKING:
        setParkingMode(true);
        break;
      default:
        break;
    }
  };

  return (
    <button
      className={`action-card rounded-md ${
        selected
          ? 'selected-card'
          : 'not-selected-card cursor-pointer border border-borders-gray1 bg-primary-white shadow-lg'
      } ${!height && autoHeight ? 'auto-height' : `fixed-height ${`h-` + height?.toString()}`}`}
      style={{ height: height, minHeight: height }}
      onClick={() => {
        if (!selected) {
          history(link);
          checkActionItemId();
        }
      }}
      disabled={selected}
    >
      <Tooltip title={tooltip} placement="bottom" className="flex">
        {selected ? (
          <FontAwesomeIcon
            className="icon mb-auto mr-2 mt-auto sm:mr-5"
            icon={faCheckCircle}
            color={colors.primary.green1}
          ></FontAwesomeIcon>
        ) : (
          <span className="dot icon mb-auto mr-2 mt-auto sm:mr-5"></span>
        )}
        <div
          className={`${classNames({ 'text-primary-gray4': disabled })} mt-1 ${
            selected ? 'font-bold' : 'font-normal'
          } action-item-title`}
        >
          {title}
        </div>
        {!selected && (
          <FontAwesomeIcon
            className="mb-auto ml-auto mr-0 mt-auto hidden xsm:block"
            icon={faArrowRight}
            color={colors.primary.green1}
            size="lg"
          ></FontAwesomeIcon>
        )}
      </Tooltip>
    </button>
  );
}

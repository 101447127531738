import _ from 'lodash';
import React from 'react';

export interface IUseScroll {
  scrollToError: (errors: object) => void;
  scrollToElem: (id: string | React.RefObject<HTMLElement>, scrollToTop?: boolean) => void;
}

const scrollArgs: ScrollIntoViewOptions = {
  block: 'center',
  behavior: 'smooth',
};
const scrollToTopArgs: ScrollIntoViewOptions = {
  block: 'start',
  behavior: 'smooth',
};

const isFormFieldOfArrayType = (errorFormFieldsInfo: any[]) => _.isArray(errorFormFieldsInfo[0]);

const isIndexValueInvalid = (fields: object[], index: number, formName: string): boolean => {
  return _.isEmpty(fields) || index < 0 || _.isEmpty(formName);
};

const createFieldNameForArrayFieldType = (formName: string, index: number, fields: object[]) => {
  if (isIndexValueInvalid(fields, index, formName)) return '';
  return `${formName}[${index}].${Object.keys(fields)[0]}`;
};

export const generateErrorFieldName = (errors: object): string => {
  if (_.isEmpty(errors)) return '';
  const errorFieldsNames = Object.keys(errors);
  const errorFieldsInfo = Object.values(errors);
  const firstErrorFieldName = errorFieldsNames[0];
  if (isFormFieldOfArrayType(errorFieldsInfo)) {
    return (
      [...errors[firstErrorFieldName as keyof object]]
        .map((item, index) => item && createFieldNameForArrayFieldType(firstErrorFieldName, index, item))
        .filter((item) => !_.isEmpty(item))[0] || ''
    );
  } else {
    return firstErrorFieldName;
  }
};

export const useScroll = (): IUseScroll => {
  const scrollToError = (errors: object) => {
    const errorFieldName = generateErrorFieldName(errors);
    errorFieldName && scrollToElem(errorFieldName);
  };

  const scrollToElem = (element: string | React.RefObject<HTMLElement>, scrollToTop = false) => {
    if (typeof element !== 'string' && element && element.current) {
      element.current.scrollIntoView(scrollToTop ? scrollToTopArgs : scrollArgs);
      element.current.focus();
    } else {
      const foundElement = document.getElementById(element as string);
      if (foundElement) {
        foundElement?.scrollIntoView(scrollToTop ? scrollToTopArgs : scrollArgs);
        foundElement?.focus();
      }
    }
  };

  return {
    scrollToError,
    scrollToElem,
  };
};

import { gql } from '@apollo/client';
import { AccountType } from '../types/onboarding.types';

export const GET_ACCOUNT_DETAILS = gql`
  query accountDetails {
    accountDetails {
      addressLine1
      addressLine2
      customerName
      cellPhone
      workPhone
      homePhone
      emailAddress
      currentBalance
      accountNumber
      accountType
      city
      state
      country
      zipcode
      parkingOption
      smsOption
      stmtDeliveryInterval
      stmtDeliveryMethod
      secondaryEmailAddress
      language
      lastReplenished
      deliveryType
      tollBalance
      replenishThreshold
      replenishAmount
      transponderListCount
      transactionAccounts {
        transactionDate
        transactionTime
        exitPlazaName
        transponder
        amount
      }
      secondaryContacts {
        displayIndex
        firstName
        lastName
        middleName
        secondaryContactId
      }
      password
      username
      securityQuestions {
        firstQuestion
        secondQuestion
        thirdQuestion
        firstAnswer
        secondAnswer
        thirdAnswer
      }
    }
  }
`;
export interface IAccountDetails {
  accountFinancialStatus: string;
  accountNumber?: string;
  accountStatus: string;
  accountType: AccountType;
  addressLine1: string;
  addressLine2: string;
  cellPhone: string;
  city: string;
  country: string;
  currentBalance: number;
  customerName: string;
  workPhone: string;
  deliveryType: string;
  documentBalance: number;
  emailAddress: string;
  emailCount: number;
  emailUniqueCode: string;
  homePhone: string;
  faxPhone: string;
  language: string;
  lastReplenished: string;
  marketOption: string;
  maximumBalance: number;
  memberSince: string;
  minPaymentBalance: number;
  parkingOption: string;
  password: string;
  payByMailBalance: number;
  paymentTypeInfo: string;
  plannedNonUse: string;
  primaryEmailStatus: string;
  reBillPayType: string;
  replenishAmount: number;
  replenishThreshold: number;
  secondaryContacts: ISecondaryContacts[];
  secondaryEmailAddress: string;
  securityPin: string;
  securityQuestions: IAccountSecurityQuestions;
  showSuspendedInfo: boolean;
  smsOption: boolean;
  state: string;
  stmtDeliveryInterval: string;
  stmtDeliveryMethod: string;
  stmtDeliveryType: string;
  tollBalance: number;
  transactionAccounts: ITransactionAccount[];
  transactionListCount: number;
  transactionSearchLimit: number;
  transponderListCount: number;
  transponderListSearch: boolean;
  username: string;
  vehicleListCount: number;
  vehicleListSearch: boolean;
  violationBalance: number;
  zipcode: string;
  zipcodePlus: string;
}

export interface IAccountSecurityQuestions {
  firstAnswer: string;
  secondAnswer: string;
  thirdAnswer: string;
  firstQuestion: string;
  secondQuestion: string;
  thirdQuestion: string;
}

export interface ITransactionAccount {
  amount: string;
  cscLookupKey: string;
  description: string;
  entryLane: string;
  entryPlaza: string;
  exitLane: string;
  exitPlaza: string;
  exitPlazaName: string;
  tagorplate: string;
  transactionDate: string;
  transactionTime: string;
  transponder: string;
}

export interface ISecondaryContacts {
  displayIndex: number;
  firstName: string;
  lastName: string;
  middleName: string;
  secondaryContactId: string;
}

import { t } from '@lingui/macro';
import { message } from 'antd';
import { NoticeType } from 'antd/lib/message';

export const showMessage = (type: NoticeType, content?: string) => {
  switch (type) {
    case 'success':
      message.success({
        content: content || t`Your changes have been saved.`,
        type: 'success',
        duration: 6,
      });
      break;
    case 'error':
      message.error({
        content: content || t`Please try again.`,
        type: 'error',
        duration: 6,
      });
      break;
    default:
      return;
  }
};

import { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { t, Trans } from '@lingui/macro';
import { Form, Input } from 'antd';
import ConfirmationModal from 'src/components/Modals/ConfirmationModal/ConfirmationModal';
import PaymentMethodFormField from '../Payments.enums';
import { IPaymentMethodGraphQl } from 'src/graphql/paymentMethods.graphql';
import { validators } from 'src/validators/validators';
import { convertPriceToString, getPaymentMethodName } from 'src/utils/formatter';
import { useSecurityCodeModalStore } from 'src/store/payment/securityCodeModal.store';
import classNames from 'classnames';
import useObfuscatedInput from '../../../hooks/obfuscatedInput/useObfuscatedInput';

interface ISecurityCodeModalProps {
  paymentMethod?: IPaymentMethodGraphQl | null;
  amount: number | null;
  onSubmit: () => void;
}

const SecurityCodeModal: FC<ISecurityCodeModalProps> = ({ paymentMethod, amount, onSubmit }) => {
  const obfuscatedInputprops = useObfuscatedInput();

  const schema = yup.object().shape({
    [PaymentMethodFormField.CC_SECURITY_CODE]: validators.securityCode,
  });

  const {
    securityCodeModalData: { showSecurityCodeModal },
    setSecurityCode,
    setShowSecurityCodeModal,
  } = useSecurityCodeModalStore();

  const {
    control,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), mode: 'onChange', reValidateMode: 'onChange' });

  const CC_SECURITY_CODE_ERROR = errors[PaymentMethodFormField.CC_SECURITY_CODE];

  const securityCodeWatch = watch(PaymentMethodFormField.CC_SECURITY_CODE);

  useEffect(() => {
    setSecurityCode(securityCodeWatch);
  }, [securityCodeWatch]);

  const cardNumber = paymentMethod && getPaymentMethodName(paymentMethod);

  const confirmModalAction = () => {
    onSubmit();
    reset();
  };

  const cancelModalAction = () => {
    setShowSecurityCodeModal(false);
    reset();
  };

  const allowOnlyNumbersInput = (e: React.KeyboardEvent) => {
    const specialCharRegex = new RegExp('[0-9]');
    const pressedKey = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (!specialCharRegex.test(pressedKey)) {
      e.preventDefault();
      return false;
    }
  };

  const modalContent = (
    <>
      <p>
        <Trans>
          Please enter the security code and confirm that {amount && convertPriceToString(amount)} will be charged to{' '}
          {cardNumber}
        </Trans>
      </p>
      <div className="flex justify-center">
        <div className="w-48">
          <Form.Item
            label={t`Security Code`}
            validateStatus={errors.ccSecurityCode ? 'error' : ''}
            help={CC_SECURITY_CODE_ERROR?.message as unknown as string}
            htmlFor={PaymentMethodFormField.CC_SECURITY_CODE}
          >
            <Controller
              name={PaymentMethodFormField.CC_SECURITY_CODE}
              control={control}
              render={({ field, fieldState }) => (
                <div>
                  <Input
                    key={PaymentMethodFormField.CC_SECURITY_CODE}
                    {...field}
                    {...fieldState}
                    className={`security-code-input ${classNames({
                      'input-error': fieldState.invalid,
                    })}`}
                    maxLength={4}
                    minLength={3}
                    id={PaymentMethodFormField.CC_SECURITY_CODE}
                    name={PaymentMethodFormField.CC_SECURITY_CODE}
                    value={field.value}
                    onKeyPress={allowOnlyNumbersInput}
                    aria-label={t`security Code`}
                    {...obfuscatedInputprops}
                  />
                </div>
              )}
            />
          </Form.Item>
        </div>
      </div>
    </>
  );

  return (
    <Form>
      <ConfirmationModal
        title="Confirm Payment"
        content={modalContent}
        showModal={paymentMethod ? showSecurityCodeModal : false}
        alignText="center"
        okButtonText="Confirm"
        onCancel={cancelModalAction}
        onOk={handleSubmit(confirmModalAction)}
      />
    </Form>
  );
};

export default SecurityCodeModal;

import create from 'zustand';
import { devtools } from 'zustand/middleware';

interface IOneTimePaymentStore {
  isOneTimePayment: boolean;
  changeIsOneTimePayment: (isOneTimePayment: boolean) => void;
}

export const useOneTimePaymentStore = create<IOneTimePaymentStore, [['zustand/devtools', IOneTimePaymentStore]]>(
  devtools((set) => ({
    isOneTimePayment: false,
    changeIsOneTimePayment: (isOneTimePayment: boolean) => set(() => ({ isOneTimePayment })),
  })),
);

import React, { useEffect, useMemo, useState } from 'react';
import { useOnboardingStore } from '../../../store/onboarding/onboarding.store';
import { AccountSubtype, AccountType, IAccountInfo, OnboardingFlowType } from '../../../types/onboarding.types';
import { FormProvider } from 'react-hook-form';
import { MainLoader } from '../../../components/Loader/MainLoader';
import ScrollToTop from '../../../components/ScrollToTop/ScrollToTop';
import OnboardingHeader, { OnboardingHeaderWholeViewProps } from '../../../components/Onboarding/OnboardingHeader';
import { t, Trans } from '@lingui/macro';
import { Alert, Form } from 'antd';
import { ReferenceNumber } from '../../../components/Onboarding/ReferenceNumber';
import { OnboardingAccountHolderInfo } from '../../../components/Onboarding/Account/OnboardingAccountHolderInfo';
import { MailingAddressInfo } from '../../../components/Onboarding/Account/MailingAddressInfo';
import { AdditionalContactsInfo } from '../../../components/Onboarding/Account/AdditionalContactsInfo';
import { OnboardingPrivacyAndSecurity } from '../../../components/Onboarding/Account/OnboardingPrivacyAndSecurity';
import { FormRequiredFieldsMsg } from '../../../components/Onboarding/Account/FormRequiredFieldsMsg';
import BackButton from '../../../components/BackButton/BackButton';
import { CustomButton } from '../../../components/CustomButton/CustomButton';
import { useAccountInfo } from '../../../hooks/domain/onboarding/accountInfo/useAccountInfo';
import { useAccountInfoForm } from '../../../hooks/domain/onboarding/accountInfo/useAccountInfoForm';
import { useRouteMonitor } from '../../../hooks/location/useRouteMonitor';
import { resetAllStores } from 'src/store/createStore';
import { MainRoutes, OnboardingStepPath } from 'src/types/routes';
import ExitAccountCreation from 'src/components/Modals/ConfirmationModal/accountCreation/ExitAccountCreation';
import { SELECT_RECOMMENDED_SUNPASS_PLAN_NAME } from '../../../store/onboarding/onboarding.selectors';
import { useOnboardingErrors } from 'src/store/errors/onboardingErrors.store';
import { OnErrorCode, errorMap } from '../OnboardingErrors/ErrorCodesOnboardingActivation';

export default function OnboardingAccountInfo(): React.ReactElement {
  const {
    onboardingInfo: { accountType, referenceNumber, accountSubtype, onboardingFlowType },

    updateOnboardingStore,
  } = useOnboardingStore();

  const { handleSubmit, methods, errorLoadingSecurityQuestions, errorLoadingAddress } = useAccountInfoForm();
  const { getValues, setError } = methods;
  const { onSubmit, steps, recaptchaChallengeElem, addressStandardizationModal, loading } = useAccountInfo();

  const { onboardingError } = useOnboardingErrors();

  useEffect(() => {
    if (onboardingError?.errorByType) {
      OnErrorCode(onboardingError?.errorByType, errorMap, setError);
    }
  }, [onboardingError]);

  const history = useRouteMonitor();
  const [isExitModalShown, setIsExitModalShown] = useState(false);

  const onboardingHistory = history.getOnboardingHistory();

  const onBack = async () => {
    const account: IAccountInfo = await getValues();
    updateOnboardingStore({
      chosenSubtype: accountSubtype,
      accountSubtype:
        accountSubtype === AccountSubtype.PB &&
        onboardingHistory[onboardingHistory.length - 2] === OnboardingStepPath.PINELLAS_RECOMENDATION
          ? AccountSubtype.PBC
          : accountSubtype,
      accountType: accountType,
      accountInfo: account as IAccountInfo,
    });
    history.goBack();
  };

  const planName = useMemo(() => {
    return SELECT_RECOMMENDED_SUNPASS_PLAN_NAME(accountType, accountSubtype);
  }, [accountType, accountSubtype]);

  return (
    <div className="h-full">
      <MainLoader loading={loading} />
      <ScrollToTop />
      <OnboardingHeader<OnboardingHeaderWholeViewProps>
        title={t`First, we just need some essential information.`}
        steps={steps ? steps : []}
        currentStepIndex={1}
        planName={planName}
      />

      <Form className="theme" layout="vertical" size="large" onFinish={handleSubmit(onSubmit)}>
        {accountType === AccountType.GOVERNMENT ? (
          <div className="warning-container">
            <div className="w-5/6 w-full ">
              <Alert
                message={'Please Note: A SunPass representative will need to verify your information'}
                description={
                  <Trans>
                    {'Once your application is submitted for review, a SunPass representative will contact you to discuss the next\n' +
                      'steps on completing your new Government Postpaid Account. Please be prepared to provide your funded\n' +
                      'Purchase Order number, vehicle license plate list, the number of transponders needed, and the Florida Tax\n' +
                      'Exempt certificate to be associated with this account.'}
                  </Trans>
                }
                type="warning"
                showIcon
              />
            </div>
          </div>
        ) : null}
        <FormProvider {...methods}>
          <ReferenceNumber />
          <OnboardingAccountHolderInfo />
          <MailingAddressInfo errorLoadingAddress={errorLoadingAddress} isCountyRequired={true} />
          {accountType !== AccountType.GOVERNMENT ? <AdditionalContactsInfo /> : null}
          {accountType !== AccountType.GOVERNMENT ? (
            <OnboardingPrivacyAndSecurity errorLoadingSecurityQuestions={errorLoadingSecurityQuestions} />
          ) : null}
          <FormRequiredFieldsMsg />
          <div className="onboarding-button-container">
            <BackButton
              content={referenceNumber && onboardingFlowType === OnboardingFlowType.PURCHASE ? 'Cancel' : 'Back'}
              onBack={
                referenceNumber && onboardingFlowType === OnboardingFlowType.PURCHASE
                  ? () => setIsExitModalShown(true)
                  : onBack
              }
              blockRedirectTill={true}
            />
            <CustomButton content="Continue" type="submit" />
          </div>
          {addressStandardizationModal}
        </FormProvider>
        {recaptchaChallengeElem}
      </Form>
      {referenceNumber && onboardingFlowType === OnboardingFlowType.PURCHASE ? (
        <ExitAccountCreation
          showModal={isExitModalShown}
          onOk={() => {
            resetAllStores();
            history.goToPath(MainRoutes.ROOT);
          }}
          onCancel={() => setIsExitModalShown(false)}
        />
      ) : null}
    </div>
  );
}

import { Controller, useFormContext } from 'react-hook-form';
import { Form, Select } from 'antd';
import { INotificationsSettings } from '../MyAccount/Notifications/Notifications';
import { useQuery } from '@apollo/client';
import { GET_LANGUAGES } from '../../graphql/languages.graphql';
import { capitalizeInitials } from '../../utils/string.utils';
import { MainLoader } from 'src/components/Loader/MainLoader';
import { t } from '@lingui/macro';

interface ILanguageProps {
  label: string | JSX.Element;
  required?: boolean;
  style?: string;
}

const Language = ({ label, required = false, style }: ILanguageProps) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<INotificationsSettings>();
  const { Option } = Select;
  const languageWatch = watch('language');

  const { data: languagesData, loading: languagesLoading } = useQuery<{ languages: string[] }>(GET_LANGUAGES);

  return (
    <div className="notifications w-full">
      <MainLoader loading={languagesLoading} />
      <Form.Item
        hasFeedback
        label={label}
        className={`theme ${style ? style : ''}`}
        validateStatus={errors.language ? 'error' : ''}
        help={errors.language?.message}
        required={required}
      >
        <Controller
          name="language"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              id="language"
              key="language"
              optionFilterProp="children"
              showSearch
              defaultValue={languageWatch}
              aria-label={t`Language`}
            >
              {languagesData?.languages?.map((language, index) => (
                <Option key={index} value={language}>
                  {capitalizeInitials(language)}
                </Option>
              ))}
            </Select>
          )}
        />
      </Form.Item>
    </div>
  );
};

export default Language;

import { t, Trans } from '@lingui/macro';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Button, Form, Radio } from 'antd';
import StyledInput from '../../StyledInput/StyledInput';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { colors } from '../../../theme/theme';
import CollapsableContainer from '../../CollapsableContainer/CollapsableContainer';
import { useEffect, useState } from 'react';
import { IAccountInfo } from '../../../types/onboarding.types';
import { InputPropsType } from '../../MyAccount/ContactInformation/Contact';
import { ADDITIONAL_CONTACTS_LIMIT } from 'src/constants';
import { IAccountDetails } from '../../../graphql/accountDetails.graphql';

export interface IAdditionalContactsProps extends InputPropsType {
  withInitialQuestion?: boolean;
  walletSection?: boolean;
  accountDetailsData?: IAccountDetails | undefined;
  isInDashboard?: boolean;
}

export const AdditionalContactsInfo = ({
  withInitialQuestion,
  walletSection,
  disabled = false,
  accountDetailsData,
  isInDashboard,
}: IAdditionalContactsProps) => {
  const [contactsCollapsed, setContactsCollapsed] = useState(false);
  const {
    control,
    watch,
    getValues,
    setValue,
    formState: { errors, isValid, isSubmitted },
  } = useFormContext<IAccountInfo>();

  useEffect(() => {
    if (!isValid && isSubmitted) {
      setContactsCollapsed(false);
    }
  }, [errors, isSubmitted, isValid]);

  const additionalContactsWatch = watch('additionalContacts');

  const onShowAdditionalContacts = () => {
    const showAdditionalContacts = accountDetailsData ? true : !getValues('showAdditionalContacts');
    const isAdditionalContactEmpty =
      additionalContactsWatch?.length == 1 &&
      additionalContactsWatch[0].firstName == '' &&
      additionalContactsWatch[0].lastName == '';

    if (showAdditionalContacts) {
      (additionalContactsWatch == undefined || additionalContactsWatch.length == 0) &&
        setValue('additionalContacts', [
          {
            id: 1,
            firstName: '',
            lastName: '',
          },
        ]);
    } else if (isAdditionalContactEmpty) {
      setValue('additionalContacts', []);
    }
    setValue('showAdditionalContacts', showAdditionalContacts);
  };

  useEffect(() => {
    if (additionalContactsWatch?.length === 0) {
      additionalContactsFields.pop();
    }
  }, [additionalContactsWatch]);

  const {
    fields: additionalContactsFields,
    append: additionalContactsAppend,
    remove: additionalContactsRemove,
  } = useFieldArray({
    control,
    name: 'additionalContacts',
  });

  const fields = additionalContactsFields.map((field, index) => {
    return (
      <div key={field.id} className="flex flex-wrap">
        <div className="w-full sm:w-5/12 sm:pr-1">
          <Form.Item {...field} required>
            <label className="form-label">
              <Trans>First Name</Trans>
            </label>
            <Controller
              control={control}
              name={`additionalContacts.${index}.firstName`}
              render={({ field, fieldState }) => (
                <StyledInput
                  disabled={disabled}
                  field={field}
                  fieldstate={fieldState}
                  onChange={field.onChange}
                  maxLength={40}
                  id={`additionalContacts[${index}].firstName`}
                  key={`additionalContacts[${index}].firstName`}
                  name={`additionalContacts[${index}].firstName`}
                  aria-label={t`First Name`}
                />
              )}
            />
            <div className="form-error">{errors.additionalContacts?.[index]?.firstName?.message}</div>
          </Form.Item>
        </div>
        <div className="w-full sm:w-1/12 sm:pr-1">
          <Form.Item {...field} required>
            <label className="form-label">
              <Trans>M.I.</Trans>
            </label>
            <Controller
              control={control}
              name={`additionalContacts.${index}.middleName`}
              render={({ field, fieldState }) => (
                <StyledInput
                  disabled={disabled}
                  field={field}
                  fieldstate={fieldState}
                  onChange={field.onChange}
                  id={`additionalContacts[${index}].middleName`}
                  key={`additionalContacts[${index}].middleName`}
                  name={`additionalContacts[${index}].middleName`}
                  maxLength={1}
                  aria-label={t`Middle Name`}
                />
              )}
            />
            <div className="form-error">{errors.additionalContacts?.[index]?.middleName?.message}</div>
          </Form.Item>
        </div>
        <div className="w-full sm:w-5/12 sm:pl-1">
          <Form.Item {...field}>
            <label className="form-label">
              <Trans>Last Name</Trans>
            </label>
            <Controller
              control={control}
              name={`additionalContacts.${index}.lastName`}
              render={({ field, fieldState }) => (
                <StyledInput
                  disabled={disabled}
                  field={field}
                  fieldstate={fieldState}
                  onChange={field.onChange}
                  maxLength={40}
                  id={`additionalContacts[${index}].lastName`}
                  key={`additionalContacts[${index}].lastName`}
                  name={`additionalContacts[${index}].lastName`}
                  aria-label={t`Last Name`}
                />
              )}
            />
            <div className="form-error">{errors.additionalContacts?.[index]?.lastName?.message}</div>
          </Form.Item>
        </div>
        <Button
          type="link"
          size="small"
          className={`theme col-span-full row-span-full ${disabled ? 'text-disabled' : 'text-red'} underline `}
          onClick={() => additionalContactsRemove(index)}
          disabled={disabled}
          icon={<MinusCircleOutlined className="dynamic-delete-button mr-2" color={colors.alerts.red} />}
        >
          <Trans>Remove additional contact</Trans>
        </Button>
      </div>
    );
  });

  useEffect(() => {
    if (
      (additionalContactsFields.length === 0 && !isInDashboard) ||
      (isInDashboard &&
        accountDetailsData?.secondaryContacts.length === 0 &&
        accountDetailsData?.secondaryContacts[0].firstName === null)
    ) {
      setValue('showAdditionalContacts', false);
    } else if (isInDashboard && accountDetailsData?.secondaryContacts[0].firstName !== null) {
      setValue('showAdditionalContacts', true);
    }
  }, [additionalContactsFields, isInDashboard, accountDetailsData]);

  return (
    <CollapsableContainer
      title={t`Additional Contact(s)`}
      onChange={(panels: any) => setContactsCollapsed(!panels[0])}
      activeKey={contactsCollapsed ? [] : ['1']}
      {...(walletSection && { unsavedChanges: additionalContactsFields.length !== 0 })}
      responsive
    >
      <div className="flex flex-wrap">
        <div className="mt-7 w-full">
          <Trans>
            Person(s) listed as additional contacts on your SunPass account are able to receive assistance from SunPass
            customer service representatives, and are considered secondary account holders.
          </Trans>
        </div>
      </div>
      {!withInitialQuestion ? (
        <div className="my-5">
          <div className="my-5 flex flex-wrap">
            <div className="w-full">
              <b>
                <label id="showAdditionalContactsLabel" htmlFor="showAdditionalContacts">
                  <Trans>
                    Do you have additional people you would like to add as additional contacts to your account?
                  </Trans>
                </label>
              </b>
            </div>
          </div>
          <div className="mt-5 flex flex-wrap">
            <div className="w-full" role="radiogroup" aria-labelledby="showAdditionalContactsLabel">
              <Controller
                name="showAdditionalContacts"
                control={control}
                render={({ field }) => (
                  <Radio.Group
                    {...field}
                    id="showAdditionalContacts"
                    onChange={onShowAdditionalContacts}
                    disabled={disabled}
                    aria-label={t`Additional Contacts`}
                  >
                    <Radio value={true}>
                      <Trans>Yes</Trans>
                    </Radio>
                    <Radio value={false} onClick={() => additionalContactsRemove()}>
                      <Trans>No</Trans>
                    </Radio>
                  </Radio.Group>
                )}
              />
            </div>
          </div>
        </div>
      ) : null}
      {(watch('showAdditionalContacts') || withInitialQuestion) && (
        <div>
          {watch('showAdditionalContacts') && fields}
          {additionalContactsWatch && additionalContactsWatch.length < ADDITIONAL_CONTACTS_LIMIT ? (
            <Button
              type="link"
              size="small"
              onClick={(event) => {
                watch('showAdditionalContacts') && additionalContactsAppend({ id: 1, firstName: '', lastName: '' });
                setValue('showAdditionalContacts', true);
                event.currentTarget.blur();
              }}
              disabled={disabled}
              className={`theme ${disabled ? 'text-disabled' : 'text-green'} mt-3 underline`}
              icon={<PlusCircleOutlined width={16} height={16} className="mr-2" />}
            >
              <Trans>Add an additional contact</Trans>
            </Button>
          ) : null}
        </div>
      )}
    </CollapsableContainer>
  );
};

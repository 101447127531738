import { FC, useRef } from 'react';
import ActivityList from './ActivityList/ActivityList';
import { useQuery } from '@apollo/client';
import {
  GET_TRANSACTION_ACTIVITY_LIST,
  ITransactionActivityList,
  ITransactionActivityListInput,
} from '../../../graphql/transactionsActivity.graphql';
import { MainLoader } from '../../Loader/MainLoader';
import { Trans } from '@lingui/macro';
import { ActivitySearch } from './ActivitySearch/ActivitySearch';
import { usePaginationInfo } from '../../../store/pagination/pagination.store';
import { DRUPAL_FILE_DISPUTE } from '../../../types/routes';
import useScrollOnPaginationChange from 'src/hooks/pagination/useScrollOnPaginationChange';
import { ACTIVITY_HEADER } from '../references';

export interface IScrollToMethod {
  scrollToHeadline: () => void;
}

const Activity: FC = () => {
  const ref = useRef<HTMLDivElement>(null);

  const {
    activitiesPagination: { size, page },
    activitiesParams: {
      plateNumber,
      transponderNumber,
      agencyName,
      endDate,
      startDate,
      searchDate,
      location,
      transactionType,
    },
  } = usePaginationInfo();

  const transactionActivityListInput = {
    pagination: {
      pageSize: size,
      pageNumber: page,
    },
    plateNumber,
    transponderNumber,
    agencyName,
    endDate,
    startDate,
    searchDate,
    location,
    transactionType,
  };

  const {
    data: transactionActivityData,
    loading: transactionActivityLoading,
    refetch,
  } = useQuery<
    { transactionActivityList: ITransactionActivityList },
    { transactionActivityListInput: ITransactionActivityListInput }
  >(GET_TRANSACTION_ACTIVITY_LIST, {
    notifyOnNetworkStatusChange: true,
    variables: {
      transactionActivityListInput: { ...transactionActivityListInput },
    },
  });

  useScrollOnPaginationChange({ data: transactionActivityData, scrollId: ACTIVITY_HEADER });

  return (
    <>
      <MainLoader loading={transactionActivityLoading} />
      <div className="mb-12 flex flex-col items-start justify-between vsm:flex-row" ref={ref}>
        <h1 className="text-primary-blue1">
          <Trans>My Activity</Trans>
        </h1>
        <a
          href={DRUPAL_FILE_DISPUTE}
          className="dashboard-links mt-4 md:w-auto"
          aria-label="Need to dispute a charge?"
          target="_blank"
          rel="noreferrer"
        >
          <span className="large-tablet:mt-8">
            <Trans>Need to dispute a charge?</Trans>
          </span>
        </a>
      </div>
      <span>
        <Trans>
          Account activity can be accessed for a period of up to 90 days, and may be viewed or downloaded for the past
          36 months.
        </Trans>
      </span>
      <ActivitySearch
        transactionActivityData={transactionActivityData}
        refetch={refetch}
        transactionActivityLoading={transactionActivityLoading}
      />
      <ActivityList
        transactionActivities={transactionActivityData?.transactionActivityList?.transactionActivities}
        activitiesCount={transactionActivityData?.transactionActivityList?.totalElements}
      />
    </>
  );
};

export default Activity;

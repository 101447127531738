import { FC } from 'react';
import { Form, Select } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import { t, Trans } from '@lingui/macro';
import classNames from 'classnames';
import { LabelValue } from 'src/types/LabelValue.interface';
import { IVehicleFormItemProps } from '../../../Onboarding/Vehicles/OnboardingVehiclesForm/OnboardingVehicleForm';
import useStateOptions from '../../../../hooks/domain/address/useStateOptions';
import { buildErrorString, getInputName } from '../../../Onboarding/Vehicles/OnboardingVehiclesForm/vehicleForm.utils';

const { Option } = Select;

const LicencePlateState: FC<IVehicleFormItemProps> = ({ field, index, name, isEditMode }) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const country = watch(getInputName('vehicleCountry', index));
  const licensePlateNotKnown = watch(getInputName('notKnown', index));

  const { stateOptions } = useStateOptions({ country });

  return (
    <div className="mb-4 w-full sm:mb-0 sm:w-3/12 sm:pr-2">
      <Form.Item
        {...field}
        label={<Trans id="License Plate State" />}
        className="theme"
        htmlFor={name}
        help={buildErrorString(name, errors, index)}
        hasFeedback
        validateStatus={errors?.vehicles?.[0]?.plateState || errors.plateState ? 'error' : ''}
        required
      >
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              {...fieldState}
              id={name}
              className={classNames({ 'input-error': fieldState.invalid })}
              placeholder={t`Select`}
              optionFilterProp="children"
              disabled={licensePlateNotKnown || isEditMode}
              showSearch
              aria-label={t`License Plate State`}
            >
              {stateOptions?.map((state: LabelValue) => (
                <Option key={state.value} value={state.value}>
                  {state.label}
                </Option>
              ))}
            </Select>
          )}
        />
      </Form.Item>
    </div>
  );
};

export default LicencePlateState;

import { Form } from 'antd';
import { t, Trans } from '@lingui/macro';
import { Controller, useFormContext } from 'react-hook-form';
import StyledInput from '../StyledInput/StyledInput';
import React from 'react';
import { ISecurityInfo } from '../../types/onboarding.types';
import { UsernamePolicy } from './UsernamePolicy';
import { IInputElemDefaultProp } from '../atoms/form/form.types';

interface IUsernameProps extends IInputElemDefaultProp {
  username?: string | undefined;
}

export const Username = ({ username, className }: IUsernameProps) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<ISecurityInfo>();
  const usernameWatch = watch('username');
  return (
    <div className="flex flex-wrap">
      <div className={className}>
        <Form.Item
          label={<Trans>Username</Trans>}
          hasFeedback
          help={errors?.username?.message}
          validateTrigger={['onChange', 'onBlur']}
          required
          className="theme"
        >
          <Controller
            name="username"
            control={control}
            render={({ field, fieldState }) => (
              <StyledInput
                field={field}
                fieldstate={fieldState}
                id="username"
                key="username"
                maxLength={30}
                defaultValue={username || usernameWatch}
                aria-label={t`Username`}
              />
            )}
          />
        </Form.Item>
      </div>
      <UsernamePolicy usernameWatch={usernameWatch} />
    </div>
  );
};

import { Trans } from '@lingui/macro';
import { DRUPAL_FILE_DISPUTE } from '../../../types/routes';

const UnpaidTollsHeader = () => {
  return (
    <div className="mb-4 flex w-full flex-col flex-wrap justify-between sm:w-auto sm:flex-row sm:items-center">
      <h1 className="mr-12 text-primary-blue1 ">
        <Trans>My Unpaid Tolls</Trans>
      </h1>
      <div>
        <a
          href={DRUPAL_FILE_DISPUTE}
          className="dashboard-links mt-4 w-full md:w-4/12"
          aria-label="Need to dispute a charge?"
          target="_blank"
          rel="noreferrer"
        >
          <span className="large-tablet:mt-8">
            <Trans>Need to dispute a charge?</Trans>
          </span>
        </a>
      </div>
    </div>
  );
};

export default UnpaidTollsHeader;

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import './Loader.scss';

export interface ILoader {
  loading: boolean;
}

export const MainLoader = ({ loading }: ILoader): React.ReactElement | null => {
  return loading ? (
    <div className="main-loader-container">
      <Spin indicator={<LoadingOutlined className="main-loader-icon" />} />
    </div>
  ) : null;
};

import React, { FC } from 'react';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { colors } from 'src/theme/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from '@lingui/macro';
import './MyAccount.scss';
import { useAccountSettings } from 'src/store/account/accountSettings.store';

const BackLink: FC = () => {
  const { setActiveTab, isTabDirty, setShowConfirmationModal, setTabToChangeTo } = useAccountSettings();

  const handleNavigateBack = () => {
    if (isTabDirty) {
      setShowConfirmationModal(true);
      setTabToChangeTo('0');
    } else {
      setActiveTab('0');
    }
  };
  return (
    <>
      <div className="mx-4 cursor-pointer text-primary-green4" onClick={handleNavigateBack}>
        <FontAwesomeIcon icon={faChevronLeft} color={colors.primary.green4} size="1x"></FontAwesomeIcon>
        <button className="tex-left ml-3 underline">
          <Trans>Account Settings</Trans>
        </button>
      </div>
    </>
  );
};
export default BackLink;

import { gql } from '@apollo/client';
import { IPlansTransponderActivationSignUp } from './signUpTransponderActivation.graphql';

export const SIGN_UP_CONFIRMATION = gql`
  mutation signUpAccountConfirmation($signUpAccountConfirmationInput: SignUpAccountConfirmationInput!) {
    signUpAccountConfirmation(signUpAccountConfirmationInput: $signUpAccountConfirmationInput) {
      accountNumber
      authCode
      srNumber
      transactionId
    }
  }
`;

export interface ISignUpAccountConfirmationInput {
  accountId: string;
  signUpPassword?: string;
  portableTransponderSerialNumber?: string;
  vehicleUploadBatchId?: string;
  vehicleUploadCount?: string;
  plansSignUp?: IPlansAccountConfirmationSignUp;
  totalTranspondersCost?: number;
  totalPlanAmount?: number;
  prepaidAmount?: number;
  additionalAmount?: number;
  applicableTax?: number;
  transactionTotalAmount?: number;
}

export interface IPlansAccountConfirmationSignUp extends IPlansTransponderActivationSignUp {
  miniTransponderSerialNumber?: string;
  proTransponderSerialNumber?: string;
}

export interface ISignUpAccountConfirmationOutput {
  authCode: string;
  accountNumber: string;
  transactionId: string;
  srNumber: string;
}

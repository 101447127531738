import { Form, Select } from 'antd';
import { t, Trans } from '@lingui/macro';
import { Controller, useFormContext } from 'react-hook-form';
import { IHomeSearchForm } from '../../../HomeSearch/HomeSearch';
import { useState } from 'react';
import { useHomeStore } from '../../../../store/home/home.store';
const { Option } = Select;

const Options = () => {
  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<IHomeSearchForm>();

  const {
    homeInfo: { chosenOption },
  } = useHomeStore();

  const [optionValue, setOptionValue] = useState(chosenOption);

  return (
    <Form.Item
      className="home-search-input w-full pt-0"
      name="options"
      label={t`I would like to...`}
      hasFeedback
      validateStatus={errors.options?.message ? 'error' : ''}
      help={errors.options?.message as unknown as string}
      htmlFor="options"
    >
      <Controller
        name="options"
        control={control}
        render={({ field, fieldState }) => (
          <Select
            {...field}
            {...fieldState}
            key="options"
            id="options"
            className="font-normal"
            aria-label={t`I would like to`}
            defaultValue="tollInvoice"
            value={optionValue}
            onChange={(value) => {
              setOptionValue(value);
              setValue('documentId', '');
              setValue('licenseNumber', '');
              setValue('invoiceNumber', '');
              setValue('postalCode', '');
              setValue('options', value);
            }}
          >
            <Option value="tollInvoice" aria-label={t`Pay a Toll Enforcement Invoice`}>
              <Trans>Pay a Toll Enforcement Invoice</Trans>
            </Option>
            <Option value="uniformCitation" aria-label={t`Pay a Uniform Traffic Citation`}>
              <Trans>Pay a Uniform Traffic Citation</Trans>
            </Option>
            <Option value="withoutInvoiceOrCitation" aria-label={t`Pay without an Invoice or Citation`}>
              <Trans>Pay without an Invoice or Citation</Trans>
            </Option>
          </Select>
        )}
      />
    </Form.Item>
  );
};

export default Options;

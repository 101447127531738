import { Col, Row } from 'antd';
import React from 'react';
import Shortcuts from '../Shortcuts/Shortcuts';
import TranspondersActivity from './TranspondersActivity/TranspondersActivity';

import AddFunds from './AddFunds/AddFunds';
import VehiclesAndTransponders from './VehiclesAndTransponders/VehiclesAndTransponders';
import './MyDashboard.scss';
import PromoContent from './PromoContent/PromoContent';
import { IAccountDetails } from '../../../graphql/accountDetails.graphql';

interface MyDashboardProps {
  accountDetails: IAccountDetails | undefined;
}

export default function MyDashboard({ accountDetails }: MyDashboardProps) {
  return (
    <>
      <Row>
        <Col className="mr-6">
          <AddFunds />
        </Col>
        <Col className="my-dashboard">
          <Shortcuts accountDetails={accountDetails} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <TranspondersActivity />
        </Col>
      </Row>
      <PromoContent />
      <VehiclesAndTransponders accountDetails={accountDetails} />
    </>
  );
}

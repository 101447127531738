import { create } from '../createStore';
import { devtools } from 'zustand/middleware';
import { IPaymentMethodGraphQl } from '../../graphql/paymentMethods.graphql';

export interface IContactSettingsStore {
  closeAccountInfo: ICloseAccountInfo;
  updateCloseAccountInfo: (data: Partial<ICloseAccountInfo>) => void;
  resetStore: () => void;
}

export interface ICloseAccountInfo {
  existingCard: IPaymentMethodGraphQl | null;
  isCloseProcessActive: boolean;
  isNewCardChosen: boolean;
  closeAccountReason: string;
}

const CLOSE_ACCOUNT_INITIAL_DATA: ICloseAccountInfo = {
  existingCard: null,
  isNewCardChosen: false,
  isCloseProcessActive: false,
  closeAccountReason: '',
};

export const useContactSettingsStore = create<
  IContactSettingsStore,
  [['zustand/devtools', IContactSettingsStore], ['zustand/persist', IContactSettingsStore]]
>(
  devtools((set) => ({
    closeAccountInfo: CLOSE_ACCOUNT_INITIAL_DATA,
    resetStore: () => set(() => ({ closeAccountInfo: CLOSE_ACCOUNT_INITIAL_DATA })),
    updateCloseAccountInfo: (data: Partial<ICloseAccountInfo>) => {
      set((state) => ({
        closeAccountInfo: {
          ...state.closeAccountInfo,
          ...data,
        },
      }));
    },
  })),
);

import { TransponderType } from 'src/graphql/transponders.graphql';

export const mapTransponderTypeToField = (transponderType: TransponderType) => {
  if (transponderType === TransponderType.MINI) {
    return 'numOfStickerTransponders';
  } else if (transponderType === TransponderType.PRO) {
    return 'numOfProTransponders';
  } else {
    return '';
  }
};

import { faCreditCard, faUser } from '@fortawesome/free-regular-svg-icons';
import { faSdCard, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { t } from '@lingui/macro';
import React from 'react';
import IconLinkCard from '../../LinkCards/IconLinkCard';
import ShortCutIcon from '../assets/Yellow-Shortcuts-Icon.svg';
import { MainRoutes, DashboardParams, concatRoutes } from '../../../types/routes';
import { ActionItemAndIconLinkCardId } from '../ActionItemBanner/ActionItemAndIconLinkCardId';
import './Shortcuts.scss';
import { IAccountDetails } from '../../../graphql/accountDetails.graphql';
import { AccountType } from '../../../types/onboarding.types';
import { usePlans } from '../MyDashboard/usePlans';

interface ShortcutsProps {
  accountDetails: IAccountDetails | undefined;
}

const Shortcuts = ({ accountDetails }: ShortcutsProps) => {
  const { PAYGAccount } = usePlans();
  const accountType = accountDetails?.accountType;

  const isPAYGAccount = accountType === AccountType.PRIVATE && PAYGAccount;

  return (
    <div>
      <div className="mb-6 flex items-center ">
        <img className="block" src={ShortCutIcon} alt="Shortcuts icon" />
        <div className="ml-3 text-xl font-bold sm:ml-6 sm:text-3xl">Shortcuts</div>
      </div>

      <div className="mt-4 w-full space-y-6">
        <IconLinkCard
          id={ActionItemAndIconLinkCardId.ADD_PAYMENT_METHOD}
          title={t`Add A Payment Method`}
          icon={faCreditCard}
          link={concatRoutes(MainRoutes.DASHBOARD, '/', DashboardParams.WALLET)}
          classNames="icon-link-card-shortcuts"
        />
        <IconLinkCard
          id={ActionItemAndIconLinkCardId.EDIT_ACCOUNT_INFO}
          title={t`Edit Your Account Information`}
          icon={faUser}
          link={MainRoutes.MY_ACCOUNT}
          classNames="icon-link-card-shortcuts"
        />
        {!isPAYGAccount ? (
          <>
            <IconLinkCard
              id={ActionItemAndIconLinkCardId.ACTIVATE_TRANSPONDER}
              title={t`Activate A Transponder`}
              icon={faSdCard}
              link={concatRoutes(MainRoutes.DASHBOARD, '/', DashboardParams.TRANSPONDERS)}
              classNames="icon-link-card-shortcuts"
            />
            <IconLinkCard
              id={ActionItemAndIconLinkCardId.PURCHASE_TRANSPONDER}
              title={t`Purchase A Transponder`}
              icon={faShoppingCart}
              link={concatRoutes(MainRoutes.DASHBOARD, '/', DashboardParams.TRANSPONDERS)}
              classNames="icon-link-card-shortcuts"
            />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Shortcuts;

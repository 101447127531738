export enum PaymentMethodFormField {
  PAYMENT_TYPE = 'paymentType',
  CC_FIRST_NAME = 'ccFirstName',
  CC_MIDDLE_NAME = 'ccMiddleName',
  CC_LAST_NAME = 'ccLastName',
  CC_NUMBER = 'ccNumber',
  CC_EXPIRATION_MONTH = 'ccExpirationMonth',
  CC_EXPIRATION_YEAR = 'ccExpirationYear',
  CC_SECURITY_CODE = 'ccSecurityCode',
  BANK_ACCOUNT_TYPE = 'bankAccountType',
  BANK_FIRST_NAME = 'bankFirstName',
  BANK_LAST_NAME = 'bankLastName',
  BANK_ACCOUNT_NUMBER = 'bankAccountNumber',
  BANK_ACCOUNT_NUMBER_CONFIRM = 'bankAccountNumberConfirm',
  BANK_ROUTING_NUMBER = 'bankRoutingNumber',
  IS_PRIMARY = 'isPrimary',
  SAVE_CARD = 'saveCard',
  IS_EASY_PAY = 'optInEZPay',
  REPLENISHMENT_AMOUNT = 'replenishmentThresholdAmount',
  AUTHORIZE_REPLENISHMENT = 'authorizeReplenishment',
}
export enum PaymentMethodType {
  CARD = 'card',
  BANK = 'bank',
}

export enum BankAccountType {
  CHECKING = 'CHECKING',
  SAVINGS = 'SAVINGS',
}

export default PaymentMethodFormField;

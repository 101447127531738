import { useEffect, useMemo } from 'react';
import { AccountType } from '../../../types/onboarding.types';
import { IAccountDetails } from '../../../graphql/accountDetails.graphql';
import { IPaymentMethodGraphQl } from '../../../graphql/paymentMethods.graphql';
import { ITransponderList } from '../../../graphql/transponders.graphql';
import { IVehicle } from '../../../graphql/vehicles.graphql';
import { useQuery } from '@apollo/client';
import { MINIMUM_AMOUNT } from '../../../graphql/minimumAmount.graphql';
import ItemsPrivate from './ItemsPrivate';
import ItemsFleet from './ItemsFleet';
import ItemsPAYG from './ItemsPAYG';
import { usePlans } from '../MyDashboard/usePlans';
import ItemsGov from './ItemsGov';

interface ActionItemsProps {
  accountDetails: IAccountDetails | undefined;
  paymentMethodsData: { paymentMethodList: IPaymentMethodGraphQl[] } | undefined;
  transponderList: ITransponderList | undefined;
  vehicles: IVehicle[] | undefined;
  setVisible: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

const ActionItems = ({
  accountDetails,
  paymentMethodsData,
  transponderList,
  vehicles,
  setVisible,
}: ActionItemsProps) => {
  const accountType = accountDetails?.accountType;
  const transponderListCount = accountDetails?.transponderListCount;
  const parkingOption = accountDetails?.parkingOption;
  const minimumAmounts = useQuery(MINIMUM_AMOUNT)?.data?.minimumAmount;
  const paymentMethods = paymentMethodsData?.paymentMethodList;

  const { PAYGAccount, GOVAccount } = usePlans();
  const isPAYGAccount = accountType === AccountType.PRIVATE && PAYGAccount;
  const isGOVAccount = accountType === AccountType.COMMERCIAL && GOVAccount;

  const minimumDepositAmount =
    accountType !== AccountType.COMMERCIAL
      ? minimumAmounts?.minimumDepositPersonal || 0
      : minimumAmounts?.minimumDepositFleet || 0;

  const hasUserAddedFunds = !!(accountDetails && accountDetails?.currentBalance >= minimumDepositAmount);

  const isOptInEasyPay = !!paymentMethodsData
    ? !!paymentMethodsData?.paymentMethodList?.find((method) => method.isEasyPay)
    : false;

  const activatedTransponders = useMemo(() => {
    return transponderList ? transponderList?.transponders.filter((value) => value.status === 'ACTIVE') : [];
  }, [transponderList]);

  const isActionRequiredForCommercialAccount = useMemo(() => {
    return transponderListCount === 0 || transponderListCount === null || !paymentMethods || !vehicles;
  }, [activatedTransponders, transponderListCount, paymentMethods, vehicles]);

  const isActionRequiredForGovernmentAccount = useMemo(() => {
    return activatedTransponders.length === 0 || !paymentMethods;
  }, [activatedTransponders, paymentMethods]);

  const isActionRequiredForPersonalAccount = useMemo(() => {
    return !isOptInEasyPay || activatedTransponders.length === 0 || !paymentMethods || parkingOption === 'N';
  }, [isOptInEasyPay, activatedTransponders, paymentMethods, parkingOption]);

  const isActionRequiredForPAYGAccount = useMemo(() => {
    return !hasUserAddedFunds || !vehicles;
  }, [hasUserAddedFunds, vehicles]);

  useEffect(() => {
    switch (accountType) {
      case AccountType.PRIVATE:
        isPAYGAccount ? setVisible(isActionRequiredForPAYGAccount) : setVisible(isActionRequiredForPersonalAccount);
        break;
      case AccountType.COMMERCIAL:
        isGOVAccount
          ? setVisible(isActionRequiredForGovernmentAccount)
          : setVisible(isActionRequiredForCommercialAccount);
        break;
    }
  }, [
    isOptInEasyPay,
    transponderListCount,
    paymentMethods,
    parkingOption,
    activatedTransponders,
    vehicles,
    hasUserAddedFunds,
  ]);

  switch (accountType) {
    case AccountType.PRIVATE:
      return isPAYGAccount ? (
        <ItemsPAYG
          vehicles={vehicles}
          hasUserAddedFunds={hasUserAddedFunds}
          paymentMethods={paymentMethods}
          isOptInEasyPay={isOptInEasyPay}
        />
      ) : (
        <ItemsPrivate
          paymentMethods={paymentMethods}
          parkingOption={parkingOption}
          activatedTransponders={activatedTransponders}
          isOptInEasyPay={isOptInEasyPay}
        />
      );
    case AccountType.COMMERCIAL:
      return isGOVAccount ? (
        <ItemsGov paymentMethods={paymentMethods} activatedTransponders={activatedTransponders} />
      ) : (
        <ItemsFleet
          vehicles={vehicles}
          paymentMethods={paymentMethods}
          transponderListCount={transponderListCount}
          activatedTransponders={activatedTransponders}
        />
      );
    default:
      return null;
  }
};

export default ActionItems;

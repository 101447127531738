import { message as toast } from 'antd';
import { useErrorStore } from '../../store/errors/error.store';
import { CustomGraphQLErrorExtensions, IUseErrorHandling } from './errorHandler.types';
import { getErrorState } from './errorHandler.utils';
import { i18n } from '@lingui/core';
import { useEffect } from 'react';
import {
  INCORRECT_TOTAL_AMOUNT,
  INCORRECT_USERNAME,
  INVALID_PRE_SET_AMOUNT,
  TRANSPONDER_ACTIVATION_SIGN_UP_ERROR,
  VEHICLES_MISSING_ERROR,
} from 'src/services/errorHandling/error.codes';
import { useOnboardingErrors } from 'src/store/errors/onboardingErrors.store';

const useErrorHandling = (): IUseErrorHandling => {
  const { setError, resetError, error, isError } = useErrorStore();
  const { setOnboardingError, setCanProceedToNextStep } = useOnboardingErrors();
  const getErrorMessage = (extensions: CustomGraphQLErrorExtensions): string => {
    if (!extensions.code) return extensions.rawMsg;
    return i18n.locale === 'en' ? extensions.englishMsg : extensions.spanishMsg;
  };
  //TODO fix errors on logging out with no authorization context

  const catchError = async (extensions: CustomGraphQLErrorExtensions): Promise<void> => {
    if (extensions.code === TRANSPONDER_ACTIVATION_SIGN_UP_ERROR) {
      if (
        extensions?.errorByType?.vehicle?.errorCode === VEHICLES_MISSING_ERROR ||
        extensions?.errorByType?.rebillThresholdAmount?.errorCode === INVALID_PRE_SET_AMOUNT ||
        extensions?.errorByType?.transactionTotalAmount?.errorCode === INCORRECT_TOTAL_AMOUNT
      ) {
        setCanProceedToNextStep(true);
      } else {
        setOnboardingError(extensions);
        setCanProceedToNextStep(false);
      }
      return;
    }
    const message = getErrorMessage(extensions);
    const errorState = getErrorState(extensions, message);
    const payInvoiceErrorCode = 1014;
    const payWithoutInvoiceErrorCode = 1279;
    setError(errorState);
    //TODO: will be removed in FLOCR-776
    if (extensions.code !== INCORRECT_USERNAME) {
      errorState.type !== 'authError' &&
        extensions.code !== payInvoiceErrorCode &&
        extensions.code !== payWithoutInvoiceErrorCode &&
        (await toast.error({
          content: message,
          type: 'error',
          duration: 6,
        }));
    } else {
      await toast.error({
        content: 'Error occurred',
        type: 'error',
        duration: 6,
      });
    }
  };

  useEffect(() => {
    return () => {
      resetError();
    };
  }, []);

  return {
    resetError,
    isError,
    catchError,
    error,
  };
};

export default useErrorHandling;

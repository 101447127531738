import React from 'react';
import { TransponderPlateSearchInput } from './TransponderPlateSearchInput';
import { DateInput } from './DateInput';
import { Trans } from '@lingui/macro';
import moment from 'moment/moment';
import styles from '../../Activity.module.scss';
import { CustomButton } from 'src/components/CustomButton/CustomButton';

interface IActivityBaseSearch {
  resetFilters: () => void;
}

export const ActivityBaseSearch = ({ resetFilters }: IActivityBaseSearch) => {
  return (
    <div className="items-top mt-4 flex w-full flex-col md:flex-row lg:w-11/12">
      <TransponderPlateSearchInput />
      <DateInput name="startDate" title={<Trans>Start Date</Trans>} defaultValue={moment().subtract(1, 'month')} />
      <DateInput name="endDate" title={<Trans>End Date</Trans>} />
      <div
        className={`${styles['margin-top-md']} flex h-full w-full flex-col self-center sm:w-auto sm:flex-row sm:justify-center md:self-start`}
      >
        <CustomButton type="submit" className="btn-primary-sm mb-5 sm:mx-4 sm:mb-0 sm:w-auto" content="Search" />
        <CustomButton
          type="reset"
          onClick={resetFilters}
          className="theme btn-primary-sm mx-0 w-full sm:w-auto "
          content="Clear"
          isPrimary={false}
        />
      </div>
    </div>
  );
};

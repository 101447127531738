import React from 'react';
import { t, Trans } from '@lingui/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import './EasyPay.css';
import { AccountSubtype, AccountType } from '../../../../types/onboarding.types';
import { useQuery } from '@apollo/client';
import { REPLENISHMENT_AMOUNT } from '../../../../graphql/replenishment-amount.graphql';
import { SELECT_RECOMMENDED_SUNPASS_PLAN_NAME } from '../../../../store/onboarding/onboarding.selectors';
import { EASY_PAY_SECTION_ID } from '../../references';

interface IAboutEasyPayProps {
  accountType: AccountType;
  accountSubType: AccountSubtype;
}

export const AboutEasyPay = ({ accountType, accountSubType }: IAboutEasyPayProps) => {
  const planName = SELECT_RECOMMENDED_SUNPASS_PLAN_NAME(accountType, accountSubType);
  const replenishmentAmounts = useQuery(REPLENISHMENT_AMOUNT)?.data?.replenishmentAmount;
  const replenishmentAmount =
    accountType !== AccountType.COMMERCIAL ? replenishmentAmounts?.personal || 0 : replenishmentAmounts?.fleet || 0;
  return (
    <div className="about-easypay-container">
      <h2 className="mb-6 text-left text-4xl text-primary-blue1" id={EASY_PAY_SECTION_ID}>
        <Trans>About Easy Pay</Trans>
      </h2>
      <div className="flex">
        <div className="w-1/2 text-left">
          <div className="mb-3 font-bold">
            <Trans>
              Pick a date you would like funds added to your account, set an amount, and let us worry about the rest!
            </Trans>
          </div>
          <div>
            {t`When a`} {planName} {t`reaches a pre-set low balance threshold (minimum/default is`} $
            {`${replenishmentAmount.toFixed(2)}`}
            {t`, it is automatically funded with a pre-set amount via credit card, as determined at the time of
              enrollment.`}
          </div>
        </div>
        <div className="w-1/2 text-left">
          <h3 className="mb-6 ml-5.5 text-2xl font-bold">Benefits</h3>
          <ul className="ml-5.5 list-none text-xl">
            <li className="mb-2">
              <span className="text-base leading-7 text-primary-black">
                <FontAwesomeIcon icon={faCircleCheck} color="green" size="lg" className="mr-2" />{' '}
                <Trans>No stress, hassle, or unpaid fines</Trans>
              </span>
            </li>
            <li className="mb-2">
              <span className="text-base leading-7 text-primary-black">
                <FontAwesomeIcon icon={faCircleCheck} color="green" size="lg" className="mr-2" />{' '}
                <Trans>Automatically withdrawn from your account, on your schedule, at your convenience</Trans>
              </span>
            </li>
            <li className="mb-2">
              <span className="text-base leading-7 text-primary-black">
                <FontAwesomeIcon icon={faCircleCheck} color="green" size="lg" className="mr-2" />{' '}
                <Trans>Adjustable to your toll usage levels and frequency of travel</Trans>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

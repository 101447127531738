import React from 'react';
import ScrollToTop from '../../ScrollToTop/ScrollToTop';
import { t, Trans } from '@lingui/macro';
import { isUndefined } from 'lodash';
import miniTransponder from '../assets/sunpass-mini.png';
import proTransponder from '../assets/sunpass-pro.png';

interface TransponderComparisonProps {
  miniPrice?: number;
  proPrice?: number;
}

export const TranspondersComparison = (props: TransponderComparisonProps): React.ReactElement => {
  const { proPrice, miniPrice } = props;
  return (
    <div className="divide-primary-gray-3 onboarding-fleet__transponders card mt-6 flex w-full flex-wrap divide-y lg:mt-14 lg:divide-y-0">
      <ScrollToTop />
      <div className="order-1 w-full text-center md:mb-4 lg:flex-1">
        <h5>
          <Trans>Mini Transponder</Trans> ({!isUndefined(miniPrice) ? `$${miniPrice.toFixed(2)}` : '--'})
        </h5>
        <div className="mb-2 text-sm font-bold uppercase tracking-tight text-primary-blue1">
          1 Vehicle per Transponder
        </div>
        <div className="flex flex-col-reverse md:flex-col">
          <img src={miniTransponder} className="onboarding-transponders-mini" alt="Mini transponder" />
          <ul className="onboarding-transponders-list">
            <li>
              <span>{t`Must be permanently adhered to inside of windshield`}</span>
            </li>
            <li>
              <span>{t`Will NOT work if removed from windshield`}</span>
            </li>
            <li>
              <span>{t`Not for motorcycles`}</span>
            </li>
          </ul>
        </div>
      </div>
      <div className="mst-10 order-3 w-full pt-6 text-center lg:order-2 lg:mb-4 lg:ml-16 lg:mt-0  lg:flex-1 lg:border-l lg:pl-16 lg:pt-0">
        <h5>
          <Trans>Pro Transponder</Trans> ({!isUndefined(proPrice) ? `$${proPrice.toFixed(2)}` : '--'})
        </h5>
        <div className="mb-2 text-sm font-bold uppercase tracking-tight text-primary-blue1">
          Up to 5 Vehicles per Transponder
        </div>
        <div className="flex flex-col-reverse md:flex-col">
          <img src={proTransponder} className="onboarding-transponders-pro" alt="Pro transponder" />
          <ul className="onboarding-transponders-list">
            <li>
              <span>{t`Interoperable with the EZPass network of toll operators`}</span>
            </li>
            <li>
              <span>{t`Sticks to the inside of windshield with suction cups`}</span>
            </li>
            <li>
              <span>{t`Carry it with you when you travel`}</span>
            </li>
            <li>
              <span>{t`Suitable for motorcycles`}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

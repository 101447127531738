import { useLogin } from '../../auth/useLogin';
import { IAccessOnlineAccountForm } from '../../../pages/AccessOnlineAccount/AccessOnlineAccount';
import React, { useEffect, useMemo, useState } from 'react';
import { Trans } from '@lingui/macro';
import { Username } from '../../../components/PrivacyAndSecurity/Username';
import { Password } from '../../../components/PrivacyAndSecurity/Password';
import { ReTypePassword } from '../../../components/PrivacyAndSecurity/ReTypePassword';
import { Response } from '../../../components/ForgetUsernameOrPassword/Response';
import { AccessOnlineFormFirstStepForm } from '../../../pages/AccessOnlineAccount/details/AccessOnlineFormFirstStepForm';
import { useMutation } from '@apollo/client';
import {
  IRegisterAccountFinalStepInput,
  IRegisterAccountInitialStepInput,
  IRegisterAccountInitialStepOutput,
  IRegisterAccountMiddleStepInput,
  REGISTER_ACCOUNT_FINAL_STEP,
  REGISTER_ACCOUNT_INITIAL_STEP,
  REGISTER_ACCOUNT_MIDDLE_STEP,
} from '../../../graphql/registerAccount.graphql';
import useErrorHandling from '../../errors/useErrorHandling';

interface IUseAccessOnlineAccountProps {
  step: number;
  changeStep: React.Dispatch<React.SetStateAction<number>>;
}

interface IUseAccessOnlineAccountReturnType {
  step: number;
  loading: boolean;
  onSubmit: (formData: Omit<IAccessOnlineAccountForm, 'question'>) => void;
  form: JSX.Element;
  reset: () => void;
}

export const useAccessOnlineAccount = ({
  step,
  changeStep,
}: IUseAccessOnlineAccountProps): IUseAccessOnlineAccountReturnType => {
  const { login, loading } = useLogin();
  const { error, resetError } = useErrorHandling();
  const [registerAccountOnInitialStep, { loading: initialRegisterLoading, data: initialStepResponseData }] =
    useMutation<
      { registerAccountInitialStep: IRegisterAccountInitialStepOutput },
      { registerAccountInitialInput: IRegisterAccountInitialStepInput }
    >(REGISTER_ACCOUNT_INITIAL_STEP);

  const [registerAccountOnMiddleStep, { loading: middleRegisterLoading }] = useMutation<
    { registerAccountMiddleStep: boolean },
    { registerAccountMiddleInput: IRegisterAccountMiddleStepInput }
  >(REGISTER_ACCOUNT_MIDDLE_STEP);

  const [registerAccountOnFinalStep, { loading: finalRegisterLoading }] = useMutation<
    { registerAccountFinalStep: boolean },
    { registerAccountFinalInput: IRegisterAccountFinalStepInput }
  >(REGISTER_ACCOUNT_FINAL_STEP);
  const [initialStepInput, setInitialStepInput] = useState<IRegisterAccountInitialStepInput>();
  const [middleStepInput, setMiddleStepInput] = useState<IRegisterAccountMiddleStepInput>();

  const onSubmit = async ({
    username,
    password,
    accountNumber,
    postalCode,
    transponderNumber,
    answer,
  }: Omit<IAccessOnlineAccountForm, 'question'>) => {
    resetError();
    if (step === 1) {
      const registerAccountInitialInput = {
        postalCode,
        ...(accountNumber && transponderNumber && { accountNumber }),
        ...(accountNumber && transponderNumber
          ? {}
          : { ...(accountNumber && { accountNumber }), ...(transponderNumber && { transponderNumber }) }),
        language: 'ENU',
      };
      const initialStepResult = await registerAccountOnInitialStep({
        variables: {
          registerAccountInitialInput,
        },
      });
      setInitialStepInput(registerAccountInitialInput);
      initialStepResult?.data?.registerAccountInitialStep && changeStep(2);
    }
    if (step === 2 && initialStepInput) {
      const registerAccountMiddleInput = {
        ...initialStepInput,
        securityQuestion: initialStepResponseData?.registerAccountInitialStep.securityQuestion || '',
        securityAnswer: answer,
      };
      const middleStepResult = await registerAccountOnMiddleStep({
        variables: {
          registerAccountMiddleInput,
        },
      });
      setMiddleStepInput(registerAccountMiddleInput);
      middleStepResult && changeStep(3);
    }
    if (step === 3 && middleStepInput) {
      const registerAccountFinalInput = {
        ...initialStepInput,
        ...middleStepInput,
        username,
        newPassword: password,
        retypeNewPassword: password,
      };
      const finalStepResult = await registerAccountOnFinalStep({
        variables: {
          registerAccountFinalInput,
        },
      });
      finalStepResult && (await login(username, password));
    }
  };

  useEffect(() => {
    resetError();
  }, []);

  const errorMsgFromErrorCode = useMemo((): JSX.Element | string | undefined => {
    if (!error?.code) return <div></div>;
    switch (error?.code) {
      case 1304:
      case 1033:
        return error?.message;
      case 1187:
        return <Trans>We were unable to validate your account due to already existing username.</Trans>;
      default:
        return (
          <Trans>
            We were unable to validate your account. Please make sure you&apos;ve properly entered your postal code AND
            either your account number <b className="underline">or</b> your transponder number.
          </Trans>
        );
    }
  }, [error, error?.code, error?.message, step]);

  const form = useMemo((): JSX.Element => {
    if (step === 1) {
      return (
        <>
          <AccessOnlineFormFirstStepForm />

          <div className="my-4 text-primary-red">{errorMsgFromErrorCode}</div>
        </>
      );
    } else if (step === 2) {
      return (
        <>
          <AccessOnlineFormFirstStepForm disabled />
          <p className="mt-5">
            <Trans>Thank you, please answer the security question below to verify your identity.</Trans>
          </p>
          <p>
            <Trans>
              <b>Question:</b> <em>{initialStepResponseData?.registerAccountInitialStep.securityQuestion}</em>
            </Trans>
          </p>
          <Response />
          <div className="my-4 text-primary-red">{errorMsgFromErrorCode}</div>
        </>
      );
    } else {
      return (
        <>
          <Username className="w-full sm:w-6/12 sm:pr-1" />
          <Password />
          <ReTypePassword />
          <div className="my-4 text-primary-red">{errorMsgFromErrorCode}</div>
        </>
      );
    }
  }, [step, initialStepResponseData, error?.code, errorMsgFromErrorCode]);

  return {
    step,
    loading: loading || initialRegisterLoading || middleRegisterLoading || finalRegisterLoading,
    onSubmit,
    form,
    reset: resetError,
  };
};

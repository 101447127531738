import { Trans } from '@lingui/macro';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import HomeSearch from '../HomeSearch/HomeSearch';
import './Home.scss';
import { MainLoader } from '../Loader/MainLoader';
import { DRUPAL_FILE_DISPUTE, OnboardingStepPath } from '../../types/routes';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import { useLogin } from 'src/hooks/auth/useLogin';
import { forgotUsernameOrPasswordLink } from './forgotUsernameOrPasswordLink';

export default function Home(): React.ReactElement {
  const { loginForm, loading } = useLogin(forgotUsernameOrPasswordLink, true, '', false, true);
  const history = useNavigate();
  const createAccount = () => history(OnboardingStepPath.INITIAL);

  return (
    <div className="home">
      <MainLoader loading={loading} />
      <div className="home-content">
        <section className="login w-11/12 rounded-xl bg-primary-white px-4 py-5 text-left shadow-md sm:w-2/3 sm:px-8 sm:py-10">
          <div className="grid h-full grid-flow-row space-x-2 lg:grid-cols-3 lg:divide-x">
            <div className="pr-6 lg:col-span-2">
              <h1 className="mb-5 mt-3 px-2 text-primary-blue1">
                <Trans>Welcome to My SunPass</Trans>
              </h1>
              <h2 className="mb-2 px-2 text-3xl font-light text-primary-gray3 text-opacity-60 md:text-primary-black md:text-opacity-100">
                <Trans>New to SunPass?</Trans>
              </h2>
              <h3 className="mb-5 px-2 pt-5 text-xl font-normal leading-7 text-primary-gray3 md:text-primary-black">
                <Trans>
                  SunPass account holders get up to a 40% reduction in toll prices. If you’re new to SunPass and have a
                  transponder you need to activate, or if you would like to purchase a transponder, select the option
                  below to get started.
                </Trans>
              </h3>
              <div className="flex justify-center sm:justify-start">
                <CustomButton
                  content="Create an account"
                  type="submit"
                  isPrimary={false}
                  className="create-account-button"
                  onClick={createAccount}
                />
              </div>
            </div>
            <div className="sign-in-form">
              <h3 className="mb-4 text-xl font-extrabold">
                <Trans>Sign In</Trans>
              </h3>
              {loginForm}
            </div>
          </div>
        </section>
        <section className="mx-auto my-10 grid w-11/12 grid-flow-row justify-center text-center sm:w-2/3 lg:grid-flow-col lg:grid-cols-8 lg:text-left">
          <div className="mt-4 md:mt-0 lg:col-span-5">
            <div className="invoice-title text-center font-thin text-primary-gray1 sm:text-left lg:text-primary-black">
              <Trans>Pay a Toll Invoice or Traffic Citation</Trans>
            </div>
            <div className="invoice-message text-center text-primary-gray1 sm:text-left lg:pr-10">
              <span className="font-bold text-primary-black">
                <Trans>
                  Explore your options, select what works for you, and pay at your own convenience. No SunPass account
                  necessary.
                </Trans>
              </span>
              &nbsp;
              <Trans>
                If you received a Toll Enforcement Invoice or Uniform Traffic Citation or would like to pay without
                Invoice or Citation, complete these fields to look up your payment options.
              </Trans>
            </div>{' '}
            <div className="mt-5 text-left">
              <Trans>
                If you think you have been mistakenly charged, you may{' '}
                <a
                  href={DRUPAL_FILE_DISPUTE}
                  className="dashboard-links mt-4 md:w-auto"
                  aria-label="Need to dispute a charge?"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="large-tablet:mt-8">
                    <Trans>file a dispute</Trans>
                  </span>
                </a>
                .
              </Trans>
            </div>
          </div>
          <div className="lg:col-span-5">
            <HomeSearch />
          </div>
        </section>
      </div>
    </div>
  );
}

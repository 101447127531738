import { t } from '@lingui/macro';
import { Form, Input } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import { IForgotUsernameOrPasswordForm } from './ForgotUsernameOrPassword';
import { ISignUpRetrieveDataInput } from 'src/graphql/signUp/signUpRetriveData.graphql';

const PostalCode = ({ disabled, className }: { disabled?: boolean; className?: string }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IForgotUsernameOrPasswordForm | ISignUpRetrieveDataInput>();

  return (
    <Form.Item
      label={t`Postal Code`}
      className={
        className ? `theme forgot-username-or-password-input ${className}` : 'theme forgot-username-or-password-input'
      }
      help={errors.postalCode?.message as React.ReactNode}
      hasFeedback
      validateStatus={errors.postalCode?.message ? 'error' : ''}
      required
    >
      <Controller
        name={'postalCode'}
        control={control}
        render={({ field, fieldState }) => (
          <Input
            {...field}
            {...fieldState}
            id={'postalCode'}
            key={'postalCode'}
            disabled={disabled}
            maxLength={20}
            aria-label={t`postal code`}
          />
        )}
      />
    </Form.Item>
  );
};

export default PostalCode;

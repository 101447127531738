import { Collapse } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';
import './CollapsableContainer.scss';
import { Trans } from '@lingui/macro';

const { Panel } = Collapse;

interface Props {
  collapseIcon?: boolean;
  responsive?: boolean;
  title: string;
  children: ReactNode;
  activeKey?: string[];
  onChange?: (input: any) => any;
  noTopMargin?: boolean;
  unsavedChanges?: boolean;
  centerHeader?: boolean;
}

/**
 * @param
 * collapseIcon - whether or not to show the collapse icon
 * responsive
 * title - the title displayed at the top of the container
 * children - the child elements displayed in the container. These can be passed in by adding jsx elements inside the componenent like <CollapsibleContainer><div>this is the child content</div></CollapsibleContainer
 * activeKey - the active key for the panel that should be open. Can be used with onChange to programmaticlly open and close the container. Since the container has only one panel, to open it change the active key to ['1']
 * IMPORTANT: If you use the activeKey prop to set the collapsed state, you need to provide an onChange method to update whatever state you are using to control the collapse.
 */
export default function CollapsableContainer({
  collapseIcon = true,
  responsive = false,
  title,
  children,
  activeKey,
  onChange,
  noTopMargin,
  unsavedChanges,
  centerHeader,
}: Props): React.ReactElement {
  const [internalActiveKey, setInternalActiveKey] = useState(['1']);

  useEffect(() => {
    setInternalActiveKey(activeKey || ['1']);
  }, [activeKey]);

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={['1']}
      activeKey={internalActiveKey}
      onChange={(activePanels: any) => {
        if (onChange) {
          onChange(activePanels);
        } else {
          setInternalActiveKey(activePanels);
        }
      }}
      style={noTopMargin ? { marginTop: '0px' } : { marginTop: '40px' }}
      className={`collapsible-container ${!collapseIcon ? 'no-collapse-icon' : ''} ${
        responsive ? 'collapsible-container-responsive' : ''
      }`}
      expandIconPosition="end"
    >
      <Panel
        header={title}
        key={1}
        className={`collapsible-container-panel ${centerHeader ? 'center-container-header' : ''}`}
      >
        {unsavedChanges ? (
          <span className="block text-right text-primary-orange1">
            <Trans>UNSAVED CHANGES</Trans>
          </span>
        ) : null}
        <div>{children}</div>
      </Panel>
    </Collapse>
  );
}

import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DashboardParams, MainRoutes } from 'src/types/routes';
import InnerHeader from '../InnerHeader';
import VehicleList from './VehicleList';
import useGetVehicles from 'src/hooks/domain/dashboard/vehicle/useGetVehicles';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import EmptyStateMessage, { IListType } from 'src/components/EmptyStates/EmptyStateMessage';
import { useVehicleStore } from '../../../../../store/vehicles/vehicle.store';
import { usePaginationInfo } from '../../../../../store/pagination/pagination.store';

const Vehicles: FC = () => {
  const navigate = useNavigate();

  const goTo = () => {
    navigate(`${MainRoutes.DASHBOARD}/${DashboardParams.VEHICLES}`);
  };

  const { vehiclesData, refetch } = useGetVehicles(true);

  const {
    vehiclesPagination: { size, page },
  } = usePaginationInfo();

  const {
    store: { isVehicleListChanged },
  } = useVehicleStore();

  const vehiclesListLength = vehiclesData?.vehicleList.vehicles.length || 0;

  useEffect(() => {
    if (isVehicleListChanged) {
      refetch({
        vehicleListInput: {
          pagination: {
            pageSize: size,
            pageNumber: page,
          },
        },
      });
    }
  }, [isVehicleListChanged]);

  return (
    <div className="w-full md:w-1/2">
      <InnerHeader target={DashboardParams.VEHICLES} />
      {vehiclesListLength > 0 ? (
        <VehicleList />
      ) : (
        <div>
          <EmptyStateMessage listType={IListType.VEHICLES} />
        </div>
      )}
      <div className="flex w-full justify-center">
        <CustomButton className="my-10 tracking-wide" content="Add Vehicle" onClick={goTo} />
      </div>
    </div>
  );
};

export default Vehicles;

import { create } from '../createStore';
import { devtools } from 'zustand/middleware';

export interface IDashboardStore {
  store: IDashboardStoreInfo;
  setAddPaymentMethodMode: (addPaymentMethodMode: boolean) => void;
  setActivateTransponderMode: (activateTransponderMode: boolean) => void;
  setAddEasyPayMode: (addEasyPayMode: boolean) => void;
  setAddVehicleMode: (addVehicleMode: boolean) => void;
  setAddFundsMode: (addFundsMode: boolean) => void;
  setPurchaseTransponderMode: (purchaseTransponderMode: boolean) => void;
  setParkingMode: (parkingMode: boolean) => void;
  setShouldAddPaymentMethodFromMyDashboard: (shouldAddPaymentMethodFromMyDashboard: boolean) => void;
  setShouldAddPaymentMethodFromMyWallet: (shouldAddPaymentMethodFromMyWallet: boolean) => void;
  setShowAlert: (showAlert: boolean) => void;
}

export interface IDashboardStoreInfo {
  addPaymentMethodMode: boolean;
  activateTransponderMode: boolean;
  addEasyPayMode: boolean;
  addVehicleMode: boolean;
  addFundsMode: boolean;
  purchaseTransponderMode: boolean;
  parkingMode: boolean;
  shouldAddPaymentMethodFromMyDashboard: boolean;
  shouldAddPaymentMethodFromMyWallet: boolean;
  showAlert: boolean;
}

const DASHBOARD_STORE_INITIAL_STATE: IDashboardStoreInfo = {
  addPaymentMethodMode: false,
  activateTransponderMode: false,
  addEasyPayMode: false,
  addVehicleMode: false,
  addFundsMode: false,
  purchaseTransponderMode: false,
  parkingMode: false,
  shouldAddPaymentMethodFromMyDashboard: false,
  shouldAddPaymentMethodFromMyWallet: false,
  showAlert: true,
};

export const useDashboardStore = create<IDashboardStore, [['zustand/devtools', IDashboardStore]]>(
  devtools((set) => ({
    store: DASHBOARD_STORE_INITIAL_STATE,
    setAddPaymentMethodMode: (addPaymentMethodMode: boolean) => {
      set(({ store }) => ({
        store: {
          ...store,
          addPaymentMethodMode,
        },
      }));
    },
    setActivateTransponderMode: (activateTransponderMode: boolean) => {
      set(({ store }) => ({
        store: {
          ...store,
          activateTransponderMode,
        },
      }));
    },
    setAddEasyPayMode: (addEasyPayMode: boolean) => {
      set(({ store }) => ({
        store: {
          ...store,
          addEasyPayMode,
        },
      }));
    },
    setAddVehicleMode: (addVehicleMode: boolean) => {
      set(({ store }) => ({
        store: {
          ...store,
          addVehicleMode,
        },
      }));
    },
    setAddFundsMode: (addFundsMode: boolean) => {
      set(({ store }) => ({
        store: {
          ...store,
          addFundsMode,
        },
      }));
    },
    setPurchaseTransponderMode: (purchaseTransponderMode: boolean) => {
      set(({ store }) => ({
        store: {
          ...store,
          purchaseTransponderMode,
        },
      }));
    },
    setParkingMode: (parkingMode: boolean) => {
      set(({ store }) => ({
        store: {
          ...store,
          parkingMode,
        },
      }));
    },
    setShouldAddPaymentMethodFromMyDashboard: (shouldAddPaymentMethodFromMyDashboard: boolean) => {
      set(({ store }) => ({
        store: {
          ...store,
          shouldAddPaymentMethodFromMyDashboard: shouldAddPaymentMethodFromMyDashboard,
        },
      }));
    },
    setShouldAddPaymentMethodFromMyWallet: (shouldAddPaymentMethodFromMyWallet: boolean) => {
      set(({ store }) => ({
        store: {
          ...store,
          shouldAddPaymentMethodFromMyWallet: shouldAddPaymentMethodFromMyWallet,
        },
      }));
    },
    setShowAlert: (showAlert: boolean) => {
      set(({ store }) => ({
        store: {
          ...store,
          showAlert,
        },
      }));
    },
  })),
);

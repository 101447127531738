import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useContext, useState } from 'react';
import { GET_ACCOUNT_DETAILS, IAccountDetails } from 'src/graphql/accountDetails.graphql';
import { MINIMUM_AMOUNT } from 'src/graphql/minimumAmount.graphql';
import { IIncreaseBalanceInput, INCREASE_BALANCE } from 'src/graphql/payment.graphql';
import { IPaymentMethodGraphQl, PAYMENT_METHODS } from 'src/graphql/paymentMethods.graphql';
import { useSortPaymentMethods } from 'src/hooks/domain/payments/useSortPaymentMethods';
import { AccountType } from 'src/types/onboarding.types';
import { NotificationContext } from '../../../../context/NotificationContext';

const useAddFunds = () => {
  const [amount, setAmount] = useState(0);
  const [finalAmount, setFinalAmount] = useState(0);

  const { data: paymentMethodsData, loading: isPaymentMethodListLoading } = useQuery<{
    paymentMethodList: IPaymentMethodGraphQl[];
  }>(PAYMENT_METHODS, {
    errorPolicy: 'all',
  });

  const checkUserBalance = (amount: number, difference: number) => {
    const sum: number = currentBalance + Number(amount);
    setAmount(amount);
    if (sum < minimumDepositAmount) {
      setFinalAmount(difference);
    } else setFinalAmount(amount);
  };

  const { paymentMethodList } = paymentMethodsData || {};

  const getPaymentMethodById = useCallback(
    (id: string): IPaymentMethodGraphQl | undefined => {
      return paymentMethodList?.find((method) => method.paymentMethodId === id);
    },
    [paymentMethodList],
  );

  const minimumAmounts = useQuery(MINIMUM_AMOUNT)?.data?.minimumAmount;
  const { data: accountData, loading: accountDetailsLoading } = useQuery<{ accountDetails: IAccountDetails }>(
    GET_ACCOUNT_DETAILS,
    {
      nextFetchPolicy: 'cache-and-network',
    },
  );

  const notificationCtx = useContext(NotificationContext);
  const { loadNotifications } = notificationCtx || {};

  const [increaseBalance, { loading: addFundsLoading }] = useMutation<
    { increaseBalance: boolean },
    { increaseBalanceInput: IIncreaseBalanceInput }
  >(INCREASE_BALANCE, {
    onCompleted: async () => {
      loadNotifications && (await loadNotifications(true));
    },
  });

  const accountType = accountData?.accountDetails?.accountType;
  const currentBalance = accountData?.accountDetails?.currentBalance || 0;

  const { sortedPaymentMethods } = useSortPaymentMethods(true);

  const minimumDepositAmount =
    accountType !== AccountType.COMMERCIAL
      ? minimumAmounts?.minimumDepositPersonal || 0
      : minimumAmounts?.minimumDepositFleet || 0;

  return {
    amount,
    finalAmount,
    checkUserBalance,
    currentBalance,
    minimumDepositAmount,
    paymentMethodsData,
    getPaymentMethodById,
    sortedPaymentMethods,
    accountData,
    accountDetailsLoading,
    increaseBalance,
    isPaymentMethodListLoading,
    addFundsLoading,
  };
};

export default useAddFunds;

import { Form } from 'antd';
import { t, Trans } from '@lingui/macro';
import { Controller, useFormContext } from 'react-hook-form';
import StyledInput from '../../../StyledInput/StyledInput';
import React from 'react';
import { useAddressFields } from '../../../../hooks/domain/address/useAddressFields';
import { IAddressFormElemBaseProps } from './address.types';

export const SecondAddress = ({ isAddressUsedForPayment, disabled, className }: IAddressFormElemBaseProps) => {
  const {
    addressFieldsState: { addressLine2Field },
  } = useAddressFields(isAddressUsedForPayment);
  const { control } = useFormContext();
  return (
    <div className={className}>
      <Form.Item label={<Trans>Address Line 2</Trans>} className="theme" help={addressLine2Field.errorMsg}>
        <Controller
          name={addressLine2Field.name}
          control={control}
          render={({ field, fieldState }) => (
            <StyledInput
              disabled={disabled}
              field={field}
              fieldstate={fieldState}
              key={addressLine2Field.name}
              id={addressLine2Field.name}
              maxLength={40}
              aria-label={t`Address line 2`}
            />
          )}
        />
      </Form.Item>
    </div>
  );
};

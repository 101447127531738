import React, { useContext, useMemo, useState } from 'react';
import './PaymentMethods.scss';
import { useMutation } from '@apollo/client';
import {
  ADD_UPDATE_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD,
  IAddUpdatePaymentMethodInput,
  IPaymentMethodGraphQl,
  OPT_OUT_OF_EASY_PAY,
  PAYMENT_METHODS,
} from '../../../../graphql/paymentMethods.graphql';
import { MainLoader } from '../../../Loader/MainLoader';
import ConfirmationModal from '../../../Modals/ConfirmationModal/ConfirmationModal';
import { t, Trans } from '@lingui/macro';
import { sessionStorageService } from '../../../../services/sessionStorage';
import { useWalletStore } from '../../../../store/dashboard/wallet.store';
import { getPaymentMethodName } from '../../../../utils/formatter';
import { NotificationContext } from '../../../../context/NotificationContext';
import OptOutOfEasyPayModal from '../../../Modals/ConfirmationModal/OptOutOfEasyPayModal';
import { useSortPaymentMethods } from '../../../../hooks/domain/payments/useSortPaymentMethods';
import MakePaymenthMethodPrimaryModal from './MakePaymenthMethodPrimaryModal';
import { mapMakePrimary } from 'src/components/Payments/mappers';

export interface IPaymentMethodItem {
  paymentMethod: IPaymentMethodGraphQl;
  updatePaymentMethodOnClick: () => void;
}

export enum PAYMENT_METHOD_ACTIVITY {
  RECENTLY_ADDED = 'RECENTLY ADDED',
  CHANGES_SAVED = 'CHANGES SAVED',
}

export const PaymentMethodItem = ({ paymentMethod, updatePaymentMethodOnClick }: IPaymentMethodItem) => {
  const [isModalOpened, setModalOpened] = useState<boolean>(false);
  const [showOptOutOfEasyPayModal, setShowOptOutOfEasyPayModal] = useState(false);
  const [showMakePrimaryModal, setShowMakePrimaryModal] = useState(false);
  const {
    updatePaymentInfo,
    paymentsInfo: { isPaymentMethodFormActive, isEditMode },
  } = useWalletStore();

  const [updatePaymentMethod, { loading: isUpdatingLoading }] = useMutation<IAddUpdatePaymentMethodInput>(
    ADD_UPDATE_PAYMENT_METHOD,
    {
      onCompleted: async () => {
        loadNotifications && (await loadNotifications(true));
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: PAYMENT_METHODS,
        },
      ],
    },
  );

  const notificationCtx = useContext(NotificationContext);
  const { loadNotifications } = notificationCtx || {};
  const { storedCards, methodsWithEasyPay } = useSortPaymentMethods();

  const [deletePaymentMethod, { loading: isDeleting }] = useMutation<
    { deletePaymentMethod: boolean },
    { rowId: string }
  >(DELETE_PAYMENT_METHOD, {
    onCompleted: async () => {
      loadNotifications && (await loadNotifications(true));
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: PAYMENT_METHODS,
      },
    ],
  });

  const [optOutOfEasyPay, { loading: optOutLoading }] = useMutation<{ optOutOfEasyPay: boolean }>(OPT_OUT_OF_EASY_PAY, {
    onCompleted: async () => {
      loadNotifications && (await loadNotifications(true));
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: PAYMENT_METHODS,
      },
    ],
  });

  const deleteMethod = async () => {
    try {
      const result = await deletePaymentMethod({ variables: { rowId: paymentMethod.paymentMethodId } });
      if (result.data?.deletePaymentMethod) {
        setModalOpened(false);
        sessionStorageService.removePaymentMethod(paymentMethod.paymentMethodId);
      }
    } catch (err) {
      setModalOpened(false);
    }
  };

  const optOutOfEZ = async () => {
    if (paymentMethod.isEasyPay && methodsWithEasyPay && methodsWithEasyPay?.length > 1) {
      const otherMethodWithEasyPay = methodsWithEasyPay?.find(
        (method) => method.paymentMethodId !== paymentMethod.paymentMethodId,
      );
      try {
        const result = await deletePaymentMethod({
          variables: { rowId: otherMethodWithEasyPay ? otherMethodWithEasyPay.paymentMethodId : '0' },
        });
        if (result.data?.deletePaymentMethod) {
          setModalOpened(false);
          sessionStorageService.removePaymentMethod(paymentMethod.paymentMethodId);
          try {
            const result = await optOutOfEasyPay();
            if (result.data?.optOutOfEasyPay) {
              setModalOpened(false);
              sessionStorageService.removePaymentMethod(paymentMethod.paymentMethodId);
            }
          } catch (err) {
            setShowOptOutOfEasyPayModal(false);
          }
        }
      } catch (err) {
        setModalOpened(false);
      }
    } else if (paymentMethod.isEasyPay && methodsWithEasyPay && methodsWithEasyPay?.length === 1) {
      try {
        const result = await optOutOfEasyPay();
        if (result.data?.optOutOfEasyPay) {
          setModalOpened(false);
          sessionStorageService.removePaymentMethod(paymentMethod.paymentMethodId);
        }
      } catch (err) {
        setShowOptOutOfEasyPayModal(false);
      }
    }
  };

  const editPaymentMethod = () => {
    updatePaymentMethodOnClick();
    updatePaymentInfo({
      isEditMode: true,
      paymentMethodToEdit: paymentMethod,
    });
  };

  const makePaymentMethodPrimary = async () => {
    if (!paymentMethod.isEasyPay) {
      setShowMakePrimaryModal(true);
    } else {
      try {
        const paymentMethodToMakePrimary = mapMakePrimary(paymentMethod);
        await updatePaymentMethod({
          variables: {
            addUpdatePaymentMethodInput: { ...paymentMethodToMakePrimary, isPrimary: true },
          },
        });
      } catch (err) {
        console.log(err);
      } finally {
        setShowMakePrimaryModal(false);
      }
    }
    //TODO to be done when we get response from PWW regarding the conduent API checking
  };
  const confirmMakePrimaryWithEasyPay = async () => {
    const paymentMethodToMakePrimary = mapMakePrimary(paymentMethod);
    try {
      await updatePaymentMethod({
        variables: {
          addUpdatePaymentMethodInput: { ...paymentMethodToMakePrimary, isEasyPay: true, isPrimary: true },
        },
      });
    } catch (err) {
      console.log(err);
    } finally {
      setShowMakePrimaryModal(false);
    }
  };
  const canMakePrimary = (paymentMethod: IPaymentMethodGraphQl) => {
    return !(storedCards?.includes(paymentMethod) && methodsWithEasyPay?.length === 2);
  };

  const {
    paymentsInfo: { newPaymentMethodAdded },
  } = useWalletStore();

  const recentActivityLabel = useMemo(() => {
    const recentActivity = sessionStorageService.getPaymentMethodStatusById(paymentMethod.paymentMethodId);
    return recentActivity ? (
      <span className="absolute right-2 ml-0 mt-4 w-3/12 self-center text-right text-xs text-primary-orange1 sm:relative sm:m-auto sm:text-center sm:text-base md:w-8/12 md:text-left large-tablet:ml-10 2xl:w-11/12">
        {recentActivity}
      </span>
    ) : null;
  }, [paymentMethod.paymentMethodId, paymentMethod, newPaymentMethodAdded, isEditMode]);

  return (
    <li className="mb-3 flex w-full flex-col justify-between rounded-lg bg-white px-4 py-5 shadow-lg sm:flex-row sm:px-6 sm:py-3">
      <MainLoader loading={isDeleting || isUpdatingLoading || optOutLoading} />
      <div className="mr-4 flex w-7/12 flex-col items-start sm:mr-10 sm:w-4/12 md:mr-12 md:w-4/12 xl:w-4/12 2xl:w-3/12">
        <span className="text-left text-sm leading-8 xsm:text-base sm:leading-normal">
          {getPaymentMethodName(paymentMethod)}
        </span>
        {paymentMethod.isPrimary ? (
          <span className="text-left text-sm italic text-primary-gray5 xsm:text-base">
            <Trans>Primary Payment Method</Trans>
          </span>
        ) : canMakePrimary(paymentMethod) ? (
          <button className="make-primary text-left text-sm underline xsm:text-base" onClick={makePaymentMethodPrimary}>
            <Trans>Make Primary Payment Method</Trans>
          </button>
        ) : null}
      </div>
      {recentActivityLabel}
      <div className="mt-4 flex flex-row items-center justify-end sm:m-0 sm:w-auto">
        <button
          disabled={isPaymentMethodFormActive}
          className={`remove-btn ${
            paymentMethod.isEasyPay ? 'remove-btn-ez' : `w-1/2`
          } rounded-md px-5 pb-0.5 pt-1 text-center sm:w-auto`}
          onClick={() => {
            if (paymentMethod.isEasyPay && paymentMethod.isPrimary) {
              setShowOptOutOfEasyPayModal(true);
            } else {
              setModalOpened(true);
            }
          }}
        >
          <span>
            {paymentMethod.isEasyPay && paymentMethod.isPrimary ? (
              <Trans>Remove & Opt Out of Easy Pay</Trans>
            ) : (
              <Trans>Remove</Trans>
            )}
          </span>
        </button>
        <button
          disabled={isPaymentMethodFormActive}
          className="edit-btn ml-5 w-1/2 rounded-md px-5 pb-0.5 pt-1 text-center md:w-auto"
          onClick={editPaymentMethod}
        >
          <span>
            <Trans>Edit</Trans>
          </span>
        </button>
      </div>
      <ConfirmationModal
        showModal={isModalOpened}
        title={
          <>
            <h1 className="text-xl font-extrabold leading-6 xsm:leading-5">
              <Trans>Confirm Removal of Payment Method</Trans>
            </h1>
            <h2 className="mt-2 text-xl font-normal sm:mt-0">{getPaymentMethodName(paymentMethod)}</h2>
          </>
        }
        onOk={deleteMethod}
        onCancel={() => setModalOpened(false)}
        content={
          <>
            <p>{t`Please confirm that you would like to remove this payment method.`}</p>
            <p className="italic leading-normal sm:leading-loose">
              {t`Note: SunPass Plus Parking requires that you have a credit or debit card on file. If removing this
              payment method will leave you with a bank account as your only payment method on file, you will no longer
              be able to opt into SunPass Plus Parking.`}
            </p>
          </>
        }
        alignButtons="center"
        alignText="center"
      />
      <OptOutOfEasyPayModal
        showModal={showOptOutOfEasyPayModal}
        onOk={optOutOfEZ}
        onCancel={() => setShowOptOutOfEasyPayModal(false)}
      />
      <MakePaymenthMethodPrimaryModal
        showModal={showMakePrimaryModal}
        onCancel={() => setShowMakePrimaryModal(false)}
        onOk={confirmMakePrimaryWithEasyPay}
      />
    </li>
  );
};

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ReactNode } from 'react';
import { colors } from 'src/theme/theme';
import './SelectableCard.scss';
import classNames from 'classnames';
import { Tooltip } from 'antd';

export default function SelectableCard({
  title,
  content,
  selected,
  onClick,
  autoHeight,
  tabIndex,
  disabled,
  tooltip,
  height,
}: {
  title: string;
  content?: ReactNode;
  selected?: boolean;
  onClick?: (value: any) => void;
  autoHeight?: boolean;
  tabIndex?: number;
  disabled?: boolean;
  tooltip?: string;
  height?: number;
}): React.ReactElement {
  const onKeyPress = (e: any) => {
    if (e.keyCode === 13 && !!onClick) {
      onClick(e);
    }
  };

  return (
    <div
      className={`selectable-card rounded-md ${
        selected ? 'selected' : 'border border-borders-gray1 bg-primary-white'
      } ${!height && autoHeight ? 'auto-height' : 'fixed-height'}`}
      style={{ height: height, minHeight: height }}
      onClick={
        disabled
          ? () => {
              // Disabled
            }
          : onClick
      }
      onKeyDown={
        disabled
          ? () => {
              // Disabled
            }
          : onKeyPress
      }
      tabIndex={tabIndex ? tabIndex : 0}
    >
      <Tooltip title={tooltip} placement="bottom">
        {selected && (
          <FontAwesomeIcon
            className="absolute right-2 top-2"
            icon={faCheckCircle}
            color={colors.primary.green1}
            size="lg"
          ></FontAwesomeIcon>
        )}
        <div className={`${classNames({ 'text-primary-gray4': disabled })} title`}>{title}</div>
        {content && (
          <div className="content" style={{ marginTop: title ? 16 : 0 }}>
            {content}
          </div>
        )}
      </Tooltip>
    </div>
  );
}

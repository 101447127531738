import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Select } from 'antd';
import React from 'react';
import { colors } from 'src/theme/theme';
import { Languages } from 'src/types/language.types';
import './LanguagePicker.scss';

const { Option } = Select;

function LanguagePicker(): React.ReactElement {
  const onChange = async (locale: string) => {
    i18n.activate(locale);
  };

  return (
    <div className="language-picker">
      <div className="hidden pt-2 lg:inline">
        <FontAwesomeIcon className="-mr-2" icon={faGlobe} color={colors.primary.gray1}></FontAwesomeIcon>
        <Select
          id="select-language"
          aria-label={t`select language`}
          defaultValue="en"
          value={i18n.locale}
          onChange={onChange}
          bordered={false}
          size="large"
          className="text-primary-gray1"
        >
          <Option id={Languages.EN} value={Languages.EN}>
            ENGLISH
          </Option>
          <Option id={Languages.ES} value={Languages.ES}>
            ESPANOL
          </Option>
        </Select>
      </div>
      <div className="lg:hidden" tabIndex={0}>
        <Select
          id="select-language-mobile"
          defaultValue="en"
          onChange={onChange}
          bordered={false}
          size="large"
          className="text-primary-gray1"
          aria-label={t`select language`}
        >
          <Option value={Languages.EN}>EN</Option>
          <Option value={Languages.ES}>ES</Option>
        </Select>
      </div>
    </div>
  );
}

export default LanguagePicker;

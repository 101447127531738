import { gql } from '@apollo/client';

export const GET_PROMOTIONS = gql`
  query promotions($promotionsByPromoTypeInput: PromotionsByPromoTypeInput!) {
    promotions(promotionsByPromoTypeInput: $promotionsByPromoTypeInput) {
      body
      expiresOn
      id
      image {
        mobile
        desktop
      }
      type
    }
  }
`;

export enum PromoType {
  EASY_PAY = 'EASY_PAY',
  INTEROPERABILITY = 'INTEROPERABILITY',
  PARKING = 'PARKING',
  PINELLAS = 'PINELLAS',
  SUNPASS_SAVINGS = 'SUNPASS_SAVINGS',
  TOLL_RELIEF_PROGRAM = 'TOLL_RELIEF_PROGRAM',
}

import { IAccountDetails } from '../../../graphql/accountDetails.graphql';
import { INotificationsSettings } from './Notifications';
import { capitalizeInitials } from '../../../utils/string.utils';

export const NOTIFICATIONS_SETTINGS_DEFAULT_VALUES: INotificationsSettings = {
  language: '',
  smsOption: false,
  deliveryModeType: '',
};

export const generateNotificationsSettingsDefaultValues = (
  accountDetails?: IAccountDetails,
): INotificationsSettings => {
  return {
    language: accountDetails?.language,
    smsOption: !!accountDetails?.smsOption,
    deliveryModeType: capitalizeInitials(accountDetails?.stmtDeliveryMethod) || '',
  };
};

import { t } from '@lingui/macro';
import { Tabs } from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MyDashboard from './MyDashboard/MyDashboard';
import Activity from './Activity/Activity';
import DashboardHeader from './DashboardHeader';
import { Wallet } from './Wallet/Wallet';
import Banner from './ActionItemBanner/Banner';
import Vehicles from './VehicleView/Vehicles';
import { concatRoutes, DashboardParams, MainRoutes } from '../../types/routes';
import { Transponders } from './Transponders/Transponder';
import UnpaidTolls from './UnpaidTolls/UnpaidTolls';
import { useQuery } from '@apollo/client';
import { GET_ACCOUNT_DETAILS, IAccountDetails } from '../../graphql/accountDetails.graphql';
import { AccountType } from '../../types/onboarding.types';
import { usePlans } from './MyDashboard/usePlans';

export interface ParamTypes {
  activeTab: string;
}

export default function Dashboard(): React.ReactElement {
  const { TabPane } = Tabs;
  const history = useNavigate();
  const { activeTab } = useParams<Partial<ParamTypes>>();

  const { data: accountData } = useQuery<{ accountDetails: IAccountDetails }>(GET_ACCOUNT_DETAILS);
  const accountType = accountData?.accountDetails.accountType;

  const { PAYGAccount } = usePlans();
  const isPAYGAccount = accountType === AccountType.PRIVATE && PAYGAccount;

  return (
    <>
      <div className="dashboard bg-primary-white">
        <Banner />
        <DashboardHeader />
        <Tabs
          id="dashboard-navigation"
          size="large"
          destroyInactiveTabPane
          className="bg-primary-white"
          activeKey={activeTab || ''}
          onChange={(key) => {
            history({ pathname: concatRoutes(MainRoutes.DASHBOARD, '/', key) });
          }}
        >
          <TabPane className="tab-content" tab={t`My Dashboard`} key={DashboardParams.MY_DASHBOARD}>
            <MyDashboard accountDetails={accountData?.accountDetails} />
          </TabPane>

          <TabPane className="tab-content" tab={t`Wallet`} key={DashboardParams.WALLET}>
            <Wallet />
          </TabPane>

          {!isPAYGAccount ? (
            <TabPane className="tab-content" tab={t`Transponders`} key={DashboardParams.TRANSPONDERS}>
              <Transponders />
            </TabPane>
          ) : null}

          <TabPane className="tab-content" tab={t`Vehicles`} key={DashboardParams.VEHICLES}>
            <Vehicles />
          </TabPane>

          <TabPane className="tab-content" tab={t`Unpaid Tolls`} key={DashboardParams.UNPAID_TOLLS}>
            <UnpaidTolls />
          </TabPane>

          <TabPane className="tab-content" tab={t`Activity`} key={DashboardParams.ACTIVITY}>
            <Activity />
          </TabPane>
        </Tabs>
      </div>
    </>
  );
}

import React, { FC, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Trans, t } from '@lingui/macro';
import { Form, Select } from 'antd';
import creditCardType from 'credit-card-type';
import classNames from 'classnames';
import moment from 'moment/moment';
import MaskedObfuscatedInput from 'src/components/MaskedObfuscatedInput/MaskedObfuscatedInput';
import StyledInput from 'src/components/StyledInput/StyledInput';
import { useCardType } from 'src/hooks/domain/payments/useCardType';
import { useNonInitialRender } from 'src/hooks/effect/useNonInitialRender';
import { useOnboardingStore } from 'src/store/onboarding/onboarding.store';
import { MONTH_NUMBERS, getArrayOfYearsFrom } from 'src/utils/time.utils';
import { creditCardMaskBuilder, CCVMask } from 'src/validators/masks';
import PaymentMethodFormField from '../Payments.enums';
import { usePaymentStore } from 'src/store/payment/payment.store';

interface IPaymentMethodCardProps {
  showCVV: boolean;
}

const PaymentMethodCard: FC<IPaymentMethodCardProps> = ({ showCVV }) => {
  const { updateOnboardingStore } = useOnboardingStore();

  const { control, formState, watch, getValues, trigger } = useFormContext();
  const { errors } = formState;
  const CC_FIRST_NAME_ERROR = errors[PaymentMethodFormField.CC_FIRST_NAME];
  const CC_MIDDLE_NAME_ERROR = errors[PaymentMethodFormField.CC_MIDDLE_NAME];
  const CC_LAST_NAME_ERROR = errors[PaymentMethodFormField.CC_LAST_NAME];
  const CC_NUMBER_ERROR = errors[PaymentMethodFormField.CC_NUMBER];
  const CC_EXPIRATION_YEAR_ERROR = errors[PaymentMethodFormField.CC_EXPIRATION_YEAR];
  const CC_EXPIRATION_MONTH_ERROR = errors[PaymentMethodFormField.CC_EXPIRATION_MONTH];
  const CC_SECURITY_CODE_ERROR = errors[PaymentMethodFormField.CC_SECURITY_CODE];
  const creditCardNumberWatch = watch(PaymentMethodFormField.CC_NUMBER);

  const { cardType } = useCardType();
  const { updateCardType } = usePaymentStore();

  useNonInitialRender(() => {
    trigger(PaymentMethodFormField.CC_NUMBER);
  }, [creditCardNumberWatch, cardType]);

  useNonInitialRender(() => {
    trigger(PaymentMethodFormField.CC_EXPIRATION_MONTH);
    trigger(PaymentMethodFormField.CC_EXPIRATION_YEAR);
  }, [watch(PaymentMethodFormField.CC_EXPIRATION_YEAR)]);

  useEffect(() => {
    updateCardType(cardType);
  }, [cardType]);

  useEffect(() => {
    updateOnboardingStore({ cardType: cardType });
  }, [cardType]);

  const { Option } = Select;

  return (
    <React.Fragment>
      <div className="flex flex-wrap">
        <h1 className="mb-2 text-3xl text-primary-blue1">
          <Trans>Credit or Debit Card Information</Trans>
        </h1>

        <div className="flex-row lg:w-full">
          <div className="inline-block w-full sm:w-5/12 sm:pr-2">
            <Form.Item
              label={<Trans>First Name</Trans>}
              className="theme"
              hasFeedback
              help={CC_FIRST_NAME_ERROR?.message as unknown as string}
              required
            >
              <Controller
                name="ccFirstName"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledInput
                    key="ccFirstName"
                    field={field}
                    fieldstate={fieldState}
                    id="ccFirstName"
                    maxLength={40}
                    aria-label={t`First Name`}
                  />
                )}
              />
            </Form.Item>
          </div>

          <div className="inline-block w-full sm:w-2/12 sm:pr-2">
            <Form.Item
              label={<Trans>M.I.</Trans>}
              className="theme"
              help={CC_MIDDLE_NAME_ERROR?.message as unknown as string}
            >
              <Controller
                name={PaymentMethodFormField.CC_MIDDLE_NAME}
                control={control}
                render={({ field, fieldState }) => (
                  <StyledInput
                    key={PaymentMethodFormField.CC_MIDDLE_NAME}
                    field={field}
                    fieldstate={fieldState}
                    id={PaymentMethodFormField.CC_MIDDLE_NAME}
                    maxLength={1}
                    aria-label={t`Middle Name`}
                  />
                )}
              />
            </Form.Item>
          </div>

          <div className="inline-block w-full sm:w-5/12">
            <Form.Item
              label={<Trans>Last Name</Trans>}
              className="theme"
              help={CC_LAST_NAME_ERROR?.message as unknown as string}
              required
            >
              <Controller
                name={PaymentMethodFormField.CC_LAST_NAME}
                control={control}
                render={({ field, fieldState }) => (
                  <StyledInput
                    key={PaymentMethodFormField.CC_LAST_NAME}
                    field={field}
                    fieldstate={fieldState}
                    id={PaymentMethodFormField.CC_LAST_NAME}
                    maxLength={40}
                    aria-label={t`Last Name`}
                  />
                )}
              />
            </Form.Item>
          </div>
        </div>

        <div className="w-full sm:w-6/12 sm:pr-1">
          <Form.Item
            label={<Trans>Card Number</Trans>}
            className="theme"
            validateStatus={errors.ccNumber ? 'error' : ''}
            help={CC_NUMBER_ERROR?.message as unknown as string}
            required
          >
            <Controller
              name={PaymentMethodFormField.CC_NUMBER}
              control={control}
              render={({ field, fieldState }) => (
                <MaskedObfuscatedInput
                  key={PaymentMethodFormField.CC_NUMBER}
                  field={field}
                  fieldstate={fieldState}
                  value={field.value}
                  id={PaymentMethodFormField.CC_NUMBER}
                  mask={creditCardMaskBuilder(creditCardType(getValues(PaymentMethodFormField.CC_NUMBER))[0])}
                  minLength={13}
                  maxLength={19}
                  label={t`Card number`}
                />
              )}
            />
          </Form.Item>
        </div>

        {showCVV ? (
          <div className="w-full sm:w-6/12 sm:pl-1">
            <Form.Item
              label={<Trans>Security Code</Trans>}
              className="theme"
              validateStatus={errors.ccSecurityCode ? 'error' : ''}
              help={CC_SECURITY_CODE_ERROR?.message as unknown as string}
              required
            >
              <Controller
                name={PaymentMethodFormField.CC_SECURITY_CODE}
                control={control}
                render={({ field, fieldState }) => (
                  <div>
                    <MaskedObfuscatedInput
                      key={PaymentMethodFormField.CC_SECURITY_CODE}
                      field={field}
                      fieldstate={fieldState}
                      maxLength={4}
                      minLength={3}
                      id={PaymentMethodFormField.CC_SECURITY_CODE}
                      mask={CCVMask}
                      value={field.value ? field.value.replace(/_|\s/g, '') : field.value}
                      label={t`Security code`}
                    />
                  </div>
                )}
              />
            </Form.Item>
          </div>
        ) : null}
        <div className="w-full sm:w-6/12 sm:pr-1">
          <Form.Item
            label={<Trans>Expiration Month</Trans>}
            className="theme"
            hasFeedback
            validateStatus={errors.ccExpirationMonth ? 'error' : ''}
            help={CC_EXPIRATION_MONTH_ERROR?.message as unknown as string}
            required
          >
            <Controller
              name={PaymentMethodFormField.CC_EXPIRATION_MONTH}
              control={control}
              render={({ field, fieldState }) => (
                <Select
                  {...field}
                  defaultValue={field.value}
                  key={PaymentMethodFormField.CC_EXPIRATION_MONTH}
                  id={PaymentMethodFormField.CC_EXPIRATION_MONTH}
                  className={classNames({ 'input-error': fieldState.error })}
                  optionFilterProp="children"
                  aria-label={t`Expiration Month`}
                  showSearch
                >
                  {MONTH_NUMBERS.map((numOfMonth) => (
                    <Option key={numOfMonth} value={numOfMonth}>
                      {numOfMonth}
                    </Option>
                  ))}
                </Select>
              )}
            />
          </Form.Item>
        </div>
        <div className="w-full sm:w-6/12 sm:pl-1">
          <Form.Item
            label={<Trans>Expiration Year</Trans>}
            className="theme"
            hasFeedback
            validateStatus={errors.ccExpirationYear ? 'error' : ''}
            help={CC_EXPIRATION_YEAR_ERROR?.message as unknown as string}
            required
          >
            <Controller
              name={PaymentMethodFormField.CC_EXPIRATION_YEAR}
              control={control}
              render={({ field, fieldState }) => (
                <Select
                  {...field}
                  defaultValue={field.value}
                  key={PaymentMethodFormField.CC_EXPIRATION_YEAR}
                  id={PaymentMethodFormField.CC_EXPIRATION_YEAR}
                  optionFilterProp="children"
                  aria-label={t`Expiration year`}
                  className={classNames({ 'input-error': fieldState.error })}
                >
                  {getArrayOfYearsFrom(moment().toDate().getFullYear()).map((year: any) => (
                    <Option key={year} value={year}>
                      {year}
                    </Option>
                  ))}
                </Select>
              )}
            />
          </Form.Item>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PaymentMethodCard;

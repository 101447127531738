import { OnboardingFlowType, OnboardingPinellasOptions, OnboardingUserType } from '../types/onboarding.types';

type OnboardingStepsUtilsType = {
  isOnboardingInitialStep: (value: any) => boolean;
  isOnboardingPurchaseInitialStep: (value: any) => boolean;
  isOnboardingPrepaidInitialStep: (value: any) => boolean;
};
export const OnboardingStepsUtils: OnboardingStepsUtilsType = {
  isOnboardingInitialStep: (value: any): boolean => {
    return Object.values(OnboardingFlowType).includes(value);
  },

  isOnboardingPurchaseInitialStep: (value: any): boolean => {
    return Object.values(OnboardingUserType).includes(value);
  },

  isOnboardingPrepaidInitialStep: (value: any): boolean => {
    return Object.values(OnboardingPinellasOptions).includes(value);
  },
};

import { Space } from 'antd';
import React, { FC } from 'react';
import sunpass_logo from 'src/assets/SunPassLogo-Blue.svg';
import { IContactInfo } from 'src/types/onboarding.types';
import { IAccountDetails } from 'src/graphql/accountDetails.graphql';
import ContactInformation from './OneTimePurchaseElements/ContactInformation';
import DueToday from './OneTimePurchaseElements/DueToday';
import MailingAddress, { IMailingAddressData } from './OneTimePurchaseElements/MailingAddress';
import AccountType from './OneTimePurchaseElements/AccountType';
import { MainLoader } from 'src/components/Loader/MainLoader';
interface IPurchaseInformation {
  isLoading: boolean;
  onEditContactInformation?(): void;
  onEditMailingAddress?(): void;
  planName?: string;
  paymentContent: JSX.Element;
  accountInfo: Partial<IAccountDetails>;
  totalPrice: number;
}

const PurchaseInformation: FC<IPurchaseInformation> = ({
  isLoading,
  onEditContactInformation,
  onEditMailingAddress,
  planName,
  accountInfo,
  paymentContent,
  totalPrice,
}) => {
  return (
    <div className="flex flex-col md:flex-row">
      {' '}
      <MainLoader loading={isLoading} />
      <Space size={8} direction="vertical" className="mx-auto p-6 md:border-r xl:px-16">
        <img
          className="mb-10 h-16 w-full sm:mt-0 md:h-full"
          src={sunpass_logo}
          alt="sunpass logo"
          style={{ maxWidth: '10rem' }}
        />
        <div className="flex flex-col gap-4">
          <ContactInformation
            onEdit={onEditContactInformation}
            contactInfo={accountInfo as IContactInfo | Partial<IAccountDetails>}
          />
          <MailingAddress onEdit={onEditMailingAddress} address={accountInfo as IMailingAddressData} />
          <AccountType localizedPlanName={planName} />
        </div>
      </Space>
      <Space direction="vertical" size="large" className="w-full px-8 py-10 md:w-2/3">
        {!isLoading && (
          <>
            {paymentContent}
            <DueToday amount={totalPrice} />
          </>
        )}
      </Space>
    </div>
  );
};
export default PurchaseInformation;

import { useEffect } from 'react';

export interface IUseSubmitEffects {
  isSubmitExecuted: boolean;
  effect: () => void;
}

export const useSubmitEffects = ({ isSubmitExecuted, effect }: IUseSubmitEffects) => {
  useEffect(() => {
    isSubmitExecuted && effect();
  }, [isSubmitExecuted]);
};

import { gql } from '@apollo/client';

export const COUNTIES = gql`
  query counties {
    counties {
      id
      name
    }
  }
`;

import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext } from 'react';
import { NotificationContext } from '../../context/NotificationContext';
import { useNotifications } from '../../hooks/notifications/useNotifications';

export interface INotificationItemProps {
  id: string;
  type: string;
  values: string[];
  dismissed: boolean;
  closeDropdown: () => void;
}

const NotificationItem: FC<INotificationItemProps> = (props) => {
  const notificationCtx = useContext(NotificationContext);
  const { dismiss } = notificationCtx || {};
  const { notificationItem } = useNotifications(props);
  const { title, content, actionName, action } = notificationItem || {};
  const { id, closeDropdown, dismissed } = props;

  return (
    <>
      <div
        className={`dropdown-content-item flex justify-between border-b-2 border-gray-200 p-4 text-left ${
          !dismissed ? 'dropdown-content-item_new' : ''
        }`}
      >
        <div>
          <b>{title} </b>
          <span>{content} </span>
          <div>
            {' '}
            <button
              className="dropdown-content-item-action mb-0 cursor-pointer underline"
              onClick={() => {
                action && action();
                closeDropdown();
                dismiss && dismiss(id);
              }}
            >
              {actionName}
            </button>
          </div>
        </div>
        {!dismissed ? (
          <button className="self-start" onClick={() => dismiss && dismiss(id)}>
            <FontAwesomeIcon
              id="xmarkIcon"
              className="dropdown-content-item-dismiss cursor-pointer p-1"
              icon={faXmarkCircle}
            />
          </button>
        ) : null}
      </div>
    </>
  );
};
export default NotificationItem;

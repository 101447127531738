import React, { FC } from 'react';
import { Form, Radio } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import { Trans } from '@lingui/macro';
import { IVehicleFormItemProps } from '../../../Onboarding/Vehicles/OnboardingVehiclesForm/OnboardingVehicleForm';
import { buildErrorString, getInputName } from '../../../Onboarding/Vehicles/OnboardingVehiclesForm/vehicleForm.utils';

const VehicleIsRental: FC<IVehicleFormItemProps> = ({ field, index, name }) => {
  const {
    control,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const clearErrorsOnChange = () => {
    if (errors?.effectiveEndDate?.type !== ('min' as string | undefined)) {
      clearErrors(getInputName('effectiveEndDate', index));
    }
    if (errors?.effectiveEndTime?.type !== ('minDateTime' as string | undefined)) {
      clearErrors(getInputName('effectiveEndTime', index));
    }
  };

  return (
    <div className="mb-4 w-full">
      <Form.Item
        {...field}
        help={buildErrorString(name, errors, index)}
        label={<Trans id="Is this a temporary or rental vehicle?" />}
        className="theme"
        required
      >
        <div className="mt-0">
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <Radio.Group
                {...field}
                id={name}
                onChange={(value) => {
                  field.onChange(value);
                  clearErrorsOnChange();
                }}
              >
                <Radio value={true}>
                  <Trans id="Yes" />
                </Radio>
                <Radio value={false}>
                  <Trans id="No" />
                </Radio>
              </Radio.Group>
            )}
          />
        </div>
      </Form.Item>
    </div>
  );
};

export default VehicleIsRental;

import _ from 'lodash';

export const getChangedProperties = <T extends object>(old: T, updated: T): Partial<T> => {
  const result: Partial<T> = {};
  for (const key in updated) {
    if (old.hasOwnProperty(key) && updated.hasOwnProperty(key)) {
      if (typeof old[key] === 'object' && typeof updated[key] === 'object') {
        const nestedFields = getChangedProperties<T>(old[key] as any, updated[key] as any);
        if (Object.keys(nestedFields)?.length > 0) {
          result[key] = nestedFields as any;
        }
      } else if (old[key] !== updated[key]) {
        result[key] = updated[key];
      }
    }
  }
  return result;
};

export const isElementIncluded = (arr1: any[], arr2: any[]): boolean => {
  return arr1.some((element) => arr2.includes(element));
};

export const getObjectKeys = (obj: Record<string, any>): string[] => {
  return Object.keys(obj);
};

export const getObjectDiff = (obj1: any, obj2: any) => {
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (_.isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));

  return diff;
};

export const generateObjectOfDiffrences = (
  properties: string[],
  newValues: Record<string, any>,
): Record<string, unknown> => {
  return properties.reduce((obj: Record<string, any>, property) => {
    obj[property] = newValues[property];
    return obj;
  }, {});
};

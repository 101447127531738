import { useQuery } from '@apollo/client';
import { find, get } from 'lodash';
import { TRANSPONDER_PRICES, TransponderType } from 'src/graphql/transponders.graphql';
import { TransponderPrice } from '../../../../types/transponder';
import { useState } from 'react';

interface IUseTransponderPrices {
  miniPrice: number; // TODO: Should add a type for API retrieved data
  proPrice: number;
  PBCPrice: number;
  errorLoading: boolean;
  loading: boolean;
}

const getTransponderPriceByType = (prices: TransponderPrice[] | undefined, transponderType: TransponderType) => {
  return +get(
    find(prices, {
      type: getTransponderTypeKey(transponderType),
    }),
    'price',
    undefined,
  );
};

const getTransponderTypeKey = (transponderType: TransponderType) => {
  return Object.keys(TransponderType)[Object.values(TransponderType).indexOf(transponderType)];
};

const useTransponderPrices = (): IUseTransponderPrices => {
  const [isError, setIsError] = useState(false);
  const { data, loading } = useQuery<{ transponderPrices: TransponderPrice[] }>(TRANSPONDER_PRICES, {
    errorPolicy: 'all',
    onError: () => setIsError(true),
  });
  const proPrice = getTransponderPriceByType(data?.transponderPrices, TransponderType.PRO);
  const miniPrice = getTransponderPriceByType(data?.transponderPrices, TransponderType.MINI);
  const PBCPrice = getTransponderPriceByType(data?.transponderPrices, TransponderType.PINELLAS);
  return {
    miniPrice,
    proPrice,
    errorLoading: isError,
    loading,
    PBCPrice,
  };
};

export default useTransponderPrices;

import { useQuery } from '@apollo/client';
import React, { FC } from 'react';
import { MainLoader } from 'src/components/Loader/MainLoader';
import {
  GET_TRANSPONDER_LIST,
  ITransponder,
  ITransponderInput,
  ITransponderList,
} from 'src/graphql/transponders.graphql';
import Pagination from 'src/components/Pagination/Pagination';
import { capitalizeInitials } from 'src/utils/string.utils';
import { Trans, t } from '@lingui/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { usePaginationInfo } from 'src/store/pagination/pagination.store';
import '../MyAccount.scss';

interface ITranspondersWithPlansList {
  setTransponderToAddPlan: (transponder: ITransponder | null) => void;
  transponderToAddPlan: ITransponder | null;
  shouldDisplayAllTransponders: boolean;
}

const TranspondersWithPlansList: FC<ITranspondersWithPlansList> = ({
  setTransponderToAddPlan,
  transponderToAddPlan,
  shouldDisplayAllTransponders,
}) => {
  const {
    transpondersPagination: { size: transpondersSize, page: transpondersPage },
  } = usePaginationInfo();

  const { data: transpondersListData, loading: transponderListLoading } = useQuery<
    { transponderList: ITransponderList },
    { transponderListInput: ITransponderInput }
  >(GET_TRANSPONDER_LIST, {
    notifyOnNetworkStatusChange: true,
    variables: {
      transponderListInput: {
        pagination: {
          pageSize: transpondersSize,
          pageNumber: transpondersPage,
        },
        filter: { sortColumn: 'planDateAdded' },
        filterHasPlan: !shouldDisplayAllTransponders,
        filterHasStatusActive: shouldDisplayAllTransponders,
        filterHasTypeMiniOrPro: shouldDisplayAllTransponders,
      },
    },
  });

  const totalPages = transpondersListData?.transponderList?.totalPages;
  const totalCount = transpondersListData?.transponderList.totalElements || 0;
  const isTransponderSelectedToAddPlan = (transponder: ITransponder) => transponderToAddPlan === transponder;

  const noActivatedTranspondersMessage = t`You don't have any activated transponders.`;

  return (
    <>
      <MainLoader loading={transponderListLoading} />
      <ul className="tranponders-list-container">
        {transpondersListData && transpondersListData?.transponderList.transponders.length > 0 ? (
          transpondersListData.transponderList.transponders?.map((transponder: ITransponder) => {
            const { transponderType, transponderNumber, friendlyName, plan } = transponder;
            return (
              <li
                onClick={() => {
                  plan === null
                    ? setTransponderToAddPlan(transponderToAddPlan === transponder ? null : transponder)
                    : null;
                }}
                key={transponderNumber}
                className={`transponder-item-shadow-background relative mb-3 flex h-20 cursor-pointer flex-col justify-center rounded-lg px-6 py-4 text-sm sm:flex-row sm:justify-between sm:text-base ${
                  isTransponderSelectedToAddPlan(transponder)
                    ? 'transponder-item-background border-2 border-green-600'
                    : ''
                }`}
              >
                {isTransponderSelectedToAddPlan(transponder) ? (
                  <div className=" absolute right-1 top-1 h-4 w-4 rounded-full bg-green-100">
                    <FontAwesomeIcon icon={faCircleCheck} color="green" size="1x" className="absolute" />
                  </div>
                ) : null}
                <div className="flex w-full items-center justify-between">
                  <div className="flex flex-col">
                    <div className="font-bold md:font-normal">
                      <span>{capitalizeInitials(transponderType)}</span> | <span>{transponderNumber}</span>{' '}
                    </div>
                    <div className="italic text-primary-gray5">
                      {friendlyName ? friendlyName : 'Unlabeled Transponder'}
                    </div>
                  </div>
                  <div>
                    {plan ? (
                      <span className="italic">
                        {' '}
                        <Trans>(Enrolled through September 30, 2023)</Trans>
                      </span>
                    ) : (
                      <span
                        className={`tranponder-item-button rounded px-2 py-1 text-xs text-white sm:text-sm ${
                          isTransponderSelectedToAddPlan(transponder) ? 'italic' : ''
                        }`}
                      >
                        <Trans>{isTransponderSelectedToAddPlan(transponder) ? 'Remove' : 'Add'}</Trans>
                      </span>
                    )}
                  </div>
                </div>
              </li>
            );
          })
        ) : (
          <div>
            {transponderListLoading ? (
              <div></div>
            ) : (
              <div className="my-4 font-bold">{noActivatedTranspondersMessage}</div>
            )}
          </div>
        )}
      </ul>
      {totalPages && totalPages >= 2 ? <Pagination count={totalCount} type={'transponders'} /> : null}
    </>
  );
};

export default TranspondersWithPlansList;

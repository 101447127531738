import {
  AccountSubtype,
  FLEET_PLAN,
  OnboardingPinellasOptions,
  OnboardingUserType,
  PERSONAL_PLAN,
  PERSONAL_PLUS_PLAN,
} from '../../../types/onboarding.types';
import { useRouteMonitor } from '../../../hooks/location/useRouteMonitor';
import { useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import { IRecommendationCard } from './useAccountRecommendation';
import { useOnboardingStore } from '../../../store/onboarding/onboarding.store';
import {
  SELECT_RECOMMENDED_SUNPASS_PLAN_NAME,
  SELECT_SUNPASS_PLAN_DESCRIPTION,
} from '../../../store/onboarding/onboarding.selectors';
import { OnboardingStepPath } from '../../../types/routes';

export interface IUsePinellasRecommendation {
  recommendedPlan: string;
  planDescription: string;
  onSubmit: () => void;
  cards: IRecommendationCard[];
  backHandler: () => void;
  accountSubtype: AccountSubtype | undefined;
}

export const usePinellasRecommendation = (): IUsePinellasRecommendation => {
  const {
    onboardingInfo: { accountType, accountSubtype, userType, pinellasUse, chosenSubtype },
    updateOnboardingStore,
  } = useOnboardingStore();
  const planDescription = useOnboardingStore(SELECT_SUNPASS_PLAN_DESCRIPTION);

  const recommendedPlan = SELECT_RECOMMENDED_SUNPASS_PLAN_NAME(accountType, AccountSubtype.PBC);
  const history = useRouteMonitor();

  const [selectedAccountSubtype, setSelectedAccountSubtype] = useState(
    chosenSubtype !== accountSubtype ? chosenSubtype : accountSubtype,
  );

  const onSelectAccountSubtype = (type: AccountSubtype) => {
    setSelectedAccountSubtype(type);
  };

  useEffect(() => {
    chosenSubtype !== accountSubtype
      ? setSelectedAccountSubtype(chosenSubtype)
      : setSelectedAccountSubtype(accountSubtype);
  }, [accountSubtype]);

  function onSubmit(): void {
    if (selectedAccountSubtype) {
      updateOnboardingStore({ accountSubtype: selectedAccountSubtype, chosenSubtype: selectedAccountSubtype });
      history.goToPath(OnboardingStepPath.ACCOUNT);
    }
  }

  let cards: IRecommendationCard[] = [];

  switch (userType) {
    case OnboardingUserType.VISITOR:
      cards = [
        {
          title:
            pinellasUse === OnboardingPinellasOptions.FREQUENT
              ? t`Sounds great! I would like to enroll in the SunPass Personal Account with the Pinellas Bayway plan added on`
              : t`I would like to enroll in the Pinellas Bayway Plan`,
          content: t`I frequently, travel on the Pinellas Bayway in addition to my regular driving throughout the state.`,
          onClick: () => onSelectAccountSubtype(AccountSubtype.PBC),
          selected: selectedAccountSubtype === AccountSubtype.PBC,
        },
        {
          title: t`No thanks, I want a SunPass Personal Account without the Pinellas Bayway Plan`,
          content: t`I rarely travel on the Pinellas Bayway Plan, and would prefer to have a SunPass Personal Account without the Pinellas Bayway Plan.`,
          onClick: () => onSelectAccountSubtype(AccountSubtype.NONE),
          selected: selectedAccountSubtype === AccountSubtype.NONE,
        },
      ];

      break;
    case OnboardingUserType.COMMERCIAL:
      if ([FLEET_PLAN, PERSONAL_PLAN].includes(recommendedPlan)) {
        cards = [];
      } else if (PERSONAL_PLUS_PLAN === recommendedPlan) {
        cards = [
          {
            title:
              accountSubtype === AccountSubtype.PBC
                ? t`Sounds great! I would like to enroll in the Pinellas Bayway Plan`
                : t`I would like to enroll in the Pinellas Bayway Plan`,
            content: t`I travel on Pinellas Bayway regularly but rarely, if ever, travel throughout the rest of the state.`,
            onClick: () => onSelectAccountSubtype(AccountSubtype.PBC),
            selected: selectedAccountSubtype === AccountSubtype.PBC,
          },
          {
            title:
              accountSubtype === AccountSubtype.PB
                ? t`Sounds great! I would like to enroll in the SunPass Personal Account with the Pinellas Bayway plan added on`
                : t`I would like to enroll in the SunPass Personal Account with the Pinellas Bayway plan added on`,
            content: t`I may, from time to time, or frequently, travel on the Pinellas Bayway in addition to my regular driving throughout the state.`,
            onClick: () => onSelectAccountSubtype(AccountSubtype.PB),
            selected: selectedAccountSubtype === AccountSubtype.PB,
          },
        ];
      }
      break;
    case OnboardingUserType.RESIDENT:
      cards = [
        {
          title:
            accountSubtype === AccountSubtype.PBC
              ? t`Sounds great! I would like to enroll in the SunPass Account for Pinellas Bayway Commuters.`
              : t`I would like to enroll in the Pinellas Bayway Plan`,
          content: t`This type of account requires a transponder and is for any two-axle vehicle. The cost of the plan is $50 per year, per transponder.`,
          onClick: () => onSelectAccountSubtype(AccountSubtype.PBC),
          selected: selectedAccountSubtype === AccountSubtype.PBC,
        },
        {
          title:
            accountSubtype === AccountSubtype.PB
              ? t`Sounds great! I would like to enroll in the SunPass Personal Account with the Pinellas Bayway plan added on`
              : t`No thanks, I'd prefer a SunPass Personal Account without the Pinellas Bayway Plan for now.`,
          content: t`This type of account does not include unlimited passage through the Pinellas Bayway toll plazas. If your travel needs change in the future however, you will have the ability to add the Pinellas Bayway Plan to your account settings.`,
          onClick: () => onSelectAccountSubtype(AccountSubtype.PB),
          selected: selectedAccountSubtype === AccountSubtype.PB,
        },
      ];
      break;
    default:
      cards = [];
  }

  const backHandler = () => {
    updateOnboardingStore({ accountSubtype: AccountSubtype.NONE });
  };

  return {
    cards,
    planDescription,
    backHandler,
    onSubmit,
    recommendedPlan,
    accountSubtype,
  };
};

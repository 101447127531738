import { t, Trans } from '@lingui/macro';
import { Form, Select } from 'antd';
import classNames from 'classnames';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ActionType } from './ForgotUsernameOrPassword';

const { Option } = Select;

interface IActionTypesSelect {
  toggleActionType(): void;
}

const ActionTypeSelect: FC<IActionTypesSelect> = ({ toggleActionType }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Form.Item
      label={t`I would like to...`}
      className="theme forgot-username-or-password-select w-full sm:w-96"
      hasFeedback
      validateStatus={errors.actionType ? 'error' : ''}
      help={errors.actionType?.message as unknown as string}
    >
      <Controller
        name={'actionType'}
        control={control}
        render={({ field, fieldState }) => (
          <Select
            {...field}
            key={'actionType'}
            id={'actionType'}
            className={classNames({ 'input-error': fieldState.invalid })}
            optionFilterProp="children"
            showSearch
            onChange={(value) => {
              field.onChange(value);
              toggleActionType();
            }}
            aria-label={t`action type`}
          >
            <Option value={ActionType.RESET_PASSWORD}>
              <Trans>Reset Password</Trans>
            </Option>
            <Option value={ActionType.RETRIEVE_USERNAME}>
              <Trans>Retrieve Username</Trans>
            </Option>
          </Select>
        )}
      />
    </Form.Item>
  );
};

export default ActionTypeSelect;

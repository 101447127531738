import { t } from '@lingui/macro';
import * as React from 'react';
import { useScroll } from 'src/hooks/screen/useScroll';
import { useMultiStepPaymentStore } from 'src/store/multiStepPayment/multiStepPayment.store';
import { TRANSPONDERS_LIST_HEADER } from '../../references';
import SuccessMessageContent from './SuccessMessageContent';

const PaymentSucces: React.FunctionComponent = (): React.ReactElement => {
  const { scrollToElem } = useScroll();
  const { resetStore } = useMultiStepPaymentStore();
  const accountSecondTitle = t`You have successfully made a payment!`;
  const accountMessage = t`You will receive a confirmation email shortly with the details of your purchase.`;
  const goToTransponder = () => {
    resetStore();
    scrollToElem(TRANSPONDERS_LIST_HEADER);
  };

  return (
    <div>
      <div>
        <SuccessMessageContent
          secondTitle={accountSecondTitle}
          message={accountMessage}
          goBack={goToTransponder}
          goBackDirection={t`Go to My Transponders`}
        />
      </div>
    </div>
  );
};

export default PaymentSucces;

import React, { FC } from 'react';
import { emptyFilteredStateMessages, emptyStateMessages } from './constantsMessages';
export enum IListType {
  ACTIVITY = 'activity',
  VEHICLES = 'vehicles',
  TRANSPONDERS = 'transponders',
}
interface IEmptyStateMessage {
  areFiltersApplied?: boolean;
  listType: IListType;
}
const EmptyStateMessage: FC<IEmptyStateMessage> = ({ areFiltersApplied = false, listType }) => {
  const generateRightMessage = (areFiltersApplied: boolean, listType: IListType) => {
    return areFiltersApplied ? emptyFilteredStateMessages[`${listType}`] : emptyStateMessages[`${listType}`];
  };

  return <div className="text-large font-bold">{generateRightMessage(areFiltersApplied, listType)}</div>;
};
export default EmptyStateMessage;

import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { PaymentMethodsHeader } from './PaymentMethodsHeader';
import { PAYMENT_METHOD_ACTIVITY, PaymentMethodItem } from './PaymentMethodItem';
import { t } from '@lingui/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from '@apollo/client';
import { IPaymentMethodGraphQl, PAYMENT_METHODS } from '../../../../graphql/paymentMethods.graphql';
import { MainLoader } from '../../../Loader/MainLoader';
import ConfirmationModal from '../../../Modals/ConfirmationModal/ConfirmationModal';
import { useSortPaymentMethods } from 'src/hooks/domain/payments/useSortPaymentMethods';
import { sessionStorageService } from '../../../../services/sessionStorage';
import { useWalletStore } from '../../../../store/dashboard/wallet.store';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import NewPaymentMethodWalletWrapper from 'src/components/Payments/NewPaymentMethodWrappers/NewPaymentMethodWalletWrapper';
import { useDashboardStore } from '../../../../store/dashboard/dashboard.store';
import { useScroll } from '../../../../hooks/screen/useScroll';
import { SELECT_PAYMENT_METHOD_SECTION_ID } from '../../references';
import { checkIfElementExists } from '../../checkIfElementExists';
import { PAYMENT_METHODS_AMOUNT_LIMIT } from 'src/constants';
import { isLimitOfPaymentMethodsReached } from 'src/components/Payments/ChosePaymentMethod/ChosePaymentMethods.utils';

export const PaymentMethods = () => {
  const location = useLocation();
  const {
    updatePaymentInfo,
    paymentsInfo: { isPaymentMethodFormActive, isMethodLimitReached, isEditMode, newPaymentMethodAdded },
  } = useWalletStore();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const paymentMethodListRef = useRef<HTMLDivElement>(null);

  const {
    data: paymentMethodsData,
    loading: isLoading,
    refetch,
  } = useQuery<{ paymentMethodList: IPaymentMethodGraphQl[] }>(PAYMENT_METHODS, {
    initialFetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
  });

  useEffect(() => {
    updatePaymentInfo({
      isPaymentMethodFormActive: false,
      isEditMode: false,
      isMethodLimitReached: false,
      paymentMethodToEdit: null,
    });
  }, [location.pathname]);

  const { sortedPaymentMethods } = useSortPaymentMethods(true);

  const updatePaymentMethodOnClick = () => {
    buttonRef.current?.scrollIntoView({ behavior: 'smooth' });
    updatePaymentInfo({ isPaymentMethodFormActive: true });
    if (paymentMethodsData && paymentMethodsData.paymentMethodList.length === PAYMENT_METHODS_AMOUNT_LIMIT) {
      updatePaymentInfo({ isMethodLimitReached: true });
    }
  };

  const isNumOfPaymentMethodsLess = (limit: number) => {
    return (
      paymentMethodsData?.paymentMethodList === null ||
      (paymentMethodsData && paymentMethodsData?.paymentMethodList?.length < limit)
    );
  };

  const activateAddPaymentMethod = () => {
    if (isNumOfPaymentMethodsLess(PAYMENT_METHODS_AMOUNT_LIMIT)) {
      updatePaymentInfo({ paymentMethodToEdit: null });
      updatePaymentMethodOnClick();
    } else {
      updatePaymentInfo({ isMethodLimitReached: true });
    }
  };

  useEffect(() => {
    if (paymentMethodsData?.paymentMethodList) {
      const prevPaymentMethods = sessionStorageService.getAllPaymentMethods();
      const isAdded = paymentMethodsData?.paymentMethodList?.length !== (prevPaymentMethods?.length || 0);
      const newPaymentMethods = paymentMethodsData?.paymentMethodList
        ?.map((payment) => payment.paymentMethodId)
        ?.filter((el) => !prevPaymentMethods.includes(el));
      if (newPaymentMethodAdded && isAdded) {
        sessionStorageService.setPaymentMethodStatus(newPaymentMethods[0], PAYMENT_METHOD_ACTIVITY.RECENTLY_ADDED);
        updatePaymentInfo({ newPaymentMethodAdded: false });
      } else {
        newPaymentMethods.forEach((e) => sessionStorageService.setPaymentMethodStatus(e));
      }
    }
  }, [paymentMethodsData, newPaymentMethodAdded]);

  const {
    setAddPaymentMethodMode,
    store: { addPaymentMethodMode },
  } = useDashboardStore();

  const { scrollToElem } = useScroll();

  const selectPaymentMethodSectionExists = checkIfElementExists(SELECT_PAYMENT_METHOD_SECTION_ID);

  useEffect(() => {
    if (selectPaymentMethodSectionExists && addPaymentMethodMode) {
      scrollToElem(SELECT_PAYMENT_METHOD_SECTION_ID, true);
      setAddPaymentMethodMode(false);
    } else if (addPaymentMethodMode) {
      updatePaymentInfo({
        isPaymentMethodFormActive: true,
      });
    }
  }, [addPaymentMethodMode, selectPaymentMethodSectionExists]);

  return (
    <div ref={paymentMethodListRef}>
      <MainLoader loading={isLoading} />
      <PaymentMethodsHeader />
      <ul>
        {sortedPaymentMethods &&
          sortedPaymentMethods.map((paymentMethod: IPaymentMethodGraphQl) => (
            <PaymentMethodItem
              key={paymentMethod.paymentMethodId}
              paymentMethod={paymentMethod}
              updatePaymentMethodOnClick={updatePaymentMethodOnClick}
            />
          ))}
      </ul>
      {isPaymentMethodFormActive ? (
        <NewPaymentMethodWalletWrapper refetch={() => refetch()} paymentMethodListRef={paymentMethodListRef} />
      ) : (
        !isLimitOfPaymentMethodsReached(sortedPaymentMethods || []) && (
          <div className="flex justify-center">
            <CustomButton
              icon={<FontAwesomeIcon icon={faPlus} className="mr-2 " />}
              className="mt-5 pt-0.5 font-medium"
              content="Add Payment Method"
              onClick={activateAddPaymentMethod}
              reference={buttonRef}
              id="addPaymentMethodButton"
            />
          </div>
        )
      )}
      <ConfirmationModal
        title={t`Add Payment Method Failure`}
        content={t`You already have a maximum number of 3 payment methods on your account. You must remove one of your existing payment methods before you can add a new one.`}
        showModal={isMethodLimitReached && !isEditMode}
        onOk={() => {
          updatePaymentInfo({
            isMethodLimitReached: false,
            isEditMode: false,
          });
        }}
        closeType
        alignButtons="center"
        alignText="center"
      />
    </div>
  );
};

import { gql } from '@apollo/client';
import { IUserNames } from './signUp/signUpDetails.graphql';

export const PAYMENT_METHODS = gql`
  query paymentMethods {
    paymentMethodList {
      addressLine1
      addressLine2
      bankAccountNumber
      bankRoutingNumber
      cardNumber
      city
      country
      expirationMonth
      expirationYear
      firstName
      isBankAccount
      isEasyPay
      isPrimary
      lastName
      middleName
      payType
      paymentMethodId
      state
      zipCode
    }
  }
`;

export const DELETE_PAYMENT_METHOD = gql`
  mutation deletePaymentMethod($rowId: String!) {
    deletePaymentMethod(rowId: $rowId)
  }
`;

export const ADD_UPDATE_PAYMENT_METHOD = gql`
  mutation addUpdatePaymentMethod($addUpdatePaymentMethodInput: AddUpdatePaymentMethodInput!) {
    addUpdatePaymentMethod(addUpdatePaymentMethodInput: $addUpdatePaymentMethodInput)
  }
`;

export const OPT_OUT_OF_EASY_PAY = gql`
  mutation optOutOfEasyPay {
    optOutOfEasyPay
  }
`;

export interface IPaymentMethodGraphQl extends IUserNames {
  addressLine1: string;
  addressLine2: string;
  bankAccountNumber: string;
  bankRoutingNumber: string;
  cardNumber: string;
  city: string;
  country: string;
  middleName: string;
  expirationMonth: number;
  expirationYear: number;
  isBankAccount: boolean;
  isEasyPay: boolean;
  isPrimary: boolean;
  payType: string;
  paymentMethodId: string;
  state: string;
  zipCode: string;
  securityCode: string;
}

export interface IAddUpdatePaymentMethodInput extends IUserNames {
  addressLine1: string;
  addressLine2: string;
  bankAccountNumber: string;
  bankRoutingNumber: string;
  cardNumber: string;
  city: string;
  country: string;
  expirationMonth: number;
  expirationYear: number;
  isEasyPay: boolean;
  isPrimary: boolean;
  middleName: string;
  payType: string;
  paymentMethodId: string;
  reBillThresholdAmount: number;
  state: string;
  zipCode: string;
}

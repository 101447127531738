import { gql } from '@apollo/client';

export const COUNTRIES = gql`
  query countries {
    countries {
      code
    }
  }
`;

export const COUNTRY_STATES_ISO3 = gql`
  query countryByIso3($iso3: String!) {
    countryByIso3(iso3: $iso3) {
      code
      states {
        code
      }
    }
  }
`;

export const PLATE_TYPES = gql`
  query plateTypesByStateCode($state: StateInput!) {
    plateTypesByStateCode(state: $state) {
      name
    }
  }
`;

export interface ICountry {
  code: string;
  states: IState[];
}

export interface IState {
  country?: ICountry;
  code: string;
}

export interface IPlateType {
  name: string;
  state: IState;
}

import React, { useEffect, useMemo, useState } from 'react';
import AuthenticatedApp from './AuthenticatedApp';
import UnauthenticatedApp from './UnauthenticatedApp';
import { IAuthContext, useAuth } from '../../store/authentication/authentication.store';
import { useLocation } from 'react-router';
import { useOnboardingStore } from '../../store/onboarding/onboarding.store';
import { AccountType } from '../../types/onboarding.types';
import { usePrevLocation } from '../../hooks/location/usePrevLocation';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import { getPathOrigin, useRouteMonitor } from '../../hooks/location/useRouteMonitor';
import { MainRoutes, OnboardingStepPath } from '../../types/routes';
import { useDashboardStore } from '../../store/dashboard/dashboard.store';
import AppHeader from '../AppHeader/AppHeader';
import { useOnboardingErrors } from 'src/store/errors/onboardingErrors.store';
import { useErrorStore } from '../../store/errors/error.store';

function App(): React.ReactElement {
  const {
    details: { isAuthenticated },
  } = useAuth((state: IAuthContext) => state);

  useEffect(() => {
    document.documentElement?.classList.add('smooth-scroll');
  }, []);
  const { setCanProceedToNextStep, resetOnboardingError } = useOnboardingErrors();
  const location = useLocation();
  const prevLocation = usePrevLocation(location);
  const { checkHistory } = useRouteMonitor();
  const {
    onboardingInfo: { accountType, hasTranspondersToActivate },
    resetOnboardingStore,
  } = useOnboardingStore();

  const history = useRouteMonitor();
  const { error } = useErrorStore();

  useEffect(() => {
    if (
      error?.code === 502 ||
      error?.code === 503 ||
      error?.message.includes('502') ||
      error?.message.includes('503')
    ) {
      history.goToPath(MainRoutes.MAINTENANCE);
    }
  }, []);

  const [isRedirectedFromSuccessPage, setIsRedirectedFromSuccessPage] = useState(false);
  const [isRedirectedFromReviewPage, setIsRedirectedFromReviewPage] = useState(false);

  useEffect(() => {
    setIsRedirectedFromReviewPage(
      accountType === AccountType.GOVERNMENT &&
        prevLocation.pathname === OnboardingStepPath.REVIEW &&
        location.pathname !== OnboardingStepPath.ACCOUNT &&
        location.pathname !== OnboardingStepPath.SUCCESS,
    );

    setIsRedirectedFromSuccessPage(
      accountType === AccountType.GOVERNMENT && prevLocation.pathname === OnboardingStepPath.SUCCESS,
    );
  }, [location, prevLocation, accountType]);

  useEffect(() => {
    (isRedirectedFromSuccessPage || isRedirectedFromReviewPage) && resetOnboardingStore();
  }, [location, isRedirectedFromReviewPage, isRedirectedFromSuccessPage]);

  useEffect(() => {
    if (getPathOrigin(location.pathname) === OnboardingStepPath.INITIAL) {
      checkHistory();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (hasTranspondersToActivate) {
      setCanProceedToNextStep(false);
      resetOnboardingError();
    }
  }, [location.pathname]);

  const {
    store: {
      addPaymentMethodMode,
      activateTransponderMode,
      addVehicleMode,
      addFundsMode,
      addEasyPayMode,
      purchaseTransponderMode,
      parkingMode,
    },
  } = useDashboardStore();

  const isActionItemSelected = useMemo(() => {
    return (
      addPaymentMethodMode ||
      activateTransponderMode ||
      addFundsMode ||
      addEasyPayMode ||
      addVehicleMode ||
      purchaseTransponderMode ||
      parkingMode
    );
  }, [
    addPaymentMethodMode,
    activateTransponderMode,
    addVehicleMode,
    addFundsMode,
    addEasyPayMode,
    purchaseTransponderMode,
    parkingMode,
  ]);

  return (
    <>
      {isActionItemSelected ? null : <ScrollToTop />}
      <AppHeader />
      {isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </>
  );
}

export default App;

import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Home from '../Home/Home';
import { Styleguide } from '../StyleGuide/Styleguide';
import AppFooter from '../AppFooter/AppFooter';
import Onboarding from '../../pages/Onboarding/Onboarding';
import AccountClosure from '../AccountClosure/AccountClosure';
import { MainRoutes } from 'src/types/routes';
import ForgotUsernameOrPassword from '../ForgetUsernameOrPassword/ForgotUsernameOrPassword';
import { AccessOnlineAccount } from '../../pages/AccessOnlineAccount/AccessOnlineAccount';
import { ResumeAccountCreation } from '../Onboarding/ResumeAccountCreation/ResumeAccountCreation';
import PayATollInvoiceOrTrafficCitation from '../PayATollInvoiceOrTrafficCitation/PayATollInvoiceOrTrafficCitation';
import MaintenancePage from '../Maintenance/MaintenancePage';

export const UnauthenticatedApp = (): React.ReactElement => {
  return (
    <div className="App w-full bg-primary-gray2 ">
      <Routes>
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path="/onboarding/*" element={<Onboarding />} />
        <Route path={MainRoutes.ROOT} element={<Home />} />
        <Route path={MainRoutes.ACCOUNT_CLOSURE} element={<AccountClosure />} />
        <Route path={MainRoutes.STYLEGUIDE} element={<Styleguide />} />
        <Route path={MainRoutes.FORGOT_USERNAME_OR_PASSWORD} element={<ForgotUsernameOrPassword />} />
        <Route
          path={MainRoutes.PAY_A_TOLL_INVOICE_OT_TRAFFIC_CITATION}
          element={<PayATollInvoiceOrTrafficCitation />}
        />
        <Route path={MainRoutes.ACCESS_ONLINE_ACCOUNT} element={<AccessOnlineAccount />} />
        <Route path={MainRoutes.RESUME_ACCOUNT_CREATION} element={<ResumeAccountCreation />} />
        <Route path={MainRoutes.MAINTENANCE} element={<MaintenancePage />} />
      </Routes>
      <AppFooter></AppFooter>
    </div>
  );
};

export default UnauthenticatedApp;

import moment from 'moment';
import { IVehicleFormSubmit } from 'src/components/Dashboard/VehicleView/VehiclesList/VehicleForm';
import { IVehicleAddInput, IVehicleEditInput } from 'src/graphql/vehicles.graphql';
import { GRAPHQL_DATE_FORMAT, GRAPHQL_TIME_FORMAT, US_LOCAL_TIME_FORMAT } from 'src/utils/time.utils';
import { AccountType } from '../../types/onboarding.types';

export const mapVehicleToEdit = (
  values: IVehicleFormSubmit,
  vehicleId: string,
  accountType: AccountType | undefined,
): IVehicleEditInput => {
  return {
    plateNumber: values.plateNumber,
    plateType: values.plateType,
    vehicleType: values.plateType,
    plateState: values.plateState,
    vehicleCountry: values.vehicleCountry,
    isTrailer: values.isTrailer,
    vehicleMake: values.vehicleMake || 'Volvo',
    vehicleModel: values.vehicleModel || 'S40',
    vehicleYear: values.vehicleYear || 2022,
    vehicleColor: values.vehicleColor,
    vehicleId: vehicleId,
    isRental: accountType === AccountType.COMMERCIAL ? false : values.isRental,
    plateTypeDesc: values.plateType,
    effectiveStartDate: moment(values.effectiveStartDate).format(GRAPHQL_DATE_FORMAT),
    effectiveStartTime: moment(values.effectiveStartTime, US_LOCAL_TIME_FORMAT).format(GRAPHQL_TIME_FORMAT),
    effectiveEndDate: values.effectiveEndDate ? moment(values.effectiveEndDate).format(GRAPHQL_DATE_FORMAT) : undefined,
    effectiveEndTime: values.effectiveEndTime
      ? moment(values.effectiveEndTime, US_LOCAL_TIME_FORMAT).format(GRAPHQL_TIME_FORMAT)
      : undefined,
  };
};
export const mapVehicleToAdd = (values: IVehicleFormSubmit, accountType: AccountType | undefined): IVehicleAddInput => {
  return {
    plateNumber: values.plateNumber,
    plateType: values.plateType,
    plateState: values.plateState,
    vehicleCountry: values.vehicleCountry,
    isTrailer: values.isTrailer,
    vehicleMake: values.vehicleMake || 'Volvo',
    vehicleModel: values.vehicleModel || 'S40',
    vehicleYear: values.vehicleYear || 2022,
    vehicleColor: values.vehicleColor,
    isRental: accountType === AccountType.COMMERCIAL ? false : values.isRental,
    effectiveStartDate: moment(values.effectiveStartDate).format(GRAPHQL_DATE_FORMAT),
    effectiveStartTime: moment(values.effectiveStartTime, US_LOCAL_TIME_FORMAT).format(GRAPHQL_TIME_FORMAT),
    effectiveEndDate: values.effectiveEndDate ? moment(values.effectiveEndDate).format(GRAPHQL_DATE_FORMAT) : undefined,
    effectiveEndTime: values.effectiveEndTime
      ? moment(values.effectiveEndTime, US_LOCAL_TIME_FORMAT).format(GRAPHQL_TIME_FORMAT)
      : undefined,
    plateTypeDesc: values.plateType,
  };
};

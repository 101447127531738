import { useQuery } from '@apollo/client';
import { GET_PLANS, IPlan, IPlansData } from '../../../graphql/plans/plans.graphql';

interface IUsePlans {
  plansData: IPlansData | undefined;
  plansLoading: boolean;
  PAYGAccount: IPlan | undefined;
  GOVAccount: IPlan | undefined;
}

export enum PlanName {
  PVIDEOREG = 'PVIDEOREG',
  GOVPOST = 'GOVPOST',
  PBC = 'PBC',
}

export const usePlans = (): IUsePlans => {
  const { data: plansData, loading: plansLoading } = useQuery<IPlansData>(GET_PLANS);

  const PAYGAccount =
    plansData?.planList !== null ? plansData?.planList.find((plan) => plan.planName === PlanName.PVIDEOREG) : undefined;

  const GOVAccount =
    plansData?.planList !== null ? plansData?.planList.find((plan) => plan.planName === PlanName.GOVPOST) : undefined;

  return {
    plansData,
    PAYGAccount,
    plansLoading,
    GOVAccount,
  };
};

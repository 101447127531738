import { Form } from 'antd';
import { t, Trans } from '@lingui/macro';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomMaskedInput } from '../../../StyledInput/CustomMaskedInput';
import { FeinMask } from '../../../../validators/masks';
import { replace } from 'lodash';
import React, { useEffect } from 'react';
import { IFleetGovAdditionalInfo } from '../../../../types/onboarding.types';
import { useLocation } from 'react-router';
import { MainRoutes } from '../../../../types/routes';

export const Fein = () => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<IFleetGovAdditionalInfo>();

  const element = document.getElementById('fein');
  const location = useLocation();

  useEffect(() => {
    if (element && !!watch('fein') && watch('fein') !== '' && location.pathname !== MainRoutes.MY_ACCOUNT) {
      element.focus();
    }
  }, [watch('fein'), element]);

  return (
    <div className="w-full sm:w-6/12 sm:pr-1">
      <Form.Item
        label={<Trans>FEIN</Trans>}
        className="theme"
        help={errors.fein?.message}
        required
        hasFeedback
        validateStatus={errors?.fein?.message ? 'error' : ''}
        validateTrigger={['onChange', 'onBlur']}
      >
        <Controller
          name="fein"
          control={control}
          render={({ field, fieldState }) => (
            <CustomMaskedInput
              field={field}
              fieldState={fieldState}
              id="fein"
              key="fein"
              mask={FeinMask}
              onChange={(val) => field.onChange(replace(val.currentTarget.value, '-', ''))}
              maxLength={9}
              aria-label={t`Fein`}
            />
          )}
        />
      </Form.Item>
    </div>
  );
};

import { t } from '@lingui/macro';
import { IVehicle } from 'src/graphql/vehicles.graphql';

export const COLORS = [
  t`White`,
  t`Black`,
  t`Blue`,
  t`Red`,
  t`Yellow`,
  t`Silver`,
  t`Purple`,
  t`Orange`,
  t`Green`,
  t`Gray`,
  t`Pink`,
  t`Tan`,
  t`Brown`,
  t`Gold`,
  t`Beige`,
].sort();
export const mapVehicles = (vehicles: IVehicle[]): IVehicle[] => {
  return vehicles.map((vehicle) => {
    const oldStartTime = vehicle?.effectiveStartTime as unknown as moment.Moment;
    const oldEndTime = vehicle?.effectiveEndTime as unknown as moment.Moment;
    return { ...vehicle, effectiveStartTime: oldStartTime?.format('LT'), effectiveEndTime: oldEndTime?.format('LT') };
  });
};

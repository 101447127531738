import { gql } from '@apollo/client';
import { IFilter } from './filter.graphql';

export const GET_VEHICLE_LIST = gql`
  query vehicleList($vehicleListInput: VehicleListInput!) {
    vehicleList(vehicleListInput: $vehicleListInput) {
      vehicles {
        effectiveEndDate
        effectiveEndTime
        effectiveStartDate
        effectiveStartTime
        index
        isRental
        isTrailer
        plateNumber
        plateState
        plateType
        vehicleColor
        vehicleCountry
        vehicleId
        vehicleMake
        vehicleModel
        vehicleType
        vehicleYear
      }
      first
      last
      numberOfElements
      pageNumber
      pageSize
      totalElements
      totalPages
    }
  }
`;

export const DELETE_VEHICLE = gql`
  mutation deleteVehicle($vehicleId: String!) {
    deleteVehicle(vehicleId: $vehicleId)
  }
`;

export const ADD_VEHICLE = gql`
  mutation addVehicle($vehicleAddInput: VehicleAddInput!) {
    addVehicle(vehicleAddInput: $vehicleAddInput)
  }
`;

export const EDIT_VEHICLE = gql`
  mutation editVehicle($vehicleEditInput: VehicleEditInput!) {
    editVehicle(vehicleEditInput: $vehicleEditInput)
  }
`;

export const BULK_UPLOAD_VEHICLES = gql`
  mutation vehiclesBulkUpload($file: UploadFile!, $accountId: String) {
    vehiclesBulkUpload(input: $file, accountId: $accountId) {
      batchId
      addCount
      deleteCount
      editCount
      totalCount
    }
  }
`;

export const BULK_UPLOAD_VEHICLES_PROCESS = gql`
  query vehiclesBulkUploadProcess($batchId: String!) {
    vehiclesBulkUploadProcess(batchId: $batchId)
  }
`;

export interface BulkUploadOutput {
  batchId: string;
}

export interface IVehiclesBulkUploadOutput extends BulkUploadOutput {
  addCount: number;
  editCount: number;
  deleteCount: number;
  totalCount: number;
  batchId: string;
}

export interface IVehicleAddInput {
  plateNumber: string;
  plateType: string;
  plateState: string;
  vehicleCountry: string;
  isTrailer: boolean;
  vehicleMake: string;
  vehicleModel: string;
  vehicleYear: number;
  vehicleColor: string;
  isRental: boolean;
  plateTypeDesc: string;
  effectiveEndDate?: string;
  effectiveEndTime?: string;
  effectiveStartDate: string;
  effectiveStartTime: string;
  isAccept?: boolean;
  zipCode?: string;
}

export interface IVehicleEditInput {
  plateNumber: string;
  plateType?: string;
  plateState: string;
  vehicleCountry: string;
  isTrailer: boolean;
  vehicleMake: string;
  vehicleModel: string;
  vehicleYear: number;
  vehicleColor?: string;
  isRental: boolean;
  plateTypeDesc: string;
  vehicleId: string;
  vehicleType: string;
  effectiveEndDate?: string;
  effectiveEndTime?: string;
  effectiveStartDate?: string;
  effectiveStartTime?: string;
  isAccept?: boolean;
}

export interface IPaginationInput {
  pageSize: number;
  pageNumber: number;
}

export interface IVehicleListInput {
  activeSearch?: boolean;
  plateNumber?: string;
  pagination?: IPaginationInput;
  filter?: IFilter;
}

export interface IVehicleList {
  vehicles: IVehicle[];
  totalElements: number;
  totalPages: number;
  pageNumber: number;
  pageSize: number;
  numberOfElements: number;
  first: boolean;
  last: boolean;
}

export interface IVehicle {
  effectiveEndDate: string | moment.Moment;
  effectiveEndTime: string | moment.Moment;
  effectiveStartDate: string | moment.Moment;
  effectiveStartTime: string | moment.Moment;
  plateNumber: string;
  plateState: string;
  plateType: string;
  vehicleCountry: string;
  vehicleMake: string;
  vehicleModel: string;
  vehicleYear: string;
  vehicleColor: string;
  isRental: boolean;
  isTrailer: boolean;
  licenseInfoNotKnown?: boolean;
  index: number;
  vehicleId: string;
}

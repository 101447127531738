import React, { useMemo, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import CollapsableContainer from 'src/components/CollapsableContainer/CollapsableContainer';
import { useFormContext } from 'react-hook-form';
import { Password } from '../../PrivacyAndSecurity/Password';
import { ReTypePassword } from '../../PrivacyAndSecurity/ReTypePassword';
import { Username } from '../../PrivacyAndSecurity/Username';
import { ISecurityInfo } from 'src/types/onboarding.types';
import { useQuery } from '@apollo/client';
import { GET_ACCOUNT_DETAILS, IAccountDetails } from '../../../graphql/accountDetails.graphql';

interface IPrivacyAndSecuritySectionProps {
  isUpdatePasswordMode: boolean;
  toggleUpdatePasswordMode: () => void;
}

const PrivacyAndSecuritySection = ({
  isUpdatePasswordMode,
  toggleUpdatePasswordMode,
}: IPrivacyAndSecuritySectionProps) => {
  const { data } = useQuery<{ accountDetails: IAccountDetails }>(GET_ACCOUNT_DETAILS, {
    fetchPolicy: 'cache-and-network',
  });
  const username = data?.accountDetails.username || '';
  const password = data?.accountDetails.password;
  const [privacyAndSecurityCollapsed, setPrivacyAndSecurityCollapsed] = useState<boolean>(false);
  const { watch } = useFormContext<ISecurityInfo>();

  const usernameWatch = watch('username');
  const oldPasswordWatch = watch('oldPassword');
  const passwordWatch = watch('password');
  const reTypePasswordWatch = watch('reTypePassword');

  const isFormSectionDirty = useMemo(() => {
    return usernameWatch !== username || passwordWatch !== '' || reTypePasswordWatch !== '' || oldPasswordWatch !== '';
  }, [usernameWatch, passwordWatch, reTypePasswordWatch, oldPasswordWatch]);

  return (
    <CollapsableContainer
      title={t`Privacy and Security`}
      onChange={(panels: any) => setPrivacyAndSecurityCollapsed(!panels[0])}
      activeKey={privacyAndSecurityCollapsed ? [] : ['1']}
      noTopMargin
      unsavedChanges={isFormSectionDirty}
      responsive
    >
      <Username username={username} className="w-full sm:w-6/12 sm:pr-1" />
      <button
        className="mb-2 mt-6 cursor-pointer text-primary-green1 underline sm:mt-2"
        onClick={toggleUpdatePasswordMode}
      >
        <Trans>Edit your account password</Trans>
      </button>
      {isUpdatePasswordMode ? (
        <>
          <Password isUpdatePasswordMode={isUpdatePasswordMode} isOldPassword={true} />
          <Password password={password} isUpdatePasswordMode={isUpdatePasswordMode} />
          <ReTypePassword isUpdatePasswordMode={isUpdatePasswordMode} />
          <div className="mt-6 w-full text-center">
            <button className="text-primary-red underline" onClick={toggleUpdatePasswordMode}>
              <Trans>Cancel</Trans>
            </button>
          </div>
        </>
      ) : null}
    </CollapsableContainer>
  );
};

export default PrivacyAndSecuritySection;

import { gql } from '@apollo/client';

export const REGISTER_ACCOUNT_INITIAL_STEP = gql`
  mutation registerAccountInitialStep($registerAccountInitialInput: RegisterAccountInitialStepInput!) {
    registerAccountInitialStep(registerAccountInitialInput: $registerAccountInitialInput) {
      securityQuestion
    }
  }
`;

export const REGISTER_ACCOUNT_MIDDLE_STEP = gql`
  mutation registerAccountMiddleStep($registerAccountMiddleInput: RegisterAccountMiddleStepInput!) {
    registerAccountMiddleStep(registerAccountMiddleInput: $registerAccountMiddleInput)
  }
`;

export const REGISTER_ACCOUNT_FINAL_STEP = gql`
  mutation registerAccountFinalStep($registerAccountFinalInput: RegisterAccountFinalStepInput!) {
    registerAccountFinalStep(registerAccountFinalInput: $registerAccountFinalInput)
  }
`;

export interface IRegisterAccountInitialStepInput {
  accountNumber?: string;
  transponderNumber?: string;
  postalCode: string;
  language: string;
}

export interface IRegisterAccountInitialStepOutput {
  securityQuestion: string;
}

export interface IRegisterAccountMiddleStepInput extends IRegisterAccountInitialStepInput {
  securityQuestion: string;
  securityAnswer: string;
}

export interface IRegisterAccountFinalStepInput extends IRegisterAccountMiddleStepInput {
  username: string;
  newPassword: string;
  retypeNewPassword: string;
}

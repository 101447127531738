import React, { FC } from 'react';
import { t, Trans } from '@lingui/macro';
import ConfirmationModal from './ConfirmationModal';
import { convertPriceToString } from 'src/utils/formatter';

interface IAccountMinimumBalanceConfirmationModalProps {
  showModal: boolean;
  onOk: () => void;
  onCancel?: (nextLocation: any) => void | (() => void);
  cancelButtonBlue?: boolean;
  balance: number;
  paymentMethodName: string;
  minBalance: number;
  amount: number;
}

const AccountMinimumBalanceConfirmationModal: FC<IAccountMinimumBalanceConfirmationModalProps> = ({
  showModal,
  onOk,
  onCancel,
  balance,
  minBalance,
  amount,
}) => {
  const okButtonText = t`Confirm`;
  const title = (
    <>
      <h1 className="text-xl font-extrabold leading-6 xsm:leading-5">
        <Trans>Account Minimum Balance Required</Trans>
      </h1>
    </>
  );

  const content = t`Your account balance of ${convertPriceToString(
    balance,
  )} is currently below the minimum account balance of ${convertPriceToString(
    minBalance,
  )}. In order to bring your balance back up the minimum requirement, you will be charged ${convertPriceToString(
    amount,
  )}.`;

  return (
    <>
      {showModal && (
        <ConfirmationModal
          onOk={onOk}
          onCancel={onCancel}
          showModal={showModal}
          okButtonText={okButtonText}
          title={title}
          content={content}
          closable={true}
          alignButtons="center"
          alignText="center"
        />
      )}
    </>
  );
};
export default AccountMinimumBalanceConfirmationModal;

import { Form, Select } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import { ISecurityQuestion } from '../../graphql/securityQuestions.graphql';
import { t } from '@lingui/macro';
import '../AppForm/AppForm.scss';

export interface ISecurityQuestionProps {
  label: JSX.Element | string;
  fieldName: string;
  questions: ISecurityQuestion[] | undefined;
}

const { Option } = Select;

export const SecurityQuestion = ({ fieldName, label, questions }: ISecurityQuestionProps) => {
  const { getValues } = useFormContext();

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <div className="flex flex-wrap">
        <div className="input-margins w-full">
          <Form.Item
            label={label}
            className="theme"
            hasFeedback
            validateStatus={errors?.[`${fieldName}`] ? 'error' : ''}
            help={errors?.[`${fieldName}`]?.message as unknown as string}
            required
          >
            <Controller
              name={fieldName}
              control={control}
              render={({ field, fieldState }) => (
                <Select
                  {...field}
                  id={fieldName}
                  key={fieldName}
                  className={classNames({ 'input-error': fieldState.invalid })}
                  optionFilterProp="children"
                  aria-label={t`${fieldName}`}
                  showSearch
                  value={questions?.find((option) => option.value === getValues(fieldName))?.label}
                >
                  {questions?.map((securityQuestion: ISecurityQuestion, index: number) => (
                    <Option key={index} value={securityQuestion.value}>
                      {securityQuestion.label}
                    </Option>
                  ))}
                </Select>
              )}
            />
          </Form.Item>
        </div>
      </div>
    </>
  );
};

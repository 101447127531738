import { t } from '@lingui/macro';

export const emptyStateMessages = {
  vehicles: t`You don't have any registered vehicles.`,
  activity: t`You don't have any activity from the last 90 days.`,
  transponders: t`You don't have any activated transponders.`,
};
export const emptyFilteredStateMessages = {
  vehicles: t`You don't have any registered vehicles matching your search/filter criteria.`,
  activity: t`You don't have any activity matching your search/filter criteria.`,
  transponders: '',
};

import { t } from '@lingui/macro';
import { Select } from 'antd';
import React, { FC } from 'react';
import { ADD_NEW_PAYMENT_OPTION, ONE_TIME_PURCHASE } from 'src/constants';
import { IPaymentMethodGraphQl } from 'src/graphql/paymentMethods.graphql';
import { useSortPaymentMethods } from 'src/hooks/domain/payments/useSortPaymentMethods';
import { getPaymentMethodName } from 'src/utils/formatter';
import './ChosePaymentMethod.scss';
import { isLimitOfPaymentMethodsReached } from './ChosePaymentMethods.utils';

interface IChosePaymentMethod {
  handleChange(value: string): void;
  defaultValue?: string | undefined;
  sortedPaymentMethods: IPaymentMethodGraphQl[] | undefined;
  showAddNewPaymentMethod?: boolean;
  selectClassName?: string;
}
const ChosePaymentMethod: FC<IChosePaymentMethod> = ({
  defaultValue,
  handleChange,
  sortedPaymentMethods,
  selectClassName = '',
}) => {
  const { Option } = Select;

  const { sortedPaymentMethods: allPaymentMethods } = useSortPaymentMethods();

  return (
    <>
      <div className="select payment-method payment-search-input mb-3 w-full">
        <Select
          defaultValue={defaultValue}
          id="paymentMethodId"
          key="paymentMethodId"
          className={`${selectClassName} w-full`}
          optionFilterProp="children"
          placeholder={t`Payment Method`}
          onChange={handleChange}
          aria-label={t`Payment Method`}
        >
          {sortedPaymentMethods &&
            sortedPaymentMethods.map((paymentMethod) => (
              <Option value={paymentMethod.paymentMethodId} key={paymentMethod.paymentMethodId}>
                {getPaymentMethodName(paymentMethod, true)}
              </Option>
            ))}
          {!isLimitOfPaymentMethodsReached(allPaymentMethods || []) ? (
            <Option value={ADD_NEW_PAYMENT_OPTION} key={ADD_NEW_PAYMENT_OPTION}>
              {t`Add new payment method`}
            </Option>
          ) : null}
          <Option value={ONE_TIME_PURCHASE} key={ONE_TIME_PURCHASE}>
            {t`Make a one time payment`}
          </Option>
        </Select>
      </div>
    </>
  );
};
export default ChosePaymentMethod;

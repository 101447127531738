import React from 'react';
import { t } from '@lingui/macro';
import './EasyPay.css';
import { ReplenishmentThreshold } from './ReplenishmentThreshold';
import { SunPassPlusParking } from './SunPassPlusParking';
import { AccountType } from '../../../../types/onboarding.types';
import { usePlans } from '../../MyDashboard/usePlans';

interface IEasyPayEnrollmentProps {
  accountType: AccountType;
  isEasyPay: boolean | undefined;
  replenishmentAmount: string;
}

export const EasyPayEnrollment = ({ accountType, isEasyPay, replenishmentAmount }: IEasyPayEnrollmentProps) => {
  const { PAYGAccount } = usePlans();
  const isPAYGAccount = accountType === AccountType.PRIVATE && PAYGAccount;

  const easyPayMessage = t`
      You can enroll, un-enroll, and configure Easy Pay when you're adding and managing individual payment methods
      above.`;

  return (
    <>
      <p className="mr-5 text-left font-medium">{easyPayMessage}</p>
      <ReplenishmentThreshold replenishmentAmount={replenishmentAmount} isEasyPay={isEasyPay} />
      {!isPAYGAccount && accountType !== AccountType.COMMERCIAL ? <SunPassPlusParking /> : null}
    </>
  );
};

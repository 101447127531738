import { gql } from '@apollo/client';
import { IPaginationInput } from './vehicles.graphql';

export const GET_TRANSACTION_ACTIVITY_LIST = gql`
  query transactionActivityList($transactionActivityListInput: TransactionActivityListInput!) {
    transactionActivityList(transactionActivityListInput: $transactionActivityListInput) {
      first
      last
      numberOfElements
      pageNumber
      pageSize
      totalElements
      totalPages
      transactionActivities {
        accountSubTollTransactions {
          subAccountId
          subActivity
          subActivitySpan
          subAmount
          subAmountActual
          subBalance
          subBalanceActual
          subCredit
          subDebit
          subDeviceNo
          subEntryDate
          subEntryDummyDate
          subEntryTime
          subExitDate
          subExitTime
          subPaymentRowId
          subPostingDate
          subTranType
          subTransactionId
          subTxDate
          subTxTime
          subViewReceipt
        }
        activity
        agency
        amount
        axle
        balance
        entryLane
        entryPlaza
        entryPlazaName
        entryTime
        exitLane
        exitPlaza
        exitPlazaName
        exitTime
        fareType
        index
        planOrRate
        postingDate
        prepaid
        tagOrPlateNumber
        transactionDate
        transactionNumber
        vehicleTypeCode
      }
    }
  }
`;

export interface ITransactionActivityListInput {
  agencyName?: string;
  endDate?: string;
  location?: string;
  plateNumber?: string;
  searchDate?: string;
  startDate?: string;
  transactionType?: string;
  transponderNumber?: string;
  pagination?: IPaginationInput;
}

export interface ITransactionActivityList {
  count: number;
  transactionActivities: ITransactionActivity[];
}

export interface ITransactionActivityList {
  first: boolean;
  last: boolean;
  numberOfElements: number;
  pageNumber: number;
  pageSize: number;
  totalElements: number;
  totalPages: number;
  transactionActivities: ITransactionActivity[];
}

export interface ITransactionActivity {
  accountSubTollTransactions: IAccountSubTollTransaction[];
  activity: string;
  agency: string;
  amount: number;
  axle: string;
  balance: number;
  entryLane: string;
  entryPlaza: string;
  entryPlazaName: string;
  entryTime: string;
  exitLane: string;
  exitPlaza: string;
  exitPlazaName: string;
  exitTime: string;
  fareType: string;
  index: number;
  planOrRate: string;
  postingDate: string;
  prepaid: string;
  tagOrPlateNumber: string;
  transactionDate: string;
  transactionNumber: string;
  vehicleTypeCode: string;
}

interface IAccountSubTollTransaction {
  subAccountId: string;
  subActivity: string;
  subActivitySpan: string;
  subAmount: string;
  subAmountActual: string;
  subBalance: string;
  subBalanceActual: string;
  subCredit: string;
  subDebit: string;
  subDeviceNo: string;
  subEntryDate: string;
  subEntryDummyDate: string;
  subEntryTime: string;
  subExitDate: string;
  subExitTime: string;
  subPaymentRowId: string;
  subPostingDate: string;
  subTranType: string;
  subTransactionId: string;
  subTxDate: string;
  subTxTime: string;
  subViewReceipt: string;
}

import { Form } from 'antd';
import { t, Trans } from '@lingui/macro';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomMaskedInput } from '../../../StyledInput/CustomMaskedInput';
import { PhoneMask } from '../../../../validators/masks';
import React, { useEffect } from 'react';
import { IPhonesInfo } from '../../../../types/onboarding.types';
import { IAccountFormElemBaseProps } from './account.types';
import '../../../AppForm/AppForm.scss';
import { useLocation } from 'react-router';
import { MainRoutes } from '../../../../types/routes';

export const HomePhone = ({ disabled }: IAccountFormElemBaseProps) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<IPhonesInfo>();

  const element = document.getElementById('homePhone');

  const location = useLocation();

  useEffect(() => {
    if (element && !!watch('homePhone') && watch('homePhone') !== '' && location.pathname !== MainRoutes.MY_ACCOUNT) {
      element.focus();
    }
  }, [watch('homePhone')]);

  return (
    <div className="input-margins w-full sm:w-6/12">
      <Form.Item
        label={<Trans>Home Phone Number</Trans>}
        className="theme"
        hasFeedback
        validateStatus={errors.homePhone ? 'error' : ''}
        help={errors.homePhone ? errors.homePhone?.message : ''}
        validateTrigger={['onChange']}
      >
        <Controller
          name="homePhone"
          control={control}
          render={({ field, fieldState }) => (
            <CustomMaskedInput
              disabled={disabled}
              id="homePhone"
              key="homePhone"
              field={field}
              fieldState={fieldState}
              mask={PhoneMask}
              maxLength={10}
              formatterType="phone"
              label={t`Home phone number`}
            />
          )}
        />
      </Form.Item>
    </div>
  );
};

import { FC } from 'react';
import { InvoiceTransaction } from 'src/graphql/invoiceTransactions.graphql';
import { convertPriceToString } from 'src/utils/formatter';
import { extractTimeFromISOString, formatDate, formatTime } from 'src/utils/time.utils';

interface IUnpaidTollsItemProps {
  invoice: InvoiceTransaction;
}

const UnpaidTollsItem: FC<IUnpaidTollsItemProps> = ({ invoice }) => {
  const { transactionDateTime, exitPlaza, plateNumber, invTransactionAmt } = invoice;

  return (
    <div className="min-h-20 mb-3 flex cursor-pointer flex-col justify-center rounded-lg bg-white px-6 py-4 text-base shadow-lg sm:flex-row sm:items-center sm:justify-between">
      <div>
        <p className="transaction-date mb-3">
          {formatDate(transactionDateTime, true)} AT{' '}
          {formatTime(extractTimeFromISOString(transactionDateTime)).toUpperCase()}
        </p>
        <p className="my-3 font-bold">{exitPlaza || '-'}</p>
        <p className="unpaid-tolls-item-plate-number mb-0 mt-3">{plateNumber}</p>
      </div>
      <div className="mt-3 font-bold text-primary-red sm:mt-0">-{convertPriceToString(invTransactionAmt)}</div>
    </div>
  );
};

export default UnpaidTollsItem;

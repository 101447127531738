import React, { FC } from 'react';
import { t, Trans } from '@lingui/macro';
import { CustomButton } from '../../CustomButton/CustomButton';
import { Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { colors } from '../../../theme/theme';
import './ConfirmationModal.scss';

interface IDeactivateTransponderModalProps {
  showModal: boolean;
  onConfirmDeactivateAndReplaceModal: () => void;
  onConfirmDeactivateWithoutReplacingModal: () => void;
  onCancel: () => void;
}

const DeactivateTransponderModal: FC<IDeactivateTransponderModalProps> = ({
  showModal,
  onConfirmDeactivateAndReplaceModal,
  onConfirmDeactivateWithoutReplacingModal,
  onCancel,
}) => {
  const title = <Trans>Deactivate Transponder</Trans>;
  const content = (
    <div>
      <Trans>
        Your transponder&apos;s status will be updated to deactivated. It will remain on your transponders list in
        deactivated status, and if you recover it, you can select the &quot;Reactivate&quot; button and resume using it.
        <br />
        <span className="mt-2 inline-block"> Would you like to purchase a replacement transponder?</span>
      </Trans>
    </div>
  );

  return (
    <>
      {showModal && (
        <Modal
          title={title ? <h1 className={`text-center text-xl`}>{title}</h1> : null}
          visible={showModal}
          width={700}
          className={`confirmation-modal_container`}
          closable={true}
          onCancel={onCancel}
          closeIcon={<CloseOutlined style={{ color: colors.primary.blue1, fontSize: 20, transform: 'scaleX(1.1)' }} />}
          footer={[
            <>
              <div className="justify-center text-center">
                <div key="buttons" className={`flex-column mt-7 justify-center text-center sm:flex`}>
                  <CustomButton
                    className="button-primary deactivate-transponder-btn sm:mr-15 p-15 ml-auto mr-auto "
                    content={t`Deactivate Without Replacing`}
                    onClick={onConfirmDeactivateWithoutReplacingModal}
                    isPrimary={false}
                  />
                  <CustomButton
                    className="button-primary deactivate-transponder-btn ml-auto mr-auto p-8"
                    content={t`Deactivate and Replace`}
                    onClick={onConfirmDeactivateAndReplaceModal}
                    isPrimary={true}
                  />
                </div>
                <button type="button" className="dashboard-links mx-auto my-2 inline text-base" onClick={onCancel}>
                  <Trans>Cancel</Trans>
                </button>
              </div>
            </>,
          ]}
        >
          <div className="text-center">{content}</div>
        </Modal>
      )}
    </>
  );
};
export default DeactivateTransponderModal;

import React, { useEffect, useMemo, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Breadcrumb, Form } from 'antd';
import { FormProvider, useForm } from 'react-hook-form';
import '../Onboarding/Onboarding.scss';
import { CustomButton } from '../../components/CustomButton/CustomButton';
import { Link } from 'react-router-dom';
import { MainRoutes } from '../../types/routes';
import { yupResolver } from '@hookform/resolvers/yup';
import { getAccessOnlineAccountSchemaByCurrentStep } from '../../validators/accessOnlineAccount/accessOnlineAccount.schema';
import { MainLoader } from '../../components/Loader/MainLoader';
import { useAccessOnlineAccount } from '../../hooks/domain/unauthorized/useAccessOnlineAccount';
import LeaveSiteConfirmationModal from '../../components/Modals/ConfirmationModal/LeaveSiteConfirmationModal';
import { useCallbackPrompt } from '../../hooks/location/useCallbackPrompt';
import './AccessOnlineAccount.scss';
interface IAccessOnlineFormInitialStepSubForm {
  postalCode: string;
  accountNumber: string;
  transponderNumber: string;
}

interface IAccessOnlineFormMiddleStepSubForm {
  question: string;
  answer: string;
}

interface IAccessOnlineFormFinalStepSubForm {
  username: string;
  password: string;
  reTypePassword: string;
}

export type IAccessOnlineAccountForm = IAccessOnlineFormInitialStepSubForm &
  IAccessOnlineFormMiddleStepSubForm &
  IAccessOnlineFormFinalStepSubForm;

export const AccessOnlineAccount = () => {
  const [step, setStep] = useState(1);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const schema = useMemo(() => {
    return getAccessOnlineAccountSchemaByCurrentStep(step, isFormSubmitted);
  }, [step]);
  const methods = useForm<Omit<IAccessOnlineAccountForm, 'question'>>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      accountNumber: undefined,
      postalCode: undefined,
      transponderNumber: undefined,
      answer: undefined,
      password: undefined,
      reTypePassword: undefined,
      username: undefined,
    },
  });
  const {
    handleSubmit,
    reset: resetForm,
    formState: { isDirty, isSubmitted, errors },
    resetField,
    clearErrors,
  } = methods;

  const {
    onSubmit,
    loading,
    form: accessOnlineAccountForm,
    reset,
  } = useAccessOnlineAccount({ step, changeStep: setStep });

  const [shouldShowRedirectPrompt, setShouldShowRedirectPrompt] = useState(false);

  const { showPrompt, confirmRedirect, cancelRedirect } = useCallbackPrompt(shouldShowRedirectPrompt);

  useEffect(() => {
    setShouldShowRedirectPrompt(isDirty);
  }, [isDirty]);

  useEffect(() => {
    if (step === 3) {
      resetForm();
    }
    setIsFormSubmitted(isSubmitted);
  }, [step]);

  const clearForm = () => {
    reset();
    if (step === 1 || step === 3) {
      resetForm();
      clearErrors();
    } else {
      resetField('answer');
    }
  };

  useEffect(() => {
    if (step === 3 && !errors.password) clearErrors('reTypePassword');
  }, [step, errors]);

  return (
    <div className="access-online-account-container h-full">
      <MainLoader loading={loading} />
      <h1 className="breadcrumb">
        <Breadcrumb separator="/">
          <Breadcrumb.Item className="underline">
            <Link to={MainRoutes.ROOT}>
              <Trans>Home</Trans>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item className="underline">
            <Trans>Access Online Account</Trans>
          </Breadcrumb.Item>
        </Breadcrumb>
      </h1>
      <h2 className="m-auto w-full px-2 py-8 pb-0 text-center text-3xl font-light md:w-2/3">
        <Trans>Access Online Account {step} of 3</Trans>
      </h2>

      <p className="mx-auto w-3/4 max-w-xl py-4 text-center">
        <Trans>
          If you’ve created your SunPass account over the phone, and would like to access your account online, please
          follow the steps below.
        </Trans>
      </p>
      <div className="forgot-username-or-password-form">
        {step !== 3 && (
          <p className="w-3/4">
            <Trans>
              To access your online account, please enter your postal code plus your account number or your transponder
              number.
            </Trans>
          </p>
        )}
        <FormProvider {...methods}>
          <Form layout="vertical" size="large">
            {accessOnlineAccountForm}
            <div className="my-4 mt-8 flex items-center justify-start gap-8">
              <CustomButton className="button-primary" onClick={handleSubmit(onSubmit)} content={t`Submit`} />
              <button type="button" className="dashboard-links mt-1 inline text-base" onClick={clearForm}>
                <Trans>Clear</Trans>
              </button>
            </div>
          </Form>
        </FormProvider>
      </div>
      <LeaveSiteConfirmationModal showModal={showPrompt} onOk={confirmRedirect} onCancel={cancelRedirect} />
    </div>
  );
};

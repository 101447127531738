import React, { FC, useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import CollapsableContainer from '../../CollapsableContainer/CollapsableContainer';
import '../../../pages/Onboarding/Onboarding.scss';
import './OnboardingTransponders.scss';
import PurchaseTranspondersContent from './PurchaseTranspondersContent';
import { useFormContext } from 'react-hook-form';
import { AccountSubtype, AccountType } from '../../../types/onboarding.types';
import { useOnboardingStore } from '../../../store/onboarding/onboarding.store';
import { SELECT_DETAILS } from '../../../store/onboarding/onboarding.selectors';

interface IOnboardingPurchaseTransponders {
  purchasePanelCollapsed: boolean;
  setPurchasePanelCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  showErrorMini?: boolean;
  showErrorPro?: boolean;
}

const OnboardingPurchaseTransponders: FC<IOnboardingPurchaseTransponders> = ({
  purchasePanelCollapsed,
  setPurchasePanelCollapsed,
  showErrorMini,
  showErrorPro,
}) => {
  const [isOptInPBCMini, setIsOptInPBCMini] = useState(false);
  const [isOptInPBCPro, setIsOptInPBCPro] = useState(false);
  const { accountSubtype, accountType, miniTransponderQtyAssignedToPlan, proTransponderQtyAssignedToPlan } =
    useOnboardingStore(SELECT_DETAILS);

  const { setValue, watch } = useFormContext();

  const isPrivateAccountWithPBC = accountType === AccountType.PRIVATE && accountSubtype === AccountSubtype.PBC;

  const miniTransponderQty = watch('miniTransponderQty');
  const proTransponderQty = watch('proTransponderQty');

  useEffect(() => {
    if (isPrivateAccountWithPBC) {
      setValue('miniTransponderQtyAssignedToPlan', miniTransponderQty);
      setValue('proTransponderQtyAssignedToPlan', 0);
      setValue('proTransponderQty', 0);
    } else {
      setValue('miniTransponderQty', 0 || miniTransponderQty);
      if (!isOptInPBCPro && proTransponderQty === 0) {
        setValue('proTransponderQtyAssignedToPlan', 0);
      }
      if (!isOptInPBCMini && miniTransponderQty === 0) {
        setValue('miniTransponderQtyAssignedToPlan', 0);
      }
    }
  }, []);

  useEffect(() => {
    if (isPrivateAccountWithPBC) {
      setValue('miniTransponderQtyAssignedToPlan', miniTransponderQty);
      setValue('proTransponderQtyAssignedToPlan', 0);
      setValue('proTransponderQty', 0);
    }
  }, [miniTransponderQty]);

  useEffect(() => {
    if (!isOptInPBCMini) {
      setValue('miniTransponderQtyAssignedToPlan', 0);
    }
    if (!isOptInPBCPro) {
      setValue('proTransponderQtyAssignedToPlan', 0);
    }
  }, [isOptInPBCPro, isOptInPBCMini]);

  useEffect(() => {
    if (miniTransponderQtyAssignedToPlan > 0) {
      setIsOptInPBCMini(true);
      setValue('miniTransponderQtyAssignedToPlan', miniTransponderQtyAssignedToPlan);
    }
    if (proTransponderQtyAssignedToPlan > 0) {
      setIsOptInPBCPro(true);
      setValue('proTransponderQtyAssignedToPlan', proTransponderQtyAssignedToPlan);
    }
  }, []);

  return (
    <CollapsableContainer
      title={t`Purchase Transponders`}
      activeKey={purchasePanelCollapsed ? [] : ['1']}
      onChange={(panels: any) => setPurchasePanelCollapsed(!panels[0])}
      collapseIcon={false}
      noTopMargin
      centerHeader
    >
      <PurchaseTranspondersContent
        isDashboard={false}
        showErrorMini={showErrorMini}
        showErrorPro={showErrorPro}
        isOptInPBCMini={isOptInPBCMini}
        isOptInPBCPro={isOptInPBCPro}
        setIsOptInPBCMini={setIsOptInPBCMini}
        setIsOptInPBCPro={setIsOptInPBCPro}
      />
    </CollapsableContainer>
  );
};

export default OnboardingPurchaseTransponders;

const Colors = {
  primary: {
    white: '#fff',
    yellow1: '#FFDE17',
    yellow1Fade: 'rgba(255, 222, 23, 0.05)',
    yellow2: '#FFF8CC',
    yellow3: '#FFFDF3',
    orange1: '#B8550A',
    red: '#C91515',
    red2: '#B40404',
    green1: '#008549',
    green2: '#B9DACB',
    green3: '#F2F9F5',
    green4: '#007E45',
    green5: '#007e45',
    greenLightBg: '#F2F9F6',
    greenLightBg2: '#F2F9F6FF',
    green1Fade: '#00854940',
    blue1: '#186298',
    blue2: '#2D75C4',
    blue3: '#295083',
    blue4: '#01426D',
    blue5: '#629CF4',
    gray1: '#333333',
    gray2: '#F7F7F7',
    gray3: '#777777',
    gray4: '#C9C9C9',
    gray5: '#666666',
    gray6: '#DCDCDC',
    gray7: '#717171',
    gray8: '#979797',
    gray9: '#F0EEEE',
    black: '#000',
  },
  borders: {
    gray1: '#D7D7D7',
    gray2: '#999999',
    gray3: '#919191',
    blue: '#006BB0',
  },
  alerts: {
    red: '#C12525',
    orange: '#B8550A',
    white: '#FFFAFA',
  },
  shadows: {
    blue: '#629CF4',
  },
};

const Screens = {
  xsm: '391px',
  vsm: '500px',
  sm: '640px',
  md: '768px',
  'large-tablet': '992px',
  lg: '1024px',
  xl: '1280px',
  '2xl': '1536px',
};

exports.colors = Colors;
exports.screens = Screens;

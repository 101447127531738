import { transponderApiErrorCodeToUIErrorMessageMap, UI_MESSAGE_VALUE_MARKER } from './error.uiMessages';
import { transponderApiErrorCodeToTemplateMap } from './error.api';

const getRegExpForTemplate = (template: string): RegExp => {
  return new RegExp(template.replace(/{@(\d+)}/g, '(.+)'));
};

const joinMatchingValuesWithUIMsgTemplate = (uiErrorMsg: string, matchingValues: string[]) => {
  const arrayOfSplitMsg = uiErrorMsg.split(UI_MESSAGE_VALUE_MARKER);
  const lastElemIndex = arrayOfSplitMsg.length - 1;
  let j = 1;
  return arrayOfSplitMsg
    .map((value, index) => {
      if (lastElemIndex === index) return value;
      const newValue = value + matchingValues[j];
      j++;
      return newValue;
    })
    .join('');
};

export const parseMessage = (errorCode: number, receivedApiErrorMsg: string): string => {
  const uiErrorMsg = transponderApiErrorCodeToUIErrorMessageMap[errorCode];
  const apiErrorMsgTemplate = transponderApiErrorCodeToTemplateMap[errorCode];
  const regex = getRegExpForTemplate(apiErrorMsgTemplate);
  const matchingValues = receivedApiErrorMsg.match(regex) || [];
  return joinMatchingValuesWithUIMsgTemplate(uiErrorMsg, matchingValues);
};

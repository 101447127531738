import { FC, useMemo } from 'react';
import { Form, Select } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import { t, Trans } from '@lingui/macro';
import { IVehicleFormItemProps } from '../../../Onboarding/Vehicles/OnboardingVehiclesForm/OnboardingVehicleForm';
import { buildErrorString, getInputName } from '../../../Onboarding/Vehicles/OnboardingVehiclesForm/vehicleForm.utils';
import classNames from 'classnames';
import usePlateTypes from 'src/hooks/domain/dashboard/vehicle/usePlateTypes';
import { useNonInitialRender } from 'src/hooks/effect/useNonInitialRender';

const { Option } = Select;

const LicencePlateType: FC<IVehicleFormItemProps> = ({ field, index, name, isEditMode }) => {
  const {
    control,
    resetField,
    watch,
    formState: { errors },
  } = useFormContext();

  const licensePlateNotKnown = watch(getInputName('notKnown', index));
  const country = watch(getInputName('vehicleCountry', index));
  const state = watch(getInputName('plateState', index));

  const { plateTypes } = usePlateTypes({ state, country });

  useNonInitialRender(() => {
    resetField(getInputName('plateType', index), {
      defaultValue: null,
    });
  }, [country, state, licensePlateNotKnown]);

  const uniquePlateNames = useMemo(() => {
    const plateNames = plateTypes.map(function (plateType) {
      return plateType.name;
    });
    return plateNames.filter((item, index) => plateNames.indexOf(item) === index);
  }, [plateTypes]);

  return (
    <div className="mb-4 w-full sm:w-6/12 sm:pr-2">
      <Form.Item
        {...field}
        help={buildErrorString(name, errors, index)}
        label={<Trans id="License Plate Type" />}
        htmlFor={name}
        className="theme"
        required
        hasFeedback
        validateStatus={errors?.vehicles?.[0]?.plateType || errors.plateType ? 'error' : ''}
      >
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              {...fieldState}
              className={`${classNames({ 'input-error': fieldState?.invalid })}`}
              id={name}
              showSearch
              placeholder={t`Select`}
              disabled={licensePlateNotKnown || isEditMode}
              aria-label={t`License Plate Type`}
            >
              {uniquePlateNames.map((plateName) => (
                <Option key={plateName} value={plateName}>
                  {plateName}
                </Option>
              ))}
            </Select>
          )}
        />
      </Form.Item>
    </div>
  );
};

export default LicencePlateType;

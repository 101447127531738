import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { useRouteMonitor } from 'src/hooks/location/useRouteMonitor';
import { colors } from 'src/theme/theme';

interface Props {
  onBack?: () => void;
  blockRedirectTill?: boolean;
  content?: string;
}

function BackButton(props: Props): React.ReactElement {
  const history = useRouteMonitor();

  const { onBack, blockRedirectTill, content } = props;

  const backHandler = useCallback(() => {
    onBack && onBack();
    if (blockRedirectTill !== true) {
      history.goBack();
    }
  }, [blockRedirectTill]);

  return (
    <button className="back-button flex items-center" onClick={backHandler} type="button">
      <FontAwesomeIcon icon={faAngleLeft} color={colors.primary.blue1} size="2x"></FontAwesomeIcon>
      <div className="ml-2 font-bold text-primary-blue1 underline">{content ? content : 'Back'}</div>
    </button>
  );
}

export default BackButton;

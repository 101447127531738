import { gql } from '@apollo/client';
import { IFilter } from './filter.graphql';
import { IPaginationInput } from './vehicles.graphql';

export const GET_INVOICE_TRANSACTIONS = gql`
  query invoiceTransactionList($invoiceTransactionListInput: InvoiceTransactionListInput!) {
    invoiceTransactionList(invoiceTransactionListInput: $invoiceTransactionListInput) {
      first
      invoiceTransactions {
        axle
        description
        exitLane
        exitPlaza
        index
        invAmtDue
        invTransactionAmt
        plateNumber
        postingDate
        status
        transactionDateTime
      }
      last
      numberOfElements
      pageNumber
      pageSize
      sumOfInvTransactionAmt
      totalElements
      totalPages
    }
  }
`;

export interface IInvoiceTransactionListInput {
  agencyName?: string;
  endDate: string;
  filter?: IFilter;
  filterPositiveAmtDue?: boolean;
  location?: string;
  pagination: IPaginationInput;
  plateNumber?: string;
  searchDate?: string;
  startDate: string;
}

export interface IInvoiceTransactionList {
  first: boolean;
  invoiceTransactions: InvoiceTransaction[];
  last: boolean;
  numberOfElements: number;
  pageNumber: number;
  pageSize: number;
  sumOfInvTransactionAmt: number;
  totalElements: number;
  totalPages: number;
}

export interface InvoiceTransaction {
  axle: string;
  description: string;
  exitLane: string;
  exitPlaza: string;
  index: number;
  invAmtDue: number;
  invTransactionAmt: number;
  plateNumber: string;
  postingDate: string;
  status: string;
  transactionDateTime: string;
}

import React, { Fragment } from 'react';
import { Trans, t } from '@lingui/macro';
import './AppFooter.scss';
import ExpresswayLogo from './assets/ExpresswayLogo.svg';
import FLTurnpikeLogo from './assets/FL-TurnpikeLogo.svg';
import MDXLogo from './assets/mdx.svg';
import GooglePlay from './assets/GooglePlay.png';
import AppStore from './assets/AppStore.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from 'src/theme/theme';
import { faFacebookF, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';

function AppFooter(): React.ReactElement {
  return (
    <Fragment>
      <div className="footer bg-primary-blue3">
        <div className="w-full py-4 md:mx-2 lg:m-auto lg:grid lg:w-11/12 lg:grid-flow-col xl:w-3/4">
          <div className="grid-cols-2 md:grid md:grid-flow-col ">
            <section className="col-span-1 flex flex-col space-y-3 text-center">
              <div className="flex flex-row justify-center space-x-8 md:flex-col  md:space-x-0 lg:mr-0">
                <img
                  className="fl-turnpike-logo block self-center pl-5 md:pl-0"
                  src={FLTurnpikeLogo}
                  alt="Florida Turnpike logo"
                ></img>
                <img
                  className="expressway-logo block self-center"
                  src={ExpresswayLogo}
                  alt="Tampa Hillsborough Expressway Authority Logo"
                ></img>
              </div>
              <img
                className="mdx-logo block w-1/2 self-center pb-8 lg:w-full"
                src={MDXLogo}
                alt="Miami-Dade Expressway Authority Logo"
              ></img>
            </section>
            <section className="ml-4 md:ml-0">
              <div className="section-title">
                <Trans>Quick Links</Trans>
              </div>
              <button className="link" aria-label={t`Contact Us`}>
                <Trans>Contact Us</Trans>
              </button>
              <button className="link" aria-label={t`Feedback`}>
                <Trans>Feedback</Trans>
              </button>
              <button className="link" aria-label={t`Florida's Turnpike`}>
                <Trans>Florida’s Turnpike</Trans>
              </button>
              <button className="link text-left" aria-label={t`Miami-Dade Expressway Authority`}>
                <Trans>Miami-Dade Expressway Authority</Trans>
              </button>
              <button className="link text-left" aria-label={t`Tampa Hillsborough Expressway Authority`}>
                <Trans>Tampa Hillsborough Expressway Authority</Trans>
              </button>
              <div>
                <div className="section-title pt-8">
                  <Trans>Follow Us</Trans>
                </div>
                <div className="space-x-5">
                  <button aria-label={t`Twitter`}>
                    <FontAwesomeIcon icon={faTwitter} color={colors.primary.white} size={'lg'}></FontAwesomeIcon>
                  </button>
                  <button aria-label={t`Facebook`}>
                    <FontAwesomeIcon icon={faFacebookF} color={colors.primary.white} size={'lg'}></FontAwesomeIcon>
                  </button>
                  <button aria-label={t`YouTube`}>
                    <FontAwesomeIcon icon={faYoutube} color={colors.primary.white} size={'lg'}></FontAwesomeIcon>
                  </button>
                </div>
              </div>
            </section>
          </div>
          <div className="mt-8 grid-cols-2 md:grid md:grid-flow-col lg:mt-0">
            <section className="col-span-1 ml-4 md:ml-32 lg:ml-0 lg:mr-10 lg:border-r lg:border-primary-white">
              <div className="mt-8 lg:mt-0">
                <div className="section-title">
                  <Trans>Partner Link</Trans>
                </div>
                <button className="link" aria-label={t`Registered Retailers`}>
                  <Trans>Registered Retailers</Trans>
                </button>
              </div>
              <div>
                <div className="section-title pt-8">
                  <Trans>Security & Privacy</Trans>
                </div>
                <button className="link" aria-label={t`Privacy Policy`}>
                  <Trans>Privacy Policy</Trans>
                </button>
                <button className="link" aria-label={t`Customer Agreements`}>
                  <Trans>Customer Agreements</Trans>
                </button>
              </div>
            </section>
            <section className="col-span-1 sm:ml-0">
              <div className="mt-8 lg:mt-0">
                <div className="section-title">
                  <Trans>Get the SunPass App</Trans>
                </div>
                <img className="app-store-logo" src={AppStore} alt="App Store Logo"></img>

                <img className="app-store-logo" src={GooglePlay} alt="Google Play Logo"></img>
              </div>
            </section>
          </div>
        </div>
      </div>
      <div className="disclaimer bg-primary-blue4 text-center text-primary-white lg:text-left">
        <Trans>
          © 2021 SunPass® is a registered trademark of the Florida Department of Transportation. Under Florida law,
          e-mail addresses are public records. If you do not want your e-mail address released in response to a public
          records request, do not send electronic mail to this entity. Instead, contact this office by phone or in
          writing.
        </Trans>
      </div>
    </Fragment>
  );
}

export default AppFooter;

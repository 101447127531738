import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { AccountType } from '../../../../types/onboarding.types';
import './EasyPay.css';
import { MINIMUM_AMOUNT } from '../../../../graphql/minimumAmount.graphql';
import { AboutEasyPay } from './AboutEasyPay';
import { EasyPayEnrollment } from './EasyPayEnrollment';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { t, Trans } from '@lingui/macro';
import { Form } from 'antd';
import ConfirmationModal from '../../../Modals/ConfirmationModal/ConfirmationModal';
import { GET_ACCOUNT_DETAILS } from '../../../../graphql/accountDetails.graphql';
import { determineAccountSubtype } from '../../../../store/onboarding/onboarding.utils';
import { getEasyPayMyWalletSchema } from '../../../../validators/onboarding/ezPay/ezPay.validator';
import { MainLoader } from '../../../Loader/MainLoader';
import LeaveSiteConfirmationModal from 'src/components/Modals/ConfirmationModal/LeaveSiteConfirmationModal';
import { useCallbackPrompt } from 'src/hooks/location/useCallbackPrompt';
import { useLocation } from 'react-router-dom';
import { REPLENISHMENT_AMOUNT } from '../../../../graphql/replenishment-amount.graphql';
import { useSortPaymentMethods } from 'src/hooks/domain/payments/useSortPaymentMethods';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import { useDashboardStore } from '../../../../store/dashboard/dashboard.store';
import { useScroll } from '../../../../hooks/screen/useScroll';
import { EASY_PAY_SECTION_ID } from '../../references';
import { checkIfElementExists } from '../../checkIfElementExists';
import { usePlans } from '../../MyDashboard/usePlans';
import { colors } from '../../../../theme/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import {
  IAccountSettingsSecondaryContacts,
  IAccountSettingsUpdateInput,
  UPDATE_ACCOUNT_SETTINGS,
} from '../../../../graphql/accountSettings.graphql';

export interface IEasyPayWallet {
  recurringPaymentAmount: string;
  replenishmentThresholdAmount: string;
  depositAmount: string;
  confirmDepositAmount: string;
  sunpassPlusParking: boolean | null;
  paymentMethodId: string;
}

export const EasyPay = () => {
  const [updateAccountSettings, { loading: updateAccountSettingsLoading }] = useMutation<
    { updateResult: boolean },
    { accountSettingsUpdateInput: IAccountSettingsUpdateInput }
  >(UPDATE_ACCOUNT_SETTINGS, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_ACCOUNT_DETAILS,
      },
    ],
  });

  const {
    setAddEasyPayMode,
    store: { addEasyPayMode },
  } = useDashboardStore();

  const { scrollToElem } = useScroll();

  const easyPaySectionExists = checkIfElementExists(EASY_PAY_SECTION_ID);

  useEffect(() => {
    if (easyPaySectionExists && addEasyPayMode) {
      scrollToElem(EASY_PAY_SECTION_ID, true);
      setAddEasyPayMode(false);
    }
  }, [addEasyPayMode, easyPaySectionExists]);

  const { sortedPaymentMethods, isPaymentMethodListLoading } = useSortPaymentMethods(true);
  const defaultPaymentMethod = sortedPaymentMethods?.[0];
  const { pathname } = useLocation();
  const { data, refetch } = useQuery(GET_ACCOUNT_DETAILS);
  const account = data?.accountDetails;
  const accountType = account?.accountType;
  const accountSubType = determineAccountSubtype(accountType);
  const minimumAmounts = useQuery(MINIMUM_AMOUNT)?.data?.minimumAmount;
  const minimumRecurringAmount =
    accountType !== AccountType.COMMERCIAL
      ? minimumAmounts?.minimumRecurringPersonal || 0
      : minimumAmounts?.minimumRecurringFleet || 0;

  const replenishmentAmounts = useQuery(REPLENISHMENT_AMOUNT)?.data?.replenishmentAmount;

  const replenishmentAmountFromAccount = account?.replenishAmount;

  const replenishmentAmount =
    accountType !== AccountType.COMMERCIAL ? replenishmentAmounts?.personal || 0 : replenishmentAmounts?.fleet || 0;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [showRedirectPrompt, setShowRedirectPrompt] = useState(false);

  const [isOptInEasyPay, setIsOptInEasyPay] = useState(defaultPaymentMethod?.isEasyPay);

  useEffect(() => {
    setIsOptInEasyPay(defaultPaymentMethod?.isEasyPay);
  }, [defaultPaymentMethod?.isEasyPay]);

  const { cancelRedirect, confirmRedirect, showPrompt } = useCallbackPrompt(showRedirectPrompt);

  const schema = getEasyPayMyWalletSchema(minimumRecurringAmount);

  const { PAYGAccount } = usePlans();
  const isPAYGAccount = accountType === AccountType.PRIVATE && PAYGAccount;

  const EASY_PAY_DEFAULT_VALUES = {
    recurringPaymentAmount: isOptInEasyPay ? Number(replenishmentAmountFromAccount).toFixed(2) : replenishmentAmount,
  };

  const methods = useForm<IEasyPayWallet>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: EASY_PAY_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { isDirty },
  } = methods;

  const resetValuesToDefault = () => {
    reset(EASY_PAY_DEFAULT_VALUES);
  };

  useEffect(() => {
    if (isDirty || isFormDirty) {
      setShowRedirectPrompt(true);
    } else {
      setShowRedirectPrompt(false);
    }
  }, [isDirty, isFormDirty]);

  const confirmRedirectUser = () => {
    resetValuesToDefault();
    confirmRedirect();
  };

  useEffect(() => {
    if (pathname === '/dashboard/wallet') {
      resetValuesToDefault();
    }
  }, [pathname]);

  useEffect(() => {
    reset(EASY_PAY_DEFAULT_VALUES);
  }, [sortedPaymentMethods, defaultPaymentMethod]);

  const recurringPaymentAmountWatch = watch('recurringPaymentAmount');

  const onSubmit = async () => {
    const secondaryContacts = account.secondaryContacts.map((contact: IAccountSettingsSecondaryContacts) => {
      return {
        firstName: contact.firstName,
        lastName: contact.lastName,
        middleName: contact.middleName,
      };
    });
    const mappedValues = {
      userName: account.username,
      addressLine1: account.addressLine1,
      addressLine2: account.addressLine2,
      city: account.city,
      state: account.state,
      country: account.country,
      county: account.county || '',
      emailAddress: account.emailAddress,
      secondaryEmailAddress: account.secondaryEmailAddress,
      cellPhone: account.cellPhone,
      homePhone: account.homePhone,
      workPhone: account.workPhone,
      secondaryContacts: secondaryContacts,
      zipCode: account.zipcode,
      faxPhone: account.faxPhone || '',
      replenishmentAmount: recurringPaymentAmountWatch,
    };
    await updateAccountSettings({
      variables: {
        accountSettingsUpdateInput: mappedValues,
      },
    }).catch(() => {
      setIsFormDirty(false);
      setShowRedirectPrompt(false);
      refetch();
    });
    await refetch();
    setShowRedirectPrompt(false);
  };

  useEffect(() => {
    if (replenishmentAmountFromAccount.toFixed(2).toString() !== recurringPaymentAmountWatch) {
      setIsFormDirty(true);
    } else {
      setIsFormDirty(false);
    }
  }, [recurringPaymentAmountWatch, replenishmentAmountFromAccount]);

  const onCancel = () => {
    setIsModalOpen(false);
    setIsOptInEasyPay(true);
  };

  useEffect(() => {
    setIsFormDirty(isDirty);
  }, [isDirty, watch('recurringPaymentAmount')]);

  return (
    <>
      <MainLoader loading={isPaymentMethodListLoading || updateAccountSettingsLoading} />
      <div className="mb-6 mt-10 h-full justify-between border-t border-gray-300 pt-10">
        <AboutEasyPay accountType={accountType} accountSubType={accountSubType} />
        <div className="easyPayContainer mt-5 flex flex-wrap">
          <FormProvider {...methods}>
            <Form layout={'vertical'} size={'large'} onFinish={handleSubmit(onSubmit)}>
              <div className="w-full sm:flex">
                <h3 className="mb-6 text-left text-3xl text-primary-blue1 sm:mr-8">
                  <Trans>Your Easy Pay Settings</Trans>
                </h3>
                <div className="mt-1 flex">
                  {isOptInEasyPay ? (
                    <div className="flex">
                      <p className="text-lg">{t`You're enrolled`}</p>
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        color={colors.primary.green1}
                        size="lg"
                        className="ml-2 mt-0.5 items-end"
                      />
                    </div>
                  ) : (
                    <div className="flex">
                      <p className="text-lg">{t`You're not enrolled`}</p>
                      <FontAwesomeIcon
                        icon={faCircleXmark}
                        color={colors.primary.red}
                        size="lg"
                        className="ml-2 mt-0.5 items-end"
                      />
                    </div>
                  )}
                </div>
                <ConfirmationModal
                  title={t`Opt out of Easy Pay and SunPass Plus parking`}
                  content={t`Confirm that you would like to opt out of Easy Pay and SunPass Plus parking. No more automatic payments will be made, and you will have to make payments on your account manually.`}
                  showModal={isModalOpen}
                  onOk={() => {
                    setIsModalOpen(false);
                    setIsOptInEasyPay(false);
                    resetValuesToDefault();
                    setValue('sunpassPlusParking', null);
                  }}
                  onCancel={onCancel}
                  closeType={false}
                  closable={false}
                  alignButtons="center"
                  alignText="center"
                ></ConfirmationModal>
                {isFormDirty ? (
                  <span className="text-s ml-auto mr-0 text-right text-primary-orange1">UNSAVED CHANGES</span>
                ) : null}
              </div>

              <EasyPayEnrollment
                accountType={accountType}
                isEasyPay={isOptInEasyPay}
                replenishmentAmount={isOptInEasyPay ? replenishmentAmountFromAccount : replenishmentAmount}
              />

              {isFormDirty && isOptInEasyPay ? (
                <CustomButton className={!isPAYGAccount ? 'mt-10' : 'mt-5'} content="Save Changes" type={'submit'} />
              ) : null}
            </Form>
          </FormProvider>
          <LeaveSiteConfirmationModal showModal={showPrompt} onOk={confirmRedirectUser} onCancel={cancelRedirect} />
        </div>
      </div>
    </>
  );
};

import { Trans } from '@lingui/macro';
import * as React from 'react';
import './CustomButton.scss';
interface ICustomButton {
  icon?: JSX.Element;
  content: string | JSX.Element;
  type?: 'submit' | 'reset' | 'button';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
  isPrimary?: boolean;
  reference?: React.LegacyRef<HTMLButtonElement>;
  tabIndex?: number;
  id?: string;
  disabled?: boolean;
}

export const CustomButton: React.FunctionComponent<ICustomButton> = ({
  icon,
  content,
  type = 'button',
  onClick,
  className,
  isPrimary = true,
  reference,
  tabIndex,
  id,
  disabled,
}) => {
  return (
    <button
      className={`${isPrimary ? 'custom-button_primary' : 'custom-button_secondary'} ${className} `}
      type={type}
      onClick={onClick}
      ref={reference}
      tabIndex={tabIndex}
      id={id}
      disabled={disabled}
    >
      {icon ? <span className="flex">{icon}</span> : null}
      <span className="custom-button-content text-base">
        <Trans>{content}</Trans>
      </span>
    </button>
  );
};

import { DependencyList, EffectCallback, useEffect } from 'react';

export const useEffectUnderCondition = (condition: boolean | string, effect: EffectCallback, deps?: DependencyList) => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    let effectsReturns: void | (() => void) = () => {};

    if (condition) effectsReturns = effect();

    if (effectsReturns && typeof effectsReturns === 'function') {
      return effectsReturns;
    }
  }, deps);
};

import { t, Trans } from '@lingui/macro';
import { Button } from 'antd';
import React, { FC } from 'react';
import { IContactInfo } from 'src/types/onboarding.types';
import { formatPhoneNumber } from 'src/utils/formatter';
interface IContactInformationData extends Partial<IContactInfo> {
  emailAddress?: string;
  customerName?: string;
}
interface IContactInformationProps {
  contactInfo: IContactInformationData;
  onEdit?: () => void;
}

const ContactInformation: FC<IContactInformationProps> = ({ contactInfo, onEdit }) => {
  const {
    cellPhone,
    homePhone,
    workPhone,
    lastName,
    firstName,
    emailAddress,
    customerName,
    email: contactEmail,
  } = contactInfo || {};

  const phoneToShow = cellPhone || homePhone || workPhone || '';
  const name = customerName ? customerName : `${firstName} ${lastName}`;
  const email = emailAddress ? emailAddress : contactEmail;

  return (
    <div>
      <div>
        <div className="title">
          <Trans>Contact Information</Trans>
        </div>
        {onEdit ? (
          <Button type="link" onClick={onEdit} className="theme" aria-label={t`Edit Contact Information`}>
            <span>
              <Trans>Edit</Trans>
            </span>
          </Button>
        ) : null}
      </div>
      <div>
        <div>{name}</div>
        <a style={{ wordBreak: 'break-word' }} href={email}>
          {email}
        </a>
        <div>{formatPhoneNumber(phoneToShow)}</div>
      </div>
    </div>
  );
};

export default ContactInformation;

import { AccountType } from '../../../types/onboarding.types';
import React from 'react';
import { useOnboardingStore } from '../../../store/onboarding/onboarding.store';
import { SELECT_DETAILS } from '../../../store/onboarding/onboarding.selectors';
import { InputPropsType } from '../../MyAccount/ContactInformation/Contact';
import { CompanyName } from '../../atoms/form/account/CompanyName';
import { OrganizationName } from '../../atoms/form/account/OrganizationName';
import { DoingBusinessAs } from '../../atoms/form/account/DoingBusinessAs';
import { PurchaseOrderNumber } from '../../atoms/form/account/PurchaseOrderNumber';
import { Fein } from '../../atoms/form/account/Fein';

export const FleetGovernmentAdditionalInfo = ({
  disabled,
  authorizedUserAccountType,
  isGovAccount,
}: InputPropsType) => {
  const { accountType } = useOnboardingStore(SELECT_DETAILS);

  return (
    <>
      <div className="flex flex-wrap">
        {accountType === AccountType.COMMERCIAL ||
        (authorizedUserAccountType === AccountType.COMMERCIAL && !isGovAccount) ? (
          <CompanyName disabled={disabled} />
        ) : accountType === AccountType.GOVERNMENT || isGovAccount ? (
          <OrganizationName disabled={disabled} />
        ) : null}
        {accountType === AccountType.COMMERCIAL ||
        accountType === AccountType.GOVERNMENT ||
        authorizedUserAccountType === AccountType.COMMERCIAL ? (
          <DoingBusinessAs disabled={disabled} />
        ) : null}
      </div>
      {accountType === AccountType.COMMERCIAL ||
      (authorizedUserAccountType === AccountType.COMMERCIAL && !isGovAccount) ? (
        <div className="flex flex-wrap">
          <Fein />
        </div>
      ) : accountType === AccountType.GOVERNMENT || isGovAccount ? (
        <div className="flex flex-wrap">
          <PurchaseOrderNumber />
        </div>
      ) : null}
    </>
  );
};

import { gql } from '@apollo/client';

export const MINIMUM_AMOUNT = gql`
  query minimumAmount {
    minimumAmount {
      minimumDepositPersonal
      minimumRecurringPersonal
      minimumDepositFleet
      minimumRecurringFleet
    }
  }
`;

export interface IStorage {
  clear(): void;

  getItem(key: string): string | null;

  setItem(key: string, data: string): void;
}

type LocalStorageItems = 'notifications';

class LocalStorageService {
  local: IStorage = localStorage;

  get(key: LocalStorageItems): any {
    const data = this.local.getItem(key);
    return data !== 'undefined' && data ? JSON.parse(data) : null;
  }

  set(key: LocalStorageItems, data: any): void {
    this.local.setItem(key, data);
  }

  clear(): void {
    this.local.clear();
  }
}

export const localStorageService = new LocalStorageService();

import create from 'zustand';
import { devtools } from 'zustand/middleware';

interface IsecurityCodeModalData {
  securityCode: string;
  showSecurityCodeModal: boolean;
  isNewPaymentMethod: boolean;
}

interface ISecurityCodeModalStore {
  securityCodeModalData: IsecurityCodeModalData;
  setSecurityCode: (securityCode: string) => void;
  setShowSecurityCodeModal: (showSecurityCodeModal: boolean) => void;
  setIsNewPaymentMethod: (isNewPaymentMethod: boolean) => void;
}

const securityCodeModalData = {
  securityCode: '',
  showSecurityCodeModal: false,
  isNewPaymentMethod: false,
};

export const useSecurityCodeModalStore = create<
  ISecurityCodeModalStore,
  [['zustand/devtools', ISecurityCodeModalStore]]
>(
  devtools((set) => ({
    securityCodeModalData,
    setSecurityCode: (securityCode: string) => {
      set(({ securityCodeModalData }) => ({ securityCodeModalData: { ...securityCodeModalData, securityCode } }));
    },
    setShowSecurityCodeModal: (showSecurityCodeModal: boolean) => {
      set(({ securityCodeModalData }) => ({
        securityCodeModalData: { ...securityCodeModalData, showSecurityCodeModal },
      }));
    },
    setIsNewPaymentMethod: (isNewPaymentMethod: boolean) => {
      set(({ securityCodeModalData }) => ({
        securityCodeModalData: { ...securityCodeModalData, isNewPaymentMethod },
      }));
    },
  })),
);

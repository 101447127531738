import { gql } from '@apollo/client';
import { IUserNames } from './signUp/signUpDetails.graphql';

export const INCREASE_BALANCE = gql`
  mutation increaseBalance($increaseBalanceInput: IncreaseBalanceInput!) {
    increaseBalance(increaseBalanceInput: $increaseBalanceInput) {
      authCode
      transactionId
    }
  }
`;

export interface IIncreaseBalanceInput extends IUserNames {
  paymentMethodId: string;
  transactionAmount: number;
  bankAccountNumber: string;
  bankRoutingNumber: string;
  cardNumber: string;
  expirationMonth: number;
  expirationYear: number;
  securityCode: string;
  addressLine1: string;
  middleName: string;
  addressLine2: string;
  city: string;
  country: string;
  state: string;
  zipCode: string;
  payType: string;
  reBillThresholdAmount: number;
  easyPayOption: string;
}

export interface IncreaseBalanceOutput {
  authCode: string;
  transactionId: string;
}

export enum EasyPayOption {
  NONE = 'NONE',
  EASY_PAY = 'EASY_PAY',
  STORED_CARD = 'STORED_CARD',
}

import { t } from '@lingui/macro';
import { Form, Input } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import { ActionType } from './ForgotUsernameOrPassword';

const Username = (actionType: any) => {
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext();

  return (
    <Form.Item
      label={t`Username`}
      className={`theme forgot-username-or-password-input ${
        actionType.actionType === ActionType.RESET_PASSWORD ? null : 'hidden'
      }`}
      help={errors.username?.message as React.ReactNode}
      hasFeedback
      validateStatus={errors.username?.message ? 'error' : ''}
    >
      <Controller
        name="username"
        control={control}
        render={({ field, fieldState }) => (
          <Input
            {...field}
            {...fieldState}
            id="username"
            key="username"
            maxLength={30}
            onChange={(value) => {
              field.onChange(value);
              trigger(['accountNumber']);
            }}
            aria-label={t`username`}
          />
        )}
      />
    </Form.Item>
  );
};

export default Username;

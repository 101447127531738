import { useQuery } from '@apollo/client';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_TRANSPONDERS_FETCH_PARAMS } from 'src/components/Dashboard/Transponders/TransponderList/defaultValues';
import { GET_TRANSPONDER_LIST, ITransponderInput, ITransponderList } from 'src/graphql/transponders.graphql';
import { DashboardParams, MainRoutes } from 'src/types/routes';
import InnerHeader from '../InnerHeader';
import TranspondersList from './TranspondersList';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import EmptyStateMessage, { IListType } from 'src/components/EmptyStates/EmptyStateMessage';

const Transponders: FC = () => {
  const navigate = useNavigate();

  const goTo = () => {
    navigate(`${MainRoutes.DASHBOARD}/${DashboardParams.TRANSPONDERS}`);
  };

  const { data } = useQuery<{ transponderList: ITransponderList }, { transponderListInput: ITransponderInput }>(
    GET_TRANSPONDER_LIST,
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
      variables: {
        transponderListInput: DEFAULT_TRANSPONDERS_FETCH_PARAMS,
      },
    },
  );
  const transpondersListLength = data?.transponderList.transponders.length || 0;

  return (
    <div className="w-full md:w-1/2">
      <div className="md:ml-3">
        <InnerHeader target={DashboardParams.TRANSPONDERS} />
      </div>
      {transpondersListLength > 0 ? (
        <TranspondersList />
      ) : (
        <div>
          <EmptyStateMessage listType={IListType.TRANSPONDERS} />
        </div>
      )}
      <div className="flex w-full justify-center">
        <CustomButton className="theme my-10 tracking-wide" content="Activate New Transponder" onClick={goTo} />
      </div>
    </div>
  );
};

export default Transponders;

import { ApolloQueryResult, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react';
import { DEFAULT_VEHICLES_FETCH_PARAM } from 'src/components/Dashboard/Transponders/TransponderList/defaultValues';
import { IVehicleFormSubmit } from 'src/components/Dashboard/VehicleView/VehiclesList/VehicleForm';
import {
  IVehicleAddInput,
  ADD_VEHICLE,
  IVehicleEditInput,
  EDIT_VEHICLE,
  IVehicleListInput,
  IVehicleList,
  IVehicle,
} from 'src/graphql/vehicles.graphql';
import { emptyVehicle, useVehicleStore } from 'src/store/vehicles/vehicle.store';
import { showMessage } from 'src/utils/message.utils';
import { useScroll } from '../screen/useScroll';
import { mapVehicleToAdd, mapVehicleToEdit } from './mappers';
import { GET_ACCOUNT_DETAILS, IAccountDetails } from '../../graphql/accountDetails.graphql';
interface IVehicleForm {
  refetch: (
    variables?: Partial<{ vehicleListInput: IVehicleListInput }> | undefined,
  ) => Promise<ApolloQueryResult<{ vehicleList: IVehicleList }>>;
}
export const useVehicleForm = ({ refetch }: IVehicleForm): IUseVehicleForm => {
  const {
    store: { vehicleToEdit, isFormVisible },
    setIsFormVisible,
    setVehicleToEdit,
    setIsVehicleListChanged,
    triggerVehicleListRefetch,
  } = useVehicleStore();

  const ref = useRef<HTMLFormElement>(null);

  const { data } = useQuery<{ accountDetails: IAccountDetails }>(GET_ACCOUNT_DETAILS);
  const accountType = data?.accountDetails.accountType;

  const [isEditMode, setIsEditMode] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  useEffect(() => {
    setIsEditMode(emptyVehicle !== vehicleToEdit);
  }, [vehicleToEdit]);

  useEffect(() => {
    scrollToElem(ref, true);
  }, []);

  const [addVehicle, { loading: addVehicleLoading }] = useMutation<IVehicleAddInput>(ADD_VEHICLE);

  const [editVehicle, { loading: editVehicleLoading }] = useMutation<{
    editVehicle: boolean;
    vehicleEditInput: IVehicleEditInput;
  }>(EDIT_VEHICLE);

  const { scrollToElem } = useScroll();

  const addMethod = async (values: IVehicleFormSubmit) => {
    const mappedVehicle = mapVehicleToAdd(values, accountType);
    try {
      await addVehicle({
        variables: {
          vehicleAddInput: mappedVehicle,
        },
      });
      refetch({ vehicleListInput: DEFAULT_VEHICLES_FETCH_PARAM });
      setIsVehicleListChanged(true);
      triggerVehicleListRefetch(false);
      closeForm();
      showMessage('success');
    } catch (error) {
      showMessage('error');
      console.error(error);
    }
  };

  const editMethod = async (values: IVehicleFormSubmit) => {
    const mappedVehicle = mapVehicleToEdit(values, vehicleToEdit?.vehicleId, accountType);
    try {
      await editVehicle({
        variables: { vehicleEditInput: mappedVehicle },
      });
      refetch({ vehicleListInput: DEFAULT_VEHICLES_FETCH_PARAM });
      setIsVehicleListChanged(true);
      triggerVehicleListRefetch(false);
      closeForm();
      showMessage('success');
    } catch (err) {
      showMessage('error');
      console.log((err as Error).message);
    }
  };

  const onSubmit = async (values: IVehicleFormSubmit) => {
    setShowErrorMessage(false);
    if (vehicleToEdit.vehicleId.length) {
      editMethod(values);
    } else {
      addMethod(values);
    }
  };
  const closeForm = async () => {
    await setVehicleToEdit(emptyVehicle);
    setIsFormVisible(false);
    scrollToElem(ref, true);
  };

  return {
    closeForm,
    loading: addVehicleLoading || editVehicleLoading,
    ref,
    isEditMode,
    vehicleToEdit,
    isFormVisible,
    setVehicleToEdit,
    setIsFormVisible,
    onSubmit,
    showErrorMessage,
  };
};

interface IUseVehicleForm {
  closeForm: () => void;
  onSubmit: (values: IVehicleFormSubmit) => void;
  setVehicleToEdit: (vehicle: IVehicle) => void;
  setIsFormVisible: (isFormVisible: boolean) => void;
  loading: boolean;
  isFormVisible: boolean;
  showErrorMessage: boolean;
  vehicleToEdit: IVehicle;
  isEditMode: boolean;
  ref: React.RefObject<HTMLFormElement>;
}

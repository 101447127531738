import React, { FC, useState } from 'react';
import { Form, Radio } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import { Trans } from '@lingui/macro';
import { IVehicleFormItemProps } from '../../../Onboarding/Vehicles/OnboardingVehiclesForm/OnboardingVehicleForm';
import { buildErrorString, getInputName } from '../../../Onboarding/Vehicles/OnboardingVehiclesForm/vehicleForm.utils';

interface IVehicleValues {
  vehicleYear: string | undefined;
  vehicleMake: string | undefined;
  vehicleModel: string | undefined;
  isTrailerInitial: boolean;
}

const IsTrailer: FC<IVehicleFormItemProps> = ({ field, index, name, isEditMode }) => {
  const [vehicleValues, setVehicleValues] = useState<IVehicleValues>({
    vehicleYear: undefined,
    vehicleMake: undefined,
    vehicleModel: undefined,
    isTrailerInitial: true,
  });

  const {
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="mb-4 w-full ">
      <Form.Item
        {...field}
        help={buildErrorString(name, errors, index)}
        label={<Trans id="Is this an attached trailer?" />}
        htmlFor={name}
        className="theme"
        required
      >
        <div className="mt-0">
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <Radio.Group {...field} id={name} disabled={isEditMode}>
                <Radio
                  value={true}
                  onChange={() => {
                    setVehicleValues({
                      vehicleYear: getValues(getInputName('vehicleYear', index)),
                      vehicleMake: getValues(getInputName('vehicleMake', index)),
                      vehicleModel: getValues(getInputName('vehicleModel', index)),
                      isTrailerInitial: false,
                    });
                    setValue(getInputName('vehicleYear', index), undefined);
                    setValue(getInputName('vehicleMake', index), undefined);
                    setValue(getInputName('vehicleModel', index), undefined);
                  }}
                >
                  <Trans id="Yes" />
                </Radio>
                <Radio
                  value={false}
                  onChange={() => {
                    if (!vehicleValues.isTrailerInitial) {
                      setValue(getInputName('vehicleYear', index), vehicleValues.vehicleYear);
                      setValue(getInputName('vehicleMake', index), vehicleValues.vehicleMake);
                      setValue(getInputName('vehicleModel', index), vehicleValues.vehicleModel);
                    } else {
                      setVehicleValues({
                        vehicleYear: getValues(getInputName('vehicleYear', index)),
                        vehicleMake: getValues(getInputName('vehicleMake', index)),
                        vehicleModel: getValues(getInputName('vehicleModel', index)),
                        isTrailerInitial: true,
                      });
                      setValue(getInputName('vehicleYear', index), null);
                      setValue(getInputName('vehicleMake', index), null);
                      setValue(getInputName('vehicleModel', index), null);
                    }
                  }}
                >
                  <Trans id="No" />
                </Radio>
              </Radio.Group>
            )}
          />
        </div>
      </Form.Item>
    </div>
  );
};

export default IsTrailer;

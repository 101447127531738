import { MinusCircleOutlined } from '@ant-design/icons';
import { Trans } from '@lingui/react';
import { Button, Collapse } from 'antd';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import useCountryOptions from 'src/hooks/domain/address/useCountryOptions';
import useStateOptions from 'src/hooks/domain/address/useStateOptions';
import useVehicleMakeOptions from 'src/hooks/domain/dashboard/vehicle/useVehicleMakeOptions';
import useVehicleModelOptions from 'src/hooks/domain/dashboard/vehicle/useVehicleModelOptions';
import ReloadButton from '../../ReloadButton/ReloadButton';
import OnboardingVehicleForm from './OnboardingVehiclesForm/OnboardingVehicleForm';
import { useScroll } from '../../../hooks/screen/useScroll';
import './VehiclesOnboarding.scss';

const { Panel } = Collapse;

interface IOnboardingVehicleProps {
  field: Record<any, string>;
  index: number;
  onRemove: (index: number) => void;
  removedIndex?: number;
  shouldPreventAnyVehicles: boolean;
  setShowLimitMessage: Dispatch<SetStateAction<boolean>>;
}

const OnboardingVehicle: FC<IOnboardingVehicleProps> = ({
  field,
  index,
  removedIndex,
  onRemove,
  shouldPreventAnyVehicles,
  setShowLimitMessage,
}) => {
  const { watch } = useFormContext();
  const country = watch(`vehicles[${index}].vehicleCountry`);
  const make = watch(`vehicles[${index}].vehicleMake`);
  const { errorLoading: errorLoadingCountries } = useCountryOptions();
  const { errorLoading: errorLoadingStates } = useStateOptions({ country });
  const { errorLoading: errorLoadingMakes } = useVehicleMakeOptions();
  const { errorLoading: errorLoadingModels } = useVehicleModelOptions(make);
  const errorLoading = errorLoadingCountries || errorLoadingStates || errorLoadingMakes || errorLoadingModels;
  const isExpandingDisabled = shouldPreventAnyVehicles ? ['0'] : ['1'];
  const [activeKeys, setActiveKeys] = useState<string | string[]>(isExpandingDisabled);

  const onChange = (keys: string | string[]) => {
    if (shouldPreventAnyVehicles) {
      setShowLimitMessage(true);
    } else {
      setActiveKeys(keys);
    }
  };

  const { scrollToElem } = useScroll();

  return (
    <>
      <Collapse
        bordered={false}
        activeKey={activeKeys}
        className="collapsible-container-with-margin"
        expandIconPosition="end"
        key={field.id}
        onChange={(keys) => onChange(keys)}
      >
        <Panel
          header={
            <>
              <Trans id="Vehicle #<0/> Information" components={[<>{index + 1}</>]} />
              {index !== 0 && (
                <Button
                  id={`vehicles.[${index}].button`}
                  type="link"
                  className="theme text-red ml-5"
                  style={{ top: '-5px' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    scrollToElem(`vehicles.[${index - 1}].header`);
                    onRemove(index);
                  }}
                  icon={<MinusCircleOutlined className="dynamic-delete-button" />}
                >
                  <span>
                    <Trans id="Remove vehicle" />
                  </span>
                </Button>
              )}
            </>
          }
          id={`vehicles.[${index}].header`}
          key={1}
          className="collapsible-container-panel"
        >
          {errorLoading ? (
            <div className="page-error-container">
              <div className="page-error-message">
                <Trans
                  id="Unable to load this form. Please reload the page and try again.<0/>We are sorry for the inconvenience."
                  components={[<br key={'new-line'} />]}
                />
              </div>
              <ReloadButton />
            </div>
          ) : (
            <OnboardingVehicleForm field={field} index={index} removedIndex={removedIndex} />
          )}
        </Panel>
      </Collapse>
      )
    </>
  );
};

export default OnboardingVehicle;

import { Trans } from '@lingui/macro';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { MainRoutes } from 'src/types/routes';
import { capitalizeInitials } from 'src/utils/string.utils';

interface IHeaderProps {
  target: string;
}

const InnerHeader: FC<IHeaderProps> = ({ target }) => {
  return (
    <div className="mb-8 ">
      <header className="flex items-end">
        <div className="text-3xl font-light tracking-tight">
          <Trans>Your {capitalizeInitials(target)}</Trans>
        </div>
        <Link
          to={`${MainRoutes.DASHBOARD}/${target}`}
          className={`dashboard-links relative mt-1 inline w-20 pb-px text-center text-base tracking-tight`}
        >
          <Trans>View All</Trans>
        </Link>
      </header>
    </div>
  );
};

export default InnerHeader;

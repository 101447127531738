import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';
import { Trans } from '@lingui/macro';
import React, { useMemo } from 'react';
import GreenSuccessIcon from '../assets/green-check-icon.png';
import { CloseCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import useErrorHandling from '../../../hooks/errors/useErrorHandling';
import { BulkUploadTemplateLink, BulkUploadType } from '../../Links/BulkUploadTemplateLink';
import { IVehiclesBulkUploadOutput } from '../../../graphql/vehicles.graphql';

interface IBulkUploadModal {
  showModal: boolean;
  onConfirm: () => void;
  processing: boolean;
  type: BulkUploadType;
  vehicleBulkUploadResult?: IVehiclesBulkUploadOutput;
}

interface IBulkUploadModalInfo {
  headline: string | JSX.Element;
  content: string | JSX.Element;
}

interface IModalScenarioContent {
  success: string | JSX.Element;
  error: string | JSX.Element;
  processing: string | JSX.Element;
  headline: string | JSX.Element;
}

const getModalContentByBulkUploadType = (
  type: BulkUploadType,
  numOfUploadedVehicles?: number,
): IModalScenarioContent => {
  switch (type) {
    case 'transponders':
      return {
        success: (
          <Trans>
            Good News! You have successfully updated the status of the transponders to your SunPass account - you’re
            ready to hit the road!
          </Trans>
        ),
        processing: (
          <Trans>
            This may take a few minutes. Please do not close this window or refresh your screen while the statuses of
            your transponders are being uploaded.
          </Trans>
        ),
        error: (
          <>
            <Trans>
              We encountered an error while attempting to process the file you uploaded. Please check to ensure that
              you’re using the correct.
            </Trans>
            <span> </span>
            <BulkUploadTemplateLink type={type} name={<Trans>Bulk Upload Template</Trans>} noIcon />
            <span> </span>
            <Trans>
              and that it’s properly formatted. <b>Max file size: 2MB. Accepted file types: csv, xls, xlsx.</b>
            </Trans>
          </>
        ),
        headline: (
          <Trans>
            <span className="font-extrabold">Transponder Bulk Upload Status:</span>
          </Trans>
        ),
      };
    case 'vehicles':
      return {
        success: (
          <Trans>
            Good News! You have successfully uploaded {numOfUploadedVehicles} vehicles to your SunPass account - you’re
            ready to hit the road!
          </Trans>
        ),
        processing: (
          <Trans>
            This may take a few minutes. Please do not close this window or refresh your screen while your vehicles are
            being uploaded.
          </Trans>
        ),
        error: (
          <>
            <Trans>
              We encountered an error while attempting to process the file you uploaded. Please check to ensure that
              you’re using the correct.
            </Trans>
            <span> </span>
            <BulkUploadTemplateLink type={type} name={<Trans>Bulk Upload Template</Trans>} noIcon />
            <span> </span>
            <Trans>
              and that it’s properly formatted. <b>Max file size: 2MB. Accepted file types: csv, xls, xlsx.</b>
            </Trans>
          </>
        ),
        headline: (
          <Trans>
            <span className="font-extrabold">Vehicle Bulk Upload Status:</span>
          </Trans>
        ),
      };
    default:
      throw new Error('Unrecognized bulk upload type: ${type}');
  }
};

export const BulkUploadModal = ({
  showModal,
  onConfirm,
  processing,
  type,
  vehicleBulkUploadResult,
}: IBulkUploadModal) => {
  const { isError, error } = useErrorHandling();
  const modalData = useMemo((): IBulkUploadModalInfo => {
    const content = getModalContentByBulkUploadType(type, vehicleBulkUploadResult?.addCount);
    if (isError) {
      return {
        content: (
          <>
            <CloseCircleFilled className="my-4" style={{ fontSize: '40px', display: 'block', color: '#DC0000' }} />
            {error && error.type === 'bulkUpload' ? error?.message : content.error}
          </>
        ),
        headline: (
          <>
            {content.headline}
            <Trans>
              <p className="m-0 font-normal">Failed</p>
            </Trans>
          </>
        ),
      };
    } else if (processing) {
      return {
        headline: (
          <>
            {content.headline}
            <p className="m-0 font-normal">
              <Trans>In Progress</Trans>
            </p>
          </>
        ),
        content: (
          <>
            <div>
              <Spin indicator={<LoadingOutlined className="my-4" style={{ fontSize: '40px' }} />} />
            </div>
            {content.processing}
          </>
        ),
      };
    } else {
      return {
        content: (
          <>
            <img src={GreenSuccessIcon} alt="success-icon" className="mx-auto mb-6 mt-0" />
            {content.success}
          </>
        ),
        headline: (
          <>
            {content.headline}
            <p className="m-0 font-normal">
              <Trans>Complete!</Trans>
            </p>
          </>
        ),
      };
    }
  }, [processing, isError, error]);

  return (
    <ConfirmationModal
      alignText="center"
      title={modalData.headline}
      content={modalData.content}
      showModal={showModal}
      onCancel={onConfirm}
      disableBtn={processing}
      cancelButtonBlue
      cancelOnly
      closeType
      modifyHeader
    />
  );
};

import { Form } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import ConfirmationModal from 'src/components/Modals/ConfirmationModal/ConfirmationModal';
import { yupResolver } from '@hookform/resolvers/yup';
import { t, Trans } from '@lingui/macro';
import { useLazyQuery, useMutation } from '@apollo/client';
import { APPLY_PROMO_CODE, IApplyPromoCodeInput } from 'src/graphql/applyPromoCodeAccount.graphql';
import { showMessage } from 'src/utils/message.utils';
import { MainLoader } from 'src/components/Loader/MainLoader';
import CouponCode from '../../../atoms/form/oneTImePurchase/CouponCode';
import { IVerifyPromotionCodeOutput, VERIFY_PROMO_CODE } from '../../../../graphql/verifyPromotionCode.graphql';
interface IPromoCodeModal {
  isPromoModalVisible: boolean;
  setIsPromoModalVisible: (isPromoModalVisible: boolean) => void;
}
export interface IPromoCodeForm {
  couponCode: string;
}
export const PromoCodeModal: FC<IPromoCodeModal> = ({ isPromoModalVisible, setIsPromoModalVisible }) => {
  const [isErrorMessageShown, setIsErrorMessageShown] = useState(false);
  const [isPromoValid, setIsPromoValid] = useState(false);
  const [isPromoApplied, setIsPromoApplied] = useState(false);
  const [enteredPromoCode, setEnteredPromoCode] = useState('');

  const methods = useForm<IPromoCodeForm>({
    resolver: yupResolver(yup.object().shape({ couponCode: yup.string().required('Please provide promo code') })),
    mode: 'onChange',
    shouldFocusError: false,
  });

  const { handleSubmit, reset, watch } = methods;

  const [applyPromoCode, { loading: isApplyingPromoCode }] = useMutation<{ applyPromoCodeInput: IApplyPromoCodeInput }>(
    APPLY_PROMO_CODE,
  );

  const [verifyPromoCode, { loading: verifyPromoCodeLoading }] = useLazyQuery<
    { verifyPromotionCode: IVerifyPromotionCodeOutput },
    { couponCode: string }
  >(VERIFY_PROMO_CODE);

  useEffect(() => {
    return () => {
      setIsErrorMessageShown(false);
    };
  }, []);

  const modalContent = (
    <>
      <span className="w-48">
        <Trans>
          {' '}
          If your account is eligible for a toll credit, enter the appropriate code below and select <br />
          &apos;Apply Code&apos;
        </Trans>
      </span>
      <div className="flex justify-center">
        <div className="mt-4 w-48">
          <FormProvider {...methods}>
            <CouponCode isPromoValid={isPromoValid} isPromoCodeApplied={isPromoApplied} />
          </FormProvider>
          {isErrorMessageShown ? (
            <div className="text-red text-left">
              <Trans>Invalid promo code</Trans>
            </div>
          ) : null}
        </div>
      </div>{' '}
    </>
  );

  const onCancel = () => {
    setIsPromoModalVisible(false);
    setIsPromoApplied(false);
    reset();
  };

  useEffect(() => {
    setIsPromoApplied(false);
    setIsErrorMessageShown(false);
  }, [watch('couponCode')]);

  const applyCode = async (couponCode: string) => {
    try {
      await applyPromoCode({ variables: { couponCode: couponCode } });
      showMessage(
        'success',
        t`Your credit was applied successfully and your account balance will be adjusted accordingly`,
      );
      setIsPromoModalVisible(false);
      reset();
    } catch (err) {
      console.error(err);
      setIsErrorMessageShown(true);
    }
  };

  const applyingPromoCodeCompletedUnsuccessfully = () => {
    setIsPromoApplied(true);
    setIsPromoValid(false);
    setEnteredPromoCode('');
  };

  const applyingPromoCodeCompletedSuccessfully = (data: IVerifyPromotionCodeOutput | undefined) => {
    setIsPromoApplied(true);
    if (data?.couponStatus === 'VALID') {
      setIsPromoValid(true);
      applyCode(enteredPromoCode);
      setIsErrorMessageShown(false);
    } else {
      setIsPromoValid(false);
      setIsErrorMessageShown(true);
    }
  };

  const onSuccess = (responseData: any) => {
    if (responseData.error) {
      applyingPromoCodeCompletedUnsuccessfully();
    } else {
      applyingPromoCodeCompletedSuccessfully(responseData.verifyPromotionCode);
    }
  };

  const verifyCode = async (couponCode: string) => {
    try {
      await verifyPromoCode({
        variables: {
          couponCode: couponCode,
        },
        onCompleted: onSuccess,
      });
    } catch (err) {
      showMessage('error', t`Invalid promo code`);
      setIsPromoValid(false);
      setIsErrorMessageShown(true);
      console.log((err as Error).message);
    }
  };

  const onSubmit = async (values: IPromoCodeForm) => {
    const { couponCode } = values;
    setEnteredPromoCode(couponCode);
    await verifyCode(couponCode);
  };

  return (
    <Form>
      <MainLoader loading={isApplyingPromoCode || verifyPromoCodeLoading} />
      <ConfirmationModal
        title="Add Toll Credit"
        content={modalContent}
        showModal={isPromoModalVisible}
        alignText="center"
        okButtonText="Apply Code"
        onCancel={onCancel}
        onOk={handleSubmit(onSubmit)}
      />
    </Form>
  );
};

export const BALANCE_SECTION_ID = 'balance-title';
export const PAYMENT_METHODS_SECTION_ID = 'payment-methods-title';
export const EASY_PAY_SECTION_ID = 'easy-pay-title';
export const SELECT_PAYMENT_METHOD_SECTION_ID = 'select-payment-method-title';
export const ADD_FUNDS_SECTION_ID = 'add-funds-title';
export const ADD_FUNDS_MY_WALLET_SECTION_ID = 'add-funds-my-wallet';

export const ACTIVATE_NEW_TRANSPONDER_SECTION_ID = 'activate-new-transponder-title';
export const PURCHASE_TRANSPONDER_SECTION_ID = 'purchase-transponder-title';
export const TRANSPONDERS_TOP = 'transponders-top';
export const TRANSPONDERS_HEADER = 'transponders-header';
export const TRANSPONDERS_LIST_HEADER = 'transponders-list-header';
export const PURCHASE_TRANSPONDER_QUANTITY_ID = 'purchaseTranspondersQty';

export const VEHICLES_SECTION = 'vehicle-title';
export const PARKING_SECTION_ID = 'parking-title';
export const ACTIVITY_HEADER = 'activity-header';
export const VEHICLE_HEADER = 'vehicle-header';

export const UNPAID_TOLLS_HEADER = 'unpaid-tolls-header';
export const PAY_TOLLS_SUMMARY = 'pay-tolls-summary';

export const ADDRESS_VALIDATION_GENERAL_ERROR = 'address-validation-general-error-msg';

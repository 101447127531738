import React, { useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import '../MyAccount.scss';
import useScreenSize from 'src/hooks/screen/useScreenSize';
import ScrollToTop from '../../ScrollToTop/ScrollToTop';
import BackLink from '../BackLink';
import { Form } from 'antd';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ACCOUNT_DETAILS, IAccountDetails } from '../../../graphql/accountDetails.graphql';
import MonthlyStatement from '../../Inputs/MonthlyStatement';
import Language from '../../Inputs/Language';
import MessageNotifications from '../../Inputs/MessageNotifications';
import './NotificationsSection.scss';
import { IAccountSettingsUpdateInput, UPDATE_ACCOUNT_SETTINGS } from '../../../graphql/accountSettings.graphql';
import CollapsableContainer from '../../CollapsableContainer/CollapsableContainer';
import ConfirmationModal from '../../Modals/ConfirmationModal/ConfirmationModal';
import { useScroll } from '../../../hooks/screen/useScroll';
import { generateNotificationsSettingsDefaultValues, NOTIFICATIONS_SETTINGS_DEFAULT_VALUES } from './initialState';
import { showMessage } from 'src/utils/message.utils';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import ScreenSize from 'src/types/ScreenSize.enum';
import { useAccountSettings } from 'src/store/account/accountSettings.store';
import { getChangedProperties } from '../../../utils/object.utils';
import { MainLoader } from '../../Loader/MainLoader';
import { getNotificationSettingsSchema } from '../../../validators/AccountSettings/notificationSettings.validator';

export interface INotificationsSettings {
  smsOption?: boolean;
  language?: string;
  deliveryModeType: string;
}

export default function Notifications(): React.ReactElement {
  const { screenSize } = useScreenSize();
  const { data: accountData, loading: accountLoading } = useQuery<{ accountDetails: IAccountDetails }>(
    GET_ACCOUNT_DETAILS,
  );

  const smsOption = accountData?.accountDetails.smsOption;
  const { setIsTabDirty } = useAccountSettings();
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [notificationsSettingsCollapsed, setNotificationsSettingsCollapsed] = useState<boolean>(false);
  const [shownErrorModal, setShownErrorModal] = useState<boolean>(true);

  const isPhoneNumberEmpty =
    accountData?.accountDetails.cellPhone === undefined ||
    accountData?.accountDetails.cellPhone === null ||
    accountData?.accountDetails.cellPhone === '';

  const methods = useForm<INotificationsSettings>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(getNotificationSettingsSchema(isPhoneNumberEmpty)),
    defaultValues: accountData
      ? generateNotificationsSettingsDefaultValues(accountData?.accountDetails)
      : NOTIFICATIONS_SETTINGS_DEFAULT_VALUES,
  });

  const {
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { isDirty, errors },
  } = methods;

  const { scrollToError } = useScroll();

  useEffect(() => {
    scrollToError(errors);
  }, [errors]);

  useEffect(() => {
    isDirty ? setIsTabDirty(true) : setIsTabDirty(false);
  }, [isDirty]);

  useEffect(() => {
    setShownErrorModal(accountLoading);
  }, [accountLoading]);

  const [updateAccountSettings, { loading }] = useMutation<
    { updateResult: boolean },
    { accountSettingsUpdateInput: IAccountSettingsUpdateInput }
  >(UPDATE_ACCOUNT_SETTINGS, {
    refetchQueries: [
      {
        query: GET_ACCOUNT_DETAILS,
      },
    ],
  });

  const onSubmit = async (formData: INotificationsSettings) => {
    try {
      await updateAccountSettings({
        variables: {
          accountSettingsUpdateInput: getChangedProperties<INotificationsSettings>(
            generateNotificationsSettingsDefaultValues(accountData?.accountDetails),
            formData,
          ),
        },
      });
      setIsSubmitError(false);
      reset();
      showMessage('success');
    } catch (err) {
      setIsSubmitError(true);
      showMessage('error');
      console.log(err);
    }
  };

  const resetValuesToDefault = () => {
    reset(
      accountData
        ? generateNotificationsSettingsDefaultValues(accountData?.accountDetails)
        : NOTIFICATIONS_SETTINGS_DEFAULT_VALUES,
    );
    setValue('smsOption', !!smsOption ? smsOption : NOTIFICATIONS_SETTINGS_DEFAULT_VALUES.smsOption);
  };

  useEffect(() => {
    resetValuesToDefault();
  }, [accountData]);

  return (
    <div className="w-full pt-5 large-tablet:pt-0">
      <MainLoader loading={loading} />
      <ScrollToTop />
      <ConfirmationModal
        title={t`Try Again Later`}
        content={t`Cannot load data.`}
        showModal={shownErrorModal}
        onOk={() => setShownErrorModal(false)}
        closeType
        alignButtons="center"
        alignText="center"
      />
      {screenSize < ScreenSize.XL ? (
        <div className="mb-5 ml-5">
          <BackLink />
        </div>
      ) : null}
      <Form className="theme" layout="vertical" size="large" onFinish={handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <CollapsableContainer
            title={t`Notifications`}
            onChange={(panels: any) => setNotificationsSettingsCollapsed(!panels[0])}
            activeKey={notificationsSettingsCollapsed ? [] : ['1']}
            noTopMargin
            unsavedChanges={isDirty || watch('smsOption') !== smsOption}
            responsive
          >
            <div className="flex flex-col">
              <MonthlyStatement label={<Trans>How would you like to receive your monthly statement?</Trans>} />
              <Language label={<Trans>What is your preferred language?</Trans>} style="language-input" />
              <MessageNotifications label={<Trans>Would you like to opt into text message notifications?</Trans>} />
            </div>
          </CollapsableContainer>
          <div className="mt-4 text-center  ">
            {isDirty || !(watch('smsOption') === smsOption && !isSubmitError) ? (
              <CustomButton content="Save Changes" type="submit" className="mx-auto mt-10" />
            ) : null}
          </div>
        </FormProvider>
      </Form>
    </div>
  );
}

import { LabelValue } from '../../../../types/LabelValue.interface';
import useVehicleModels from './useVehicleModels';
import { IVehicleModel } from '../../../../graphql/vehicleMakes.graphql';

interface IUseVehicleModelOptions {
  vehicleModelOptions: LabelValue[];
  errorLoading: boolean;
  loading: boolean;
}

const useVehicleModelOptions = (vehicleMakeName: string): IUseVehicleModelOptions => {
  const { vehicleModels, errorLoading, loading } = useVehicleModels(vehicleMakeName);
  return {
    vehicleModelOptions:
      vehicleModels?.map(
        (model: IVehicleModel): LabelValue => ({
          value: model.name,
          label: model.name,
        }),
      ) || [],
    errorLoading: errorLoading,
    loading,
  };
};

export default useVehicleModelOptions;

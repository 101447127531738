import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate } from 'react-router';
import { colors } from 'src/theme/theme';
import { useDashboardStore } from '../../store/dashboard/dashboard.store';
import { ActionItemAndIconLinkCardId } from '../Dashboard/ActionItemBanner/ActionItemAndIconLinkCardId';

interface ILargeIconLinkProps {
  title: string;
  icon: IconProp;
  link: string;
  classNames?: string;
  id: string;
}

export default function IconLinkCard({ id, title, icon, link, classNames }: ILargeIconLinkProps): React.ReactElement {
  const history = useNavigate();
  const { setPurchaseTransponderMode, setAddPaymentMethodMode, setActivateTransponderMode } = useDashboardStore();
  return (
    <button
      className={`mr-6 flex h-16 cursor-pointer items-center justify-between rounded-lg bg-primary-white px-6 shadow-lg hover:border-2 hover:border-black hover:underline sm:mr-10 ${classNames}`}
      onClick={() => {
        history(link);
        switch (id) {
          case ActionItemAndIconLinkCardId.ADD_PAYMENT_METHOD:
            setAddPaymentMethodMode(true);
            break;
          case ActionItemAndIconLinkCardId.ACTIVATE_TRANSPONDER:
            setActivateTransponderMode(true);
            break;
          case ActionItemAndIconLinkCardId.PURCHASE_TRANSPONDER:
            setPurchaseTransponderMode(true);
            break;
        }
      }}
    >
      <div className="flex items-center">
        <FontAwesomeIcon icon={icon} color={colors.primary.green1} size="1x" className="w-8" />
        <div className="ml-1 mt-1 text-lg">{title}</div>
      </div>
      <FontAwesomeIcon icon={faArrowRight} color={colors.primary.green1} className="items-end self-center" />
    </button>
  );
}

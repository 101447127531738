import { FC } from 'react';
import { Form } from 'antd';
import { Trans } from '@lingui/macro';
import { Controller, FieldValues, SubmitHandler, useFormContext } from 'react-hook-form';
import StyledInput from '../StyledInput/StyledInput';
import VerifiedIcon from '../VerifiedIcon/VerifiedIcon';
import {
  AT_LEAST_ONE_SPECIAL_CHAR,
  AT_LEAST_ONE_UPPER_ONE_LOWER_ONE_NUMBER,
  ONLY_VALID_CHARACTERS,
} from 'src/validators/regex';
import { CustomButton } from '../CustomButton/CustomButton';
import { IForgotPasswordForm } from './ForgotUsernameOrPassword';
import { Response } from './Response';

interface IRetrievePassword {
  changePassword: SubmitHandler<FieldValues>;
  securityQuestion: string | undefined;
}

const RetrievePassword: FC<IRetrievePassword> = ({ changePassword, securityQuestion }) => {
  const {
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext<IForgotPasswordForm>();
  const passwordWatch = watch('password');

  return (
    <>
      <p className="mt-6">
        <Trans>
          Thank you, please answer the security question below and then enter your new password. If you answer the
          security question accurately and provide a valid password, your password will be reset accordingly.
        </Trans>
      </p>
      <p>
        <span className="font-bold">
          <Trans>Questions: </Trans>{' '}
        </span>
        <span className="italic">
          <Trans>{securityQuestion}</Trans>
        </span>
      </p>
      <Response />
      <div className="flex flex-wrap gap-0">
        <div className="w-full sm:w-1/2">
          <Form.Item
            label={<Trans>Password</Trans>}
            className="theme w-full sm:w-3/4"
            help={errors.password?.message as React.ReactNode}
            validateTrigger={['onChange', 'onBlur']}
          >
            <Controller
              name="password"
              control={control}
              render={({ field, fieldState }) => (
                <StyledInput
                  field={field}
                  fieldstate={fieldState}
                  id="password"
                  key="password"
                  type={'password'}
                  maxLength={64}
                />
              )}
            />
          </Form.Item>
        </div>
        <div className="w-full sm:w-1/2">
          <div className="onboarding-help-box">
            <b>
              <Trans>Passwords must have</Trans>
            </b>
            <ul className="list-none">
              <li>
                <VerifiedIcon isVerified={(watch('password') || '').length >= 8} />
                <span className="pl-2">
                  <Trans>8 to 64 characters</Trans>
                </span>
              </li>
              <li>
                <VerifiedIcon isVerified={AT_LEAST_ONE_UPPER_ONE_LOWER_ONE_NUMBER.test(passwordWatch || '')} />
                <span className="pl-2">
                  <Trans>At least one uppercase, one lowercase letter, and one number</Trans>
                </span>
              </li>
              <li>
                <VerifiedIcon
                  isVerified={
                    AT_LEAST_ONE_SPECIAL_CHAR.test(passwordWatch || '') &&
                    ONLY_VALID_CHARACTERS.test(passwordWatch || '')
                  }
                />
                <span className="pl-2">
                  <Trans>At least one of the following special characters: ! @ # $ % * ( ) - _ + = ~ ; , .</Trans>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="w-full sm:w-6/12">
        <Form.Item
          label={<Trans>Re-Enter Password</Trans>}
          className="theme w-full sm:w-3/4"
          help={errors.reTypePassword?.message as React.ReactNode}
          validateTrigger={['onChange', 'onBlur']}
        >
          <Controller
            name="reTypePassword"
            control={control}
            render={({ field, fieldState }) => (
              <StyledInput
                field={field}
                fieldstate={fieldState}
                onPaste={(e) => e.preventDefault()}
                id="reTypePassword"
                key="reTypePassword"
                type={'password'}
                maxLength={64}
              />
            )}
          />
        </Form.Item>
      </div>
      <div className="forgot-username-or-password-buttons-container">
        <CustomButton
          className="button-primary mt-6"
          onClick={handleSubmit(changePassword)}
          content={<Trans>Submit</Trans>}
        />
      </div>
    </>
  );
};

export default RetrievePassword;

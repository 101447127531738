import React, { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Breadcrumb, Form, message } from 'antd';
import { Link } from 'react-router-dom';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import { FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import RetrievePassword from './RetrievePassword';
import { CustomButton } from '../CustomButton/CustomButton';
import { MainLoader } from '../Loader/MainLoader';
import { useForgotPassword } from '../../hooks/retriveUsernamePassword/useForgotPassword';
import { getForgotCredentialsSchemaBy } from './ForgotUsernameOrPassword.schema';
import AccountNumber from './AccountNumber';
import Username from './Username';
import PostalCode from './PostalCode';
import TransponderNumber from './TransponderNumber';
import ActionTypeSelect from './ActionType';
import { getMessage } from './ForgotUsernameOrPassword.util';
import { useRetrieveUsername } from 'src/hooks/retriveUsernamePassword/useRetrieveUsername';
import { MainRoutes } from '../../types/routes';

export interface IAnswerForConfirmationForm {
  answer: string;
}

export interface IForgotPasswordForm extends IAnswerForConfirmationForm {
  password: string;
  reTypePassword: string;
}
export interface IForgotUsernameOrPasswordForm extends IForgotPasswordForm {
  actionType: ActionType;
  postalCode: string;
  accountNumber: string;
  transponderNumber: string;
  username: string;
}

export enum ActionType {
  RESET_PASSWORD = 'resetPassword',
  RETRIEVE_USERNAME = 'retrieveUsername',
}

const ForgetUsernameOrPassword = () => {
  const [actionType, setActionType] = useState<ActionType>(ActionType.RETRIEVE_USERNAME);
  const [showSecurityQuestion, setShowSecurityQuestion] = useState(false);
  const [showError, setShowError] = useState(false);

  const {
    forgotPasswordGetSecurityQuestionData,
    updatePassword,
    updatePasswordResetError,
    forgotPasswordGetSecurityQuestionLoading,
    updatePasswordLoading,
    forgotPasswordGetSecurityQuestionError,
    updatePasswordError,
    getSecurityQuestion,
  } = useForgotPassword();

  const methods = useForm<IForgotUsernameOrPasswordForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      getForgotCredentialsSchemaBy(actionType, showSecurityQuestion, forgotPasswordGetSecurityQuestionError),
    ),
    defaultValues: {
      actionType: ActionType.RETRIEVE_USERNAME,
      answer: '',
      password: '',
      reTypePassword: '',
    },
  });

  const { handleSubmit, reset, clearErrors, watch, setValue } = methods;

  const watchActionType = watch('actionType');

  const onForgottenPassword = () => {
    setValue('actionType', ActionType.RESET_PASSWORD);
    setActionType(ActionType.RESET_PASSWORD);
    clearErrors();
  };
  const clearForm = () => {
    resetRetrievingUsername();
    setShowSecurityQuestion(false);
    reset({ actionType });
    updatePasswordResetError();
    setShowError(false);
  };
  const toggleActionType = () => {
    setShowSecurityQuestion(false);
    resetRetrievingUsername();
    clearErrors();
    setShowError(false);
    setActionType((prevState) => {
      return prevState === ActionType.RESET_PASSWORD ? ActionType.RETRIEVE_USERNAME : ActionType.RESET_PASSWORD;
    });
  };

  const {
    retrieveUsername,
    onFoundUsername,
    generateErrorMessage,
    resetRetrievingUsername,
    loading: retrieveUsernameLoading,
  } = useRetrieveUsername(onForgottenPassword);

  const retrieve = (values: IForgotUsernameOrPasswordForm) => {
    switch (actionType) {
      case 'resetPassword':
        retrievePassword(values);
        break;
      case 'retrieveUsername':
        retrieveUsername(values);
        break;
      default:
        return;
    }
  };

  const retrievePassword = async (values: IForgotUsernameOrPasswordForm) => {
    const { accountNumber, username, postalCode } = values;

    let forgotPasswordGetSecurityQuestionInput;
    if (accountNumber) {
      forgotPasswordGetSecurityQuestionInput = {
        zipcode: postalCode,
        accountNumber: accountNumber,
      };
    } else {
      forgotPasswordGetSecurityQuestionInput = {
        zipcode: postalCode,
        username: username,
      };
    }

    try {
      await getSecurityQuestion({
        variables: {
          forgotPasswordGetSecurityQuestionInput: forgotPasswordGetSecurityQuestionInput,
        },
      });
      setShowError(true);
      setShowSecurityQuestion(true);
    } catch (err) {
      console.log((err as Error).message);
    }
  };

  const changePassword = async (values: IForgotUsernameOrPasswordForm) => {
    try {
      await updatePassword({
        variables: {
          forgotPasswordUpdateInput: {
            newPassword: values.password,
            securityAnswer: values.answer,
            zipcode: values.postalCode,
            accountNumber: values.accountNumber,
            username: values.username,
          },
        },
      });
      setShowSecurityQuestion(false);
      scroll(0, 0);
      message.success({
        content: t`Your password have been successfully changed.`,
        type: 'success',
        duration: 6,
      });
    } catch (err) {
      console.log((err as Error).message);
    }
  };

  return (
    <>
      <div className="forgot-username-or-password-container h-full bg-white">
        <ScrollToTop />
        <MainLoader
          loading={forgotPasswordGetSecurityQuestionLoading || updatePasswordLoading || retrieveUsernameLoading}
        />
        <div className="breadcrumb">
          <Breadcrumb separator="/">
            <Breadcrumb.Item className="underline">
              <Link to={MainRoutes.ROOT}>
                <Trans>Home</Trans>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item className="underline">
              <Trans>Forgot Username or Password</Trans>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <h1 className="m-auto w-full px-2 py-8 pb-0 text-center font-light md:w-2/3">
          <Trans>Forgot Username or Password</Trans>
        </h1>
        <p className="mx-auto w-3/4 max-w-xl py-4 text-center">
          <Trans>
            If you need help retrieving your username or resetting your password, please follow the steps below. If you
            have a SunPass account you created over the phone or in person, but have never accessed your account online,{' '}
            <Link to={MainRoutes.ACCESS_ONLINE_ACCOUNT} className="green-link">
              select here
            </Link>
            .
          </Trans>
        </p>
        <div className="forgot-username-or-password-form">
          <FormProvider {...methods}>
            <Form>
              <div className="mb-6">
                <ActionTypeSelect toggleActionType={toggleActionType} />
              </div>
              {getMessage(actionType)}
              <div className="forgot-username-or-password-inputs-container">
                <div className="w-full sm:w-1/3">
                  <PostalCode />
                </div>
              </div>
              <p>
                <Trans>AND</Trans>
              </p>

              <div className="flex flex-col items-center gap-4 sm:flex-row sm:items-stretch">
                <div className="w-full sm:w-1/3">
                  <AccountNumber />
                </div>

                <div className="sm:mt-10">
                  - <Trans>OR</Trans> -
                </div>

                <div className="w-full sm:w-1/3">
                  <Username actionType={watchActionType} />
                  <TransponderNumber actionType={watchActionType} />
                </div>
              </div>

              <div className="my-4 mt-8 flex items-center gap-8">
                {' '}
                <button type="button" className="dashboard-links mt-1 inline text-base" onClick={clearForm}>
                  <Trans>Clear</Trans>
                </button>
                <CustomButton className="button-primary" onClick={handleSubmit(retrieve)} content={t`Retrieve`} />
              </div>
            </Form>
            {forgotPasswordGetSecurityQuestionError && showError ? (
              <p className="text-red mt-6 text-center">
                <Trans>
                  We were unable to validate your account. Please make sure you&apos;ve properly entered your postal
                  code AND either your account number or your username.
                </Trans>
              </p>
            ) : null}

            {showSecurityQuestion && !forgotPasswordGetSecurityQuestionError ? (
              <RetrievePassword
                changePassword={changePassword as SubmitHandler<FieldValues>}
                securityQuestion={
                  forgotPasswordGetSecurityQuestionData?.forgotPasswordGetSecurityQuestion?.securityQuestion
                }
              />
            ) : null}
            {onFoundUsername()}

            {updatePasswordError ? (
              <p className="text-red mt-6 text-center">
                <Trans>
                  We were unable to validate your account. Please check your response to your security question.
                </Trans>
              </p>
            ) : null}
            {generateErrorMessage()}
          </FormProvider>
        </div>
      </div>
    </>
  );
};

export default ForgetUsernameOrPassword;

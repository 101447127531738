import { t, Trans } from '@lingui/macro';
import { Button, Space } from 'antd';
import React, { FC } from 'react';
import { formatPhoneNumber } from 'src/utils/formatter';
import { IContactInfo } from 'src/types/onboarding.types';

interface IContactInformationProps {
  contactInfo: IContactInfo;
  onEdit: () => void;
}

const ContactInformation: FC<IContactInformationProps> = ({ contactInfo, onEdit }) => {
  const { firstName, lastName, email, cellPhone, homePhone, workPhone, organizationName } = contactInfo;
  const phoneToShow = cellPhone || homePhone || workPhone || '';
  return (
    <Space direction="vertical" size="small">
      <Space align="start" size="middle">
        <div className="title">
          <Trans>Contact Information</Trans>
        </div>
        <Button type="link" onClick={onEdit} className="theme" aria-label={t`Edit Contact Information`}>
          <span>
            <Trans>Edit</Trans>
          </span>
        </Button>
      </Space>
      <div>
        <div>{`${firstName} ${lastName}`}</div>
        <div className="break-all">{organizationName}</div>
        <a style={{ wordBreak: 'break-word' }} href={`mailto:${email}`}>
          {email}
        </a>
        <div>{formatPhoneNumber(phoneToShow)}</div>
      </div>
    </Space>
  );
};

export default ContactInformation;

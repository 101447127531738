import { IPaymentMethodGraphQl } from '../../../../graphql/paymentMethods.graphql';

export const sortPaymentMethods = (
  paymentMethodList: IPaymentMethodGraphQl[] | undefined,
  allowBankPayment: boolean,
): IPaymentMethodGraphQl[] | undefined => {
  if (paymentMethodList) {
    if (!allowBankPayment) {
      return sortByPrimary([...paymentMethodList].filter((payment) => !payment.isBankAccount));
    } else return sortByPrimary(paymentMethodList);
  } else return paymentMethodList;
};

const sortByPrimary = (list: IPaymentMethodGraphQl[]) =>
  [...list].sort(({ isPrimary: aPrimary }, { isPrimary: bPrimary }) => (aPrimary === bPrimary ? 0 : aPrimary ? -1 : 1));

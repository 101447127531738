import { gql } from '@apollo/client';
import { IVehicleSignUpInput } from './signUpVehiclesTransponders.graphql';
import { IPaymentDetailsSignUpInput, IPlanSignUpInput } from './signUpPlans.graphql';
import { IAdditionalEmailAddressInput, ISecondaryContactInput, IUserNames } from './signUpDetails.graphql';

export const SIGN_UP_TRANSPONDER_ACTIVATION = gql`
  mutation signUpTransponderActivation($signUpTransponderActivationInput: SignUpTransponderActivationInput!) {
    signUpTransponderActivation(signUpTransponderActivationInput: $signUpTransponderActivationInput) {
      accountNumber
    }
  }
`;

export interface ISignUpTransponderActivationInput {
  isAnonymous?: boolean;
  accountDetailsSignUp?: IAccountDetailsTransponderActivationSignUpInput;
  transponderNumbers?: string[];
  vehicles?: IVehicleSignUpInput[];
  plansSignUp?: IPlansTransponderActivationSignUp;
  prepaidAmount?: number;
  additionalAmount?: number;
  transactionTotalAmount?: number;
}

export interface IPlansTransponderActivationSignUp {
  planList?: IPlanSignUpInput[];
  paymentDetails?: IPaymentDetailsSignUpInput;
  saveCard?: string;
  promoCode?: string;
  reBillThresholdAmount?: number;
}

export interface IAccountDetailsTransponderActivationSignUpInput extends IUserNames {
  fein: string;
  companyName: string;
  dbaName: string;
  secondaryContacts: ISecondaryContactInput[];
  addressLine1: string;
  addressLine2: string;
  country: string;
  city: string;
  state: string;
  zipcode: string;
  zipcodePlus: string;
  email: string;
  reTypeEmail: string;
  additionalEmailAddress: IAdditionalEmailAddressInput;
  cellPhone: string;
  homePhone: string;
  workPhone: string;
  smsOption: boolean;
  language: string;
  statementType: string;
  username: string;
  password: string;
  reTypePassword: string;
  securityQuestion: string;
  securityQuestionAnswer: string;
  digitPin: string;
}

export interface ISignUpTransponderActivationOutput {
  accountNumber: string;
}

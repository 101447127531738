import React from 'react';
import ConfirmationModal from './ConfirmationModal';
import { Trans } from '@lingui/macro';
import './ConfirmationModal.scss';
import Photo from '../assets/Toll-Invoice-and-UTC-Samples.jpg';

interface ILocateInvoiceNumberModalProps {
  showModal: boolean;
  onClose: () => void;
}

const LocateInvoiceNumberModal = ({ showModal, onClose }: ILocateInvoiceNumberModalProps) => {
  return (
    <>
      {showModal && (
        <ConfirmationModal
          title={
            <h1 className="text-center text-xl">
              <Trans>How to Locate Your Invoice Number or Citation ID</Trans>
            </h1>
          }
          onOk={onClose}
          okButtonText="Cancel"
          showModal={showModal}
          content={
            <div className="text-center">
              <img src={Photo} alt="How to locate invoice number"></img>
            </div>
          }
          alignText="center"
        />
      )}
    </>
  );
};
export default LocateInvoiceNumberModal;

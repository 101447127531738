import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Form } from 'antd';
import { FormProvider } from 'antd/lib/form/context';
import { t, Trans } from '@lingui/macro';
import StyledInput from '../../../StyledInput/StyledInput';
import { ApolloQueryResult } from '@apollo/client';
import { IVehicleList, IVehicleListInput } from '../../../../graphql/vehicles.graphql';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { validators } from '../../../../validators/validators';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import { DEFAULT_VEHICLES_FETCH_PARAM } from '../../Transponders/TransponderList/defaultValues';
import { usePaginationInfo } from 'src/store/pagination/pagination.store';

export interface IVehicleSearchProps {
  refetch: (
    variables?: Partial<{ vehicleListInput: IVehicleListInput }> | undefined,
  ) => Promise<ApolloQueryResult<{ vehicleList: IVehicleList }>>;
}

interface IVehicleSearchForm {
  plateNumberSearchValue: string;
}

export const VehicleSearch = ({ refetch }: IVehicleSearchProps) => {
  const methods = useForm<IVehicleSearchForm>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        plateNumberSearchValue: validators.alphanumeric.min(3, /*i18n*/ t`Please input at least 3 characters`),
      }),
    ),
    defaultValues: {
      plateNumberSearchValue: '',
    },
  });

  const {
    handleSubmit,
    control,
    watch,
    clearErrors,
    reset,
    trigger,
    formState: { errors },
  } = methods;

  const plateNumberWatch = watch('plateNumberSearchValue');
  const firstRequestSent = useRef(false);
  const [cleared, setCleared] = useState(false);
  const { setAreVehiclesFiltersApplied } = usePaginationInfo();
  useEffect(() => {
    if (plateNumberWatch?.length >= 3) {
      setAreVehiclesFiltersApplied(true);
      (async () => {
        await performRequest(true);
        firstRequestSent.current = true;
      })();
    } else {
      setAreVehiclesFiltersApplied(false);
    }
    !cleared && firstRequestSent.current && trigger('plateNumberSearchValue');
  }, [plateNumberWatch, cleared]);

  const onSubmit = async () => {
    setCleared(false);
    await performRequest();
  };

  const performRequest = async (activeSearch = false) => {
    clearErrors();
    setCleared(false);
    await refetch(
      activeSearch
        ? {
            vehicleListInput: {
              plateNumber: plateNumberWatch,
              activeSearch,
            },
          }
        : {
            vehicleListInput: {
              plateNumber: plateNumberWatch,
              ...DEFAULT_VEHICLES_FETCH_PARAM,
            },
          },
    );
  };

  const clear = async () => {
    await refetch({
      vehicleListInput: {
        ...DEFAULT_VEHICLES_FETCH_PARAM,
      },
    });
    reset();
    clearErrors();
    setCleared(true);
  };

  return (
    <div className="flex w-full flex-row">
      <Form className="theme w-full items-start" layout="vertical" onFinish={handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <div className="flex w-full flex-col items-end sm:flex-row sm:pr-2">
            <div className="w-full sm:pr-2 md:w-6/12 lg:w-4/12 ">
              <Form.Item
                id="plateNumberSearchValue"
                className="theme"
                label={<Trans>Search by License Plate</Trans>}
                help={errors?.plateNumberSearchValue?.message as unknown as string}
              >
                <Controller
                  name="plateNumberSearchValue"
                  control={control}
                  render={({ field, fieldState }) => (
                    <StyledInput
                      key="plateNumberSearchValue"
                      id="plateNumberSearchValue"
                      field={field}
                      fieldstate={fieldState}
                      aria-label={t`Search by License Plate`}
                    />
                  )}
                ></Controller>
              </Form.Item>
            </div>
            <div className="mt-4 flex h-full w-full flex-col justify-center self-start sm:mt-12 sm:w-auto sm:flex-row">
              <div className="flex h-full w-full flex-col justify-start xsm:flex-row">
                <CustomButton
                  type="submit"
                  className="w-full font-medium xsm:w-1/2 sm:ml-4 sm:w-auto"
                  content="Apply"
                />
                <CustomButton
                  type="reset"
                  className="mx-0 mt-4 w-full font-medium xsm:ml-4 xsm:mt-auto xsm:w-1/2 sm:mt-auto sm:w-auto"
                  content="Reset"
                  isPrimary={false}
                  onClick={clear}
                />
              </div>
            </div>
          </div>
        </FormProvider>
      </Form>
    </div>
  );
};

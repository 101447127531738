import { useState } from 'react';
import { Collapse } from 'antd';
import { useQuery } from '@apollo/client';
import { GET_FAQS, IFaqs } from '../../../graphql/faqs.graphql';
import { MainLoader } from '../../Loader/MainLoader';

const FrequentlyAskedQuestions = () => {
  const { Panel } = Collapse;

  const { data: faqsData, loading: faqsLoading } = useQuery<{ faqs: IFaqs[] }>(GET_FAQS);

  const [activeKeys, setActiveKeys] = useState<string | string[]>([]);

  const onChange = (keys: string | string[]) => {
    setActiveKeys(keys);
  };

  return (
    <div className="w-full flex-row lg:w-1/2">
      <MainLoader loading={faqsLoading} />
      <h1 className="pt-4 text-xl font-bold sm:text-3xl">Frequently Asked Questions</h1>
      <div className="faqs-section mt-10">
        <Collapse activeKey={activeKeys} ghost expandIconPosition={'end'} onChange={(keys) => onChange(keys)}>
          {faqsData?.faqs.map((value, index) => (
            <Panel header={value.question} key={index} className="border-t border-gray-300 py-5">
              <p>{value.answer}</p>
            </Panel>
          ))}
        </Collapse>
      </div>
    </div>
  );
};

export default FrequentlyAskedQuestions;

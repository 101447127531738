import React, { FC } from 'react';
import { t } from '@lingui/macro';
import ConfirmationModal from '../ConfirmationModal';

interface IConfirmationModalProps {
  showModal: boolean;
  onOk: () => void;
  onCancel?: () => void;
}

const AbandonAccountCreationModal: FC<IConfirmationModalProps> = ({ showModal, onOk, onCancel }) => {
  const cancelButtonText = t`Cancel`;
  const okButtonText = t`Continue`;
  const title = t`Abandon Account Creation?`;
  const content = t`Are you sure you'd like to abandon the account creation process? Please note that if you cancel, all information will be erased.`;

  return (
    <>
      {showModal && (
        <ConfirmationModal
          onOk={onOk}
          onCancel={onCancel}
          showModal={showModal}
          cancelButtonText={cancelButtonText}
          okButtonText={okButtonText}
          title={title}
          content={content}
          alignButtons="center"
          alignText="center"
        />
      )}
    </>
  );
};
export default AbandonAccountCreationModal;

import { IPaymentMethodGraphQl } from 'src/graphql/paymentMethods.graphql';
import { IPaymentMethod } from './Payments.interfaces';

export const mapAddUpdatePaymentMethod = (
  paymentMethod: IPaymentMethod,
  paymentMethodToEdit: any,
  cardType: string,
  replenishmentThresholdAmount?: string,
  isEasyPay?: boolean,
  authorizeReplenishment?: boolean,
) => {
  const payType = paymentMethod.paymentType === 'card' ? cardType : paymentMethod.bankAccountType;
  return {
    addressLine1: paymentMethod.addressLine1,
    addressLine2: paymentMethod.addressLine2 || '',
    bankAccountNumber: paymentMethod.bankAccountNumber || '',
    bankRoutingNumber: paymentMethod.bankRoutingNumber || '',
    cardNumber: paymentMethod.ccNumber || '',
    city: paymentMethod.city,
    country: paymentMethod.country,
    expirationMonth: paymentMethod.ccExpirationMonth || '',
    expirationYear: paymentMethod.ccExpirationYear || '',
    firstName: paymentMethod.ccFirstName || paymentMethod.bankFirstName,
    isEasyPay: isEasyPay || false,
    isPrimary: paymentMethodToEdit?.isPrimary || false,
    lastName: paymentMethod.ccLastName || paymentMethod.bankLastName,
    payType: payType,
    paymentMethodId: paymentMethodToEdit?.paymentMethodId || '',
    state: paymentMethod.state,
    zipCode: paymentMethod.postalCode,
    reBillThresholdAmount: authorizeReplenishment === true ? replenishmentThresholdAmount : '',
  };
};
export const mapMakePrimary = (paymentMethod: IPaymentMethodGraphQl) => {
  const {
    addressLine1,
    addressLine2,
    bankAccountNumber,
    bankRoutingNumber,
    cardNumber,
    city,
    country,
    middleName,
    expirationMonth,
    expirationYear,
    isEasyPay,
    isPrimary,
    payType,
    paymentMethodId,
    state,
    zipCode,
    securityCode,
    firstName,
    lastName,
  } = paymentMethod;
  return {
    addressLine1,
    addressLine2,
    bankAccountNumber,
    bankRoutingNumber,
    cardNumber,
    city,
    country,
    middleName,
    expirationMonth,
    expirationYear,
    isEasyPay,
    isPrimary,
    payType,
    paymentMethodId,
    state,
    zipCode,
    securityCode,
    firstName,
    lastName,
  };
};

import { Form, Select } from 'antd';
import { Trans, t } from '@lingui/macro';
import { Controller, useFormContext } from 'react-hook-form';
import { LabelValue } from '../../../../../types/LabelValue.interface';
import { AGENCIES } from '../activity.utils';
import styles from '../../Activity.module.scss';

const { Option } = Select;

export const AgencyName = () => {
  const { control, setValue } = useFormContext();

  return (
    <div className="w-full align-bottom sm:w-9/12 sm:pr-1 md:w-5/12">
      <Form.Item label={<Trans>Issuing Agency Name</Trans>} className={`theme ${styles['agency-name']}`}>
        <Controller
          name="agencyName"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              key="agencyName"
              id="agencyName"
              onChange={(value) => {
                field.onChange(value);
                setValue('agencyName', value);
              }}
              optionFilterProp="children"
              aria-label={t`Agency name`}
              showSearch
            >
              {AGENCIES?.map((agency: LabelValue) => (
                <Option key={agency.value} value={agency.value}>
                  {agency.label}
                </Option>
              ))}
            </Select>
          )}
        />
      </Form.Item>
    </div>
  );
};

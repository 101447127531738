import { ILinkedAccount } from '../../../../graphql/linkedAccounts.graphql';
import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import { MainLoader } from '../../../Loader/MainLoader';
import { useLinkedAccounts } from '../../../../hooks/useLinkedAccounts';

interface IActiveParentAccountItem {
  activeAccount: ILinkedAccount;
}

export const ActiveLinkedAccountItem = ({ activeAccount }: IActiveParentAccountItem) => {
  const { removeParentAccountAccess, loading } = useLinkedAccounts();
  return (
    <div className="flex-rowitems-center flex">
      <MainLoader loading={loading} />
      <span className="break-word flex-1">{activeAccount.owner}</span>
      <Link
        to="#"
        className="flex-1 text-primary-red underline"
        onClick={() => removeParentAccountAccess(activeAccount.accountNumber)}
      >
        <Trans>Revoke Access</Trans>
      </Link>
    </div>
  );
};

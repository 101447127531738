import React from 'react';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { colors } from 'src/theme/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import ScreenSize from 'src/types/ScreenSize.enum';
import useScreenSize from 'src/hooks/screen/useScreenSize';
import './MyAccount.scss';
import { useQuery } from '@apollo/client';
import { GET_ACCOUNT_DETAILS, IAccountDetails } from '../../graphql/accountDetails.graphql';
import { getProperPhoneNumber } from '../../utils/formatter';
import { DashboardParams, MainRoutes } from '../../types/routes';
import { capitalizeInitials } from '../../utils/string.utils';
import { usePlans } from '../Dashboard/MyDashboard/usePlans';
import { AccountType } from '../../types/onboarding.types';

export default function Header(): React.ReactElement {
  const { data } = useQuery<{ accountDetails: IAccountDetails }>(GET_ACCOUNT_DETAILS);
  const { screenSize } = useScreenSize();
  const accountType = data?.accountDetails.accountType;
  const { GOVAccount } = usePlans();
  const isGOVAccount = accountType === AccountType.COMMERCIAL && GOVAccount;

  return (
    <>
      <header className="flex w-full justify-center bg-primary-white py-10">
        <div className="flex-column space w-full justify-between pl-8 sm:flex lg:w-9/12 xl:pl-0">
          <div className="ml-1 text-left text-sm sm:text-base large-tablet:pr-3">
            <Link
              to={`${MainRoutes.DASHBOARD}/${DashboardParams.MY_DASHBOARD}`}
              className="mr-4 text-primary-green1 underline"
              aria-label="Return to Dashboard"
            >
              <FontAwesomeIcon icon={faChevronLeft} color={colors.primary.green1} size="1x"></FontAwesomeIcon>
              <span className="mb-5 ml-3 text-sm large-tablet:text-base">
                <Trans>Return to Dashboard</Trans>
              </span>
            </Link>
            <div className="account-title my-3 flex-row justify-center text-3xl font-light large-tablet:my-6 large-tablet:text-4xl">
              <span>
                <Trans>Account Settings</Trans>
              </span>
            </div>
            <h6 className="large-tablet:font-regular text-base font-medium text-primary-gray1 large-tablet:text-xl">
              {capitalizeInitials(data?.accountDetails.customerName)}
            </h6>
            <div className="flex-col flex-wrap large-tablet:mt-2">
              <span className="mr-6 large-tablet:border-r large-tablet:border-gray-400 large-tablet:py-1 large-tablet:pr-6">
                {getProperPhoneNumber(data?.accountDetails)}
              </span>
              {screenSize <= ScreenSize.LARGE_TABLET ? <br /> : null}
              <span>{data?.accountDetails.emailAddress}</span>
            </div>
            {screenSize <= ScreenSize.LARGE_TABLET ? (
              <div>
                <div>
                  <span className="font-semibold">
                    <Trans>Account Number</Trans>
                  </span>
                  <span className="pl-4">{data?.accountDetails.accountNumber}</span>
                </div>
                <div>
                  <span className="font-semibold">
                    <Trans>Type</Trans>
                  </span>
                  <span className="pl-4">{isGOVAccount ? 'GOVERNMENT' : data?.accountDetails.accountType}</span>
                </div>
              </div>
            ) : (
              <div className="mt-3.5">
                <span className="font-semibold">
                  <Trans>Account Number</Trans>
                </span>
                <span className="pl-4">{data?.accountDetails.accountNumber}</span>
                <span className="ml-8 font-semibold">
                  <Trans>Type</Trans>
                </span>
                <span className="pl-4">{isGOVAccount ? 'GOVERNMENT' : data?.accountDetails.accountType}</span>
              </div>
            )}
          </div>
        </div>
      </header>
    </>
  );
}

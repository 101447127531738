import { useMutation, useQuery } from '@apollo/client';
import { t, Trans } from '@lingui/macro';
import React, { FC, useContext, useState } from 'react';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import ChosePaymentMethod from 'src/components/Payments/ChosePaymentMethod/ChosePaymentMethod';
import NewPaymentMethodSettleBallanceWraper from 'src/components/Payments/NewPaymentMethodWrappers/NewPaymentMethodSettleBallanceWraper';
import { IPaymentMethod } from 'src/components/Payments/Payments.interfaces';
import PurchaseInformationTable from 'src/components/Payments/PaymentSummary/OneTimePurchaseElements/PaymentSummaryTable';
import PurchaseInformation from 'src/components/Payments/PaymentSummary/PurchaseInformation';
import { ADD_NEW_PAYMENT_OPTION, ONE_TIME_PURCHASE } from 'src/constants';
import { GET_ACCOUNT_DETAILS, IAccountDetails } from 'src/graphql/accountDetails.graphql';
import { IIncreaseBalanceInput, INCREASE_BALANCE } from 'src/graphql/payment.graphql';
import {
  ADD_UPDATE_PAYMENT_METHOD,
  IAddUpdatePaymentMethodInput,
  IPaymentMethodGraphQl,
  PAYMENT_METHODS,
} from 'src/graphql/paymentMethods.graphql';
import { useSortPaymentMethods } from 'src/hooks/domain/payments/useSortPaymentMethods';
import { usePaymentStore } from 'src/store/payment/payment.store';
import { showMessage } from 'src/utils/message.utils';
import LeaveSiteConfirmationModal from '../../Modals/ConfirmationModal/LeaveSiteConfirmationModal';
import { MainLoader } from 'src/components/Loader/MainLoader';
import ConfirmPaymentModal from 'src/components/Modals/ConfirmationModal/ConfirmPaymentModal';
import { getPaymentMethodName } from 'src/utils/formatter';
import SecurityCodeModal from 'src/components/Payments/PaymentMethods/SecurityCodeModal';
import { useSecurityCodeModalStore } from 'src/store/payment/securityCodeModal.store';
import { Form } from 'antd';
import './SettleBallance.scss';
import { mapAddUpdatePaymentMethod } from 'src/components/Payments/mappers';
import { getMappedInputToSettleBallance } from './utils';
import { NotificationContext } from '../../../context/NotificationContext';
import { PAY_TOLLS_SUMMARY } from '../references';
import { useOneTimePaymentStore } from 'src/store/payment/oneTimePayment.store';

interface ISettleBallance {
  ballance: number;
  setIsSettleBalanceSectionShown: React.Dispatch<React.SetStateAction<boolean>>;
  closeSettleBallance(): void;
  showCVV: boolean;
}

const SettleBallance: FC<ISettleBallance> = ({
  ballance,
  closeSettleBallance,
  setIsSettleBalanceSectionShown,
  showCVV,
}) => {
  const ballanceToSettle = Math.abs(ballance) + 5;
  const [paymentMethod, setPaymentMethod] = useState<IPaymentMethodGraphQl>();
  const [isNewPayment, setIsNewPayment] = useState<boolean | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmPaymentModalOpen, setConfirmPaymentModalOpen] = useState(false);
  const [isPaymentFormDirty, setIsPaymentFormDirty] = useState(false);
  const [shouldShowError, setShouldShowError] = useState(false);

  const {
    securityCodeModalData: { securityCode },
    setShowSecurityCodeModal,
  } = useSecurityCodeModalStore();

  const { cardType } = usePaymentStore();
  const { sortedPaymentMethods } = useSortPaymentMethods();
  const { isOneTimePayment, changeIsOneTimePayment } = useOneTimePaymentStore();

  const notificationCtx = useContext(NotificationContext);
  const { loadNotifications } = notificationCtx || {};

  const [increaseBalance, { loading: isIncreasingBallanceLoading }] = useMutation<{
    increaseBalanceInput: IIncreaseBalanceInput;
  }>(INCREASE_BALANCE, {
    onCompleted: async () => {
      loadNotifications && (await loadNotifications());
    },
  });

  const [addPaymentMethod, { loading: isAddingLoading }] = useMutation<IAddUpdatePaymentMethodInput>(
    ADD_UPDATE_PAYMENT_METHOD,
    {
      onCompleted: async () => {
        loadNotifications && (await loadNotifications(true));
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: PAYMENT_METHODS,
        },
      ],
    },
  );

  const {
    data: accountData,
    loading: isAccountDataLoading,
    refetch: refetchAccountDetails,
  } = useQuery<{ accountDetails: IAccountDetails }>(GET_ACCOUNT_DETAILS);

  const { accountDetails } = accountData || {};
  const { accountType, replenishThreshold } = accountDetails || {};

  const handleChange = (value: string) => {
    changeIsOneTimePayment(false);
    setShouldShowError(false);
    const chosenPaymentMethod = sortedPaymentMethods?.find((card) => {
      return card.paymentMethodId === value;
    });
    chosenPaymentMethod && setPaymentMethod(chosenPaymentMethod);
    if (value === ADD_NEW_PAYMENT_OPTION || value === ONE_TIME_PURCHASE) {
      if (value === ONE_TIME_PURCHASE) changeIsOneTimePayment(true);
      setIsNewPayment(true);
    } else {
      setIsNewPayment(false);
    }
  };

  const onMakePaymentClick = (): void => {
    if (isNewPayment === null) {
      setShouldShowError(true);
    } else {
      if (paymentMethod?.isBankAccount) {
        setConfirmPaymentModalOpen(true);
      } else {
        if (!securityCode) {
          setShowSecurityCodeModal(true);
        } else {
          onSubmit();
        }
      }
    }
  };

  const onSubmit = async (formValues?: IPaymentMethod) => {
    const mappedPaymentMethod = getMappedInputToSettleBallance({
      isNewPayment,
      formValues,
      ballanceToSettle,
      replenishThreshold,
      securityCode,
      paymentMethod,
      cardType,
    });
    try {
      await increaseBalance({
        variables: {
          increaseBalanceInput: mappedPaymentMethod,
        },
      });
      if (formValues && isNewPayment === true && !isOneTimePayment) {
        const addUpdatePaymentMethodInput = mapAddUpdatePaymentMethod(formValues, formValues, cardType);
        await addPaymentMethod({
          variables: {
            addUpdatePaymentMethodInput,
          },
        });
      }
      await refetchAccountDetails();
      showMessage('success');
      closeSettleBallance();
    } catch (err) {
      showMessage('error');
      console.log(err);
    } finally {
      setShowSecurityCodeModal(false);
      window.scrollTo(0, 0);
    }
  };

  const closeSettleBalanceFormAndScroll = () => {
    setIsSettleBalanceSectionShown(false);
  };

  const closeReviewForm = () => {
    if (isNewPayment && isPaymentFormDirty) {
      setIsModalOpen(true);
    } else {
      closeSettleBalanceFormAndScroll();
    }
  };

  return (
    <div className="home w-full">
      <MainLoader loading={isAccountDataLoading || isIncreasingBallanceLoading || isAddingLoading} />
      <div id={PAY_TOLLS_SUMMARY} className="settle-ballance-container mb-12 rounded-lg bg-white shadow-lg ">
        <div className="mx-auto lg:max-w-6xl">
          <div className="p-7 pb-0">
            <div className="py-4 text-center">
              <h3>
                <Trans>Review and Pay Owed Tolls</Trans>
              </h3>
            </div>
            <PurchaseInformation
              isLoading={isAccountDataLoading || isIncreasingBallanceLoading}
              planName={accountType}
              paymentContent={<PurchaseInformationTable ballance={ballance} isMinimumRequired={true} />}
              totalPrice={ballanceToSettle}
              accountInfo={accountDetails || {}}
            />{' '}
            <div className="border-t pt-8">
              <div className="mx-10">
                <h4 className="py-2 text-center text-3xl font-bold text-primary-blue1 md:px-10">
                  <Trans>Which payment method do you intend on using for this purchase?</Trans>
                </h4>
                <div className="settle-ballance-chose-payment-method mx-auto w-full py-4 sm:max-w-sm">
                  <Form.Item
                    name="paymentMethods"
                    hasFeedback
                    validateStatus={shouldShowError ? 'error' : ''}
                    help={shouldShowError ? t`Please select a payment method to proceed` : ''}
                    required
                  >
                    <ChosePaymentMethod
                      handleChange={handleChange}
                      selectClassName={`${shouldShowError ? 'ant-select-focused' : ''} mb-2`}
                      sortedPaymentMethods={sortedPaymentMethods}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isNewPayment || isOneTimePayment ? (
          <div className="px-4">
            <NewPaymentMethodSettleBallanceWraper
              onSubmit={onSubmit}
              closeReviewForm={closeReviewForm}
              isLoading={isAccountDataLoading || isIncreasingBallanceLoading}
              setIsPaymentFormDirty={setIsPaymentFormDirty}
              showCVV={showCVV}
            />
          </div>
        ) : (
          <div className="flex justify-center">
            <div className="mx-20 flex w-full flex-col-reverse justify-center gap-5 pb-8 sm:flex-row">
              <CustomButton type="button" content={<Trans>Cancel</Trans>} isPrimary={false} onClick={closeReviewForm} />
              <CustomButton
                type="button"
                content={isNewPayment === null ? <Trans>Make Payment</Trans> : <Trans>Submit Payment</Trans>}
                onClick={onMakePaymentClick}
              />
            </div>
          </div>
        )}
      </div>{' '}
      <LeaveSiteConfirmationModal
        showModal={isModalOpen}
        onOk={() => {
          closeSettleBalanceFormAndScroll();
          setIsModalOpen(false);
        }}
        onCancel={() => setIsModalOpen(false)}
      />
      {paymentMethod ? (
        <ConfirmPaymentModal
          showModal={isConfirmPaymentModalOpen}
          onOk={() => {
            onSubmit();
            closeSettleBalanceFormAndScroll();
            setConfirmPaymentModalOpen(false);
          }}
          onCancel={() => {
            setConfirmPaymentModalOpen(false);
          }}
          value={ballanceToSettle}
          paymentMethodName={getPaymentMethodName(paymentMethod)}
        />
      ) : null}
      {paymentMethod ? (
        <SecurityCodeModal paymentMethod={paymentMethod} amount={ballanceToSettle} onSubmit={onSubmit} />
      ) : null}
    </div>
  );
};
export default SettleBallance;

import { Trans, t } from '@lingui/macro';
import * as React from 'react';
import ThumbsUp from 'src/components/Onboarding/assets/thumbs_up.svg';
import ScrollToTop from 'src/components/ScrollToTop/ScrollToTop';
import { CustomButton } from 'src/components/CustomButton/CustomButton';

interface ISuccessMessageContentProps {
  firstTitle?: string;
  planName?: string;
  secondTitle?: string;
  message: string;
  goBack(): void;
  goBackDirection: string;
  isOnboardingStep?: boolean;
  isWaitingForGovermentalApproval?: boolean;
}

const SuccessMessageContent: React.FunctionComponent<ISuccessMessageContentProps> = ({
  firstTitle,
  secondTitle,
  message,
  goBack,
  goBackDirection,
  planName,
  isOnboardingStep,
  isWaitingForGovermentalApproval,
}) => {
  return (
    <div>
      <ScrollToTop />
      <div className="h-full ">
        {firstTitle ? <h5 className="onboarding-header">{t`${firstTitle}`}</h5> : <div className="my-4"></div>}
        {}
        <h1 className="md:2/3 m-auto w-full px-2 py-8 text-center font-light xl:w-1/2">
          <Trans>{secondTitle}</Trans> {planName ? `${planName}!` : null}
        </h1>
        <div className={isOnboardingStep ? 'card p-20' : 'p-20'}>
          <div className="flex w-full flex-col items-center">
            <img className="w-30" src={ThumbsUp} alt="Success message image" />
            <div className="mt-8 w-full text-center sm:w-3/4">
              {t`${message}`} {planName ? `${planName}!` : null}
            </div>
            {isWaitingForGovermentalApproval ? null : (
              <CustomButton onClick={goBack} className="mt-12" content={goBackDirection} />
            )}
          </div>
        </div>
        {isWaitingForGovermentalApproval ? (
          <div className="flex justify-center">
            <CustomButton onClick={goBack} className="mt-20" content={goBackDirection} isPrimary={false} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SuccessMessageContent;

import { FC } from 'react';
import { formatDate, formatTime } from '../../../../utils/time.utils';
import { ITransactionActivity } from 'src/graphql/transactionsActivity.graphql';
import { addPlusToBalanceIfPositive, convertPriceToString } from 'src/utils/formatter';

interface ITranspondersActivityItemSmallScreenProps {
  key: string;
  activity: ITransactionActivity;
}

const TranspondersActivityItemSmallScreen: FC<ITranspondersActivityItemSmallScreenProps> = ({ activity }) => {
  const { transactionDate, exitTime, exitPlazaName, tagOrPlateNumber, amount } = activity;

  return (
    <li className="mb-3 flex flex-row items-center justify-between rounded-lg bg-white px-6 py-4 shadow-lg">
      <div className="flex flex-col">
        <div>
          <span>{formatDate(transactionDate, true)}</span> at <span>{formatTime(exitTime)}</span>
        </div>
        <div className="font-bold">{exitPlazaName}</div>
        <div>{tagOrPlateNumber ? `T#${tagOrPlateNumber}` : '-'}</div>
      </div>

      <div className={`${amount >= 0 ? 'text-black' : 'text-red'} font-bold`}>
        {addPlusToBalanceIfPositive(convertPriceToString(amount, false))}
      </div>
    </li>
  );
};

export default TranspondersActivityItemSmallScreen;

import * as yup from 'yup';
import {
  additionalContactsValidator,
  emailsValidator,
  fleetGovernmentAdditionalInfoValidator,
  phonesValidator,
} from '../../../validators/onboarding/accountInfo/accountInfo.validator';
import { billingAddressValidator, mailingAddressValidator } from '../../../components/Address/mailingAddressValidator';
import { IAccountDetails } from '../../../graphql/accountDetails.graphql';
import { useOnboardingStore } from '../../../store/onboarding/onboarding.store';
import { useMemo } from 'react';
import { useContactSettingsStore } from '../../../store/account/contactSettings.store';
import { usePaymentValidatorSchema } from 'src/components/Payments/validation/usePaymentValidatorSchema';
import { CLOSE_ACCOUNT_REQUIRED_ERROR_MSG } from '../../../validators/onboarding/accountInfo/accountInfoErrorMessages';
import { IPlan } from '../../../graphql/plans/plans.graphql';

export interface IUseDynamicContactSettingsValidation {
  accountDetails?: IAccountDetails;
  GOVAccount?: IPlan | undefined;
}

export interface IUseDynamicContactSettingsValidationReturnType {
  schema: any;
}

export const useDynamicContactSettingsValidation = ({
  accountDetails,
  GOVAccount,
}: IUseDynamicContactSettingsValidation): IUseDynamicContactSettingsValidationReturnType => {
  const { onboardingInfo: onboardingDetails } = useOnboardingStore();
  const { cardType } = onboardingDetails;
  const {
    closeAccountInfo: { isCloseProcessActive, isNewCardChosen, existingCard },
  } = useContactSettingsStore();
  const validationSchemaForContactSettingsOnly = yup.object().shape(
    {
      ...emailsValidator,
      ...phonesValidator,
      ...fleetGovernmentAdditionalInfoValidator(accountDetails?.accountType, GOVAccount),
      ...mailingAddressValidator(false),
      ...additionalContactsValidator(accountDetails?.accountType),
    },
    [
      ['cellPhone', 'workPhone'],
      ['cellPhone', 'homePhone'],
      ['workPhone', 'homePhone'],
    ],
  );
  const { cardSchema } = usePaymentValidatorSchema(true, true);

  const isAboutToCloseWithNewCard = useMemo(() => {
    return isNewCardChosen && isCloseProcessActive;
  }, [isNewCardChosen, isCloseProcessActive]);

  const validationSchemaForCloseAccountOnly = useMemo(() => {
    return yup.object().shape({
      reason: yup.string().required(CLOSE_ACCOUNT_REQUIRED_ERROR_MSG),
      ...cardSchema,
      ...billingAddressValidator(),
    });
  }, [cardType, isAboutToCloseWithNewCard]);

  const isAboutToCloseWithExistingCardOrAccountHasPositiveBalance = () => {
    return !!existingCard || (accountDetails && accountDetails.currentBalance >= 0);
  };

  const isAboutToCloseWithExistingCard = useMemo(() => {
    return isCloseProcessActive && !isNewCardChosen && isAboutToCloseWithExistingCardOrAccountHasPositiveBalance();
  }, [isNewCardChosen, isCloseProcessActive, existingCard, accountDetails?.currentBalance]);

  const schema = useMemo((): any => {
    let schema;
    if (isAboutToCloseWithNewCard) {
      schema = validationSchemaForCloseAccountOnly;
    } else if (isAboutToCloseWithExistingCard) {
      schema = yup.object().shape({});
    } else {
      schema = validationSchemaForContactSettingsOnly;
    }
    return isCloseProcessActive
      ? yup
          .object()
          .shape({
            reason: yup.string().required(CLOSE_ACCOUNT_REQUIRED_ERROR_MSG),
          })
          .concat(schema)
      : schema;
  }, [
    isAboutToCloseWithNewCard,
    isAboutToCloseWithExistingCard,
    validationSchemaForCloseAccountOnly,
    validationSchemaForContactSettingsOnly,
    validationSchemaForCloseAccountOnly,
  ]);

  return {
    schema,
  };
};

import { gql } from '@apollo/client';
import { TransponderType } from '../transponders.graphql';
import { EasyPayOption } from '../payment.graphql';

export const PURCHASE_PLAN = gql`
  mutation purchasePlan($purchasePlanInput: PurchasePlanInput!) {
    purchasePlan(purchasePlanInput: $purchasePlanInput) {
      referenceNumber
      transactionId
      authCode
      emailStatusCode
      emailMessage
    }
  }
`;

export interface IPurchasePlanOutput {
  referenceNumber: string;
  transactionId: string;
  authCode: string;
  emailStatusCode: string;
  emailMessage: string;
}

export interface IPurchasePlanInput {
  paymentAmount: string;
  paymentMethodId: string;
  bankRoutingNumber: string;
  bankAccountNumber: string;
  promoCode: string;
  cardType: string;
  cardNumber: string;
  expirationMonth?: number;
  expirationYear?: number;
  securityCode: string;
  firstName: string;
  middleName: string;
  lastName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  planName: string;
  transponderNumber: string;
  transponderType: TransponderType;
  easyPayOption: EasyPayOption;
}

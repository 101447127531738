import { CreditCardTypeCardBrandId } from 'credit-card-type/dist/types';
import creditCardType from 'credit-card-type';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { mapCreditCardTypeNameToApiType } from '../../../utils/creditCard.util';
import { useQuery } from '@apollo/client';
import { GET_CREDIT_CARD_TYPES } from '../../../graphql/creditCardTypes.graphql';
import PaymentMethodFormField from 'src/components/Payments/Payments.enums';

interface IUseCardType {
  cardType: string;
  loading: boolean;
}

export const useCardType = (): IUseCardType => {
  const { data, loading } = useQuery<{ creditCardTypes: string[] }>(GET_CREDIT_CARD_TYPES);
  const cardNumberWatch = useWatch({ name: PaymentMethodFormField.CC_NUMBER }) || '';

  const cardType = useMemo(() => {
    return (
      creditCardType(cardNumberWatch)
        .map(({ type }) => mapCreditCardTypeNameToApiType[type])
        .filter((type) => data?.creditCardTypes?.includes(type as CreditCardTypeCardBrandId))[0] || ''
    );
  }, [cardNumberWatch]);

  return {
    cardType,
    loading,
  };
};

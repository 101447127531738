import { i18n } from '@lingui/core';

export interface IUIErrorMsgTransponders {
  TRANS_NUMBER_DUPLICATED: string;
  TRANS_STATUS_BLANK: string;
  TRANS_NUMBER_BLANK: string;
  TRANS_NUMBER_DOES_NOT_EXISTS: string;
}

const UI_ERROR_MSG: IUIErrorMsgTransponders = {
  TRANS_NUMBER_DUPLICATED: i18n._(
    'The following transponder number (#) is already registered. Please review your upload to remove any previously activated transponders, and re-upload.',
  ),
  TRANS_STATUS_BLANK: i18n._(
    'One or more transponders in your file are missing a status. Please check to ensure that all transponders in your file have a valid status and re-upload.',
  ),
  TRANS_NUMBER_BLANK: i18n._(
    'One or more transponders in your file are missing a transponder number. Please check to ensure that all transponders in your file have a valid number and re-upload.',
  ),
  TRANS_NUMBER_DOES_NOT_EXISTS: i18n._(
    'The following transponder (#) does not exist on the account. Please check to ensure that all transponders in your file exist on your account and re-upload',
  ),
};

export const UI_MESSAGE_VALUE_MARKER = '(#)';

export const transponderApiErrorCodeToUIErrorMessageMap: Record<number, string> = {
  1614: UI_ERROR_MSG.TRANS_NUMBER_DUPLICATED,
  1615: UI_ERROR_MSG.TRANS_STATUS_BLANK,
  1616: UI_ERROR_MSG.TRANS_NUMBER_BLANK,
  1617: UI_ERROR_MSG.TRANS_NUMBER_DOES_NOT_EXISTS,
};

import { AccountType, IAccountInfo } from '../../../../types/onboarding.types';
import {
  IAdditionalEmailAddressInput,
  ISecondaryContactInput,
  ISignUpAccountDetailsInput,
} from '../../../../graphql/signUp/signUpDetails.graphql';
import { IAddressValidationOutput } from '../../../../graphql/addressValidation.graphql';

export const mapAccountInfoToDetailsSignUpInput = (
  account: IAccountInfo,
  accountType: AccountType,
  accountId: string,
) => {
  const accountInput: ISignUpAccountDetailsInput = {
    accountType: account.accountType || accountType,
    accountSubType: account.accountSubType,
    accountId: accountId,
    additionalEmailAddress:
      account?.additionalEmailAddresses?.length !== 0
        ? ({
            email: account?.additionalEmailAddresses?.[0]?.email,
            reTypeEmail: account?.additionalEmailAddresses?.[0]?.reTypeEmail,
          } as IAdditionalEmailAddressInput)
        : undefined,
    addressLine1: account.addressLine1,
    addressLine2: account.addressLine2 || '',
    city: account.city,
    cellPhone: account.cellPhone?.replace(/[\s()-]/g, ''),
    homePhone: account.homePhone?.replace(/[\s()-]/g, ''),
    workPhone: account.workPhone?.replace(/[\s()-]/g, ''),
    companyName: account.companyName,
    country: account.country,
    fein: account.fein,
    email: account.email,
    reTypeEmail: account.reTypeEmail,
    firstName: account.firstName,
    middleName: account.middleName,
    lastName: account.lastName,
    language: account.language,
    password: account.password,
    reTypePassword: account.reTypePassword,
    zipcode: account.postalCode || '',
    zipcodePlus: '',
    secondaryContacts: account.additionalContacts?.map((contact: ISecondaryContactInput, index: number) => {
      return {
        ...contact,
        id: index + 1,
      } as ISecondaryContactInput;
    }),
    securityQuestion: account.firstSecurityQuestion,
    securityQuestionAnswer: account.firstSecurityQuestionAnswer,
    smsOption: !!account.smsOption,
    state: account.state || '',
    statementType: 'EMAIL|MONTHLY|Detail', //TODO FLO-3081
    username: account.username,
    digitPin: '1354',
    dbaName: '',
  };
  return accountInput;
};

export const updateAccountInfoAddressAfterStandardization = (
  account: IAccountInfo,
  standardizedAddressData: { addressValidation: IAddressValidationOutput },
) => {
  return {
    ...account,
    addressLine1: standardizedAddressData?.addressValidation.addressLine1,
    addressLine2: standardizedAddressData?.addressValidation.addressLine2,
    country: standardizedAddressData?.addressValidation.country,
    state: standardizedAddressData?.addressValidation.state,
    city: standardizedAddressData?.addressValidation.city,
    postalCode: standardizedAddressData?.addressValidation.zipcode,
  };
};

export const prepareAddressStandardizationInputFromAccountInfo = (account: IAccountInfo) => {
  return {
    addressLine1: account.addressLine1 || '',
    addressLine2: account?.addressLine2 || '',
    country: account.country || '',
    state: account.state || '',
    city: account.city || '',
    zipcode: account.postalCode || '',
    language: account.language || 'English',
  };
};

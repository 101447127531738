import { Form, FormProps } from 'antd';
import React, { FunctionComponent } from 'react';
import './AppForm.scss';

interface FormPropsModified extends Omit<FormProps, 'children'> {
  children?: React.ReactNode;
}

export const AppForm: FunctionComponent<FormPropsModified> = (props) => {
  const formProps = { ...props };
  formProps.size = 'large';
  formProps.requiredMark = true;
  return <Form {...formProps} />;
};
export default AppForm;

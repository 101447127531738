import { Trans } from '@lingui/macro';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import ConfirmationModal from 'src/components/Modals/ConfirmationModal/ConfirmationModal';

const TransponderActivationRequiredModal = () => {
  const [showTransponderActivationRequiredModal, setShowTransponderActivationRequiredShowModal] = useState(false);

  const modalContent = (
    <p>
      <Trans>
        You must activate at least one transponder in order to create an account. If you don&apos;t have a transponder,
        you can create an account and purchase a transponder{' '}
      </Trans>
      <Link to="#" className="text-primary-green1 underline" aria-label="return to purchase transponders">
        <Trans>here</Trans>
      </Link>
    </p>
  );

  return (
    <ConfirmationModal
      onCancel={() => {
        setShowTransponderActivationRequiredShowModal(false);
      }}
      showModal={showTransponderActivationRequiredModal}
      title={''}
      cancelOnly={true}
      content={modalContent}
      alignText="center"
    />
  );
};

export default TransponderActivationRequiredModal;

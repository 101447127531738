enum ScreenSize {
  XS = 0,
  SM = 1,
  MD = 2,
  LARGE_TABLET = 3,
  LG = 4,
  XL = 5,
  XXL = 6,
}

export default ScreenSize;

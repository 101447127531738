import React from 'react';
import { Col, Row } from 'antd';
import { PaymentMethods } from './PaymentMethods/PaymentMethods';
import { EasyPay } from './EasyPay/EasyPay';
import { Balance } from './Balance/Balance';
import { AccountType } from '../../../types/onboarding.types';
import { usePlans } from '../MyDashboard/usePlans';
import { useQuery } from '@apollo/client';
import { GET_ACCOUNT_DETAILS, IAccountDetails } from '../../../graphql/accountDetails.graphql';

export const Wallet = () => {
  const { data: accountData } = useQuery<{ accountDetails: IAccountDetails }>(GET_ACCOUNT_DETAILS);
  const accountType = accountData?.accountDetails.accountType;
  const { GOVAccount } = usePlans();
  const isGOVAccount = accountType === AccountType.COMMERCIAL && GOVAccount;
  return (
    <>
      <Row className="flex">
        <Col span={24} className="text-center">
          <Balance />
        </Col>
      </Row>
      <Row className="flex">
        <Col span={24} className="text-center">
          <PaymentMethods />
        </Col>
      </Row>
      {isGOVAccount ? null : (
        <Row className="flex">
          <Col span={24} className="text-center">
            <EasyPay />
          </Col>
        </Row>
      )}
    </>
  );
};

import React, { useEffect } from 'react';
import { Trans } from '@lingui/macro';
import { Form, Checkbox } from 'antd';
import { useFormContext, Controller } from 'react-hook-form';
import PaymentMethodFormField from '../Payments.enums';

export interface ISaveForFutureCheckBoxProps {
  disabled?: boolean;
}

const SaveForFutureCheckBox = ({ disabled }: ISaveForFutureCheckBoxProps) => {
  const { watch, setValue, control } = useFormContext();

  useEffect(() => {
    disabled === true && setValue(PaymentMethodFormField.SAVE_CARD, true);
  }, [disabled]);

  return (
    <div className="new-payment-content text-left">
      <Form.Item>
        <Controller
          name="saveCard"
          control={control}
          render={({ field }) => (
            <Checkbox
              {...field}
              id={PaymentMethodFormField.SAVE_CARD}
              name={PaymentMethodFormField.SAVE_CARD}
              defaultChecked={true}
              checked={watch(PaymentMethodFormField.SAVE_CARD)}
              disabled={disabled}
              onChange={(e) => {
                setValue(PaymentMethodFormField.SAVE_CARD, e.target.checked as never);
              }}
            >
              <Trans>
                <b>Save this payment method for future use</b>
              </Trans>
            </Checkbox>
          )}
        />
      </Form.Item>
    </div>
  );
};
export default SaveForFutureCheckBox;

export enum OnboardingStep {
  INITIAL,
  ACCOUNT_TYPE,
  TRANSPONDERS_ACTIVATION,
  ACCOUNT_RECOMMENDATION,
  PINELLAS_RECOMMENDATION,
  FLEET,
  PINELLAS,
  ACCOUNT,
  TRANSPONDERS,
  EASY_PAY,
  VEHICLES,
  PAYMENT_SUMMARY,
  REVIEW,
  SUCCESS,
}

export enum OnboardingRoutes {
  HOME = '/',
  ACCOUNT_TYPE = '/accountType',
  TRANSPONDERS_ACTIVATION = '/transponders-activation',
  ACCOUNT_RECOMMENDATION = '/account-recommendation',
  PINELLAS_RECOMMENDATION = '/pinellas-recommendation',
  FLEET = '/fleet',
  PINELLAS = '/pinellas',
  ACCOUNT = '/account',
  VEHICLES = '/vehicles',
  TRANSPONDERS = '/transponders',
  EASY_PAY = '/ez-pay',
  PAYMENT_SUMMARY = '/payment',
  REVIEW = '/review',
  SUCCESS = '/success',
}

export enum OnboardingStepPath {
  INITIAL = '/onboarding',
  ACCOUNT_TYPE = '/onboarding/accountType',
  TRANSPONDERS_ACTIVATION = '/onboarding/transponders-activation',
  ACCOUNT_RECOMMENDATION = '/onboarding/account-recommendation',
  PINELLAS_RECOMENDATION = '/onboarding/pinellas-recommendation',
  FLEET = '/onboarding/fleet',
  PINELLAS = '/onboarding/pinellas',
  ACCOUNT = '/onboarding/account',
  VEHICLES = '/onboarding/vehicles',
  TRANSPONDERS = '/onboarding/transponders',
  EASY_PAY = '/onboarding/ez-pay',
  PAYMENT_SUMMARY = '/onboarding/payment',
  REVIEW = '/onboarding/review',
  SUCCESS = '/onboarding/success',
}

export enum MainRoutes {
  ROOT = '/',
  DASHBOARD = '/dashboard',
  MY_ACCOUNT = '/my-account',
  MY_ACCOUNT_MOBILE = '/mobile/my-account',
  FORGOT_USERNAME_OR_PASSWORD = '/forgot-username-or-password',
  ACCESS_ONLINE_ACCOUNT = '/access-online-account',
  STYLEGUIDE = '/styleguide',
  ACCOUNT_CLOSURE = '/account-closure',
  RESUME_ACCOUNT_CREATION = '/resume-account-creation',
  PAY_A_TOLL_INVOICE_OT_TRAFFIC_CITATION = '/pay-a-toll-invoice-or-traffic-citation',
  MAINTENANCE = '/maintenance',
}

export enum DashboardParams {
  MY_DASHBOARD = 'my-dashboard',
  WALLET = 'wallet',
  TRANSPONDERS = 'transponders',
  VEHICLES = 'vehicles',
  UNPAID_TOLLS = 'unpaid-tolls',
  ACTIVITY = 'activity',
}

export enum MyAccountParams {
  CONTACT_INFO = 'contact-info',
  PRIVACY = 'privacy',
  NOTIFICATIONS = 'notifications',
  LINKED = 'linked',
  PARKING = 'parking',
  PINELLAS = 'pinellas',
}

export const mapPathToOnboardingStep: Record<OnboardingStepPath, OnboardingStep> = {
  '/onboarding': OnboardingStep.INITIAL,
  '/onboarding/accountType': OnboardingStep.ACCOUNT_TYPE,
  '/onboarding/transponders-activation': OnboardingStep.TRANSPONDERS_ACTIVATION,
  '/onboarding/account-recommendation': OnboardingStep.ACCOUNT_RECOMMENDATION,
  '/onboarding/pinellas-recommendation': OnboardingStep.PINELLAS_RECOMMENDATION,
  '/onboarding/fleet': OnboardingStep.FLEET,
  '/onboarding/pinellas': OnboardingStep.PINELLAS,
  '/onboarding/account': OnboardingStep.ACCOUNT,
  '/onboarding/vehicles': OnboardingStep.VEHICLES,
  '/onboarding/transponders': OnboardingStep.TRANSPONDERS,
  '/onboarding/ez-pay': OnboardingStep.EASY_PAY,
  '/onboarding/payment': OnboardingStep.PAYMENT_SUMMARY,
  '/onboarding/review': OnboardingStep.REVIEW,
  '/onboarding/success': OnboardingStep.SUCCESS,
};

export const DRUPAL_FILE_DISPUTE = 'http://sunpass.mythic.sh/pay-toll/file-dispute';
export const PARTNERS_PAGE_DRUPAL = 'http://sunpass.mythic.sh/program-information/sunpass-partners';
export const DRUPAL = 'http://sunpass.mythic.sh/';
export const SAVINGS_PAGE_DRUPAL = 'https://www.sunpass.com/en/about/savings.shtml';

export const concatRoutes = (...routes: string[]): string => {
  return ''.concat(...routes);
};

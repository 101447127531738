import ScrollToTop from '../ScrollToTop/ScrollToTop';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { MainRoutes } from '../../types/routes';
import { Trans } from '@lingui/macro';
import React from 'react';
import './Maintenance.scss';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { colors } from '../../theme/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MaintenancePage = () => {
  return (
    <>
      <div className="h-full">
        <ScrollToTop />
        <div className="breadcrumb">
          <Breadcrumb separator="/">
            <Breadcrumb.Item className="underline">
              <Link to={MainRoutes.ROOT}>
                <Trans>Home</Trans>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item className="underline">
              <Trans>System Maintenance</Trans>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <h1 className="m-auto w-full px-2 py-8 pb-0 text-center font-light md:w-2/3">
          <Trans>System Maintenance</Trans>
        </h1>
        <div className="maintenance-form mt-10 text-center">
          <FontAwesomeIcon
            className="icon mb-auto mr-2 mt-auto sm:mr-5"
            icon={faTriangleExclamation}
            color={colors.primary.red}
            size="3x"
          ></FontAwesomeIcon>
          <p className="mt-10">
            <Trans>
              We regret that SunPass customer-specific information is temporarily unavailable while performing
              maintenance.
            </Trans>
          </p>
          <p className="mt-5">
            <Trans>Please try again later. Thanks!</Trans>
          </p>
        </div>
      </div>
    </>
  );
};

export default MaintenancePage;

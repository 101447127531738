import { useMutation, useQuery } from '@apollo/client';
import { FC, useState } from 'react';
import { MainLoader } from 'src/components/Loader/MainLoader';
import { ITransponderInput } from 'src/graphql/transponders.graphql';
import Pagination from 'src/components/Pagination/Pagination';
import { capitalizeInitials } from 'src/utils/string.utils';
import { Trans } from '@lingui/macro';
import { usePaginationInfo } from 'src/store/pagination/pagination.store';
import {
  ITransponderListResponse,
  GET_TRANSPONDER_PARKING_LIST,
  ITransponderWithParking,
  SunpassPlusParking,
} from 'src/graphql/tranponderParking/transponderPlusParkingList.graphql';
import '../MyAccount.scss';
import { sessionStorageService } from 'src/services/sessionStorage';
import {
  IUpdateTransponderPlusParkingListInput,
  UPDATE_TRANSPONDER_PLUS_PARKING,
} from 'src/graphql/tranponderParking/updateTransponderPlusParkingList.graphql';
import { showMessage } from 'src/utils/message.utils';
import ChangeTranpondersParkingModal from './ChangeTranpondersParkingModal';
import { useAccountSettings } from 'src/store/account/accountSettings.store';
import { SortDirectionType } from 'src/graphql/filter.graphql';
const TransponderListWithParking: FC = () => {
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [transponderNumberToOptOut, setTransponderNumberToOptOut] = useState('');
  const {
    transpondersPagination: { size: transpondersSize, page: transpondersPage },
  } = usePaginationInfo();
  const { setIsTabDirty } = useAccountSettings();
  const {
    data: transpondersListData,
    loading: transponderListLoading,
    refetch,
  } = useQuery<
    { transponderPlusParkingList: ITransponderListResponse },
    { transponderPlusParkingListInput: ITransponderInput }
  >(GET_TRANSPONDER_PARKING_LIST, {
    notifyOnNetworkStatusChange: true,
    variables: {
      transponderPlusParkingListInput: {
        pagination: {
          pageSize: transpondersSize,
          pageNumber: transpondersPage,
        },
        filter: { sortColumn: 'sunpassPlusParking', sortDirection: SortDirectionType.ASC },
      },
    },
  });

  const [updateTransponderPlusParkingList, { loading: isUpdatingTransponderPlusParkingList }] = useMutation<{
    updateTransponderPlusParkingList: boolean;
    updateTransponderPlusParkingListInput: IUpdateTransponderPlusParkingListInput;
  }>(UPDATE_TRANSPONDER_PLUS_PARKING);

  const totalPages = transpondersListData?.transponderPlusParkingList?.totalPages;
  const totalCount = transpondersListData?.transponderPlusParkingList.totalElements || 0;
  const hasParking = (transponder: ITransponderWithParking) =>
    transponder.sunpassPlusParking === SunpassPlusParking.OPT_IN;
  const removeParkingFromTransponder = () => {
    onParkingChange(transponderNumberToOptOut, false);
    setShowRemoveModal(false);
  };
  const onRemoveClick = (transponderNumber: string) => {
    setShowRemoveModal(true);
    setTransponderNumberToOptOut(transponderNumber);
  };
  const onSuccessfullParkingChange = (transponderNumber: string) => {
    sessionStorageService.changeParkingOnTransponder(transponderNumber);
    refetch();
    setIsTabDirty(false);
  };
  const onParkingChange = async (transponderNumber: string, optIn: boolean) => {
    try {
      await updateTransponderPlusParkingList({
        variables: {
          updateTransponderPlusParkingListInput: {
            transpondersPlusParking: [
              {
                transponderNumber,
                sunpassPlusParking: optIn ? SunpassPlusParking.OPT_IN : SunpassPlusParking.OPT_OUT,
              },
            ],
          },
        },
        onCompleted: () => onSuccessfullParkingChange(transponderNumber),
      });
    } catch (err) {
      showMessage('error');
    }
  };
  const listOfChangedTranponders = sessionStorageService.getChangedTranponderParkings();

  return (
    <>
      <MainLoader loading={transponderListLoading || isUpdatingTransponderPlusParkingList} />
      <ul className="tranponders-list-container">
        {transpondersListData &&
          transpondersListData.transponderPlusParkingList.transpondersPlusParking?.map(
            (transponder: ITransponderWithParking) => {
              const { transponderNumber, friendlyName } = transponder;
              return (
                <li
                  key={transponderNumber}
                  className={`transponder-item-shadow-background relative mb-3 flex h-20 cursor-pointer flex-col justify-center rounded-lg px-6 py-4 text-sm sm:flex-row sm:justify-between sm:text-base `}
                >
                  <div className="flex w-full items-center justify-between">
                    <div className="flex flex-col">
                      <div className="font-bold md:font-normal">
                        <span>{capitalizeInitials('PRO')}</span> | <span>{transponderNumber}</span>{' '}
                      </div>
                      <div className="italic text-primary-gray5">
                        {friendlyName ? friendlyName : 'Unlabeled Transponder'}
                      </div>
                    </div>

                    <div>
                      {listOfChangedTranponders?.includes(transponderNumber) ? <Trans>CHANGES SAVED</Trans> : null}
                    </div>
                    <div>
                      <span
                        className={`tranponder-item-button rounded px-2 py-1 text-xs text-white sm:text-sm ${
                          hasParking(transponder) ? 'italic' : ''
                        }`}
                        onClick={() => {
                          hasParking(transponder)
                            ? onRemoveClick(transponderNumber)
                            : onParkingChange(transponderNumber, true);
                        }}
                      >
                        <Trans>{hasParking(transponder) ? 'Remove' : 'Add'}</Trans>
                      </span>
                    </div>
                  </div>
                </li>
              );
            },
          )}
      </ul>
      {totalPages && totalPages >= 2 ? <Pagination count={totalCount} type={'transponders'} /> : null}
      <ChangeTranpondersParkingModal
        showModal={showRemoveModal}
        onBack={() => setShowRemoveModal(false)}
        onOk={removeParkingFromTransponder}
      />
    </>
  );
};

export default TransponderListWithParking;

import { Trans } from '@lingui/macro';
import React, { FC } from 'react';
import { useVehicleStore } from 'src/store/vehicles/vehicle.store';
import { renderInformation } from './stringRenderers';
import { IVehicleItemProps } from './VehicleItemBigScreen';

const VehicleItemSmallScreen: FC<IVehicleItemProps> = ({ vehicle }) => {
  const { plateNumber, plateState } = vehicle;

  const {
    store: { isFormVisible },
    setIsDeleteModalVisible,
    setVehicleToDelete,
    setVehicleToEdit,
  } = useVehicleStore();

  const editOnClick = () => {
    setVehicleToEdit(vehicle);
  };

  const removeOnClick = () => {
    setIsDeleteModalVisible(true);
    setVehicleToDelete(vehicle);
  };

  const plateNumberWithState = plateNumber + ' ' + plateState;

  return (
    <>
      <div className="w-1/2">
        <div>
          <span>{plateNumberWithState}</span>
        </div>
        <div className="font-bold">{renderInformation(vehicle)}</div>
      </div>

      <div className="mt-4 flex flex-row items-center justify-center sm:m-0">
        <button
          disabled={isFormVisible}
          className="remove-btn w-1/2 rounded-md px-5 pb-0.5 pt-1 text-center sm:w-auto"
          onClick={removeOnClick}
        >
          <span>
            <Trans>Remove</Trans>
          </span>
        </button>

        <button
          disabled={isFormVisible}
          className="edit-btn ml-5 w-1/2 rounded-md px-5 pb-0.5 pt-1 text-center md:w-auto"
          onClick={editOnClick}
        >
          <span>
            <Trans>Edit</Trans>
          </span>
        </button>
      </div>
    </>
  );
};

export default VehicleItemSmallScreen;

import React, { FC, useEffect } from 'react';
import { Form, Select } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import { t, Trans } from '@lingui/macro';
import { HOURS } from '../../../../utils/time.utils';
import { IVehicleFormItemProps } from '../../../Onboarding/Vehicles/OnboardingVehiclesForm/OnboardingVehicleForm';
import { buildErrorString, getInputName } from '../../../Onboarding/Vehicles/OnboardingVehiclesForm/vehicleForm.utils';
import classNames from 'classnames';

const { Option } = Select;

const EffectiveEndTime: FC<IVehicleFormItemProps> = ({ field, index, name, isInMyDashboard }) => {
  const {
    setValue,
    control,
    watch,
    trigger,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    if (watch(getInputName('effectiveEndTime', index)) === null) {
      setValue(getInputName('effectiveEndTime', index), undefined);
    }
  }, [watch(getInputName('effectiveEndTime', index))]);

  const isRental = isInMyDashboard ? 'isRental' : `vehicles.[${index}].isRental`;

  return (
    <div className="mb-4 w-full sm:mb-0 sm:w-3/12">
      <Form.Item
        {...field}
        help={buildErrorString(name, errors, index)}
        label={<Trans id="Effective End Time" />}
        htmlFor={name}
        validateTrigger={'onChange'}
        className="theme"
        hasFeedback
        validateStatus={errors?.vehicles?.[0]?.effectiveEndTime || errors.effectiveEndTime ? 'error' : ''}
        required={watch(isRental)}
      >
        <Controller
          control={control}
          name={name}
          render={({ field, fieldState }) => (
            <React.Fragment>
              <Select
                {...field}
                id={name}
                placeholder={t`Select`}
                onChange={(value) => {
                  field.onChange(value);
                  trigger([getInputName('effectiveEndTime', index), getInputName('effectiveEndDate', index)]);
                }}
                allowClear={!watch(getInputName('isRental', index))}
                value={watch(getInputName('effectiveEndTime', index))}
                className={`${classNames({ 'input-error': fieldState?.invalid })} ${'end-time'}`}
                aria-label={t`Effective end time`}
              >
                {HOURS.map((hour: any) => (
                  <Option key={hour} value={hour}>
                    {hour}
                  </Option>
                ))}
              </Select>
            </React.Fragment>
          )}
        />
      </Form.Item>
    </div>
  );
};

export default EffectiveEndTime;

import { useQuery } from '@apollo/client';
import { IVehicleModel, VEHICLE_MAKE_MODELS } from '../../../../graphql/vehicleMakes.graphql';
import { useState } from 'react';

interface IUseVehicleModels {
  vehicleModels: IVehicleModel[] | undefined;
  errorLoading: boolean;
  loading: boolean;
}

const useVehicleModels = (vehicleMakeName: string): IUseVehicleModels => {
  const [isError, setIsError] = useState(false);
  const { data, loading } = useQuery<{ vehicleModelsByVehicleMakeName: IVehicleModel[] }>(VEHICLE_MAKE_MODELS, {
    variables: { vehicleMakeName: vehicleMakeName },
    errorPolicy: 'all',
    skip: !vehicleMakeName,
    onError: () => setIsError(true),
  });
  return {
    vehicleModels: data?.vehicleModelsByVehicleMakeName,
    errorLoading: isError,
    loading: loading,
  };
};

export default useVehicleModels;

import { gql } from '@apollo/client';

export const GET_PLANS = gql`
  query planList {
    planList {
      planId
      planName
      transponderNumber
      planDescription
    }
  }
`;

export interface IPlansData {
  planList: IPlan[];
}

export interface IPlan {
  checkState: number;
  dateAdded: string;
  deletable: boolean;
  endDate: string;
  isRenewable: boolean;
  planDescription: string;
  planId: string;
  planName: string;
  status: string;
  transponderNumber: string;
}

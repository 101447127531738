import { t } from '@lingui/macro';
import React, { useCallback, useEffect, useState } from 'react';
import { getStepsByUserTypeAndFlowType, IContactInfo, IMailingAddress } from 'src/types/onboarding.types';
import sunpass_logo from '../../../assets/SunPassLogo-Blue.svg';
import OnboardingHeader, { OnboardingHeaderWholeViewProps } from '../OnboardingHeader';
import ScrollToTop from '../../ScrollToTop/ScrollToTop';
import AccountType from './AccountType';
import Billing from './Billing';
import MailingAddress from './MailingAddress';
import ContactInformation from './ContactInformation';
import styles from './ReviewYourInformation.module.scss';
import { Row, Space } from 'antd';
import BackButton from '../../BackButton/BackButton';
import { useRouteMonitor } from '../../../hooks/location/useRouteMonitor';
import { useOnboardingStore } from '../../../store/onboarding/onboarding.store';
import { SELECT_RECOMMENDED_SUNPASS_PLAN_NAME } from '../../../store/onboarding/onboarding.selectors';
import { MainLoader } from '../../Loader/MainLoader';
import { OnboardingStepPath } from '../../../types/routes';
import { useCallbackPrompt } from '../../../hooks/location/useCallbackPrompt';
import LeaveSiteConfirmationModal from '../../Modals/ConfirmationModal/LeaveSiteConfirmationModal';
import { useRecaptcha } from '../../../hooks/recaptcha/useRecaptcha';
import { CustomButton } from 'src/components/CustomButton/CustomButton';
import { IGovOnboardingEmailInput, SEND_GOV_ONBOARDING_EMAIL } from '../../../graphql/email.graphql';
import { showMessage } from '../../../utils/message.utils';
import { useMutation } from '@apollo/client';

export default function OnboardingReviewYourInformation(): React.ReactElement {
  const history = useRouteMonitor();

  const {
    onboardingInfo: { userType, accountType, accountSubtype, accountInfo, onboardingFlowType },
  } = useOnboardingStore();

  const planName = SELECT_RECOMMENDED_SUNPASS_PLAN_NAME(accountType, accountSubtype);
  const steps = userType && onboardingFlowType ? getStepsByUserTypeAndFlowType(userType, onboardingFlowType) : [];
  const [showRedirectPrompt, setShowRedirectPrompt] = useState(true);
  const { cancelRedirect, confirmRedirect, showPrompt } = useCallbackPrompt(showRedirectPrompt);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const govOnboardingEmailInput: IGovOnboardingEmailInput = {
    agencyName: accountInfo?.organizationName || '',
    firstName: accountInfo?.firstName || '',
    lastName: accountInfo?.lastName || '',
    phoneNumber: accountInfo?.cellPhone || '',
    emailAddress: accountInfo?.email || '',
  };

  const [sendGovOnboardingEmail] = useMutation<{ govOnboardingEmailInput: IGovOnboardingEmailInput }>(
    SEND_GOV_ONBOARDING_EMAIL,
    {
      variables: {
        govOnboardingEmailInput,
      },
    },
  );

  const moveToAccountInfo = useCallback(() => {
    !isSubmitted && history.goToPath(OnboardingStepPath.ACCOUNT);
  }, [isSubmitted]);

  useEffect(() => {
    !showRedirectPrompt && !isSubmitted && moveToAccountInfo();
    isSubmitted && !showRedirectPrompt && history.goToPath(OnboardingStepPath.SUCCESS);
  }, [isSubmitted, showRedirectPrompt]);

  async function onSubmit() {
    try {
      await sendGovOnboardingEmail();
    } catch (err) {
      showMessage('error');
      console.log(err);
    }
    setIsSubmitted(true);
    setShowRedirectPrompt(false);
  }

  const { recaptchaChallengeElem, loading: recaptchaLoading, submitWithRecaptcha } = useRecaptcha({ onSubmit });
  return (
    <React.Fragment>
      <ScrollToTop />
      <MainLoader loading={recaptchaLoading} />
      <LeaveSiteConfirmationModal showModal={showPrompt} onOk={confirmRedirect} onCancel={cancelRedirect} />
      <OnboardingHeader<OnboardingHeaderWholeViewProps>
        title={t`Review Your Information`}
        steps={steps}
        planName={planName}
        currentStepIndex={2}
      />
      <div className={`flex flex-wrap ${styles.reviewInformationContainer}`}>
        <Space size="large" direction="vertical" className="w-full py-10 pl-5 pr-3 md:w-1/3">
          <img className="header-logo mt-2 h-16 w-full sm:mt-0 md:h-full" src={sunpass_logo} alt="sunpass logo"></img>
        </Space>
        <Space size="large" direction="vertical" className="w-full py-10 pl-5 pr-3 md:w-1/3">
          <ContactInformation onEdit={() => setShowRedirectPrompt(false)} contactInfo={accountInfo as IContactInfo} />
          <AccountType localizedPlanName={planName} />
        </Space>
        <Space size="large" direction="vertical" className="w-full py-10 pl-5 pr-3 md:w-1/3">
          <MailingAddress onEdit={() => setShowRedirectPrompt(false)} address={accountInfo as IMailingAddress} />
          <Billing billingInfo={accountInfo as IContactInfo} />
        </Space>
      </div>
      {recaptchaChallengeElem}
      <Row className="onboarding-button-container grid grid-cols-3 items-center">
        <BackButton
          blockRedirectTill={showRedirectPrompt}
          onBack={() => {
            setShowRedirectPrompt(false);
            history.goBack();
          }}
        />
        <CustomButton content="Complete" type="submit" onClick={submitWithRecaptcha} />
      </Row>
    </React.Fragment>
  );
}

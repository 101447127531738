import { UseFormSetError } from 'react-hook-form';
import { IErrorByType, ITranpondersErrors } from 'src/hooks/errors/errorHandler.types';
import { IAccountInfo, IAccountInfoPropertyNames } from 'src/types/onboarding.types';

export const errorMap = {
  '1066': 'lastName',
  '1078': 'postalCode',
  '1160': 'email',
  '1083': 'city',
  '1178': 'firstSecurityQuestion',
  '1115': 'firstSecurityQuestionAnswer',
  '1080': 'addressLine1',
  '1102': 'cellPhone',
  '1187': 'username',
  '1179': 'digitPin',
  '1065': 'firstName',
  '1176': 'statementType',
  '1170': 'reTypeEmail',
  '1109': 'password',
};

type ErrorMap = { [key: string]: string };

export const OnErrorCode = (
  errors: IErrorByType,
  errorMap: ErrorMap,
  onErrorCallback: UseFormSetError<IAccountInfo>,
) => {
  Object.entries(errors).forEach(([value]) => {
    onErrorCallback(
      errorMap[`${value}`] as IAccountInfoPropertyNames,
      { type: 'focus', message: value as unknown as string },
      { shouldFocus: true },
    );
  });
};
export const displayTranponderError = (errors: ITranpondersErrors, transponderNumber: string) => {
  const transponderError = Object.values(errors).find((transponder) => {
    if (Number(transponder.transponderNumber) === Number(transponderNumber)) return transponder;
  });
  if (transponderError) {
    return transponderError.errorMessage;
  } else return null;
};

export const getLicencePlateFromMessage = (errorMessage: string) => {
  return errorMessage.split(' ')[1];
};

import { FC } from 'react';
import { Form, Select } from 'antd';
import { Controller, useFormContext } from 'react-hook-form';
import { t, Trans } from '@lingui/macro';
import { HOURS, US_LOCAL_TIME_FORMAT } from '../../../../utils/time.utils';
import moment from 'moment';
import { IVehicleFormItemProps } from '../../../Onboarding/Vehicles/OnboardingVehiclesForm/OnboardingVehicleForm';
import { buildErrorString, getInputName } from '../../../Onboarding/Vehicles/OnboardingVehiclesForm/vehicleForm.utils';
import classNames from 'classnames';

const TODAY = moment();

const { Option } = Select;

const EffectiveStartTime: FC<IVehicleFormItemProps> = ({ field, index, name }) => {
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="mb-4 w-full sm:mb-0 sm:w-3/12 sm:pr-2">
      <Form.Item
        {...field}
        help={buildErrorString(name, errors, index)}
        label={<Trans id="Effective Start Time" />}
        htmlFor={name}
        className="theme"
        required
      >
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              id={name}
              placeholder={t`Select`}
              className={`${classNames({ 'input-error': fieldState?.invalid })}`}
              onChange={(value) => {
                field.onChange(value);
                trigger(getInputName('effectiveEndTime', index));
              }}
              defaultValue={TODAY.startOf('hour').format(US_LOCAL_TIME_FORMAT)}
              aria-label={t`Effective start time`}
            >
              {HOURS.map((hour: any) => (
                <Option key={hour} value={hour}>
                  {hour}
                </Option>
              ))}
            </Select>
          )}
        />
      </Form.Item>
    </div>
  );
};

export default EffectiveStartTime;

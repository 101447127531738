import { IAccountDetailsTransponderActivationSignUpInput } from 'src/graphql/signUp/signUpTransponderActivation.graphql';
import { IAccountInfo } from 'src/types/onboarding.types';

export const mapAccountInfoToPatrialyActivateTranponders = (
  accountInfo: IAccountInfo,
): IAccountDetailsTransponderActivationSignUpInput => {
  return {
    firstName: accountInfo?.firstName || '',
    lastName: accountInfo?.lastName || '',
    fein: accountInfo?.fein || '',
    companyName: accountInfo?.companyName || '',
    dbaName: accountInfo?.doingBusinessAs || '',
    secondaryContacts: accountInfo?.additionalContacts || [],
    addressLine1: accountInfo?.addressLine1 || '',
    addressLine2: accountInfo?.addressLine2 || '',
    country: accountInfo?.country || '',
    city: accountInfo?.city || '',
    state: accountInfo?.state || '',
    zipcode: accountInfo?.postalCode || '',
    zipcodePlus: '',
    email: accountInfo?.email || '',
    reTypeEmail: accountInfo?.reTypeEmail || '',
    additionalEmailAddress: {
      email: '',
      reTypeEmail: '',
    },
    cellPhone: accountInfo?.cellPhone || '',
    homePhone: accountInfo?.homePhone || '',
    workPhone: accountInfo?.workPhone || '',
    smsOption: accountInfo?.smsOption || false,
    language: accountInfo?.language || '',
    statementType: 'EMAIL|MONTHLY|Detail', //TODO FLO-3081
    username: accountInfo?.username || '',
    password: accountInfo?.password || '',
    reTypePassword: accountInfo?.reTypePassword || '',
    securityQuestion: accountInfo?.firstSecurityQuestion || '',
    securityQuestionAnswer: accountInfo?.firstSecurityQuestionAnswer || '',
    digitPin: '',
  };
};

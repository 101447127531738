import React, { useEffect } from 'react';
import { Form } from 'antd';
import { t, Trans } from '@lingui/macro';
import { Controller, useFormContext } from 'react-hook-form';
import StyledInput from '../StyledInput/StyledInput';
import { ISecurityInfo } from 'src/types/onboarding.types';
import { useRouteMonitor } from '../../hooks/location/useRouteMonitor';
import { OnboardingStepPath } from '../../types/routes';
import { PasswordPolicy } from './PasswordPolicy';

interface IPasswordProps {
  password?: string | undefined;
  isUpdatePasswordMode?: boolean;
  isOldPassword?: boolean;
}

export const Password = ({ password, isUpdatePasswordMode, isOldPassword }: IPasswordProps) => {
  const {
    control,
    watch,
    trigger,
    setValue,
    formState: { errors },
  } = useFormContext<ISecurityInfo>();

  const getLabel = () => {
    if (isOldPassword) {
      return 'Old Password';
    }
    if (isUpdatePasswordMode) {
      return 'New Password';
    }
    return 'Password';
  };

  const inputName = isOldPassword ? 'oldPassword' : 'password';

  const passwordWatch = watch(inputName);

  useEffect(() => {
    trigger('reTypePassword');
  }, [passwordWatch]);

  const history = useRouteMonitor();
  const fullOnboardingHistory = history.getFullOnboardingHistory();
  const prevPath = fullOnboardingHistory.length >= 2 ? fullOnboardingHistory[fullOnboardingHistory.length - 2] : '';
  const shouldClearPassword =
    prevPath !== OnboardingStepPath.TRANSPONDERS &&
    prevPath !== OnboardingStepPath.VEHICLES &&
    prevPath !== OnboardingStepPath.REVIEW;

  useEffect(() => {
    if (shouldClearPassword) setValue(inputName, '');
  }, []);

  return (
    <div className="flex flex-wrap">
      <div className="w-full sm:w-6/12 sm:pr-1">
        <Form.Item
          label={<Trans>{getLabel()}</Trans>}
          className="theme"
          help={errors[inputName]?.message}
          validateTrigger={['onChange', 'onBlur']}
          required
        >
          <Controller
            name={inputName}
            control={control}
            render={({ field, fieldState }) => (
              <StyledInput
                field={field}
                fieldstate={fieldState}
                id={inputName}
                key={inputName}
                type={'password'}
                maxLength={64}
                defaultValue={!isUpdatePasswordMode ? password || passwordWatch : undefined}
                aria-label={t`${inputName}`}
              />
            )}
          />
        </Form.Item>
      </div>
      {!isOldPassword ? <PasswordPolicy inputName={inputName} /> : null}
    </div>
  );
};

import { Button, Form } from 'antd';
import { t, Trans } from '@lingui/macro';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import StyledInput from '../../StyledInput/StyledInput';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';
import { AccountType, IEmailAddresses } from '../../../types/onboarding.types';
import { InputPropsType } from '../../MyAccount/ContactInformation/Contact';
import { colors } from '../../../theme/theme';
import { useWithTrigger } from '../../../hooks/effect/useWithTrigger';
import { useOnboardingStore } from '../../../store/onboarding/onboarding.store';
import '../../AppForm/AppForm.scss';

export const EmailAddresses = ({ disabled = false }: InputPropsType) => {
  const {
    control,
    formState: { errors },
    getValues,
    watch,
    trigger,
  } = useFormContext<IEmailAddresses>();

  const {
    fields: additionalEmailAddressesFields,
    append: additionalEmailAddressesAppend,
    remove: additionalEmailAddressesRemove,
  } = useFieldArray({
    control,
    name: 'additionalEmailAddresses',
  });

  const {
    onboardingInfo: { accountType },
  } = useOnboardingStore();

  const additionalEmailShown = useMemo(() => {
    return getValues('additionalEmailAddresses')?.length === 1;
  }, [watch('additionalEmailAddresses')]);

  const additionalEmailWatch = getValues('additionalEmailAddresses')?.[0]?.email;
  const additionalReTypeEmailWatch = getValues('additionalEmailAddresses')?.[0]?.reTypeEmail;

  const isAdditionalEmptyEmailFieldsShown =
    (additionalEmailWatch && additionalReTypeEmailWatch) || additionalEmailAddressesFields?.length == 1;

  useWithTrigger(
    isAdditionalEmptyEmailFieldsShown,
    () => {
      if (isAdditionalEmptyEmailFieldsShown) {
        trigger('additionalEmailAddresses.0.reTypeEmail');
        trigger('additionalEmailAddresses.0.email');
      }
    },
    [additionalEmailWatch, additionalReTypeEmailWatch],
  );

  const emailWatch = watch('email');
  const reTypeEmailWatch = watch('reTypeEmail');

  const isPrimaryEmptyEmailFieldsShown = emailWatch !== '' || reTypeEmailWatch !== '';

  useWithTrigger(
    isPrimaryEmptyEmailFieldsShown,
    () => {
      if (isPrimaryEmptyEmailFieldsShown) {
        trigger('email');
        trigger('reTypeEmail');
      }
    },
    [emailWatch, reTypeEmailWatch],
  );

  return (
    <>
      <div className="flex flex-wrap">
        <div className="input-margins w-full sm:w-6/12 sm:pr-1">
          <Form.Item
            label={<Trans>Primary Email Address</Trans>}
            className="theme"
            help={errors.email?.message}
            required
          >
            <Controller
              name="email"
              control={control}
              render={({ field, fieldState }) => (
                <StyledInput
                  disabled={disabled}
                  field={field}
                  fieldstate={fieldState}
                  id="email"
                  key="email"
                  maxLength={40}
                  aria-label={t`Primary Email Address`}
                />
              )}
            />
          </Form.Item>
        </div>
        <div className="input-margins w-full sm:w-6/12 sm:pl-1">
          <Form.Item
            label={<Trans>Re-Type Primary Email Address</Trans>}
            className="theme"
            help={errors.reTypeEmail?.message}
            required
          >
            <Controller
              name="reTypeEmail"
              control={control}
              render={({ field, fieldState }) => (
                <StyledInput
                  disabled={disabled}
                  field={field}
                  fieldstate={fieldState}
                  id="reTypeEmail"
                  key="reTypeEmail"
                  maxLength={40}
                  aria-label={t`Re-Type Primary Email Address`}
                />
              )}
            />
          </Form.Item>
        </div>
      </div>

      {accountType !== AccountType.GOVERNMENT
        ? additionalEmailAddressesFields.map((field, index) => {
            return (
              <div key={field.id} className="flex flex-wrap">
                <div className="input-margins w-full sm:w-6/12 sm:pr-1">
                  <Form.Item {...field}>
                    <label className="form-label">
                      <Trans>Secondary Email Address</Trans>
                    </label>
                    <Controller
                      control={control}
                      name={`additionalEmailAddresses.${index}.email`}
                      render={({ field, fieldState }) => (
                        <StyledInput
                          disabled={disabled}
                          field={field}
                          fieldstate={fieldState}
                          onChange={field.onChange}
                          id={`additionalEmailAddresses[${index}].email`}
                          key={`additionalEmailAddresses[${index}].email`}
                          name={`additionalEmailAddresses[${index}].email`}
                          maxLength={40}
                          aria-label={t`Additional Email Address`}
                        />
                      )}
                    />
                    <div className="form-error">{errors.additionalEmailAddresses?.[index]?.email?.message}</div>
                  </Form.Item>
                </div>
                <div className="input-margins w-full sm:w-6/12 sm:pl-1">
                  <Form.Item {...field}>
                    <label className="form-label">
                      <Trans>Re-Type Secondary Email Address</Trans>
                    </label>
                    <Controller
                      control={control}
                      name={`additionalEmailAddresses.${index}.reTypeEmail`}
                      render={({ field, fieldState }) => (
                        <StyledInput
                          disabled={disabled}
                          field={field}
                          fieldstate={fieldState}
                          onChange={field.onChange}
                          id={`additionalEmailAddresses[${index}].reTypeEmail`}
                          key={`additionalEmailAddresses[${index}].reTypeEmail`}
                          name={`additionalEmailAddresses[${index}].reTypeEmail`}
                          maxLength={40}
                          aria-label={t`Re-Type Additional Email Address`}
                        />
                      )}
                    />
                    <div className="form-error">{errors.additionalEmailAddresses?.[index]?.reTypeEmail?.message}</div>
                  </Form.Item>
                </div>
                <Button
                  type="link"
                  disabled={disabled}
                  size="small"
                  className={`theme col-span-full row-span-full ${disabled ? 'text-disabled' : 'text-red'} underline`}
                  onClick={() => additionalEmailAddressesRemove(index)}
                  icon={<MinusCircleOutlined className="dynamic-delete-button mr-2" color={colors.alerts.red} />}
                >
                  <Trans>Remove secondary email address</Trans>
                </Button>
              </div>
            );
          })
        : null}

      {!additionalEmailShown && accountType !== AccountType.GOVERNMENT ? (
        <Button
          type="link"
          disabled={disabled}
          size="small"
          onClick={() => additionalEmailAddressesAppend({ email: '', reTypeEmail: '' })}
          className={`theme ${disabled ? 'text-disabled' : 'text-green'} underline`}
          icon={<PlusCircleOutlined width={16} height={16} className="mr-2" />}
        >
          <Trans>Add secondary email address</Trans>
        </Button>
      ) : null}

      {accountType !== AccountType.GOVERNMENT ? (
        <div className="flex flex-wrap">
          <div className="my-4 w-full">
            <Trans>
              While optional, a secondary email address provides SunPass with an additional method of contact should you
              lose access to your account. You can also use multiple email addresses to receive account notifications.
            </Trans>
          </div>
        </div>
      ) : null}
    </>
  );
};

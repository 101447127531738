import { FC, useState } from 'react';
import SelectTransponderWithPlan from './SelectTransponderWithPlan';
import AddPlanProccess from '../AddPlanProccess';
import { addPlanStore } from 'src/store/addPlan/addPlan.store';
import { TransponderType } from 'src/graphql/transponders.graphql';
interface IPurchaseTransponderWithPlan {
  setIsPurchaseMode: (isPurchaseMode: boolean) => void;
  isPurchaseMode: boolean;
}
const PurchaseTransponderWithPlan: FC<IPurchaseTransponderWithPlan> = ({ isPurchaseMode, setIsPurchaseMode }) => {
  const { nextStep, currentStep } = addPlanStore();
  const [transponderType, setTransponderType] = useState<TransponderType>();
  return (
    <div>
      {currentStep === 0 ? (
        <SelectTransponderWithPlan
          proceedToNextStep={nextStep}
          returnToPreviousStep={() => {
            setIsPurchaseMode(false);
          }}
          setTransponderType={setTransponderType}
        />
      ) : null}
      {currentStep !== 0 && isPurchaseMode ? (
        <div className="multistep-container mx-0 pt-8">
          <AddPlanProccess transponderType={transponderType} />
        </div>
      ) : null}
    </div>
  );
};
export default PurchaseTransponderWithPlan;

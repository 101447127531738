import * as yup from 'yup';
import { validators } from '../../validators';
import { CHOOSE_ONE_OF_THE_OPTIONS_ERROR_MSG, MINIMUM_REQUIRED_ERROR_MSG } from './ezPayErrorMessages';

export const getEZPaySchemaForOnboarding = (minimumRecurringAmount: number, minimumDepositAmount: number) => {
  return yup.object().shape({
    optInEZPay: yup.bool().required(CHOOSE_ONE_OF_THE_OPTIONS_ERROR_MSG),
    recurringPaymentAmount: yup.string().when('optInEZPay', {
      is: true,
      then: validators.payment.test(
        'recurringPaymentAmount',
        `$${minimumRecurringAmount} ${MINIMUM_REQUIRED_ERROR_MSG}`,
        (value) => +(value || 0) >= minimumRecurringAmount,
      ),
    }),
    depositAmount: validators.payment.test(
      'depositAmount',
      `$${minimumDepositAmount} ${MINIMUM_REQUIRED_ERROR_MSG}`,
      (value) => +(value || 0) >= minimumDepositAmount,
    ),
    confirmDepositAmount: validators.depositAmountConfirm('depositAmount'),
  });
};

export const getEasyPayMyWalletSchema = (minimumRecurringAmount: number) => {
  return yup.object().shape({
    recurringPaymentAmount: validators.payment.test(
      'recurringPaymentAmount',
      `$${minimumRecurringAmount} ${MINIMUM_REQUIRED_ERROR_MSG}`,
      (value) => +(value || 0) >= minimumRecurringAmount,
    ),
  });
};

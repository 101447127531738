import React from 'react';
import './AddFunds.scss';
import { Form, InputNumber } from 'antd';
import AddFundsButton from './AddFundsButton';
import { Controller, useFormContext } from 'react-hook-form';
import ButtonGroup from 'antd/es/button/button-group';
import { IAddFunds } from './AddFunds';
import { t } from '@lingui/macro';
import { ADD_FUNDS_MINIMUM_AMOUNT_REQUIRED_ERROR_MSG } from 'src/components/Payments/validation/paymentErrorMessages';

const ChooseAmount = () => {
  const {
    control,
    watch,
    resetField,
    setValue,
    register,
    trigger,
    formState: { isSubmitted },
  } = useFormContext<IAddFunds>();

  const otherAmountWatch = watch('otherAmount');
  const addFundsButtonWatch = watch('addFundsButton');

  const changeHandler = (e: number) => {
    resetField('addFundsButton');
    setValue('actualAmount', e);
    setValue('otherAmount', e);
  };

  const isAmountInvalid = otherAmountWatch === undefined || otherAmountWatch >= 5;

  return (
    <>
      <div
        className={`add-funds-buttons flex h-16 cursor-pointer rounded-lg bg-primary-white shadow-lg  ${
          (otherAmountWatch && otherAmountWatch < 5) ||
          (isSubmitted && otherAmountWatch === undefined && addFundsButtonWatch === undefined)
            ? 'button-error'
            : 'border-white'
        }`}
      >
        <Form.Item validateTrigger={['onChange']} {...register('addFundsButton')}>
          <Controller
            name="addFundsButton"
            control={control}
            render={() => (
              <ButtonGroup>
                <AddFundsButton value="10" />
                <AddFundsButton value="15" />
                <AddFundsButton value="25" />
                <AddFundsButton value="50" />
              </ButtonGroup>
            )}
          />
        </Form.Item>
        <div className="other-amount">
          <Form.Item name="otherAmount" validateTrigger={['onChange']}>
            <Controller
              name="otherAmount"
              control={control}
              render={({ field }) => (
                <InputNumber
                  {...field}
                  id="otherAmount"
                  key="otherAmount"
                  precision={2}
                  placeholder={t`Other amount`}
                  className={
                    otherAmountWatch && otherAmountWatch >= 5 ? 'other-amount-input_positive' : 'other-amount-input '
                  }
                  max={100000}
                  decimalSeparator={','}
                  formatter={
                    otherAmountWatch === undefined
                      ? undefined
                      : (value) => {
                          return `$${value}`;
                        }
                  }
                  onChange={(e) => {
                    if (e === null) {
                      setValue('actualAmount', 0);
                      resetField('otherAmount');
                      trigger(['actualAmount', 'addFundsButton']);
                    } else changeHandler(e);
                    trigger(['actualAmount', 'addFundsButton', 'otherAmount']);
                  }}
                  min={0}
                  onBlur={() => {
                    if (otherAmountWatch === 0) {
                      resetField('otherAmount');
                    }
                  }}
                />
              )}
            />
          </Form.Item>
        </div>
      </div>
      {isAmountInvalid ? null : (
        <div className="text-red ml-4 mt-2 text-left">{ADD_FUNDS_MINIMUM_AMOUNT_REQUIRED_ERROR_MSG}</div>
      )}
    </>
  );
};

export default ChooseAmount;

import { Trans } from '@lingui/macro';
import React from 'react';
import BackButton from '../../BackButton/BackButton';
import SelectableCard from '../../SelectableCard/SelectableCard';
import ScrollToTop from '../../ScrollToTop/ScrollToTop';
import { useAccountRecommendation } from './useAccountRecommendation';
import { CustomButton } from 'src/components/CustomButton/CustomButton';

export default function OnboardingAccountRecommendation(): React.ReactElement {
  const { onSubmit, cards, selectable, recommendedPlan } = useAccountRecommendation();

  return (
    <div className="onboarding-recommendation h-full">
      <ScrollToTop />
      <div className="onboarding-recommendation__header">
        <div role="heading" aria-level={1} className="main-header onboarding-header">
          <Trans>Recommended for you…</Trans>
        </div>

        <h4 className="py-5 text-center">{recommendedPlan}</h4>
      </div>

      <section className="onboarding-recommendation__content card">
        <div
          className={`content__plan-types ${
            selectable ? 'content__plan-types--selectable' : 'content__plan-types--non-selectable'
          }`}
          tabIndex={-1}
        >
          {cards.map((card) => (
            <SelectableCard key={card.title} {...card}></SelectableCard>
          ))}
        </div>
      </section>

      <div className="onboarding-recommendation__button-container onboarding-button-container">
        <BackButton></BackButton>
        <CustomButton content="Continue" onClick={onSubmit} />
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { Trans } from '@lingui/macro';
import ScreenSize from 'src/types/ScreenSize.enum';
import useScreenSize from 'src/hooks/screen/useScreenSize';
import './Dashboard.scss';
import { Link, useParams } from 'react-router-dom';
import { convertPriceToString, getProperPhoneNumber } from 'src/utils/formatter';
import { useQuery } from '@apollo/client';
import { GET_ACCOUNT_DETAILS, IAccountDetails } from '../../graphql/accountDetails.graphql';
import { MainLoader } from '../Loader/MainLoader';
import { DashboardParams, MainRoutes } from '../../types/routes';
import { capitalizeInitials } from '../../utils/string.utils';
import SettleBallance from './SettleBalance/SettleBallance';
import { ParamTypes } from './Dashboard';
import MakePaymentButton from './MakePaymentButton';

export default function DashboardHeader(): React.ReactElement {
  const { screenSize } = useScreenSize();
  const [isSettleBalanceSectionShown, setIsSettleBalanceSectionShown] = useState(false);

  const { data, loading } = useQuery<{ accountDetails: IAccountDetails }>(GET_ACCOUNT_DETAILS);

  const currentBalance = data?.accountDetails.currentBalance || 0;

  const { activeTab } = useParams<Partial<ParamTypes>>();

  useEffect(() => {
    if (activeTab !== DashboardParams.UNPAID_TOLLS) {
      setIsSettleBalanceSectionShown(false);
    }
  }, [location.pathname]);

  return (
    <>
      <MainLoader loading={loading} />
      <header className="dashboard-header flex-column min-h-72 w-full bg-primary-white py-12 align-middle  sm:flex  sm:px-20">
        <div className="w-full text-center text-sm sm:text-left">
          <div className="dashboard-title flex-row justify-center text-3xl  font-light large-tablet:text-4xl ">
            <span>
              Hello, <strong>{capitalizeInitials(data?.accountDetails.customerName)}</strong>
            </span>
          </div>

          <div className="mb-4 flex-col flex-wrap large-tablet:mt-2">
            <span
              className={
                loading
                  ? 'balance ml-0 mr-auto mt-5 sm:ml-auto sm:mr-0'
                  : 'mr-6 large-tablet:border-r large-tablet:border-gray-400 large-tablet:py-1 large-tablet:pr-6'
              }
            >
              {loading ? '-' : getProperPhoneNumber(data?.accountDetails)}
            </span>

            {screenSize <= ScreenSize.LARGE_TABLET ? <br /> : null}
            <span>{data?.accountDetails.emailAddress}</span>
          </div>
          <Link
            to={MainRoutes.MY_ACCOUNT}
            className="text-primary-green1 underline"
            aria-label="Edit Contact Information"
          >
            <span className="text-sm large-tablet:mt-8">
              <Trans>Edit Contact Information</Trans>
            </span>
          </Link>
        </div>
        <div className="balance-container mr-45 items-baseline ">
          <span className="balance-title mx-auto md:ml-auto md:mr-0">
            <Trans>Current Balance</Trans>
          </span>
          <span
            className={`balance mt-5
              ${currentBalance >= 0 ? 'text-black' : 'text-red-600'}`}
          >
            {loading ? '-' : convertPriceToString(currentBalance)}
          </span>

          <MakePaymentButton
            currentBalance={currentBalance}
            isSettleBalanceSectionShown={isSettleBalanceSectionShown}
            setIsSettleBalanceSectionShown={setIsSettleBalanceSectionShown}
            activeTab={activeTab as DashboardParams}
          />
        </div>
      </header>{' '}
      {isSettleBalanceSectionShown && activeTab === DashboardParams.UNPAID_TOLLS ? (
        <SettleBallance
          setIsSettleBalanceSectionShown={setIsSettleBalanceSectionShown}
          ballance={currentBalance}
          closeSettleBallance={() => setIsSettleBalanceSectionShown(false)}
          showCVV={true}
        />
      ) : null}
    </>
  );
}

import { getAccessToken } from '../../security/token.service';
import { validateTokens } from '../../security/CustomApolloProvider';
import { useState } from 'react';
import { useAuth } from '../../store/authentication/authentication.store';

export type FileResourceNameType = 'transactionActivities';

type RequestMethod = 'POST';
const API_BASE_URL = '/api';

const fileDownloadApiCall = async (method: RequestMethod, resourceName: FileResourceNameType, data?: any) => {
  return await fetch(`${API_BASE_URL}/file/${resourceName}`, {
    method,
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
};

const handleBrowserDownload = async (response: Response): Promise<void> => {
  const filename = response?.headers?.get('Content-Disposition')?.split('filename=')[1];
  if (!filename) {
    throw new Error();
  }
  const blob = await response.blob();
  downloadFiles([
    {
      name: filename || 'file',
      url: window.URL.createObjectURL(blob),
    },
  ]);
};
export interface IFile {
  name: string;
  url: string;
}

export const downloadFiles = (files?: IFile[]) => {
  if (files === undefined) return;
  files.forEach((file) => {
    const link = document.createElement('a');
    link.href = file.url;
    link.download = file.name;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  });
};

export interface IUseFileApi {
  loading: boolean;
  downloadFile: (resourceName: FileResourceNameType, input?: any) => Promise<void>;
}

export const useFileApi = (): IUseFileApi => {
  const [loading, setLoading] = useState(false);
  const { invalidateAuthentication } = useAuth();
  const downloadFile = async (resourceName: FileResourceNameType, input?: any): Promise<void> => {
    setLoading(true);
    try {
      await validateTokens();
    } catch (err) {
      console.log(err);
      invalidateAuthentication();
    }
    try {
      const response = await fileDownloadApiCall('POST', resourceName, input);
      await handleBrowserDownload(response);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  return {
    downloadFile,
    loading,
  };
};

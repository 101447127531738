import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Dashboard from '../Dashboard/Dashboard';
import AppFooter from '../AppFooter/AppFooter';
import Onboarding from '../../pages/Onboarding/Onboarding';
import { Styleguide } from '../StyleGuide/Styleguide';
import MyAccount from '../MyAccount/MyAccount';
import useErrorHandling from 'src/hooks/errors/useErrorHandling';
import { useLogout } from 'src/hooks/auth/useLogout';
import { useEffectUnderCondition } from 'src/hooks/effect/useEffectUnderCondition';

function AuthenticatedApp(): React.ReactElement {
  const { clearSession } = useLogout();
  const { isError, error } = useErrorHandling();

  useEffectUnderCondition(
    isError && error?.type === 'authError',
    () => {
      (async () => {
        await clearSession();
      })();
    },
    [isError, error],
  );

  return (
    <div className="App w-full bg-primary-gray2 ">
      <Routes>
        <Route path="*" element={<Navigate replace to="/dashboard/my-dashboard" />} />
        <Route path="/onboarding/*" element={<Onboarding />} />
        <Route path="/dashboard/:activeTab" element={<Dashboard />} />
        <Route path="/dashboard" element={<Navigate replace to="/dashboard/my-dashboard" />} />
        <Route path="/styleguide" element={<Styleguide />} />
        <Route path="/my-account" element={<MyAccount />} />
      </Routes>
      <AppFooter></AppFooter>
    </div>
  );
}

export default AuthenticatedApp;

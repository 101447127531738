import { useLazyQuery, useMutation } from '@apollo/client';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { t, Trans } from '@lingui/macro';
import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { IPromoCodeForm } from 'src/components/Dashboard/Wallet/PromoCodeModal/PromoCodeModal';
import TableRow from 'src/components/Payments/PaymentSummary/OneTimePurchase/TableRow';
import CouponCode from 'src/components/atoms/form/oneTImePurchase/CouponCode';
import { IVerifyPromotionCodeOutput, PromoCodeType, VERIFY_PROMO_CODE } from 'src/graphql/verifyPromotionCode.graphql';
import { colors } from 'src/theme/theme';
import './AddPlansPaymentSummary.scss';
import { showMessage } from 'src/utils/message.utils';
import { validationSchemaForPromoCode } from 'src/validators/dashboard/transponders/promoCode.validator';
import useTransponderPrices from 'src/hooks/domain/dashboard/transponders/useTransponderPrices';
import { TransponderType } from 'src/graphql/transponders.graphql';
import {
  IOrderTransponderInput,
  IOrderTransponderOutput,
  ORDER_TRANSPONDER,
} from '../../../graphql/purchaseTransponder.graphql';
import { MainLoader } from '../../Loader/MainLoader';

interface IAddPlansPaymentSummary {
  setPromoCodeAmount: React.Dispatch<React.SetStateAction<number>>;
  setPromoCode: React.Dispatch<React.SetStateAction<string | undefined>>;
  promoCodeAmount: number;
  transponderType?: TransponderType;
  setIsPromoInvalid: React.Dispatch<React.SetStateAction<boolean>>;
}
const AddPlansPaymentSummary: FC<IAddPlansPaymentSummary> = ({
  setPromoCode,
  setPromoCodeAmount,
  promoCodeAmount,
  transponderType,
  setIsPromoInvalid,
}) => {
  const methods = useForm<IPromoCodeForm>({
    resolver: yupResolver(validationSchemaForPromoCode),
    mode: 'onChange',
  });
  const { miniPrice, proPrice, PBCPrice } = useTransponderPrices();
  const { watch, setValue } = methods;

  const [isPromoCodeApplied, setIsPromoCodeApplied] = useState(false);
  const [isPromoValid, setIsPromoValid] = useState(false);
  const [isPromoEmpty, setIsPromoEmpty] = useState(false);
  const [promoType, setPromoType] = useState<string | null>(null);
  const [amount, setAmount] = useState<number | null>(null);
  const [noTaxAmount, setNoTaxAmout] = useState(0);
  const [tax, setTax] = useState(0);

  const [finalMiniTransponderQty, setFinalMiniTransponderQty] = useState(
    transponderType === TransponderType.MINI ? 1 : 0,
  );
  const [finalProTransponderQty, setFinalProTransponderQty] = useState(transponderType === TransponderType.PRO ? 1 : 0);

  const promoCodeWatch = watch('couponCode');

  useEffect(() => {
    if (promoType === PromoCodeType.PRO && isPromoCodeApplied && isPromoValid) {
      setFinalProTransponderQty(0);
    } else if (promoType === PromoCodeType.STK && isPromoCodeApplied && isPromoValid) {
      setFinalMiniTransponderQty(0);
    }

    if (promoCodeWatch === '') {
      setFinalProTransponderQty(transponderType === TransponderType.PRO ? 1 : 0);
      setFinalMiniTransponderQty(transponderType === TransponderType.MINI ? 1 : 0);
    }
  }, [promoCodeWatch, isPromoValid, isPromoCodeApplied, promoType]);

  const [verifyPromoCode] = useLazyQuery<{ verifyPromotionCode: IVerifyPromotionCodeOutput }, { couponCode: string }>(
    VERIFY_PROMO_CODE,
  );

  const [orderTransponder, { loading: isTransponderOrderingLoading }] = useMutation<{
    orderTransponder: IOrderTransponderOutput;
    orderTransponderInput: IOrderTransponderInput;
  }>(ORDER_TRANSPONDER);

  const applyingPromoCodeCompletedUnsuccessfully = () => {
    setIsPromoCodeApplied(true);
    setIsPromoValid(false);
    setIsPromoInvalid(true);
    setPromoCodeAmount(0);
    setPromoCode('');
    setPromoType(null);
  };

  const applyingPromoCodeCompletedSuccessfully = (data: IVerifyPromotionCodeOutput | undefined) => {
    setPromoCode(promoCodeWatch);
    setIsPromoCodeApplied(true);
    if (data?.couponStatus === 'VALID') {
      if (
        (data?.mountType === PromoCodeType.STK && transponderType === TransponderType.PRO) ||
        (data?.mountType === PromoCodeType.PRO && transponderType === TransponderType.MINI) ||
        data?.mountType === null
      ) {
        applyingPromoCodeCompletedUnsuccessfully();
      } else {
        setIsPromoValid(true);
        setIsPromoInvalid(false);
        data && setPromoCodeAmount(Number(data?.amount));
        data && setPromoType(data?.mountType);
      }
    } else {
      setIsPromoValid(false);
      setIsPromoInvalid(true);
      setPromoCodeAmount(0);
      setPromoType(null);
    }
  };

  const onSuccess = (responseData: any) => {
    if (responseData.error) {
      applyingPromoCodeCompletedUnsuccessfully();
    } else {
      applyingPromoCodeCompletedSuccessfully(responseData.verifyPromotionCode);
    }
  };

  const checkPromoCode = async () => {
    if (promoCodeWatch === '' || promoCodeWatch === undefined) {
      setIsPromoEmpty(true);
      setPromoCodeAmount(0);
      setPromoType(null);
    } else {
      setIsPromoEmpty(false);
      try {
        await verifyPromoCode({
          variables: {
            couponCode: promoCodeWatch,
          },
          onCompleted: onSuccess,
        });
      } catch (err) {
        showMessage('error', t`Invalid promo code`);
        setPromoCodeAmount(0);
        console.log((err as Error).message);
      }
    }
  };

  useEffect(() => {
    isPromoCodeApplied && checkPromoCode();
  }, [isPromoCodeApplied]);

  const renderTypeColumn = (transponderType: TransponderType) => {
    if (transponderType === TransponderType.MINI)
      return (
        <TableRow
          col1={<Trans>Mini Transponder</Trans>}
          col2={miniPrice}
          col3={1}
          col4={`$${miniPrice.toFixed(2)}`}
          className="py-8"
        />
      );
    else if (transponderType === TransponderType.PRO)
      return (
        <TableRow
          col1={<Trans>Pro Transponder</Trans>}
          col2={proPrice}
          col3={1}
          col4={`$${proPrice.toFixed(2)}`}
          className="py-8"
        />
      );
  };

  const makeOrder = async () => {
    if (finalProTransponderQty !== 0 || finalMiniTransponderQty !== 0) {
      const { data } = await orderTransponder({
        variables: {
          orderTransponderInput: {
            numOfProTransponders: finalProTransponderQty,
            numOfStickerTransponders: finalMiniTransponderQty,
          },
        },
      });
      data && setNoTaxAmout(data.orderTransponder.amountDue - data.orderTransponder.tax + PBCPrice);
      data && setAmount(data.orderTransponder.amountDue + PBCPrice);
      data && setTax(data.orderTransponder.tax);
    }
  };

  useEffect(() => {
    if (finalProTransponderQty === 0 && finalMiniTransponderQty === 0) {
      setNoTaxAmout(PBCPrice);
      setAmount(PBCPrice);
      setTax(0);
    }
  }, [finalMiniTransponderQty, finalProTransponderQty, isPromoCodeApplied]);

  useEffect(() => {
    makeOrder();
  }, [finalMiniTransponderQty, finalProTransponderQty]);

  const promoAmountToDisplay = () => {
    if (Number(promoCodeAmount) > 0 && !(promoType === PromoCodeType.TOLL || promoType === null)) {
      return `-$${Number(promoCodeAmount).toFixed(2)}`;
    }
    return '';
  };

  return (
    <div className="add-palns-payment-summary">
      <h3 className="text-primary-blue1">
        <Trans>Payment Summary</Trans>
      </h3>
      <div className="md:pl-8">
        <MainLoader loading={isTransponderOrderingLoading} />
        <table className="border-spacing-2 w-full border-collapse">
          <tbody>
            <tr>
              <td></td>
              <td className="text-left">
                <Trans>PRICE</Trans>
              </td>
              <td className="text-left">
                <Trans>QTY</Trans>
              </td>
              <td className="text-right">
                <Trans>TOTAL</Trans>
              </td>
            </tr>
            {transponderType ? renderTypeColumn(transponderType) : null}
            <TableRow
              col1={<Trans>Pinellas Bayway Plan</Trans>}
              col2={PBCPrice}
              col3={1}
              col4={`$${PBCPrice.toFixed(2)}`}
              className="py-8"
            />
            <TableRow
              className={isPromoCodeApplied || isPromoEmpty ? 'coupon-applied' : 'coupon'}
              col1={
                <>
                  <div className="flex w-52">
                    <FormProvider {...methods}>
                      <CouponCode isPromoValid={isPromoValid} isPromoCodeApplied={isPromoCodeApplied} />
                    </FormProvider>
                    {isPromoCodeApplied ? (
                      <button
                        onClick={() => {
                          setValue('couponCode', '');
                          setPromoCodeAmount(0);
                          setPromoCode('');
                          setIsPromoInvalid(false);
                          setPromoType(null);
                          setIsPromoValid(false);
                          setIsPromoCodeApplied(false);
                          setFinalMiniTransponderQty(transponderType === TransponderType.MINI ? 1 : 0);
                          setFinalProTransponderQty(transponderType === TransponderType.PRO ? 1 : 0);
                        }}
                        className="red-link ml-3 mt-5 cursor-pointer"
                      >
                        <Trans>Cancel</Trans>
                      </button>
                    ) : (
                      <button
                        onClick={async () => await checkPromoCode()}
                        className="apply-text green-link ml-3 cursor-pointer"
                      >
                        <Trans>Apply</Trans>
                      </button>
                    )}
                  </div>
                  {isPromoCodeApplied && isPromoValid && (
                    <div className="flex">
                      <p className="italic text-primary-green1">
                        <Trans>Applied</Trans>
                      </p>
                      <FontAwesomeIcon
                        className="ml-2"
                        icon={faCheck}
                        color={colors.primary.green1}
                        size="lg"
                      ></FontAwesomeIcon>
                    </div>
                  )}
                  {isPromoCodeApplied && !isPromoValid ? (
                    <div className="flex">
                      <p className="text-primary-red">
                        <Trans>Invalid promo code</Trans>
                      </p>
                    </div>
                  ) : null}
                  {isPromoEmpty && (
                    <div className="flex">
                      <p className="text-primary-red">
                        <Trans>Please enter a valid promo code</Trans>
                      </p>
                    </div>
                  )}
                </>
              }
              col4={promoAmountToDisplay()}
            />
            <TableRow
              className="border-t border-gray-300"
              col1={
                <strong>
                  <Trans>Subtotal</Trans>
                </strong>
              }
              col4={`$${Number(noTaxAmount).toFixed(2)}`}
            />
            <TableRow col1={<Trans>Sales Tax</Trans>} col4={`$${Number(tax).toFixed(2)}`} />
          </tbody>
        </table>
      </div>
      <div className="mt-4 flex justify-between">
        <h3 className=" text-primary-blue1">
          <Trans>Amount Due Today</Trans>
        </h3>{' '}
        <h3>${Number(amount).toFixed(2)}</h3>
      </div>
    </div>
  );
};
export default AddPlansPaymentSummary;

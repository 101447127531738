import { IPaymentMethod } from 'src/components/Payments/Payments.interfaces';
import { EasyPayOption } from 'src/graphql/payment.graphql';
import { IPaymentMethodGraphQl } from 'src/graphql/paymentMethods.graphql';
import { IPurchasePlanInput } from 'src/graphql/plans/purchasePlans.graphql';
import { ITransponder, TransponderType } from 'src/graphql/transponders.graphql';

export const mapNewPaymentMethodToPurchasePlan = (
  paymentMethod: IPaymentMethod,
  transponderToAddPlan: ITransponder | null,
  cardType: string,
  paymentAmount: string,
  transponderType?: TransponderType,
): IPurchasePlanInput => {
  return {
    paymentAmount: paymentAmount,
    paymentMethodId: '',
    bankRoutingNumber: paymentMethod?.bankRoutingNumber || '',
    bankAccountNumber: paymentMethod?.bankAccountNumber || '',
    cardNumber: paymentMethod?.ccNumber || '',
    expirationMonth: paymentMethod?.ccExpirationMonth,
    expirationYear: paymentMethod?.ccExpirationYear,
    securityCode: paymentMethod?.ccSecurityCode || '',
    firstName: paymentMethod?.ccFirstName || paymentMethod?.bankFirstName || '',
    middleName: paymentMethod?.ccMiddleName || '',
    lastName: paymentMethod?.ccLastName || paymentMethod?.bankLastName || '',
    addressLine1: paymentMethod?.addressLine1 || '',
    addressLine2: paymentMethod?.addressLine2 || '',
    city: paymentMethod.city || '',
    state: paymentMethod?.state || '',
    country: paymentMethod?.country || '',
    zipCode: paymentMethod?.postalCode || '',
    easyPayOption:
      paymentMethod?.optInEZPay === true
        ? EasyPayOption.EASY_PAY
        : paymentMethod?.bankAccountNumber !== ''
        ? EasyPayOption.STORED_CARD
        : EasyPayOption.NONE,
    planName: 'PBC',
    cardType: paymentMethod?.bankAccountNumber ? '' : cardType,
    promoCode: '',
    transponderNumber: transponderToAddPlan === null ? '' : transponderToAddPlan.transponderNumber,
    transponderType:
      transponderToAddPlan !== null
        ? transponderToAddPlan.transponderType
        : transponderType
        ? transponderType
        : TransponderType.MINI,
  };
};
export const mapPaymentMethodToPurchasePlan = (
  paymentMethod: IPaymentMethodGraphQl,
  paymentAmount: string,
  transponderToAddPlan: ITransponder | null,
  transponderType?: TransponderType,
): IPurchasePlanInput => {
  return {
    paymentAmount: paymentAmount,
    paymentMethodId: paymentMethod.paymentMethodId,
    bankRoutingNumber: paymentMethod.bankRoutingNumber,
    bankAccountNumber: paymentMethod.bankAccountNumber,
    cardNumber: paymentMethod.cardNumber,
    expirationMonth: paymentMethod.expirationMonth,
    expirationYear: paymentMethod.expirationYear,
    securityCode: paymentMethod.securityCode,
    firstName: paymentMethod.firstName,
    middleName: paymentMethod.middleName,
    lastName: paymentMethod.lastName,
    addressLine1: paymentMethod.addressLine1,
    addressLine2: paymentMethod.addressLine2,
    city: paymentMethod.city,
    state: paymentMethod.state,
    country: paymentMethod.country,
    zipCode: paymentMethod.zipCode,
    easyPayOption: paymentMethod.isEasyPay ? EasyPayOption.EASY_PAY : EasyPayOption.STORED_CARD,
    promoCode: '',
    transponderNumber: transponderToAddPlan === null ? '' : transponderToAddPlan.transponderNumber,
    transponderType:
      transponderToAddPlan !== null
        ? transponderToAddPlan.transponderType
        : transponderType
        ? transponderType
        : TransponderType.MINI,
    cardType: paymentMethod.payType,
    planName: 'PBC',
  };
};

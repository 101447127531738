import { downloadFiles, IFile } from '../../hooks/file/useFileApi';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

export type BulkUploadType = 'transponders' | 'vehicles';

interface IBulkUploadTempalteLinkProps {
  name: string | JSX.Element;
  type: BulkUploadType;
  noIcon?: boolean;
}

const mapTypeToTemplateFiles: Record<BulkUploadType, IFile[]> = {
  transponders: [
    {
      name: 'SampleTransponderStatus_1.csv',
      url: '/assets/transponders/SampleTransponderStatus_1.csv',
    },
    {
      name: 'SampleTransponderStatus_2.xlsx',
      url: '/assets/transponders/SampleTransponderStatus_2.xlsx',
    },
  ],
  vehicles: [
    {
      name: 'SampleVehicles_1.csv',
      url: '/assets/vehicle/SampleVehicles_1.csv',
    },
    {
      name: 'SampleVehicles_2.xlsx',
      url: '/assets/vehicle/SampleVehicles_2.xlsx',
    },
  ],
};

export const BulkUploadTemplateLink = ({ name, type, noIcon }: IBulkUploadTempalteLinkProps) => {
  const [templateFilesToBeDownloaded, setTemplateFilesToBeDownloaded] = useState<IFile[]>();

  useEffect(() => {
    setTemplateFilesToBeDownloaded(mapTypeToTemplateFiles[type]);
  }, [type]);

  const downloadTemplateFiles = () => {
    downloadFiles(templateFilesToBeDownloaded);
  };

  return (
    <Link
      to="#"
      onClick={downloadTemplateFiles}
      className="dashboard-links mt-4 w-auto"
      aria-label="Bulk upload template link"
    >
      <span className="large-tablet:mt-8">
        {!noIcon && <FontAwesomeIcon icon={faFileExcel} className="text-large mr-2" />}
        {name}
      </span>
    </Link>
  );
};
